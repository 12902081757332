import React from 'react';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '../../hooks';
import TileItem from '../../features/Tiles/TileItem';

const useStyles = makeStyles((theme) => ({
	tile3Columns: {
		gridColumnEnd: 'span 3'
	}
}));

const lastDayOfMonth = 'L';

const DayItem = ({ day, selected, onClick }) => {
	const classes = useStyles();
	const { translate } = useLocale();

	return (
		<Box
			display='flex'
			justifyContent='center'
			flex={1}
			className={day == lastDayOfMonth ? classes.tile3Columns : null}>
			<TileItem
				minWidth={45}
				maxWidth={260}
				flex={day == lastDayOfMonth ? 1 : null}
				p={0.5}
				text={day == lastDayOfMonth ? translate('Common_LastDayOfMonth').toLowerCase() : day}
				selected={selected}
				onClick={() => onClick(day)}
			/>
		</Box>
	);
};

export default DayItem;
