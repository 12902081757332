import React, { useEffect } from 'react';
import { ShareUsers } from '@sonar-web/common';

const Sharing = ({ stepIndex, useConfigurator, configuratorData, updateConfiguratorData, property, ...rest }) => {
	const { updateStepValid, isValid, visitedSteps } = useConfigurator();
	const users = configuratorData['recipients'] ?? [];
	const count = users.length;
	const visited = visitedSteps[stepIndex];

	useEffect(() => {
		if (!visited || !isValid[stepIndex]) updateStepValid(stepIndex, true);
	}, [count, visited]);

	const setUsers = (data) => {
		const recipientIds = data.length === 0 ? [] : data.map((u) => u.id);

		updateConfiguratorData({ ['recipients']: data }, false);
		updateConfiguratorData({ [property]: recipientIds }, false);

		if (!isValid[stepIndex]) updateStepValid(stepIndex, true);
	};

	return <ShareUsers {...rest} users={users} setUsers={setUsers} />;
};

export default Sharing;
