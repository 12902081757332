import React from 'react';
import BaseIcon from './BaseIcon';

export default function MeasurementPointIcon(props) {
	return (
		<BaseIcon {...props}>
			<path d='m 6.0042423,3.5625298 c -1.343688,0 -2.441712,1.09751 -2.441712,2.44119 V 17.99782 c 0,1.34369 1.098024,2.43965 2.441712,2.43965 H 17.996275 c 1.343687,0 2.441194,-1.09596 2.441194,-2.43965 V 6.0037198 c 0,-1.34368 -1.097507,-2.44119 -2.441194,-2.44119 z m 0,0.99994 H 17.996275 c 0.806986,0 1.441255,0.63428 1.441255,1.44125 V 17.99782 c 0,0.80699 -0.634269,1.43971 -1.441255,1.43971 H 6.0042423 c -0.806986,0 -1.441773,-0.63272 -1.441773,-1.43971 V 6.0037198 c 0,-0.80697 0.634787,-1.44125 1.441773,-1.44125 z m 5.9898147,2.15232 c -2.8405097,0.003 -5.1595777,2.28106 -5.1578247,5.0860002 v 0.49971 l 0.999939,-0.002 v -0.49971 c -0.0014,-2.2549502 1.850311,-4.0819502 4.1599527,-4.0839902 2.309643,-0.002 4.163438,1.82336 4.166154,4.0783102 l 0.0021,0.49971 0.999939,-0.002 -0.0021,-0.49971 c -0.0034,-2.8049302 -2.32765,-5.0786902 -5.16816,-5.0761802 z m 0.0062,3.9294802 a 0.5,0.5 0 0 0 -0.500228,0.50023 v 1.84174 a 0.5,0.5 0 0 0 0.03566,0.18294 c -0.823165,0.20939 -1.437638,0.96121 -1.437638,1.8464 0,1.04517 0.857044,1.90221 1.902209,1.90221 1.045164,0 1.904278,-0.85704 1.904277,-1.90221 0,-0.88519 -0.616113,-1.63701 -1.439706,-1.8464 a 0.5,0.5 0 0 0 0.03514,-0.18294 V 11.1445 A 0.5,0.5 0 0 0 12.00026,10.64427 Z m 0,3.46697 c 0.504726,0 0.904338,0.39961 0.904338,0.90434 0,0.50473 -0.399612,0.90227 -0.904338,0.90227 -0.504725,0 -0.90227,-0.39754 -0.90227,-0.90227 0,-0.50473 0.397545,-0.90434 0.90227,-0.90434 z' />
		</BaseIcon>
	);
}
