import { Typography } from '@mui/material';
import { useRoutes } from '@sonar-web/common/src/hooks';
import LinkRouter from '@sonar-web/common/src/components/Router/LinkRouter';

const DeviceNumberAndType = ({ number, type, deviceId, typographyProps = {} }) => {
	const { getModuleDeviceRouteByModuleName, modules } = useRoutes();
	const route = getModuleDeviceRouteByModuleName(modules.heatallocator.name);

	if (route)
		return (
			<LinkRouter to={`/${route.devicesRoute}/${deviceId}`} data-at='heatallocator'>
				<strong>{number}</strong> {`(${type})`}
			</LinkRouter>
		);

	return (
		<Typography variant='body2' color='textPrimary' data-at='heatallocator' {...typographyProps}>
			<strong>{number}</strong> {`(${type})`}
		</Typography>
	);
};

export default DeviceNumberAndType;
