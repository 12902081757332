import React, { useEffect, useState } from 'react';
import { Menu } from '@mui/material';
import BaseTooltip from '../BaseTooltip/BaseTooltip';
import BaseIconButton from '../Buttons/BaseIconButton';
import { useLocale } from '../../hooks';
import useList from '../../features/List/useList';
import BaseSelectMenuItem from '../BaseSelectMenuItem/BaseSelectMenuItem';
import { useSidebar } from '../../features';
import MultiSelectIcon from '@sonar-web/common/src/icons/MultiSelectIcon';

/**
 * @param {Object|boolean} multipleSelect "sidebar component" to render or "true" for no component
 */
const MultiSelectMenu = ({
	slice,
	sidebar,
	multipleSelect,
	multipleSelectParams,
	toggleMultipleSelectionModeEnabled = true
}) => {
	const { translate } = useLocale();
	const {
		toggleMultipleSelectionMode,
		isMultiSelect,
		isMultiSelectSelectAll,
		selectedCount,
		deselectListItem,
		toggleSelectAllListItems,
		listSelector
	} = useList(slice);
	const { totalCount, elements } = listSelector;
	const { loadSidebar, getComponentInfo, unloadSidebar, isSidebarComponentActive } = useSidebar();
	const [anchor, setAnchor] = useState(null);

	const count = selectedCount();
	const sidebarComponent = multipleSelect === true ? null : getComponentInfo(multipleSelect, multipleSelectParams);
	const allCount = !isMultiSelectSelectAll ? count : totalCount - (elements.length - count);
	const multiSelectText = isMultiSelect ? 'Common_MultiSelectToggleOff' : 'Common_MultiSelectToggleOn';

	useEffect(() => {
		if (multipleSelect === true) return;
		if (isMultiSelect) loadSidebar(sidebar, sidebarComponent);
		else {
			if (isSidebarComponentActive(sidebar, sidebarComponent.name)) {
				deselectListItem(null);
				unloadSidebar(sidebar);
			}
		}
	}, [isMultiSelect]);

	const handleMenu = (event) => {
		setAnchor(event ? event.currentTarget : null);
	};

	const handleToggleMode = () => {
		toggleMultipleSelectionMode();
		toggleSelectAllListItems(false);
		handleMenu(false);
	};

	const handleSelectAll = () => {
		if (!isMultiSelect) toggleMultipleSelectionMode();
		toggleSelectAllListItems(true);
		handleMenu(false);
	};

	const handleDeselectAll = () => {
		deselectListItem(null);
		toggleSelectAllListItems(false);
		handleMenu(false);
	};

	return (
		<>
			<BaseTooltip text={translate('Common_MultiSelect')}>
				<div>
					<BaseIconButton
						icon={MultiSelectIcon}
						color='primary'
						onClick={handleMenu}
						name='ListActionToolbarToggleMultipleSelect'
					/>
				</div>
			</BaseTooltip>
			<Menu
				anchorEl={anchor}
				open={Boolean(anchor)}
				anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
				transformOrigin={{ vertical: 'top', horizontal: 'left' }}
				onClose={() => handleMenu(false)}>
				{toggleMultipleSelectionModeEnabled && (
					<BaseSelectMenuItem data-at={multiSelectText} onClick={handleToggleMode}>
						{translate(multiSelectText)}
					</BaseSelectMenuItem>
				)}
				{isMultiSelect && allCount > 0 && (
					<BaseSelectMenuItem data-at='Common_MultiSelectDeselectAll' onClick={handleDeselectAll}>
						{translate('Common_MultiSelectDeselectAll')}
					</BaseSelectMenuItem>
				)}
				{allCount !== totalCount && (
					<BaseSelectMenuItem data-at='Common_MultiSelectSelectAll' onClick={handleSelectAll}>
						{translate('Common_MultiSelectSelectAll')}
					</BaseSelectMenuItem>
				)}
			</Menu>
		</>
	);
};

export default MultiSelectMenu;
