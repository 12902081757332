import React from 'react';
import NotificationBar from '../../../../components/Notification/NotificationBar';
import { useLocale } from '../../../../hooks';

const ConfiguratorSectionDescription = ({ text }) => {
	const { translate } = useLocale();

	return <NotificationBar style={{ marginTop: 0, marginBottom: 24 }} text={translate(text)} show={text != null} />;
};

export default ConfiguratorSectionDescription;
