import { useMemo } from 'react';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';
import { useLocale } from '@sonar-web/common/src/hooks';
import Points from './Points';
import useBuilingVisualization from './useBuilingVisualization';
import { VisualizationType } from '../../Enums/VisualizationType';
import useUrlQuery from '@sonar-web/common/src/hooks/useUrlQuery';

const Wrapper = styled('div')(({ theme }) => ({
	flex: 1,
	contentVisibility: 'auto',
	display: 'flex',
	flexDirection: 'column',
	'@keyframes flashQueryPoint': {
		'0%': {
			boxShadow: `inset 0px 0px 0px 2px ${theme.palette.primary.main}, inset 0px 0px 0px 3px #ffffff`
		},
		'50%': {
			boxShadow: `inset 0px 0px 0px 2px #ffffff, inset 0px 0px 0px 3px #ffffff`
		},
		'100%': {
			boxShadow: `inset 0px 0px 0px 2px ${theme.palette.primary.main}, inset 0px 0px 0px 3px #ffffff`
		}
	},
	'&.rootDetails': {
		width: 45,
		height: 35
	},
	'&.rootPoints': {
		borderRight: `1px solid #E2E6EC`,
		padding: 10,
		minHeight: 100,
		minWidth: 120
	},
	'&.read': {
		backgroundColor: '#2E7D32'
	},
	'&.notRead': {
		backgroundColor: '#d32f2f'
	},
	'&.partiallyRead': {
		backgroundColor: '#FFCA00'
	},
	'&.noPoints': {
		backgroundColor: '#CACACA'
	},
	'&.empty': {
		backgroundColor: 'transparent'
	},
	'&.queryMeasurementPoint': {
		boxShadow: `inset 0px 0px 0px 2px ${theme.palette.primary.main}, inset 0px 0px 0px 3px #ffffff`,
		animation: `flashQueryPoint 1000ms ease-in-out`,
		animationIterationCount: 2,
		animationDelay: '200ms'
	}
}));

const Local = ({ local, points = [], empty = false }) => {
	const { translate } = useLocale();
	const { visualizationType } = useBuilingVisualization();
	const query = useUrlQuery();
	const queryMeasurementPoint = query.get('measurementPoint');
	const hasQueryMeasurementPoint = useMemo(
		() => getQueryMeasurementPoint(points, queryMeasurementPoint),
		[queryMeasurementPoint]
	);

	const status = useMemo(() => localStatus(points), []);
	const pointsView = visualizationType === VisualizationType.Points.value;

	let rootClassNames = `local`;
	if (visualizationType === VisualizationType.All.value) rootClassNames += ` ${status}`;
	if (visualizationType === VisualizationType.Details.value) rootClassNames += ` rootDetails ${status}`;
	if (pointsView) rootClassNames += ` rootPoints`;
	if (empty) rootClassNames += ` empty`;
	if (hasQueryMeasurementPoint) rootClassNames += ` queryMeasurementPoint`;

	return (
		<Wrapper className={rootClassNames}>
			{(!pointsView || (pointsView && empty)) && <>&nbsp;</>}
			{pointsView && !empty && (
				<>
					<Typography variant='caption' color='textSecondary'>
						{translate('Stats_Local')}
					</Typography>
					<Typography variant='subtitle1' lineHeight={1}>
						{local}
					</Typography>
					<Points points={points} />
				</>
			)}
		</Wrapper>
	);
};

export default Local;

function localStatus(points) {
	if (points.length === 0) return 'noPoints';
	if (points.every((p) => p.isRead)) return 'read';
	if (points.some((p) => p.isRead)) return 'partiallyRead';
	return 'notRead';
}

function getQueryMeasurementPoint(points, point) {
	if (!point || points.length === 0) return false;

	return points.some((p) => p.id === point);
}
