import { useMemo } from 'react';
import styled from '@emotion/styled';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { addFilters, removeMemberAllFilters, removeMemberSelectedFilters, selectFilterByName } from './filtersSlice';
import { useFilterDescriptor } from '../../hooks';
import ButtonAdd from '../Buttons/ButtonAdd';
import BaseButton from '../Buttons/BaseButton';
import { useListFiltersContext } from './listFiltersContext';
import useFilters from './useFilters';
import EventBus from '../../helpers/EventBus';
import { Stack } from '@mui/material';

const PaperContent = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	padding: '2rem',
	gap: '2rem',
	width: '100vw',
	maxWidth: 400,
	'&.widefilters': {
		maxWidth: 800
	},
	'& .MuiAutocomplete-root, .MuiFormControl-root': {
		flex: 0,
		marginTop: 0,
		marginBottom: 0
	},
	[theme.breakpoints.down('md')]: {
		maxWidth: '400px !important'
	}
}));

const Actions = styled('div')({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	gap: '1rem'
});

const ListFilters = ({ slice, controls, showClearButton = true, columns = null }) => {
	const { closeFilters } = useListFiltersContext();
	const { clearAllFilters } = useFilters(slice);

	const filtersSelectorFunction = useMemo(() => selectFilterByName(slice), [slice]);
	const current = useSelector(filtersSelectorFunction, shallowEqual);

	return (
		<PaperContent data-at='ListFilters' className={columns > 1 ? 'widefilters' : ''}>
			<Controls controls={controls} slice={slice} columns={columns} current={current} />
			<Actions>
				<ButtonAdd
					name='filtersClearAll'
					onClick={clearAllFilters}
					startIcon={null}
					sx={{
						visibility: showClearButton && current ? 'visible' : 'hidden'
					}}>
					Common_ClearAllFilters
				</ButtonAdd>
				<BaseButton name='closeListFilters' onClick={closeFilters} startIcon={null}>
					Common_Close
				</BaseButton>
			</Actions>
		</PaperContent>
	);
};

export default ListFilters;

const Controls = ({ slice, controls, columns, current }) => {
	const perColumnCount = useMemo(() => Math.ceil(controls.length / columns), [controls?.length, columns]);

	return useMemo(() => {
		if (columns) {
			return (
				<Stack
					direction='column'
					gap='2rem'
					sx={{
						flexDirection: {
							md: 'row'
						}
					}}>
					<Stack flex={1} gap='2rem'>
						<ControlRender slice={slice} controls={controls.slice(0, perColumnCount)} current={current} />
					</Stack>
					<Stack flex={1} gap='2rem'>
						<ControlRender slice={slice} controls={controls.slice(perColumnCount)} current={current} />
					</Stack>
				</Stack>
			);
		}

		return <ControlRender slice={slice} controls={controls} current={current} />;
	}, [current]);
};

const ControlRender = ({ slice, controls, current }) => {
	const dispatch = useDispatch();
	const { createFilter } = useFilterDescriptor();

	const items = Array.isArray(controls) ? controls : [controls];

	const handleChange = (searchData, member, append = false) => {
		const filtersData = createFilter(searchData);

		dispatch(addFilters({ slice, filtersData, member, append }));
		EventBus.dispatch(EventBus.messages.FilteringChanged, { slice, member });
	};

	const handleClearMemberAll = (member) => {
		dispatch(removeMemberAllFilters({ slice, member }));
		if (current) EventBus.dispatch(EventBus.messages.FilteringChanged, { slice, member });
	};

	const handleClearMemberSelected = (filterValue, member) => {
		const filtersData = createFilter(filterValue);

		dispatch(removeMemberSelectedFilters({ slice, filtersData, member }));
		EventBus.dispatch(EventBus.messages.FilteringChanged, { slice, member });
	};

	return items.map((item, index) => {
		const { control, searchControl, searchControlProps = {}, ...rest } = item;
		const Control = control;
		const SearchControl = searchControl;

		return (
			<Control
				key={index}
				{...rest}
				stateSlice={current}
				onChange={handleChange}
				onClearMemberAll={handleClearMemberAll}
				onClearMemberSelected={handleClearMemberSelected}
				{...searchControlProps} //na potrzeby testów automatycznych - texfieldName
				renderSearchComponent={
					!searchControl
						? null
						: ({ label, setInputRef, handleSelect, handleCancelSelect, onSelect }) => (
								<SearchControl
									onSelectCallback={handleSelect}
									label={label}
									setInputRef={setInputRef}
									onListClose={handleCancelSelect}
									onSelect={onSelect}
									{...searchControlProps}
								/>
						  )
				}
			/>
		);
	});
};
