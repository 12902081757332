import React from 'react';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { useLocale } from '../../hooks';
import ButtonLinkAlt from '../../components/Buttons/ButtonLinkAlt';
import ButtonAdd from '../../components/Buttons/ButtonAdd';

const useStyles = makeStyles((theme) => ({
	titleRoot: {
		marginBottom: 10
	},
	primaryTextRoot: {
		fontWeight: theme.typography.fontWeightLight,
		lineHeight: 1
	},
	primaryValueRoot: {
		fontWeight: theme.typography.fontWeightMedium
	},
	buttonsWrap: {
		[theme.breakpoints.down('md')]: {
			flexDirection: ({ horizontal }) => (horizontal ? 'row' : 'column'),
			alignItems: ({ horizontal }) => (horizontal ? 'flex-strt' : 'center')
		}
	},
	submitRoot: {
		marginLeft: theme.spacing(2),
		[theme.breakpoints.down('md')]: {
			marginLeft: ({ horizontal }) => (horizontal ? theme.spacing(2) : 0),
			marginTop: ({ horizontal }) => (horizontal ? 0 : theme.spacing(2))
		}
	}
}));

const DeletePageContent = ({ onCancel, onSubmit, primaryText, primaryValue, pending, horizontal }) => {
	const classes = useStyles({ horizontal });
	const { translate } = useLocale();

	return (
		<>
			<Typography variant='h4' color='textPrimary' classes={{ root: classes.titleRoot }}>
				{translate('Common_DeleteTitle')}
			</Typography>
			<Typography
				variant='subtitle1'
				component='span'
				color='textSecondary'
				classes={{ root: classes.primaryTextRoot }}>
				{translate(primaryText)}{' '}
				<Typography
					variant='subtitle1'
					color='textPrimary'
					component='span'
					classes={{ root: classes.primaryValueRoot }}
					data-at='deletedItem'>
					{primaryValue}
				</Typography>
				?
			</Typography>
			<Typography variant='subtitle1' color='textSecondary' classes={{ root: classes.primaryTextRoot }}>
				{translate('Common_ChangeIsIrrreversible')}
			</Typography>
			<Box
				mt={4}
				display='flex'
				flexWrap='wrap'
				justifyContent={horizontal ? 'flex-start' : 'center'}
				className={classes.buttonsWrap}>
				<ButtonLinkAlt
					startIcon={<KeyboardBackspaceOutlinedIcon />}
					onClick={onCancel}
					name='cancelDeleteRecord'>
					Common_NoOnlyMistake
				</ButtonLinkAlt>
				<ButtonAdd
					startIcon={null}
					progress
					pending={pending}
					className={classes.submitRoot}
					onClick={onSubmit}
					name='submitDeleteRecord'>
					Common_YesDelete
				</ButtonAdd>
			</Box>
		</>
	);
};

export default DeletePageContent;
