import React from 'react';
import { Box, Typography } from '@mui/material';
import { useLocale } from '@sonar-web/common/src/hooks';
import LinkRouter from '@sonar-web/common/src/components/Router/LinkRouter';
import useGroupDevices from '../../../../sonar-web-core/src/sets/Hooks/useGroupDevices';
import PointSetsDevice from './PointSetsDevice';

const PointSets = ({ data, measurementSetName }) => {
	const { groupDevices } = useGroupDevices();
	const { translate } = useLocale();
	const devices = data?.measurementSetCurrent ? groupDevices(data?.measurementSetCurrent.measurementPairs) : [];

	return (
		<Box p={2} pr={4}>
			<LinkRouter to={`/locationnodes/${data.id}`}>
				<strong>{data.addressDisplayName}</strong>
			</LinkRouter>
			<br />
			<Typography variant='caption' color='textSecondary'>
				{translate(measurementSetName)}
			</Typography>
			<br />
			<br />
			{devices.map(({ meterDevices, transmissionDevices }, index) => {
				if (meterDevices && transmissionDevices && transmissionDevices.length == 1) {
					const transmissionDevice = transmissionDevices[0].device;

					return (
						<React.Fragment key={index}>
							{meterDevices.map((item, mdIndex) => {
								const meterDevice = item.device;

								if (mdIndex == 0) {
									return (
										<React.Fragment key={meterDevice.id}>
											<PointSetsDevice device={meterDevice} />
											<br />
											<PointSetsDevice device={transmissionDevice} />
										</React.Fragment>
									);
								} else {
									return <PointSetsDevice key={meterDevice.id} device={meterDevice} />;
								}
							})}
						</React.Fragment>
					);
				} else if (meterDevices) {
					return meterDevices.map((item) => {
						return (
							<React.Fragment key={item.device.id}>
								<PointSetsDevice key={item.device.id} device={item.device} />
								<br />
							</React.Fragment>
						);
					});
				} else if (transmissionDevices) {
					return transmissionDevices.map((item) => {
						return (
							<React.Fragment key={item.device.id}>
								<PointSetsDevice key={item.device.id} device={item.device} />
								<br />
							</React.Fragment>
						);
					});
				}
			})}
		</Box>
	);
};

export default PointSets;
