import { Toolbar } from '@mui/material';

const FormToolbar = ({ children, sx = {}, ...rest }) => {
	return (
		<Toolbar
			variant='dense'
			disableGutters
			sx={{
				marginTop: '1.5rem',
				justifyContent: 'flex-end',
				gap: '.5rem',
				...sx
			}}
			{...rest}>
			{children}
		</Toolbar>
	);
};

export default FormToolbar;
