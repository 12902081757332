import { apiBase } from '@sonar-web/common';
import { REST_API_PATHS } from '@sonar-web/common/src/constants/system';

const path = REST_API_PATHS.SETS;

export async function fetchSets(locationId) {
	const { data } = await apiBase(`terminalinstallationsets/${locationId}`, 'get', {
		path
	});

	return data;
}

export async function fetchSetsForDevice(deviceId) {
	const { data } = await apiBase(`devices/${deviceId}/terminalinstallationsets`, 'get', {
		path
	});

	return data;
}

export async function fetchActiveMeasurementSetDevicesCount() {
	const { data } = await apiBase(`devices/activemeasurementdevicescount`, 'get', {
		path
	});

	return data;
}

export async function fetchConnectionRules(pageDescriptor) {
	const { data } = await apiBase(`connectionrules`, 'get', {
		path,
		params: { pageDescriptor }
	});

	return data;
}

export async function fetchDeviceTypes(descriptor) {
	const { data } = await apiBase(`devicetypes`, 'get', {
		path: path,
		params: { pageDescriptor: descriptor }
	});

	return data;
}

export async function fetchDeviceTypeConnectionRules(deviceType) {
	const { data } = await apiBase(`connectionrules/${deviceType}`, 'get', {
		path
	});

	return data;
}

export async function addMeasurementSet(values) {
	const { data } = await apiBase(`terminalinstallationsets/${values.locationId}/open`, 'put', {
		path,
		data: { ...values }
	});

	return data;
}

export async function editMeasurementSet(values) {
	const { data } = await apiBase(`terminalinstallationsets/${values.closeRequest.locationId}/closeopen`, 'put', {
		path,
		data: values
	});

	return data;
}

export async function closeMeasurementSet(values) {
	const { data } = await apiBase(`terminalinstallationsets/${values.locationId}/close`, 'put', {
		path,
		data: values
	});

	return data;
}

export async function deleteMeasurementSet(locationId) {
	const { data } = await apiBase(`terminalinstallationsets/${locationId}`, 'delete', {
		path
	});

	return data;
}

export async function fetchMeasurementPoint(id) {
	const { data } = await apiBase(`locationNodes/${id}`, 'get', {
		path: REST_API_PATHS.LOCATION
	});

	return data;
}

export async function checkDevicesAvailability(pageDescriptor) {
	const { data } = await apiBase(`devices/availability`, 'get', {
		path,
		params: { pageDescriptor }
	});

	return data;
}

export async function fetchMeasurementInterfaces(locationId) {
	const { data } = await apiBase(`terminalinstallationsets/${locationId}/measurementinterfaces`, 'get', {
		path
	});

	return data;
}

const SetsService = {
	fetchActiveMeasurementSetDevicesCount,
	fetchConnectionRules,
	fetchDeviceTypes,
	fetchDeviceTypeConnectionRules,
	addMeasurementSet,
	editMeasurementSet,
	closeMeasurementSet,
	deleteMeasurementSet,
	fetchSets,
	fetchMeasurementPoint,
	checkDevicesAvailability,
	fetchSetsForDevice,
	fetchMeasurementInterfaces
};

export default SetsService;
