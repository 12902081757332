import { apiBase } from '@sonar-web/common';
import { REST_API_PATHS } from '@sonar-web/common/src/constants/system';

const path = REST_API_PATHS.TCP;

async function fetchTcpDeviceTypes(pageDescriptor) {
	const { data } = await apiBase(`deviceTypes`, 'get', {
		path,
		params: { pageDescriptor }
	});

	return data;
}

async function fetchTcpDevices(pageDescriptor) {
	const { data } = await apiBase(`devices`, 'get', {
		path,
		params: { pageDescriptor }
	});

	return data;
}

export async function addTcpDevice(data) {
	const response = await apiBase(`devices`, 'post', {
		path,
		data
	});

	const locationHeader = response.headers.location;

	if (locationHeader)
		return {
			url: locationHeader,
			id: locationHeader.slice(locationHeader.indexOf('/') + 1)
		};

	return response;
}

const TcpService = {
	fetchTcpDeviceTypes,
	fetchTcpDevices,
	addTcpDevice
};

export default TcpService;
