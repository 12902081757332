import React from 'react';
import { FormControlLabel, Switch, Typography } from '@mui/material';
import { useLocale } from '../../../../hooks';
import useConfigurator from '../useConfigurator';

const ConfiguratorGroupSelection = ({ form, groupName, isGroupIncludedInConfiguration }) => {
	const { translate } = useLocale();
	const { updateGroupIncludeInConfiguration, isRegisterSelectionModeEnabled, datasFormRegisters, isReadOnly } =
		useConfigurator();

	const handleOnChange = (_, v) => {
		updateGroupIncludeInConfiguration(groupName, v);

		//czyszczenie błędów walidacji po wyłączeniu grupu z konfiguracji
		if (!v) {
			const keys = datasFormRegisters.filter((dfr) => dfr.group === groupName).map((dfr) => dfr.key);
			keys.forEach((key) => {
				form.setFieldTouched(key, false);
				setTimeout(() => form.setFieldError(key, undefined), 100);
			});
		}
	};

	if (!isRegisterSelectionModeEnabled) return null;
	return (
		<>
			<Typography variant='caption' component='p' style={{ marginTop: 20 }}>
				{translate('Dconf_ConfiguratorGroupSelectionQuestion')}
			</Typography>
			<FormControlLabel
				name='disableGroup'
				disabled={isReadOnly}
				label={translate(isGroupIncludedInConfiguration ? 'Common_Yes' : 'Common_No')}
				style={{ marginLeft: 0 }}
				control={
					<Switch
						size='small'
						color='primary'
						checked={isGroupIncludedInConfiguration}
						onChange={handleOnChange}
					/>
				}
			/>
		</>
	);
};

export default ConfiguratorGroupSelection;
