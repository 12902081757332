import React from 'react';
import { UserProfileIcon } from '@sonar-web/common';
import AvatarLetters from './AvatarLetters';

const AvatarLettersNotInSystem = ({ ...rest }) => {
	return (
		<AvatarLetters {...rest}>
			<UserProfileIcon primaryPathClass='secondaryTextColor' />
		</AvatarLetters>
	);
};

export default AvatarLettersNotInSystem;
