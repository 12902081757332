import { createSelector, createSlice } from '@reduxjs/toolkit';
import { pageDescriptor, filterOperators } from '@sonar-web/common';
import FilterType from '@sonar-web/common/src/enums/FilterType';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import TcpService from '../TcpService';

const slice = 'tcpDeviceTypes';

export const tcpDeviceTypesSlice = createSlice({
	name: slice,
	initialState: {
		deviceType: null,
		deviceTypePending: false
	},
	reducers: {
		fetchSingleDeviceTypeSuccess: (state, { payload }) => {
			state.deviceType = payload.elements?.[0] || null;
			state.deviceTypePending = false;
		},
		fetchSingleDeviceTypePending: (state, { payload }) => {
			state.deviceTypePending = payload;
		},
		resetSingleDeviceType: (state) => {
			state.deviceType = null;
		}
	}
});

export const { fetchSingleDeviceTypeSuccess, fetchSingleDeviceTypePending, resetSingleDeviceType } =
	tcpDeviceTypesSlice.actions;

export const fetchSingleDeviceType = (deviceTypeName) => async (dispatch) => {
	try {
		dispatch(fetchSingleDeviceTypePending(true));

		const pd = {
			...pageDescriptor,
			FilterDescriptors: [
				{
					member: 'name',
					value: deviceTypeName,
					filterOperator: filterOperators.equal,
					filterType: FilterType.String.name
				}
			],
			SortDescriptors: []
		};

		const response = await TcpService.fetchTcpDeviceTypes(pd);
		dispatch(fetchSingleDeviceTypeSuccess(response));
	} catch (error) {
		dispatch(addErrorAsync({ slice, error }));
		fetchSingleDeviceTypePending(false);
	}
};

export const selectDeviceType = createSelector(
	(state) => state.tcpDeviceTypes,
	(deviceType) => ({
		deviceType: deviceType.deviceType,
		deviceTypePending: deviceType.deviceTypePending
	})
);

export default tcpDeviceTypesSlice.reducer;
