import moment from 'moment';
import { Box, RadioGroup, Typography } from '@mui/material';
import { useLocale } from '@sonar-web/common/src/hooks';
import { DateRangeOptions } from '@sonar-web/common/src/features/DateRange/DateRangeOptions';
import DateRangeOwn from '@sonar-web/common/src/features/DateRange/DateRangeOwn';
import FiltersDateRangeTile from './FiltersDateRangeTile';

const options = [DateRangeOptions.Days1, DateRangeOptions.Days7, DateRangeOptions.Days30, DateRangeOptions.Own];

const FiltersDateRange = ({ filters, setFilters }) => {
	const { translate } = useLocale();
	const dateRangeOption = filters.dateRangeOption;
	const dateRangeDate = filters.dateRangeDate;

	const handleChangeRange = (e) => {
		const value = e.target.value;
		if (value === dateRangeOption) return;
		setFilters({
			...filters,
			dateRangeOption: value,
			dateRangeDate: isOwn(value)
				? moment(dateRangeDate).set('hours', 0).set('minutes', 0).set('seconds', 0).toISOString()
				: getDateByDateRangeOption(value)
		});
	};

	const handleSetOwn = (date) =>
		setFilters({
			...filters,
			dateRangeDate: date
		});

	return (
		<Box display='flex' flexDirection='column' rowGap={1} className='filtersSection'>
			<Typography variant='body2'>{translate('Stats_ShowReadsFrom')}</Typography>
			<RadioGroup value={dateRangeOption} onChange={handleChangeRange} className='filtersSectionItems'>
				{options.map((o) => {
					return (
						<FiltersDateRangeTile
							key={o.value}
							value={o.value}
							title={`${o.description}Filter`}
							dataat={o.name}
							selected={dateRangeOption === String(o.value)}
							selectedDateRangeDate={dateRangeDate}
						/>
					);
				})}
			</RadioGroup>
			<DateRangeOwn
				show={isOwn(dateRangeOption)}
				onChange={handleSetOwn}
				initialOwnValue={new Date(dateRangeDate)}
			/>
		</Box>
	);
};

export default FiltersDateRange;

const isOwn = (option) => option === String(DateRangeOptions.Own.value);

const getDateByDateRangeOption = (dateRangeOption) =>
	moment().subtract(dateRangeOption, 'days').set('seconds', 0).toISOString();
