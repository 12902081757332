import React from 'react';
import { TableListColumn } from '@sonar-web/common';
import DeviceNumberAndType from './DeviceNumberAndType';

const DeviceColumn = ({ number, type, deviceId }) => {
	return (
		<TableListColumn flex>
			<DeviceNumberAndType number={number} type={type} deviceId={deviceId} />
		</TableListColumn>
	);
};

export default DeviceColumn;
