import React from 'react';
import BaseIcon from './BaseIcon';

export default function WmbusDevicesIcon(props) {
	return (
		<BaseIcon
			{...props}
			renderMultiPath={({ primaryPathClass, primaryPathColor }) => (
				<path
					className={primaryPathClass}
					style={{ color: primaryPathColor }}
					d='m 6.0037198,3.5625348 c -1.34369,0 -2.44119,1.09752 -2.44119,2.44119 V 17.997825 c 0,1.34367 1.0975,2.43964 2.44119,2.43964 H 17.99627 c 1.34369,0 2.4412,-1.09597 2.4412,-2.43964 V 6.0037248 c 0,-1.34367 -1.09751,-2.44119 -2.4412,-2.44119 z m 0,0.99993 H 17.99627 c 0.807,0 1.44126,0.63431 1.44126,1.44126 V 17.997825 c 0,0.80696 -0.63426,1.43971 -1.44126,1.43971 h -2.49339 c 1.28082,-3.17589 0.4876,-6.81964 -2.00298,-9.17774 C 11.61746,8.4774848 9.0548398,7.7046548 6.5762998,8.0604448 c -0.68304,0.0981 -1.3597,0.28199 -2.01383,0.55397 v -2.61069 c 0,-0.80695 0.63425,-1.44126 1.44125,-1.44126 z m 1.67328,4.41162 c 1.86872,-0.0219 3.7190102,0.67103 5.1356102,2.0122802 2.21128,2.09365 2.90215,5.32948 1.73633,8.14266 l -0.12765,0.30851 h -2.68355 c 0.98307,-1.25163 1.32825,-2.90874 0.8971,-4.46692 -0.48995,-1.77063 -1.90476,-3.13761 -3.6912502,-3.56619 -1.54889,-0.37157 -3.16938,0.0128 -4.38112,1.00511 V 9.7094448 l 0.02532,-0.0124 c 0.9866,-0.47354 2.04091,-0.71068 3.08921,-0.72295 z m 0.0863,3.2907602 c 0.31451,1e-4 0.6319,0.0369 0.94568,0.11213 1.4344502,0.34412 2.5676702,1.43753 2.9610602,2.85926 0.3934,1.42172 -0.01511,2.94395 -1.06867,3.9765 l -0.22944,0.2248 H 6.0037198 c -0.807,0 -1.44125,-0.63275 -1.44125,-1.43971 v -4.18424 l 0.21084,-0.23151 c 0.77781,-0.8504 1.86677,-1.31761 2.98999,-1.31723 z m 5.2e-4,2.14922 c -1.04516,0 -1.90273,0.85911 -1.90273,1.90428 0,1.04515 0.85757,1.90221 1.90273,1.90221 1.04517,0 1.90428,-0.85706 1.90428,-1.90221 0,-1.04517 -0.85911,-1.90428 -1.90428,-1.90428 z m 0,0.99994 c 0.50473,0 0.90434,0.39961 0.90434,0.90434 0,0.50472 -0.39961,0.90227 -0.90434,0.90227 -0.50472,0 -0.90227,-0.39755 -0.90227,-0.90227 0,-0.50473 0.39755,-0.90434 0.90227,-0.90434 z'
				/>
			)}
		/>
	);
}
