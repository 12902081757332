import styled from '@emotion/styled';
import { LinearProgress, linearProgressClasses, Skeleton, Typography } from '@mui/material';
import { BaseTooltip, Spacer } from '@sonar-web/common';
import { useLocale } from '@sonar-web/common/src/hooks';

const Wrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: '2rem 1rem',
	border: `1px solid ${theme.palette.border.main}`,
	borderRadius: theme.shape.borderRadius,
	'& .icon': {
		fontSize: '2rem',
		marginBottom: '.5rem'
	},
	'& .title': {
		marginBottom: '.5rem'
	},
	'& span, p': {
		textAlign: 'center'
	}
}));

const Visualization = styled(LinearProgress)(({ theme }) => ({
	alignSelf: 'stretch',
	height: 8,
	borderRadius: 5,
	[`&.${linearProgressClasses.colorPrimary}`]: {
		backgroundColor: theme.palette.grey[200]
	},
	[`& .${linearProgressClasses.bar}`]: {
		borderRadius: 5,
		backgroundColor: theme.palette.primary.main
	}
}));

const LicenseItem = ({ pending, children, data }) => {
	const { translate } = useLocale();
	const { Icon, title, description, primaryData, secondaryData, visualizationData } = data || {};

	if (pending && !data) return <Skeleton variant='rectangular' height='300px' />;
	return (
		<Wrapper>
			{Icon && <Icon fontSize='m' className='icon' color='action' />}
			{title && (
				<Typography className='title' variant='h6'>
					{translate(title)}
				</Typography>
			)}
			{description && (
				<Typography variant='caption' color='textSecondary' minHeight='3rem'>
					{translate(description)}
				</Typography>
			)}
			{secondaryData && <Spacer />}
			{primaryData && (
				<>
					<br />
					<Typography variant='subtitle1'>{translate(primaryData.value)}</Typography>
					<Typography variant='caption' color='textSecondary'>
						{translate(primaryData.text)}
					</Typography>
				</>
			)}
			{secondaryData && (
				<>
					<br />
					<Typography variant='subtitle1'>{translate(secondaryData.value)}</Typography>
					<Typography variant='caption' color='textSecondary'>
						{translate(secondaryData.text)}
					</Typography>
				</>
			)}
			{visualizationData && (
				<>
					<br />
					<BaseTooltip text={visualizationData.tooltip ?? ''}>
						<Visualization
							variant='determinate'
							value={visualizationData.value ?? 0}
							sx={{ visibility: visualizationData.value ? 'visible' : 'hidden' }}
						/>
					</BaseTooltip>
				</>
			)}
			{children}
		</Wrapper>
	);
};

export default LicenseItem;
