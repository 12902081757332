import { PanelListItem, PanelListItemActions, PanelListItemText, PanelListItemActionRemove } from '@sonar-web/common';
import LocationNodeLevelAvatar from '@sonar-web/location/src/LocationNodeLevels/LocationNodeLevelAvatar';
import LocationNodeLevelName from '../../LocationNodeLevels/LocationNodeLevelName';

const LocationsSelectSelectedListItem = ({ row, action }) => {
	return (
		<PanelListItem
			id={row.id}
			row={row}
			renderFrontItem={(show) => (
				<>
					<LocationNodeLevelAvatar level={row.level} />
					<PanelListItemText
						primaryText={row.displayName}
						secondaryText={<LocationNodeLevelName locationNodeLevelId={row.locationNodeLevelId} />}
						secondaryTypographyProps={{ 'data-at-params': `LocationNodeLevelType|${row.level.type}` }}
						disableFlex={false}
					/>
					{action && (
						<PanelListItemActions show={show} shrink={false}>
							<PanelListItemActionRemove
								tooltip='Exports_RemoveLocationFromExport'
								dataat='removeSelectedLocation'
								onClick={() => action(row)}
							/>
						</PanelListItemActions>
					)}
				</>
			)}
		/>
	);
};

export default LocationsSelectSelectedListItem;
