import clsx from 'clsx';
import { Radio, FormControlLabel } from '@mui/material';
import { useLocale } from '@sonar-web/common/src/hooks';

const FiltersLayersTile = ({ title, value, selected }) => {
	const { translate } = useLocale();

	return (
		<FormControlLabel
			className={clsx('filtersSectionItem', { 'filtersSectionItem-selected': selected })}
			value={value}
			classes={{
				label: 'filtersSectionItemLabel'
			}}
			control={
				<Radio
					color='primary'
					size='small'
					data-at={selected ? 'radioSelected' : 'radioNotSelected'}
					sx={{
						padding: '4px',
						marginRight: '2px',
						'& svg': {
							fontSize: '1rem'
						}
					}}
				/>
			}
			label={translate(title)}
			data-at={title}
		/>
	);
};

export default FiltersLayersTile;
