import React from 'react';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '../../hooks';
import EmptyListIcon from '../../icons/EmptyListIcon';

const useStyles = makeStyles((theme) => ({
	textRoot: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		whiteSpace: 'break-spaces',
		textAlign: 'center'
	}
}));

const ComponentNoData = ({ show, text, icon, action, ...rest }) => {
	const classes = useStyles();
	const { translate } = useLocale();

	if (!show) return null;

	return (
		<Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' height={150} {...rest}>
			{icon ?? <EmptyListIcon fontSize='l' primaryPathClass='primaryColor' secondaryPathClass='secondaryColor' />}
			<Typography variant='body2' color='textSecondary' classes={{ root: classes.textRoot }}>
				{translate(text)}
			</Typography>
			{action ? action : null}
		</Box>
	);
};

export default ComponentNoData;
