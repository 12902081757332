import React from 'react';
import { List } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ShareUsersListItem from './ShareUsersListItem';

const useStyles = makeStyles(() => ({
	list: {
		maxHeight: 182,
		overflow: 'auto',
		padding: 0,
		marginTop: 16,
		'& :first-child': {
			marginTop: 0
		}
	}
}));

const ShareUsersList = ({ records, onDelete, disableSearch }) => {
	const classes = useStyles();

	return (
		<List dense classes={{ root: classes.list }}>
			{records.map((r, index) => {
				return <ShareUsersListItem key={index} {...r} onDelete={onDelete} disableSearch={disableSearch} />;
			})}
		</List>
	);
};

export default ShareUsersList;
