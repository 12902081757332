import { useSelector } from 'react-redux';
import { selectSectionsValidationStatus } from './deviceConfiguratorSlice';

export default () => {
	const sectionsValidationStatus = useSelector(selectSectionsValidationStatus);

	const getSectionValidationStatus = (section) => {
		return sectionsValidationStatus[section.name];
	};

	const isCurrentConfigurationModified = () => {
		for (const prop in sectionsValidationStatus) {
			if (sectionsValidationStatus[prop]?.isModifed) {
				return true;
			}
		}
		return false;
	};

	const isCurrentConfigurationValid = () => {
		for (const prop in sectionsValidationStatus) {
			if (sectionsValidationStatus[prop].isNotCompleted) return false;
		}
		return true;
	};

	return {
		getSectionValidationStatus,
		isCurrentConfigurationModified,
		isCurrentConfigurationValid
	};
};
