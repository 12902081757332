import React from 'react';
import BaseIcon from '../BaseIcon';

export default function BatteryIcon(props) {
	return (
		<BaseIcon {...props}>
			<path d='m 9.5053446,3.1026673 c -0.388689,0 -0.72554,0.336853 -0.72554,0.725544 v 1.434597 a 0.14835344,0.14835344 0 0 1 -0.148411,0.148407 h -0.730256 c -0.75661,0 -1.385119,0.624357 -1.385119,1.380417 V 19.512204 c 0,0.756059 0.629064,1.385129 1.385119,1.385129 h 8.1977104 c 0.75604,0 1.385133,-0.629071 1.385133,-1.385129 V 6.7916323 c 0,-0.75606 -0.62852,-1.380417 -1.385133,-1.380417 h -0.730253 a 0.14835344,0.14835344 0 0 1 -0.148414,-0.148407 v -1.434597 c 0,-0.389184 -0.334493,-0.725544 -0.723186,-0.725544 z m 0.334512,0.911641 h 4.3202844 a 0.14835344,0.14835344 0 0 1 0.1484,0.148405 v 1.100095 a 0.14835344,0.14835344 0 0 1 -0.1484,0.148407 H 9.8398566 a 0.14835344,0.14835344 0 0 1 -0.148411,-0.148407 v -1.100095 a 0.14835344,0.14835344 0 0 1 0.148411,-0.148405 z m -1.938719,2.303837 h 8.1977104 c 0.260347,0 0.473493,0.213132 0.473493,0.473487 V 19.512204 c 0,0.260354 -0.21192,0.475843 -0.473493,0.475843 H 7.9011376 c -0.261566,0 -0.473478,-0.215489 -0.473478,-0.475843 V 6.7916323 c 0,-0.260355 0.213129,-0.473487 0.473478,-0.473487 z m 1.425174,7.0834727 c -0.238687,1.38e-4 -0.455435,0.218314 -0.454639,0.456999 1.45e-4,0.238093 0.216556,0.454504 0.454639,0.454643 h 5.3049494 c 0.238094,-1.39e-4 0.454507,-0.21655 0.454654,-0.454643 7.86e-4,-0.238685 -0.21596,-0.456861 -0.454654,-0.456999 z m 0,3.092984 c -0.238083,1.39e-4 -0.454494,0.21655 -0.454639,0.454643 1.45e-4,0.238093 0.216556,0.454504 0.454639,0.454643 h 5.3049494 c 0.238094,-1.39e-4 0.454507,-0.21655 0.454654,-0.454643 -1.47e-4,-0.238093 -0.21656,-0.454504 -0.454654,-0.454643 z' />
		</BaseIcon>
	);
}
