import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { apiBase, PanelListItemActionBack, PanelListItemActionConfirm, VerticalSpacer } from '@sonar-web/common';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';

const slice = 'configurator';

const TemplateDelete = ({ record, setDeleteMode, props, onSuccess }) => {
	const dispatch = useDispatch();
	const [pending, setPending] = useState(false);

	const handleCancel = (e) => {
		e.stopPropagation();
		setDeleteMode(false);
	};

	const handleDelete = async (e) => {
		e.stopPropagation();

		try {
			setPending(true);
			await deleteTemplate(props, record);
			setPending(false);
			onSuccess();
		} catch (error) {
			dispatch(addErrorAsync({ slice, error }));
			setPending(false);
		}
	};

	return (
		<Box display='flex'>
			<PanelListItemActionBack onClick={handleCancel} disabled={pending} />
			<VerticalSpacer size={1} />
			<PanelListItemActionConfirm onClick={handleDelete} disabled={pending} />
		</Box>
	);
};

export default TemplateDelete;

async function deleteTemplate(props, record) {
	const { servicePath, templateDeleteEndpoint, templateDeleteEndpointPathParams } = props;

	let url = templateDeleteEndpoint;

	if (templateDeleteEndpointPathParams?.length > 0)
		templateDeleteEndpointPathParams.forEach((ep) => {
			url = url.replace(`{${ep}}`, `${record[ep]}`);
		});

	const { data } = await apiBase(url, 'delete', {
		path: servicePath
	});

	return data;
}
