import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { Tabs, Tab, Box, useTheme, Fade } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { selectCoverMode, selectSidebar } from './sidebarSlice';
import { TabPanelBase } from '../../components';
import Blank from './Blank';
import { usePrevious } from '../../hooks';
import SidebarContext from './sidebarContext';

const useStyles = makeStyles((theme) => ({
	hidden: {
		display: 'none'
	},
	root: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.short
		})
	},
	rootShow: {
		padding: theme.spacing(0, 0, 0, 5),
		marginLeft: theme.spacing(5),
		borderLeft: `1px solid ${theme.palette.divider}`,
		flex: 2
	},
	rootHidden: {
		overflowY: 'hidden'
	},
	standalone: {
		padding: 0,
		margin: 0,
		borderLeft: 'none'
	}
}));

const Sidebar = ({ type, content, standalone }) => {
	const classes = useStyles();
	const theme = useTheme();
	const { coverComponent } = useContext(SidebarContext);

	const [renderContent, setRenderContent] = useState(false);
	const [tabValue, setTabValue] = useState(0);
	const [sidebarProps, setSidebarProps] = useState(null);

	const coverMode = useSelector(selectCoverMode);
	const isCoverMode = coverMode === true || coverMode === type;

	const sidebarState = useSelector(selectSidebar);
	const sidebarInstance = sidebarState[type];
	const previousSidebarInstance = usePrevious(sidebarInstance);
	const isOpen = sidebarInstance != null;
	const allTabs = [
		{
			id: 0,
			name: 'Blank',
			component: Blank
		}
	].concat(content);

	const handleChange = (event, newValue) => setTabValue(newValue);

	const setCurrentView = useCallback((view) =>
		setTabValue(view === false ? false : view === 0 ? 0 : sidebarInstance.id)
	);

	const toggleContent = useCallback((state) => {
		setTimeout(() => setRenderContent(state), theme.transitions.duration.short);
	});

	useEffect(() => {
		if (sidebarInstance && sidebarInstance.id != null) {
			if (previousSidebarInstance && previousSidebarInstance.id !== sidebarInstance.id) {
				setCurrentView(0);
				setSidebarProps(null);
			}

			setTimeout(() => {
				setSidebarProps(isOpen ? sidebarInstance.props : null);
				setCurrentView();
			}, 20);

			return;
		}
		setCurrentView(0);
	}, [sidebarInstance]);

	useEffect(() => {
		if (!isOpen && renderContent) {
			toggleContent(false);
			return;
		}
		if (!isOpen) return;
		toggleContent(true);
	}, [isOpen]);

	return (
		<Box
			data-at='Sidebar'
			width={0}
			minHeight={0}
			display='flex'
			flexDirection='column'
			className={clsx(classes.root, {
				[classes.rootShow]: isOpen,
				[classes.rootHidden]: !isOpen,
				[classes.standalone]: standalone
			})}>
			{renderContent && (
				<Fade in={!isCoverMode} style={{ height: '100%' }}>
					<Box display={!isCoverMode ? 'initial' : 'none'}>
						<Tabs value={false} onChange={handleChange} indicatorColor='primary' className={classes.hidden}>
							{allTabs.map((tab) => (
								<Tab key={tab.id} value={tab.id} />
							))}
						</Tabs>
						{allTabs.map((tab) => {
							const Component = tab.component;

							return (
								<TabPanelBase
									key={tab.id}
									value={tabValue}
									index={tab.id}
									disableInnerTopPadding
									disableRemoveFromDOM={tab.disableRemoveFromDOM}>
									<Component {...sidebarProps} />
								</TabPanelBase>
							);
						})}
					</Box>
				</Fade>
			)}
			<Fade in={isCoverMode} mountOnEnter unmountOnExit style={{ height: '100%' }}>
				<Box position='relative' height='100%'>
					<Box
						position='absolute'
						width='100%'
						height='100%'
						zIndex={theme.zIndex.drawer}
						display='flex'
						flexDirection='column'
						overflow='auto'
						style={{ backgroundColor: theme.palette.background.paper }}>
						{isCoverMode && coverComponent}
					</Box>
				</Box>
			</Fade>
		</Box>
	);
};

export default Sidebar;
