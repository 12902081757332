import React, { useState } from 'react';
import { FormControl, FormControlLabel, Slider, Radio, RadioGroup } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '../../../../hooks';

const useStyles = makeStyles((theme) => ({
	formControlRoot: {
		marginLeft: theme.spacing(1)
	},
	formControlLabelRoot: {
		marginRight: 0,
		marginLeft: -12,
		alignSelf: 'flex-start'
	},
	radioRoot: {
		padding: 4,
		'& svg': {
			fontSize: '1rem'
		}
	},
	sliderRoot: {
		marginLeft: -6
	}
}));

const ConfiguratorRadioSlider = ({ name, value, form, element }) => {
	const classes = useStyles();
	const { translate } = useLocale();
	const { option, min, max, step } = element;
	const [sliderValue, setSliderValue] = useState(value);
	const [radioValue, setRadioValue] = React.useState(value != option.offValue ? option.onValue : option.offValue);

	const handleSliderChange = (e, newValue) => {
		setSliderValue(newValue);
		form.setFieldValue(name, newValue);
	};

	const handleRadioChange = (e) => {
		const newValue = e.target.value;

		setRadioValue(newValue);
		setSliderValue(newValue);
		form.setFieldValue(name, newValue);
	};

	return (
		<>
			<FormControl fullWidth margin='normal' classes={{ root: classes.formControlRoot }}>
				<RadioGroup value={radioValue} onChange={handleRadioChange}>
					<FormControlLabel
						value={option.offValue}
						control={<Radio color='primary' size='small' classes={{ root: classes.radioRoot }} />}
						label={translate(option.onLabel)}
						classes={{ root: classes.formControlLabelRoot }}
					/>
					<FormControlLabel
						value={option.onValue}
						control={<Radio color='primary' size='small' classes={{ root: classes.radioRoot }} />}
						label={translate(option.offLabel)}
						classes={{ root: classes.formControlLabelRoot }}
					/>
				</RadioGroup>
				{form.values[name] != option.offValue && (
					<Slider
						aria-labelledby={`${name}_slider`}
						valueLabelDisplay='auto'
						value={Number(sliderValue)}
						step={step ?? 1}
						min={min}
						max={max}
						onChange={handleSliderChange}
						classes={{
							root: classes.sliderRoot
						}}
					/>
				)}
			</FormControl>
		</>
	);
};

export default ConfiguratorRadioSlider;
