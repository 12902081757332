import React from 'react';
import { Typography } from '@mui/material';
import { useLocale } from '@sonar-web/common/src/hooks';

const ConfiguratorTitle = ({ text }) => {
	const { translate } = useLocale();

	return <Typography variant='h5'>{translate(text)}</Typography>;
};

export default ConfiguratorTitle;
