import { createSlice } from '@reduxjs/toolkit';
import { pageDescriptor, getSliceDescriptor } from '@sonar-web/common';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';

const slice = 'locationNodeReads';

export const locationNodeReadsSlice = createSlice({
	name: slice,
	initialState: {
		pageDescriptor,
		pending: false,
		totalCount: 0,
		hasMore: true,
		reset: false,
		LocationNodeReads: [],
		selected: null
	},
	reducers: {
		fetchLocationNodeReadsSucces: (state, action) => {
			const { elements, totalCount } = action.payload;

			state.LocationNodeReads =
				state.pageDescriptor.Offset === 0 ? elements : [...state.LocationNodeReads, ...elements];
			state.totalCount = totalCount;
			state.pending = false;
			state.reset = false;
			state.hasMore = totalCount > state.pageDescriptor.Offset + state.pageDescriptor.Limit;
		},
		fetchLocationNodeReadsPending: (state, action) => {
			state.pending = action.payload;
		},
		setResetAndReload: (state, action) => {
			state.reset = action.payload ?? true;
			state.pageDescriptor.Offset = 0;
			state.LocationNodeReads = [];
		},
		setOffset: (state, action) => {
			state.pageDescriptor.Offset = action.payload ? state.pageDescriptor.Offset + state.pageDescriptor.Limit : 0;
		},
		setSelected: (state, action) => {
			state.selected = action.payload;
		}
	}
});

export const {
	fetchLocationNodeReadsSucces,
	fetchLocationNodeReadsPending,
	setResetAndReload,
	setSelected,
	setOffset
} = locationNodeReadsSlice.actions;

export const fetchLocationNodeReadsAsync = (apiFunction, defaultFilters) => async (dispatch, getState) => {
	try {
		const pd = getSliceDescriptor(getState, slice, defaultFilters);

		dispatch(fetchLocationNodeReadsPending(true));
		const response = await apiFunction(pd);

		dispatch(fetchLocationNodeReadsSucces(response));
	} catch (error) {
		dispatch(fetchLocationNodeReadsPending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const selectLocationNodeReads = (state) => {
	return {
		dataRows: state.locationNodeReads.LocationNodeReads,
		pending: state.locationNodeReads.pending,
		hasMore: state.locationNodeReads.hasMore,
		reset: state.locationNodeReads.reset,
		totalCount: state.locationNodeReads.totalCount
	};
};

export const selectSelected = (state) => state.locationNodeReads.selected;

export default locationNodeReadsSlice.reducer;
