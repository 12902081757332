import { useDispatch, useSelector } from 'react-redux';
import {
	selectActiveStep,
	setActiveStep,
	selectSteps,
	editConfiguratorData,
	selectIsEditing,
	selectData,
	loadConfiguratorInitialData,
	updateValid,
	updateVisited,
	selectValid,
	selectVisited
} from './configuratorSlice';

export default () => {
	const dispatch = useDispatch();
	const steps = useSelector(selectSteps);
	const activeStep = useSelector(selectActiveStep);
	const isEditing = useSelector(selectIsEditing);
	const configuratorData = useSelector(selectData);
	const isValid = useSelector(selectValid);
	const visitedSteps = useSelector(selectVisited);

	//skok do wskaznego kroku - np. na potrzeby steppera
	const changeStep = (v) => dispatch(setActiveStep(v));

	//następny krok
	const nextStep = () => changeStep(activeStep + 1);

	//poprzedni krok
	const previousStep = () => changeStep(activeStep - 1);

	//edycja stanu walidacji kroku
	const updateStepValid = (stepIndex, value) => {
		dispatch(updateValid({ [stepIndex]: value }));
	};

	//edycja stanu odwiedzenia kroku
	const updateStepVisited = (stepIndex, value) => {
		dispatch(updateVisited({ [stepIndex]: value }));
	};

	//edycja wskazanych danych w konfiguratorze - merga na false aby nadpisać
	const updateConfiguratorData = (data, merge = true, nestedProperty = null) => {
		dispatch(editConfiguratorData({ data, merge, nestedProperty }));
	};

	//czyści dane konfiguratora
	const defaultConfiguratorData = () => dispatch(loadConfiguratorInitialData());

	//ładuje dane z szablonu do konfiguratora
	const templateConfiguratorData = (templateData, serializedProperties) => {
		const newData = Object.entries(templateData).reduce((acc, d) => {
			let propName = d[0];
			let propValue = d[1];

			if (propName === 'id') propName = 'templateId';

			acc[propName] = serializedProperties.includes(propName) ? JSON.parse(propValue) : propValue;

			return acc;
		}, {});

		//update roota danych konfiguratora, pusta nazwa property wskazuje na state.configurator.configurator
		dispatch(editConfiguratorData({ data: { '': { reportTypeId: configuratorData.reportTypeId, ...newData } } }));
	};

	return {
		isEditing,
		steps,
		activeStep,
		changeStep,
		nextStep,
		previousStep,
		isValid,
		visitedSteps,
		updateStepVisited,
		updateStepValid,
		updateConfiguratorData,
		defaultConfiguratorData,
		templateConfiguratorData,
		configuratorData
	};
};
