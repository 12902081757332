import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { LocationNodeLevelsLoader, LocationsSelect } from '@sonar-web/location';

const useStyles = makeStyles(() => ({
	wrapper: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		'& > *': {
			display: 'flex',
			flexDirection: 'column'
		}
	}
}));

const ReportLocations = ({ stepIndex, useConfigurator, configuratorData, props }) => {
	const classes = useStyles();
	const { updateConfiguratorData, updateStepValid, isValid, visitedSteps } = useConfigurator();

	const visited = visitedSteps[stepIndex];
	const columns = configuratorData.reportSettings?.columns;
	const column = columns.find((c) => c.columnName === props.columnName);
	const index = React.useMemo(() => columns.findIndex((c) => c.columnName === props.columnName));
	const filterValues = column?.filterValues ?? [];
	const locationsIds = React.useMemo(() => filterValues.map((fv) => fv.id), [filterValues.length]);

	const disableActionByLevelType = React.useCallback((levelTypes) => {
		return levelTypes.filter((lt) => lt.takeToDisplay === false).map((ltd) => ltd.id);
	}, []);

	const addLocation = (location) => {
		if (filterValues.some((fv) => fv.id === location.id)) return;

		const newLocation = { id: location.id, displayName: location.displayName };
		const newColumnState = {
			...column,
			filterValues: column.filterValues ? [...column.filterValues, newLocation] : [newLocation]
		};

		let newColumns = [...columns];
		newColumns[index] = newColumnState;

		updateConfiguratorData({ ['reportSettings']: { columns: newColumns } }, false, 'columns');
	};

	const removeLocation = (location) => {
		const filterValuesIndex = filterValues.findIndex((fv) => fv.id === location.id);
		let newFilterValue = [...filterValues];

		newFilterValue.splice(filterValuesIndex, 1);

		const newColumnState = {
			...column,
			filterValues: newFilterValue
		};

		if (newColumnState.filterValues.length === 0) delete newColumnState.filterValues;

		let newColumns = [...columns];
		newColumns[index] = newColumnState;

		updateConfiguratorData({ ['reportSettings']: { columns: newColumns } }, false, 'columns');
	};

	useEffect(() => {
		if (!visited || !isValid[stepIndex]) updateStepValid(stepIndex, true);
	}, [filterValues?.length, visited]);

	if (columns?.length === 0 || !column) return null;
	return (
		<LocationNodeLevelsLoader>
			{({ levelTypes }) => {
				return (
					<div className={classes.wrapper}>
						<LocationsSelect
							allProps={{
								tabTitle: 'Exports_AllLocationNodes',
								data: locationsIds,
								action: addLocation,
								disableActionByLevelType: disableActionByLevelType(levelTypes)
							}}
							selectedProps={{
								tabTitle: 'Exports_Selected',
								data: locationsIds,
								action: removeLocation
							}}
						/>
					</div>
				);
			}}
		</LocationNodeLevelsLoader>
	);
};

export default ReportLocations;
