import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { DateRangeOptions } from '@sonar-web/common/src/features/DateRange/DateRangeOptions';
import { formatDateTime } from '@sonar-web/common';
import { statsLocationSlice } from '../statsLocationSlice';
import { selectVisualizationType } from './buildingVisualizationActions';

const { updateVisualizationType } = statsLocationSlice.actions;

export default () => {
	const dispatch = useDispatch();
	const visualizationType = useSelector(selectVisualizationType);

	const getDateForOption = (value) => {
		if (value === DateRangeOptions.Own.value) return null;
		return moment().subtract(value, 'days').set('seconds', 0).set('milliseconds', 0).toISOString();
	};

	const loadInitialValues = ({ dateRange }) => {
		let option = {
			...Object.entries(DateRangeOptions)
				.map((dro) => dro[1])
				.find((dro) => dro.value === dateRange.dateRangeOption)
		};

		if (option.value === DateRangeOptions.Own.value) option.label = formatDateTime(dateRange.dateRangeDate, 'date');
	};

	const setVisualizationType = (data) => dispatch(updateVisualizationType(data));

	return {
		getDateForOption,
		loadInitialValues,
		visualizationType,
		setVisualizationType
	};
};
