import { createSelector, createSlice } from '@reduxjs/toolkit';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import { slices } from '../Constants/Module';
import { fetchContractors } from '../OrdersService';

const slice = slices.contractors;
const initial = {
	contractors: [],
	contractorsFetchPending: false
};

export const contractorsSlice = createSlice({
	name: slice,
	initialState: {
		...initial
	},
	reducers: {
		fetchContractorsPending: (state, { payload }) => {
			state.contractorsFetchPending = payload;
		},
		fetchContractorsSuccess: (state, { payload }) => {
			state.contractors = payload;
			state.contractorsFetchPending = false;
		}
	}
});

export const { fetchContractorsPending, fetchContractorsSuccess } = contractorsSlice.actions;

export const fetchContractorsAsync = () => async (dispatch) => {
	try {
		dispatch(fetchContractorsPending(true));
		const response = await fetchContractors();
		dispatch(fetchContractorsSuccess(response));
	} catch (error) {
		dispatch(fetchContractorsPending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const selectContractors = createSelector(
	(state) => state.contractors,
	(contractors) => ({
		contractors: contractors.contractors,
		pending: contractors.contractorsFetchPending
	})
);

export default contractorsSlice.reducer;
