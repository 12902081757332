import { createSelector, createSlice } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';

export const filtersSlice = createSlice({
	name: 'filters',
	initialState: {},
	reducers: {
		addFilters: (state, action) => {
			const { slice, filtersData, append } = action.payload;
			const nextFilters = state[slice] ? Object.assign({}, state[slice]) : {};
			const filtersArray = [];

			filtersData.forEach((filter) => {
				if (Array.isArray(filter)) {
					filtersArray.push(...filter);
				} else {
					filtersArray.push(filter);
				}
			});

			filtersArray.forEach((fa) => {
				if (!nextFilters[fa.member]) {
					nextFilters[fa.member] = [fa];
				} else {
					if (fa.replace) nextFilters[fa.member] = [fa];
					else {
						let current = nextFilters[fa.member].slice(0);

						if (!append) {
							current = current.filter((c) => c.filterOperator !== fa.filterOperator);
						}

						const next = current.concat(fa);

						nextFilters[fa.member] = next;
					}
				}
			});

			state[slice] = nextFilters;
		},
		removeMemberAllFilters: (state, action) => {
			const { slice, member } = action.payload;

			if (!state[slice]) return;
			delete state[slice][member];

			if (Object.keys(state[slice]).length === 0) delete state[slice];
		},
		removeMemberSelectedFilters: (state, action) => {
			const { slice, filtersData, member } = action.payload;
			let current = state[slice][member].slice(0);

			filtersData.forEach((fd) => {
				current = current.filter((c) => !isEqual(c, fd));
			});

			if (current.length) {
				state[slice][member] = current;
			} else {
				delete state[slice][member];
				if (Object.keys(state[slice]).length === 0) delete state[slice];
			}
		},
		removeAllSliceFilters: (state, action) => {
			delete state[action.payload];
		}
	}
});

export const { addFilters, removeMemberAllFilters, removeMemberSelectedFilters, removeAllSliceFilters } =
	filtersSlice.actions;

//Selectors
export const selectFilterByName = (name) =>
	createSelector(
		(state) => state.filters,
		(filters) => filters[name]
	);

export default filtersSlice.reducer;
