import React from 'react';
import { ListItemIcon, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '../../hooks';
import BaseTooltip from '../BaseTooltip/BaseTooltip';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
	listItemIconRoot: {
		minWidth: 32,
		justifyContent: 'center'
	},
	linkRoot: {
		display: 'flex'
	}
}));

const PanelListItemAction = ({ icon, tooltip, onClick, renderLink, disabled = false, ...rest }) => {
	const classes = useStyles();
	const { translate } = useLocale();
	const { dataat, iconButtonProps = {} } = rest;

	return (
		<BaseTooltip text={translate(tooltip)} disableInteractive>
			<ListItemIcon classes={{ root: classes.listItemIconRoot }} data-at={dataat}>
				{renderLink ? (
					<Link to={onClick()} target='_blank' className={classes.linkRoot}>
						<IconButton size='small'>{icon}</IconButton>
					</Link>
				) : (
					<IconButton disabled={disabled} size='small' onClick={onClick} {...iconButtonProps}>
						{icon}
					</IconButton>
				)}
			</ListItemIcon>
		</BaseTooltip>
	);
};

export default PanelListItemAction;
