import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import { useLocale } from '@sonar-web/common/src/hooks';

const FileInput = ({ inputProps, open }) => {
	const { translate } = useLocale();

	return (
		<Box mt='1rem' mb='2rem'>
			<input {...inputProps({ multiple: false })} />
			<label htmlFor='contained-button-file'>
				<Button variant='contained' color='primary' component='span' size='small' onClick={open}>
					{translate('SelectFromDisk')}
				</Button>
			</label>
		</Box>
	);
};

export default FileInput;
