import { apiBase } from '@sonar-web/common';
import { REST_API_PATHS } from '@sonar-web/common/src/constants/system';

const path = REST_API_PATHS.STATS;

export async function fetchBuildingVisualization(params) {
	const { data } = await apiBase(`building/visualization`, 'get', {
		path,
		params
	});

	return data;
}
