import { createSlice } from '@reduxjs/toolkit';
import { moveLocationNode } from './api';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import { setResetAndReload } from '../LocationNodes/slice';

const slice = 'locationNodeMove';

export const locationNodeMoveSlice = createSlice({
	name: slice,
	initialState: {
		error: null,
		pending: false,
		locationNode: null,
		movePending: false,
		moveSuccess: false
	},
	reducers: {
		moveLocationNodePending: (state, action) => {
			state.movePending = action.payload ?? true;
		},
		moveLocationNodeSuccess: (state, action) => {
			state.moveSuccess = action.payload ?? true;
			state.movePending = false;
		}
	}
});

export const { moveLocationNodePending, moveLocationNodeSuccess } = locationNodeMoveSlice.actions;

export const moveLocationNodeAsync = (node) => async (dispatch, getState) => {
	const parentNode = getState().locationNodesMove.selectedBreadcrumb;
	const selectedNodeToMoveTo = getState().locationNodesMove.selectedNodeToMoveTo;
	const parentLocationNodeId = selectedNodeToMoveTo !== null ? selectedNodeToMoveTo.id : parentNode.id;
	const nodeData = { id: node.id, parentLocationNodeId };

	try {
		dispatch(moveLocationNodePending());
		await moveLocationNode(nodeData);
	} catch (error) {
		const errorResult = dispatch(addErrorAsync({ slice, error }));
		dispatch(moveLocationNodePending(false));
		return errorResult;
	}

	dispatch(moveLocationNodeSuccess());
	setTimeout(() => dispatch(setResetAndReload()), 400);
};

export const selectLocationNode = (state) => {
	return {
		dataRow: state.locationNode.locationNode,
		pending: state.locationNode.pending
	};
};
export const selectMove = (state) => {
	return {
		movePending: state.locationNode.movePending,
		moveSuccess: state.locationNode.moveSuccess
	};
};

export default locationNodeMoveSlice.reducer;
