import { filterOperators } from '@sonar-web/common';
import { useFilterDescriptor } from '@sonar-web/common/src/hooks';
import { fetchWatermeterTypes } from '../WatermeterTypesSearch/api';
import { addWatermeter } from '../Watermeter/watermeterApi';

export default () => {
	const { createFilter } = useFilterDescriptor();

	const addDeviceNew = (values) =>
		addWatermeter({
			number: values.number,
			watermeterTypeId: values.deviceTypeId
		});

	const fetchDeviceTypeById = (deviceTypeId) => {
		const pageDescriptor = {
			Offset: 0,
			SortDescriptors: [],
			IncludeTotalRowCount: true,
			FilterDescriptors: createFilter([
				{
					member: { name: 'id', type: 'guid' },
					value: deviceTypeId,
					operator: filterOperators.equal
				}
			])
		};

		return fetchWatermeterTypes(pageDescriptor);
	};

	return {
		addDevice: addDeviceNew,
		fetchDeviceTypeById: fetchDeviceTypeById
	};
};
