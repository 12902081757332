import React from 'react';
import clsx from 'clsx';
import { Typography, ListItemAvatar, Avatar, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ListItemTextBase, PanelListItem, formatDateTimeSecondsPrecision } from '@sonar-web/common';
import { useLocale } from '@sonar-web/common/src/hooks';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import TerminalTransmissionData from './TransmissionData/TerminalTransmissionData';

const useStyles = makeStyles((theme) => ({
	primaryText: {
		color: theme.palette.text.primary,
		fontWeight: theme.typography.fontWeightMedium
	},
	listItemAvatarRoot: {
		alignSelf: 'flex-start',
		marginTop: 6
	},
	avatarRoot: {
		color: theme.palette.text.secondary
	},
	dotIconReadOk: {
		color: 'green'
	},
	dotIconReadNotOk: {
		color: 'red'
	}
}));

const TerminalDiagnosticReadsListItem = ({ row, selected }) => {
	const classes = useStyles();
	const { translate } = useLocale();

	const [isExpanded, setIsExpanded] = React.useState(false);

	const handleClick = () => setIsExpanded(!isExpanded);

	const isSuccessfullRead = row.isSuccessfullRead;
	return (
		<PanelListItem
			row={row}
			onClick={handleClick}
			selected={selected}
			hoverBehavior={false}
			renderFrontItem={() => {
				return (
					<>
						<ListItemAvatar classes={{ root: classes.listItemAvatarRoot }}>
							<Avatar classes={{ root: classes.avatarRoot }}>
								<FiberManualRecordIcon
									classes={{
										root: clsx({
											[classes.dotIconReadOk]: isSuccessfullRead,
											[classes.dotIconReadNotOk]: !isSuccessfullRead
										})
									}}></FiberManualRecordIcon>
							</Avatar>
						</ListItemAvatar>
						<ListItemTextBase disableTypography={true} hideBorder={true}>
							<Box display='flex' margin='1px 0 2px 0' flexDirection='column'>
								<Box justifyContent='flex-start' flexGrow={1} flexDirection='row'>
									<Typography
										variant='body2'
										classes={{
											root: classes.primaryText
										}}>
										{formatDateTimeSecondsPrecision(row.terminalReadDate ?? row.networkReadDate)}{' '}
										{translate(
											isSuccessfullRead
												? 'Wmbus_DiagnosticReadSuccessull'
												: 'Wmbus_DiagnosticReadFailed'
										)}
									</Typography>
									<TerminalTransmissionData row={row} />
								</Box>
							</Box>
						</ListItemTextBase>
					</>
				);
			}}
		/>
	);
};

export default TerminalDiagnosticReadsListItem;
