export default {
	Connected: {
		value: 0,
		name: 'Connected'
	},
	Disconnected: {
		value: 1,
		name: 'Disconnected'
	}
};
