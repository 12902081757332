import {
	setErrorReason,
	getInvalidDataErrorReason,
	getCombinedInvalidDataErrorReason,
	billingErrors,
	isErrorCode
} from './billingErrorHelpers';

const businessErrorCodes = {
	CustomerCannotBeDeleted: 0x01,
	CustomerNameMustBeUnique: 0x02,
	CustomerNotFound: 0x03,
	SettlementNameMustBeUnique: 0x101,
	SettlementCannotBeDeleted: 0x102,
	SettlementHasError: 0x0103,
	SettlementIsProgress: 0x0104,
	SettlementCompleted: 0x0105,
	SettlementAproveInvalidState: 0x106,
	SettlementGenerateReportsPackageInvalidState: 0x107,
	FlatReportsMustExists: 0x201,
	FlatReportDefinitionNameMustBeUnique: 0x202,
	HeatNodeReportDefinitionNameMustBeUnique: 0x301,
	HeatNodeReportsMustExists: 0x302
};

const getExternalApiErrorReason = ({ error }) => {
	const data = error.response.data;

	if (!isErrorCode({ code: 'external_service_error', error })) {
		return null;
	}
	if (!data.details) {
		return billingErrors.unknownExternalApiError;
	}
	for (const key in businessErrorCodes) {
		const errorStr = businessErrorCodes[key].toString() + ';';

		if (data.details.startsWith(errorStr)) {
			return `:Billing_${key}`;
		}
	}

	if (data.details.includes('Settement not found')) {
		return `:Billing_SettlementNotFound`;
	}
	if (data.details.includes('Internal Server Error')) {
		return `:Billing_InternalServerError`;
	}
	if (data.details.includes('Report not found')) {
		return `:Billing_ReportNotFound`;
	}
	return billingErrors.unknownExternalApiError;
};

export const parseError = (error) => {
	try {
		if (!error.response || !error.response.data) {
			return setErrorReason(error, billingErrors.unknownError);
		}
		if (error.response.data.reason?.includes('Billing_')) {
			return error;
		}

		const businessErrorReason = getExternalApiErrorReason({ error });
		if (businessErrorReason) {
			return setErrorReason(error, businessErrorReason);
		}

		const invalid_client_data = getCombinedInvalidDataErrorReason({ code: 'invalid_client_data', error });

		if (invalid_client_data) {
			return setErrorReason(error, invalid_client_data);
		}

		const invalid_billing_data = getInvalidDataErrorReason({ code: 'invalid_billing_data', error });

		if (invalid_billing_data) {
			return setErrorReason(error, invalid_billing_data);
		}

		const invalid_settlement_data = getCombinedInvalidDataErrorReason({ code: 'invalid_settlement_data', error });

		if (invalid_settlement_data) {
			return setErrorReason(error, invalid_settlement_data);
		}

		const billing_period_delete_error = getInvalidDataErrorReason({ code: 'billing_period_delete_error', error });

		if (billing_period_delete_error) {
			return setErrorReason(error, billing_period_delete_error);
		}

		const billing_period_name_exists = getInvalidDataErrorReason({ code: 'billing_period_name_exists', error });

		if (billing_period_name_exists) {
			return setErrorReason(error, billing_period_name_exists);
		}

		const billing_period_not_found = getInvalidDataErrorReason({ code: 'billing_period_not_found', error });

		if (billing_period_not_found) {
			return setErrorReason(error, billing_period_not_found);
		}

		const not_allowed_client_image_format = getInvalidDataErrorReason({
			code: 'not_allowed_client_image_format',
			error
		});

		if (not_allowed_client_image_format) {
			return setErrorReason(error, not_allowed_client_image_format);
		}

		const client_image_not_found = getInvalidDataErrorReason({ code: 'client_image_not_found', error });

		if (client_image_not_found) {
			return setErrorReason(error, client_image_not_found);
		}

		const client_not_found = getInvalidDataErrorReason({ code: 'client_not_found', error });

		if (client_not_found) {
			return setErrorReason(error, client_not_found);
		}

		const file_service_error = getInvalidDataErrorReason({ code: 'file_service_error', error });

		if (file_service_error) {
			return setErrorReason(error, file_service_error);
		}

		const invalid_request_data = getCombinedInvalidDataErrorReason({ code: 'invalid_request_data', error });

		if (invalid_request_data) {
			return setErrorReason(error, invalid_request_data);
		}

		const settlement_name_is_in_use = getInvalidDataErrorReason({ code: 'settlement_name_is_in_use', error });

		if (settlement_name_is_in_use) {
			return setErrorReason(error, settlement_name_is_in_use);
		}

		const settlement_not_found = getInvalidDataErrorReason({ code: 'settlement_not_found', error });

		if (settlement_not_found) {
			return setErrorReason(error, settlement_not_found);
		}

		const settlement_with_given_name_exist = getInvalidDataErrorReason({
			code: 'settlement_with_given_name_exist',
			error
		});

		if (settlement_with_given_name_exist) {
			return setErrorReason(error, settlement_with_given_name_exist);
		}

		if (isErrorCode({ code: 'db_error', error })) {
			return setErrorReason(error, 'Billing_DatabaseError');
		}
	} catch (ex) {
		console.log('parseError exception: ');
		console.log(ex);
	}

	return setErrorReason(error, billingErrors.unknownError);
};
