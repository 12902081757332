import FiltersControlTextSingle from './FiltersControlTextSingle';
import FiltersControlAutocompleteSingle from './FiltersControlAutocompleteSingle';
import FiltersControlDate from './FiltersControlDate';
import FiltersControlComboSingle from './FiltersControlComboSingle';
import FiltersControlNumberRange from './FiltersControlNumberRange';
import FiltersControlAutocompleteMulti from './FiltersControlAutocompleteMulti';
import FiltersControlAutocomplete from './FiltersControlAutocomplete';
import FiltersControlComboMulti from './FiltersControlComboMulti';

const FiltersControls = {
	textSingle: FiltersControlTextSingle,
	autocompleteSingle: FiltersControlAutocompleteSingle,
	date: FiltersControlDate,
	comboSingle: FiltersControlComboSingle,
	comboMulti: FiltersControlComboMulti,
	numberRange: FiltersControlNumberRange,
	autocompleteMulti: FiltersControlAutocompleteMulti,
	autocomplete: FiltersControlAutocomplete
};

export default FiltersControls;
