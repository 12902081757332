const TimeLicenseStatus = {
	Ok: {
		value: 0,
		name: 'Ok'
	},
	Expiring: {
		value: 1,
		name: 'Expiring'
	},
	Expired: {
		value: 2,
		name: 'Expired'
	}
};

export default TimeLicenseStatus;
