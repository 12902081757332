import React from 'react';
import CardStatusItem from '../../../Cards/CardStatusItem';
import { colors } from '@mui/material';

const status = {
	changed: {
		text: 'Common_Configurator_ConfigurationHasChanged',
		color: colors.red[700]
	},
	required: {
		text: 'Common_Configurator_ConfigurationIsRequired',
		color: colors.grey[400]
	},
	changedDefault: {
		text: 'Common_Configurator_DefaultConfigurationHasChanged',
		color: colors.orange[400]
	}
};

const getStatusProps = ({ isAddNew, isModifed, isNotCompleted }) => {
	if (isNotCompleted) return status.required;
	if (isAddNew && isModifed && isNotCompleted) return status.changedDefault;
	if (isModifed) return status.changed;
};

const ConfigurationDataCardStatusItem = (props) => {
	const statusProps = getStatusProps(props);

	return statusProps ? <CardStatusItem text={statusProps.text} color={statusProps.color} /> : null;
};

export default ConfigurationDataCardStatusItem;
