import React, { createContext, useContext, useState } from 'react';

const PanelContentContext = createContext({});

export const usePanelContentContext = () => {
	return useContext(PanelContentContext);
};

export const PanelContentContextProvider = ({ children }) => {
	const [panelCover, setPanelCover] = useState(null);

	return (
		<PanelContentContext.Provider
			value={{
				panelCover,
				setPanelCover
			}}>
			{children}
		</PanelContentContext.Provider>
	);
};

export default PanelContentContext;
