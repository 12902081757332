const useRolesByTenant = (roles, tenant) => {
	if (!tenant || !roles) return roles;

	const tenantRoles = getTenantRoles(roles, tenant);

	if (typeof roles[0] === 'string') return tenantRoles.map((tr) => tr.slice(tr.indexOf('|') + 1));

	return tenantRoles.map((tr) => ({
		name: tr.name.slice(tr.name.indexOf('|') + 1),
		description: tr.description ? tr.description.slice(tr.description.indexOf('|') + 1) : null,
		id: tr.id
	}));
};

export default useRolesByTenant;

function getTenantRoles(roles, tenant) {
	if (typeof roles[0] === 'string') return roles.filter((r) => r.includes(tenant.name + '|'));
	return roles.filter((r) => r.name.includes(tenant.name + '|'));
}
