export default {
	FinishedWithError: {
		value: -1,
		name: 'FinishedWithError'
	},
	FinishedSuccessfull: {
		value: 0,
		name: 'FinishedSuccessfull'
	},
	WaitingForUserToApprove: {
		value: 1,
		name: 'WaitingForUserToApprove'
	},
	WaitingForUserToRunReportsPackage2: {
		value: 2,
		name: 'WaitingForUserToRunReportsPackage2'
	},
	WaitingForImportTaskToBegin: {
		value: 11,
		name: 'WaitingForImportTaskToBegin'
	},
	Importing: {
		value: 12,
		name: 'Importing'
	},
	WaitingForCheckingConvergencyToBegin: {
		value: 13,
		name: 'WaitingForCheckingConvergencyToBegin'
	},
	CheckingConvergency: {
		value: 14,
		name: 'CheckingConvergency'
	},
	Status15: {
		value: 15,
		name: 'Status15'
	},
	Calculating: {
		value: 16,
		name: 'Calculating'
	},
	WaitingForReportsPackage1ToBegin: {
		value: 17,
		name: 'WaitingForReportsPackage1ToBegin'
	},
	GeneratingReportsPackage1: {
		value: 18,
		name: 'GeneratingReportsPackage1'
	},
	WaitingForRunReportsPackage2ToBegin: {
		value: 21,
		name: 'WaitingForRunReportsPackage2ToBegin'
	},
	GeneratingReportsPackage2: {
		value: 22,
		name: 'GeneratingReportsPackage2'
	},
	WildStatusAppeared: {
		value: 255,
		name: 'WildStatusAppeared'
	},
	IncompleteBilling: {
		value: -999,
		name: 'IncompleteBilling'
	}
};
