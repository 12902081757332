import { BaseTooltip, CloseIcon } from '@sonar-web/common';
import { IconButton } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
	buttonRoot: {
		color: '#FFFFFF'
	}
}));

const RemindButton = ({ onClick }) => {
	const classes = useStyles();

	return (
		<BaseTooltip text='Common_LicenseRemindLater' placement='left'>
			<IconButton size='small' onClick={onClick} classes={{ root: classes.buttonRoot }}>
				<CloseIcon fontSize='smaller' />
			</IconButton>
		</BaseTooltip>
	);
};

export default RemindButton;
