import React from 'react';
import PanelListItemAction from './PanelListItemAction';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

const PanelListItemActionAdd = ({ onClick, tooltip, ...rest }) => {
	return (
		<PanelListItemAction
			icon={<AddCircleOutlineOutlinedIcon fontSize='small' />}
			tooltip={tooltip ?? 'Common_Add'}
			onClick={onClick}
			{...rest}
		/>
	);
};

export default PanelListItemActionAdd;
