import React from 'react';

const Actions = ({ actions }) => {
	if (!actions) return null;

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				gap: '0.5rem'
			}}>
			{actions.map((Action, index) => (
				<React.Fragment key={index}>
					<Action />
				</React.Fragment>
			))}
		</div>
	);
};

export default Actions;
