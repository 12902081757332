import { filterOperators } from '@sonar-web/common';
import LocationsSelectAllListItem from './LocationsSelectAllListItem';
import LocationNodesBreadcrumb from '../../LocationNodes/LocationNodesBreadcrumb';
import LocationsSelectList from '../LocationsSelectList';

const LocationsSelectAll = ({
	data,
	action,
	disableActionByLevelType = [],
	filterMemeber = { name: 'id', type: 'guid' }
}) => {
	const filters = (locations) => [
		{
			member: filterMemeber,
			value: locations.join(';'),
			operator: filterOperators.notIn
		}
	];

	return (
		<>
			<LocationNodesBreadcrumb isPanel={false} />
			<LocationsSelectList
				selectedLocations={data}
				listDefaultFilters={filters}
				listItem={(row) => (
					<LocationsSelectAllListItem
						row={row}
						action={disableActionByLevelType.includes(row.locationNodeLevelId) ? null : action}
					/>
				)}
			/>
		</>
	);
};

export default LocationsSelectAll;
