import { useState } from 'react';
import { Fade, Box, Typography } from '@mui/material';
import { formatDateTime } from '../../../../../helpers/date';
import EventIcon from '@mui/icons-material/Event';
import BaseIconButton from '../../../../../components/Buttons/BaseIconButton';
import CustomTimePicker from './CustomTimePicker';
import ButtonOnlyIconDelete from '@sonar-web/common/src/components/Buttons/ButtonOnlyIconDelete';

const TimeItem = ({ value, onChange, onRemove, minutesOnly = false }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [show, setShow] = useState(false);

	const handleOnMouseOver = () => {
		if (!show) setShow(true);
	};
	const handleOnMouseLeave = () => {
		setShow(false);
	};

	const handleOnRemove = () => {
		setTimeout(() => {
			onRemove();
		}, 100);
	};

	return (
		<Box>
			<Box position='relative' style={{ height: 25 }}>
				<Box display='flex' flexDirection='row' position='absolute'>
					<Fade in={show} mountOnEnter unmountOnExit timeout={300}>
						<Box
							onMouseLeave={handleOnMouseLeave}
							position='absolute'
							top={0}
							left={0}
							width='100%'
							height='100%'
							sx={{
								backgroundColor: '#ffffffB0',
								zIndex: 'drawer'
							}}
							display='flex'
							justifyContent='center'>
							<ButtonOnlyIconDelete
								onClick={handleOnRemove}
								size='small'
								sx={(theme) => ({
									backgroundColor: '#ffffffB0',
									'& svg': {
										fill: theme.palette.secondary.main
									}
								})}
								style={{
									boxShadow: 'none',
									backgroundColor: '#ffffffB0',
									padding: 3
								}}
							/>
						</Box>
					</Fade>
					<Typography
						variant='body1'
						onMouseOver={handleOnMouseOver}
						style={{
							marginRight: -8
						}}>
						{formatDateTime(value, minutesOnly ? 'timeminutes' : 'time')}
					</Typography>
					<BaseIconButton
						icon={EventIcon}
						iconProps={{
							color: 'primary'
						}}
						onClick={() => setIsOpen(true)}
						style={{
							marginLeft: '10px'
						}}
					/>
				</Box>
			</Box>
			<CustomTimePicker
				value={value}
				onChange={onChange}
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				minutesOnly={minutesOnly}
			/>
		</Box>
	);
};

export default TimeItem;
