import { createSlice } from '@reduxjs/toolkit';
import { fetchProfiles, fetchProfile, addProfile, editProfile } from './deviceConfigProfilesApi';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';

const slice = 'deviceConfigProfiles';

export const deviceConfigProfilesSlice = createSlice({
	name: slice,
	initialState: {
		fetchPending: false,
		systemProfiles: [],
		userProfiles: [],
		reload: false,
		reset: true,
		selected: null,
		fetchSinglePending: false,
		submitPending: false,
		submitSuccess: false
	},
	reducers: {
		fetchProfilesSuccess: (state, action) => {
			let profiles = [...action.payload.elements];

			let systemProfiles = [];
			let userProfiles = [];

			for (const p of profiles) {
				if (p.isSystemProfile === true) {
					systemProfiles.push(p);
				} else {
					userProfiles.push(p);
				}
			}
			state.systemProfiles = systemProfiles;
			state.userProfiles = userProfiles;
			state.fetchPending = false;
		},
		fetchProfilesPending: (state) => {
			state.fetchPending = true;
		},
		setResetAndReload: (state) => {
			state.profiles = [];
			state.reload = true;
			state.reset = true;
		},
		setReload: (state) => {
			state.reload = true;
		},
		setSelected: (state, action) => {
			state.selected = action.payload;
		},
		fetchProfilePending: (state, action) => {
			state.fetchSinglePending = action.payload;
		},
		setSubmitPending: (state, action) => {
			state.submitPending = action.payload;
		},
		setSubmitSuccess: (state, action) => {
			state.submitSuccess = action.payload;
			state.submitPending = false;
		}
	}
});

export const {
	fetchProfilesSuccess,
	fetchProfilesPending,
	setResetAndReload,
	setReload,
	setSelected,
	fetchProfilePending,
	setSubmitPending,
	setSubmitSuccess
} = deviceConfigProfilesSlice.actions;

export const fetchProfilesAsync = (params) => async (dispatch) => {
	try {
		dispatch(fetchProfilesPending());
		const response = await fetchProfiles(params);
		dispatch(fetchProfilesSuccess(response));
	} catch (error) {
		dispatch(fetchProfilesPending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const fetchProfileAsync = (params) => async (dispatch) => {
	try {
		dispatch(fetchProfilePending(params.profileId));
		const response = await fetchProfile(params);
		dispatch(fetchProfilePending(false));
		return response.profile;
	} catch (error) {
		dispatch(fetchProfilePending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const addProfileAsync = (params) => async (dispatch) => {
	try {
		dispatch(setSubmitPending(true));
		const response = await addProfile(params);
		dispatch(setSubmitSuccess(true));
		return response;
	} catch (error) {
		dispatch(setSubmitPending(false));
		return dispatch(addErrorAsync({ slice, error, skipNotificationMembers: ['name', 'description'] }));
	}
};

export const editProfileAsync = (params) => async (dispatch) => {
	try {
		dispatch(setSubmitPending(true));
		await editProfile(params);
		dispatch(setSubmitSuccess(true));
		return true;
	} catch (error) {
		dispatch(setSubmitPending(false));
		dispatch(addErrorAsync({ slice, error, skipNotificationMembers: ['name', 'description'] }));
	}
};

export const selectProfiles = (state) => {
	return {
		systemProfiles: state.deviceConfigProfiles.systemProfiles,
		userProfiles: state.deviceConfigProfiles.userProfiles,
		pending: state.deviceConfigProfiles.fetchPending,
		reload: state.deviceConfigProfiles.reload
	};
};

export const selectProfileSubmit = (state) => {
	return {
		pending: state.deviceConfigProfiles.submitPending,
		success: state.deviceConfigProfiles.submitSuccess
	};
};

export const selectProfileSingle = (state) => {
	return {
		pendingSingle: state.deviceConfigProfiles.fetchSinglePending
	};
};

export const selectSelected = (state) => state.deviceConfigProfiles.selected;

export default deviceConfigProfilesSlice.reducer;
