import React from 'react';
import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => {
	return {
		tooltip: {
			backgroundColor: theme.palette.primary.main,
			fontSize: theme.typography.pxToRem(10),
			fontWeight: theme.typography.fontWeightLight,
			color: 'white'
		},
		tooltipArrow: {
			color: theme.palette.primary.main
		}
	};
});
const TimelinePointTooltip = ({ text, children }) => {
	const classes = useStyles();

	return (
		<Tooltip
			title={text}
			arrow
			placement='bottom'
			classes={{ arrow: classes.tooltipArrow, tooltip: classes.tooltip }}>
			<div>{children}</div>
		</Tooltip>
	);
};

export default TimelinePointTooltip;
