import React from 'react';
import delete_scene from './delete_scene.svg';
import delete_scene_small from './delete_scene_small.svg';
import DeletePageContent from './DeletePageContent';
import ConfirmationPage from '../../components/Confirmation/ConfirmationPage';

const DeletePage = ({ onCancel, onSubmit, primaryText, primaryValue, pending, slices, children }) => {
	return (
		<ConfirmationPage
			background={delete_scene}
			horizontalBackground={delete_scene_small}
			data-at='deleteQ'
			slices={slices}>
			{(props) =>
				children ? (
					children(props)
				) : (
					<DeletePageContent
						onCancel={onCancel}
						onSubmit={onSubmit}
						primaryText={primaryText}
						primaryValue={primaryValue}
						pending={pending}
						{...props}
					/>
				)
			}
		</ConfirmationPage>
	);
};

export default DeletePage;
