import React from 'react';
import BaseIcon from './BaseIcon';

export default function WaterMeterIcon(props) {
	return (
		<BaseIcon {...props}>
			<path d='M 11.401159,3.3546695 C 10.19858,3.4379298 9.0201782,3.770939 7.942475,4.3420587 6.2888664,5.2183719 4.9632854,6.6069264 4.1640652,8.2991389 3.3648504,9.991361 3.1340198,11.896898 3.507686,13.730721 c 0.3736678,1.833725 1.3313165,3.496749 2.7289562,4.74135 1.3976396,1.244599 3.160687,2.003486 5.0253418,2.162852 1.864695,0.159367 3.730079,-0.28958 5.318738,-1.278904 1.588561,-0.989228 2.814967,-2.465313 3.494417,-4.2091 0.590474,-1.515282 0.740967,-3.162172 0.441974,-4.750753 -0.07492,-0.3980024 -0.482384,-0.6254867 -0.872664,-0.5172041 -0.390279,0.1082922 -0.615378,0.5129251 -0.547295,0.9121591 0.21849,1.281798 0.08609,2.603503 -0.389314,3.823548 -0.564476,1.448647 -1.582277,2.67443 -2.901984,3.496299 -1.319806,0.821869 -2.870634,1.195023 -4.419743,1.062618 C 9.8370133,19.041183 8.3719888,18.411484 7.2108667,17.377478 6.0497446,16.34357 5.255005,14.960792 4.9445733,13.437324 4.6341407,11.913858 4.8260216,10.33127 5.4899878,8.9254259 6.1539547,7.519582 7.2551774,6.3659005 8.6289458,5.6378897 9.785927,5.0247569 11.082578,4.7413563 12.381024,4.8103635 c 0.404427,0.021494 0.78008,-0.2487167 0.842573,-0.6488568 0.06249,-0.4001496 -0.211029,-0.778382 -0.615002,-0.8068372 -0.403129,-0.02841 -0.806577,-0.02775 -1.207436,0 z m 7.731728,0.2783496 c 1e-6,0 -8.180886,7.1418339 -8.835723,7.9799859 -0.6548378,0.838143 -0.507142,2.049707 0.33101,2.704507 0.838153,0.654894 2.04963,0.505347 2.704506,-0.332892 0.654799,-0.838142 5.800207,-10.3516009 5.800207,-10.3516009 z' />
		</BaseIcon>
	);
}
