import styled from '@emotion/styled';
import FetchLicensesData from './FetchLicensesData';
import LicenseRefresh from './LicenseRefresh';
import MaxDevices from './MaxDevices';
import Time from './Time';
import LicensesHeader from './LicensesHeader';
import Billing from './Billing';
import Networks from './Networks';
import Reports from './Reports';
import Exports from './Exports';

const LicensesGrid = styled('div')({
	display: 'grid',
	gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
	gridGap: '1rem',
	alignItems: 'stretch'
});

const Licenses = () => {
	return (
		<>
			<LicensesHeader />
			<LicenseRefresh />
			<br />
			<FetchLicensesData>
				{(fetchResult) => (
					<LicensesGrid>
						<Time {...fetchResult} />
						<MaxDevices {...fetchResult} />
						<Exports {...fetchResult} />
						<Reports {...fetchResult} />
						<Networks {...fetchResult} />
						<Billing {...fetchResult} />
					</LicensesGrid>
				)}
			</FetchLicensesData>
		</>
	);
};

export default Licenses;
