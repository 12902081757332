import { useEffect } from 'react';
import { useFetchOnDemand } from '@sonar-web/common/src/hooks';
import TenantsService from '../TenantsService';
import Slices from '../Constants/Slices';

const slice = Slices.tenant;

const FetchServersAddressesData = ({ children }) => {
	const { fetchFunc, data, pending } = useFetchOnDemand({
		api: () => TenantsService.fetchServerAddresses(),
		slice
	});

	useEffect(() => {
		fetchFunc();
	}, []);

	return children({ pending, data });
};

export default FetchServersAddressesData;
