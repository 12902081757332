import { createSelector, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import TenantsService from './TenantsService';
import TenantsHelpers from './TenantsHelpers';
import Slices from './Constants/Slices';

const initialState = {
	licenses: null,
	licensesPending: false,
	timeLicenses: null,
	timeLicensesPending: false
};

export const slice = Slices.licenses;
export const licensesSlice = createSlice({
	name: slice,
	initialState,
	reducers: {
		fetchLicensesSuccess: (state, { payload }) => {
			state.licenses = payload;
			state.licensesPending = false;
		},
		setLicensesPending: (state, { payload }) => {
			state.licensesPending = payload;
		},
		fetchTimeLicensesSuccess: (state, { payload }) => {
			const tenants = payload.tenants.map((t) => {
				const isValid = moment(t.timeLicence).isAfter(new Date().toISOString(), 'minute');

				return {
					...t,
					isValid: isValid,
					status: TenantsHelpers.timeLicenseStatus(t.timeLicence, isValid),
					days: TenantsHelpers.timeLicenseReminingDays(t.timeLicence)
				};
			});

			state.timeLicenses = tenants;
			state.timeLicensesPending = false;
		},
		setTimeLicensesPending: (state, { payload }) => {
			state.timeLicensesPending = payload;
		}
	}
});

export const { fetchTimeLicensesSuccess, setTimeLicensesPending, fetchLicensesSuccess, setLicensesPending } =
	licensesSlice.actions;

export const fetchLicensesAsync = (tenantId) => async (dispatch) => {
	try {
		dispatch(setLicensesPending(true));

		const response = await TenantsService.fetchTenantLicense(tenantId);

		dispatch(fetchLicensesSuccess(response));
	} catch (error) {
		dispatch(addErrorAsync({ slice, error }));
		dispatch(setLicensesPending(false));
	}
};

export const fetchTimeLicensesAsync = () => async (dispatch) => {
	try {
		dispatch(setTimeLicensesPending(true));

		const response = await TenantsService.fetchTenantsTimeLicenses();

		dispatch(fetchTimeLicensesSuccess(response));
	} catch (error) {
		dispatch(addErrorAsync({ slice, error }));
		dispatch(setTimeLicensesPending(false));
	}
};

export const selectLicenses = createSelector(
	(state) => state.licenses,
	(licenses) => ({
		licenses: licenses.licenses,
		licensesPending: licenses.licensesPending
	})
);

export const selectTimeLicenses = createSelector(
	(state) => state.licenses,
	(licenses) => ({
		timeLicenses: licenses.timeLicenses,
		timeLicensesPending: licenses.timeLicensesPending
	})
);

export default licensesSlice.reducer;
