import { useState, useEffect, useMemo } from 'react';
import { FormControl, Slider, Typography } from '@mui/material';
import { useDebounce, useLocale } from '../../hooks';
import { filterOperators } from '../../constants';

const initialValue = [0, 100];
const FiltersControlNumberRange = ({ member, label, stateSlice, onChange, onClearMemberAll }) => {
	const { translate } = useLocale();
	const [value, setValue] = useState(initialValue);
	const debouncedValue = useDebounce(value);

	const handleChange = (_, newValue) => setValue(newValue);

	const handlerClearValue = () => setValue(initialValue);

	const handleClearFilter = () => onClearMemberAll(member.name);

	const displayValue = useMemo(() => {
		return `${translate('Common_From')} ${value[0]}% ${translate('Common_To')} ${value[1]}%`;
	}, [value[0], value[1]]);

	useEffect(() => {
		if (debouncedValue[0] === 0 && debouncedValue[1] === 100) {
			if (stateSlice && stateSlice[member.name]) handleClearFilter();
			return;
		}

		onChange(
			[
				{
					member,
					value: debouncedValue[0],
					operator: filterOperators.ge,
					replace: false
				},
				{
					member,
					value: debouncedValue[1],
					operator: filterOperators.le,
					replace: false
				}
			],
			member.name
		);
	}, [debouncedValue[0], debouncedValue[1]]);

	useEffect(() => {
		if (stateSlice == null || (stateSlice && Object.keys(stateSlice).length === 0) || !stateSlice[member.name]) {
			handlerClearValue();
		}
	}, [stateSlice]);

	return (
		<FormControl fullWidth>
			<Typography variant='caption' color='textSecondary'>
				{translate(label)}
			</Typography>
			<Typography variant='caption' color='textSecondary'>
				{displayValue}
			</Typography>
			<Slider
				getAriaLabel={() => label}
				value={value}
				onChange={handleChange}
				valueLabelDisplay='auto'
				sx={{ marginTop: '1rem' }}
			/>
		</FormControl>
	);
};

export default FiltersControlNumberRange;
