import React from 'react';
import { Fade } from '@mui/material';

const BaseGrowAnimation = ({ children, show, noAnimation, ...rest }) => {
	if (noAnimation) {
		return show ? (
			<Fade in={show} mountOnEnter unmountOnExit {...rest}>
				{children}
			</Fade>
		) : null;
	} else {
		return (
			<Fade in={show} mountOnEnter unmountOnExit {...rest}>
				{children}
			</Fade>
		);
	}
};

export default BaseGrowAnimation;
