import { useSelector } from 'react-redux';
import TimeLicenseStatus from '../Enums/TimeLicenseStatus';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import { selectTimeLicenses } from '../licensesSlice';
import { useTheme } from '@emotion/react';
import { BaseTooltip } from '@sonar-web/common';

const TimeLicenseStatusIcon = ({ status }) => {
	const theme = useTheme();
	const { timeLicensesPending, timeLicenses } = useSelector(selectTimeLicenses);

	const color = () => {
		if (status === TimeLicenseStatus.Expired.name) return theme.palette.error.main;
		return '#ff6b34';
	};

	const text = () => {
		if (status === TimeLicenseStatus.Expired.name) return 'TimeLicenseExpiredTooltip';
		return 'TimeLicenseExpiringTooltip';
	};

	if (timeLicensesPending || !timeLicenses) return null;
	if (status === TimeLicenseStatus.Ok.name) return null;

	return (
		<BaseTooltip text={text()}>
			<NotificationsActiveOutlinedIcon sx={{ color: color() }} />
		</BaseTooltip>
	);
};

export default TimeLicenseStatusIcon;
