import LicenseItem from './LicenseItem';
import NetworksLicenseIcon from '../Icons/NetworksLicenseIcon';

const Networks = ({ pending, data }) => {
	return (
		<LicenseItem
			pending={pending}
			data={
				data
					? {
							Icon: NetworksLicenseIcon,
							title: 'Common_StationaryNetworks',
							description: 'LicenseNetworksDescription',
							primaryData: {
								value: data.maxNumberOfReads,
								text: 'Limit'
							}
					  }
					: null
			}
		/>
	);
};

export default Networks;
