import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useDebounce } from '../../../../../hooks';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		marginLeft: theme.spacing(1)
	},
	text: {
		fontWeight: theme.typography.fontWeightRegular
	},
	previous: {
		marginRight: theme.spacing(1)
	},
	next: {
		marginLeft: theme.spacing(1)
	},
	hide: {
		visibility: 'hidden'
	}
}));

const YearSelector = ({ year, setYear }) => {
	const classes = useStyles();
	const [value, setValue] = useState(year);
	const debounceValue = useDebounce(value, 50);

	const currentYear = new Date().getFullYear();
	const isMaxYear = year === currentYear + 10;
	const isCurrentYear = year === currentYear;

	useEffect(() => {
		setYear(debounceValue);
	}, [debounceValue]);

	return (
		<div className={classes.root}>
			<IconButton
				size='small'
				className={clsx(classes.previous, { [classes.hide]: isCurrentYear })}
				onClick={() => setValue(year - 1)}>
				<ChevronLeftIcon fontSize='small' />
			</IconButton>
			<Typography variant='h6' className={classes.text}>
				{year}
			</Typography>
			<IconButton
				size='small'
				className={clsx(classes.next, { [classes.hide]: isMaxYear })}
				onClick={() => setValue(year + 1)}>
				<ChevronRightIcon fontSize='small' />
			</IconButton>
		</div>
	);
};

export default YearSelector;
