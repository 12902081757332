import { createSlice } from '@reduxjs/toolkit';
import { pageDescriptor, getSliceFilters } from '@sonar-web/common';
import { fetchTcpDeviceTypes } from './api';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';

const slice = 'tcpDeviceTypesSearch';

export const tcpDeviceTypesSearchSlice = createSlice({
	name: slice,
	initialState: {
		pageDescriptor,
		fetchPending: false,
		tcpDeviceTypes: [],
		totalCount: 0,
		hasMore: true,
		reload: false,
		reset: true
	},
	reducers: {
		fetchTcpDeviceTypesSuccess: (state, action) => {
			let fetched = [...action.payload.elements];

			state.tcpDeviceTypes = state.reset ? fetched : state.tcpDeviceTypes.concat(fetched);
			state.totalCount = action.payload.totalCount;
			state.fetchPending = false;
		},
		fetchTcpDeviceTypesPending: (state) => {
			state.fetchPending = true;
		},
		setHasMore: (state) => {
			state.hasMore = state.totalCount > state.pageDescriptor.Offset + state.pageDescriptor.Limit;
		},
		setResetAndReload: (state) => {
			state.tcpDeviceTypes = [];
			state.reload = true;
			state.reset = true;
			state.hasMore = true;
			state.pageDescriptor.Offset = 0;
			state.pageDescriptor.FilterDescriptors = [];
			state.pageDescriptor.SortDescriptors = [];
		},
		setResetToFirstPage: (state) => {
			state.tcpDeviceTypes = [];
			state.reload = true;
			state.reset = true;
			state.hasMore = true;
			state.pageDescriptor.Offset = 0;
		},
		setAppend: (state, action) => {
			state.reset = !action.payload;
			if (action.payload) state.pageDescriptor.Offset += state.pageDescriptor.Limit;
			else state.pageDescriptor.Offset = 0;
		},
		setReload: (state) => {
			state.reload = true;
		}
	}
});

export const {
	fetchTcpDeviceTypesSuccess,
	fetchTcpDeviceTypesPending,
	setHasMore,
	setResetAndReload,
	setResetToFirstPage,
	setAppend,
	setReload
} = tcpDeviceTypesSearchSlice.actions;

export const fetchTcpDeviceTypesAsync = () => async (dispatch, getState) => {
	let response;

	try {
		dispatch(fetchTcpDeviceTypesPending());

		let pd = Object.assign({}, getState().tcpDeviceTypesSearch.pageDescriptor);

		pd.FilterDescriptors = getSliceFilters(getState(), slice);
		pd.SortDescriptors = [{ Member: 'nameToSort', ListSortDirection: 1 }];

		response = await fetchTcpDeviceTypes(pd);
	} catch (error) {
		dispatch(addErrorAsync({ slice, error }));
		return;
	}

	dispatch(fetchTcpDeviceTypesSuccess(response));
	dispatch(setHasMore());
};

export const fetchTcpDeviceTypeByIdAsync = (filter) => async (dispatch, getState) => {
	let response;

	try {
		let pd = Object.assign({}, getState().tcpDeviceTypesSearch.pageDescriptor);

		pd.FilterDescriptors = filter;
		pd.SortDescriptors = [];

		response = await fetchTcpDeviceTypes(pd);
	} catch (error) {
		dispatch(addErrorAsync({ slice, error }));
		return;
	}

	return response;
};

export const selectTcpDeviceTypes = (state) => {
	return {
		dataRows: state.tcpDeviceTypesSearch.tcpDeviceTypes,
		pending: state.tcpDeviceTypesSearch.fetchPending,
		hasMore: state.tcpDeviceTypesSearch.hasMore,
		reload: state.tcpDeviceTypesSearch.reload,
		totalCount: state.tcpDeviceTypesSearch.totalCount
	};
};

export default tcpDeviceTypesSearchSlice.reducer;
