import { useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import { ListItemTextBase } from '..';
import { ListItemAvatar, ListItemSecondaryAction, IconButton, Avatar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useLocale from '../../hooks/useLocale';
import IconButtonProgress from '../IconButtonProgress/IconButtonProgress';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ErrorTooltip from '../ErrorTooltip/ErrorTooltip';
import BaseTooltip from '../BaseTooltip/BaseTooltip';
import { RecycleBinIcon } from '../../icons';

const useStyles = makeStyles((theme) => ({
	leftAction: {
		marginRight: theme.spacing(4)
	},
	avatarRoot: {
		background: theme.palette.error.dark,
		color: theme.palette.common.white
	},
	submitError: { marginRight: theme.spacing(8) },
	submitErrorIconRoot: {
		color: theme.palette.error.dark
	}
}));

const PanelListItemDelete = ({ show, setShow, onCancel, submitAction, submitPending, primaryText, secondaryText }) => {
	const classes = useStyles();
	const { translate } = useLocale();
	const [submitError, setSubmitError] = useState(null);

	const primary = typeof primaryText === 'function' ? primaryText(translate) : translate(primaryText);
	const secondary = typeof secondaryText === 'function' ? secondaryText(translate) : translate(secondaryText);

	const handleCancel = () => {
		if (onCancel) onCancel();
		setSubmitError(null);
		setShow(false);
	};

	const handleSubmit = () => submitAction(setSubmitError);

	const handleEsc = () => setShow(false);

	useEffect(() => {
		if (show) document.addEventListener('keyup', handleEsc);
		return () => document.removeEventListener('keyup', handleEsc);
	}, [show]);

	if (!show) return null;
	return (
		<>
			<ListItemAvatar>
				<Avatar classes={{ root: classes.avatarRoot }}>
					<RecycleBinIcon />
				</Avatar>
			</ListItemAvatar>
			<ListItemTextBase
				hideBorder
				primary={primary}
				primaryTypographyProps={{ variant: 'caption' }}
				secondary={secondary}
				secondaryTypographyProps={{
					variant: 'body2',
					classes: { root: classes.secondaryTextRoot }
				}}
			/>
			{submitError && (
				<ListItemSecondaryAction className={classes.submitError}>
					<ErrorTooltip errorMessage={submitError}>
						<IconButton
							name='listitemdelete_error'
							edge='start'
							size='small'
							classes={{ root: classes.submitErrorIconRoot }}>
							<ErrorOutlineIcon fontSize='small' />
						</IconButton>
					</ErrorTooltip>
				</ListItemSecondaryAction>
			)}
			<ListItemSecondaryAction onClick={handleCancel} className={classes.leftAction}>
				<BaseTooltip text='Common_CancelDelete'>
					<IconButton name='listitemdelete_cancel' edge='start' size='small'>
						<ArrowBackIcon fontSize='small' />
					</IconButton>
				</BaseTooltip>
			</ListItemSecondaryAction>
			<ListItemSecondaryAction onClick={handleSubmit}>
				<BaseTooltip text='Common_ConfirmDelete'>
					<IconButton
						name='listitemdelete_submit'
						edge='end'
						size='small'
						className={classes.rightActionButton}>
						<CheckIcon fontSize='small' />
						<IconButtonProgress pending={submitPending} />
					</IconButton>
				</BaseTooltip>
			</ListItemSecondaryAction>
		</>
	);
};

export default PanelListItemDelete;
