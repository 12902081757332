import React from 'react';
import { Avatar, Box, Collapse, IconButton, Typography, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close } from '@mui/icons-material';
import { Spacer } from '../../components';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { useLocale } from '../../hooks';

const useStyles = makeStyles((theme) => ({
	collapse: {
		marginBottom: '2rem'
	},
	root: {
		padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
		borderTop: `1px solid ${theme.palette.divider}`,
		borderBottom: `1px solid ${theme.palette.divider}`
	},
	avatarRoot: {
		backgroundColor: theme.palette.grey[100]
	},
	messageRoot: {
		marginLeft: theme.spacing(1),
		whiteSpace: 'break-spaces'
	}
}));

const ErrorInlineTemplate = ({ show, handleClose, message, collapseTimeout, iconColor }) => {
	const classes = useStyles();
	const theme = useTheme();
	const { translate } = useLocale();

	const iconColorStyle = iconColor
		? {
				color: iconColor
		  }
		: {};

	return (
		<Collapse
			in={show}
			timeout={collapseTimeout ?? theme.transitions.duration.shorter}
			classes={{
				wrapperInner: classes.collapse
			}}>
			<Box display='flex' alignItems='center' className={classes.root}>
				<Avatar classes={{ root: classes.avatarRoot }}>
					<NotificationsActiveIcon color='error' style={iconColorStyle} />
				</Avatar>
				<Typography
					variant='body2'
					color='textSecondary'
					classes={{ root: classes.messageRoot }}
					data-at-error={`errorInline|${message}`}>
					{translate(message)}
				</Typography>
				{handleClose ? (
					<>
						<Spacer />
						<IconButton onClick={handleClose}>
							<Close />
						</IconButton>
					</>
				) : null}
			</Box>
		</Collapse>
	);
};

export default ErrorInlineTemplate;
