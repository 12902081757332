import { apiBase } from '@sonar-web/common';
import { REST_API_PATHS } from '@sonar-web/common/src/constants/system';

const path = REST_API_PATHS.STATIONARY;

export async function fetchDiagnosticConverterReads(pageDescriptor) {
	const { data } = await apiBase(`converter/diagnosticreads`, 'get', {
		path,
		params: { pageDescriptor }
	});
	return data;
}
