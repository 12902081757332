import React, { createContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';
import { updateCronObjects, stringifyCronObject, getScheduleTypeFromCronObject } from '../cronParser';
import { getScheduleTypeDefinition } from './constants';

import { selectSchedule, setSchedule, setIsValid } from '../scheduleSlice';

const defaultCron = [
	{
		dayOfTheMonth: ['*'],
		dayOfTheWeek: ['*'],
		hour: ['*'],
		minute: ['*'],
		month: ['*'],
		year: ['*']
	}
];

const ScheduleContext = createContext({});

export const ScheduleContextProvider = ({ data, entryData, children }) => {
	const dispatch = useDispatch();
	const { isValid } = useSelector(selectSchedule);

	const [cronObjects, setCronObjects] = useState(defaultCron);
	const [selected, setSelected] = useState(null); //nazwa typu harmonogramu
	const timeZone = moment.tz.guess();
	const [scheduleId, setScheduleId] = useState(null);

	const resetSchedule = () => setCronObjects(defaultCron);

	const updateSchedule = (type, d) => {
		const newCron = updateCronObjects(defaultCron[0], type, d);

		setCronObjects(newCron);
	};

	const getScheduleData = () => {
		var entries = [];

		for (const element of cronObjects) {
			entries.push({
				cronTime: stringifyCronObject(element),
				...entryData
			});
		}

		return {
			id: scheduleId,
			timeZone: timeZone,
			entries: entries
		};
	};

	useEffect(() => {
		if (data) {
			setCronObjects(data.parsed);
			const firstCornExp = data.parsed[0];

			const type = getScheduleTypeFromCronObject(firstCornExp);
			setSelected(getScheduleTypeDefinition(type).type);
		}
		if (!scheduleId) {
			setScheduleId(data?.id ?? uuidv4());
		}
	}, []);

	useEffect(() => {
		if (cronObjects && isValid) {
			const schedule = getScheduleData();

			dispatch(setSchedule(schedule));
		} else {
			dispatch(setSchedule({}));
		}
	}, [isValid, cronObjects]);

	return (
		<ScheduleContext.Provider
			value={{
				cronObjects,
				setCronObjects,
				selected,
				setSelected,
				isValid,
				setIsValid: (value) => dispatch(setIsValid(value)),
				resetSchedule,
				updateSchedule,
				scheduleId
			}}>
			{children}
		</ScheduleContext.Provider>
	);
};

export default ScheduleContext;
