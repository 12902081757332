import React, { useState } from 'react';
import { FormControl, FormControlLabel, Switch, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '../../../../hooks';

const useStyles = makeStyles((theme) => ({
	formControlRoot: {
		marginLeft: theme.spacing(1)
	}
}));

const ConfiguratorToggleableTextfield = ({
	onChange,
	onBlur,
	name,
	value,
	form,
	element,
	error,
	helperText,
	dataAtError
}) => {
	const classes = useStyles();
	const { translate } = useLocale();
	const { option } = element;
	const [switchValue, setSwitchValue] = useState(!(option && value === option.offValue));
	const switchLabel = switchValue ? option.onLabel : option.offLabel;

	const handleSwitchChange = () => {
		form.setFieldValue(name, switchValue ? option.offValue : option.onValue);
		setSwitchValue(!switchValue);
	};

	return (
        (<FormControl fullWidth margin='normal' classes={{ root: classes.formControlRoot }}>
            <FormControlLabel
				name={name}
				label={translate(switchLabel)}
				control={<Switch size='small' color='primary' checked={switchValue} onChange={handleSwitchChange} />}
			/>
            {switchValue && (
				<TextField
					type='text'
					margin='normal'
					size='small'
					fullWidth
					label={translate(name)}
					name={name}
					value={value}
					onChange={onChange}
					onBlur={onBlur}
					error={error}
					helperText={helperText}
					slotProps={{
                        formHelperText: {
                            'data-at-error': dataAtError
                        }
                    }}
				/>
			)}
        </FormControl>)
    );
};

export default ConfiguratorToggleableTextfield;
