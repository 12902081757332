import React, { useState } from 'react';
import clsx from 'clsx';
import { Box, Tab, Tabs, Typography, Zoom } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { TabPanelBase } from '../../../..';
import { useLocale } from '../../../../hooks';
import ConfiguratorTab from './ConfiguratorTab';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CheckIcon from '@mui/icons-material/Check';

const useStyles = makeStyles((theme) => ({
	tabsRoot: {
		position: 'relative',
		minHeight: 36,
		paddingLeft: theme.spacing(2),
		marginBottom: theme.spacing(2),
		'&:after': {
			content: '""',
			position: 'absolute',
			width: '100%',
			height: 2,
			backgroundColor: theme.palette.divider,
			top: `calc(50% - 4px)`,
			left: 0
		}
	},
	hidden: {
		display: 'none'
	},
	indicator: {
		display: 'none'
	},
	tabRoot: {
		flexDirection: 'row',
		zIndex: 1,
		minHeight: 'auto',
		minWidth: 'auto',
		opacity: 1,
		paddingBlock: 5,
		paddingInline: 10,
		marginRight: theme.spacing(4),
		color: theme.palette.text.secondary,
		backgroundColor: theme.palette.background.paper,
		border: `1px solid transparent`,
		borderRadius: '16px',
		'&:hover': {
			color: theme.palette.text.primary
		}
	},
	tabSelected: {
		color: `${theme.palette.text.primary} !important`,
		borderColor: '#E0E0E0'
	},
	tabWrapper: {
		flexDirection: 'row',
		'& > svg': {
			marginBottom: '0 !important',
			marginRight: 0
		}
	},
	tabIconWrapper: {
		marginBottom: '0 !important'
	},
	tabNameRoot: {
		padding: '0 4px',
		background: theme.palette.background.paper
	},
	tabIcon: {
		background: theme.palette.background.paper
	},
	validIcon: {
		color: theme.palette.success.main,
		background: theme.palette.background.paper
	}
}));

const ConfiguratorTabs = ({ tabs, form, ...rest }) => {
	const classes = useStyles();
	const { translate } = useLocale();
	const [tabValue, setTabValue] = useState(0);
	const [tabsValidity, setTabsValidity] = useState({});

	const singleTab = tabs.length === 1;

	const handleChange = (_, newValue) => setTabValue(newValue);

	const updateTabValidity = (tabIndex, data) => {
		if (tabsValidity[tabIndex] === data) return;

		setTabsValidity(Object.assign(tabsValidity, { [tabIndex]: data }));
	};

	return (
		<>
			<Tabs
				data-at='configuratorTabs'
				value={tabValue}
				onChange={handleChange}
				classes={{ root: classes.tabsRoot, indicator: classes.indicator }}
				className={clsx({ [classes.hidden]: singleTab })}>
				{tabs.map((tab, index) => {
					const tabIsValid = tabsValidity[index] === true;
					return (
						<Tab
							key={index}
							name={tab.name || `configuratorTabs_tab${index}`}
							label={
								<Box display='flex' alignItems='center'>
									<Typography variant='body2' classes={{ root: classes.tabNameRoot }}>
										{translate(tab.name)}
									</Typography>
									<Zoom in={tabIsValid} timeout={250}>
										<CheckIcon fontSize='small' classes={{ root: classes.validIcon }} />
									</Zoom>
								</Box>
							}
							disableRipple
							disableFocusRipple
							disableTouchRipple
							icon={<RadioButtonCheckedIcon fontSize='small' classes={{ root: classes.tabIcon }} />}
							classes={{
								root: clsx(classes.tabRoot, { [classes.tabIsValid]: tabIsValid }),
								wrapper: classes.tabWrapper,
								selected: classes.tabSelected,
								iconWrapper: classes.tabIconWrapper
							}}
						/>
					);
				})}
			</Tabs>

			{tabs.map((tab, index) => {
				return (
					<TabPanelBase
						key={index}
						index={index}
						value={tabValue}
						disableRemoveFromDOM={true}
						disableInnerTopPadding>
						<Box overflow='auto' pr={2} height='100%'>
							<ConfiguratorTab
								tabIndex={index}
								groups={tab.groups}
								form={form}
								updateTabValidity={updateTabValidity}
								{...rest}
							/>
						</Box>
					</TabPanelBase>
				);
			})}
		</>
	);
};

export default ConfiguratorTabs;
