import { apiBase } from '@sonar-web/common';
import { useFormat } from '@sonar-web/common/src/hooks';

const { format } = useFormat();

export async function fetchProfiles({ api, deviceTypeId }) {
	const url = format(api.url, deviceTypeId);

	const { data } = await apiBase(url, 'get', {
		path: api.path
	});

	return data;
}

export async function fetchProfile({ api, profileId }) {
	const url = format(api.url, profileId);

	const { data } = await apiBase(url, 'get', {
		path: api.path
	});

	return data;
}

export async function addProfile({ api, profile }) {
	const response = await apiBase(api.add.url, 'post', {
		path: api.add.path,
		data: profile
	});

	const locationHeader = response.headers.location;

	if (locationHeader)
		return {
			url: locationHeader,
			id: locationHeader.slice(locationHeader.lastIndexOf('/') + 1)
		};

	return response.data;
}

export async function editProfile({ api, profile }) {
	const { data } = await apiBase(api.edit.url.replace('{0}', profile.id), 'put', {
		path: api.add.path,
		data: profile
	});

	return data;
}
