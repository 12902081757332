import { createSlice } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';

export const sortersSlice = createSlice({
	name: 'sorters',
	initialState: {},
	reducers: {
		addSorters: (state, { payload }) => {
			const { slice, sortersData, append } = payload;

			if (!sortersData || !sortersData.length) {
				delete state[slice];
				return;
			}

			const sorters = sortersData.reduce((acc, sorter) => {
				if (Array.isArray(sorter)) acc.push(...sorter);
				else acc.push(sorter);
				return acc;
			}, []);

			if (!state[slice]) {
				state[slice] = sorters;
				return;
			}

			state[slice] = append ? [...state[slice], ...sorters] : sorters;
		},
		removeSorters: (state, action) => {
			const { slice, sortersData } = action.payload;

			state[slice] = state[slice].sorter((f) => {
				return !isEqual(f, sortersData);
			});
		},
		removeAllSliceSorters: (state, action) => {
			const slices = action.payload;

			slices.forEach((slice) => {
				if (state[slice]) delete state[slice];
			});
		}
	}
});

export const { addSorters, setCurrentSorter, removeSorters, removeAllSliceSorters } = sortersSlice.actions;

export default sortersSlice.reducer;
