import { apiBase } from '@sonar-web/common';
import { REST_API_PATHS } from '@sonar-web/common/src/constants/system';

const path = REST_API_PATHS.LOCATION;

export async function fetchLocationNode(id) {
	const { data } = await apiBase(`locationNodes/${id}`, 'get', {
		path
	});

	return data;
}

export async function addLocationNode(node) {
	const response = await apiBase(`locationNodes`, 'post', {
		path,
		data: node
	});

	const locationHeader = response.headers.location;

	if (locationHeader)
		return {
			url: locationHeader,
			id: locationHeader.slice(locationHeader.indexOf('/') + 1)
		};
	return response;
}

export async function editLocationNode(node) {
	const response = await apiBase(`locationNodes/${node.id}`, 'put', {
		path,
		data: node
	});

	const locationHeader = response.headers.location;

	if (locationHeader)
		return {
			url: locationHeader,
			id: locationHeader.slice(locationHeader.indexOf('/') + 1)
		};
	return response;
}

export async function deleteLocationNode(id) {
	const { data } = await apiBase(`locationNodes/${id}`, 'delete', {
		path
	});

	return data;
}
