import React, { useState } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { resetLocationNodeData } from '../LocationNode/slice';
import { useSelector, useDispatch } from 'react-redux';
import { selectAvailableLocationNodeLevels } from '../LocationNodeLevels/slice';
import { ListItemTextBase, useSidebar } from '@sonar-web/common';
import { useLocale } from '@sonar-web/common/src/hooks';
import LocationNodeLevelAvatar from '../LocationNodeLevels/LocationNodeLevelAvatar';
import { locationNodesSidebarComponents } from '../LocationNodes/locationNodesSidebar';
import { setSelected } from '../LocationNodes/slice';

const sidebarType = 'locationNodes';
const LocationNodeAddMenu = ({
	showMoveAdd,
	currentBreadcrumb,
	disableSubmitMany,
	nodeAddOnClose,
	submitActionTooltip = 'Common_Confirm'
}) => {
	const dispatch = useDispatch();
	const { translate } = useLocale();
	const availableLevels = useSelector(selectAvailableLocationNodeLevels);
	const { loadSidebar, unloadSidebar, getComponentInfo } = useSidebar();
	const [anchor, setAnchor] = useState(null);

	const handleMenu = (event) => setAnchor(event.currentTarget);

	const handleClose = () => setAnchor(null);

	const handleAddLocationNode = (level) => {
		if (showMoveAdd) {
			showMoveAdd(level);
			return;
		}

		dispatch(resetLocationNodeData());
		loadSidebar(
			sidebarType,
			getComponentInfo(locationNodesSidebarComponents.NodeAdd, {
				submitActionTooltip,
				disableSubmitMany,
				level,
				currentBreadcrumb,
				onClose: (result) => {
					unloadSidebar(sidebarType);

					if (nodeAddOnClose) nodeAddOnClose(result);
				}
			})
		);
		dispatch(setSelected(null));
		setAnchor(false);
	};

	return (
		<>
			<IconButton
				name='locationnodes_addmenu'
				size='small'
				aria-label='location_node_addmenu'
				onClick={handleMenu}>
				<AddCircleOutlineIcon fontSize='small' />
			</IconButton>
			{anchor && (
				<Menu
					anchorEl={anchor}
					keepMounted
					anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
					transformOrigin={{ vertical: 'top', horizontal: 'left' }}
					open={Boolean(anchor)}
					onClose={handleClose}>
					{availableLevels.map((al) => (
						<MenuItem dense key={al.id} onClick={() => handleAddLocationNode(al)}>
							<LocationNodeLevelAvatar level={al} size='small' />
							<ListItemTextBase
								hideBorder
								primary={translate(al.name)}
								primaryTypographyProps={{
									'data-at-params': `LocationAddMenu|LocationNodeLevelType|${al.type}`
								}}
							/>
						</MenuItem>
					))}
				</Menu>
			)}
		</>
	);
};

export default LocationNodeAddMenu;
