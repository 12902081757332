import { apiBase } from '@sonar-web/common';
import { REST_API_PATHS } from '@sonar-web/common/src/constants/system';

const path = REST_API_PATHS.BILLING;

export async function fetchPeriod(id) {
	const { data } = await apiBase(`billings/settlement/${id}/details`, 'get', {
		path
	});

	return data;
}

export async function fetchPeriodTemplate(clientId) {
	const { data } = await apiBase(`billings/getTemplate/${clientId}`, 'get', {
		path
	});

	return data;
}

export async function addPeriod(period) {
	const response = await apiBase(`billings/add`, 'post', {
		path,
		data: period
	});

	const periodHeader = response.headers.location;

	if (periodHeader)
		return {
			url: periodHeader,
			id: periodHeader.slice(periodHeader.indexOf('/') + 1)
		};

	return response;
}

export async function addPeriodWithTemplate(period) {
	const response = await apiBase(`billings/addWithTemplate`, 'post', {
		path,
		data: period
	});

	const periodHeader = response.headers.location;

	if (periodHeader)
		return {
			url: periodHeader,
			id: periodHeader.slice(periodHeader.indexOf('/') + 1)
		};

	return response;
}

export async function editPeriod(period) {
	const { data } = await apiBase(`billingPeriod/${period.id}`, 'put', {
		path,
		data: period
	});

	return data;
}

export async function editPeriodWithTemplate(period) {
	const { data } = await apiBase(`billingPeriod/editWithTemplate`, 'put', {
		path,
		data: period
	});

	return data;
}

export async function deletePeriod(id) {
	const { data } = await apiBase(`billings/delete/${id}`, 'delete', {
		path
	});

	return data;
}

export async function fetchAggregateTemplates(clientId) {
	const { data } = await apiBase(`reports/heatNodeReportDefinitions/${clientId}`, 'get', {
		path
	});

	return data;
}

export async function fetchIndividualTemplates(clientId) {
	const { data } = await apiBase(`reports/flatReportDefinitions/${clientId}`, 'get', {
		path
	});

	return data;
}

export async function fetchAggregateTemplateFile(id) {
	const { data } = await apiBase(`reports/example/heatnode/${id}`, 'get', {
		path,
		responseType: 'blob'
	});

	return data;
}

export async function fetchIndividualTemplateFile(id) {
	const { data } = await apiBase(`reports/example/flat/${id}`, 'get', {
		path,
		responseType: 'blob'
	});

	return data;
}
