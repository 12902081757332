import React, { useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '../../hooks';
import TileBase from '../../features/Tiles/TileBase';
import DayItem from './DayItem';

const useStyles = makeStyles((theme) => ({
	titleRoot: {
		fontWeight: theme.typography.fontWeightMedium
	},
	grid: {
		display: 'grid',
		gridTemplateColumns: 'repeat(7, 1fr)',
		alignItems: 'center',
		gap: `12px`,
		padding: theme.spacing(1),
		[theme.breakpoints.down('lg')]: {
			gridTemplateColumns: '1fr 1fr 1fr 1fr'
		}
	},
	tile3Columns: {
		gridColumnEnd: 'span 3'
	}
}));

const lastDayOfMonth = 'L';

const SelectDayOfMonth = ({
	single = false,
	inverse = false,
	initial = [],
	onChange,
	max = 31,
	disableTitle,
	includeLastDay = true,
	...rest
}) => {
	const classes = useStyles();
	const { translate } = useLocale();

	const [days, setDays] = useState(initial);

	const daysTiles = useMemo(() => {
		const items = Array.from({ length: max }, (_, i) => i + 1);
		if (includeLastDay) items.push(lastDayOfMonth);

		return items;
	}, [max, includeLastDay]);

	const handleClick = (v) => {
		if (single) singleSelection(v);
		else multipleSelection(v);
	};

	function singleSelection(v) {
		if (days.length === 1 && days[0] === v) return;

		setDays([v]);

		if (onChange) onChange([v]);
	}

	function multipleSelection(v) {
		const isSelected = days.includes(v);
		const newDays = isSelected ? days.filter((d) => d !== v) : [...days, v];

		setDays(newDays);

		if (onChange) onChange(newDays);
	}

	return (
		<TileBase
			hoverEffect={false}
			renderContent={() => {
				return (
					<Box display='flex' flexDirection='column' flex={1} pr={5} pl={5} pt={4} pb={4}>
						{disableTitle && (
							<Typography variant='body2' classes={{ root: classes.titleRoot }}>
								{translate('Common_DayOfMonth')}
							</Typography>
						)}
						<Box className={classes.grid}>
							{daysTiles.map((day, index) => {
								const selected = inverse ? !days.includes(day) : days.includes(day);

								return <DayItem key={index} day={day} onClick={handleClick} selected={selected} />;
							})}
						</Box>
					</Box>
				);
			}}
			{...rest}
		/>
	);
};

export default SelectDayOfMonth;
