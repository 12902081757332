import React from 'react';
import clsx from 'clsx';
import { Tabs, Tab, Collapse, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TabPanelBase from '../TabPanelBase/TabPanelBase';
import { useLocale } from '../../hooks';

const useStyles = makeStyles((theme) => ({
	root: {
		borderBottom: `1px solid ${theme.palette.grey[400]}`
	},
	hidden: {
		display: 'none'
	}
}));

const ControlledTabsBase = ({ tabs, hidden, tabValue, setTabValue, handleTabChange, tabPanelBaseProps, ...other }) => {
	const classes = useStyles();
	const { translate } = useLocale();
	const tabPanelBasePropsObj = Object.assign({}, tabPanelBaseProps);

	const oneTab = tabs.length === 1;
	const activeTab = tabValue > 0 && tabValue > tabs.length - 1 ? 0 : tabValue;

	const handleChange = (event, newValue) =>
		handleTabChange ? handleTabChange(event, newValue) : setTabValue(newValue);

	return (
		<Box minHeight={0} height='100%' display='flex' flexDirection='column'>
			<Collapse in={!oneTab}>
				<Tabs
					value={hidden ? false : activeTab}
					onChange={handleChange}
					indicatorColor='primary'
					variant='scrollable'
					classes={{ root: classes.root }}
					className={clsx({ [classes.hidden]: hidden })}
					{...other}>
					{tabs.map((tab) => {
						if (tab.tabConfig) return tab.tabConfig(tab.Id);
						return <Tab name={tab.name || null} key={tab.Id} label={translate(tab.label)} />;
					})}
				</Tabs>
			</Collapse>
			{tabs.map((tab) => {
				return (
					<TabPanelBase
						key={tab.Id}
						value={activeTab}
						index={tab.Id}
						tabPanelBasePropsObj={tabPanelBasePropsObj}
						disableRemoveFromDOM={tab.disableRemoveFromDOM}
						disableInnerTopPadding={tab.disableInnerTopPadding}>
						{tab.content}
					</TabPanelBase>
				);
			})}
		</Box>
	);
};

export default ControlledTabsBase;
