import React from 'react';
import BaseIcon from './BaseIcon';

export default function WaterMeterMountedIcon(props) {
	return (
		<BaseIcon
			{...props}
			renderMultiPath={({ primaryPathClass, primaryPathColor, secondaryPathClass, secondaryPathColor }) => (
				<>
					<path
						className={primaryPathClass}
						style={{ color: primaryPathColor }}
						d='m 10.34108,2.5671856 c -1.20257,0.08326 -2.38062,0.41586 -3.45833,0.98698 C 5.22914,4.4304789 3.90332,5.8202855 3.10411,7.5124984 2.30488,9.2047204 2.07419,11.110968 2.44786,12.94479 c 0.37366,1.833726 1.33153,3.494982 2.72917,4.739583 1.39763,1.244598 3.16139,2.004696 5.02604,2.164062 0.29712,0.02539 0.59197,0.0078 0.88801,0.0026 -0.17029,-0.464125 -0.27978,-0.953532 -0.33853,-1.460937 -0.14171,-0.0037 -0.28263,0.0095 -0.42448,-0.0026 C 8.77896,18.255094 7.3121,17.624629 6.15098,16.590622 4.98986,15.556716 4.19579,14.173986 3.88535,12.650518 3.57491,11.127054 3.76827,9.5433424 4.43223,8.1374984 5.0962,6.7316534 6.19648,5.5790509 7.57026,4.8510402 8.72723,4.2379083 10.0244,3.9539083 11.32286,4.0229149 11.72727,4.0444059 12.10151,3.7746176 12.164,3.3744776 12.2265,2.9743283 11.95339,2.5956403 11.54942,2.5671856 c -0.40314,-0.028409 -0.80747,-0.027749 -1.20834,0 z m 7.73178,0.2786467 c 0,0 -8.1785,7.1436181 -8.83334,7.9817697 -0.65482,0.838143 -0.50742,2.048326 0.33074,2.703126 0.83814,0.654893 2.04824,0.504906 2.70312,-0.333334 0.6548,-0.838141 5.79948,-10.351563 5.79948,-10.3515617 z m 0.80208,6.2265621 c -0.0943,-0.01232 -0.1915,-0.0062 -0.28906,0.02083 -0.39028,0.108292 -0.61496,0.512224 -0.54688,0.9114566 0.002,0.01388 0.003,0.02778 0.005,0.04167 a 5.5979279,5.5979279 0 0 1 1.5,0.286458 C 19.5216,10.09092 19.5032,9.8486924 19.458,9.6088514 19.4018,9.3103494 19.15743,9.1093474 18.87467,9.0723934 Z'
					/>
					<path
						className={secondaryPathClass}
						style={{ color: secondaryPathColor }}
						d='m 17.08067,12.162208 c -2.566305,0 -4.645833,2.07953 -4.645833,4.645834 0,2.566305 2.079528,4.645833 4.645833,4.645833 2.566306,0 4.645834,-2.079527 4.645834,-4.645833 0,-2.566306 -2.079528,-4.645834 -4.645834,-4.645834 z m 1.979167,2.536459 a 0.66666501,0.66666501 0 0 1 0.440104,0.161459 0.66666501,0.66666501 0 0 1 0.0651,0.942708 L 17.039,18.690855 a 0.66673166,0.66673166 0 0 1 -0.994792,0.01302 l -1.434896,-1.557291 a 0.66666501,0.66666501 0 0 1 0.03906,-0.942709 0.66666501,0.66666501 0 0 1 0.942708,0.03906 l 0.932292,1.010417 2.036459,-2.330729 a 0.66666501,0.66666501 0 0 1 0.5,-0.223959 z'
					/>
				</>
			)}
		/>
	);
}
