export default {
	Day: {
		value: 1,
		name: 'Day'
	},
	Week: {
		value: 2,
		name: 'Week'
	},
	Month: {
		value: 3,
		name: 'Month'
	},
	Year: {
		value: 4,
		name: 'Year'
	}
};
