import React from 'react';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useLocale from '../../hooks/useLocale';

const useStyles = makeStyles((theme) => ({
	root: {
		transition: theme.transitions.create(['color'], {
			easing: theme.transitions.easing.easeInOut,
			duration: theme.transitions.duration.short
		}),
		'&:hover': {
			backgroundColor: 'transparent'
		}
	},
	text: {
		padding: 0
	}
}));

const TextButton = ({ text, isActive, ...rest }) => {
	const classes = useStyles();
	const { translate } = useLocale();

	return (
        <Button
            variant='text'
            size='small'
            disableRipple
            disableFocusRipple
            {...rest}
            classes={{
				root: classes.root,
				text: classes.text
			}}>
			{translate(text)}
		</Button>
    );
};

export default TextButton;
