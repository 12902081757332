import React from 'react';
import { Toolbar } from '@mui/material';
import { Spacer } from '@sonar-web/common';
import useToolbar from './useToolbar';
import BaseButton from '@sonar-web/common/src/components/Buttons/BaseButton';

const ConfiguratorControl = () => {
	const { getButtonsState } = useToolbar();
	const { back, next, cancel } = getButtonsState();

	return (
		<Toolbar variant='dense' disableGutters sx={{ gap: '.5rem' }}>
			<Spacer />
			<BaseButton name='ConfiguratorCancel' variant='text' {...cancel}>
				Common_Cancel
			</BaseButton>
			{!back.hidden && (
				<BaseButton name='ConfiguratorPrevious' variant='outlined' {...back}>
					NavigationPrevious
				</BaseButton>
			)}
			{!next.hidden && (
				<BaseButton name='ConfiguratorNext' {...next}>
					NavigationNext
				</BaseButton>
			)}
		</Toolbar>
	);
};

export default ConfiguratorControl;
