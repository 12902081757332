import React from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import useLocale from '@sonar-web/common/src/hooks/useLocale';
import BaseButton from '@sonar-web/common/src/components/Buttons/BaseButton';

const useStyles = makeStyles((theme) => ({
	header: {
		color: theme.palette.text.secondary,
		fontSize: '1.5rem',
		fontWeight: theme.typography.fontWeightRegular
	},
	text: {
		color: theme.palette.text.secondary,
		fontSize: '1rem',
		fontWeight: theme.typography.fontWeightRegular,
		marginBottom: '1rem'
	}
}));

const DiagnosticMoreDataInfo = ({ onClick }) => {
	const { translate } = useLocale();
	const classes = useStyles();

	return (
		<Box display='flex' flexDirection='column' justifyContent='center' flex={1}>
			<Box display='flex' flexDirection='column' alignItems='center'>
				<Typography
					classes={{
						root: classes.header
					}}>
					{translate('Wmbus_MoreCommunicationHistoryDataHeader')}
				</Typography>
				<Typography
					classes={{
						root: classes.text
					}}>
					{translate('Wmbus_MoreCommunicationHistoryDataText')}
				</Typography>
				<BaseButton variant='outlined' name='billingClientProfileShow' onClick={onClick}>
					Wmbus_MoreCommunicationHistoryButtonText
				</BaseButton>
			</Box>
		</Box>
	);
};

export default DiagnosticMoreDataInfo;
