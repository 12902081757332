import React from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '@sonar-web/common/src/hooks';

const useStyles = makeStyles((theme) => ({
	root: {
		fontWeight: theme.typography.fontWeightRegular
	}
}));

const ContentTitle = ({ text }) => {
	const classes = useStyles();
	const { translate } = useLocale();

	return (
		<Typography variant='h6' classes={{ root: classes.root }} data-at-params={`contentTitle|${text}`}>
			{translate(text)}
		</Typography>
	);
};

export default ContentTitle;
