import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Typography } from '@mui/material';
import { apiBase, ERROR_TYPES } from '@sonar-web/common';
import { useLocale } from '@sonar-web/common/src/hooks';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import ShareUsersList from './ShareUsersList';
import ShareUsersSelect from './ShareUsersSelect';

const slice = 'common';

const ShareUsers = ({
	props,
	users,
	setUsers,
	handleDelete,
	disableSearch,
	disableAddToApi,
	useSearch = true,
	children
}) => {
	const dispatch = useDispatch();
	const { translate } = useLocale();
	const [err, setErr] = useState(null);

	const addEmail = async (email) => {
		setErr(null);

		try {
			const { headers } = await apiBase(`users`, 'post', {
				path: props.servicePath,
				data: { email }
			});

			return headers.location;
		} catch (error) {
			return dispatch(addErrorAsync({ slice, error }));
		}
	};

	const onDelete = (email) => {
		if (handleDelete) handleDelete(email);
		else setUsers(users.filter((u) => u.email !== email));
	};

	const handleSelect = async (v) => {
		if (disableAddToApi) {
			setUsers([v, ...users]);
			return;
		}

		if (v.id === null) {
			const result = await addEmail(v.email);

			if (typeof result === 'string') setUsers([{ ...v, id: result }, ...users]);
			else if (result?.type === ERROR_TYPES.api) setErr(result.message);
			else setErr('Reports_ErrorAddingEmail');

			return;
		}

		setUsers([v, ...users]);
	};

	return (
		<>
			{useSearch && (
				<div>
					<ShareUsersSelect
						servicePath={props.servicePath}
						onSelect={handleSelect}
						currentUsers={users}
						disableSearch={disableSearch}
					/>
				</div>
			)}
			<Typography variant='caption' color='error' style={{ visibility: err ? 'visible' : 'hidden' }}>
				{translate(err)}
			</Typography>
			{children}
			<ShareUsersList records={users} setRecords={setUsers} onDelete={onDelete} disableSearch={disableSearch} />
		</>
	);
};

export default ShareUsers;
