import React from 'react';
import clsx from 'clsx';
import { Card, CardContent, Typography, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useLocale from '@sonar-web/common/src/hooks/useLocale';
import { BaseCircularProgress } from '../../components';

const useStyles = makeStyles((theme) => ({
	cardRoot: {
		display: 'flex',
		position: 'relative',
		alignSelf: 'stretch',
		minHeight: 120,
		padding: 0,
		textDecoration: 'none',
		backgroundColor: theme.palette.common.white,
		transition: 'background 200ms ease-in-out',
		'&:hover': {
			cursor: 'pointer',
			backgroundColor: theme.palette.primaryLight.light,
			'&$cardRootSelected': {
				backgroundColor: theme.palette.primaryLight.dark
			}
		}
	},
	cardRootSelected: {
		borderColor: theme.palette.primary.main,
		backgroundColor: theme.palette.primaryLight.main,
		color: theme.palette.primary.main
	},
	contentRoot: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		margin: 0,
		position: 'relative',
		'&:last-child': {
			paddingBottom: 16
		}
	},
	pendingBox: {
		position: 'absolute',
		top: 0,
		left: 0,
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		background: 'rgba(255,255,255,0.5)'
	},
	disabledBox: {
		position: 'absolute',
		top: 0,
		left: 0,
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		zIndex: 1,
		background: 'rgba(245,245,245,0.7)',
		'&:hover $disabledBoxText': {
			opacity: 1
		}
	},
	disabledCard: {},
	disabledBoxText: {
		opacity: 0,
		transition: 'opacity 300ms ease-in-out',
		whiteSpace: 'break-spaces'
	},
	titleRoot: {
		marginBottom: theme.spacing(1)
	},
	descriptionRoot: {
		color: theme.palette.text.secondary,
		flex: 1,
		fontWeight: theme.typography.fontWeightLight,
		lineHeight: 1.2
	},
	descriptionRootSelected: {
		color: theme.palette.primary.main
	},
	footRoot: {
		display: 'flex',
		alignItems: 'center',
		minHeight: 26
	}
}));

const CardBase = ({ selected, record, onClick, renderStatus, pending, disabled, ...rest }) => {
	const classes = useStyles();
	const { translate } = useLocale();
	const { name, description } = record;

	const handleClick = (e) => {
		if (disabled) return;

		e.preventDefault();
		if (onClick) onClick(e, record);
	};

	return (
		<Card
			elevation={0}
			onClick={handleClick}
			classes={{
				root: clsx(classes.cardRoot, {
					[classes.cardRootSelected]: selected
				})
			}}
			{...rest}>
			{disabled && (
				<Box className={classes.disabledBox}>
					<Typography variant='body2' className={classes.disabledBoxText}>
						{translate('Common_FeatureAvailableInPremiumPackage')}
					</Typography>
				</Box>
			)}
			<CardContent classes={{ root: classes.contentRoot }} className={clsx({ [classes.disabledCard]: disabled })}>
				{pending && (
					<Box className={classes.pendingBox}>
						<BaseCircularProgress pending={pending} />
					</Box>
				)}

				<Typography variant='body1' classes={{ root: classes.titleRoot }} data-at='cardName'>
					{translate(name)}
				</Typography>
				<Typography
					data-at='cardDescription'
					variant='caption'
					component='p'
					classes={{
						root: clsx(classes.descriptionRoot, { [classes.descriptionRootSelected]: selected })
					}}>
					{translate(description)}
				</Typography>
				<Box className={classes.footRoot} data-at='cardStatus'>
					{renderStatus && renderStatus()}
				</Box>
			</CardContent>
		</Card>
	);
};

export default CardBase;
