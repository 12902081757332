import { apiBase } from '@sonar-web/common';
import { REST_API_PATHS } from '@sonar-web/common/src/constants/system';

const path = REST_API_PATHS.EXPORT;

async function fetchCurrentNumberOfExports() {
	const { data } = await apiBase(`currentNumberOfExports`, 'get', {
		path
	});

	return data;
}

async function shareExport({ id, recipients }) {
	const { data } = await apiBase(`exports/${id}/share`, 'post', {
		path,
		data: {recipients}
	});

	return data;
}

const ExportsService = {
	fetchCurrentNumberOfExports,
	shareExport
};

export default ExportsService;
