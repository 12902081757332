import React from 'react';
import { FormControl, InputLabel, Select } from '@mui/material';
import { useLocale } from '@sonar-web/common/src/hooks';
import BaseSelectMenuItem from '@sonar-web/common/src/components/BaseSelectMenuItem/BaseSelectMenuItem';
import { dateUnitOrders } from './constants';

const name = 'dateUnitOrder';
const SettingsDateUnitOrder = ({ updateSettings, current }) => {
	const { translate } = useLocale();

	const options = [
		{
			value: dateUnitOrders.dmy,
			label: `${translate('Common_Day')}, ${translate('Common_Month')}, ${translate('Common_Year')}`
		},
		{
			value: dateUnitOrders.dym,
			label: `${translate('Common_Day')}, ${translate('Common_Year')}, ${translate('Common_Month')}`
		},
		{
			value: dateUnitOrders.mdy,
			label: `${translate('Common_Month')}, ${translate('Common_Day')}, ${translate('Common_Year')}`
		},
		{
			value: dateUnitOrders.myd,
			label: `${translate('Common_Month')}, ${translate('Common_Year')}, ${translate('Common_Day')}`
		},
		{
			value: dateUnitOrders.ydm,
			label: `${translate('Common_Year')}, ${translate('Common_Day')}, ${translate('Common_Month')}`
		},
		{
			value: dateUnitOrders.ymd,
			label: `${translate('Common_Year')}, ${translate('Common_Month')}, ${translate('Common_Day')}`
		}
	];

	const handleChange = (e) => {
		updateSettings(name, e.target.value);
	};

	return (
		<FormControl fullWidth margin='normal'>
			<InputLabel id={name}>{translate('Common_DateUnitOrder')}</InputLabel>
			<Select labelId={name} value={current[name]} onChange={handleChange}>
				{options.map((opt, index) => (
					<BaseSelectMenuItem key={index} value={opt.value}>
						{translate(opt.label)}
					</BaseSelectMenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default SettingsDateUnitOrder;
