import makeStyles from '@mui/styles/makeStyles';
import useBuilingVisualization from './useBuilingVisualization';
import { VisualizationType } from '../../Enums/VisualizationType';

const useStyles = makeStyles(() => ({
	root: {
		height: ({ visualizationType }) => (visualizationType === VisualizationType.All.value ? '2px' : '5px'),
		backgroundColor: ({ visualizationType }) =>
			visualizationType === VisualizationType.All.value ? '#fff' : '#F1F1F1'
	}
}));

const FloorSeparator = () => {
	const { visualizationType } = useBuilingVisualization();
	const classes = useStyles({ visualizationType });

	return <div className={'floorSeparator ' + classes.root} />;
};

export default FloorSeparator;
