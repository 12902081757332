import useList from '@sonar-web/common/src/features/List/useList';

export default (slice) => {
	const { updateNotifications } = useList(slice);

	const setNotification = (name, value = null) => {
		updateNotifications({
			name,
			value
		});
	};

	const resetNotification = (name) => {
		setTimeout(
			() =>
				updateNotifications({
					name
				}),
			50
		);
	};

	return { setNotification, resetNotification };
};
