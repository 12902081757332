import clsx from 'clsx';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

const useStyles = makeStyles((theme) => ({
	tile: {
		display: 'flex',
		position: 'relative',
		alignSelf: 'stretch',
		padding: 0,
		textDecoration: 'none',
		backgroundColor: theme.palette.common.white,
		boxShadow: '0 0 8px 0 rgba(0,0,0,0.03)',
		border: `1px solid ${theme.palette.border.main}`,
		color: theme.palette.primary.main,
		borderRadius: 8,
		transition: 'border-color 100ms ease-in',
		alignItems: 'center',
		justifyContent: 'center'
	},
	tileSelected: {
		borderColor: `${theme.palette.border.selected} !important`
	},
	tileSelectedBackground: {
		backgroundColor: theme.palette.primaryLight.main
	},
	tileHover: {
		'&:hover': {
			cursor: 'pointer',
			borderColor: theme.palette.border.hover
		}
	},
	tileHoverBackground: {
		'&:hover': {
			backgroundColor: theme.palette.primaryLight.light
		}
	},
	radioIcon: {
		fontSize: '10px',
		position: 'absolute',
		right: '10px',
		top: '10px'
	}
}));

const TileBase = ({
	onClick,
	renderContent,
	selected = false,
	showRadio = false,
	hoverEffect = true,
	hoverBackground = false,
	selectedBackgroundEffect = false,
	...rest
}) => {
	const classes = useStyles();

	const handleClick = (e) => {
		e.preventDefault();

		if (onClick) onClick();
	};

	return (
		<Box
			className={clsx(`${classes.tile} tileBase`, {
				[classes.tileSelected]: selected,
				[classes.tileSelectedBackground]: selected && selectedBackgroundEffect,
				[classes.tileHover]: hoverEffect,
				[classes.tileHoverBackground]: hoverBackground
			})}
			onClick={handleClick}
			{...rest}>
			{showRadio &&
				(selected ? (
					<RadioButtonCheckedIcon
						classes={{
							root: classes.radioIcon
						}}
					/>
				) : (
					<RadioButtonUncheckedIcon
						classes={{
							root: classes.radioIcon
						}}
					/>
				))}
			{renderContent(selected)}
		</Box>
	);
};

export default TileBase;
