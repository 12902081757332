import { useState } from 'react';
import { splitDateTimeFormat } from './helpers';
import SettingsDate from './SettingsDate';
import SettingsTime from './SettingsTime';

const settingName = 'dateTimeFormat';
const dateSettingName = 'dateFormat';
const timeSettingName = 'timeFormat';

const SettingsDateTime = ({ updateSettings, current }) => {
	const { dateFormat, timeFormat } = splitDateTimeFormat(current[settingName]);

	const defaultDatetimeFormat = {
		[dateSettingName]: dateFormat,
		[timeSettingName]: timeFormat
	};

	const [currentDatetimeFormat, setDatetimeFormat] = useState(defaultDatetimeFormat);

	const updateCurrentDatetimeFormat = (settingName, value) => {
		let currentNew = { ...currentDatetimeFormat };
		currentNew[settingName] = value;
		setDatetimeFormat(currentNew);
	};

	const udpateCurrentDateFormat = (value) => {
		const { timeFormat } = splitDateTimeFormat(current[settingName]);

		const newDateTimeFormat = value.concat(' ', timeFormat);

		updateSettings(settingName, newDateTimeFormat);
		updateCurrentDatetimeFormat(dateSettingName, value);
	};

	const udpateCurrentTimeFormat = (value) => {
		const { dateFormat } = splitDateTimeFormat(current[settingName]);
		const newDateTimeFormat = dateFormat.concat(' ', value);

		updateSettings(settingName, newDateTimeFormat);
		updateCurrentDatetimeFormat(timeSettingName, value);
	};

	return (
		<>
			<SettingsDate
				updateSettings={(sname, svalue) => {
					udpateCurrentDateFormat(svalue);
				}}
				current={currentDatetimeFormat}
				settingName={dateSettingName}
			/>
			<SettingsTime
				updateSettings={(sname, svalue) => {
					udpateCurrentTimeFormat(svalue);
				}}
				current={currentDatetimeFormat}
				settingName={timeSettingName}
			/>
		</>
	);
};

export default SettingsDateTime;
