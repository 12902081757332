export const moduleName = 'orders';

export const slices = {
	order: 'order',
	currentOrders: 'currentOrders',
	tasks: 'tasks',
	task: 'task',
	orderStatistics: 'orderStatistics',
	availableLocations: 'availableLocations',
	currentLocations: 'currentLocations',
	currentPointsInOrder: 'currentPointsInOrder',
	archivedOrders: 'archivedOrders',
	protocolTemplate: 'protocolTemplate',
	contractors: 'contractors',
	ordersPacks: 'ordersPacks',
	availableOrders: 'availableOrders',
	ordersInPack: 'ordersInPack'
};

export const routes = {
	currentOrders: () => '/orders/current',
	archivedOrders: () => '/orders/archived',
	orderNew: () => '/orders/new',
	orderCopy: (props) => (props ? `/orders/${props.orderId}/copy` : `/orders/:orderId/copy`),
	orderPage: (props) => (props ? `/orders/${props.orderId}` : `/orders/:orderId`),
	protocolTemplate: () => '/protocol/template',
	contractors: () => '/orders/contractors',
	orderedOrdersPacks: () => '/orderedOrdersPacks',
	receivedOrdersPacks: () => '/receivedOrdersPacks',
	ordersPackPage: (props) => (props ? `/ordersPack/${props.ordersPackId}` : `/ordersPack/:ordersPackId`),
	ordersPackPageSimple: (props) =>
		props ? `/ordersPack/${props.ordersPackId}/simple` : `/ordersPack/:ordersPackId/simple`
};

export const CURRENT_ORDERS_LIST_MENU = 'CURRENT_ORDERS_LIST_MENU';
