import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { selectBuildingVisualization } from './buildingVisualizationActions';
import Floor from './Floor';
import FloorSeparator from './FloorSeparator';
import StaircaseIndicator from './StaircaseIndicator';
import useBuilingVisualization from './useBuilingVisualization';
import { VisualizationType } from '../../Enums/VisualizationType';
import FloorIndicatorWrapper from './FloorIndicatorWrapper';
import BuildingVisualizationLegend from './BuildingVisualizationLegend';

const Wrapper = styled('div')({
	display: 'flex',
	height: '100%',
	width: '100%',
	paddingBottom: '1rem',
	'&.notAllView': {
		overflow: 'auto'
	},
	//prawa borderka dla ostatniej klatki
	'& .staircase-wrapper:last-of-type .staircase-floors': {
		borderRightWidth: 1
	},
	'& .staircase:last-of-type': {
		marginRight: 5
	},
	'& .floorSeparator:last-of-type': {
		backgroundColor: 'transparent'
	}
});

const Building = () => {
	const { visualizationType } = useBuilingVisualization();
	const { staircases, floors, allFloors, maxLocalsPerStaircase, allLocalsCount } = useSelector(
		selectBuildingVisualization,
		shallowEqual
	);

	const allView = visualizationType === VisualizationType.All.value;

	let wrapperClasses = '';
	if (!allView) wrapperClasses += 'notAllView';

	return (
		<>
			<Wrapper className={wrapperClasses}>
				{!allView && <FloorIndicatorWrapper key={visualizationType} allFloors={allFloors} />}
				{staircases.map((s, staircaseIndex) => {
					const floorsInStaircase = floors.filter((fl) => fl.staircase === s);
					const localsCount = maxLocalsPerStaircase[s] || 0;
					const staircaseWidth = !allView ? 'auto' : `${Math.round((localsCount / allLocalsCount) * 100)}%`;

					return (
						<Box key={s} width={staircaseWidth} className='staircase'>
							{!allView && <StaircaseIndicator name={s} firstStaircase={staircaseIndex === 0} />}
							{allFloors.map((af) => {
								const floor = floorsInStaircase.find((fl) => fl.name === af);

								return (
									<React.Fragment key={floor?.name ?? af}>
										<Floor
											staircase={s}
											floor={floor}
											floorName={af}
											firstStaircase={staircaseIndex === 0}
											locals={floor?.locals ?? []}
											maxLocalsPerStaircase={localsCount}
										/>
										<FloorSeparator />
									</React.Fragment>
								);
							})}
						</Box>
					);
				})}
			</Wrapper>
			<BuildingVisualizationLegend pointsView={visualizationType === VisualizationType.Points.value} />
		</>
	);
};

export default Building;
