import { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { useDebounce, useLocale } from '@sonar-web/common/src/hooks';
import { YupSchemaDefinitions } from '@sonar-web/common/src/validation/yupValidation';

const nameSchema = YupSchemaDefinitions.shortString();

const ReportSubmitName = ({ configuratorData, updateConfiguratorData, property, setDisabled }) => {
	const { translate } = useLocale();
	const [value, setValue] = useState(configuratorData[property] ?? '');
	const [error, setError] = useState('');
	const debounceValue = useDebounce(value, 100);

	const handleValidation = (v, err) => {
		const shouldDisable = v === '' || err;

		setDisabled(Boolean(shouldDisable));
		setValue(v);
		setError(shouldDisable ? err : '');
	};

	const handleChange = (e) => {
		const name = e.target.value;

		try {
			nameSchema.validateSync(name);
			handleValidation(name);
		} catch (err) {
			handleValidation(name, err.errors[0]);
		}
	};

	useEffect(() => {
		updateConfiguratorData({ [property]: debounceValue });
	}, [debounceValue]);

	return (
		<TextField
			autoFocus
			margin='normal'
			name='name'
			label={translate('ReportName')}
			type='text'
			size='small'
			fullWidth
			value={value}
			onChange={handleChange}
			error={error !== ''}
			helperText={error !== '' && translate(error)}
		/>
	);
};

export default ReportSubmitName;
