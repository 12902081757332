import React from 'react';
import { useLocale } from '@sonar-web/common/src/hooks';
import SettingsDateUnitSeparator from './SettingsDateUnitSeparator';
import SettingsDateUnitOrder from './SettingsDateUnitOrder';
import SettingsDayMonthFormat from './SettingsDayMonthFormat';
import SettingsYearFormat from './SettingsYearFormat';
import SettingsTemplate from './SettingsTemplate';
import { dateUnitOrders, dayMonthFormats, yearFormats, dateUnitSeparators, sampleDate } from './constants';
import { getDateFormat, dateTimeToString } from './helpers';

const SettingsDate = ({ updateSettings, current, settingName = 'dateFormat' }) => {
	const { translate } = useLocale();

	const predefinedOptions = [
		{
			value: 'dd.MM.yyyy',
			label: `${translate('Common_Day')}.${translate('Common_Month')}.${translate(
				'Common_Year'
			)} (${dateTimeToString(sampleDate, 'dd.MM.yyyy')})`
		},
		{
			value: 'yyyy-MM-dd',
			label: `${translate('Common_Year')}-${translate('Common_Month')}-${translate(
				'Common_Day'
			)} (${dateTimeToString(sampleDate, 'yyyy-MM-dd')})`
		},
		{
			value: 'dd/MM/yyyy',
			label: `${translate('Common_Day')}/${translate('Common_Month')}/${translate(
				'Common_Year'
			)} (${dateTimeToString(sampleDate, 'dd/MM/yyyy')})`
		},
		{
			value: 'MM.dd.yyyy',
			label: `${translate('Common_Month')}.${translate('Common_Day')}.${translate(
				'Common_Year'
			)} (${dateTimeToString(sampleDate, 'MM.dd.yyyy')})`
		}
	];

	const defaultManualSettings = {
		dateUnitOrder: dateUnitOrders.dmy,
		dayMonthFormat: dayMonthFormats.single,
		yearFormat: yearFormats.full,
		dateUnitSeparator: dateUnitSeparators.dot
	};

	return (
		<SettingsTemplate
			current={current}
			updateSettings={updateSettings}
			sampleDate={sampleDate}
			defaultManualSettings={defaultManualSettings}
			predefinedOptions={predefinedOptions}
			manualSettingsToFormat={getDateFormat}
			settingName={settingName}
			label={'Common_DateFormat'}
			renderManualModeItems={({ currentManualSettings, updateCurrentManualSettings }) => (
				<>
					<SettingsDateUnitOrder
						updateSettings={updateCurrentManualSettings}
						current={currentManualSettings}
					/>
					<SettingsDayMonthFormat
						updateSettings={updateCurrentManualSettings}
						current={currentManualSettings}
					/>
					<SettingsYearFormat updateSettings={updateCurrentManualSettings} current={currentManualSettings} />
					<SettingsDateUnitSeparator
						updateSettings={updateCurrentManualSettings}
						current={currentManualSettings}
					/>
				</>
			)}
		/>
	);
};

export default SettingsDate;
