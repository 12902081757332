import { lazy } from 'react';
import { Features } from '@sonar/auth';

const NetworksPage = lazy(() => import('./Networks/NetworksPage'));
const NetworkPage = lazy(() => import('./Network/NetworkPage'));

export default [
	{
		element: NetworksPage,
		feature: Features.NetworksPage.name,
		path: '/wmbusnetworks',
		exact: true,
		name: 'Common_StationaryNetwork'
	},
	{
		element: NetworkPage,
		feature: Features.NetworkPage.name,
		path: `/wmbusnetworks/:id`,
		exact: true,
		name: '{0}'
	}
];
