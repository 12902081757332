import { useEffect } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { fetchBuildingVisualizationAsync, selectBuildingVisualization } from './buildingVisualizationActions';
import NoBuildingVisualization from './NoBuildingVisualization';
import Building from './Building';
import BuildingFilters from './BuildingFilters';
import BuildingControls from './BuildingControls';
import useLocationNavigation from '@sonar-web/location/src/Hooks/useLocationNavigation';
import { BaseLinearProgress } from '@sonar-web/common';

const Wrapper = styled('div')({
	display: 'flex',
	height: '100%',
	minHeight: '0%',
	flexDirection: 'column',
	'& .buildingToolbar': {
		marginBottom: '2rem'
	}
});

const BuildingVisualization = ({ location }) => {
	const dispatch = useDispatch();
	const { filters } = useLocationNavigation();
	const { data, pending } = useSelector(selectBuildingVisualization);

	const { dateRangeDate, layer } = filters;

	useEffect(() => {
		if (!location || !dateRangeDate) return;

		dispatch(fetchBuildingVisualizationAsync(location.id, { layer, dateRangeDate: dateRangeDate }));
	}, [layer, dateRangeDate]);

	return (
		<Wrapper>
			{pending && <BaseLinearProgress pending={true} />}
			<Visualization pending={pending} data={data} />
		</Wrapper>
	);
};

export default BuildingVisualization;

const Visualization = ({ data, pending }) => {
	if (!data) return <NoBuildingVisualization />;
	if (!checkStructureIsOk(data)) return <NoBuildingVisualization />;
	return (
		<>
			<Box display='flex' className='buildingToolbar' gap='.5rem'>
				<BuildingFilters />
				<BuildingControls />
			</Box>
			{!pending && <Building />}
		</>
	);
};

function checkStructureIsOk(data) {
	if (data.length === 0) return false;

	const hasFloors = data[0].floors?.length > 0;
	if (!hasFloors) return false;

	return data[0].floors[0].locals?.length > 0;
}
