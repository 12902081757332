import { apiBase } from '@sonar-web/common';
import { REST_API_PATHS } from '@sonar-web/common/src/constants/system';

const path = REST_API_PATHS.WATERMETERS;

export async function fetchWatermeter(id) {
	const { data } = await apiBase(`watermeters/${id}`, 'get', {
		path
	});

	return data;
}

export async function addWatermeter(watermeter) {
	const response = await apiBase(`watermeters`, 'post', {
		path,
		data: watermeter
	});

	const watermeterHeader = response.headers.location;

	if (watermeterHeader)
		return {
			url: watermeterHeader,
			id: watermeterHeader.slice(watermeterHeader.indexOf('/') + 1)
		};

	return response;
}

export async function editWatermeter(watermeter) {
	const data = await apiBase(`watermeters/${watermeter.id}`, 'put', {
		path,
		data: watermeter
	});

	return data;
}
