import { cloneDeep } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import { slices } from '../../Constants/Module';
import { addTask, editTask, fetchTask } from './tasksApi';

const slice = slices.task;
const initial = {
	task: {
		name: '',
		type: '',
		settings: []
	},
	isEdit: false,
	isValid: false,
	isReadOnly: false,
	taskFetchPending: false,
	taskSubmitPending: false,
	taskSubmitSuccess: false
};

export const taskSlice = createSlice({
	name: slice,
	initialState: {
		...initial
	},
	reducers: {
		updateTask: (state, { payload }) => {
			const index = state.task.settings.findIndex((s) => s.key === payload.key);

			if (index === -1) {
				state.task.settings.push(payload);
				return;
			}

			if (payload.value === null) state.task.settings.splice(index, 1);
			else state.task.settings[index] = payload;
		},
		updateTaskSettings: (state, { payload }) => {
			state.task.settings = payload;
		},
		updateTaskName: (state, { payload }) => {
			state.task.name = payload;
		},
		updateTaskType: (state, { payload }) => {
			state.task.type = payload;
		},
		fetchTaskPending: (state, { payload }) => {
			state.taskFetchPending = payload;
		},
		fetchTaskSuccess: (state, { payload }) => {
			state.taskFetchPending = false;
			state.task = payload;
		},
		submitTaskPending: (state, { payload }) => {
			state.taskSubmitPending = payload;
		},
		submitTaskSuccess: (state) => {
			state.taskSubmitSuccess = true;
			state.taskSubmitPending = false;
		},
		setInitialState: (state) => {
			for (let key in initial) {
				state[key] = initial[key];
			}
		},
		setIsValid: (state, { payload }) => {
			state.isValid = payload;
		},
		setIsReadOnly: (state, { payload }) => {
			state.isReadOnly = payload;
		},
		setTask: (state, { payload }) => {
			state.task = payload;
		}
	}
});

export const {
	updateTask,
	fetchTaskPending,
	fetchTaskSuccess,
	removeTask,
	submitTaskPending,
	submitTaskSuccess,
	updateTaskName,
	setInitialState,
	setIsValid,
	updateTaskSettings,
	setIsReadOnly,
	updateTaskType,
	setTask
} = taskSlice.actions;

export const submitTaskAsync = () => async (dispatch, getState) => {
	try {
		const { task, isEdit } = getState().task;
		const submitFunc = isEdit ? editTask : addTask;
		let submitData = cloneDeep(task);

		dispatch(submitTaskPending(true));

		await submitFunc(submitData);

		dispatch(submitTaskSuccess());
		setTimeout(() => dispatch(setInitialState()), 100);
	} catch (error) {
		dispatch(submitTaskPending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const fetchTaskAsync = (id) => async (dispatch) => {
	try {
		dispatch(fetchTaskPending(true));
		const { specification } = await fetchTask(id);
		dispatch(fetchTaskSuccess(specification));
	} catch (error) {
		dispatch(fetchTaskPending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const selectTask = (state) => {
	return {
		task: state.task.task,
		pending: state.task.taskFetchPending
	};
};

export const selectIsEdit = (state) => state.task.isEdit;
export const selectIsValid = (state) => state.task.isValid;
export const selectIsReadOnly = (state) => state.task.isReadOnly;

export const selectTaskSubmit = (state) => {
	return { taskSubmitPending: state.task.taskSubmitPending, taskSubmitSuccess: state.task.taskSubmitSuccess };
};

export default taskSlice.reducer;
