import React from 'react';
import clsx from 'clsx';
import { Box } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
	tile: {
		display: 'flex',
		position: 'relative',
		textDecoration: 'none',
		backgroundColor: theme.palette.common.white,
		border: `1px solid ${theme.palette.common.white}`,
		color: theme.palette.primary.main,
		borderRadius: 8,
		alignItems: 'center',
		justifyContent: 'center'
	},
	tileSelected: {
		borderColor: `${theme.palette.border.selected} !important`,
		color: theme.palette.common.white,
		backgroundColor: theme.palette.primary.main
	},
	tileHover: {
		'&:hover': {
			cursor: 'pointer',
			borderColor: theme.palette.border.hover,
			boxShadow: '0 0 8px 0 rgba(0,0,0,0.03)',
			transition: 'border-color 100ms ease-in'
		}
	},
	tileText: {
		userSelect: 'none'
	}
}));

const TileItem = ({ onClick, text, selected = false, hoverEffect = true, ...rest }) => {
	const classes = useStyles();

	const handleClick = (e) => {
		e.preventDefault();

		if (onClick) onClick();
	};

	return (
		<Box
			className={clsx(`${classes.tile} tileBase`, {
				[classes.tileSelected]: selected,
				[classes.tileHover]: hoverEffect
			})}
			onClick={handleClick}
			{...rest}>
			<div className={`tileItemText ${classes.tileText}`}>{text}</div>
		</Box>
	);
};

export default TileItem;
