import { useRef } from 'react';
import { Backdrop, Grow, Paper, Popper } from '@mui/material';
import { BaseTooltip, useFilters } from '..';
import { useLocale } from '../../hooks';
import FiltersActiveIcon from '../../icons/FiltersActiveIcon';
import FiltersIcon from '../../icons/FiltersIcon';
import BaseIconButton from '../Buttons/BaseIconButton';
import { useListFiltersContext } from './listFiltersContext';

const ListFiltersToggle = ({ slice, filtersIgnored, noData, Filters, filtersProps }) => {
	const { translate } = useLocale();
	const anchorRef = useRef(null);
	const { anchor, openFilters, closeFilters } = useListFiltersContext();
	const { hasCurrentFilters } = useFilters(slice);

	const toggleInfo = anchor ? 'Common_HideFilters' : 'Common_ShowFilters';

	const hasFilters = hasCurrentFilters(filtersIgnored);

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) return;

		closeFilters();
	};

	if (!Filters) return null;
	return (
		<>
			<BaseTooltip text={translate(toggleInfo)}>
				<div>
					<BaseIconButton
						ref={anchorRef}
						icon={hasFilters ? FiltersActiveIcon : FiltersIcon}
						iconProps={{ secondaryPathClass: 'secondaryColor' }}
						color='primary'
						onClick={openFilters}
						name='ListActionToolbarToggleFilters'
						data-at-params={`${toggleInfo}|hasCurrentFilters|${hasFilters}`}
						sx={{
							visibility: noData ? 'hidden' : 'visible'
						}}
					/>
				</div>
			</BaseTooltip>
			<Popper
				open={Boolean(anchor)}
				anchorEl={anchorRef.current}
				role='searchbox'
				keepMounted
				transition
				placement='bottom-end'
				sx={{ zIndex: 'modal' }}>
				{({ TransitionProps }) => (
					<Grow {...TransitionProps} sx={{ transformOrigin: 'top right' }}>
						<Paper>
							<div>
								<Filters {...filtersProps} />
							</div>
						</Paper>
					</Grow>
				)}
			</Popper>
			<Backdrop open={Boolean(anchor)} onClick={handleClose} sx={{ zIndex: 'modal', opacity: '0 !important' }} />
		</>
	);
};

export default ListFiltersToggle;
