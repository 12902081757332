import moment from 'moment';

/**
 * @summary - fills missing dates in reads data
 * @param {Array} readsData - array of objects with date and volume
 * @returns {Array} - array of objects with date and volume
 */
export const fillMissingDates = (readsData, initialFrom, initialTo) => {
	const noReads = !readsData || readsData.length === 0;
	if (noReads) return [];
	const readsMap = new Map(
		readsData.map((data) => [
			moment.utc(moment(data.date).hours(0).minutes(0).seconds(0)).format('YYYY-MM-DDTHH:mm:ss[Z]'),
			data.date
		])
	);
	const minDate = noReads ? initialFrom : readsData[0].date;
	const maxDate = noReads ? initialTo : readsData[readsData.length - 1].date;
	const dateArray = [];

	const currentDate = moment(minDate).hours(0).minutes(0).seconds(0);

	while (currentDate <= moment(maxDate)) {
		dateArray.push(moment.utc(currentDate).format('YYYY-MM-DDTHH:mm:ss[Z]'));
		currentDate.add(1, 'days');
	}

	const missingDaysData = dateArray
		.filter((date) => {
			return !readsMap.has(date);
		})
		.map((missingDate) => ({
			date: missingDate,
			volume: null
		}));

	const filledReadsData = [...readsData, ...missingDaysData].sort((a, b) => new Date(a.date) - new Date(b.date));

	return filledReadsData;
};

/**
 * @summary - returns animation settings for line chart
 * @param {Array} data - array of objects with date and volume
 * @returns {Object} - object with animation settings
 */
export const lineAnimation = (data) => {
	const totalDuration = 500;
	const delayBetweenPoints = totalDuration / data.length;

	const previousY = (ctx) =>
		ctx.index === 0
			? ctx.chart.scales.y.getPixelForValue(100)
			: ctx.chart.getDatasetMeta(ctx.datasetIndex).data[ctx.index - 1].getProps(['y'], true).y;

	return {
		x: {
			type: 'number',
			easing: 'linear',
			duration: delayBetweenPoints,
			from: NaN,
			delay(ctx) {
				if (ctx.type !== 'data' || ctx.xStarted) {
					return 0;
				}
				ctx.xStarted = true;
				return ctx.index * delayBetweenPoints;
			}
		},
		y: {
			type: 'number',
			easing: 'linear',
			duration: delayBetweenPoints,
			from: previousY,
			delay(ctx) {
				if (ctx.type !== 'data' || ctx.yStarted) {
					return 0;
				}
				ctx.yStarted = true;
				return ctx.index * delayBetweenPoints;
			}
		}
	};
};

/**
 * @summary - custom plugin for chart background color
 */
export const customCanvasBackgroundColor = {
	id: 'customCanvasBackgroundColor',
	beforeDraw: (chart, _, options) => {
		const { ctx } = chart;
		ctx.save();
		ctx.globalCompositeOperation = 'destination-over';
		ctx.fillStyle = options.color || '#99ffff';
		ctx.fillRect(0, 0, chart.width, chart.height);
		ctx.restore();
	}
};
