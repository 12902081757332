import { LinearProgress } from '@mui/material';

const BaseLinearProgress = ({ pending, ...rest }) => {
	return (
		<LinearProgress
			sx={{
				height: '2px',
				minHeight: '2px',
				marginBottom: '.5rem',
				visibility: pending ? 'visible' : 'hidden',
				...rest?.sx
			}}
		/>
	);
};

export default BaseLinearProgress;
