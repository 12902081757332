import { useState } from 'react';
import BaseDateTimePicker from '../BaseDateTimePicker/BaseDateTimePicker';

const FormikDateTimePicker = ({ field, form, ...rest }) => {
	const [isOpen, setIsOpen] = useState(false);

	const { errors, touched, setFieldValue } = form;

	const currentError = errors[field.name];
	const isTouched = touched?.[field.name];

	const handleOnChange = (v) => setFieldValue('date', v);

	return (
		<BaseDateTimePicker
			open={isOpen}
			onOpen={() => setIsOpen(true)}
			name={field.name}
			value={field.value}
			helperText={isTouched ? currentError : ''}
			error={Boolean(currentError) && isTouched}
			variant='inline'
			strictCompareDates
			onChange={handleOnChange}
			onClose={() => setIsOpen(false)}
			fullWidth={true}
			{...rest}
		/>
	);
};

export default FormikDateTimePicker;
