import { createSelector, createSlice } from '@reduxjs/toolkit';
import ImportService from '../ImportService';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import { guidEmpty } from '@sonar-web/common';
import { cloneDeep } from 'lodash';
import ImportWizardHelper from './ImportWizardHelper';

const steps = ImportWizardHelper.steps;

const initialState = {
	activeTab: 0,
	lastActiveTab: 0,
	stepValidation: steps.reduce((acc) => {
		acc.push(false);
		return acc;
	}, []),
	visitedTab: steps.reduce((acc, _, i) => {
		acc.push(i === 0);
		return acc;
	}, []),
	importId: '',
	templatePending: {
		system: false,
		user: false
	},
	templates: {
		system: [],
		user: []
	},
	selectedTemplate: { id: '', type: '', importType: '' },
	currentSettings: null,
	templateSettingsPending: false,
	templateSettingsColumns: null,
	templateSettingsColumnsSelected: [],
	columnValidityQueue: [],
	columnValidityQueuePending: null,
	addOrEditTemplatePending: false,
	addOrEditTemplateSuccess: false,
	deleteTemplatePending: false,
	deleteTemplateSuccess: false,
	startImportPending: false,
	startImportSuccess: false,
	skippedIndexes: [],
	lastSavedTemplateConfiguration: null,
	saveTemplate: false,
	file: {
		name: '',
		isValid: false,
		columns: [],
		reloadFileData: true
	}
};

const slice = 'importWizard';
export const importWizardWizardSlice = createSlice({
	name: slice,
	initialState: initialState,
	reducers: {
		setActiveTab: (state, action) => {
			state.lastActiveTab = state.activeTab;
			state.activeTab = action.payload;
			if (!state.visitedTab[action.payload]) state.visitedTab[action.payload] = true;
		},
		updateStepValidation: (state, action) => {
			state.stepValidation = { ...state.stepValidation, ...action.payload };
		},
		restoreOriginSettings: (state, action) => {
			const { formatProperties } = action.payload;

			state.currentSettings.formatProperties = { ...state.currentSettings.formatProperties, ...formatProperties };
		},
		resetImportWizard: (state) => {
			for (const is in initialState) {
				state[is] = initialState[is];
			}
		},
		setWizardSelectedFile: (state, action) => {
			const { fileName } = action.payload;

			state.file = { ...state.file, name: fileName, isValid: true };
		},
		setWizardCurrentImportId: (state, action) => {
			state.importId = action.payload;
		},
		fetchTemplatesSucces: (state, action) => {
			const { type, value } = action.payload;

			state.templatePending[type] = false;
			state.templates[type] = value.elements;
		},
		fetchTemplatesPending: (state, action) => {
			const { type, value } = action.payload;

			state.templatePending[type] = value;
		},
		fetchTemplateSettingsSucces: (state, { payload }) => {
			let settings = Object.assign({}, payload);

			state.currentSettings = payload ? settings : null;
			state.templateSettingsPending = false;
			state.lastSavedTemplateConfiguration = settings;

			if (!settings.columns) return;

			let columns = [];

			for (let s in settings.columns) {
				const allColumns = [...settings.columns[s].columns];
				const allColumnsWithGroup = allColumns.map((ac) => {
					return { ...ac, groupName: settings.columns[s].groupName };
				});

				columns = [...columns, ...allColumnsWithGroup];
			}

			state.templateSettingsColumnsSelected = [];
			state.templateSettingsColumns = columns;
			state.skippedIndexes = settings.skippedIndexes || [];

			state.currentSettingsOrigin = settings;
			state.templateSettingsColumnsOrigin = columns;

			state.file.reloadFileData = true;
		},
		resetTemplateColumnSettings: (state) => {
			state.currentSettings = { ...state.currentSettings, columns: state.currentSettingsOrigin.columns };
			state.templateSettingsColumnsSelected = [];
			state.templateSettingsColumns = state.templateSettingsColumnsOrigin;
			state.skippedIndexes = state.currentSettingsOrigin.skippedIndexes;
			state.visitedTab[ImportWizardHelper.views.mapping] = false; //reset zakładki mapowania inaczej kontrolki, które już były wcześniej wyrenderowane nie aktualizują wybranych kolumn
		},
		fetchTemplateSettingsPending: (state, action) => {
			state.templateSettingsPending = action.payload ?? false;
		},
		updateTemplateSettingsColumns: (state, action) => {
			const { toAdd, toDelete } = action.payload;
			let previousColumnsSelected = state.templateSettingsColumnsSelected.slice();

			if (toDelete) {
				const index = previousColumnsSelected.findIndex(
					(pcs) => pcs.systemName === toDelete.systemName && pcs.groupName === toDelete.groupName
				);
				if (index === -1) return;

				previousColumnsSelected.splice(index, 1);
			}

			state.templateSettingsColumnsSelected = toAdd
				? [...previousColumnsSelected, toAdd]
				: previousColumnsSelected;
		},
		updateCurrentSettings: (state, action) => {
			const { currentSettings, formatProperties } = action.payload;

			if (currentSettings) state.currentSettings = { ...state.currentSettings, ...currentSettings };
			if (formatProperties)
				state.currentSettings.formatProperties = {
					...state.currentSettings.formatProperties,
					...formatProperties
				};
		},
		setSelectedTemplate: (state, action) => {
			state.visitedTab = initialState.visitedTab;
			state.stepValidation = initialState.stepValidation;
			state.selectedTemplate = action.payload;
		},
		updateColumnValidityQueue: (state, action) => {
			const { systemName, index, groupName, actionType } = action.payload;

			if (actionType === 'add') state.columnValidityQueue.push({ systemName, index, groupName });
			if (actionType === 'delete') {
				const temp = [...state.columnValidityQueue];
				const idx = temp.findIndex((t) => t.systemName === systemName);

				if (idx === -1) return;

				temp.splice(idx, 1);
				state.columnValidityQueue = temp;
			}
		},
		removePreviousColumnMapping: (state, action) => {
			const currentColumnsGroups = state.currentSettings.columns;

			for (const [i, ccg] of currentColumnsGroups.entries()) {
				const columns = ccg.columns;
				const columnIndex = columns.findIndex((c) => c.index === action.payload);

				if (columnIndex === -1) continue;

				const newColumnData = {
					systemName: columns[columnIndex].systemName,
					index: null,
					required: columns[columnIndex].required
				};

				state.currentSettings.columns[i].columns[columnIndex] = newColumnData;

				break;
			}
		},
		setColumnMapped: (state, action) => {
			const { column, mapState } = action.payload;
			const currentColumnsGroups = state.currentSettings.columns;

			for (const [, ccg] of currentColumnsGroups.entries()) {
				const columns = ccg.columns;
				const columnIndex = columns.findIndex((c) => c.index === column);

				if (columnIndex === -1) continue;

				const hasReferences = columns[columnIndex].references?.length > 0;

				if (hasReferences) {
					const refs = columns[columnIndex].references;
					const refsColumns = [];

					for (const ref of refs) {
						const groupColumns = currentColumnsGroups.find((g) => g.groupName === ref.groupName);
						const groupColumn = groupColumns.columns.find((gc) => gc.systemName === ref.systemName);

						refsColumns.push(groupColumn);
					}

					if (mapState) {
						columns[columnIndex] = {
							...columns[columnIndex],
							mapped: mapState,
							required: true
						};

						for (const rc of refsColumns) {
							rc.required = true;
						}
					} else {
						let someReferencesAreMapped = false;

						for (const rc of refsColumns) {
							if (rc.mapped && rc.index !== null) {
								someReferencesAreMapped = true;
								break;
							}
						}

						if (!someReferencesAreMapped) {
							for (const rc of refsColumns) {
								rc.required = false;
							}
						}

						columns[columnIndex] = {
							...columns[columnIndex],
							mapped: mapState,
							required: someReferencesAreMapped
						};
					}
				} else {
					columns[columnIndex].mapped = mapState;
				}

				break;
			}
		},
		setColumnSkipped: (state, action) => {
			const { index, actionType } = action.payload;

			if (actionType === 'add') {
				state.skippedIndexes = [...state.skippedIndexes, index];
				const currentColumnsGroups = state.currentSettings.columns;

				for (const [i, ccg] of currentColumnsGroups.entries()) {
					const columns = ccg.columns;
					const columnIndex = columns.findIndex((c) => c.index === index);

					if (columnIndex === -1) continue;

					const newColumnData = { ...columns[columnIndex], index: null, messages: null };

					state.currentSettings.columns[i].columns[columnIndex] = newColumnData;

					break;
				}
			}
			if (actionType === 'delete') state.skippedIndexes = state.skippedIndexes.filter((sc) => sc !== index);
		},
		setColumnValidityQueuePending: (state, action) => {
			state.columnValidityQueuePending = action.payload;
		},
		checkColumnValiditySuccess: (state, action) => {
			const { systemName, index, groupName } = action.payload;

			state.columnValidityQueuePending = null;

			const group = state.currentSettings.columns.find((c) => c.groupName === groupName);
			const groupColumn = group.columns.find((g) => g.systemName === systemName);

			groupColumn.index = index;
		},
		setTemplateActionPending: (state, action) => {
			state.addOrEditTemplatePending = action.payload ?? false;
		},
		setTemplateActionSuccess: (state, action) => {
			state.addOrEditTemplateSuccess = action.payload ?? false;
			state.addOrEditTemplatePending = false;
		},
		fetchFileColumnsPending: (state, action) => {
			state.fetchFileColumnsPending = action.payload ?? false;
		},
		fetchFileColumnsSucces: (state, action) => {
			state.file.columns = action.payload;
			state.fetchFileColumnsPending = false;
			state.file.reloadFileData = false;
		},
		setReloadFileData: (state) => {
			state.file.reloadFileData = true;
		},
		setDeleteTemplatePending: (state, action) => {
			state.deleteTemplatePending = action.payload ?? false;
		},
		setDeleteTemplateSuccess: (state, action) => {
			state.deleteTemplateSuccess = action.payload;
			state.deleteTemplatePending = false;
		},
		setStartImportPending: (state, action) => {
			state.startImportPending = action.payload ?? false;
		},
		setStartImportSuccess: (state, action) => {
			state.startImportSuccess = action.payload;
			state.startImportPending = false;
		},
		setLastSavedTemplateConfiguration: (state, action) => {
			state.lastSavedTemplateConfiguration = action.payload;
		},
		setSaveTemplate: (state, { payload }) => {
			state.saveTemplate = payload;
		}
	}
});

export const {
	setActiveTab,
	updateStepValidation,
	resetImportWizard,
	restoreOriginSettings,
	setWizardSelectedFile,
	setWizardCurrentImportId,
	fetchTemplatesSucces,
	fetchTemplatesPending,
	fetchTemplateSettingsSucces,
	resetTemplateColumnSettings,
	fetchTemplateSettingsPending,
	updateTemplateSettingsColumns,
	updateCurrentSettings,
	setSelectedTemplate,
	updateColumnValidityQueue,
	setColumnValidityQueuePending,
	checkColumnValiditySuccess,
	removePreviousColumnMapping,
	setColumnMapped,
	setColumnSkipped,
	setTemplateActionPending,
	setTemplateActionSuccess,
	fetchFileColumnsPending,
	fetchFileColumnsSucces,
	setReloadFileData,
	setDeleteTemplatePending,
	setDeleteTemplateSuccess,
	setStartImportPending,
	setStartImportSuccess,
	setLastSavedTemplateConfiguration,
	setSaveTemplate
} = importWizardWizardSlice.actions;

/* API Calls */
export const fetchTemplatesAsync = (type, importType) => async (dispatch) => {
	try {
		dispatch(fetchTemplatesPending({ type, value: true }));

		const response = await fetchTemplates(type, importType);

		dispatch(fetchTemplatesSucces({ type, value: { elements: response.templates } }));

		return response.templates;
	} catch (error) {
		dispatch(fetchTemplatesPending({ type, value: false }));

		return await dispatch(addErrorAsync({ slice, error }));
	}
};

export const fetchTemplateSettingsAsync = () => async (dispatch, getState) => {
	try {
		let response;
		const { id, type, importType } = getState().importWizard.selectedTemplate;

		dispatch(fetchTemplateSettingsPending(true));

		if (type === 'default') response = await ImportService.fetchTemplates(type, importType);
		else response = await ImportService.fetchTemplate(id);

		dispatch(fetchTemplateSettingsSucces(type === 'default' ? response.templates?.[0] : response));
	} catch (error) {
		dispatch(fetchTemplateSettingsPending(false));

		return await dispatch(addErrorAsync({ slice, error }));
	}
};

export const fetchFileColumnsAsync = () => async (dispatch, getState) => {
	try {
		dispatch(fetchFileColumnsPending(true));
		const response = await ImportService.fetchFileColumns(
			getState().importWizard.importId,
			getState().importWizard.currentSettings.formatProperties
		);

		dispatch(fetchFileColumnsSucces(response.columns ?? []));
	} catch (error) {
		dispatch(fetchFileColumnsPending(false));

		return await dispatch(addErrorAsync({ slice, error }));
	}
};

export const checkColumnValuesAsync =
	({ systemName, index, groupName }) =>
	async (dispatch) => {
		dispatch(checkColumnValiditySuccess({ systemName, index, groupName }));
	};

export const addTemplateAsync = () => async (dispatch, getState) => {
	const request = {
		...cloneDeep(getState().importWizard.currentSettings),
		skippedIndexes: getState().importWizard.skippedIndexes,
		id: guidEmpty
	};

	try {
		dispatch(setTemplateActionPending(true));

		const response = await ImportService.addTemplate(request);

		dispatch(setTemplateActionPending(false));
		dispatch(setLastSavedTemplateConfiguration({ ...request, id: response.id, templateType: 'User' }));

		return response;
	} catch (error) {
		dispatch(setTemplateActionPending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const editTemplateAsync = (values, importType) => async (dispatch, getState) => {
	try {
		const request = {
			...getState().importWizard.currentSettings,
			skippedIndexes: getState().importWizard.skippedIndexes,
			...values,
			importType: importType
		};

		dispatch(setTemplateActionPending(true));

		const response = await ImportService.editTemplate(request);

		dispatch(setTemplateActionSuccess(true));
		dispatch(setLastSavedTemplateConfiguration(cloneDeep(request)));

		setTimeout(() => {
			dispatch(setTemplateActionSuccess(false));
		}, 1000);

		return response;
	} catch (error) {
		dispatch(setTemplateActionPending(false));

		return await dispatch(addErrorAsync({ slice, error }));
	}
};

export const startImportAsync = () => async (dispatch, getState) => {
	try {
		let settings = cloneDeep(getState().importWizard.currentSettings);
		settings.skippedIndexes = getState().importWizard.skippedIndexes;

		dispatch(setStartImportPending(true));

		const response = await ImportService.startImport({
			template: { ...settings },
			importId: getState().importWizard.importId
		});

		dispatch(setStartImportSuccess(true));
		return response;
	} catch (error) {
		dispatch(setStartImportPending(false));

		return await dispatch(addErrorAsync({ slice, error }));
	}
};

export const deleteTemplateAsync = (id) => async (dispatch) => {
	try {
		dispatch(setDeleteTemplatePending(true));

		await ImportService.deleteTemplate(id);

		dispatch(setDeleteTemplateSuccess(true));
		setTimeout(() => dispatch(setDeleteTemplateSuccess(false)), 500);
	} catch (error) {
		dispatch(setDeleteTemplatePending(false));

		return await dispatch(addErrorAsync({ slice, error }));
	}
};

/* Selectors */
export const selectActiveTab = (state) => state.importWizard.activeTab;

export const selectlastActiveTab = (state) => state.importWizard.lastActiveTab;

export const selectVisitedTab = (state) => state.importWizard.visitedTab;

export const selectStepValidation = (state) => state.importWizard.stepValidation;

export const selectCurrentTemplate = createSelector(
	(state) => state.importWizard,
	(importWizard) => ({
		currentSettingsPending: importWizard.templateSettingsPending,
		currentSettings: importWizard.currentSettings
	})
);

export const selectCurrentSettingsOrigin = (state) => state.importWizard.currentSettingsOrigin;

export const selectSelectedTemplate = (state) => state.importWizard.selectedTemplate;

export const selectSelectedTemplateColumns = (state) => state.importWizard.templateSettingsColumns;

export const selectSelectedTemplateColumnsSelected = (state) => state.importWizard.templateSettingsColumnsSelected;

export const selectTemplateSettingsPending = (state) => state.importWizard.templateSettingsPending;

export const selectColumnValidityQueue = (state) => state.importWizard.columnValidityQueue;

export const selectColumnValidityQueuePending = (state) => state.importWizard.columnValidityQueuePending;

export const selectSkippedIndexes = (state) => state.importWizard.skippedIndexes;

export const selectCurrentImportId = (state) => state.importWizard.importId;

export const selectFileData = createSelector(
	(state) => state.importWizard,
	(importWizard) => ({
		fileData: importWizard.file,
		fetchFileColumnsPending: importWizard.fetchFileColumnsPending,
		reloadFileData: importWizard.file.reloadFileData
	})
);

export const selectTemplateAction = createSelector(
	(state) => state.importWizard,
	(importWizard) => ({
		addOrEditTemplatePending: importWizard.addOrEditTemplatePending,
		addOrEditTemplateSuccess: importWizard.addOrEditTemplateSuccess
	})
);

export const selectTemplateDelete = createSelector(
	(state) => state.importWizard,
	(importWizard) => ({
		deleteTemplatePending: importWizard.deleteTemplatePending,
		deleteTemplateSuccess: importWizard.deleteTemplateSuccess
	})
);

export const selectStartImport = createSelector(
	(state) => state.importWizard,
	(importWizard) => ({
		startImportPending: importWizard.startImportPending,
		startImportSuccess: importWizard.startImportSuccess
	})
);

export const selectSaveTemplate = (state) => state.importWizard.saveTemplate;

export default importWizardWizardSlice.reducer;
