import React, { useEffect, useState } from 'react';
import { BaseTooltip } from '..';

export const BaseTooltipFollowCursor = ({ render, permanent = false, ...rest }) => {
	const [position, setPosition] = useState({ x: undefined, y: undefined });
	const [showTooltip, setShowTooltip] = useState(permanent);
	let timer;

	useEffect(() => {
		if (showTooltip) {
			timer = setTimeout(() => setShowTooltip(false), 2000);
		}
		return () => {
			if (timer) clearTimeout(timer);
		};
	}, [showTooltip]);

	return (
		<BaseTooltip
			open={showTooltip}
			disableFocusListener
			disableHoverListener
			disableTouchListener
			onMouseMove={(e) => setPosition({ x: e.pageX, y: e.pageY })}
			PopperProps={{
				anchorEl: {
					clientHeight: 0,
					clientWidth: 0,
					getBoundingClientRect: () => ({
						top: position.y,
						left: position.x,
						right: position.x,
						bottom: position.y,
						width: 0,
						height: 0
					})
				}
			}}
			{...rest}>
			{render(setShowTooltip)}
		</BaseTooltip>
	);
};
