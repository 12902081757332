import { lazy } from 'react';
import { Features } from '@sonar/auth';

const ReportPage = lazy(() => import('./Report/ReportPage'));
const ReportConfiguratorEdit = lazy(() => import('./ReportConfigurator/ReportConfiguratorEdit'));
const ReportsPage = lazy(() => import('./Reports/ReportsPage'));
const ReportTypes = lazy(() => import('./ReportTypes/ReportTypes/ReportTypes'));
const ReportConfiguratorAdd = lazy(() => import('./ReportConfigurator/ReportConfiguratorAdd'));

export default [
	{
		element: ReportsPage,
		feature: Features.ReportsPage.name,
		path: '/generatedReports',
		exact: true,
		name: 'Reports_GeneratedReports'
	},
	{
		element: ReportsPage,
		feature: Features.ReportsPage.name,
		path: '/scheduledReports',
		exact: true,
		name: 'Reports_ScheduledReports'
	},
	{
		element: ReportTypes,
		feature: Features.ReportConfigurator.name,
		path: '/reportTypes',
		exact: true,
		name: 'Reports_ReportTypes'
	},
	{
		element: ReportConfiguratorEdit,
		feature: Features.ReportConfigurator.name,
		path: `/reportConfigurator/:id`,
		exact: true,
		name: '{0}'
	},
	{
		element: ReportPage,
		feature: Features.ReportPage.name,
		path: `/report/:id`,
		exact: true,
		name: 'Reports_Report'
	},
	{
		element: ReportConfiguratorAdd,
		feature: Features.ReportConfigurator.name,
		path: `/report/new/:typeId`,
		exact: true,
		name: 'Reports_Report'
	}
];
