import { apiBase } from '@sonar-web/common';
import { REST_API_PATHS } from '@sonar-web/common/src/constants/system';

const path = REST_API_PATHS.SETS;

async function fetchActiveMeasurementSetDevicesCount() {
	const { data } = await apiBase(`devices/activemeasurementdevicescount`, 'get', {
		path
	});

	return data;
}

async function fetchConnectionRules(pageDescriptor) {
	const { data } = await apiBase(`connectionrules`, 'get', {
		path,
		params: { pageDescriptor }
	});

	return data;
}

async function fetchDeviceTypes(descriptor) {
	const { data } = await apiBase(`devicetypes`, 'get', {
		path: path,
		params: { pageDescriptor: descriptor }
	});

	return data;
}

async function fetchMeasurementSets(locationId) {
	const { data } = await apiBase(`terminalinstallationsets/${locationId}`, 'get', {
		path
	});

	return data;
}

async function fetchMeasurementInterfaces(locationId) {
	const { data } = await apiBase(`terminalinstallationsets/${locationId}/measurementinterfaces`, 'get', {
		path
	});

	return data;
}

const MeasurementSetService = {
	fetchActiveMeasurementSetDevicesCount,
	fetchConnectionRules,
	fetchDeviceTypes,
	fetchMeasurementSets,
	fetchMeasurementInterfaces
};

export default MeasurementSetService;
