import React from 'react';
import { Box, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { formatDateTimeSecondsPrecision } from '@sonar-web/common';
import { useLocale } from '@sonar-web/common/src/hooks';
import TimelinePoint from './TimelinePoint';
import TimelineBottomTooltip from './TimelineBottomTooltip';
import ConverterStatus from '../ConverterStatus';
import { readSucceededColor, readFailedColor, dotSizeLarge, marginLeft } from './constants';

const useStyles = makeStyles((theme) => ({
	timelinePeriod: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		flex: 1,
		minHeight: 28
	},
	timelineConnector: {
		position: 'absolute',
		left: 0,
		top: 6,
		width: '100%',
		height: 2,
		backgroundColor: theme.palette.grey[300]
	}
}));

const TimelineLastPoint = ({ lastEntry, ...rest }) => {
	const classes = useStyles();
	const { translate } = useLocale();

	const theme = useTheme();

	const isConnected = lastEntry?.status === ConverterStatus.Connected.name;

	const text = `${translate('Stationary_StateAsOf')} ${formatDateTimeSecondsPrecision(new Date())}`;

	return (
		<Box display='flex' flexDirection='column' flex={1} minWidth={40}>
			<Box className={classes.timelinePeriod}>
				<Box style={{ position: 'relative', width: '100%' }}>
					<Box className={classes.timelineConnector} {...rest}></Box>
					{lastEntry ? (
						<Box
							style={{
								position: 'absolute',
								background: theme.palette.common.white,
								padding: '0 3px 0 3px',
								top: 1,
								left: `${marginLeft}px`
							}}>
							<TimelineBottomTooltip
								title={translate(
									isConnected
										? 'Stationary_ConverterConnectedTooltipTitle'
										: 'Stationary_ConverterDisconnectedTooltipTitle'
								)}
								text={text}
								textColor={readSucceededColor}
								tooltipBackground={isConnected ? readSucceededColor : readFailedColor}
								tooltipTextColor={'white'}
								tooltipBorder={`1px solid ${isConnected ? readSucceededColor : readFailedColor}`}>
								<TimelinePoint
									size={dotSizeLarge}
									color={isConnected ? readSucceededColor : readFailedColor}
								/>
							</TimelineBottomTooltip>
						</Box>
					) : (
						<Box
							style={{
								position: 'absolute',
								background: theme.palette.common.white,
								padding: '0 3px 0 3px',
								top: 1,
								left: `${marginLeft}px`
							}}>
							<TimelineBottomTooltip
								title={translate('Stationary_ConverterNotConnectedYetTooltipTitle')}
								text={text}
								textColor={readSucceededColor}
								tooltipBackground={theme.palette.grey[300]}
								tooltipTextColor={'black'}
								tooltipBorder={`1px solid ${theme.palette.grey[300]}`}>
								<TimelinePoint size={dotSizeLarge} color={theme.palette.grey[300]} />
							</TimelineBottomTooltip>
						</Box>
					)}
				</Box>
			</Box>
		</Box>
	);
};

export default TimelineLastPoint;
