import React from 'react';
import { CircularProgress, Box } from '@mui/material';

const BaseCircularProgress = ({ pending, paddingTop = 0, size = 24, thickness = 3.6, ...rest }) => {
	return pending ? (
		<Box textAlign='center' paddingTop={paddingTop} {...rest}>
			<CircularProgress size={size} thickness={thickness} />
		</Box>
	) : null;
};

export default BaseCircularProgress;
