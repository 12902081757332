import { useEffect, useState } from 'react';
import { ClickAwayListener, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import styled from '@emotion/styled';
import { useFetchOnDemand } from '@sonar-web/common/src/hooks';
import MeasurementSetService from '../../../../sonar-web-core/src/Services/MeasurementSet';
import { slices } from '../../Constants/Module';
import StatsHelper from '../../Helpers/StatsHelper';
import PointSets from './PointSets';

const useStyles = makeStyles((theme) => ({
	tooltipArrow: {
		color: theme.palette.common.white
	},
	tooltipRoot: {
		backgroundColor: theme.palette.common.white,
		color: theme.palette.text.primary,
		boxShadow: '0 0 8px 0 rgba(0,0,0,0.3)',
		maxWidth: '90vw'
	}
}));

const IconWrap = styled('div')(({ theme }) => ({
	'&.iconWrap': {
		minHeight: 16,
		borderRadius: 3,
		marginTop: 5,
		border: '1px solid #DB2242',
		color: '#DB2242',
		cursor: 'pointer',
		padding: 2,
		'&:hover': {
			backgroundColor: 'hsl(350deg 73% 75% / 16%)'
		}
	},
	'&.iconWrap_read': {
		borderColor: theme.palette.success.main,
		color: theme.palette.success.main,
		'&:hover': {
			backgroundColor: 'hsl(141deg 46% 84% / 16%)'
		}
	},
	'& svg': {
		verticalAlign: 'middle'
	}
}));

const Point = ({ id, isRead, interfaceType }) => {
	const classes = useStyles();
	const { fetchFunc, data, error, pending } = useFetchOnDemand({
		api: (locationId) => MeasurementSetService.fetchMeasurementSets(locationId),
		slice: slices.statsLocation
	});

	const [open, setOpen] = useState(false);

	const Icon = StatsHelper.getIconByInterfaceType(interfaceType);
	const measurementSetName = StatsHelper.getMeasurementSetNameByInterfaceType(interfaceType);

	const handleTooltipClose = () => {
		if (open) setOpen(false);
	};

	const showTooltip = () => {
		if (!data && !pending) fetchFunc(id);
		else setOpen(true);
	};

	useEffect(() => {
		if (pending || error) return;
		if (data) {
			setOpen(true);
		}
	}, [pending]);

	let iconWrapClasses = 'iconWrap';
	if (isRead) iconWrapClasses += ' iconWrap_read';

	return (
		<ClickAwayListener onClickAway={handleTooltipClose}>
			<Tooltip
				onClose={handleTooltipClose}
				open={open}
				disableFocusListener
				disableHoverListener
				disableTouchListener
				arrow
				placement='left'
				title={<PointSets data={data} measurementSetName={measurementSetName} />}
				classes={{
					arrow: classes.tooltipArrow,
					tooltip: classes.tooltipRoot
				}}>
				<IconWrap className={iconWrapClasses} onClick={showTooltip}>
					<Icon />
				</IconWrap>
			</Tooltip>
		</ClickAwayListener>
	);
};

export default Point;
