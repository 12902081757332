export const fillDecimalPlaces = (value, numOfPlaces) => {
	if (value == null) return null;

	let [left, right] = value.toString().split('.');

	if (!right) return Number(`${left}.000`).toFixed(numOfPlaces);
	if (right.length === numOfPlaces) return value;

	while (right.length < numOfPlaces) {
		right += '0';
	}

	return Number(`${left}.${right}`).toFixed(numOfPlaces);
};

export const guidEmpty = '00000000-0000-0000-0000-000000000000';

export function uuidv4() {
	return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
		(c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
	);
}

export const stringToNumber = (data) => {
	if (!data) return data;
	if (typeof data === 'number') return data;
	return Number(data.replace(',', '.'));
};

export const stringToNumberAllPropertiesInObject = (inputObj) => {
	const outputObj = { ...inputObj };

	for (let o in outputObj) {
		const oData = outputObj[o];

		if (typeof oData === 'number') continue;

		outputObj[o] = stringToNumber(oData);
	}

	return outputObj;
};

export const toDecimal = (value) => {
	if (value == null) return value;
	if (typeof value === 'string') return Number(value.replace(',', '.'));
	return value;
};

const NumberHelper = {
	toDecimal
};

export default NumberHelper;
