import LicenseItem from './LicenseItem';
import ReportsLicenseIcon from '../Icons/ReportsLicenseIcon';
import ReportsCount from './ReportsCount';

const Reports = ({ pending, data }) => {
	return (
		<LicenseItem
			pending={pending}
			data={
				data
					? {
							Icon: ReportsLicenseIcon,
							title: 'Common_Reports',
							description: 'LicenseReportsDescription',
							primaryData: {
								value: data.maxNumberOfReports,
								text: 'Limit'
							}
					  }
					: null
			}>
			{data && !pending && <ReportsCount maxNumberOfReports={data.maxNumberOfReports} />}
		</LicenseItem>
	);
};

export default Reports;
