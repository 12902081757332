import { useEffect } from 'react';
import useCurrentTenant from '../Hooks/useCurrentTenant';

const SelectTenant = ({ tenant, children }) => {
	const { storeTenant } = useCurrentTenant();

	useEffect(() => {
		storeTenant(tenant);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return children;
};

export default SelectTenant;
