import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '../../../../../hooks';
import YearSelector from './YearSelector';
import Legend from './Legend';
import Calendar from './Calendar';

const useStyles = makeStyles((theme) => ({
	root: {
		background: '#FFFFFF',
		border: `1.5px solid ${theme.palette.border.main}`,
		borderRadius: '7px',
		padding: theme.spacing(5),
		paddingRight: theme.spacing(2),
		marginBottom: theme.spacing(4)
	}
}));

const ConfiguratorOmsScheduleCalendar = ({ form }) => {
	const classes = useStyles();
	const { translate } = useLocale();
	const [year, setYear] = useState(new Date().getFullYear());

	const {
		first_hour_work_mode_hours_property: hours,
		days_of_week_with_hours_work_mode_property: weekdays,
		days_of_month_with_hours_work_mode_property: monthdays,
		months_with_hours_work_mode_property: months
	} = form.values;

	return (
		<div className={classes.root}>
			<Box display='flex' alignItems='center'>
				<Typography variant='h6'>{translate('ScheduleSettingsPreview')}</Typography>
				<YearSelector year={year} setYear={setYear} />
			</Box>
			<Legend />
			<Calendar year={year} formValues={{ hours, weekdays, monthdays, months }} />
		</div>
	);
};

export default ConfiguratorOmsScheduleCalendar;
