import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setObjectSucessfullyDeleted } from '@sonar-web/common/src/appSlice';

export default () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const showDeleteSuccessPage = (obj) => {
		dispatch(setObjectSucessfullyDeleted(obj));
		navigate('/deletesuccess');
	};

	return {
		showDeleteSuccessPage
	};
};
