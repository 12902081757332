import { lazy } from 'react';

const Empty = lazy(() => import('./features/Empty/Empty'));

export default [
	{
		element: Empty,
		path: 'empty',
		exact: true,
		name: '',
		standalone: true
	}
];
