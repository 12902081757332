import { apiBase } from '@sonar-web/common';
import { REST_API_PATHS } from '@sonar-web/common/src/constants/system';

const path = REST_API_PATHS.LOCATION;

export async function fetchLocationNodes(pageDescriptor) {
	const { data } = await apiBase(`locationNodes`, 'get', {
		path,
		params: { pageDescriptor }
	});

	return data;
}

export async function moveLocationNode(node) {
	const response = await apiBase(`locationNodes/${node.id}/move`, 'put', {
		path,
		data: node
	});

	const locationHeader = response.headers.location;

	if (locationHeader)
		return {
			url: locationHeader,
			id: locationHeader.slice(locationHeader.indexOf('/') + 1)
		};

	return response;
}
