import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Typography from '@mui/material/Typography';
import { useLocale } from '@sonar-web/common/src/hooks';

const useStyles = makeStyles((theme) => ({
	content: {
		marginLeft: 9,
		paddingLeft: 34
	},
	subtitle: {
		lineHeight: 1.2
	},
	inactiveText: {
		color: '#ABABAB'
	},
	stepperRoot: {
		padding: `${theme.spacing(3)} 0 0 0`,
		'& .MuiStepConnector-root': {
			marginLeft: 9
		}
	},
	stepRoot: {
		cursor: 'pointer',
		'& svg': {
			transition: 'transform 300ms ease'
		}
	},
	stepRootDisabled: {
		cursor: 'initial'
	},
	activeStep: {
		'& svg': {
			transform: 'scale(1.4)'
		}
	},
	iconContainer: {
		paddingRight: theme.spacing(3)
	},
	iconRoot: {
		fontSize: '1.2rem'
	},
	label: {
		fontSize: theme.typography.subtitle1.fontSize
	}
}));

const StepperBase = ({ steps, activeStep, completed, isDisabled, handleChangeStep }) => {
	const classes = useStyles();
	const { translate } = useLocale();

	return (
		<Stepper
			activeStep={activeStep}
			orientation='vertical'
			classes={{ root: classes.stepperRoot }}
			className='stepperBase'>
			{steps.map((step, index) => {
				const stepCompleted = completed[index];
				const stepActive = activeStep === index;
				const stepDisabled = isDisabled(index);

				return (
					<Step
						key={index}
						classes={{
							completed: classes.stepCompleted,
							root: clsx(classes.stepRoot, {
								[classes.stepRootDisabled]: stepDisabled,
								[classes.activeStep]: stepActive
							})
						}}
						disabled={stepDisabled}
						completed={stepCompleted}
						data-at-params={`step|${step.stepName}|active ${stepActive}|completed ${stepCompleted}`}>
						<StepLabel
							onClick={() => handleChangeStep(index)}
							classes={{
								label: classes.label,
								iconContainer: classes.iconContainer
							}}
							StepIconProps={{
								classes: {
									root: classes.iconRoot
								}
							}}>
							{translate(step.stepName)}
						</StepLabel>
						<StepContent className={classes.content}>
							<Typography
								variant='body2'
								color='textSecondary'
								classes={{ root: classes.subtitle }}
								className={clsx({ [classes.inactiveText]: !stepActive })}>
								{translate(step.stepDescription)}
							</Typography>
						</StepContent>
					</Step>
				);
			})}
		</Stepper>
	);
};

export default StepperBase;
