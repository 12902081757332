import React from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '@sonar-web/common/src/hooks';
import LocationNodeLevelName from '../LocationNodeLevels/LocationNodeLevelName';

const useStyles = makeStyles((theme) => ({
	empesizeText: {
		fontWeight: theme.typography.fontWeightMedium
	},
	root: {
		textAlign: 'right'
	}
}));

const LocationNodeMoveNewLocation = ({ newLocationNode, nodeToMove }) => {
	const classes = useStyles();
	const { translate } = useLocale();

	if (newLocationNode == null) return null;

	const isInSourceLocation = newLocationNode.id === nodeToMove.parentLocationNodeId;
	const newLocationName = isInSourceLocation
		? translate('LocationNodes_NodeCanNotBeMovedToLocation')
		: newLocationNode.locationNodeLevelId
		? newLocationNode.name
		: translate('LocationNodes_MainNode');

	return (
		<div className={classes.root}>
			<Typography variant='caption' color='textSecondary'>
				{translate('LocationNodes_NewLocation')}
			</Typography>
			<Typography variant='body2' color='textSecondary'>
				{!isInSourceLocation && newLocationNode.locationNodeLevelId && (
					<>
						<LocationNodeLevelName locationNodeLevelId={newLocationNode.locationNodeLevelId} />{' '}
					</>
				)}
				<span className={classes.empesizeText}>{newLocationName}</span>
			</Typography>
		</div>
	);
};

export default LocationNodeMoveNewLocation;
