export const sidebars = {
	watermeter: 'watermeter',
	watermeters: 'watermeters',
	watermeterReads: 'watermeterReads',
	watermeterRead: 'watermeterRead',
	watermeterEvents: 'watermeterEvents',
	watermeterEvent: 'watermeterEvent',
	watermeterMonitReads: 'watermeterMonitReads'
};

export const readType = {
	instant: { value: 1, name: 'Instant' },
	logged: { value: 2, name: 'Logged' },
	monitoring: { value: 3, name: 'Monitoring' }
};

export const readSource = {
	tenant: { value: 1, name: 'Tenant' },
	inkasent: { value: 2, name: 'Inkasent' }
};

export const slices = {
	watermeter: 'watermeter',
	watermeters: 'watermeters',
	watermeterRead: 'watermeterRead',
	watermeterReads: 'watermeterReads',
	watermeterEvent: 'watermeterEvent',
	watermeterEvents: 'watermeterEvents'
};

export const moduleName = 'watermeter';
