import { createSelector, createSlice } from '@reduxjs/toolkit';
import {
	fetchBilling,
	addBilling,
	editBillingInputFiles,
	editBilling,
	deleteBilling,
	fetchSettlementProgress
} from './billingApi';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import {
	billingContentStateName,
	initialBillingContentData,
	billingContentReducers
} from './BillingContent/billingContentSlice';
import {
	filesUploadReducers,
	filesUploadStateName,
	initialFilesUploadData
} from './BillingContent/FilesUpload/filesUploadSlice';
import FileUploadTypes from './BillingContent/FilesUpload/FileUploadTypes';
import {
	billingConfiguratorReducers,
	billingConfiguratorStateName,
	initialBillingConfigurator
} from './BillingConfigurator/billingConfiguratorSlice';
import { parseError } from '../billingErrors';
import BillingService from '../BillingService';
import Module from '../Constants/Module';
import { getSliceDescriptor } from '@sonar-web/common';

const slice = Module.slices.billing;

const initialBillingData = {
	name: '',
	[FileUploadTypes.Infrastructure.name]: null,
	[FileUploadTypes.Changes.name]: null,
	[FileUploadTypes.Reads.name]: null
};

export const billingSlice = createSlice({
	name: slice,
	initialState: {
		addOrEditPending: false,
		addOrEditSuccess: false,
		fetchPending: false,
		fetchSuccess: false,
		deletePending: false,
		deleteSuccess: false,
		billing: initialBillingData,
		[billingContentStateName]: initialBillingContentData,
		[filesUploadStateName]: initialFilesUploadData,
		[billingConfiguratorStateName]: initialBillingConfigurator
	},
	reducers: {
		fetchBillingSucces: (state, action) => {
			state.billing = action.payload;
			state.fetchPending = false;
			state.fetchSuccess = true;
		},
		fetchBillingPending: (state) => {
			state.fetchPending = true;
			state.fetchSuccess = false;
		},
		resetFetchSucces: (state) => {
			state.fetchSuccess = false;
		},
		checkBillingStatusSucces: (state, action) => {
			state.billing = action.payload;
		},
		addBillingSucces: (state, action) => {
			state.addOrEditPending = false;
			state.addOrEditSuccess = action.payload;
		},
		addBillingPending: (state, action) => {
			state.addOrEditPending = action.payload ?? true;
		},
		deleteBillingSucces: (state) => {
			state.deletePending = false;
			state.deleteSuccess = true;
		},
		deleteBillingPending: (state, { payload }) => {
			state.deletePending = payload;
		},
		setBillingData: (state, action) => {
			state.billing = action.payload;
		},
		resetBillingData: (state) => {
			state.billing = initialBillingData;
			state.deleteSuccess = false;
			state.fetchSuccess = false;
		},
		resetAddSuccess: (state) => {
			state.addOrEditSuccess = false;
		},
		updateBillingData: (state, { payload }) => {
			state.billing = { ...state.billing, ...payload };
		},
		setSettlementProgressSuccess: (state, { payload }) => {
			state.billing = { ...state.billing, ...payload };
		},
		...billingContentReducers,
		...filesUploadReducers,
		...billingConfiguratorReducers
	}
});

export const {
	fetchBillingSucces,
	fetchBillingPending,
	resetFetchSucces,
	checkBillingStatusSucces,
	addBillingSucces,
	addBillingPending,
	deleteBillingSucces,
	deleteBillingPending,
	setBillingData,
	resetBillingData,
	resetAddSuccess,
	updateBillingData,
	setSettlementProgressSuccess
} = billingSlice.actions;

export const fetchBillingAsync = (id) => async (dispatch) => {
	try {
		dispatch(fetchBillingPending(true));
		const response = await fetchBilling(id);
		dispatch(fetchBillingSucces(response));
	} catch (error) {
		dispatch(addErrorAsync({ slice, error: parseError(error) }));
		dispatch(fetchBillingPending(false));
	}
};

export const checkBillingStatusAsync = (id) => async (dispatch, getState) => {
	try {
		const currentBilling = getState().billing.billing;
		const response = await fetchBilling(id);

		if (billingChanged(currentBilling, response)) dispatch(checkBillingStatusSucces(response));
		return response;
	} catch (error) {
		dispatch(addErrorAsync({ slice, error: parseError(error) }));
	}
};

export const checkBillingsStatusAsync = (config) => async (dispatch, getState) => {
	try {
		const pd = getSliceDescriptor(getState, slice, null, null, 40);
		const result = await BillingService.fetchBillings(pd, config);
		return { currentElements: getState().billings.elements, result };
	} catch (error) {
		dispatch(addErrorAsync({ slice, error: parseError(error) }));
	}
};

//Pobranie progressu generowania indywidualek
export const fetchFlatReportsProgressAsync = () => async (dispatch, getState) => {
	try {
		const currentBilling = getState().billing.billing;
		const response = await fetchSettlementProgress(currentBilling.id);

		if (billingChanged(currentBilling, response)) dispatch(setSettlementProgressSuccess(response));
		return response;
	} catch (error) {
		dispatch(addErrorAsync({ slice, error: parseError(error) }));
	}
};

export const addBillingAsync = (submitData) => async (dispatch) => {
	try {
		dispatch(addBillingPending());

		const result = await addBilling(submitData);

		dispatch(addBillingSucces(result?.id));
	} catch (error) {
		dispatch(addBillingPending(false));
		return await dispatch(addErrorAsync({ slice, error: parseError(error) }));
	}
};

export const editBillingAsync = (submitData, id) => async (dispatch) => {
	try {
		dispatch(addBillingPending());

		await editBilling(submitData, id);

		dispatch(addBillingSucces(id));
	} catch (error) {
		dispatch(addBillingPending(false));

		return await dispatch(addErrorAsync({ slice, error: parseError(error) }));
	}
};

export const editBillingInputFilesAsync = (submitData) => async (dispatch) => {
	try {
		await editBillingInputFiles(submitData);
		return true;
	} catch (error) {
		await dispatch(addErrorAsync({ slice, error: parseError(error) }));
	}
};

export const deleteBillingAsync = (id) => async (dispatch) => {
	try {
		dispatch(deleteBillingPending(true));
		await deleteBilling(id);
		dispatch(deleteBillingSucces());
	} catch (error) {
		dispatch(deleteBillingPending(false));
		return await dispatch(addErrorAsync({ slice, error: parseError(error) }));
	}
};

export const selectBilling = createSelector(
	(state) => state.billing,
	(billing) => ({
		dataRow: billing.billing,
		fetchPending: billing.fetchPending,
		fetchSuccess: billing.fetchSuccess
	})
);

export const selectBillingAddOrEdit = (state) => {
	return {
		pending: state.billing.addOrEditPending,
		success: state.billing.addOrEditSuccess
	};
};

export const selectBillingDelete = (state) => {
	return {
		deletePending: state.billing.deletePending,
		deleteSuccess: state.billing.deleteSuccess
	};
};

export default billingSlice.reducer;

function billingChanged(current, next) {
	if (!current || !next) return false;
	const fields = ['errorTask', 'hasError', 'inProgress', 'isApproved', 'isCompleted', 'progress', 'status'];

	const differences = fields.map((f) => current[f] === next[f]);

	return differences.some((d) => d === false);
}
