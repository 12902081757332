import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PanelListItem, PanelListItemText } from '@sonar-web/common';
import { addBreadcrumb, setSelectedNodeToMoveTo, selectSelectedNodeToMoveTo } from './slice';
import LocationNodeLevelAvatar from '../LocationNodeLevels/LocationNodeLevelAvatar';
import LocationNodeLevelName from '../LocationNodeLevels/LocationNodeLevelName';

const LocationNodesMoveListItem = ({ row, canMoveInto }) => {
	const dispatch = useDispatch();
	const selected = useSelector(selectSelectedNodeToMoveTo);

	const handleGoDeeper = () => dispatch(addBreadcrumb(row));

	const handleClick = () => {
		if (canMoveInto) dispatch(setSelectedNodeToMoveTo(row));
	};

	return (
		<>
			<PanelListItem
				button={!canMoveInto}
				disabled={!canMoveInto}
				onClick={handleClick}
				selected={canMoveInto && selected && selected.id === row.id}
				renderFrontItem={() => (
					<>
						<LocationNodeLevelAvatar level={row.level} />
						<PanelListItemText
							primaryText={row.name}
							primaryTextAction={canMoveInto ? handleGoDeeper : null}
							secondaryText={<LocationNodeLevelName locationNodeLevelId={row.locationNodeLevelId} />}
						/>
					</>
				)}
			/>
		</>
	);
};

export default LocationNodesMoveListItem;
