import { apiBase } from '@sonar-web/common';
import { REST_API_PATHS } from '@sonar-web/common/src/constants/system';

const path = REST_API_PATHS.SETS;

export async function fetchLocationNodeReadsDevices(measurementPointId, pageDescriptor) {
	const { data } = await apiBase(`devices/${measurementPointId}`, 'get', {
		path,
		params: { pageDescriptor }
	});

	return data;
}
