export const moduleName = 'heatallocator';

export const slices = {
	heatallocatorReads: 'heatallocatorReads',
	heatallocatorRead: 'heatallocatorRead',
	heatAllocatorCharts: 'heatAllocatorCharts'
};

const Module = {
	slices
};

export default Module;
