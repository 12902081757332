import React from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '../../../../hooks';

const useStyles = makeStyles((theme) => ({
	root: {}
}));

const ConfiguratorControlLabel = ({ label, marginBottom = 0, marginTop = 0 }) => {
	const classes = useStyles();
	const { translate } = useLocale();

	return (
		<Typography
			variant='caption'
			color='textSecondary'
			classes={{ root: classes.root }}
			style={{ marginBottom, marginTop }}>
			{translate(label)}
		</Typography>
	);
};

export default ConfiguratorControlLabel;
