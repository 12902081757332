import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTimeLicensesAsync, selectTimeLicenses } from '../licensesSlice';

const FetchTimeLicenses = ({ children }) => {
	const dispatch = useDispatch();
	const timeLicenses = useSelector(selectTimeLicenses);

	useEffect(() => {
		dispatch(fetchTimeLicensesAsync());
	}, []);

	return children(timeLicenses);
};

export default FetchTimeLicenses;
