import DeviceTypePage from './DeviceTypePage';
import DeviceTypeForm from './DeviceTypeForm';
import DeviceTypeDelete from './DeviceTypeDelete';

export const sidebarComponents = {
	Dashboard: {
		id: 1,
		name: 'Dashboard',
		component: DeviceTypePage
	},
	DeviceTypeAdd: {
		id: 2,
		name: 'DeviceTypeAdd',
		component: DeviceTypeForm
	},
	DeviceTypeEdit: {
		id: 3,
		name: 'DeviceTypeEdit',
		component: DeviceTypeForm
	},
	DeviceTypeDelete: {
		id: 4,
		name: 'DeviceTypeDelete',
		component: DeviceTypeDelete
	}
};

export const sidebarType = 'deviceType';
