import { Typography } from '@mui/material';
import { useLocale } from '@sonar-web/common/src/hooks';
import { TableListColumn, TableListHeader } from '@sonar-web/common';

const WatermeterReadsLocationHeader = () => {
	const { translate } = useLocale();

	return (
		<TableListHeader id='WatermeterReadsListHeader'>
			<TableListColumn width={200} disableLeftPadding>
				<Typography variant='caption' color='textSecondary'>
					{translate('Watermeters_ReadDateAndType')}
				</Typography>
			</TableListColumn>
			<TableListColumn width={250}>
				<Typography variant='caption' color='textSecondary'>
					{translate('Watermeters_ReadSource')}
				</Typography>
			</TableListColumn>
			<TableListColumn flex>
				<Typography variant='caption' color='textSecondary'>
					{translate('Watermeters_SerialNumber')}
				</Typography>
			</TableListColumn>
			<TableListColumn width={200} style={{ textAlign: 'end' }}>
				<Typography variant='caption' color='textSecondary'>
					{translate('Watermeters_VolumeWithUnit')}
				</Typography>
			</TableListColumn>
		</TableListHeader>
	);
};

export default WatermeterReadsLocationHeader;
