import React from 'react';
import BaseIcon from './BaseIcon';

export default function RecycleBinIcon(props) {
	return (
		<BaseIcon {...props}>
			<path d='M 9.6149534,2.0000003 A 0.98927976,0.98927976 0 0 0 8.7088489,2.5911904 L 7.4569169,5.4350853 H 3.8421563 A 0.98918084,0.98918084 0 0 0 2.8529755,6.4242661 0.98918084,0.98918084 0 0 0 3.8421563,7.413447 H 4.6400692 V 19.683539 C 4.6400692,20.948998 5.68914,22 6.954598,22 h 10.088873 c 1.265457,0 2.316459,-1.051 2.316459,-2.316461 V 7.413447 h 0.797914 a 0.98918084,0.98918084 0 0 0 0.98918,-0.9891809 0.98918084,0.98918084 0 0 0 -0.989181,-0.9891808 h -3.63408 L 15.291153,2.5950544 A 0.98927976,0.98927976 0 0 0 14.383115,2.0000003 Z M 10.26024,3.978362 h 3.473725 l 0.633694,1.4567233 H 9.6188181 Z M 6.6184309,7.413447 H 17.381569 v 12.270092 c 0,0.203669 -0.134433,0.338099 -0.338098,0.338099 H 6.954598 c -0.2036654,0 -0.3361671,-0.134431 -0.3361671,-0.338099 z m 3.28439,2.0401854 A 0.98918084,0.98918084 0 0 0 8.91364,10.442814 v 6.68663 a 0.98918084,0.98918084 0 0 0 0.9891809,0.989181 0.98918084,0.98918084 0 0 0 0.9891811,-0.989181 v -6.68663 A 0.98918084,0.98918084 0 0 0 9.9028209,9.4536324 Z m 4.1943581,0 a 0.98918084,0.98918084 0 0 0 -0.989181,0.9891816 v 6.68663 a 0.98918084,0.98918084 0 0 0 0.989181,0.989181 0.98918084,0.98918084 0 0 0 0.98918,-0.989181 v -6.68663 a 0.98918084,0.98918084 0 0 0 -0.98918,-0.9891816 z' />
		</BaseIcon>
	);
}
