export const moduleName = 'devicesConfigurations';

export const slices = {
	devicesConfigurations: 'devicesConfigurations',
	deviceConfigurationCreator: 'deviceConfigurationCreator',
	devicesConfigurationsTypes: 'devicesConfigurationsTypes'
};

export const routes = {
	configurations: (props) => (props != null ? `/configurations/${props.configType}` : '/configurations/:configType'),

	configurationsList: (props) =>
		props != null
			? `/configurations/${props.configType}/${props.deviceTypeName}/list`
			: `/configurations/:configType/:deviceTypeName/list`,

	configurationWizard: (props) =>
		props != null
			? `/configurations/${props.configType}/${props.deviceTypeName}/wizard`
			: `/configurations/:configType/:deviceTypeName/wizard`,

	configurationWizardAction: (props) =>
		props != null
			? `/configurations/${props.configType}/${props.deviceTypeName}/wizard/${props.configId}/${props.action}`
			: `/configurations/:configType/:deviceTypeName/wizard/:id/:action`
};
