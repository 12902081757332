import { cloneDeep } from 'lodash';
import moment from 'moment';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import { filterOperators, pageDescriptor } from '@sonar-web/common';
import FilterType from '@sonar-web/common/src/enums/FilterType';
import AccessService from '@sonar-web/access/src/AccessService';
import { slices } from '../Constants/Module';
import SpecificationType from '../Enums/SpecificationType';
import { fetchTask, fetchTasksDetails } from './Tasks/tasksApi';
import OrdersService from '../OrdersService';
import OrderStatus from '../Enums/OrderStatus';

const slice = slices.order;
const initial = {
	order: {
		name: '',
		specificationId: null,
		plannedOrderStartDate: moment().add(1, 'hour').set('minute', 0).toISOString(),
		orderedFinishDate: null,
		addressNodeIds: [],
		userIds: [],
		ordersPackId: null,
		orderTags: [],
		reclamation: false,
		specification: {
			name: '',
			deviceTypeName: '',
			elements: []
		}
	},
	orderFetchPending: false,
	orderSubmitPending: false,
	customSpecificationFetchPending: true,
	fittersPending: true,
	fitters: [],
	tasks: [],
	tasksFetchPending: true,
	tasksFilters: null,
	redirectUrl: null,
	note: null,
	orderCurrentOwnership: false
};

export const orderSlice = createSlice({
	name: slice,
	initialState: initial,
	reducers: {
		updateOrderName: (state, { payload }) => {
			state.order.name = payload;
			state.order.specification.name = payload + new Date().toLocaleString();
		},
		setOrderSpecificationDeviceTypeName: (state, { payload }) => {
			state.order.specification.deviceTypeName = payload;
			state.order.specification.elements = [];
			state.order.addressNodeIds = [];
			state.order.userIds = [];
			state.tasks = [];
			state.fitters = [];
		},
		fetchOrderPending: (state, { payload }) => {
			state.orderFetchPending = payload;
		},
		fetchOrderSuccess: (state, { payload }) => {
			state.order = payload;
			state.orderFetchPending = false;
		},
		submitOrderPending: (state, { payload }) => {
			state.orderSubmitPending = payload;
		},
		setInitialState: (state) => {
			const { order, ...rest } = initial;

			for (let key in rest) {
				state[key] = rest[key];
			}
			state.order = { ...order, plannedOrderStartDate: moment().add(1, 'hour').set('minute', 0).toISOString() };
		},
		fetchCustomSpecificationPending: (state, { payload }) => {
			state.customSpecificationFetchPending = payload;
		},
		fetchSpecificationSuccess: (state, { payload }) => {
			state.order.specification = { ...payload, elements: payload.elements.map((e) => ({ id: e.id })) };
			state.customSpecificationFetchPending = false;
			state.tasks = payload.elements;
		},
		fetchFittersPending: (state, { payload }) => {
			state.fittersPending = payload;
		},
		fetchTasksPending: (state, { payload }) => {
			state.tasksFetchPending = payload;
		},
		setTasksFilters: (state, { payload }) => {
			state.tasksFilters = payload ?? null;
		},
		setOrderedFinishDate: (state, { payload }) => {
			state.order.orderedFinishDate = payload;
		},
		setOrdersPackId: (state, { payload }) => {
			state.order.ordersPackId = payload.ordersPackId;
		},
		setRedirectUrl: (state, { payload }) => {
			state.redirectUrl = payload;
		},
		setPlannedOrderStartDate: (state, { payload }) => {
			state.order.plannedOrderStartDate = payload;
		},
		setTags: (state, { payload }) => {
			state.order.orderTags = payload;
		},
		addTag: (state, { payload }) => {
			state.order.orderTags.push(payload);
		},
		removeTag: (state, { payload }) => {
			state.order.orderTags = state.order.orderTags.filter((tag) => tag.id !== payload);
		},
		setFitters: (state, { payload }) => {
			state.fitters = payload;
			state.order.userIds = payload.map((p) => p.id);

			if (state.order.id) {
				if (state.order.ordersPackId)
					state.order.status =
						payload.length > 0 ? OrderStatus.InProgress.name : OrderStatus.AssignedToContractor.name;
				else state.order.status = payload.length > 0 ? OrderStatus.InProgress.name : OrderStatus.Waiting.name;
			}
		},
		setFetchedFitters: (state, { payload }) => {
			state.fitters = payload;
			state.order.userIds = payload.map((p) => p.id);
		},
		setLocations: (state, { payload }) => {
			state.order.addressNodeIds = payload;
		},
		setTasks: (state, { payload }) => {
			state.tasks = payload;
			state.order.specification.elements = payload.filter((p) => p.id != null).map((p) => p.id);
		},
		setTaskAtIndex: (state, { payload }) => {
			if (!state.tasks[payload.index]) return;
			state.tasks[payload.index] = payload.taskData;
			state.order.specification.elements.push(payload.taskData.id);
			state.order.addressNodeIds = [];
		},
		addNewTask: (state, { payload }) => {
			state.tasks.push({ type: payload, deviceTypeName: state.order.specification.deviceTypeName });
		},
		removeTaskAtIndex: (state, { payload }) => {
			state.tasks.splice(payload, 1);
			state.order.addressNodeIds = [];
		},
		removeTaskWithId: (state, { payload }) => {
			state.tasks = state.tasks.filter((t) => t.id !== payload);
			state.order.addressNodeIds = [];
		},
		setOrderNote: (state, { payload }) => {
			state.note = payload;
		},
		setOrderCurrentOwnership: (state, { payload }) => {
			state.orderCurrentOwnership = payload;
		},
		setOrderReclamation: (state, { payload }) => {
			state.order.reclamation = payload;
		}
	}
});

export const {
	setOrderStep,
	setOrderSpecificationDeviceTypeName,
	fetchOrderPending,
	fetchOrderSuccess,
	removeTask,
	submitOrderPending,
	submitOrderSuccess,
	updateTask,
	updateOrderName,
	setInitialState,
	changeTasksOrder,
	fetchCustomSpecificationPending,
	fetchSpecificationSuccess,
	fetchFittersPending,
	fetchTasksPending,
	updateTasksData,
	addOrderTaskData,
	removeOrderTaskData,
	removeTemporaryOrderTaskData,
	setTasks,
	setTasksFilters,
	setOrderedFinishDate,
	setOrdersPackId,
	setRedirectUrl,
	setPlannedOrderStartDate,
	setTags,
	addTag,
	removeTag,
	setFitters,
	setFetchedFitters,
	setLocations,
	setTaskAtIndex,
	addNewTask,
	removeTaskAtIndex,
	removeTaskWithId,
	setOrderNote,
	setOrderCurrentOwnership,
	setOrderReclamation
} = orderSlice.actions;

export const submitOrderAsync = () => async (dispatch, getState) => {
	try {
		const { order } = getState().order;
		let submitData = cloneDeep(order);

		if (order.specificationId) {
			const { id, description, version, isInActive, isOptional, canModify, ...spec } = submitData.specification;
			submitData = {
				addressNodeIds: submitData.addressNodeIds,
				userIds: submitData.userIds,
				specification: spec,
				name: submitData.name,
				orderedFinishDate: submitData.orderedFinishDate,
				plannedOrderStartDate: submitData.plannedOrderStartDate,
				id: null,
				ordersPackId: submitData.ordersPackId,
				reclamation: submitData.reclamation
			};
		}
		submitData.specification.type = SpecificationType.Custom.name;
		submitData.specification.elements = getState().order.tasks;
		submitData.orderTags = order.orderTags.map((tag) => tag.id);

		dispatch(submitOrderPending(true));

		const orderId = await OrdersService.addOrder(submitData);
		dispatch(submitOrderPending(false));

		if (typeof orderId === 'string') return orderId;
	} catch (error) {
		dispatch(submitOrderPending(false));
		dispatch(addErrorAsync({ slice, error }));
		return false;
	}
};

export const fetchOrderAsync = (id, ordersPackId) => async (dispatch, getState) => {
	try {
		dispatch(fetchOrderPending(true));

		const { order } = await OrdersService.fetchOrder(id, ordersPackId);
		dispatch(fetchOrderSuccess(order));
	} catch (error) {
		dispatch(fetchOrderPending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const fetchOrderForCopyAsync = (id) => async (dispatch, getState) => {
	try {
		dispatch(fetchOrderPending(true));

		const { order } = await OrdersService.fetchOrder(id);
		const copyKey = 'Copy';
		const value = getState().locales.data?.[copyKey];
		const name = `${order.name} (${value ?? copyKey})`;

		dispatch(fetchOrderSuccess({ ...order, name }));
	} catch (error) {
		dispatch(fetchOrderPending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const fetchCustomSpecificationAsync = (id) => async (dispatch) => {
	try {
		dispatch(fetchCustomSpecificationPending(true));
		const { specification } = await fetchTask(id);
		dispatch(fetchSpecificationSuccess(specification));
	} catch (error) {
		dispatch(fetchCustomSpecificationPending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const fetchFittersAsync = (ids) => async (dispatch) => {
	try {
		dispatch(fetchFittersPending(true));

		const res = await AccessService.fetchUsers({
			...pageDescriptor,
			Limit: ids.length,
			SortDescriptors: [{ member: 'email', sortDirection: 'asc' }],
			FilterDescriptors: [
				{
					member: 'id',
					value: ids.join(','),
					filterType: FilterType.Guid.value,
					filterOperator: filterOperators.like
				}
			]
		});

		dispatch(setFetchedFitters(res.elements));
		dispatch(fetchFittersPending(false));
	} catch (error) {
		dispatch(fetchFittersPending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const fetchTasksAsync = (ids) => async (dispatch) => {
	try {
		dispatch(fetchTasksPending(true));
		const res = await fetchTasksDetails({
			...pageDescriptor,
			Limit: ids.length,
			FilterDescriptors: [
				{
					member: 'id',
					value: ids,
					filterType: FilterType.Guid.value,
					filterOperator: filterOperators.in
				}
			]
		});
		dispatch(updateTasksData(res.elements));
	} catch (error) {
		dispatch(fetchTasksPending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const selectOrder = createSelector(
	(state) => state.order,
	(order) => ({
		order: order.order,
		pending: order.orderFetchPending
	})
);

export const selectOrderSubmitPending = (state) => state.order.orderSubmitPending;

export const selectCustomSpecification = createSelector(
	(state) => state.order,
	(order) => ({
		customSpecificationFetchPending: order.customSpecificationFetchPending
	})
);

export const selectTasksFilters = (state) => state.order.tasksFilters;

export const selectOrdersPackId = createSelector(
	(state) => state.order,
	(order) => order.ordersPackId
);

export const selectRedirectUrl = (state) => state.order.redirectUrl;

export const selectTags = (state) => state.order.order.orderTags;

export const selectFitters = createSelector(
	(state) => state.order,
	(order) => ({
		fittersIds: order.order.userIds,
		fitters: order.fitters,
		fittersPending: order.fittersPending
	})
);

export const selectLocations = createSelector(
	(state) => state.order,
	(order) => ({
		locations: order.order.addressNodeIds
	})
);

export const selectTasks = createSelector(
	(state) => state.order,
	(order) => ({
		tasks: order.tasks,
		specificationElements: order.specification?.elements ?? []
	})
);

export const selectNote = (state) => state.order.note;

export const selectOrderCurrentOwnership = (state) => state.order.orderCurrentOwnership;

export default orderSlice.reducer;
