import React, { createContext, useState } from 'react';

const SidebarContext = createContext({});

export const SidebarContextProvider = ({ children }) => {
	const [coverComponent, setCoverComponent] = useState(null);

	return (
		<SidebarContext.Provider
			value={{
				coverComponent,
				setCoverComponent
			}}>
			{children}
		</SidebarContext.Provider>
	);
};

export default SidebarContext;
