import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from 'react';
import { useLocale } from '../../../hooks';
import TileBase from '../../Tiles/TileBase';
import ScheduleContext from './scheduleContext';

const useStyles = makeStyles(() => ({
	lableRoot: {
		wordBreak: 'break-word',
		userSelect: 'none'
	}
}));

const ScheduleType = ({ type, icon, label }) => {
	const classes = useStyles();
	const { translate } = useLocale();
	const { resetSchedule, selected, setSelected } = useContext(ScheduleContext);

	const handleChangeType = () => {
		resetSchedule();
		setSelected(type);
	};

	return (
		<TileBase
			onClick={handleChangeType}
			selected={selected === type}
			showRadio={true}
			renderContent={() => {
				const Icon = icon;
				return (
					<Box display='flex' flexDirection='column' alignItems='center' padding={2}>
						<Icon style={{ fontSize: '1.7rem' }} />
						<Typography variant='caption' classes={{ root: classes.lableRoot }}>
							{translate(label)}
						</Typography>
					</Box>
				);
			}}
		/>
	);
};

export default ScheduleType;
