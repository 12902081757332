import RolesFeaturesAccess from '../Roles/RolesFeaturesAccess';
import Role from '../Enums/Role';
import useRolesByTenant from './useRolesByTenant';
import AuthService from '../AuthService';

const useRolesAccess = (tenant) => {
	const roles = () => useRolesByTenant(AuthService.getRoles(), tenant);

	const isAdministrator = () => roles().includes(Role.Administrator.name);

	const isNetworkOperator = () => roles().includes(Role.NetworkOperator.name);

	const isCustomerService = () => roles().includes(Role.CustomerService.name);

	const isExecutiveAdmin = () => roles().includes(Role.ExecutiveAdministrator.name);

	const hasAccess = (feature) => {
		let access = false;
		const featureRoles = RolesFeaturesAccess[feature]?.roles;

		if (!featureRoles) return access;
		const tenantRoles = roles();

		for (const r of tenantRoles) {
			if (featureRoles.includes(r)) {
				access = true;
				break;
			}
		}

		return access;
	};

	return { roles, hasAccess, isAdministrator, isNetworkOperator, isCustomerService, isExecutiveAdmin };
};

export default useRolesAccess;
