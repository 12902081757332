import { lazy } from 'react';
import { Features } from '@sonar/auth';

const LocationDashboard = lazy(() => import('./Dashboard/LocationDashboard'));

export default [
	{
		element: LocationDashboard,
		feature: Features.LocationNodePage.name,
		path: `locationnodes/:id`,
		exact: true,
		name: '{0}'
	}
];
