import React from 'react';
import { useTheme } from '@mui/material';

const VerticalSpacer = ({ size }) => {
	const theme = useTheme();
	const width = theme.spacing(size ?? 2);

	return <div style={{ width: width }}></div>;
};

export default VerticalSpacer;
