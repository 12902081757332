import { apiBase } from '@sonar-web/common';
import { SCHEDULER_API } from '@sonar-web/common/src/constants/system';

const path = SCHEDULER_API;

export async function fetchSchedules(ids) {
	const { data } = await apiBase(`job`, 'get', {
		path,
		params: { ids: JSON.stringify(ids) }
	});

	return data;
}

export async function fetchSchedule(id) {
	const { data } = await apiBase(`job/${id}`, 'get', {
		path
	});

	return data;
}

export async function addSchedule(schedule) {
	const { data } = await apiBase(`job`, 'post', {
		path,
		data: schedule
	});

	return data;
}

export async function updateSchedule(schedule) {
	const { data } = await apiBase(`job`, 'put', {
		path,
		data: schedule
	});

	return data;
}

export async function deleteSchedule(id) {
	const data = await apiBase(`job/${id}`, 'delete', {
		path
	});

	return data;
}
