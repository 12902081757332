export default {
	Empty: {
		name: 'Empty',
		value: null
	},
	DontSave: {
		name: 'DontSave',
		value: -1
	},
	SaveInCurrentTemplate: {
		name: 'SaveInCurrentTemplate',
		value: 0
	},
	SaveAsNewTemplate: {
		name: 'SaveAsNewTemplate',
		value: 1
	}
};
