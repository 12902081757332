import React from 'react';
import { BaseIcon } from '@sonar-web/common';

export default function PadlockIcon(props) {
	return (
		<BaseIcon {...props}>
			<path d='M 12.762082,2.0113977 C 9.735881,1.8313511 6.8976272,3.7995492 6.0823951,6.8418897 L 5.2751033,9.8495546 4.1058325,9.5370703 c -0.027999,-0.0075 -0.055288,-0.00222 -0.083333,-0.00781 V 10.760968 L 18.626666,14.672234 16.663125,22 h 1.385415 l 1.895834,-7.075174 C 20.100291,14.342962 19.690151,13.71018 19.043332,13.536874 L 17.863644,13.221785 18.668332,10.21412 C 19.600027,6.7371595 17.541298,3.1565779 14.064166,2.2249289 13.629524,2.1084728 13.194397,2.0371187 12.762082,2.0113977 Z m -0.08594,1.3358719 c 0.344874,0.021342 0.693564,0.075994 1.041666,0.1692624 2.78482,0.7461542 4.41546,3.569171 3.669271,6.3538554 L 16.579791,12.878052 6.5693741,10.195892 7.3740618,7.188227 C 8.0269787,4.7516288 10.262041,3.1978712 12.676146,3.3472696 Z' />
		</BaseIcon>
	);
}
