import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { FormControl, FormHelperText, TextField, Autocomplete } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '../../../../hooks';
import { BaseTooltip } from '../../../..';
import SelectedCounter from './ConfiguratorSelectList/SelectedCounter';
import SelectedList from './ConfiguratorSelectList/SelectedList';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative'
	},
	rootMaxReached: {
		'&:after': {
			content: '""',
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			backgroundColor: theme.palette.background.paper,
			opacity: 0.6,
			cursor: 'not-allowed'
		}
	},
	option: {
		'&[aria-selected="true"]': {
			backgroundColor: 'transparent'
		}
	}
}));

const ConfiguratorSelectList = ({ name, value, element, form, error, helperText, dataAtError }) => {
	const classes = useStyles();
	const { translate } = useLocale();
	const { options, dependsOn, defaultValue, max, nullable } = element;

	const [inputVal, setInputVal] = useState('');
	const [dependencyVisibility, setDependencyVisibility] = useState(false);
	const [opts, setOpts] = useState(getAvailableOptions(options, value, translate));
	const [selected, setSelected] = useState(value?.length > 0 ? getSelectedOptions(options, value) : []);

	const selectedCount = selected.length;
	const maxReached = selectedCount === max;

	const handleChange = (_, v) => {
		if (!v || v.length === 0) return;

		const newSelected = [...selected, v];
		const newFormValues = newSelected.map((vv) => vv.value);

		setOpts(opts.filter((o) => o.value !== v.value));
		setSelected(newSelected);

		form.setFieldValue(name, newFormValues);
		setInputVal(null);
	};

	const handleDelete = (_, item) => {
		const newSelected = selected.filter((s) => s.value !== item.value);
		const newFormValues = newSelected.map((s) => s.value);
		const formValue = newFormValues.length === 0 && nullable ? null : newFormValues;

		setSelected(newSelected);
		form.setFieldValue(name, formValue);
		setOpts(getAvailableOptions(options, newFormValues, translate));
	};

	useEffect(() => {
		if (!dependsOn) return;

		setDependencyVisibility(
			dependsOn.isEqual
				? dependsOn.value === form.values[dependsOn.key]
				: dependsOn.value !== form.values[dependsOn.key]
		);
	}, [form.values]);

	useEffect(() => {
		if (!dependsOn) return;
		if (dependencyVisibility === true) form.setFieldValue(name, value ?? defaultValue);
	}, [dependencyVisibility]);

	if (dependsOn && !dependencyVisibility) return null;
	return (
		<>
			<FormControl fullWidth margin='normal' error={error}>
				<BaseTooltip hide={!maxReached} text='MaxSelectedValuesReached' color='error'>
					<Autocomplete
						options={opts}
						onChange={handleChange}
						disableCloseOnSelect
						blurOnSelect
						inputValue={inputVal}
						noOptionsText={translate('Common_NoOptions')}
						getOptionLabel={(option) => translate(option.label)}
						renderTags={() => null}
						onInputChange={(_, newInputValue, reason) => {
							setInputVal(reason === 'reset' ? '' : newInputValue);
						}}
						filterOptions={(o, { inputValue }) => {
							if (!inputValue) return o;

							return o.filter((opt) =>
								translate(opt.label).toLowerCase().includes(inputValue.toLowerCase())
							);
						}}
						classes={{
							root: clsx(classes.root, { [classes.rootMaxReached]: maxReached }),
							option: classes.option
						}}
						renderInput={(params) => (
							<TextField {...params} variant='outlined' label={translate('Common_SelectElements')} />
						)}
						disabled={maxReached}
					/>
				</BaseTooltip>
				<FormHelperText data-at-error={dataAtError}>{helperText}</FormHelperText>
			</FormControl>
			<SelectedCounter current={selectedCount} max={max} />
			<SelectedList name={name} selected={selected} onDelete={handleDelete} />
		</>
	);
};

export default ConfiguratorSelectList;

function getSelectedOptions(options, values) {
	if (!values) return options;
	return options.reduce((acc, option) => {
		if (values.includes(option.value)) acc.push(option);
		return acc;
	}, []);
}

function getAvailableOptions(options, values, translate) {
	if (!values) return sortOptions(options, translate);
	const optionsArr = options.reduce((acc, option) => {
		if (!values.includes(option.value)) acc.push(option);
		return acc;
	}, []);

	return sortOptions(optionsArr, translate);
}

function sortOptions(options, translate) {
	if (!options || options.length === 0) return [];
	return [...options].sort((a, b) => translate(a.label).localeCompare(translate(b.label)));
}
