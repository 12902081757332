import * as Yup from 'yup';

const getMFieldCode = (mField) => {
	var mFieldCode = (((mField.charCodeAt(0) - '@'.charCodeAt(0)) & 0x7f) << 10) & 0xffff;
	mFieldCode = mFieldCode | ((((mField.charCodeAt(1) - '@'.charCodeAt(0)) & 0x1f) << 5) & 0xffff);
	mFieldCode = mFieldCode | ((mField.charCodeAt(2) - '@'.charCodeAt(0)) & 0x1f & 0xffff);
	return mFieldCode;
};

const getMField = (mFieldCode) => {
	var b1 = (mFieldCode / 1024) & 0xff;
	var b2 = ((mFieldCode - b1 * 1024) / 32) & 0xff;
	var b3 = (mFieldCode - b1 * 1024 - b2 * 32) & 0xff;
	b1 += 0x40;
	b2 += 0x40;
	b3 += 0x40;
	String.fromCharCode(b1);

	var id = String.fromCharCode(b1) + String.fromCharCode(b2) + String.fromCharCode(b3);
	return id.toUpperCase();
};

export default Yup.object().shape({
	name: Yup.string()
		.nullable()
		.min(1, 'Devices_NameValidationMin')
		.max(50, 'Devices_NameValidationMax')
		.required('Common_ValidationRequired')
		.matches(/^(?!.*;).*$/, 'Devices_NameValidation'),
	sw: Yup.number()
		.nullable()
		.typeError('Devices_SwValidationNumber')
		.min(0, 'Devices_SwValidationMin')
		.max(255, 'Devices_SwValidationMax')
		.required('Common_ValidationRequired'),
	hw: Yup.number()
		.nullable()
		.typeError('Devices_HwValidationNumber')
		.min(0, 'Devices_HwValidationMin')
		.max(127, 'Devices_HwValidationMax')
		.required('Common_ValidationRequired'),
	mField: Yup.string()
		.nullable()
		.min(3, 'Devices_MfieldValidationMin')
		.max(3, 'Devices_MfieldValidationMax')
		.required('Common_ValidationRequired')
		.matches(/([A-Z]+)$/, 'Devices_MfieldValidation')
		.test('isMField', 'Devices_MfieldValidation', (value) => {
			if (!value) return false;
			if (value.length != 3) return false;

			const mFieldCode = getMFieldCode(value);
			const mField = getMField(mFieldCode);

			return mField == value;
		})
});
