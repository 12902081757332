const LIMIT = 20;
const OFFSET = 0;

export const pageDescriptor = {
	FilterDescriptors: [],
	SortDescriptors: [],
	IncludeTotalRowCount: true,
	Limit: LIMIT,
	Offset: OFFSET
};

export const getSliceFilters = (state, name) => {
	let result = [];
	const slice = state.filters[name];

	if (!slice) return result;

	for (const member in slice) {
		const memberFilters = slice[member];

		memberFilters.map((mf) => {
			result = [...result, mf];
		});
	}

	return result;
};

export const getSliceSorters = (state, name) => {
	return state.sorters[name] ?? [];
};

export const getSliceDescriptor = (getState, slice, defaultFilters, defaultSorters, limit) => {
	const state = getState();
	const sorters = getSliceSorters(state, slice);
	let pd = Object.assign({}, state[slice].pageDescriptor);

	if (limit) pd.Limit = limit;
	pd.SortDescriptors = sorters.length ? sorters : defaultSorters ?? [];
	pd.FilterDescriptors = defaultFilters
		? defaultFilters.concat(getSliceFilters(state, slice))
		: getSliceFilters(state, slice);

	return pd;
};
