import React from 'react';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
		marginBottom: theme.spacing(2),
		backgroundColor: theme.palette.primaryLight.dark,
		borderRadius: '4px'
	},
	messageRoot: {
		marginLeft: theme.spacing(2),
		whiteSpace: 'break-spaces'
	}
}));

const NotificationBar = ({ text, show, ...rest }) => {
	const classes = useStyles();

	if (!show) return null;
	return (
		<Box display='flex' alignItems='center' className={classes.root} {...rest}>
			<NotificationsActiveOutlinedIcon color='primary' />
			<Typography
				variant='body2'
				color='primary'
				classes={{ root: classes.messageRoot }}
				dangerouslySetInnerHTML={{
					__html: text
				}}
			/>
		</Box>
	);
};

export default NotificationBar;
