import { useCallback, useMemo } from 'react';
import { Typography } from '@mui/material';
import { useLocale } from '@sonar-web/common/src/hooks';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CheckboxCheckedIcon from '../../icons/CheckboxCheckedIcon';
import CheckboxUncheckedIcon from '../../icons/CheckboxUncheckedIcon';
import TileButtonSelectable_Styles from './TileButtonSelectable_Styles';
import { BaseCircularProgress } from '../../components';
import TileButtonErrorMessage from './TileButtonErrorMessage';

const { Content, IndicatorArea, Left, Right, Wrapper } = TileButtonSelectable_Styles;

const TileButtonSelectable = ({
	children,
	Icon,
	title,
	subtitle,
	onClick,
	selected = false,
	pending,
	error = '',
	iconProps = {},
	titleProps = {},
	subtitleProps = {},
	EndIcon,
	endIconProps = {},
	EndButton,
	checkbox = false,
	radio = false,
	readOnly = false,
	...rest
}) => {
	const { translate } = useLocale();

	const CheckCmp = useCallback(() => {
		if (!checkbox) return null;
		return selected ? <CheckboxCheckedIcon /> : <CheckboxUncheckedIcon />;
	}, [selected, checkbox]);

	const RadioCmp = useCallback(() => {
		if (!radio) return null;
		return selected ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />;
	}, [selected, radio]);

	const EndIconCmp = useCallback(() => {
		if (!EndIcon) return null;
		return <EndIcon fontSize='sm' {...endIconProps} />;
	}, []);

	const EndButtonCmp = useCallback(() => {
		if (!EndButton) return null;
		return <EndButton />;
	}, []);

	const wrapperClasses = useMemo(() => {
		let classes = 'tileButtonSelectableWrapper';
		if (selected) classes += ' selected';
		return classes;
	}, [selected]);

	return (
		<Wrapper
			type='button'
			className={wrapperClasses}
			onClick={readOnly ? null : onClick}
			data-readonly={readOnly}
			{...rest}>
			{pending && (
				<BaseCircularProgress
					pending={true}
					sx={{ position: 'absolute', right: '27px', top: 'calc(50% - 12px)' }}
				/>
			)}
			{error && <TileButtonErrorMessage error={error} />}
			{!children && (
				<Content>
					{Icon && (
						<Left>
							<Icon fontSize='sm' {...iconProps} />
						</Left>
					)}
					<Right>
						<Typography variant='body2' className='tileButtonSelectableTitle' {...titleProps}>
							{translate(title)}
						</Typography>
						{subtitle && (
							<Typography
								variant='caption'
								color='textSecondary'
								className='tileButtonSelectableSubtitle'
								{...subtitleProps}>
								{translate(subtitle)}
							</Typography>
						)}
					</Right>
					{!readOnly && (
						<IndicatorArea selected={selected}>
							<CheckCmp />
							<RadioCmp />
							<EndIconCmp />
							<EndButtonCmp />
						</IndicatorArea>
					)}
				</Content>
			)}
			{children}
		</Wrapper>
	);
};

export default TileButtonSelectable;
