import React from 'react';
import { Container, Dialog, Fade } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
	paper: {
		minWidth: 500,
		height: ({ licenseNotifierHidden }) => `calc(100% - ${licenseNotifierHidden ? 120 : 160}px)`,
		transition: 'height 500ms ease',
		width: '100%',
		maxWidth: theme.breakpoints.values.lg
	},
	scrollPaper: {
		alignItems: 'flex-end'
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%'
	}
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Fade ref={ref} {...props} />;
});

const PanelContentDialog = ({ children, open, setOpen }) => {
	const licenseNotifier = document.getElementById('LicenseNotifier');
	const licenseNotifierHidden = licenseNotifier.classList.contains('MuiCollapse-hidden');

	const classes = useStyles({ licenseNotifierHidden });

	const handleClose = () => setOpen(false);

	return (
		<Dialog
			open={open}
			onClose={(_, reason) => {
				if (reason === 'backdropClick') return;
				handleClose();
			}}
			TransitionComponent={Transition}
			BackdropProps={{
				invisible: true
			}}
			PaperProps={{
				elevation: 0
			}}
			classes={{ paper: classes.paper, scrollPaper: classes.scrollPaper }}>
			<Container className={classes.container}>{children(setOpen)}</Container>
		</Dialog>
	);
};

export default PanelContentDialog;
