import React from 'react';
import ListItemTextBase from '../ListItemTextBase/ListItemTextBase';
import { useLocale } from '../../hooks';

const PanelListItemText = ({ primaryText, secondaryText, disableFlex, ...rest }) => {
	const { translate } = useLocale();

	const secondaryComponent = () => {
		if (secondaryText == null) return null;

		return typeof secondaryText === 'function' ? secondaryText(translate) : translate(secondaryText);
	};

	return (
		<ListItemTextBase
			hideBorder
			disableFlex={disableFlex}
			primary={primaryText}
			secondary={secondaryComponent()}
			{...rest}
		/>
	);
};

export default PanelListItemText;
