import { useContext } from 'react';
import EventBus from '@sonar-web/common/src/helpers/EventBus';
import DashboardContentContext from '@sonar-web/common/src/components/Dashboard/dashboardContentContext';
import ButtonAdd from '@sonar-web/common/src/components/Buttons/ButtonAdd';
import { Features, Restricted } from '@sonar/auth';
import ReadForm from './ReadForm';
import Messages from '../Constants/Messages';

const ReadAdd = ({ location }) => {
	const { setCoverComponent } = useContext(DashboardContentContext);

	const onClick = () => {
		setCoverComponent(
			<ReadForm
				heatallocatorId={location.currentSet.measurementPairs[0].inputDevice.id}
				startDate={location.currentSet.startDate}
				onClose={() => setCoverComponent(null)}
				onSuccess={() => {
					setCoverComponent(null);
					EventBus.dispatch(Messages.ManualReadAdded);
				}}
			/>
		);
	};

	return (
		<Restricted to={Features.ReadsModification.name}>
			<ButtonAdd name='read_add' onClick={onClick}>
				Read
			</ButtonAdd>
		</Restricted>
	);
};

export default ReadAdd;
