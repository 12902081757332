import { useNavigate } from 'react-router-dom';
import useConfigurator from './useConfigurator';

export default () => {
	const navigate = useNavigate();
	const { steps, previousStep, nextStep, activeStep, isValid } = useConfigurator();
	const stepsCount = steps.length;

	const back = {
		hidden: false,
		onClick: previousStep
	};

	const next = {
		hidden: false,
		disabled: false,
		onClick: nextStep
	};

	const cancel = {
		onClick: () => navigate(-1)
	};

	const getButtonsState = () => {
		if (activeStep === 0) {
			back.hidden = true;
		} else if (activeStep === stepsCount - 1) {
			next.hidden = true;
		}

		next.disabled = !isValid[activeStep];

		return { back, next, cancel };
	};

	return { getButtonsState };
};
