import React from 'react';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	innerTopPadding: {
		paddingTop: theme.spacing(3)
	},
	hidden: {
		display: 'none'
	}
}));

const TabPanelBase = ({
	children,
	value,
	index,
	disableRemoveFromDOM,
	disableInnerTopPadding,
	tabPanelBasePropsObj,
	...other
}) => {
	const classes = useStyles();

	const content = (disableRemove) =>
		disableRemove ? (
			<Box
				display='flex'
				flexDirection='column'
				minHeight='0'
				height='100%'
				className={clsx({
					[classes.hidden]: value !== index,
					[classes.innerTopPadding]: !disableInnerTopPadding
				})}
				{...tabPanelBasePropsObj}>
				{children}
			</Box>
		) : (
			value === index && (
				<Box
					display='flex'
					flexDirection='column'
					minHeight='0'
					height='100%'
					className={clsx({ [classes.innerTopPadding]: !disableInnerTopPadding })}
					{...tabPanelBasePropsObj}>
					{children}
				</Box>
			)
		);

	return (
		<Box
			minHeight={0}
			height='100%'
			role='tabpanel'
			hidden={value !== index}
			id={`wrapped-tabpanel-${index}`}
			aria-labelledby={`wrapped-tab-${index}`}
			{...other}>
			{content(disableRemoveFromDOM)}
		</Box>
	);
};

export default TabPanelBase;
