import React from 'react';
import { IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '../../hooks';
import BaseTooltip from '../BaseTooltip/BaseTooltip';
import { Link } from 'react-router-dom';
const useStyles = makeStyles(() => ({
	linkRoot: {
		display: 'inline'
	}
}));

const DashboardTitleAction = ({ icon, tooltip, onClick, renderLink, name }) => {
	const classes = useStyles();
	const { translate } = useLocale();

	return (
		<BaseTooltip text={translate(tooltip)}>
			{renderLink ? (
				<Link
					onClick={(e) => e.stopPropagation()}
					to={onClick()}
					target='_blank'
					className={classes.linkRoot}
					name={name}>
					<IconButton edge='start' size='small'>
						{icon}
					</IconButton>
				</Link>
			) : (
				<IconButton edge='start' size='small' onClick={onClick} name={name}>
					{icon}
				</IconButton>
			)}
		</BaseTooltip>
	);
};

export default DashboardTitleAction;
