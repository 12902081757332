const listItemHeight = 61;

export const listScrollToElement = (elementIndex, scrollableComponentId, delay = 0) => {
	const amountToScroll = listItemHeight * elementIndex;
	const element = document.getElementById(scrollableComponentId);

	if (!element) return;

	const scrollable = element.parentNode.parentNode;
	setTimeout(() => scrollable.scrollTo({ top: amountToScroll, behavior: 'instant' }), delay);
};

export const checkListItemIsSelectable = (target) => {
	const isClickablePrimaryText =
		target.className && typeof target.className === 'string' && target.className.includes('linkBehavior');
	const noEventTags = ['BUTTON', 'path', 'svg'];
	const isLink = target.getAttribute('data-at-link') === 'ListItemPrimaryTextLink';

	return !(isClickablePrimaryText || noEventTags.includes(target.tagName) || isLink);
};
