import { apiBase } from '@sonar-web/common';
import { REST_API_PATHS } from '@sonar-web/common/src/constants/system';
import { fillMissingDates } from '@sonar-web/location/src/LocationNodeCharts/Utils/ChartSettings';

const path = REST_API_PATHS.HEATALLOCATORS;

async function fetchChartReads({ pageDescriptor, from, to }) {
	const { data } = await apiBase(`reads`, 'get', {
		path,
		params: { pageDescriptor }
	});

	return { elements: fillMissingDates(data.elements, from, to) };
}

async function fetchChartConsumptions(params) {
	const { data } = await apiBase(`consumptions`, 'get', {
		path,
		params
	});

	return data;
}

export async function addHeatAllocator(data) {
	const response = await apiBase(`heatallocators`, 'post', {
		path,
		data
	});

	const locationHeader = response.headers.location;

	if (locationHeader)
		return {
			url: locationHeader,
			id: locationHeader.slice(locationHeader.indexOf('/') + 1)
		};

	return response;
}

export async function fetchHeatAllocatorTypes(pageDescriptor) {
	const { data } = await apiBase(`heatAllocatorTypes`, 'get', {
		path,
		params: { pageDescriptor }
	});

	return data;
}

const HeatAllocatorService = {
	fetchChartReads,
	fetchChartConsumptions,
	addHeatAllocator,
	fetchHeatAllocatorTypes
};

export default HeatAllocatorService;
