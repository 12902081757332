import React from 'react';
import { IMaskInput } from 'react-imask';

const TextMaskAes = React.forwardRef(function TextMaskCustom(props, ref) {
	const { name, onChange, arrayLength, ...other } = props;

	return (
		<IMaskInput
			{...other}
			mask='** ** ** ** ** ** ** **'
			definitions={{
				'*': /[0-9ABCDEFabcdef]/
			}}
			placeholder='00 00 00 00 00 00 00 00'
			inputRef={ref}
			onAccept={(value) => {
				onChange({ target: { name, value } });
			}}
			overwrite
		/>
	);
});

export default TextMaskAes;
