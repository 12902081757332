import { createSlice, createSelector } from '@reduxjs/toolkit';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import MeshService from './MeshService';
import Module from './Constants/Module';
import TransmitHelpers from './Helpers/TransmitHelpers';

const slice = Module.slices.meshNetwork;
const initialNetworkData = null;

const getNetwork = (getState) => getState().meshNetwork.meshNetwork;

export const networkSlice = createSlice({
	name: slice,
	initialState: {
		addPending: false,
		fetchPending: false,
		deletePending: false,
		deleteSuccess: false,
		meshNetwork: initialNetworkData,
		assignToNetworkPending: false,
		unassignFromNetworkPending: false,
		networkProperties: [],
		networkPropertiesPending: false,
		setNetworkSchedulePending: false,
		networkSchedule: null,
		networkSchedulePending: false,
		deleteNetworkSchedulePending: false,
		setTransmitPending: false,
		transmit: null,
		fetchTransmitPending: false,
		deleteTransmitPending: false,
		transmitsStats: null,
		networkAddresses: null,
		fetchTransmitsStatsPending: false,
		fetchNetworkAddressesPending: false,
		routingTablesFlagsPending: false,
		routingTablesFlags: null,
		networkStructureFlagsPending: false,
		networkStructureFlags: null
	},
	reducers: {
		fetchNetworkSucces: (state, { payload }) => {
			state.meshNetwork = payload;
			state.fetchPending = false;
		},
		fetchNetworkPending: (state, { payload }) => {
			state.fetchPending = payload;
		},
		addOrEditNetworkPending: (state, { payload }) => {
			state.addPending = payload;
		},
		deleteNetworkSucces: (state) => {
			state.deletePending = false;
			state.deleteSuccess = true;
		},
		deleteNetworkPending: (state, { payload }) => {
			state.deletePending = payload;
		},
		setNetworkData: (state, action) => {
			state.meshNetwork = action.payload;
		},
		resetNetworkData: (state) => {
			state.meshNetwork = initialNetworkData;
			state.deleteSuccess = false;
		},
		assignToNetworkPending: (state, { payload }) => {
			state.assignToNetworkPending = payload;
		},
		unassignFromNetworkPending: (state, { payload }) => {
			state.unassignFromNetworkPending = payload;
		},
		fetchNetworkPropertiesPending: (state, { payload }) => {
			state.networkPropertiesPending = payload;
		},
		fetchNetworkPropertiesSuccess: (state, { payload }) => {
			state.networkPropertiesPending = false;
			state.networkProperties = payload;
		},
		setNetworkSchedulePending: (state, { payload }) => {
			state.setNetworkSchedulePending = payload;
		},
		fetchNetworkSchedulePending: (state, { payload }) => {
			state.networkSchedulePending = payload;
		},
		fetchNetworkScheduleSuccess: (state, { payload }) => {
			state.networkSchedulePending = false;
			state.networkSchedule = payload;
		},
		deleteNetworkSchedulePending: (state) => {
			state.deleteNetworkSchedulePending = false;
		},
		setTransmitPending: (state, { payload }) => {
			state.setTransmitPending = payload;
		},
		fetchTransmitPending: (state, { payload }) => {
			state.fetchTransmitPending = payload;
		},
		fetchTransmitSuccess: (state, { payload }) => {
			state.fetchTransmitPending = false;
			state.transmit = payload;
		},
		deleteTransmitPending: (state) => {
			state.deleteTransmitPending = false;
		},
		fetchTransmitsStatsPending: (state, { payload }) => {
			state.fetchTransmitsStatsPending = payload;
		},
		fetchTransmitsStatsSuccess: (state, { payload }) => {
			state.fetchTransmitsStatsPending = false;
			state.transmitsStats = payload;
		},
		fetchNetworkAddressesPending: (state, { payload }) => {
			state.fetchNetworkAddressesPending = payload;
		},
		fetchNetworkAddressesSuccess: (state, { payload }) => {
			state.fetchNetworkAddressesPending = false;
			state.networkAddresses = payload;
		},
		resetNetworkAddresses: (state) => {
			state.networkAddresses = null;
		},
		routingTablesFlagsPending: (state, { payload }) => {
			state.routingTablesFlagsPending = payload;
		},
		fetchRoutingTablesFlagsSuccess: (state, { payload }) => {
			state.routingTablesFlagsPending = false;
			state.routingTablesFlags = payload;
		},
		networkStructureFlagsPending: (state, { payload }) => {
			state.networkStructureFlagsPending = payload;
		},
		fetchNetworkStructureFlagsSuccess: (state, { payload }) => {
			state.networkStructureFlagsPending = false;
			state.networkStructureFlags = payload;
		}
	}
});

export const {
	fetchNetworkSucces,
	fetchNetworkPending,
	addOrEditNetworkPending,
	deleteNetworkSucces,
	deleteNetworkPending,
	setNetworkData,
	resetNetworkData,
	assignToNetworkPending,
	unassignFromNetworkPending,
	fetchNetworkPropertiesPending,
	fetchNetworkPropertiesSuccess,
	setNetworkSchedulePending,
	fetchNetworkScheduleSuccess,
	fetchNetworkSchedulePending,
	deleteNetworkSchedulePending,
	setTransmitPending,
	fetchTransmitPending,
	fetchTransmitSuccess,
	deleteTransmitPending,
	fetchTransmitsStatsPending,
	fetchTransmitsStatsSuccess,
	fetchNetworkAddressesPending,
	fetchNetworkAddressesSuccess,
	resetNetworkAddresses,
	routingTablesFlagsPending,
	fetchRoutingTablesFlagsSuccess,
	networkStructureFlagsPending,
	fetchNetworkStructureFlagsSuccess
} = networkSlice.actions;

export const fetchNetworkAsync = (id) => async (dispatch) => {
	try {
		dispatch(fetchNetworkPending(true));
		const response = await MeshService.fetchNetwork(id);
		dispatch(fetchNetworkSucces(response));
	} catch (error) {
		dispatch(fetchNetworkPending(false));
		return dispatch(addErrorAsync({ slice, error }));
	}
};

export const addNetworkAsync = () => async (dispatch, getState) => {
	const network = getNetwork(getState);

	try {
		dispatch(addOrEditNetworkPending(true));
		const response = await MeshService.addNetwork(network);
		dispatch(addOrEditNetworkPending(false));
		return response;
	} catch (error) {
		dispatch(addOrEditNetworkPending(false));
		return dispatch(
			addErrorAsync({ slice, error, skipNotificationMembers: ['name', 'cycleLength', 'maxDevicesSlotsCount'] })
		);
	}
};

export const editNetworkAsync = () => async (dispatch, getState) => {
	const network = getNetwork(getState);

	try {
		dispatch(addOrEditNetworkPending(true));
		await MeshService.editNetwork(network);
		dispatch(addOrEditNetworkPending(false));
	} catch (error) {
		dispatch(addOrEditNetworkPending(false));
		return dispatch(
			addErrorAsync({ slice, error, skipNotificationMembers: ['name', 'cycleLength', 'maxDevicesSlotsCount'] })
		);
	}
};

export const deleteNetworkAsync = (id) => async (dispatch) => {
	try {
		dispatch(deleteNetworkPending(true));
		await MeshService.deleteNetwork(id);
		dispatch(deleteNetworkSucces());
	} catch (error) {
		dispatch(deleteNetworkPending(false));
		return dispatch(addErrorAsync({ slice, error }));
	}
};

export const assignToNetworkAsync = (networkId, assignData) => async (dispatch) => {
	try {
		dispatch(assignToNetworkPending(true));
		await MeshService.assignItemsToNetwork(networkId, assignData);
		dispatch(assignToNetworkPending(false));
		return true;
	} catch (error) {
		dispatch(assignToNetworkPending(false));
		return dispatch(addErrorAsync({ slice, error }));
	}
};

export const unassignFromNetworkAsync = (networkId, assignData) => async (dispatch) => {
	try {
		dispatch(unassignFromNetworkPending(true));
		await MeshService.unassignItemsFromNetwork(networkId, assignData);
		dispatch(unassignFromNetworkPending(false));
		return true;
	} catch (error) {
		dispatch(unassignFromNetworkPending(false));
		return dispatch(addErrorAsync({ slice, error }));
	}
};

export const fetchNetworkPropertiesAsync = () => async (dispatch) => {
	try {
		dispatch(fetchNetworkPropertiesPending(true));
		const response = await MeshService.fetchNetworkProperties();
		dispatch(fetchNetworkPropertiesSuccess(response.elements));
	} catch (error) {
		dispatch(fetchNetworkPropertiesPending(false));
		return dispatch(addErrorAsync({ slice, error }));
	}
};

export const addNetworkScheduleAsync = (scheduleInfo) => async (dispatch) => {
	try {
		dispatch(setNetworkSchedulePending(true));
		await MeshService.addNetworkSchedule(scheduleInfo);
		dispatch(setNetworkSchedulePending(false));
	} catch (error) {
		dispatch(setNetworkSchedulePending(false));
		return dispatch(addErrorAsync({ slice, error }));
	}
};

export const editNetworkScheduleAsync = (scheduleInfo) => async (dispatch) => {
	try {
		dispatch(setNetworkSchedulePending(true));
		await MeshService.editNetworkSchedule(scheduleInfo);
		dispatch(setNetworkSchedulePending(false));
	} catch (error) {
		dispatch(setNetworkSchedulePending(false));
		return dispatch(addErrorAsync({ slice, error }));
	}
};

export const fetchNetworkScheduleAsync = (networkId) => async (dispatch) => {
	try {
		dispatch(fetchNetworkSchedulePending(true));
		const response = await MeshService.fetchNetworkSchedule(networkId);
		dispatch(fetchNetworkScheduleSuccess(response));
	} catch (error) {
		dispatch(fetchNetworkSchedulePending(false));
		return dispatch(addErrorAsync({ slice, error }));
	}
};

export const deleteNetworkScheduleAsync = (networkId) => async (dispatch) => {
	try {
		dispatch(deleteNetworkSchedulePending(true));
		await MeshService.deleteNetworkSchedule(networkId);
		dispatch(deleteNetworkSchedulePending(false));
		return true;
	} catch (error) {
		dispatch(deleteNetworkSchedulePending(false));
		return dispatch(addErrorAsync({ slice, error }));
	}
};

export const fetchNetworkAddressesAsync = () => async (dispatch) => {
	try {
		dispatch(fetchNetworkAddressesPending(true));
		const response = await MeshService.fetchNetworkAddresses();
		dispatch(fetchNetworkAddressesSuccess(response));
	} catch (error) {
		dispatch(fetchNetworkAddressesPending(false));
		return dispatch(addErrorAsync({ slice, error }));
	}
};

export const addTransmitAsync =
	({ networkId, transmit }) =>
	async (dispatch) => {
		try {
			dispatch(setTransmitPending(true));
			await MeshService.addTransmit({ networkId, transmit });
			dispatch(setTransmitPending(false));
		} catch (error) {
			dispatch(setTransmitPending(false));
			return dispatch(addErrorAsync({ slice, error }));
		}
	};

export const editTransmitAsync =
	({ networkId, transmit }) =>
	async (dispatch) => {
		try {
			dispatch(setTransmitPending(true));
			await MeshService.editTransmit({ networkId, transmit });
			dispatch(setTransmitPending(false));
		} catch (error) {
			dispatch(setTransmitPending(false));
			return dispatch(addErrorAsync({ slice, error }));
		}
	};

export const fetchTransmitAsync =
	({ networkId, id }) =>
	async (dispatch) => {
		try {
			dispatch(fetchTransmitPending(true));
			const response = await MeshService.fetchTransmit({ networkId, id });
			dispatch(fetchTransmitSuccess(TransmitHelpers.prepareFetchedData(response)));
		} catch (error) {
			dispatch(fetchTransmitPending(false));
			return dispatch(addErrorAsync({ slice, error }));
		}
	};

export const deleteTransmitAsync =
	({ networkId, id }) =>
	async (dispatch) => {
		try {
			dispatch(deleteTransmitPending(true));
			await MeshService.deleteTransmit({ networkId, id });
			dispatch(deleteTransmitPending(false));
		} catch (error) {
			dispatch(deleteTransmitPending(false));
			return dispatch(addErrorAsync({ slice, error }));
		}
	};

export const fetchTransmitsStatsAsync = (networkId) => async (dispatch) => {
	try {
		dispatch(fetchTransmitsStatsPending(true));
		const response = await MeshService.fetchTransmitsStats(networkId);
		dispatch(fetchTransmitsStatsSuccess(response));
	} catch (error) {
		dispatch(fetchTransmitsStatsPending(false));
		return dispatch(addErrorAsync({ slice, error }));
	}
};

export const fetchRoutingTablesFlagsAsync = (networkId) => async (dispatch) => {
	try {
		dispatch(routingTablesFlagsPending(true));
		const response = await MeshService.fetchRoutingTablesFlags(networkId);
		dispatch(fetchRoutingTablesFlagsSuccess(response));
	} catch (error) {
		dispatch(routingTablesFlagsPending(false));
		return dispatch(addErrorAsync({ slice, error }));
	}
};

export const fetchNetworkStructureFlagsAsync = (networkId) => async (dispatch) => {
	try {
		dispatch(networkStructureFlagsPending(true));
		const response = await MeshService.fetchNetworkStructureFlags(networkId);
		dispatch(fetchNetworkStructureFlagsSuccess(response));
	} catch (error) {
		dispatch(networkStructureFlagsPending(false));
		return dispatch(addErrorAsync({ slice, error }));
	}
};

export const selectNetwork = createSelector(
	(state) => state.meshNetwork,
	(network) => ({
		network: network.meshNetwork,
		fetchPending: network.fetchPending
	})
);

export const selectNetworkAdd = createSelector(
	(state) => state.meshNetwork,
	(network) => ({
		addPending: network.addPending,
		addSuccess: network.addSuccess
	})
);

export const selectDeleteNetwork = createSelector(
	(state) => state.meshNetwork,
	(network) => ({
		deletePending: network.deletePending,
		deleteSuccess: network.deleteSuccess
	})
);

export const selectAssignToNetworkPending = createSelector(
	(state) => state.meshNetwork,
	(network) => ({
		assignToNetworkPending: network.assignToNetworkPending
	})
);

export const selectUnassignFromNetworkPending = createSelector(
	(state) => state.meshNetwork,
	(network) => ({
		unassignFromNetworkPending: network.unassignFromNetworkPending
	})
);

export const selectNetworkProperties = createSelector(
	(state) => state.meshNetwork,
	(network) => ({
		networkPropertiesPending: network.networkPropertiesPending,
		networkProperties: network.networkProperties
	})
);

export const selectSetNetworkSchedulePending = createSelector(
	(state) => state.meshNetwork,
	(network) => ({
		setNetworkSchedulePending: network.setNetworkSchedulePending
	})
);

export const selectNetworkSchedule = createSelector(
	(state) => state.meshNetwork,
	(network) => ({
		networkSchedulePending: network.networkSchedulePending,
		networkSchedule: network.networkSchedule
	})
);

export const selectDeleteNetworkSchedule = createSelector(
	(state) => state.meshNetwork,
	(network) => ({
		deleteNetworkSchedulePending: network.deleteNetworkSchedulePending
	})
);

export const selectSetTransmitPending = createSelector(
	(state) => state.meshNetwork,
	(network) => ({
		setTransmitPending: network.setTransmitPending
	})
);

export const selectTransmit = createSelector(
	(state) => state.meshNetwork,
	(network) => ({
		fetchTransmitPending: network.fetchTransmitPending,
		transmit: network.transmit
	})
);

export const selectDeleteTransmit = createSelector(
	(state) => state.meshNetwork,
	(network) => ({
		deleteTransmitPending: network.deleteTransmitPending
	})
);

export const selectTransmitsStats = createSelector(
	(state) => state.meshNetwork,
	(network) => ({
		fetchTransmitsStatsPending: network.fetchTransmitsStatsPending,
		transmitsStats: network.transmitsStats
	})
);

export const selectNetworkAddresses = createSelector(
	(state) => state.meshNetwork,
	(network) => ({
		networkAddresses: network.networkAddresses,
		fetchNetworkAddressesPending: network.fetchNetworkAddressesPending
	})
);

export const selectRoutingTablesFlags = createSelector(
	(state) => state.meshNetwork,
	(network) => ({
		routingTablesFlags: network.routingTablesFlags,
		routingTablesFlagsPending: network.routingTablesFlagsPending
	})
);

export const selectNetworkStructureFlags = createSelector(
	(state) => state.meshNetwork,
	(network) => ({
		networkStructureFlags: network.networkStructureFlags,
		networkStructureFlagsPending: network.networkStructureFlagsPending
	})
);

export default networkSlice.reducer;
