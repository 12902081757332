import { createSlice } from '@reduxjs/toolkit';
import { pageDescriptor, getSliceDescriptor } from '@sonar-web/common';
import { fetchWatermeterMonitReads } from './watermeterMonitReadsApi';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';

const slice = 'watermeterMonitReads';

export const watermeterMonitReadsSlice = createSlice({
	name: slice,
	initialState: {
		pageDescriptor,
		pending: false,
		totalCount: 0,
		hasMore: true,
		reset: false,
		WatermeterMonitReads: [],
		selected: null,
		exportReadsPending: false,
		exportReads: []
	},
	reducers: {
		fetchWatermeterMonitReadsSucces: (state, action) => {
			const { elements, totalCount } = action.payload;

			state.WatermeterMonitReads =
				state.pageDescriptor.Offset === 0 ? elements : [...state.WatermeterMonitReads, ...elements];
			state.totalCount = totalCount;
			state.pending = false;
			state.reset = false;
			state.hasMore = totalCount > state.pageDescriptor.Offset + state.pageDescriptor.Limit;
		},
		fetchWatermeterMonitReadsPending: (state, action) => {
			state.pending = action.payload;
		},
		setResetAndReload: (state, action) => {
			state.reset = action.payload ?? true;
			state.pageDescriptor.Offset = 0;
		},
		setOffset: (state, action) => {
			state.pageDescriptor.Offset = action.payload ? state.pageDescriptor.Offset + state.pageDescriptor.Limit : 0;
		},
		setSelected: (state, action) => {
			state.selected = action.payload;
		},
		fetchExportReadsSuccess: (state, { payload }) => {
			state.exportReadsPending = false;
		},
		fetchExportReadsPending: (state, { payload }) => {
			state.exportReadsPending = payload;
		}
	}
});

export const {
	fetchWatermeterMonitReadsSucces,
	fetchWatermeterMonitReadsPending,
	setResetAndReload,
	setSelected,
	setOffset,
	fetchExportReadsSuccess,
	fetchExportReadsPending
} = watermeterMonitReadsSlice.actions;

export const fetchWatermeterMonitReadsAsync = (defaultFilters) => async (dispatch, getState) => {
	try {
		const pd = getSliceDescriptor(getState, slice, defaultFilters);

		dispatch(fetchWatermeterMonitReadsPending(true));
		const response = await fetchWatermeterMonitReads(pd);

		dispatch(fetchWatermeterMonitReadsSucces(response));
		return response;
	} catch (error) {
		dispatch(fetchWatermeterMonitReadsPending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const fetchWaterMeterExportMonitoringReadsAsync = (filters) => async (dispatch, getState) => {
	try {
		const pd = getSliceDescriptor(getState, slice, null, null, Math.pow(2, 31) - 1);
		pd.FilterDescriptors = [...pd.FilterDescriptors, ...filters];
		pd.Offset = 0;

		dispatch(fetchExportReadsPending(true));
		const response = await fetchWatermeterMonitReads(pd);
		dispatch(fetchExportReadsSuccess(response));
		return response;
	} catch (error) {
		dispatch(fetchExportReadsPending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const selectWatermeterMonitReads = (state) => {
	return {
		dataRows: state.watermeterMonitReads.WatermeterMonitReads,
		pending: state.watermeterMonitReads.pending,
		hasMore: state.watermeterMonitReads.hasMore,
		reset: state.watermeterMonitReads.reset,
		totalCount: state.watermeterMonitReads.totalCount
	};
};

export const selectWaterMeterExportMonitoringReads = (state) => state.watermeterMonitReads.exportReadsPending;

export const selectSelected = (state) => state.watermeterMonitReads.selected;

export default watermeterMonitReadsSlice.reducer;
