import { Typography, Stack } from '@mui/material';
import { useLocale } from '@sonar-web/common/src/hooks';
import EventBus from '@sonar-web/common/src/helpers/EventBus';
import AuthService from '@sonar/auth/src/AuthService';
import useCurrentTenant from '../Hooks/useCurrentTenant';
import TenantsList from './TenantsList';
import FetchTimeLicenses from '../TimeLicense/FetchTimeLicenses';
import Events from '../Constants/Events';

const ChangeTenant = () => {
	const { translate } = useLocale();
	const { getCurrentTenant } = useCurrentTenant();
	const groups = AuthService.getGroups();
	const currentTenant = getCurrentTenant();

	const onSelect = (t) => EventBus.dispatch(Events.TenantChanged, t);

	return (
		<FetchTimeLicenses>
			{({ timeLicenses }) => {
				return (
					<Stack>
						<Typography variant='h5'>{translate('TenantsTitle')}</Typography>
						<Typography variant='caption' color='textSecondary'>
							{translate('TenantsSubtitle')}
						</Typography>
						<TenantsList
							tenants={groups}
							timeLicenses={timeLicenses}
							onSelect={onSelect}
							selected={currentTenant}
						/>
					</Stack>
				);
			}}
		</FetchTimeLicenses>
	);
};

export default ChangeTenant;
