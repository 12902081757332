import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	selectDatasForm,
	selectConfigurationData,
	selectCurrentDataSet,
	selectCurrentData,
	selectProfileData,
	setSectionsValidationStatus,
	selectDatasFormRegisters,
	selectRegisterSelectionMode,
	selectInitialDatasFormRegisters
} from './deviceConfiguratorSlice';

const SectionStatusValidation = () => {
	const dispatch = useDispatch();

	const currentData = useSelector(selectCurrentData);
	const { datasForm } = useSelector(selectDatasForm);
	const currentDataSet = useSelector(selectCurrentDataSet);
	const profileDatas = useSelector(selectProfileData);
	const configurationDatas = useSelector(selectConfigurationData);
	const datasFormRegisters = useSelector(selectDatasFormRegisters);
	const initialDatasFormRegisters = useSelector(selectInitialDatasFormRegisters);
	const isInRegisterSelectionMode = useSelector(selectRegisterSelectionMode);

	const calculateSectionsStatus = (sections) => {
		const currentDataItems = currentData?.deviceConfiguration?.deviceConfigurationItems;
		let defaultStatus = { isAddNew: false, isModifed: false, isNotCompleted: false };
		let sectionsStatus = {};
		let previousDatas = [];

		if (!currentDataItems || currentDataItems.length == 0) {
			for (const section of sections) {
				sectionsStatus[section.name] = { ...defaultStatus };
			}

			return sectionsStatus;
		}

		if (currentDataSet == 'profile') previousDatas = profileDatas?.deviceConfiguration?.deviceConfigurationItems;

		if (currentDataSet == 'configuration')
			previousDatas = configurationDatas?.deviceConfiguration?.deviceConfigurationItems;

		if (currentDataSet == 'manual') {
			defaultStatus.isAddNew = true;

			previousDatas = [];

			for (const section of sections) {
				for (const t of section.tabs) {
					for (const g of t.groups) {
						for (const e of g.elements) {
							previousDatas.push({
								key: e.key,
								value: e.defaultValue
							});
						}
					}
				}
			}
		}

		const currentDataValues = keyValueArrayToObj(currentDataItems);
		const previousDataValues = keyValueArrayToObj(previousDatas);

		for (const section of sections) {
			let sectionStatus = { ...defaultStatus };

			for (const t of section.tabs) {
				for (const g of t.groups) {
					for (const e of g.elements) {
						if (e.display === false) continue;

						const key = e.key;
						const currentValue = currentDataValues[key];
						const valueIsEmpty = currentValue === '' || currentValue == null;

						if (e.isRequired && valueIsEmpty) sectionStatus.isNotCompleted = true;
						else if (previousDataValues) {
							if (isInRegisterSelectionMode) {
								const previousInclude = initialDatasFormRegisters.find(
									(pdfr) => pdfr.key === key
								)?.includeInConfiguration;

								const nextInclude = datasFormRegisters.find(
									(dfr) => dfr.key === key
								)?.includeInConfiguration;

								if (previousInclude !== nextInclude) {
									sectionStatus.isModifed = true;
								}
							}

							const previousValue = previousDataValues[key];
							if (previousValue != currentValue) sectionStatus.isModifed = true;
						}
					}
				}
			}

			sectionsStatus[section.name] = sectionStatus;
		}

		return sectionsStatus;
	};

	useEffect(() => {
		const sections = datasForm?.sections;
		if (!sections) return;

		dispatch(setSectionsValidationStatus(calculateSectionsStatus(sections)));
	}, [datasForm, currentData, currentDataSet, profileDatas, configurationDatas]);

	return null;
};

export default SectionStatusValidation;

function keyValueArrayToObj(array) {
	return array.reduce((acc, curr) => {
		acc[curr.key] = curr.value;
		return acc;
	}, {});
}
