import { createSelector, createSlice } from '@reduxjs/toolkit';
import { guidEmpty } from '@sonar-web/common';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import { fetchLocationNode, addLocationNode, deleteLocationNode, editLocationNode } from './api';
import { setResetAndReload } from '../LocationNodes/slice';

const slice = 'locationNode';

const initialData = {
	name: '',
	hasZipCode: false,
	zipCode: '',
	parentLocationNodeId: guidEmpty,
	locationNodeLevelId: '',
	level: {},
	currentSet: null
};
export const locationNodeSlice = createSlice({
	name: slice,
	initialState: {
		fetchPending: false,
		fetchSuccess: false,
		locationNode: initialData,
		addPending: false,
		addSuccess: false,
		deletePending: false,
		deleteSuccess: false,
		editPending: false,
		editSuccess: false,
		showForm: false,
		currentLevel: null,
		hierarchy: null,
		note: null
	},
	reducers: {
		fetchLocationNodeSucces: (state, action) => {
			const { response, level } = action.payload;

			if (response.zipCode === null) response.zipCode = '';
			state.locationNode = { ...response, level };
			state.fetchPending = false;
			state.fetchSuccess = true;
		},
		fetchLocationNodePending: (state, action) => {
			state.fetchPending = action.payload ?? true;
			state.fetchSuccess = false;
		},
		addLocationNodePending: (state, action) => {
			state.addPending = action.payload ?? true;
		},
		addLocationNodeSuccess: (state, action) => {
			state.addPending = false;
			state.locationNode = initialData;
			state.addSuccess = action.payload ?? true;
		},
		deleteLocationNodePending: (state, action) => {
			state.deletePending = action.payload ?? true;
		},
		deleteLocationNodeSuccess: (state, action) => {
			state.deleteSuccess = action.payload ?? true;
			state.deletePending = false;
		},
		editLocationNodePending: (state, action) => {
			state.editPending = action.payload ?? true;
		},
		editLocationNodeSuccess: (state, action) => {
			state.editSuccess = action.payload ?? true;
			state.editPending = false;
		},
		setLocationNodeData: (state, action) => {
			state.locationNode = action.payload;
		},
		resetLocationNodeData: (state) => {
			state.locationNode = initialData;
			state.addSuccess = false;
			state.editSuccess = false;
			state.hierarchy = null;
		},
		resetAddSuccess: (state) => {
			state.addSuccess = false;
		},
		updateLocationData: (state, { payload }) => {
			state.locationNode = { ...state.locationNode, ...payload };
		},
		setLocationHierarchy: (state, { payload }) => {
			state.hierarchy = payload;
		},
		setLocationCurrentSet: (state, { payload }) => {
			state.locationNode.currentSet = payload;
		},
		setLocationNote: (state, { payload }) => {
			state.note = payload;
		}
	}
});

export const {
	fetchLocationNodeSucces,
	fetchLocationNodePending,
	addLocationNodePending,
	addLocationNodeSuccess,
	deleteLocationNodePending,
	deleteLocationNodeSuccess,
	editLocationNodePending,
	editLocationNodeSuccess,
	setLocationNodeData,
	resetLocationNodeData,
	resetAddSuccess,
	updateLocationData,
	setLocationHierarchy,
	setLocationCurrentSet,
	setLocationNote
} = locationNodeSlice.actions;

export const fetchLocationNodeAsync = (id) => async (dispatch, getState) => {
	let response;

	try {
		dispatch(fetchLocationNodePending());
		response = await fetchLocationNode(id);
	} catch (error) {
		dispatch(addErrorAsync({ slice, error }));
		dispatch(fetchLocationNodePending(false));
		return;
	}

	const levels = getState().locationNodeLevels.locationNodeLevels;
	const level = levels.filter((l) => l.id === response.locationNodeLevelId)[0];

	dispatch(fetchLocationNodeSucces({ response, level }));
	return { ...response, level };
};

export const addLocationNodeAsync = (submitMany) => async (dispatch, getState) => {
	const nodeData = { ...getState().locationNode.locationNode };

	if (!nodeData.hasZipCode) delete nodeData.zipCode;
	let response;
	try {
		dispatch(addLocationNodePending());
		response = await addLocationNode(nodeData);
	} catch (error) {
		dispatch(addLocationNodePending(false));
		return dispatch(addErrorAsync({ slice, error, skipNotificationMembers: ['name', 'zipCode'] }));
	}

	dispatch(addLocationNodeSuccess(response));

	if (!submitMany) {
		setTimeout(() => {
			dispatch(setResetAndReload());
		}, 300);
	}
};

export const addLocationNodeInMoveAsync = (submitMany, onSuccess) => async (dispatch, getState) => {
	const nodeData = { ...getState().locationNode.locationNode };

	if (!nodeData.hasZipCode) delete nodeData.zipCode;
	let response;
	try {
		dispatch(addLocationNodePending());
		response = await addLocationNode(nodeData);
	} catch (error) {
		dispatch(addLocationNodePending(false));
		return dispatch(addErrorAsync({ slice, error }));
	}

	dispatch(addLocationNodeSuccess(response));

	if (!submitMany) {
		setTimeout(() => {
			if (onSuccess) onSuccess();
		}, 300);
	}
};

export const editLocationNodeAsync = () => async (dispatch, getState) => {
	const nodeData = { ...getState().locationNode.locationNode };

	try {
		dispatch(editLocationNodePending());
		await editLocationNode(nodeData);
	} catch (error) {
		dispatch(editLocationNodePending(false));
		return dispatch(addErrorAsync({ slice, error, skipNotificationMembers: ['name', 'zipCode'] }));
	}

	dispatch(editLocationNodeSuccess());
};

export const deleteLocationNodeAsync = (id) => async (dispatch) => {
	try {
		dispatch(deleteLocationNodePending());
		await deleteLocationNode(id);

		dispatch(deleteLocationNodeSuccess());
		dispatch(setResetAndReload());

		setTimeout(() => dispatch(deleteLocationNodeSuccess(false)), 500);
	} catch (error) {
		dispatch(deleteLocationNodePending(false));
		return dispatch(addErrorAsync({ slice, error }));
	}
};

export const selectLocationNode = createSelector(
	(state) => state.locationNode,
	(locationNode) => ({
		dataRow: locationNode.locationNode,
		fetchPending: locationNode.fetchPending,
		fetchSuccess: locationNode.fetchSuccess
	})
);

export const selectAddLocationNode = createSelector(
	(state) => state.locationNode,
	(locationNode) => ({
		addPending: locationNode.addPending,
		addSuccess: locationNode.addSuccess
	})
);

export const selectDeleteLocationNodePending = createSelector(
	(state) => state.locationNode,
	(locationNode) => ({
		deletePending: locationNode.deletePending,
		deleteSuccess: locationNode.deleteSuccess
	})
);

export const selectEditLocationNode = createSelector(
	(state) => state.locationNode,
	(locationNode) => ({
		editPending: locationNode.editPending,
		editSuccess: locationNode.editSuccess
	})
);

export const selectLocationHierarchy = (state) => state.locationNode.hierarchy;

export const selectLocationNote = (state) => state.locationNode.note;

export default locationNodeSlice.reducer;
