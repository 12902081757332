import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import {
	PanelListItem,
	PanelListItemText,
	PanelListItemDelete,
	PanelListItemActions,
	PanelListItemActionDashboard,
	PanelListItemActionEdit,
	PanelListItemActionDelete,
	useSidebar,
	PanelListItemAction,
	checkListItemIsSelectable
} from '@sonar-web/common';
import { addBreadcrumb, selectSelectedBreadcrumb, setSelected } from './slice';
import LocationNodeLevelAvatar from '../LocationNodeLevels/LocationNodeLevelAvatar';
import LocationNodeLevelName from '../LocationNodeLevels/LocationNodeLevelName';
import { deleteLocationNodeAsync, selectDeleteLocationNodePending } from '../LocationNode/slice';
import { ERROR_TYPES } from '@sonar-web/common/src/constants/errorTypes';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import useLocationNodeLevels from '../LocationNodeLevels/useLocationNodeLevels';
import { sidebars } from '../constants';
import { locationNodesSidebarComponents } from './locationNodesSidebar';

const useStyles = makeStyles((theme) => ({
	emphasizeText: { fontWeight: theme.typography.fontWeightMedium }
}));

const sidebarType = sidebars.locationNodes;
const LocationNodesListItem = ({ row, selected, additionMode, additionModeOnSelect }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { loadSidebar, getComponentInfo, unloadSidebar, isSidebarActive } = useSidebar();
	const { canHaveChildren, isTopNodeLevel } = useLocationNodeLevels();
	const { deletePending } = useSelector(selectDeleteLocationNodePending);
	const currentBreadcrumb = useSelector(selectSelectedBreadcrumb);

	const isSelected = selected && selected.id === row.id;
	const sidebarActive = isSidebarActive(sidebarType);
	const canGoDeeper = canHaveChildren(row.locationNodeLevelId);
	const isNodeTopLevel = isTopNodeLevel(row.locationNodeLevelId);

	const selectMode = !additionMode || (additionMode && additionModeOnSelect);

	const handleSelect = (event, record) => {
		if (checkListItemIsSelectable(event.target) === false) return;
		const newSelected = selected === null ? record : selected.id === record.id ? null : record;

		dispatch(setSelected(newSelected));

		if (additionMode) {
			if (additionModeOnSelect) additionModeOnSelect(newSelected);
			return;
		}

		if (newSelected === null) {
			unloadSidebar(sidebarType);
			return;
		}
	};

	const handleDashboard = () => `/locationnodes/${row.id}`;

	const handleEdit = () => {
		dispatch(setSelected(row));
		loadSidebar(
			sidebarType,
			getComponentInfo(locationNodesSidebarComponents.NodeEdit, {
				id: row.id,
				onClose: () => {
					unloadSidebar(sidebarType);
					dispatch(setSelected(null));
				}
			})
		);
	};

	const handleMove = () => {
		loadSidebar(
			sidebarType,
			getComponentInfo(locationNodesSidebarComponents.NodeMove, {
				node: row,
				onClose: () => {
					unloadSidebar(sidebarType);
					dispatch(setSelected(null));
				}
			})
		);
	};

	const handleDeleteSubmit = (setSubmitError) => {
		setSubmitError(null);
		dispatch(deleteLocationNodeAsync(row.id)).then((err) => {
			if (err && err.type === ERROR_TYPES.api) {
				setSubmitError(err.message);
			} else {
				if (selected) {
					unloadSidebar(sidebarType);
					dispatch(setSelected(null));
				}
			}
		});
	};

	const handleGoDeeper = () => {
		if (row.id === currentBreadcrumb.id) return;
		if (canGoDeeper) dispatch(addBreadcrumb(row));
	};

	return (
		<PanelListItem
			row={row}
			onClick={selectMode ? handleSelect : canGoDeeper ? handleGoDeeper : null}
			selected={selectMode && isSelected}
			renderFrontItem={(show, handleInlineDelete) => (
				<>
					<LocationNodeLevelAvatar level={row.level} />
					<PanelListItemText
						primaryText={row.name}
						primaryTextAction={canGoDeeper ? handleGoDeeper : null}
						primaryUnderlineDiabled={!selectMode}
						secondaryText={<LocationNodeLevelName locationNodeLevelId={row.locationNodeLevelId} />}
						secondaryTypographyProps={{ 'data-at-params': `LocationNodeLevelType|${row.level.type}` }}
						disableFlex={!sidebarActive}
					/>
					{!additionMode && (
						<PanelListItemActions show={show} shrink={sidebarActive} selected={selected}>
							<PanelListItemActionDashboard onClick={handleDashboard} />
							<PanelListItemActionEdit onClick={handleEdit} />
							{!isNodeTopLevel && (
								<PanelListItemAction
									icon={<SwapHorizIcon fontSize='small' />}
									tooltip={'LocationNodes_Move'}
									onClick={handleMove}
								/>
							)}
							{!row.hasChildren && row.canDelete && (
								<PanelListItemActionDelete onClick={handleInlineDelete} />
							)}
						</PanelListItemActions>
					)}
				</>
			)}
			renderDeleteItem={(inlineDeleteAction, setInlineDeleteAction) => (
				<PanelListItemDelete
					show={inlineDeleteAction}
					setShow={setInlineDeleteAction}
					primaryText={
						<>
							<LocationNodeLevelName locationNodeLevelId={row.locationNodeLevelId} />{' '}
							<span className={classes.emphasizeText}>{row.name}</span>
						</>
					}
					secondaryText='LocationNodes_LocationNodeDeleteThisQ'
					submitAction={handleDeleteSubmit}
					submitPending={deletePending}
				/>
			)}
		/>
	);
};

export default LocationNodesListItem;
