import React from 'react';
import clsx from 'clsx';
import { Checkbox } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
	checkboxRoot: {
		padding: '4px 4px 4px 9px',
		color: `${theme.palette.divider} !important`,
		'&:hover': {
			backgroundColor: 'transparent !important'
		},
		'& svg': {
			fontSize: '1.2rem'
		}
	},
	checkboxRootChecked: {
		color: `${theme.palette.primary.main} !important`
	}
}));

const BaseCheckbox = ({ name, checked, setChecked, disabled = false }) => {
	const classes = useStyles();

	return (
		<Checkbox
			disableRipple
			disableFocusRipple
			disableTouchRipple
			name={name}
			disabled={disabled}
			checked={checked}
			onChange={setChecked}
			value={name}
			classes={{
				root: clsx(classes.checkboxRoot, {
					[classes.checkboxRootChecked]: checked
				})
			}}
		/>
	);
};

export default BaseCheckbox;
