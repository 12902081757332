import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Sidebar } from '../..';
import PanelListMask from '../PanelList/PanelListMask';
import { SidebarContextProvider } from '../../features/Sidebar/sidebarContext';
import { PanelContentContextProvider, usePanelContentContext } from './panelContentContext';
import PanelContentCover from './PanelContentCover';

const useStyles = makeStyles((theme) => ({
	mainContentWrapper: {
		minWidth: 400,
		[theme.breakpoints.down('md')]: {
			minWidth: 'auto'
		}
	}
}));

const PanelContentWrapper = ({
	mainContent,
	sidebarType,
	sidebarComponents,
	isSidebar,
	maskText,
	panelName,
	children,
	standalone = false
}) => {
	const classes = useStyles();

	return (
		<Box display='flex' position='relative' height='100%' width='100%'>
			<Box
				id={`PanelContentWrapper_${panelName}`}
				display={standalone ? 'none' : 'flex'}
				flex='1'
				flexDirection='column'
				position='relative'
				className={classes.mainContentWrapper}
				data-at={isSidebar ? 'MainContentWrapperSidebar' : 'MainContentWrapper'}>
				<PanelListMask panelName={panelName} text={maskText ?? 'Common_ViewBlockedWhileActionsActive'} />
				<PanelContentContextProvider>
					<Content mainContent={mainContent} />
				</PanelContentContextProvider>
			</Box>
			{sidebarType && (
				<SidebarContextProvider>
					<Sidebar type={sidebarType} content={sidebarComponents} standalone={standalone} />
				</SidebarContextProvider>
			)}
			{children}
		</Box>
	);
};

export default PanelContentWrapper;

const Content = ({ mainContent }) => {
	const { panelCover } = usePanelContentContext();

	return (
		<>
			<Box display={panelCover ? 'none' : 'flex'} flex={1} flexDirection='column'>
				{mainContent}
			</Box>
			<PanelContentCover />
		</>
	);
};
