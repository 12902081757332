import React from 'react';
import clsx from 'clsx';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => {
	return {
		root: {
			position: 'sticky',
			left: 0,
			top: 0,
			backgroundColor: 'white',
			marginBottom: '16px'
		},
		border: {
			width: '100%',
			height: '100%',
			minHeight: '100%',
			margin: '10px 0',
			position: 'relative',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center'
		},
		textRoot: {
			position: 'absolute',
			background: theme.palette.common.white,
			padding: '0px 3px',
			margin: 0,
			maxWidth: 'calc(100% - 10px)',
			display: 'flex',
			flexDirection: 'row',
			//flexShrink: 2,
			flexWrap: 'wrap'
		}
	};
});
const TimelineBottomIndicator = ({ className, children, ...rest }) => {
	const classes = useStyles();
	return (
		<Box className={clsx(classes.root, className)} display='flex' {...rest}>
			<Box display='flex' flexDirection='column' flex={1}>
				<Box className={classes.border}>
					<Box variant='caption' color='textSecondary' classes={{ root: classes.textRoot }} flexWrap='wrap'>
						{children}
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default TimelineBottomIndicator;
