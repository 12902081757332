import store from '../../../sonar-web-core/src/store';

const ReduxHelpers = {
	getDirectlyFormStore,
	getState
};

export default ReduxHelpers;

function getDirectlyFormStore(path) {
	let storeData = store.getState();
	const pathArray = path.split('.');

	pathArray.forEach((pa) => {
		storeData = storeData[pa];
	});

	return storeData;
}

function getState() {
	return store.getState;
}
