import { useSelector } from 'react-redux';
import DOMPurify from 'dompurify';
import { selectLocaleData } from '../features/Locales/localesSlice';

export default () => {
	const data = useSelector(selectLocaleData);

	const translate = (key, ...params) => {
		if (!key) return '';

		const translationKeyExists = data.hasOwnProperty(key);
		if (!translationKeyExists) return key;
		if (!params?.length) return data[key];

		return replaceParameters(data[key], params);
	};

	const makeBoldParameters = (value, ...params) => {
		let result = value;

		for (const [, p] of params.entries()) {
			result = result.replace(p, `<strong>${p}</strong>`);
		}

		return DOMPurify.sanitize(result);
	};

	return { translate, makeBoldParameters };
};

// Replace each placeholder in the string with its corresponding value from the parameters array
function replaceParameters(string, parameters) {
	const regex = /{(\d+)}/g;

	return string.replace(regex, (match, index) => {
		index = parseInt(index);

		if (index >= 0 && index < parameters.length) {
			return parameters[index];
		}
		return match;
	});
}
