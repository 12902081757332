export const DateRangeOptions = {
	Days1: {
		name: 'Days1',
		value: 1,
		label: 'Common_LastDays1',
		description: 'LastDays1Description'
	},
	Days7: {
		name: 'Days7',
		value: 7,
		label: 'Common_LastDays7',
		description: 'LastDays7Description'
	},
	Days14: {
		name: 'Days14',
		value: 14,
		label: 'Common_LastDays14',
		description: 'LastDays14Description'
	},
	Days30: {
		name: 'Days30',
		value: 30,
		label: 'Common_LastDays30',
		description: 'LastDays30Description'
	},
	Own: {
		name: 'Own',
		value: 0,
		label: 'Common_OwnDateRange',
		description: 'OwnDateRangeDescription'
	}
};
