import { useEffect } from 'react';
import EventBus from '../../helpers/EventBus';

const useListSorting = ({ slice, callback }) => {
	useEffect(() => {
		const onSortingChanged = ({ detail }) => {
			if (detail.slice === slice) callback();
		};

		EventBus.on(EventBus.messages.SortingChanged, onSortingChanged);

		return () => EventBus.off(EventBus.messages.SortingChanged, onSortingChanged);
	}, [callback]);
};

export default useListSorting;
