import React from 'react';
import PanelListItemAction from './PanelListItemAction';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';

const PanelListItemActionRemove = ({ onClick, tooltip, ...rest }) => {
	return (
		<PanelListItemAction
			icon={<RemoveCircleOutlineOutlinedIcon fontSize='small' />}
			tooltip={tooltip ?? 'Common_Remove'}
			onClick={onClick}
			{...rest}
		/>
	);
};

export default PanelListItemActionRemove;
