import clsx from 'clsx';
import React from 'react';
import { Box, Collapse, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '../../../../../hooks';

const useStyles = makeStyles((theme) => ({
	counter: {
		marginTop: theme.spacing(4),
		'& span': {
			color: theme.palette.text.secondary
		}
	},
	indicator: {
		width: 18,
		height: 4,
		marginRight: 2,
		borderRadius: 2,
		backgroundColor: theme.palette.divider
	},
	indicatorFilled: {
		backgroundColor: theme.palette.primary.main
	}
}));

const SelectedCounter = ({ current, max }) => {
	const classes = useStyles();
	const { translate } = useLocale();
	const maxReached = current === max;
	const selectedIndicators = Array.from(Array(max));

	return (
		<div>
			<Typography variant='body2' className={classes.counter}>
				{translate('Common_SelectedValues')} <span>{`(${current}/${max})`}</span>
			</Typography>
			<Box display='flex' py={1}>
				{selectedIndicators.map((_, index) => (
					<div
						key={index}
						className={clsx(classes.indicator, { [classes.indicatorFilled]: index < current })}
					/>
				))}
			</Box>
			<Collapse in={maxReached}>
				<Typography variant='caption' color='primary' style={{ visibility: maxReached ? 'visible' : 'hidden' }}>
					{translate('MaxSelectedValuesReached')}
				</Typography>
			</Collapse>
		</div>
	);
};

export default SelectedCounter;
