import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { TextField } from '@mui/material';
import { useLocale } from '@sonar-web/common/src/hooks';
import useConfigurator from './useConfigurator';
import { useIsMount } from '../../../hooks';

const validationSchema = Yup.object().shape({
	name: Yup.string()
		.min(3, 'Common_NameValidationMin')
		.max(50, 'Common_NameValidationMax')
		.required('Common_ValidationRequired')
		.matches(/^[^\s].+[^\s]$/, 'Common_NameValidationWhitespaces')
});

const DeviceConfigurationName = ({ disabled, setDisabled }) => {
	const { translate } = useLocale();
	const isMount = useIsMount();
	const { updateConfigurationName, getCurrentData } = useConfigurator();
	const { name } = getCurrentData();

	const { values, handleChange, handleBlur, errors, touched } = useFormik({
		validationSchema,
		initialValues: {
			name: name || ''
		}
	});

	const onBlur = (e) => {
		if (!errors.name) updateConfigurationName(values.name);
		handleBlur(e);
	};

	useEffect(() => {
		if (isMount && !values.name) return;
		if (typeof disabled === 'boolean') setDisabled(errors.name != null);
		else setDisabled((prev) => ({ ...prev, name: errors.name != null }));
	}, [errors.name]);

	return (
		<form
			style={{ marginTop: 10 }}
			onSubmit={(e) => {
				e.preventDefault();
			}}>
			<TextField
				autoFocus
				margin='normal'
				name='name'
				label={translate('Common_ConfigurationName')}
				type='text'
				size='small'
				style={{ width: 300 }}
				value={values.name}
				onChange={handleChange}
				onBlur={onBlur}
				error={errors.name && touched.name}
				helperText={errors.name && touched.name && translate(errors.name)}
			/>
		</form>
	);
};

export default DeviceConfigurationName;
