import { createSlice } from '@reduxjs/toolkit';

const slice = 'dashboard';
const initialState = {
	mapLoaded: false,
	controls: {
		dashboard: { state: true },
		filters: { state: false },
		manage: { state: false }
	},
	dashboards: {}
};

export const dashboardSlice = createSlice({
	name: slice,
	initialState,
	reducers: {
		resetDashboard: (state) => {
			state.mapLoaded = false;
			state.controls = initialState.controls;
			delete state.dashboards.main;
		},
		toggleControl: (state, { payload }) => {
			const { type, data } = payload;
			const { hideRest = true } = data;
			const controls = { ...state.controls };

			if (!data.state) {
				state.controls[type] = { state: false };
				return;
			}

			if (hideRest) {
				for (let c in controls) {
					controls[c] = { state: false };
				}
			}

			controls[type] = data;

			state.controls = controls;
		},
		setMapLoaded: (state, { payload }) => {
			state.mapLoaded = payload;
		}
	}
});

export const { toggleControl, setMapLoaded, resetDashboard } = dashboardSlice.actions;

//Selectors
export const selectMapLoaded = (state) => state.dashboard.mapLoaded;

export const selectControls = (state) => state.dashboard.controls;

export default dashboardSlice.reducer;
