import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import BaseButton from './BaseButton';
import ButtonLinkAlt from './ButtonLinkAlt';

const ButtonAdd = React.forwardRef(function ButtonAdd(props, ref) {
	const { variant, ...rest } = props;

	if (variant === 'outlined') {
		return (
			<BaseButton ref={ref} startIcon={<AddCircleOutlineIcon />} variant={variant} {...rest}>
				{props.children ?? 'Common_Add'}
			</BaseButton>
		);
	}

	if (variant === 'link') {
		return (
			<ButtonLinkAlt ref={ref} startIcon={<AddCircleOutlineIcon />} {...rest}>
				{props.children ?? 'Common_Add'}
			</ButtonLinkAlt>
		);
	}

	return (
		<BaseButton ref={ref} startIcon={<AddIcon />} variant='outlined' {...rest}>
			{props.children ?? 'Common_Add'}
		</BaseButton>
	);
});

export default ButtonAdd;
