import { Features } from '@sonar/auth';
import { lazy } from 'react';

const ExportsPage = lazy(() => import('./ExportsPage'));
const ExportConfiguratorAdd = lazy(() => import('./ExportConfigurator/ExportConfiguratorAdd'));
const ExportConfiguratorEdit = lazy(() => import('./ExportConfigurator/ExportConfiguratorEdit'));

export default [
	{
		element: ExportsPage,
		feature: Features.ExportsPage.name,
		path: 'exports/templates/:type',
		exact: true,
		name: 'Exports_Templates'
	},
	{
		element: ExportsPage,
		feature: Features.ExportsPage.name,
		path: 'exports/done/:type',
		exact: true,
		name: 'Exports_Generated'
	},
	{
		element: ExportConfiguratorAdd,
		feature: Features.ExportConfigurator.name,
		path: 'exports/:type/new',
		exact: true,
		name: 'Exports_NewExport'
	},
	{
		element: ExportConfiguratorEdit,
		feature: Features.ExportConfigurator.name,
		path: 'exports/:type/:templateId',
		exact: true,
		name: 'Exports_EditExport'
	}
];
