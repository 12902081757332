export default {
	Initial: 'Initial',
	CheckingInputFiles: 'CheckingInputFiles',
	InputFilesError: 'InputFilesError',
	InputFilesSuccess: 'InputFilesSuccess',
	UpdatingInputFiles: 'UpdatingInputFiles',
	CollectiveBillingInProgress: 'CollectiveBillingInProgress',
	CollectiveBillingSuccess: 'CollectiveBillingSuccess',
	IndividualBillingInProgress: 'IndividualBillingInProgress',
	IndividualBillingSuccess: 'IndividualBillingSuccess'
};
