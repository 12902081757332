import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { useLocale } from '../../hooks';

const Wrapper = styled('div')(({ theme }) => ({
	position: 'absolute',
	height: '100%',
	width: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	background: 'rgb(220 220 220 / 50%)',
	cursor: 'default',
	'& p': {
		marginRight: '1rem'
	}
}));

const TileButtonErrorMessage = ({ error }) => {
	const { translate } = useLocale();

	return (
		<Wrapper onClick={(e) => e.stopPropagation()}>
			<Typography variant='body2' color='error'>
				{translate(error)}
			</Typography>
		</Wrapper>
	);
};

export default TileButtonErrorMessage;
