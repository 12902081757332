export default {
	Optical: {
		name: 'Optical',
		value: 1
	},
	IMP: {
		name: 'IMP',
		value: 2
	},
	ImpMagnetic: {
		name: 'ImpMagnetic',
		value: 3
	},
	RS232: {
		name: 'RS232',
		value: 4
	},
	HeatAllocating: {
		name: 'HeatAllocating',
		value: 5
	},
	GasMeasurement: {
		name: 'GasMeasurement',
		value: 6
	},
	WMBUS: {
		name: 'WMBUS',
		value: 7
	},
	ColdWaterMeasurement: {
		name: 'ColdWaterMeasurement',
		value: 8
	},
	HeatMeasurement: {
		name: 'HeatMeasurement',
		value: 9
	},
	EletricalEnergyMeasurement: {
		name: 'EletricalEnergyMeasurement',
		value: 10
	},
	WMBUS169MHz: {
		name: 'WMBUS169MHz',
		value: 11
	},
	TempeatureMeasurement: {
		name: 'TempeatureMeasurement',
		value: 12
	},
	PreasureMeasurement: {
		name: 'PreasureMeasurement',
		value: 13
	},
	UART: {
		name: 'UART',
		value: 14
	},
	WarmWaterMeasurement: {
		name: 'WarmWaterMeasurement',
		value: 15
	},
	DigitalSerialPort: {
		name: 'DigitalSerialPort',
		value: 16
	},
	ColdMeasurement: {
		name: 'ColdMeasurement',
		value: 17
	},
	HeatAndColdMeasurement: {
		name: 'HeatAndColdMeasurement',
		value: 18
	},
	AllHeatmeters: {
		name: 'AllHeatmeters',
		value: 19
	},
	Everything: {
		name: 'Everything',
		value: 99
	}
};
