import { Box } from '@mui/material';
import useConfiguratorControls from './Controls/useConfiguratorControls';
import ContentDescription from './ContentDescription';
import ContentTitle from './ContentTitle';
import useConfigurator from './useConfigurator';

const ConfiguratorContent = () => {
	const { getComponent } = useConfiguratorControls();
	const { updateConfiguratorData, configuratorData, steps, activeStep } = useConfigurator();

	return steps.map((step, index) => {
		const show = index === activeStep;

		return (
			<Box
				key={step.stepName}
				height='100%'
				flexDirection='column'
				display={show ? 'flex' : 'none'}
				minHeight={0}
				data-at={show ? 'currentConfiguratorContent' : 'configuratorContent'}>
				<ContentTitle text={step.contentTitle} />
				<ContentDescription text={step.contentDescription} />
				{step.controls.map((control) => {
					const { name, props, ...rest } = control;
					const Component = getComponent(name);

					return (
						<Component
							key={control.name}
							stepIndex={index}
							useConfigurator={useConfigurator}
							configuratorData={configuratorData.data}
							updateConfiguratorData={updateConfiguratorData}
							props={props}
							{...rest}
						/>
					);
				})}
			</Box>
		);
	});
};

export default ConfiguratorContent;
