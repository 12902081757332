export const sidebars = {
	locationNode: 'locationNode',
	locationNodes: 'locationNodes',
	locationNodeDashboard: 'locationNodeDashboard'
};

export const readType = {
	instant: { value: 1, name: 'Instant' },
	logged: { value: 2, name: 'Logged' }
};

export const readSource = {
	tenant: { value: 1, name: 'Tenant' },
	inkasent: { value: 2, name: 'Inkasent' }
};

export const nodelevelTypeLocalName = 'Local';
