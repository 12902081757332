import { apiBase } from '@sonar-web/common';
import { REST_API_PATHS } from '@sonar-web/common/src/constants/system';
import ApiHelpers from '@sonar-web/common/src/helpers/ApiHelpers';
import { fillMissingDates } from '@sonar-web/location/src/LocationNodeCharts/Utils/ChartSettings';

const path = REST_API_PATHS.HEATMETER;

async function fetchChartReads({ pageDescriptor, from, to }) {
	const params = ApiHelpers.urlParamsFromPageDescriptor(pageDescriptor, ['heatMeterNumber']);
	const { data } = await apiBase(`reads?${params}`, 'get', {
		path
	});

	return { elements: fillMissingDates(data.elements, from, to) };
}

async function fetchChartConsumptions(params) {
	const { data } = await apiBase(`consumptions`, 'get', {
		path,
		params
	});

	return data;
}

const HeatMeterService = {
	fetchChartReads,
	fetchChartConsumptions
};

export default HeatMeterService;
