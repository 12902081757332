/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { Virtuoso } from 'react-virtuoso';
import {
	BaseLinearProgress,
	ListNoRecords,
	BaseCircularProgress,
	checkListItemIsSelectable,
	ListActionToolbar,
	listScrollToElement,
	PanelList,
	guidEmpty
} from '@sonar-web/common';
import { usePrevious } from '@sonar-web/common/src/hooks';
import { addSorters } from '@sonar-web/common';
import TerminalDiagnosticReadsListItem from './TerminalDiagnosticReadsListItem';
import {
	selectTerminalDiagnosticReads,
	setSelected,
	setResetAndReload,
	selectSelected,
	fetchTerminalDiagnosticReadsAsync,
	setOffset
} from './terminalDiagnosticReadsSlice';

const slice = 'terminalDiagnosticReads';
const sorters = {
	initial: 0,
	sorterData: [
		{
			member: 'networkReadDate',
			text: 'Stationary_ReadDate',
			direction: 'desc',
			directionText: 'Common_Latest'
		},
		{
			member: 'networkReadDate',
			text: 'Stationary_ReadDate',
			direction: 'asc',
			directionText: 'Common_Oldest'
		}
	]
};

const TerminalDiagnosticReadsList = ({ deviceId, networkId, isDashboard }) => {
	const dispatch = useDispatch();
	const { dataRows, hasMore, pending, reset, totalCount } = useSelector(selectTerminalDiagnosticReads);
	const selected = useSelector(selectSelected);
	const [isReloading, setIsReloading] = useState(true);

	const currentSorters = useSelector((state) => state.sorters[slice]);
	const previousSorters = usePrevious(currentSorters);
	const currentFilters = useSelector((state) => state.filters[slice]);
	const previousFilters = usePrevious(currentFilters);
	const previousDeviceId = usePrevious(deviceId);
	const previousNetworkId = usePrevious(networkId);

	const handleSelect = (event, record) => {
		if (checkListItemIsSelectable(event.target) === false) return;

		const newSelected = selected === null ? record : selected.id === record.id ? null : record;

		dispatch(setSelected(newSelected));
	};

	const fetchData = (append = false) => {
		if (append) {
			if (!hasMore) return;
		} else {
			listScrollToElement(0, 'WmbusReadsList');
		}

		dispatch(setOffset(append));
		setIsReloading(!append);
		dispatch(fetchTerminalDiagnosticReadsAsync({ deviceId, networkId }));
	};

	useEffect(() => {
		return () => {
			dispatch(setResetAndReload(false));
			dispatch(setSelected(null));
			dispatch(addSorters({ slice: slice, sortersData: [] }));
		};
	}, []);

	useEffect(() => {
		if (pending) return;

		if (networkId === guidEmpty || deviceId === guidEmpty || previousDeviceId == null || previousNetworkId == null)
			return;

		const noEqualSorters = !isEqual(currentSorters, previousSorters);
		const noEqualFilters = !isEqual(currentFilters, previousFilters);
		const noEqualDeviceId = !isEqual(deviceId, previousDeviceId);
		const noEqualNetworkId = !isEqual(networkId, previousNetworkId);

		if (reset || noEqualSorters || noEqualFilters || noEqualDeviceId || noEqualNetworkId) {
			{
				fetchData();
			}
		}
	}, [reset, currentSorters, currentFilters, deviceId, networkId]);

	if (!dataRows) return null;
	const hasRecords = dataRows && dataRows.length !== 0;

	const Components = React.useMemo(() => {
		return {
			List: React.forwardRef(({ style, children, className }, listRef) => {
				return (
					<PanelList
						id='TerminalDiagnosticReadsList'
						ref={listRef}
						style={style}
						disablePadding
						className={className}>
						{children}
					</PanelList>
				);
			}),
			Footer: () => <BaseCircularProgress pending={pending && !isReloading} paddingTop={3} />,
			EmptyPlaceholder: () => (
				<ListNoRecords show={!pending && !hasRecords} text='Wmbus_WmbusDeviceDiagnosticReadsListEmpty' />
			)
		};
	}, [dataRows]);

	const InnerItem = React.memo(({ row }) => {
		const isSelected = selected && selected.id === row.id;
		return (
			<TerminalDiagnosticReadsListItem
				row={row}
				selected={isSelected}
				onClick={handleSelect}
				isDashboard={isDashboard}
			/>
		);
	});

	const itemContent = (index, row) => {
		return <InnerItem row={row} />;
	};
	return (
		<>
			<ListActionToolbar slice={slice} pending={pending} sorters={sorters} totalCount={totalCount} />
			<BaseLinearProgress pending={pending && isReloading} />
			<Virtuoso
				style={{ minHeight: 225 }}
				data={dataRows}
				components={Components}
				itemContent={itemContent}
				totalCount={dataRows.length}
				endReached={() => fetchData(true)}
			/>
		</>
	);
};

export default TerminalDiagnosticReadsList;
