export const moduleName = 'access';

const slices = {
	user: 'user',
	users: 'users',
	usersSearch: 'usersSearch',
	userProfile: 'userProfile',
	rolesSearch: 'rolesSearch',
	usersSelected: 'usersSelected',
	usersAll: 'usersAll'
};

const routes = {
	users: () => `/users`,
	user: (props) => {
		if (!props) return '/user';

		const { action, id } = props;

		if (action === 'edit') return id ? `/user/${id}/edit` : `/user/:id/edit`;
		return id ? `/user/${id}` : `/user/:id`;
	},
	profile: (props) => (props ? `/profile/edit` : '/profile')
};

const keycloakRoles = ['uma_authorization', 'offline_access', 'default-roles-sonar-web'];

const Module = {
	moduleName,
	slices,
	routes,
	keycloakRoles
};

export default Module;
