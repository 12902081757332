import { createSlice, createSelector } from '@reduxjs/toolkit';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import MeshService from './MeshService';
import Module from './Constants/Module';
import { pageDescriptor } from '@sonar-web/common';
import OverlaysGroupTypes from '@sonar-web/common/src/enums/OverlaysGroupTypes';

const slice = Module.slices.meshTerminal;
const initialTerminalData = null;

const getTerminal = (getState) => getState().meshTerminal.terminal;

export const terminalSlice = createSlice({
	name: slice,
	initialState: {
		addPending: false,
		fetchPending: false,
		deletePending: false,
		deleteSuccess: false,
		terminal: initialTerminalData,
		terminalTypePending: false,
		terminalType: null,
		terminalsTypes: [],
		terminalsTypesTransmissionDevices: [],
		terminasTypesPending: false
	},
	reducers: {
		fetchTerminalSucces: (state, { payload }) => {
			state.terminal = payload;
			state.fetchPending = false;
		},
		fetchTerminalPending: (state, { payload }) => {
			state.fetchPending = payload;
		},
		addOrEditTerminalPending: (state, { payload }) => {
			state.addPending = payload;
		},
		deleteTerminalSucces: (state) => {
			state.deletePending = false;
			state.deleteSuccess = true;
		},
		deleteTerminalPending: (state, { payload }) => {
			state.deletePending = payload;
		},
		setTerminalData: (state, action) => {
			state.terminal = action.payload;
		},
		resetTerminalData: (state) => {
			state.terminal = initialTerminalData;
			state.deleteSuccess = false;
		},
		fetchTerminalTypeSuccess: (state, { payload }) => {
			state.terminalTypePending = false;
			state.terminalType = payload;
		},
		fetchTerminalTypePending: (state, { payload }) => {
			state.terminalTypePending = payload;
		},
		resetTerminalType: (state) => {
			state.terminalTypePending = false;
			state.terminalType = null;
		},
		fetchTerminalsTypesSuccess: (state, { payload }) => {
			state.terminalsTypes = payload.elements;
			state.terminalsTypesTransmissionDevices = payload.elements.filter((e) =>
				Object.keys(OverlaysGroupTypes).includes(e.typeGroup)
			);
			state.terminasTypesPending = false;
		},
		fetchTerminalsTypesPending: (state, { payload }) => {
			state.terminasTypesPending = payload;
		}
	}
});

export const {
	fetchTerminalSucces,
	fetchTerminalPending,
	addOrEditTerminalPending,
	deleteTerminalSucces,
	deleteTerminalPending,
	setTerminalData,
	resetTerminalData,
	fetchTerminalTypeSuccess,
	fetchTerminalTypePending,
	resetTerminalType,
	fetchTerminalsTypesSuccess,
	fetchTerminalsTypesPending
} = terminalSlice.actions;

export const fetchTerminalAsync = (id) => async (dispatch) => {
	try {
		dispatch(fetchTerminalPending(true));
		const response = await MeshService.fetchTerminal(id);
		dispatch(fetchTerminalSucces(response));
	} catch (error) {
		dispatch(fetchTerminalPending(false));
		return dispatch(addErrorAsync({ slice, error }));
	}
};

export const addTerminalAsync = () => async (dispatch, getState) => {
	const terminal = getTerminal(getState);

	try {
		dispatch(addOrEditTerminalPending(true));
		const response = await MeshService.addTerminal(terminal);
		dispatch(addOrEditTerminalPending(false));
		return response;
	} catch (error) {
		dispatch(addOrEditTerminalPending(false));
		return dispatch(addErrorAsync({ slice, error, skipNotificationMembers: ['number'] }));
	}
};

export const editTerminalAsync = () => async (dispatch, getState) => {
	const terminal = getTerminal(getState);

	try {
		dispatch(addOrEditTerminalPending(true));
		await MeshService.editTerminal(terminal);
		dispatch(addOrEditTerminalPending(false));
	} catch (error) {
		dispatch(addOrEditTerminalPending(false));
		return dispatch(addErrorAsync({ slice, error, skipNotificationMembers: ['number'] }));
	}
};

export const deleteTerminalAsync = (id) => async (dispatch) => {
	try {
		dispatch(deleteTerminalPending(true));
		await MeshService.deleteTerminal(id);
		dispatch(deleteTerminalSucces());
	} catch (error) {
		dispatch(deleteTerminalPending(false));
		return dispatch(addErrorAsync({ slice, error }));
	}
};

export const fetchTerminalTypeAsync = (id) => async (dispatch) => {
	try {
		dispatch(fetchTerminalTypePending(true));
		const response = await MeshService.fetchTerminalType(id);
		dispatch(fetchTerminalTypeSuccess(response));
	} catch (error) {
		dispatch(fetchTerminalTypePending(false));
		return dispatch(addErrorAsync({ slice, error }));
	}
};

export const fetchTerminalsTypesAsync = () => async (dispatch) => {
	try {
		dispatch(fetchTerminalsTypesPending(true));
		const response = await MeshService.fetchTerminalsTypes({ ...pageDescriptor, Limit: Math.pow(2, 31) - 1 });
		dispatch(fetchTerminalsTypesSuccess(response));
	} catch (error) {
		dispatch(fetchTerminalsTypesPending(false));
		return dispatch(addErrorAsync({ slice, error }));
	}
};

export const selectTerminal = createSelector(
	(state) => state.meshTerminal,
	(terminal) => ({
		terminal: terminal.terminal,
		fetchPending: terminal.fetchPending
	})
);

export const selectTerminalAdd = createSelector(
	(state) => state.meshTerminal,
	(terminal) => ({
		addPending: terminal.addPending,
		addSuccess: terminal.addSuccess
	})
);

export const selectDeleteTerminal = createSelector(
	(state) => state.meshTerminal,
	(terminal) => ({
		deletePending: terminal.deletePending,
		deleteSuccess: terminal.deleteSuccess
	})
);

export const selectTerminalType = createSelector(
	(state) => state.meshTerminal,
	(terminal) => ({
		terminalTypePending: terminal.terminalTypePending,
		terminalType: terminal.terminalType
	})
);

export const selectTerminalsTypes = createSelector(
	(state) => state.meshTerminal,
	(terminal) => ({
		terminalsTypes: terminal.terminalsTypes,
		terminasTypesPending: terminal.terminasTypesPending,
		terminalsTypesTransmissionDevices: terminal.terminalsTypesTransmissionDevices
	})
);

export default terminalSlice.reducer;
