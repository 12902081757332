import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import { deviceConfiguratorSlice } from '../deviceConfiguratorSlice';
import { apiBase } from '@sonar-web/common';

const slice = 'deviceConfigurator';

export const multiActionsInitial = {
	addPending: false,
	deletePending: false,
	deleteSuccess: false
};

export const multiActionsReducers = {
	fetchTcpStatePending: (state, action) => {
		state.multiActions.fetchPending = action.payload;
	},
	resetMultiActions: (state) => {
		state.multiActions = { ...multiActionsInitial };
	},
	addConfigurationSucces: (state) => {
		state.multiActions.addPending = false;
	},
	addConfigurationPending: (state, action) => {
		state.multiActions.addPending = action.payload;
	},
	deletePendingConfigurationsSucces: (state) => {
		state.multiActions.deletePending = false;
		state.multiActions.deleteSuccess = true;
		state.multiActions.reload = true;
	},
	deletePendingConfigurationsPending: (state, action) => {
		state.multiActions.deletePending = action.payload;
		state.multiActions.deleteSuccess = false;
	}
};

export const addConfigurationsAsync = (params) => async (dispatch) => {
	const { addConfigurationSucces, addConfigurationPending } = deviceConfiguratorSlice.actions;

	try {
		dispatch(addConfigurationPending(true));
		const response = await multiAddConfig(params);
		dispatch(addConfigurationSucces(response));
		return true;
	} catch (error) {
		dispatch(addConfigurationPending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const deletePendingConfigurationsAsync = (params) => async (dispatch) => {
	const { deletePendingConfigurationsSucces, deletePendingConfigurationsPending } = deviceConfiguratorSlice.actions;

	try {
		dispatch(deletePendingConfigurationsPending(true));
		const response = await multiDeleteConfig(params);
		dispatch(deletePendingConfigurationsSucces(response));
		return true;
	} catch (error) {
		dispatch(deletePendingConfigurationsPending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const selectDelete = (state) => {
	return {
		deletePending: state.deviceConfigurator.multiActions.deletePending,
		deleteSuccess: state.deviceConfigurator.multiActions.deleteSuccess
	};
};

export const selectAdd = (state) => {
	return {
		addPending: state.deviceConfigurator.multiActions.addPending
	};
};

/* API */
export async function multiAddConfig({ api, ...config }) {
	const { data } = await apiBase(api.multiAdd.url, 'post', {
		path: api.multiAdd.path,
		data: config
	});

	return data;
}

export async function multiDeleteConfig({ api, ...config }) {
	const { data } = await apiBase(api.multiDelete.url, 'put', {
		path: api.multiDelete.path,
		data: config
	});

	return data;
}
