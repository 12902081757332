import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import { tcpDeviceSlice } from '../tcpDeviceSlice';
import { apiBase, REST_API_PATHS } from '@sonar-web/common';

const path = REST_API_PATHS.TCP;
const slice = 'tcpDevice';

export const tcpInfoInitial = {
	fetchPending: false,
	fetchSuccess: false,
	data: null
};

export const tcpInfoReducers = {
	fetchTcpInfoSucces: (state, action) => {
		state.tcpInfo.data = action.payload;
		state.tcpInfo.fetchPending = false;
		state.tcpInfo.fetchSuccess = true;
	},
	fetchTcpInfoPending: (state, action) => {
		state.tcpInfo.fetchPending = action.payload;
	},
	resetTcpInfo: (state) => {
		state.tcpInfo = { ...tcpInfoInitial };
	}
};

export const fetchTcpInfoAsync = (id) => async (dispatch) => {
	const { fetchTcpInfoPending, fetchTcpInfoSucces, resetTcpInfo } = tcpDeviceSlice.actions;
	try {
		dispatch(fetchTcpInfoPending(true));
		const response = await fetchTcpInfo(id);
		dispatch(fetchTcpInfoSucces(response));
	} catch (error) {
		dispatch(fetchTcpInfoPending(false));
		dispatch(resetTcpInfo());
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const selectTcpInfo = (state) => {
	return {
		dataRow: state.tcpDevice.tcpInfo.data,
		fetchPending: state.tcpDevice.tcpInfo.fetchPending,
		fetchSuccess: state.tcpDevice.tcpInfo.fetchSuccess
	};
};

/* API */
async function fetchTcpInfo(id) {
	const { data } = await apiBase(`devices/${id}/info`, 'get', {
		path
	});

	return data;
}
