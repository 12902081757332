import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography, Fade } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '@sonar-web/common/src/hooks';
import ScheduleContext from '../scheduleContext';
import SelectTime from './SelectTime/SelectTime';
import { isDefaultCronObject } from '../../cronParser';

const useStyles = makeStyles((theme) => ({
	textBold: {
		fontWeight: theme.typography.fontWeightMedium
	}
}));

const EveryDayControls = (data) => {
	const classes = useStyles();
	const { translate } = useLocale();
	const { updateSchedule, setIsValid } = useContext(ScheduleContext);

	const [times, setTimes] = useState([]);

	const onTimeChange = (values) => {
		setTimes(values);
	};

	const setValues = (cronObjects) => {
		if (cronObjects.length == 0) return;

		let newTimes = [];

		for (const cornObject of cronObjects) {
			let date = new Date();
			date.setHours(cornObject.hour[0]);
			date.setMinutes(cornObject.minute[0]);
			newTimes.push(date);
		}

		setTimes(newTimes);
	};

	useEffect(() => {
		setIsValid(false);

		if (data && isDefaultCronObject(data.cronObjects)) return;

		if (data.type != 'everyDay') return;

		setValues(data.cronObjects);
	}, []);

	useEffect(() => {
		updateSchedule('everyDay', times);
		setIsValid(times.length > 0);
	}, [times]);

	return (
		<Box paddingY={2}>
			<Fade in={true} timeout={1000} appear={true}>
				<div>
					<SelectTime
						values={times}
						onChange={onTimeChange}
						maxValuesCount={5}
						title={'Common_ScheduleStartMoment2'}
					/>
				</div>
			</Fade>
			{times.length > 0 && (
				<Box mt={3}>
					<Typography variant='caption' component='span' color='textSecondary'>
						{translate('Common_ScheduleDescriptionText1')}{' '}
						{translate(
							times.length > 1 ? 'Common_ScheduleDescriptionText2' : 'Common_ScheduleDescriptionText3'
						)}
						{': '}
					</Typography>
					<Typography variant='caption' component='span' classes={{ root: classes.textBold }}>
						{(() => {
							let formattedTimes = times.map(
								(time) =>
									(time.getHours() + '').padStart(2, '0') +
									':' +
									(time.getMinutes() + '').padStart(2, '0')
							);
							formattedTimes.sort();

							return formattedTimes.join(', ');
						})()}
					</Typography>
				</Box>
			)}
		</Box>
	);
};

export default EveryDayControls;
