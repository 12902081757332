import React from 'react';
import ToolbarDivider from '../ToolbarDivider/ToolbarDivider';
import MultiSelectMenu from './MultiSelectMenu';

const MultiSelect = ({
	slice,
	sidebar,
	isSidebar,
	multipleSelect,
	multipleSelectParams,
	toggleMultipleSelectionModeEnabled,
	noData
}) => {
	if (noData || !multipleSelect) return null;
	return (
		<>
			{!isSidebar && <ToolbarDivider />}
			<MultiSelectMenu
				slice={slice}
				sidebar={sidebar}
				multipleSelect={multipleSelect}
				multipleSelectParams={multipleSelectParams}
				toggleMultipleSelectionModeEnabled={toggleMultipleSelectionModeEnabled}
			/>
			{isSidebar && <ToolbarDivider />}
		</>
	);
};

export default MultiSelect;
