import { createSlice } from '@reduxjs/toolkit';
import { pageDescriptor, getSliceFilters } from '@sonar-web/common';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import TcpService from '../../TcpService';

const slice = 'tcpDevicesSearch';

export const tcpDevicesSearchSlice = createSlice({
	name: slice,
	initialState: {
		pageDescriptor,
		fetchPending: false,
		devices: [],
		totalCount: 0,
		hasMore: true,
		reload: false,
		reset: true
	},
	reducers: {
		fetchDevicesSuccess: (state, { payload }) => {
			state.devices = state.reset ? payload.elements : state.devices.concat(payload.elements);
			state.totalCount = payload.totalCount;
			state.fetchPending = false;
		},
		fetchDevicesPending: (state) => {
			state.fetchPending = true;
		},
		setHasMore: (state) => {
			state.hasMore = state.totalCount > state.pageDescriptor.Offset + state.pageDescriptor.Limit;
		},
		setResetAndReload: (state) => {
			state.devices = [];
			state.reload = true;
			state.reset = true;
			state.hasMore = true;
			state.pageDescriptor.Offset = 0;
			state.pageDescriptor.FilterDescriptors = [];
			state.pageDescriptor.SortDescriptors = [];
		},
		setResetToFirstPage: (state) => {
			state.reload = true;
			state.reset = true;
			state.hasMore = true;
			state.pageDescriptor.Offset = 0;
		},
		setAppend: (state, action) => {
			state.reset = !action.payload;
			if (action.payload) state.pageDescriptor.Offset += state.pageDescriptor.Limit;
			else state.pageDescriptor.Offset = 0;
		},
		setReload: (state) => {
			state.reload = true;
		},
		resetDevicesData: (state, action) => {
			state.pageDescriptor.Offset = 0;
			state.pageDescriptor.FilterDescriptors = [];
			state.pageDescriptor.SortDescriptors = [];
			state.fetchPending = false;
			state.devices = [];
			state.totalCount = 0;
			state.hasMore = true;
			state.reload = false;
			state.reset = true;
		}
	}
});

export const {
	fetchDevicesSuccess,
	fetchDevicesPending,
	setHasMore,
	setResetAndReload,
	setResetToFirstPage,
	setAppend,
	setReload,
	resetDevicesData
} = tcpDevicesSearchSlice.actions;

export const fetchDevicesAsync = () => async (dispatch, getState) => {
	let response;

	try {
		dispatch(fetchDevicesPending());

		let pd = Object.assign({}, getState().tcpDevicesSearch.pageDescriptor);
		pd.FilterDescriptors = getSliceFilters(getState(), slice);
		pd.SortDescriptors = [{ Member: 'numberToSort', ListSortDirection: 1 }];

		response = await TcpService.fetchTcpDevices(pd);
	} catch (error) {
		dispatch(fetchDevicesPending(false));
		dispatch(addErrorAsync({ slice, error }));
		return;
	}

	dispatch(fetchDevicesSuccess(response));
	dispatch(setHasMore());
};

export const selectDevices = (state) => {
	return {
		dataRows: state.tcpDevicesSearch.devices,
		pending: state.tcpDevicesSearch.fetchPending,
		hasMore: state.tcpDevicesSearch.hasMore,
		reload: state.tcpDevicesSearch.reload,
		totalCount: state.tcpDevicesSearch.totalCount
	};
};

export default tcpDevicesSearchSlice.reducer;
