import React from 'react';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TileItem from '../../features/Tiles/TileItem';

const useStyles = makeStyles(() => ({
	root: {
		'& .tileItemText': {
			textTransform: 'uppercase'
		}
	}
}));

const MonthItem = ({ month, selected, onClick }) => {
	const classes = useStyles();

	return (
		<Box display='flex' justifyContent='center' flex={1} className={classes.root}>
			<TileItem
				minWidth={45}
				maxWidth={260}
				flex={null}
				p={0.5}
				text={month.name}
				selected={selected}
				onClick={() => onClick(month.value)}
			/>
		</Box>
	);
};

export default MonthItem;
