import { createSelector } from '@reduxjs/toolkit';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import { fetchBuildingVisualization } from './buildingVisualizationApi';
import { buildingVisualizationStateName } from './buildingVisualizationSlice';
import { statsLocationSlice } from '../statsLocationSlice';
import { slices } from '../../Constants/Module';

const slice = slices.statsLocation;
const { setFetchBuildingVisualizationPending, setBuildingVisualizationSuccess, resetBuildingVisualization } =
	statsLocationSlice.actions;

export const fetchBuildingVisualizationAsync = (locationId, statisticsOptions) => async (dispatch) => {
	try {
		dispatch(setFetchBuildingVisualizationPending(true));

		const { layer, dateRangeDate } = statisticsOptions;
		const response = await fetchBuildingVisualization({
			id: locationId,
			date: dateRangeDate,
			interfaceType: layer
		});

		dispatch(setBuildingVisualizationSuccess(response.staircases));
	} catch (error) {
		dispatch(resetBuildingVisualization());
		if (error?.response?.data?.reason === 'Visualization:PremisesDirectlyUnderBuilding') return;
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const selectBuildingVisualization = createSelector(
	(state) => state.statsLocation,
	(statsLocation) => ({
		pending: statsLocation[buildingVisualizationStateName].pending,
		data: statsLocation[buildingVisualizationStateName].data,
		staircases: statsLocation[buildingVisualizationStateName].staircases,
		floors: statsLocation[buildingVisualizationStateName].floors,
		allFloors: statsLocation[buildingVisualizationStateName].allFloors,
		maxLocalsPerStaircase: statsLocation[buildingVisualizationStateName].maxLocalsPerStaircase,
		maxLocals: statsLocation[buildingVisualizationStateName].maxLocals,
		allLocalsCount: statsLocation[buildingVisualizationStateName].allLocalsCount
	})
);

export const selectVisualizationType = (state) => state.statsLocation[buildingVisualizationStateName].visualizationType;
