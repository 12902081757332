import React from 'react';
import BaseIcon from './BaseIcon';

export default function EditPenIcon(props) {
	return (
		<BaseIcon {...props}>
			<path d='M 2.0170914,20.806261 2.5369869,16.339379 16.564826,2.1795424 c 0.353019,-0.1675186 1.016145,-0.2980508 1.303666,-0.00894 l 3.978081,4.0000382 c 0.263262,0.2647147 0.11632,0.9043673 -0.03124,1.2438336 L 7.6465675,21.450331 3.3740127,21.992345 c -0.3114397,0.0036 -1.478898,0.164194 -1.3569213,-1.186084 z M 5.6992928,19.713176 6.9580012,19.566632 16.41933,10.104063 13.878992,7.5682588 4.4193811,17.05182 4.2728629,18.298589 c -0.1806624,1.537339 -0.1805049,1.558151 0.011875,1.559798 0.085717,7.12e-4 0.7222658,-0.06461 1.4145549,-0.145211 z M 19.756066,6.7647594 17.220109,4.2288014 15.30701,6.1419004 17.843765,8.6361168 Z' />
		</BaseIcon>
	);
}
