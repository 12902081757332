import React from 'react';
import BaseIcon from './BaseIcon';

export default function HeatAllocatorSearchIcon(props) {
	return (
		<BaseIcon
			{...props}
			renderMultiPath={({ primaryPathClass, primaryPathColor }) => (
				<path
					className={primaryPathClass}
					style={{ color: primaryPathColor }}
					d='m 6.0050148,3.5126627 c -1.3705,0 -2.49235,1.119782 -2.49235,2.490287 V 9.8115028 17.99705 c 0,1.370498 1.12186,2.490287 2.49235,2.490287 h 5.3728002 6.61924 c 1.37052,0 2.49028,-1.119782 2.49028,-2.490287 V 6.0029497 c 0,-1.370505 -1.11976,-2.490287 -2.49028,-2.490287 z m 0,1.099674 H 17.997055 c 0.78016,0 1.39061,0.610494 1.39061,1.390613 V 17.99705 c 0,0.780119 -0.61045,1.390613 -1.39061,1.390613 h -4.55476 c 0.26969,-0.397703 0.42788,-0.876419 0.42788,-1.390613 V 9.8115028 c 0,-1.370498 -1.12186,-2.4923541 -2.49236,-2.4923541 H 6.0050148 c -0.5146,0 -0.99444,0.158316 -1.39268,0.428397 v -1.744596 c 0,-0.780119 0.61253,-1.390613 1.39268,-1.390613 z m 0,3.8085531 h 5.3728002 c 0.78013,0 1.39268,0.610488 1.39268,1.390613 V 17.99705 c 0,0.780126 -0.61255,1.390613 -1.39268,1.390613 H 6.0050148 c -0.41443,0 -0.78145,-0.172122 -1.03456,-0.450619 -0.02978,-0.03276 -0.05823,-0.06724 -0.08475,-0.102836 -0.17233,-0.231376 -0.27336,-0.520232 -0.27337,-0.837158 V 9.8115028 c 0,-0.780125 0.61257,-1.390613 1.39268,-1.390613 z m 0.9276,2.9843142 a 0.55000001,0.55000001 0 0 0 -0.55087,0.548804 0.55000001,0.55000001 0 0 0 0.55087,0.550871 h 3.5176102 a 0.55000001,0.55000001 0 0 0 0.55087,-0.550871 0.55000001,0.55000001 0 0 0 -0.55087,-0.548804 z'
				/>
			)}
		/>
	);
}
