import React from 'react';
import { FormControl, InputLabel, Select } from '@mui/material';
import { useLocale } from '@sonar-web/common/src/hooks';
import BaseSelectMenuItem from '@sonar-web/common/src/components/BaseSelectMenuItem/BaseSelectMenuItem';
import { dateUnitSeparators } from './constants';
import { capitalize } from './helpers';
const options = [
	{ value: dateUnitSeparators.dot, label: 'Common_Dot' },
	{ value: dateUnitSeparators.dash, label: 'Common_Dash' },
	{ value: dateUnitSeparators.slash, label: 'Common_Slash' }
];

const name = 'dateUnitSeparator';
const SettingsDateUnitSeparator = ({ updateSettings, current }) => {
	const { translate } = useLocale();

	const handleChange = (e) => {
		updateSettings(name, e.target.value);
	};

	return (
		<FormControl fullWidth margin='normal'>
			<InputLabel id={name}>{translate('Common_DateSeparator')}</InputLabel>
			<Select labelId={name} value={current[name]} onChange={handleChange}>
				{options.map((opt, index) => (
					<BaseSelectMenuItem key={index} value={opt.value}>
						{capitalize(translate(opt.label))}
					</BaseSelectMenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default SettingsDateUnitSeparator;
