import React from 'react';
import BaseIcon from './BaseIcon';

export default function CloseOutlinedIcon(props) {
	return (
		<BaseIcon {...props}>
			<path d='M 12,2.0000002 C 6.4850489,2.0000002 2.0000001,6.4850481 2.0000001,12 2.0000014,17.51495 6.4850509,22 12,22 17.51495,22 22,17.51495 22,12 22,6.4850481 17.51495,1.9999989 12,2.0000002 Z m 0,1.330728 c 4.794365,0 8.661458,3.8749052 8.661458,8.6692718 0,4.794364 -3.867093,8.661457 -8.661458,8.661457 C 7.2056359,20.661457 3.3281267,16.794364 3.3281254,12 3.3281254,7.2056334 7.2056359,3.3307295 12,3.3307282 Z M 8.4557289,8.1276041 a 0.66666581,0.66666581 0 0 0 -0.05729,0.0052 0.66666581,0.66666581 0 0 0 -0.385417,0.1875001 0.66666581,0.66666581 0 0 0 0,0.945312 L 11.00521,12.257804 8.0130219,15.249992 a 0.66666581,0.66666581 0 0 0 0,0.945312 0.66666581,0.66666581 0 0 0 0.945312,0 l 2.9921901,-2.99218 2.992188,2.992188 a 0.66666581,0.66666581 0 0 0 0.945312,0 0.66666581,0.66666581 0 0 0 0,-0.945312 l -2.992188,-2.992188 2.992188,-2.9921878 a 0.66666581,0.66666581 0 0 0 0,-0.945312 0.66666581,0.66666581 0 0 0 -0.5625,-0.1875001 0.66666581,0.66666581 0 0 0 -0.382812,0.1875001 L 11.950524,11.312498 8.9583359,8.3203122 A 0.66666581,0.66666581 0 0 0 8.4557289,8.1276041 Z' />
		</BaseIcon>
	);
}
