import styled from '@emotion/styled';
import { LinearProgress, linearProgressClasses, Skeleton } from '@mui/material';
import { BaseTooltip } from '@sonar-web/common';

const Progress = styled(LinearProgress)(({ theme }) => ({
	alignSelf: 'stretch',
	height: 8,
	borderRadius: 5,
	[`&.${linearProgressClasses.colorPrimary}`]: {
		backgroundColor: theme.palette.grey[200]
	},
	[`& .${linearProgressClasses.bar}`]: {
		borderRadius: 5,
		backgroundColor: theme.palette.primary.main
	}
}));

const LicenseItemProgress = ({ pending, tooltip, progress }) => {
	if (pending) return <Skeleton variant='text' height='8px' />;
	return (
		<>
			<br />
			<BaseTooltip text={tooltip ?? ''}>
				<Progress
					variant='determinate'
					value={progress}
					sx={{ visibility: progress !== null ? 'visible' : 'hidden' }}
				/>
			</BaseTooltip>
		</>
	);
};

export default LicenseItemProgress;
