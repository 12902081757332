const FilterType = {
	String: {
		value: 0,
		name: 'string'
	},
	Int: {
		value: 1,
		name: 'int'
	},
	Long: {
		value: 2,
		name: 'long'
	},
	Datetime: {
		value: 3,
		name: 'datetime'
	},
	Boolean: {
		value: 4,
		name: 'boolean'
	},
	Guid: {
		value: 5,
		name: 'guid'
	},
	Date: {
		value: 6,
		name: 'date'
	}
};

export default FilterType;
