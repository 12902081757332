import React, { useEffect, useState } from 'react';
import LocationNodesMoveList from './List';
import LocationNodesMoveBreadcrumb from './LocationNodesMoveBreadcrumb';
import { Tabs, Tab, Grow, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { HorizontalSpacer, TabPanelBase, BaseGrowAnimation, FormTopActionsToolbar } from '@sonar-web/common';
import { selectBreadcrumbs, setResetAndReload } from '../LocationNodes/slice';
import { useDispatch, useSelector } from 'react-redux';
import { addBreadcrumb, resetToBreadcrumb, selectSelectedBreadcrumb, selectSelectedNodeToMoveTo } from './slice';
import { moveLocationNodeAsync, selectMove } from './moveSlice';
import { ERROR_TYPES } from '@sonar-web/common/src/constants/errorTypes';
import LocationNodeMoveNewLocation from './LocationNodeMoveNewLocation';
import LocationNodeAdd from '../LocationNode/LocationNodeAdd';
import { useIsMount } from '@sonar-web/common/src/hooks';
import LocationNodeMoveError from './LocationNodeMoveError';

const useStyles = makeStyles(() => ({
	contentWrapper: {
		height: 500
	},
	tabsRoot: {
		display: 'none'
	}
}));

const LocationNodeMove = ({ node, onClose, onSuccessCallback }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const isMount = useIsMount();
	const [tabId, setTabId] = useState(0);
	const [requireListReload, setRequireListReload] = useState(false);
	const [levelToAdd, setLevelToAdd] = useState(null);
	const [apiError, setApiError] = useState(null);
	const mainListBreadcrumbsNodes = useSelector(selectBreadcrumbs);
	const breadcrumb = useSelector(selectSelectedBreadcrumb);
	const selectedNode = useSelector(selectSelectedNodeToMoveTo);
	const { movePending, moveSuccess } = useSelector(selectMove);
	const newLocationNode = selectedNode ?? breadcrumb;

	const handleApiErrors = (err) => {
		if (err && err.type === ERROR_TYPES.api) {
			setApiError(err.message);
		}
	};

	const handleClose = () => {
		if (requireListReload) setTimeout(() => dispatch(setResetAndReload()), 300);
		onClose();
	};

	const handleSubmit = () => {
		dispatch(moveLocationNodeAsync(node)).then((err) => {
			handleApiErrors(err);
			if (!err) handleClose();
		});
	};

	const handleSubmitDispatch = async () => {
		const action = await import('../LocationNode/slice');

		return {
			action: action.addLocationNodeInMoveAsync,
			actionOnSuccess: () => {
				setTabId(0);
				setRequireListReload(true);
			}
		};
	};

	const showAdd = (level) => {
		setLevelToAdd(level);
		setTabId(1);
	};

	const handleAddCancel = () => setTabId(0);

	const handleTabChange = (event, newValue) => setTabId(newValue);

	useEffect(() => {
		dispatch(addBreadcrumb(mainListBreadcrumbsNodes.slice(1)));

		return () => {
			dispatch(resetToBreadcrumb(0));
		};
	}, []);

	useEffect(() => {
		if (apiError) setApiError(null);
	}, [newLocationNode]);

	return node ? (
		<BaseGrowAnimation show={true}>
			<Box flex='1' alignSelf='center' width={500}>
				<Tabs value={false} onChange={handleTabChange} classes={{ root: classes.tabsRoot }}>
					<Tab id={0} />
					<Tab id={1} />
				</Tabs>

				<TabPanelBase value={tabId} index={0} disableInnerTopPadding>
					<Grow in={tabId === 0} appear={!isMount}>
						<div className={classes.contentWrapper}>
							<FormTopActionsToolbar
								title={'LocationNodes_MoveLocationNode'}
								handleCancel={handleClose}
								handleSubmit={handleSubmit}
								pending={movePending}
								success={moveSuccess}
								submitDisabled={newLocationNode.id === node.parentLocationNodeId}
								disableSubmitMany={true}
								onSuccess={() =>
									onSuccessCallback ? onSuccessCallback(addSuccess, submitMany) : handleOnSuccess()
								}
							/>
							<LocationNodesMoveBreadcrumb showAdd={showAdd} />
							<LocationNodesMoveList nodeToMove={node} />
							<HorizontalSpacer size={2} />
							{!apiError ? (
								<LocationNodeMoveNewLocation newLocationNode={newLocationNode} nodeToMove={node} />
							) : (
								<LocationNodeMoveError message={apiError} />
							)}
						</div>
					</Grow>
				</TabPanelBase>

				<TabPanelBase value={tabId} index={1}>
					<Grow in={tabId === 1}>
						<div className={classes.contentWrapper}>
							{levelToAdd && (
								<LocationNodeAdd
									level={levelToAdd}
									onClose={handleAddCancel}
									onSubmitDispatch={handleSubmitDispatch}
									currentBreadcrumb={breadcrumb}
								/>
							)}
						</div>
					</Grow>
				</TabPanelBase>
			</Box>
		</BaseGrowAnimation>
	) : null;
};

export default LocationNodeMove;
