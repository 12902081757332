/* eslint-disable react/display-name */
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { colors } from '@mui/material';
import CardStatusItem from '../../../Cards/CardStatusItem';
import CardBase from '../../../Cards/CardBase';
import { selectProfileSingle, selectSelected } from './deviceConfigProfilesSlice';
import ProfilesContext from './profilesContext';
import ProfileCardChip from './ProfileCardChip';

const ProfileCard = ({ current, record, ...rest }) => {
	const { pendingSingle } = useSelector(selectProfileSingle);
	const selected = useSelector(selectSelected);
	const { handleSelectProfile } = useContext(ProfilesContext);

	const status = () => {
		if (handleSelectProfile && record.deviceTypeName)
			return () => <ProfileCardChip label={record.deviceTypeName} />;

		if (current)
			return () => <CardStatusItem text={'Common_Device_ActiveConfiguration'} color={colors.green[500]} />;

		return null;
	};

	return (
		<CardBase
			data-at='profileCardItem'
			record={record}
			renderStatus={status()}
			selected={handleSelectProfile && selected?.id === record.id}
			pending={pendingSingle === record.id}
			{...rest}
		/>
	);
};

export default ProfileCard;
