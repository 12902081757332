import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import BaseButton from './BaseButton';

const useStyles = makeStyles((theme) => ({
	root: {
		minWidth: 'auto',
		transition: theme.transitions.create(['color'], {
			easing: theme.transitions.easing.easeInOut,
			duration: theme.transitions.duration.short
		}),
		color: theme.palette.action.active,
		'&:hover': {
			backgroundColor: 'transparent',
			color: theme.palette.text.primary
		}
	}
}));

const ButtonText = React.forwardRef(function ButtonText({ size = 'medium', ...rest }, ref) {
	const classes = useStyles();

	return (
		<BaseButton ref={ref} variant='text' size={size} disableRipple disableFocusRipple classes={classes} {...rest} />
	);
});

export default ButtonText;
