import { apiBase } from '@sonar-web/common';
import { REST_API_PATHS } from '@sonar-web/common/src/constants/system';

const path = REST_API_PATHS.WMBUS;

export async function fetchDevice(id) {
	const { data } = await apiBase(`devices/${id}`, 'get', {
		path
	});

	return data;
}

export async function addDevice(device) {
	const response = await apiBase(`devices`, 'post', {
		path,
		data: device
	});

	const deviceHeader = response.headers.location;

	if (deviceHeader)
		return {
			url: deviceHeader,
			id: deviceHeader.slice(deviceHeader.indexOf('/') + 1)
		};

	return response;
}

export async function editDevice(device) {
	const data = await apiBase(`devices/${device.id}`, 'put', {
		path,
		data: device
	});

	return data;
}

export async function deleteDevice(id) {
	const data = await apiBase(`devices/${id}`, 'delete', {
		path
	});

	return data;
}

export async function addToNetworkAsync(devices) {
	const data = await apiBase(`devices/assigntonetwork`, 'put', {
		path,
		data: devices
	});

	return data;
}

export async function removeFromNetworkAsync(devices) {
	const data = await apiBase(`devices/unassignfromnetwork`, 'put', {
		path,
		data: devices
	});

	return data;
}
