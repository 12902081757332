import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from '@sonar-web/common/src/hooks';
import { downloadCSVFile } from '@sonar-web/common/src/utils/file';
import { formatDateTimeSecondsPrecision } from '@sonar-web/common';
import FilterOperator from '@sonar-web/common/src/enums/FilterOperator';
import FilterType from '@sonar-web/common/src/enums/FilterType';
import BaseButton from '@sonar-web/common/src/components/Buttons/BaseButton';
import {
	selectHeatAllocatorExportReads,
	fetchHeatAllocatorExportReadsAsync
} from '../Heatallocator/heatallocatorSlice';
import { getReadTypeByPeriod } from '../Reads/ReadTypeByPeriod';

const ExportMeasurementPointReads = ({ measurementPoint }) => {
	const { translate } = useLocale();
	const dispatch = useDispatch();

	const { pending } = useSelector(selectHeatAllocatorExportReads);

	const onClick = () => {
		const filters = [
			{
				value: measurementPoint.id,
				member: 'measurementPointId',
				filterOperator: FilterOperator.Equal.value,
				filterType: FilterType.Guid.value
			}
		];

		dispatch(fetchHeatAllocatorExportReadsAsync(filters)).then((response) => {
			if (!response) return;

			const fileName = measurementPoint.displayName;
			const headers = `${translate('Imports_HeatAllocatorNumber')};${translate(
				'Common_HeatAllocatorType'
			)};${translate('DateAndTime')};${translate('Common_Bearing')};${translate('Common_ReadType')}\n`;
			const rows = response.elements
				.map((d, index) => {
					const date = formatDateTimeSecondsPrecision(d.date);
					const bearing = d.bearing ?? '';
					const readType = translate(getReadTypeByPeriod(d.type, d.period));
					const newLine = index < response.totalCount - 1 ? '\n' : '';
					return `${d.heatAllocatorNumber};${d.heatAllocatorType};${date};${bearing};${readType}${newLine}`;
				})
				.join('');

			downloadCSVFile(headers + rows, fileName);
		});
	};

	return (
		<BaseButton name='exportReadsCSV' variant='outlined' onClick={onClick} pending={pending} progress>
			ExportReads
		</BaseButton>
	);
};

export default ExportMeasurementPointReads;
