export const heatallocatorReadsSorters = {
	initial: 0,
	sorterData: [
		{
			member: 'date',
			text: 'Common_MeasurementDate',
			direction: 'desc',
			directionText: 'Common_Latest'
		},
		{
			member: 'date',
			text: 'Common_MeasurementDate',
			direction: 'asc',
			directionText: 'Common_Oldest'
		}
	]
};
