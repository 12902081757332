import { BillingInfrastructureIcon, BillingReadsIcon, BillingChangesIcon } from '../../../Icons';

const FileUploadTypes = {
	Infrastructure: {
		id: 1,
		name: 'infrastructureFile',
		displayName: 'Infrastructure',
		Icon: BillingInfrastructureIcon,
		description: 'Billing_ChangesFileDescription',
		required: true
	},
	Changes: {
		id: 2,
		name: 'exchangesFile',
		displayName: 'Changes',
		Icon: BillingChangesIcon,
		description: 'Billing_InfrastructureFileDescription',
		required: false
	},
	Reads: {
		id: 3,
		name: 'readsFile',
		displayName: 'Reads',
		Icon: BillingReadsIcon,
		description: 'Billing_ReadsFileDescription',
		required: true
	}
};

export const getFileUploadTypeByName = (name) => {
	const toArr = Object.entries(FileUploadTypes).map((f) => f[1]);

	return toArr.find((f) => f.name === name);
};

export const getFileUploadTypeById = (id) => {
	const toArr = Object.entries(FileUploadTypes).map((f) => f[1]);

	return toArr.find((f) => f.id === id);
};

export default FileUploadTypes;
