const prepareFetchedData = (data) => {
	if (!data) return data;

	//reduce settings array to obeject and add properites to return object
	const { settings, ...rest } = data;
	const settingsObj = settings.reduce((acc, curr) => {
		acc[curr.key] = curr.value;
		return acc;
	}, {});

	return { ...rest, ...settingsObj };
};

const prepareDataForSave = (data, settingsPropertiesNames) => {
	if (!data) return data;

	//create settings array of key value pairs from selected properties in data object
	const settings = settingsPropertiesNames.map((key) => {
		return { key, value: data[key] };
	});

	//remove selected properties from data object
	const { ...rest } = data;
	settingsPropertiesNames.forEach((key) => {
		delete rest[key];
	});

	return { ...rest, settings };
};

const TransmitHelpers = {
	prepareFetchedData,
	prepareDataForSave
};

export default TransmitHelpers;
