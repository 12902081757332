import React, { useEffect } from 'react';
import { InputAdornment, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '../../../../hooks';
import useConfigurator from '../useConfigurator';

const useStyles = makeStyles(() => ({
	root: {
		'& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
			display: 'none'
		}
	}
}));

const ConfiguratorOmsBatteryLifeThreshold = ({
	onChange,
	onBlur,
	name,
	value,
	error,
	helperText,
	element,
	dataAtError,
	form,
	forceUpdateValidationSchema
}) => {
	const classes = useStyles();
	const { translate } = useLocale();
	const { getCurrentKeyValue } = useConfigurator();
	const { unit, description = '', isReadOnly } = element;
	const dependencyValue = getCurrentKeyValue('battery_capacity_property');

	const helperTextValue = React.useMemo(() => {
		if (helperText && description) return `${helperText} (${translate(description, dependencyValue)})`;
		if (helperText) return helperText;
		if (description) return translate(description, dependencyValue);
		return '';
	}, [helperText]);

	const handleChange = (e) => {
		const nextValue = e.target.value;
		const isMatch = /^-?\d*$/.test(nextValue);

		if (isMatch) onChange(e);
	};

	useEffect(() => {
		forceUpdateValidationSchema(form.values, { key: name, value: { max: dependencyValue } });
	}, []);

	return (
        (<TextField
            margin='normal'
            size='small'
            type='number'
            fullWidth
            label={translate(name)}
            name={name}
            value={value ?? ''}
            disabled={Boolean(isReadOnly)}
            onChange={handleChange}
            onBlur={onBlur}
            error={error}
            helperText={helperTextValue}
            classes={{ root: classes.root }}
            slotProps={{
                input: {
                    endAdornment: unit ? (
                        <InputAdornment position='end'>
                            <Typography variant='caption' color='textSecondary'>
                                {translate(unit)}
                            </Typography>
                        </InputAdornment>
                    ) : null
                },

                formHelperText: {
                    'data-at-error': dataAtError
                }
            }} />)
    );
};

export default ConfiguratorOmsBatteryLifeThreshold;
