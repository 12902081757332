import React from 'react';
import { FormControl, FormHelperText, useTheme } from '@mui/material';
import SelectHourOfDay from '../../../../components/SelectHourOfDay/SelectHourOfDay';
import ConfiguratorControlLabel from './ConfiguratorControlLabel';

const ConfiguratorHourOfDay = ({ name, value, element, form, dataAtError, helperText }) => {
	const { defaultValue, description } = element;
	const theme = useTheme();

	const initial = value || defaultValue || [];

	const handleOnChange = (v) => form.setFieldValue(name, v);

	return (
		<FormControl fullWidth margin='normal'>
			<ConfiguratorControlLabel label={description ?? 'Common_MonthOfYear'} marginBottom={10} />
			<SelectHourOfDay single={false} initial={initial} onChange={handleOnChange} />
			<FormHelperText data-at-error={dataAtError} style={{ color: theme.palette.error.main }}>
				{helperText}
			</FormHelperText>
		</FormControl>
	);
};

export default ConfiguratorHourOfDay;
