import React, { useState, useEffect } from 'react';
import { useIsMount } from '../../hooks';
import { filterOperators } from '../../constants';

const FiltersControlAutocomplete = ({
	member,
	label,
	stateSlice,
	onChange,
	onClearMemberAll,
	renderSearchComponent,
	valueName = 'name'
}) => {
	const isMount = useIsMount();
	const [filterValue, setFilterValue] = useState('');
	const [key, setKey] = useState(0);

	const onSelect = (selected) => {
		setFilterValue(selected ? selected[valueName] : '');
	};

	const handleClearFilter = () => onClearMemberAll(member.name);

	const handleSetFilter = () => {
		onChange(
			[
				{
					member,
					value: [filterValue],
					operator: filterOperators.collectionContain
				}
			],
			member.name
		);
	};

	useEffect(() => {
		if (isMount) return;
		if (filterValue === '') {
			handleClearFilter();
			return;
		}
		handleSetFilter();
	}, [filterValue]);

	useEffect(() => {
		if (stateSlice == null || (stateSlice && Object.keys(stateSlice).length === 0) || !stateSlice[member.name]) {
			setKey((prev) => prev + 1);
			setFilterValue('');
		}
	}, [stateSlice]);

	return <React.Fragment key={key}>{renderSearchComponent({ label, onSelect })}</React.Fragment>;
};

export default FiltersControlAutocomplete;
