const views = {
	template: 0,
	settings: 1,
	mapping: 2,
	submit: 3
};

const cancelView = 4;

const steps = [
	{
		id: views.template,
		stepName: 'Imports_ImportWizardTimelineHeader_Template',
		stepDescription: 'Imports_ImportWizardTimelineDescription_Template'
	},
	{
		id: views.settings,
		stepName: 'Imports_ImportWizardTimelineHeader_Settings',
		stepDescription: 'Imports_ImportWizardTimelineDescription_Settings'
	},
	{
		id: views.mapping,
		stepName: 'Imports_ImportWizardTimelineHeader_Mapping',
		stepDescription: 'Imports_ImportWizardTimelineDescription_Mapping'
	},
	{
		id: views.submit,
		stepName: 'Imports_ImportWizardTimelineHeader_Submit',
		stepDescription: 'Imports_ImportWizardTimelineDescription_Submit'
	}
];

export default {
	views,
	cancelView,
	steps
};
