import { createSelector, createSlice } from '@reduxjs/toolkit';
import {
	fetchPeriod,
	addPeriod,
	deletePeriod,
	editPeriod,
	editPeriodWithTemplate,
	fetchPeriodTemplate,
	addPeriodWithTemplate
} from './periodApi';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import { parseError } from '../billingErrors';
import BillingHelpers from '../Helpers/BillingHelpers';
import Module from '../Constants/Module';

const slice = Module.slices.billingPeriod;
const initialState = {
	addPending: false,
	addSuccess: false,
	fetchPending: false,
	fetchSuccess: false,
	deletePending: false,
	deleteSuccess: false,
	billingPeriod: null,
	periodAdded: false,
	periodDeleted: false
};

export const billingPeriodSlice = createSlice({
	name: slice,
	initialState,
	reducers: {
		fetchBillingPeriodSucces: (state, { payload }) => {
			let billingPeriod = { ...payload, name: payload.name ?? '' };

			if (billingPeriod.basicBillingPeriodData) {
				for (const key in billingPeriod.basicBillingPeriodData) {
					let val = billingPeriod.basicBillingPeriodData[key]?.toString();

					if (val?.includes(',')) {
						val = val.replaceAll(',', '.');
						billingPeriod.basicBillingPeriodData[key] = val;
					}
				}
			}

			state.billingPeriod = billingPeriod;
			state.fetchPending = false;
			state.fetchSuccess = true;
		},
		fetchBillingPeriodPending: (state) => {
			state.fetchPending = true;
			state.fetchSuccess = false;
		},
		addBillingPeriodSucces: (state, { payload }) => {
			state.addPending = false;
			state.addSuccess = payload;
		},
		addBillingPeriodPending: (state, { payload }) => {
			state.addPending = payload;
		},
		deleteBillingPeriodSucces: (state) => {
			state.deletePending = false;
			state.deleteSuccess = true;
			state.periodDeleted = true;
		},
		deleteBillingPeriodPending: (state, { payload }) => {
			state.deletePending = payload;
		},
		setBillingPeriodData: (state, { payload }) => {
			state.billingPeriod = payload;
		},
		resetBillingPeriodData: (state) => {
			for (let is in initialState) {
				state[is] = initialState[is];
			}
		},
		resetAddSuccess: (state) => {
			state.addSuccess = false;
		},
		periodAdded: (state, { payload }) => {
			state.periodAdded = payload;
		}
	}
});

export const {
	fetchBillingPeriodSucces,
	fetchBillingPeriodPending,
	addBillingPeriodSucces,
	addBillingPeriodPending,
	deleteBillingPeriodSucces,
	deleteBillingPeriodPending,
	setBillingPeriodData,
	resetBillingPeriodData,
	resetAddSuccess,
	periodAdded
} = billingPeriodSlice.actions;

export const fetchBillingPeriodAsync = (id, clientId) => async (dispatch) => {
	try {
		dispatch(fetchBillingPeriodPending(true));

		let response;

		if (id) {
			response = await fetchPeriod(id);
		} else response = await fetchPeriodTemplate(clientId);

		dispatch(fetchBillingPeriodSucces({ ...response, hasInitialFiles: BillingHelpers.billingHasFiles(response) }));
		return response;
	} catch (error) {
		dispatch(addErrorAsync({ slice, error: parseError(error) }));
	}
};

export const addBillingPeriodAsync = (data, saveAsTemplate) => async (dispatch) => {
	try {
		dispatch(addBillingPeriodPending(true));

		if (saveAsTemplate) await addPeriodWithTemplate(data);
		else await addPeriod(data);

		dispatch(addBillingPeriodSucces(true));
	} catch (error) {
		dispatch(addBillingPeriodPending(false));

		return await dispatch(addErrorAsync({ slice, error: parseError(error) }));
	}
};

export const editBillingPeriodAsync = (data, saveAsTemplate) => async (dispatch) => {
	try {
		dispatch(addBillingPeriodPending(true));

		if (saveAsTemplate) await editPeriodWithTemplate(data);
		else await editPeriod(data);

		dispatch(addBillingPeriodSucces(true));
	} catch (error) {
		dispatch(addBillingPeriodPending(false));

		return await dispatch(addErrorAsync({ slice, error: parseError(error) }));
	}
};

export const deleteBillingPeriodAsync = (id) => async (dispatch) => {
	try {
		dispatch(deleteBillingPeriodPending(true));

		await deletePeriod(id);

		dispatch(deleteBillingPeriodSucces());
		setTimeout(() => dispatch(resetBillingPeriodData()), 0);
	} catch (error) {
		dispatch(deleteBillingPeriodPending(false));
		return await dispatch(addErrorAsync({ slice, error: parseError(error) }));
	}
};

export const selectBillingPeriod = createSelector(
	(state) => state.billingPeriod,
	(billingPeriod) => ({
		period: billingPeriod.billingPeriod,
		fetchPending: billingPeriod.fetchPending,
		fetchSuccess: billingPeriod.fetchSuccess,
		addPending: billingPeriod.addPending,
		addSuccess: billingPeriod.addSuccess
	})
);

export const selectDeleteBillingPeriod = createSelector(
	(state) => state.billingPeriod,
	(billingPeriod) => ({
		deletePending: billingPeriod.deletePending,
		deleteSuccess: billingPeriod.deleteSuccess
	})
);

export default billingPeriodSlice.reducer;
