import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import { tcpDeviceSlice } from '../tcpDeviceSlice';
import { apiBase, REST_API_PATHS } from '@sonar-web/common';

const path = REST_API_PATHS.TCP;
const slice = 'tcpDevice';

export const tcpStateInitial = {
	fetchPending: false,
	fetchSuccess: false,
	data: null,
	deletePending: false,
	deleteSuccess: false,
	reload: false
};

export const tcpStateReducers = {
	fetchTcpStateSucces: (state, action) => {
		state.tcpState.data = action.payload;
		state.tcpState.fetchPending = false;
		state.tcpState.fetchSuccess = true;
		state.tcpState.reload = false;
	},
	fetchTcpStatePending: (state, action) => {
		state.tcpState.fetchPending = action.payload;
	},
	resetTcpState: (state) => {
		state.tcpState = { ...tcpStateInitial };
	},
	deleteTcpPendingConfigurationSucces: (state) => {
		state.tcpState.deletePending = false;
		state.tcpState.deleteSuccess = true;
		state.tcpState.reload = true;
	},
	deleteTcpPendingConfigurationPending: (state, action) => {
		state.tcpState.deletePending = action.payload;
		state.tcpState.deleteSuccess = false;
	}
};

export const fetchTcpStateAsync = (id) => async (dispatch) => {
	const { fetchTcpStatePending, fetchTcpStateSucces, resetTcpState } = tcpDeviceSlice.actions;
	try {
		dispatch(fetchTcpStatePending(true));
		const response = await fetchTcpState(id);
		dispatch(fetchTcpStateSucces(response));
	} catch (error) {
		dispatch(fetchTcpStatePending(false));
		dispatch(resetTcpState());
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const deleteTcpPendingConfigurationAsync = (id) => async (dispatch) => {
	const { deleteTcpPendingConfigurationSucces, deleteTcpPendingConfigurationPending } = tcpDeviceSlice.actions;

	try {
		dispatch(deleteTcpPendingConfigurationPending(true));
		const response = await deleteTcpPendingConfiguration(id);
		dispatch(deleteTcpPendingConfigurationSucces(response));
	} catch (error) {
		dispatch(deleteTcpPendingConfigurationPending(false));
		dispatch(addErrorAsync({ slice, error }));
		return error;
	}
};

export const selectTcpState = (state) => {
	return {
		dataRow: state.tcpDevice.tcpState.data,
		fetchPending: state.tcpDevice.tcpState.fetchPending,
		fetchSuccess: state.tcpDevice.tcpState.fetchSuccess,
		reload: state.tcpDevice.tcpState.reload
	};
};

export const selectTcpDelete = (state) => {
	return {
		deletePending: state.tcpDevice.tcpState.deletePending,
		deleteSuccess: state.tcpDevice.tcpState.deleteSuccess
	};
};

/* API */
async function fetchTcpState(id) {
	const { data } = await apiBase(`devices/${id}/state`, 'get', {
		path
	});

	return data;
}

async function deleteTcpPendingConfiguration(id) {
	const { data } = await apiBase(`devices/${id}/configuration`, 'delete', {
		path
	});

	return data;
}
