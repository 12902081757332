import { apiBase } from '@sonar-web/common';
import { REST_API_PATHS } from '@sonar-web/common/src/constants/system';
import ApiHelpers from '@sonar-web/common/src/helpers/ApiHelpers';

const path = REST_API_PATHS.HEATMETER;

async function fetchHeatMeter(id) {
	const { data } = await apiBase(`heatmeters/${id}`, 'get', {
		path
	});

	return data;
}

async function addHeatMeter(device) {
	const response = await apiBase(`heatmeters`, 'post', {
		path,
		data: device
	});

	const header = response.headers.location;

	if (header)
		return {
			url: header,
			id: header.slice(header.indexOf('/') + 1)
		};

	return response;
}

async function editHeatMeter(device) {
	return await apiBase(`heatmeters/${device.id}`, 'put', {
		path,
		data: device
	});
}

async function deleteHeatMeter(id) {
	return await apiBase(`heatmeters/${id}`, 'delete', {
		path
	});
}

async function fetchHeatMeterType(id) {
	const { data } = await apiBase(`heatmeterTypes/${id}`, 'get', {
		path
	});

	return data;
}

async function fetchHeatMeterTypes() {
	const { data } = await apiBase(`heatmeterTypes`, 'get', {
		path
	});

	return data;
}

export async function fetchHeatMeterMetaData(id) {
	const { data } = await apiBase(`heatmeters/${id}/properties`, 'get', {
		path
	});

	return data;
}

export async function editHeatMeterMetaData(id, metadata) {
	const data = await apiBase(`heatmeters/${id}/properties`, 'put', {
		path,
		data: metadata
	});

	return data;
}

export async function fetchHeatMeterReads(pageDescriptor) {
	const params = ApiHelpers.urlParamsFromPageDescriptor(pageDescriptor, ['heatMeterNumber']);

	const { data } = await apiBase(`reads?${params}`, 'get', {
		path
	});

	return data;
}

export async function fetchDiameterAndLength() {
	const { data } = await apiBase('diameterAndLengthDictionary', 'get', {
		path
	});

	return data;
}

const HeatMeterService = {
	fetchHeatMeter,
	addHeatMeter,
	editHeatMeter,
	deleteHeatMeter,
	fetchHeatMeterType,
	fetchHeatMeterTypes,
	fetchHeatMeterMetaData,
	editHeatMeterMetaData,
	fetchHeatMeterReads,
	fetchDiameterAndLength
};

export default HeatMeterService;
