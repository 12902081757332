import moment from 'moment';
import { dateAsIsoString } from '@sonar-web/common/src/helpers';
import TimeLicenseStatus from './Enums/TimeLicenseStatus';

const timeLicenseReminingDays = (date) => {
	const now = moment(new Date().toISOString()).set({ second: 0, minute: 0, hour: 0 });
	const lic = moment(date).set({ second: 0, minute: 0, hour: 0 });
	const diff = now.diff(dateAsIsoString(lic), 'days') * -1;

	return diff <= 0 ? 0 : diff;
};

const timeLicenseStatus = (date, isValid) => {
	const remainingDays = timeLicenseReminingDays(date);

	if (!isValid && remainingDays === 0) return TimeLicenseStatus.Expired.name;
	if (remainingDays <= 60) return TimeLicenseStatus.Expiring.name;
	return TimeLicenseStatus.Ok.name;
};

const TenantsHelpers = {
	timeLicenseStatus,
	timeLicenseReminingDays
};

export default TenantsHelpers;
