import React, { useEffect } from 'react';
import { Box, Toolbar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ListItemTextBase from '../ListItemTextBase/ListItemTextBase';
import ListItemBase from '../ListItemBase/ListItemBase';
import { useLocale } from '../../hooks';
import BaseTooltip from '../BaseTooltip/BaseTooltip';
import ToggleButtonSubmitMany from '../Buttons/ToggleButtonSubmitMany';
import ButtonText from '../Buttons/ButtonText';
import BaseButton from '../Buttons/BaseButton';

const useStyles = makeStyles((theme) => ({
	action: {
		marginRight: theme.spacing(1),
		minWidth: 'auto',
		'&:last-child': {
			marginRight: 0
		}
	},
	title: {
		fontWeight: theme.typography.fontWeightMedium
	}
}));

const FormTopActionsToolbar = ({
	avatar,
	title,
	subtitle,
	handleCancel,
	pending,
	success,
	resetSuccess,
	onSuccess,
	submitMany,
	setSubmitMany,
	disableSubmitMany,
	submitDisabled,
	submitButtonType,
	handleSubmit
}) => {
	const classes = useStyles();
	const { translate } = useLocale();

	useEffect(() => {
		if (!success) return;
		if (onSuccess) onSuccess();
		setTimeout(() => {
			if (resetSuccess) resetSuccess();
		}, 1000);
	}, [success]);

	return (
		<Toolbar variant='dense' disableGutters data-at='FormTopActionsToolbar'>
			<ListItemBase disableGutters hideBorder style={{ padding: 0 }}>
				{avatar ? avatar() : null}
				<ListItemTextBase
					hideBorder
					primary={translate(title)}
					primaryTypographyProps={{
						variant: 'h6',
						color: 'textPrimary',
						lineHeight: 1.2,
						classes: { root: classes.title },
						'data-at-params': `FormTopActionsToolbar-title|${title}`
					}}
					secondary={subtitle ? subtitle : null}
				/>
				<Box className={classes.action}>
					<BaseButton
						name='formTopActions_cancel'
						variant='outlined'
						pending={pending}
						onClick={handleCancel}>
						Common_Cancel
					</BaseButton>
				</Box>
				{!disableSubmitMany && (
					<BaseTooltip text='Common_ToggleAddMany' color='default'>
						<Box className={classes.action}>
							<ToggleButtonSubmitMany
								name='formTopActions_toggleAddMany'
								value='submitMany'
								selected={submitMany}
								pending={pending}
								onChange={() => setSubmitMany(!submitMany)}
							/>
						</Box>
					</BaseTooltip>
				)}
				<Box className={classes.action}>
					<BaseButton
						name='formTopActions_submit'
						type={submitButtonType ?? 'submit'}
						progress
						pending={pending}
						size='small'
						onClick={submitButtonType ? handleSubmit : null}
						disabled={submitDisabled ?? false}>
						Common_Confirm
					</BaseButton>
				</Box>
			</ListItemBase>
		</Toolbar>
	);
};

export default FormTopActionsToolbar;
