import { createSelector, createSlice } from '@reduxjs/toolkit';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import Slices from '../Constants/Slices';
import TenantsService from '../TenantsService';

const initialState = {
	tenant: null,
	mode: null,
	modePending: false,
	settings: null,
	settingsPending: null
};

export const slice = Slices.tenant;
export const tenantSlice = createSlice({
	name: slice,
	initialState,
	reducers: {
		setTenant: (state, { payload }) => {
			state.tenant = payload;
		},
		setTenantsMode: (state, { payload }) => {
			state.mode = payload;
		},
		setTenantsModePending: (state, { payload }) => {
			state.modePending = payload;
		},
		setTenantSettingsPending: (state, { payload }) => {
			state.settingsPending = payload;
		},
		setTenantSettingsSuccess: (state, { payload }) => {
			state.settingsPending = false;
			state.settings = payload;
		}
	}
});

export const { setTenant, setTenantsMode, setTenantsModePending, setTenantSettingsPending, setTenantSettingsSuccess } =
	tenantSlice.actions;

export const fetchTenantsModeAsync = () => async (dispatch, getState) => {
	try {
		const tenantId = getState().tenant.tenant.id;

		dispatch(setTenantsModePending(true));
		const response = await TenantsService.fetchTenantsMode(tenantId);
		dispatch(setTenantsMode(response));
	} catch (error) {
		dispatch(addErrorAsync({ slice, error }));
		dispatch(setTenantsModePending(false));
	}
};

export const fetchTenantSettingsAsync = () => async (dispatch) => {
	try {
		dispatch(setTenantSettingsPending(true));
		const response = await TenantsService.fetchTenantSettings();
		dispatch(setTenantSettingsSuccess(response));
	} catch (error) {
		dispatch(addErrorAsync({ slice, error }));
		dispatch(setTenantSettingsPending(false));
	}
};

export const selectCurrentTenant = (state) => state.tenant.tenant;

export const selectTenantsMode = createSelector(
	(state) => state.tenant,
	(tenant) => ({
		mode: tenant.mode,
		modePending: tenant.modePending
	})
);

export const selectTenantSettings = createSelector(
	(state) => state.tenant,
	(tenant) => ({
		settings: tenant.settings,
		settingsPending: tenant.settingsPending
	})
);

export default tenantSlice.reducer;
