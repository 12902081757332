import { createSelector, createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { addErrorAsync } from '../../Errors/errorsSlice';
import { addConfig, editConfig, fetchConfig, fetchDatasForm } from './deviceConfigApi';
import { multiActionsInitial, multiActionsReducers } from './MultiActions/multiActionsSlice';

const initialState = {
	isLoaded: null, //null - progress, false - błąd, true - ok
	activeTab: 0,
	lastActiveTab: 0,
	readOnly: false,
	deviceId: '',
	deviceTypeId: '',
	currentDataSet: 'manual', //profile, configuration, manual
	currentData: {
		id: '',
		name: '',
		description: '',
		isSystemProfile: false,
		deviceConfiguration: {
			deviceConfigurationItems: []
		}
	},
	profileData: null,
	configurationData: null,
	fetchedData: null,
	fetchedMetadata: null,
	isInEditMode: true,
	isInProfileSubmitMode: false,
	isInSectionEditMode: false,
	submitPending: false,
	registerSelectionMode: false,
	datasForm: null,
	initialDatasFormRegisters: [],
	datasFormRegisters: [],
	datasFormElementsSettings: {},
	disabledRegisters: [],
	fetchDatasFormPending: false,
	sectionsValidationStatus: {},
	multiActions: multiActionsInitial,
	onRegistersChange: null
};

const slice = 'deviceConfigurator';
export const deviceConfiguratorSlice = createSlice({
	name: slice,
	initialState: initialState,
	reducers: {
		setIsLoaded: (state, { payload }) => {
			const { loaded, tabId } = payload;

			state.isLoaded = loaded;
			if (loaded) state.activeTab = tabId ?? 1;
		},
		fetchConfigSuccess: (state, { payload }) => {
			const { response, configData } = payload;
			const { metadata, ...responseRest } = response;
			const data = {
				...responseRest,
				id: configData.profileId ?? responseRest?.id,
				pendingConfig: configData.pendingConfig
			};

			state.fetchedData = data;
			state.fetchedMetadata = metadata;
			state.currentDataSet = 'configuration';
		},
		setActiveTab: (state, { payload }) => {
			state.lastActiveTab = state.activeTab;
			state.activeTab = payload;
		},
		setData: (state, { payload }) => {
			const valuesCurrent = {};
			let deviceConfigurationItems = [];

			for (const el of state.currentData.deviceConfiguration.deviceConfigurationItems) {
				if (el.key) {
					valuesCurrent[el.key] = el.value;
				}
			}
			const valuesMerged = { ...valuesCurrent, ...payload };

			for (const value in valuesMerged) {
				deviceConfigurationItems.push({
					key: value,
					value: valuesMerged[value]
				});
			}

			state.currentData.deviceConfiguration.deviceConfigurationItems = deviceConfigurationItems;
		},
		setConfigurationName: (state, { payload }) => {
			state.currentData.name = payload;
		},
		setDeviceData: (state, { payload }) => {
			state.deviceId = payload.id;
			state.deviceTypeId = payload.deviceTypeId;
		},
		setManualMode: (state) => {
			state.currentData = initialState.currentData;
			state.profileData = initialState.profileData;
			state.currentDataSet = 'manual';
			state.fetchedData = initialState.fetchedData;
			if (state.readOnly) state.readOnly = false;
		},
		setReadOnlyMode: (state) => {
			state.readOnly = true;
		},
		resetConfiguratorData: (state) => {
			for (const is in initialState) {
				state[is] = initialState[is];
			}
		},
		setEditMode: (state, { payload }) => {
			state.isInEditMode = payload;
		},
		setProfileSubmitMode: (state, { payload }) => {
			state.isInProfileSubmitMode = payload;
		},
		setSectionEditMode: (state, { payload }) => {
			state.isInSectionEditMode = payload;
		},
		configSubmitPending: (state, { payload }) => {
			state.submitPending = payload;
		},
		setDatasFormPending: (state, { payload }) => {
			state.fetchDatasFormPending = payload;
		},
		fetchDatasFormSuccess: (state, { payload }) => {
			const { response, defaultFormValues, overrideValues, noMeasurementDeviceRegistersToRemove = [] } = payload;
			const hasData = state.fetchedData ?? false;
			const datasFormRegisters = [];

			let elements = [];
			let datasFormElementsSettings = {};
			let additionalElements =
				defaultFormValues && Object.keys(defaultFormValues).length > 0
					? [...Object.entries(defaultFormValues).map((dfv) => ({ key: dfv[0], value: dfv[1] }))]
					: [];

			if (!response) {
				state.datasForm = null;
				return;
			}

			const registersToRemove = getRegistersToRemove(
				noMeasurementDeviceRegistersToRemove,
				hasData,
				state.fetchedMetadata
			);
			const formConfiguration = prepareFormConfiguration(response, registersToRemove);

			for (let s of formConfiguration.sections) {
				for (let t of s.tabs) {
					for (let g of t.groups) {
						for (let e of g.elements) {
							//ustawienie wartości domyślnej wstrzykniętej spoza definicji formularza
							const isDefaultValueInConfigurator = defaultFormValues?.[e.key];

							if (isDefaultValueInConfigurator) {
								e.defaultValue = defaultFormValues[e.key];
								additionalElements = additionalElements.filter((el) => el.key !== e.key);
							}

							//jeśli jest edycja i ustawiona wartość to rejestr będzie włączony w formularzu
							let keyData = null;

							if (hasData)
								keyData = state.fetchedData.deviceConfiguration.deviceConfigurationItems.find(
									(el) => el.key === e.key
								);

							//dodaj element do tablicy rejestrów, aby móc skojarzyć klucz z elementu do rejestru grupy
							//dodatkowo includeInConfiguration będzie użyte do włączania/wyłączania rejestru w formularzu - jeśli opcja jest włączona dla konfiguratora
							//keyData z type === 'Metadata' pwodują ustawienie danych w formularzu, tak jak wartości domyślne
							//podczas edycji takie dane domyślne mogą przyjść jako metadata po fetchu, stąd takie obejście
							datasFormRegisters.push({
								group: g.name,
								register: g.register ?? null,
								key: e.key,
								value: keyData ?? e.defaultValue,
								includeInConfiguration:
									keyData != null && keyData.type !== 'Metadata'
										? true
										: e.includeInConfiguration ?? false
							});

							elements.push({ key: e.key, value: keyData ?? e.defaultValue });

							//obiekt z elementami wzgledem sekcji do tworzenia schemy formularza
							if (!datasFormElementsSettings[s.name]) datasFormElementsSettings[s.name] = [e];
							else datasFormElementsSettings[s.name].push(e);
						}
					}
				}
			}

			additionalElements.forEach((al) => {
				elements.push({ key: al.key, value: al.value });
			});

			if (hasData) {
				//nie kopiuj wartości z deviceConfigurationItems jeśli jest już wartość w elements
				//pozwala na wczytanie wartości nowego id profilu dla skopiowanej konfiguracji - PR2020111601-1492
				const keys = [
					'energy_profile_property',
					'battery_capacity_property',
					'energy_profile_type_property'
				].filter((k) => !elements.find((e) => e.key === k));

				const filteredItems = state.fetchedData.deviceConfiguration.deviceConfigurationItems.filter((dci) =>
					keys.includes(dci.key)
				);

				if (filteredItems.length > 0) elements = [...elements, ...filteredItems];
			}

			if (state.currentDataSet !== 'profile') {
				const current = state.fetchedData
					? state.fetchedData.deviceConfiguration.deviceConfigurationItems
					: null;

				const merged = current
					? elements.map((el) => {
							if (el.key === 'energy_profile_property') return el;

							const overiddenElementValue = !overrideValues ? null : overrideValues[el.key] ?? null;
							const currentEl = overiddenElementValue
								? { key: el.key, value: overiddenElementValue }
								: current.find((ce) => ce.key === el.key);

							return currentEl ?? el;
					  })
					: elements;

				if (current) {
					const newCurrent = {
						...state.fetchedData,
						deviceConfiguration: { deviceConfigurationItems: merged }
					};
					state.currentData = newCurrent;
					state.configurationData = newCurrent;
				} else state.currentData.deviceConfiguration.deviceConfigurationItems = merged;
			}

			//sprawdzenie czy wszystkie elementy w grupie/rejestrze są włączone, ustawenie wszystkim tego samego includeInConfiguration
			const groupsIncludesStatus = datasFormRegisters.reduce((acc, dfr) => {
				if (!acc[dfr.group]) acc[dfr.group] = dfr.includeInConfiguration;
				else acc[dfr.group] = dfr.includeInConfiguration === false ? false : acc[dfr.group];
				return acc;
			}, {});

			const datasFormRegistersFinal = datasFormRegisters.map((dfr) => {
				return {
					...dfr,
					includeInConfiguration: groupsIncludesStatus[dfr.group]
				};
			});

			state.fetchDatasFormPending = false;
			state.datasForm = formConfiguration;
			state.initialDatasFormRegisters = datasFormRegistersFinal;
			state.datasFormRegisters = datasFormRegistersFinal;
			state.datasFormElementsSettings = datasFormElementsSettings;
		},
		setProfileData: (state, action) => {
			const { id, isSystemProfile, name, description, deviceConfiguration, isFetchedProfile } = action.payload;
			const newProfileData = { id, name, description, isSystemProfile };

			state.currentData = { ...state.currentData, ...newProfileData };
			if (deviceConfiguration) state.currentData.deviceConfiguration = deviceConfiguration;

			if (isFetchedProfile) {
				state.currentDataSet = 'profile';
				state.profileData = { ...newProfileData, deviceConfiguration: deviceConfiguration };
			}

			if (state.readOnly) state.readOnly = false;
		},
		setSectionsValidationStatus: (state, { payload }) => {
			state.sectionsValidationStatus = { ...state.sectionsValidationStatus, ...payload };
		},
		setRegisterSelectionMode: (state, { payload }) => {
			state.registerSelectionMode = payload;
		},
		setDatasFormRegister: (state, { payload }) => {
			const { groupName, value, updateAll } = payload;

			if (updateAll) {
				state.datasFormRegisters = updateAll;
				return;
			}

			const newDatasFormRegisters = state.datasFormRegisters.map((dfr) => {
				if (dfr.group !== groupName) return dfr;

				const currentValue = state.currentData.deviceConfiguration.deviceConfigurationItems.find(
					(dci) => dci.key === dfr.key
				);
				return { ...dfr, includeInConfiguration: value, value: currentValue ? currentValue.value : null };
			});

			state.datasFormRegisters = tryOnRegistersChange(groupName, value, newDatasFormRegisters);
		},
		setDisabledRegisters: (state, { payload }) => {
			state.disabledRegisters = [...state.disabledRegisters, payload];
		},
		setDatasFormElementsSettings: (state, { payload }) => {
			const { name, sectionElements } = payload;

			state.datasFormElementsSettings[name] = sectionElements;
		},
		...multiActionsReducers
	}
});

export const {
	setIsLoaded,
	fetchConfigSuccess,
	setActiveTab,
	setData,
	setConfigurationName,
	setDeviceData,
	setManualMode,
	setReadOnlyMode,
	resetConfiguratorData,
	setEditMode,
	setProfileSubmitMode,
	setSectionEditMode,
	configSubmitPending,
	setDatasFormPending,
	fetchDatasFormSuccess,
	setProfileData,
	setSectionsValidationStatus,
	setRegisterSelectionMode,
	setDatasFormRegister,
	setDisabledRegisters,
	setDatasFormElementsSettings
} = deviceConfiguratorSlice.actions;

/* API Calls */
export const fetchConfigAsync = (apiData, isCopy) => async (dispatch, getState) => {
	try {
		let response = await fetchConfig(apiData);
		if (apiData.api.fetch.onSuccess) response = apiData.api.fetch.onSuccess(response);

		let name = response.name;

		if (isCopy) {
			const copyKey = 'Copy';
			const value = getState().locales.data?.[copyKey];
			name += ` (${value ?? copyKey})`;
		}

		const responseData = isCopy ? { ...response, id: null, name } : response;

		dispatch(fetchConfigSuccess({ response: responseData, configData: apiData.configData }));
		dispatch(setIsLoaded({ loaded: true }));
	} catch (error) {
		dispatch(setIsLoaded({ loaded: false }));
		return await dispatch(addErrorAsync({ slice, error }));
	}
};

export const submitConfigAsync = (params) => async (dispatch, getState) => {
	try {
		const configuratorState = getState().deviceConfigurator;
		const config = {
			deviceConfiguration: {
				...configuratorState.currentData.deviceConfiguration
			},
			deviceId: configuratorState.deviceId
		};

		dispatch(configSubmitPending(true));
		await addConfig({ ...params, config });
		return true;
	} catch (error) {
		dispatch(configSubmitPending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const submitConfigOwnDataAsync = (params) => async (dispatch) => {
	try {
		dispatch(configSubmitPending(true));
		await addConfig(params);
		return true;
	} catch (error) {
		dispatch(configSubmitPending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const submitConfigEditOwnDataAsync = (params) => async (dispatch) => {
	try {
		dispatch(configSubmitPending(true));
		await editConfig(params);
		return true;
	} catch (error) {
		dispatch(configSubmitPending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const fetchDatasFormAsync =
	({
		api,
		deviceTypeName,
		defaultFormValues,
		overrideValues,
		noMeasurementDeviceRegistersToRemove,
		readOnly,
		devicePreview
	}) =>
	async (dispatch) => {
		try {
			dispatch(setDatasFormPending(true));

			const response = await fetchDatasForm({ api, deviceTypeName });
			const prepared = prepareFetchedForm(response, readOnly, devicePreview);

			dispatch(
				fetchDatasFormSuccess({
					response: prepared,
					defaultFormValues,
					overrideValues,
					noMeasurementDeviceRegistersToRemove
				})
			);
		} catch (error) {
			dispatch(setDatasFormPending(false));
			dispatch(addErrorAsync({ slice, error }));
		}
	};

/* Selectors */
export const selectIsLoaded = (state) => state.deviceConfigurator.isLoaded;
export const selectActiveTab = (state) => state.deviceConfigurator.activeTab;
export const selectLastActiveTab = (state) => state.deviceConfigurator.lastActiveTab;

export const selectIsInEditMode = (state) => state.deviceConfigurator.isInEditMode;
export const selectIsInProfileSubmitMode = (state) => state.deviceConfigurator.isInProfileSubmitMode;
export const selectIsInSectionEditMode = (state) => state.deviceConfigurator.isInSectionEditMode;

export const selectDatasForm = createSelector(
	(state) => state.deviceConfigurator,
	(deviceConfigurator) => ({
		datasForm: deviceConfigurator.datasForm,
		fetchDatasFormPending: deviceConfigurator.fetchDatasFormPending,
		deviceConfigurationItems: deviceConfigurator.currentData?.deviceConfiguration?.deviceConfigurationItems
	})
);

export const selectProfileSubmit = createSelector(
	(state) => state.deviceConfigurator,
	(deviceConfigurator) => ({
		id: deviceConfigurator.currentData.id,
		isSystemProfile: deviceConfigurator.currentData.isSystemProfile,
		name: deviceConfigurator.currentData.name,
		description: deviceConfigurator.currentData.description,
		deviceTypeId: deviceConfigurator.deviceTypeId,
		deviceConfiguration: deviceConfigurator.currentData.deviceConfiguration
	})
);

export const selectConfigurationData = (state) => state.deviceConfigurator.configurationData;

export const selectCurrentDataSet = (state) => state.deviceConfigurator.currentDataSet;

export const selectCurrentData = (state) => state.deviceConfigurator.currentData;

export const selectProfileData = (state) => state.deviceConfigurator.profileData;

export const selectSectionsValidationStatus = (state) => state.deviceConfigurator.sectionsValidationStatus;

export const selectReadOnly = (state) => state.deviceConfigurator.readOnly;

export const selectConfigSubmit = (state) => state.deviceConfigurator.submitPending;

export const selectDatasFormRegisters = (state) => state.deviceConfigurator.datasFormRegisters;

export const selectInitialDatasFormRegisters = (state) => state.deviceConfigurator.initialDatasFormRegisters;

export const selectRegisterSelectionMode = (state) => state.deviceConfigurator.registerSelectionMode;

export const selectDsiabledRegisters = (state) => state.deviceConfigurator.disabledRegisters;

export default deviceConfiguratorSlice.reducer;

const onRegistersChange = new Map([['volume_property-Name', onVolumeSetPropertyChange]]);

function tryOnRegistersChange(groupName, value, registers) {
	if (!value) return registers;

	const onChange = onRegistersChange.get(groupName);
	if (!onChange) return registers;

	return onChange(value, registers);
}

function onVolumeSetPropertyChange(value, registers) {
	return registers.map((reg) => {
		if (!['water_meter_type-Name', 'water_meter_type_properties-Name'].includes(reg.group)) return reg;

		return { ...reg, includeInConfiguration: value };
	});
}

function prepareFormConfiguration(form, noMeasurementDeviceRegistersToRemove) {
	const formConfiguration = cloneDeep(form);
	if (!noMeasurementDeviceRegistersToRemove || noMeasurementDeviceRegistersToRemove.length === 0)
		return formConfiguration;

	const { sections } = formConfiguration;

	for (let s of sections) {
		for (let t of s.tabs) {
			t.groups = t.groups.filter((g) => !noMeasurementDeviceRegistersToRemove.includes(g.register));
		}
	}

	return formConfiguration;
}

function getRegistersToRemove(registersToRemove, isEdit, metadata) {
	if (!isEdit) return registersToRemove;

	const hasWaterMeter =
		metadata?.find((fm) => fm.key === 'water_meter_type_property' || fm.Key === 'water_meter_type_property') !=
		null;
	return hasWaterMeter ? [] : registersToRemove;
}

function prepareFetchedForm(response, readOnly, devicePreview) {
	if (readOnly && devicePreview) return devicePreviewForm(response);
	if (readOnly) return previewForm(response);
	return newForm(response);
}

function devicePreviewForm(form) {
	const formConfiguration = cloneDeep(form);

	for (let s of formConfiguration.sections) {
		for (let t of s.tabs) {
			for (let g of t.groups) {
				g.elements = g.elements.filter((e) => e.devicePreview !== false);
			}
		}
	}

	return formConfiguration;
}

function previewForm(form) {
	const formConfiguration = cloneDeep(form);

	for (let s of formConfiguration.sections) {
		for (let t of s.tabs) {
			for (let g of t.groups) {
				g.elements = g.elements.filter((e) => e.devicePreview !== true);
			}
		}
	}

	return formConfiguration;
}

function newForm(form) {
	let formConfiguration = cloneDeep(form);
	formConfiguration = {
		...formConfiguration,
		sections: formConfiguration.sections.filter((s) => s.previewOnly !== true)
	};

	for (let s of formConfiguration.sections) {
		for (let t of s.tabs) {
			for (let g of t.groups) {
				g.elements = g.elements.filter((e) => e.devicePreview !== true);
			}
		}
	}

	return formConfiguration;
}
