export default {
	None: {
		name: 'None',
		value: null
	},
	FromScratch: {
		name: 'FromScratch',
		value: 0
	},
	FromTemplate: {
		name: 'FromTemplate',
		value: 1
	}
};
