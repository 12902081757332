import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '@sonar-web/common/src/hooks';
import React from 'react';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		gap: '1rem',
		marginTop: '1rem',
		'& span': {
			flex: 1
		}
	},
	dot: {
		height: 10,
		width: 10,
		borderRadius: 5,
		marginRight: 8
	},
	read: {
		background: theme.palette.success.main
	},
	notRead: {
		background: '#DB2242'
	},
	partiallyRead: {
		backgroundColor: '#FFCA00'
	},
	noPoints: {
		backgroundColor: '#CACACA'
	}
}));

const Item = ({ className, text }) => (
	<Box display='flex' alignItems='center'>
		<Box className={className} />
		<Typography variant='caption' color='textSecondary'>
			{text}
		</Typography>
	</Box>
);

const BuildingVisualizationLegend = ({ pointsView }) => {
	const classes = useStyles();
	const { translate } = useLocale();

	return (
		<Box className={classes.root}>
			{pointsView ? (
				<>
					<Item className={`${classes.dot} ${classes.read}`} text={translate('Stats_ReadMeasurementSets')} />
					<Item
						className={`${classes.dot} ${classes.notRead}`}
						text={translate('Stats_NotReadMeasurementSets')}
					/>
				</>
			) : (
				<>
					<Item className={`${classes.dot} ${classes.read}`} text={translate('Stats_ReadPremise')} />
					<Item
						className={`${classes.dot} ${classes.partiallyRead}`}
						text={translate('Stats_PartiallyReadPremise')}
					/>
					<Item className={`${classes.dot} ${classes.notRead}`} text={translate('Stats_NotReadPremise')} />
					<Item
						className={`${classes.dot} ${classes.noPoints}`}
						text={translate('Stats_NotMeasuredPremise')}
					/>
				</>
			)}
		</Box>
	);
};

export default BuildingVisualizationLegend;
