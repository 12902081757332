import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Grow, Dialog, Typography, Toolbar } from '@mui/material';
import { Box } from '@mui/system';
import { Close } from '@mui/icons-material';
import { useLocale } from '@sonar-web/common/src/hooks';
import ButtonText from '@sonar-web/common/src/components/Buttons/ButtonText';
import BaseButton from '@sonar-web/common/src/components/Buttons/BaseButton';
import BaseButtonOnlyIcon from '../Buttons/BaseButtonOnlyIcon';

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(5),
		minWidth: 500,
		transition: 'height 500ms ease'
	},
	paperNoMaxWidth: {
		maxWidth: 'none'
	},
	toolbarRoot: {
		marginTop: theme.spacing(3),
		justifyContent: 'flex-end'
	}
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Grow ref={ref} {...props} />;
});

const BaseDialog = ({
	open,
	setOpen,
	title,
	onClose,
	onSubmit,
	cancelText = 'Common_Cancel',
	submitText = 'Common_Confirm',
	actions,
	progress,
	pending,
	children,
	disableSubmit = false,
	disableMaxWidth = false,
	disableBottomToolbar = false,
	closeWithX = false,
	useCancel = true,
	preventOnClick = true,
	dialogProps = {}
}) => {
	const classes = useStyles();
	const { translate } = useLocale();
	const [submitDisabled, setSubmitDisabled] = React.useState(disableSubmit);

	const handleClose = () => {
		if (onClose) onClose();
		else setOpen(false);
	};

	const handleSubmit = () => {
		if (onSubmit) onSubmit();
		else setOpen(false);
	};

	return (
		<Dialog
			onClick={(e) => {
				if (preventOnClick) {
					e.stopPropagation();
					e.preventDefault();
				}
			}}
			open={open}
			onClose={(_, reason) => {
				if (reason === 'backdropClick') return;
				handleClose();
			}}
			maxWidth='md'
			TransitionComponent={Transition}
			transitionDuration={400}
			classes={{ paper: clsx(classes.paper, { [classes.paperNoMaxWidth]: disableMaxWidth }) }}
			{...dialogProps}>
			{title && (
				<Box display='flex' alignItems='center' justifyContent='space-between' marginBottom='.75rem'>
					<Typography variant='h6' fontWeight='normal'>
						{translate(title)}
					</Typography>
					{closeWithX && (
						<BaseButtonOnlyIcon name='dialogClose' onClick={() => setOpen(false)} icon={Close} />
					)}
				</Box>
			)}
			{typeof children === 'function' ? children({ setSubmitDisabled, handleClose }) : children}
			{!disableBottomToolbar && (
				<Toolbar disableGutters variant='dense' classes={{ root: classes.toolbarRoot }}>
					{actions ? (
						actions()
					) : (
						<>
							{useCancel && (
								<ButtonText name='baseDialogCancel' onClick={handleClose}>
									{cancelText}
								</ButtonText>
							)}
							<BaseButton
								name='baseDialogSubmit'
								progress={progress}
								pending={pending}
								disabled={submitDisabled}
								size='small'
								onClick={handleSubmit}
								style={{ marginLeft: 10 }}>
								{submitText}
							</BaseButton>
						</>
					)}
				</Toolbar>
			)}
		</Dialog>
	);
};

export default BaseDialog;
