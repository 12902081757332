import React from 'react';
import BaseIcon from './BaseIcon';

export default function FiltersActiveIcon(props) {
	return (
		<BaseIcon
			{...props}
			renderMultiPath={({ primaryPathClass, primaryPathColor, secondaryPathClass, secondaryPathColor }) => (
				<>
					<path
						className={primaryPathClass}
						style={{ color: primaryPathColor }}
						d='M 4.274286,3.0414474 C 3.892331,3.0407249 3.5398845,3.2566098 3.3654325,3.5961349 c -0.175558,0.3415555 -0.144624,0.754275 0.080729,1.0677085 h -0.0026 L 9.105019,12.640406 c 8.5e-5,1.19e-4 0.0064,0.0034 0.0078,0.0052 0.0012,0.0012 0.0014,0.0041 0.0026,0.0052 0.168022,0.226843 0.25985,0.501485 0.260417,0.786459 v 6.500001 c -7.71e-4,0.27 0.10434,0.530222 0.294271,0.721352 0.191383,0.192608 0.453476,0.296876 0.721354,0.296876 0.133619,0 0.266083,-0.02284 0.393229,-0.07552 l 3.158855,-1.203125 c 0.412601,-0.125853 0.679688,-0.523309 0.679688,-0.960937 v -0.5 C 14.086198,17.976534 13.60384,17.65004 13.162308,17.27323 v 1.132812 l -2.325522,0.88801 v -5.856771 c -0.0011,-0.593496 -0.187449,-1.175038 -0.539063,-1.653646 l -0.0052,-0.0026 -5.166667,-7.2760426 h 13.747402 l -1.484375,2.0885417 c 0.553359,0.026669 1.080949,0.13218 1.585937,0.296875 l 1.58073,-2.2265626 h -0.0027 C 20.778411,4.3504514 20.806625,3.9377408 20.63104,3.5961349 20.456636,3.2567048 20.106846,3.040725 19.72479,3.0414474 Z'
					/>
					<path
						className={secondaryPathClass}
						style={{ color: secondaryPathColor }}
						d='m 21.108182,12.423529 c 0,2.550877 -2.067893,4.618771 -4.61877,4.618771 -2.550877,0 -4.61877,-2.067894 -4.61877,-4.618771 0,-2.5508759 2.067893,-4.6187696 4.61877,-4.61877 2.550877,4e-7 4.61877,2.0678941 4.61877,4.61877 z'
					/>
				</>
			)}
		/>
	);
}
