import { useEffect } from 'react';
import { moduleName } from './constants';
import { useDeps } from '@sonar-web/common/src/features/Dependencies/depsContext';
import useMeasurementSetsDeps from './Dependencies/useMeasurementSetsDeps';
import useCoreDeps from './Dependencies/useCoreDeps';

const RegisterWmbusModule = () => {
	const { setDeps, targetModules } = useDeps();
	const measurementSetsDeps = useMeasurementSetsDeps();
	const coreDeps = useCoreDeps();

	useEffect(() => {
		setDeps(targetModules.measurementSet, moduleName, {
			measurementSetDevice: {
				addDevice: measurementSetsDeps.addDevice,
				fetchDeviceTypeById: measurementSetsDeps.fetchDeviceTypeById
			}
		});

		setDeps(targetModules.core, moduleName, {
			deviceTypes: {
				sidebarType: coreDeps.sidebarType,
				sidebarComponents: coreDeps.sidebarComponents,
				fetchDeviceTypes: coreDeps.fetchDeviceTypes,
				deleteDeviceTypeAsync: coreDeps.deleteDeviceTypeAsync,
				selectDeleteDeviceTypePending: coreDeps.selectDeleteDeviceTypePending,
				resetDeviceTypeData: coreDeps.resetDeviceTypeData
			}
		});
	}, []);

	return null;
};

export default RegisterWmbusModule;
