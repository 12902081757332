import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { Breadcrumbs, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Typography from '@mui/material/Typography';
import { HomeIcon } from '@sonar-web/common';
import { useLocale } from '@sonar-web/common/src/hooks';
import {
	selectBreadcrumbs,
	setSelectedBreadcrumbIndex,
	resetToBreadcrumb,
	setResetAndReload,
	selectSelectedBreadcrumbIndex,
	selectSelectedBreadcrumb
} from './slice';
import { setAvailableLocationNodeLevels, selectAvailableLocationNodeLevels } from '../LocationNodeLevels/slice';
import LocationNodeAddMenu from '../LocationNode/LocationNodeAddMenu';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: `0px 0px ${theme.spacing(1)} 0px`
	},
	ol: {
		minHeight: 42
	},
	separator: {
		margin: 0
	},
	itemRoot: {
		display: 'flex',
		flexDirection: 'column',
		padding: theme.spacing(0, 1),
		cursor: 'pointer'
	},
	nameRoot: {
		fontWeight: theme.typography.fontWeightMedium,
		color: theme.palette.text.secondary
	},
	nameRootLast: {
		color: theme.palette.text.primary
	},
	levelRoot: {
		marginTop: theme.spacing(-1) + 2
	}
}));

const LocationNodesBreadcrumb = ({
	isPanel = true,
	disableSubmitMany,
	nodeAddOnClose,
	submitActionTooltip = 'Common_Confirm'
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { translate } = useLocale();
	const breadcrumbsNodes = useSelector(selectBreadcrumbs);
	const breadcrumb = useSelector(selectSelectedBreadcrumb);
	const selectedBreadcrumbIndex = useSelector(selectSelectedBreadcrumbIndex);
	const availableNodeLevels = useSelector(selectAvailableLocationNodeLevels);
	const canAddChildren = isPanel ? availableNodeLevels.length > 0 : false;

	const handleClick = (index) => {
		if (selectedBreadcrumbIndex === index) dispatch(setResetAndReload());
		else dispatch(resetToBreadcrumb(index));
		dispatch(setSelectedBreadcrumbIndex(index));
	};

	React.useEffect(() => {
		if (!isPanel) return;

		const nodeLevel = breadcrumbsNodes[selectedBreadcrumbIndex];
		dispatch(setAvailableLocationNodeLevels(nodeLevel));
	}, [selectedBreadcrumbIndex]);

	return (
		<Breadcrumbs
			maxItems={9}
			separator={<ChevronRightIcon fontSize='small' color='disabled' />}
			className={classes.root}
			classes={{ separator: classes.separator, ol: classes.ol }}
			aria-label='breadcrumb'
			data-at-params={`currentBreadcrumb|${
				breadcrumb.level ? `${breadcrumb.level.type}|${breadcrumb.name}` : 'root'
			}`}>
			{breadcrumbsNodes.map((node, index) => {
				const isLast = index === breadcrumbsNodes.length - 1;

				if (node.locationNodeLevelId === null)
					return (
						<IconButton
							name='breadcrumb_item_home'
							size='small'
							key={index}
							onClick={() => handleClick(index)}>
							<HomeIcon fontSize='xs' />
						</IconButton>
					);
				else {
					return (
						<div key={node.id} className={classes.itemRoot} onClick={() => handleClick(index)}>
							<Typography
								variant='subtitle1'
								data-at-params={`breadcrumbItem|${node.level.type}|${node.name}`}
								className={clsx(classes.nameRoot, {
									[classes.nameRootLast]: isLast
								})}>
								{node.name}
							</Typography>
							<Typography variant='caption' classes={{ root: classes.levelRoot }}>
								{translate(node.level.name)}
							</Typography>
						</div>
					);
				}
			})}
			{canAddChildren && (
				<LocationNodeAddMenu
					submitActionTooltip={submitActionTooltip}
					currentBreadcrumb={breadcrumb}
					disableSubmitMany={disableSubmitMany}
					nodeAddOnClose={nodeAddOnClose}
				/>
			)}
		</Breadcrumbs>
	);
};

export default LocationNodesBreadcrumb;
