import React from 'react';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '../../hooks';
import EmptyListIcon from '../../icons/EmptyListIcon';

const useStyles = makeStyles((theme) => ({
	textRoot: {
		marginBottom: theme.spacing(2),
		whiteSpace: 'break-spaces',
		textAlign: 'center'
	},
	iconRoot: {
		color: '#b8b8b8'
	}
}));

const ListNoRecords = ({ show, text, icon, action, ...rest }) => {
	const classes = useStyles();
	const { translate } = useLocale();

	if (!show) return null;

	return (
		<Box
			display='flex'
			flexDirection='column'
			justifyContent='center'
			alignItems='center'
			flex={1}
			height='100%'
			{...rest}>
			{icon ?? <EmptyListIcon fontSize='xxl' classes={{ root: classes.iconRoot }} />}
			<Typography
				variant='body2'
				color='textSecondary'
				classes={{ root: classes.textRoot }}
				data-at='listNoRecords'>
				{translate(text)}
			</Typography>
			{action ? action : null}
		</Box>
	);
};

export default ListNoRecords;
