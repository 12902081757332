import FetchTimeLicenses from '../TimeLicense/FetchTimeLicenses';
import TenantsSelector from './TenantsSelector';

const TenantsPage = (props) => {
	return (
		<FetchTimeLicenses>
			{(licensesProps) => <TenantsSelector {...props} licensesProps={licensesProps} />}
		</FetchTimeLicenses>
	);
};

export default TenantsPage;
