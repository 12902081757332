import React from 'react';
import { RecycleBinIcon } from '../../icons';
import PanelListItemAction from './PanelListItemAction';

const PanelListItemActionDelete = ({ onClick, show = true, ...rest }) => {
	return (
		<PanelListItemAction
			icon={<RecycleBinIcon fontSize='small' />}
			tooltip={'Common_Delete'}
			onClick={onClick}
			disabled={!show}
			{...rest}
		/>
	);
};

export default PanelListItemActionDelete;
