import { createSelector, createSlice } from '@reduxjs/toolkit';
import { addErrorAsync } from '../Errors/errorsSlice';
import { parseCronStringEntries } from './cronParser';
import { addSchedule, fetchSchedule, deleteSchedule, updateSchedule } from './schedulesApi';

const slice = 'schedule';

const getSchedule = (getState) => getState().schedule.schedule;

const initialState = {
	schedule: {},
	fetchPending: false,
	pending: false,
	deletePending: false,
	deleteSuccess: false,
	isValid: false
};

export const scheduleSlice = createSlice({
	name: slice,
	initialState,
	reducers: {
		setFetchSuccess: (state, { payload }) => {
			state.fetchPending = false;
			state.resetList = false;

			if (payload.entries.length > 0) {
				state.schedule = { ...payload, parsed: parseCronStringEntries(payload.entries) };
				state.isValid = true;
			} else {
				state.schedule = {};
				state.isValid = false;
			}
		},
		setIsValid: (state, { payload }) => {
			state.isValid = payload;
		},
		setSchedule: (state, { payload }) => {
			state.schedule = payload;
		},
		setFetchPending: (state, { payload }) => {
			state.fetchPending = payload;
		},
		setPending: (state, { payload }) => {
			state.pending = payload;
		},
		setDeleteSchedulePending: (state, { payload }) => {
			state.deleteSchedulePending = payload;
		},
		deleteScheduleSuccess: (state) => {
			state.deletePending = false;
			state.deleteSuccess = true;
		},
		resetAndReloadSchedules: (state) => {
			state.fetchPending = false;
			state.deletePending = false;
			state.deleteSuccess = false;
			state.schedule = {};
		},
		resetSchedule: (state) => {
			state.schedule = {};
		}
	}
});

export const {
	setIsValid,
	setSchedule,
	resetSchedule,
	setFetchSuccess,
	setFetchPending,
	setPending,
	setDeleteSchedulePending,
	deleteScheduleSuccess,
	resetAndReloadSchedules
} = scheduleSlice.actions;

export const selectDeleteSchedulePending = createSelector(
	(state) => state.schedule,
	(schedule) => ({
		deletePending: schedule.deletePending,
		deleteSuccess: schedule.deleteSuccess
	})
);

export const selectSchedule = createSelector(
	(state) => state.schedule,
	(schedule) => ({
		schedule: schedule.schedule,
		isValid: schedule.isValid
	})
);

export const addScheduleAsync = () => async (dispatch, getState) => {
	try {
		const data = { ...getSchedule(getState) };

		dispatch(setPending(true));
		await addSchedule(data);
	} catch (error) {
		return dispatch(addErrorAsync({ slice, error }));
	}
	dispatch(setPending(false));
};

export const fetchScheduleAsync = (data) => async (dispatch) => {
	try {
		dispatch(setFetchPending(true));

		const response = await fetchSchedule(data);

		dispatch(setFetchSuccess(response));
	} catch (error) {
		dispatch(setFetchPending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const deleteScheduleAsync = (id) => async (dispatch) => {
	try {
		dispatch(setDeleteSchedulePending(true));
		await deleteSchedule(id);
		dispatch(deleteScheduleSuccess());
	} catch (error) {
		dispatch(setDeleteSchedulePending(false));
		return dispatch(addErrorAsync({ slice, error }));
	}
};

export const updateScheduleAsync = () => async (dispatch, getState) => {
	try {
		dispatch(setPending(true));
		await updateSchedule(getSchedule(getState));
		dispatch(setPending(false));
	} catch (error) {
		dispatch(setPending(false));
		return dispatch(addErrorAsync({ slice, error }));
	}
};

export default scheduleSlice.reducer;
