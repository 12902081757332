import React, { lazy, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { AuthProvider } from 'react-oidc-context';
import NotificationContainer from '@sonar-web/common/src/components/Notification/NotificationContainer';
import { BaseLinearProgress } from '@sonar-web/common';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { onSigninCallback, userManager } from './config';
import store from './store';
import TranslationsLoader from './features/Translations/TranslationsLoader';

const Main = lazy(() => import('./Main'));

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
	<AuthProvider userManager={userManager} onSigninCallback={onSigninCallback}>
		<React.StrictMode>
			<Provider store={store}>
				<TranslationsLoader>
					<NotificationContainer />
					<App>
						<Suspense fallback={<BaseLinearProgress pending={true} />}>
							<Main />
						</Suspense>
					</App>
				</TranslationsLoader>
			</Provider>
		</React.StrictMode>
	</AuthProvider>
);

serviceWorker.unregister();
