import React from 'react';
import { Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
	({ theme, tooltipBackground, tooltipTextColor, tooltipBorder }) => ({
		'& .MuiTooltip-tooltip': {
			marginTop: 15,
			backgroundColor: tooltipBackground,
			fontSize: theme.typography.pxToRem(12),
			fontWeight: theme.typography.fontWeightLight,
			padding: 10,
			borderRadius: 8,
			color: tooltipTextColor,
			border: tooltipBorder
		},
		'& .MuiTooltip-arrow': {
			color: tooltipBackground,
			'&:before': {
				border: tooltipBorder
			}
		},
		zIndex: 1
	})
);

const TimelineBottomTooltip = ({ children, title, text, tooltipBackground, tooltipTextColor, tooltipBorder }) => {
	return (
		<StyledTooltip
			tooltipBackground={tooltipBackground}
			tooltipTextColor={tooltipTextColor}
			tooltipBorder={tooltipBorder}
			zindex={1}
			title={
				<React.Fragment>
					<Typography variant='body2'>{title}</Typography>
					{text}
				</React.Fragment>
			}
			arrow
			placement='bottom'
			open={true}>
			<div>{children}</div>
		</StyledTooltip>
	);
};

export default TimelineBottomTooltip;
