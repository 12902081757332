import { createSlice } from '@reduxjs/toolkit';
import { fetchDeviceType, addDeviceType, editDeviceType, deleteDeviceType } from './deviceTypeApi';
import { setResetAndReload } from '../Devices/devicesSlice';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
const slice = 'wmbusDeviceType';
const getDeviceType = (getState) => getState().wmbusDeviceType.deviceType;

const initialDeviceData = {
	id: null,
	name: '',
	sw: '',
	hw: '',
	mField: '',
	protocol: 'OMS-4',
	typeGroup: 'WaterMeterTransmissionDevices',
	inputInterfaceTypes: ['Optical'],
	isAesRequired: false
};

export const wmbusDeviceTypeSlice = createSlice({
	name: slice,
	initialState: {
		addPending: false,
		addSuccess: false,
		fetchPending: false,
		fetchSuccess: false,
		deletePending: false,
		deleteSuccess: false,
		deviceType: initialDeviceData
	},
	reducers: {
		fetchDeviceTypeSucces: (state, action) => {
			let deviceType = action.payload;

			deviceType.sw = deviceType.sw && deviceType.sw.length > 0 ? deviceType.sw[0] : '';
			deviceType.hw = deviceType.hw ? deviceType.hw : '';
			state.deviceType = {
				...initialDeviceData,
				...deviceType
			};

			state.fetchPending = false;
			state.fetchSuccess = true;
		},
		fetchDeviceTypePending: (state) => {
			state.fetchPending = true;
			state.fetchSuccess = false;
		},
		addDeviceTypeSucces: (state, action) => {
			state.addPending = false;
			state.addSuccess = action.payload;
		},
		addDeviceTypePending: (state, action) => {
			state.addPending = action.payload ?? true;
		},
		deleteDeviceTypeSucces: (state) => {
			state.deletePending = false;
			state.deleteSuccess = true;
		},
		deleteDeviceTypePending: (state, action) => {
			state.deletePending = action.payload ?? true;
		},
		setDeviceTypeData: (state, action) => {
			state.deviceType = action.payload;
		},
		resetDeviceTypeData: (state, action) => {
			const { isInSubmitManyMode } = action.payload || {};

			state.deviceType = isInSubmitManyMode
				? {
						...initialDeviceData,
						...state.deviceType
				  }
				: initialDeviceData;
			state.addSuccess = false;
			state.deleteSuccess = false;
			state.fetchSuccess = false;
		},
		resetAddSuccess: (state) => {
			state.addSuccess = false;
		}
	}
});

export const {
	fetchDeviceTypeSucces,
	fetchDeviceTypePending,
	addDeviceTypeSucces,
	addDeviceTypePending,
	deleteDeviceTypeSucces,
	deleteDeviceTypePending,
	setDeviceTypeData,
	resetDeviceTypeData,
	resetAddSuccess
} = wmbusDeviceTypeSlice.actions;

export const fetchDeviceTypeAsync = (id) => async (dispatch) => {
	let response;

	try {
		dispatch(fetchDeviceTypePending());
		response = await fetchDeviceType(id);
	} catch (error) {
		dispatch(fetchDeviceTypePending(false));
		dispatch(addErrorAsync({ slice, error }));
		return;
	}

	dispatch(fetchDeviceTypeSucces(response));
};

export const addDeviceTypeAsync = () => async (dispatch, getState) => {
	let deviceType = { ...getDeviceType(getState) };
	let response;
	let result = Object.assign({}, deviceType);

	deviceType.sw = [parseInt(deviceType.sw, 10)];
	deviceType.hw = parseInt(deviceType.hw, 10);

	try {
		dispatch(addDeviceTypePending());

		response = await addDeviceType(deviceType);
	} catch (error) {
		dispatch(addDeviceTypePending(false));

		const errorResult = await dispatch(
			addErrorAsync({ slice, error, skipNotificationMembers: ['name', 'hw', 'sw', 'mField', 'protocol'] })
		);

		return errorResult;
	}

	result.id = response.id;

	dispatch(addDeviceTypeSucces(response.id ? result : true));
};

export const editDeviceTypeAsync = () => async (dispatch, getState) => {
	const deviceType = getDeviceType(getState);

	try {
		dispatch(addDeviceTypePending());
		await editDeviceType({ ...deviceType, sw: [parseInt(deviceType.sw, 10)], hw: parseInt(deviceType.hw, 10) });
		dispatch(addDeviceTypeSucces(true));
	} catch (error) {
		dispatch(addDeviceTypePending(false));

		return dispatch(
			addErrorAsync({ slice, error, skipNotificationMembers: ['name', 'hw', 'sw', 'mField', 'protocol'] })
		);
	}
};

export const deleteDeviceTypeAsync = (id) => async (dispatch) => {
	try {
		dispatch(deleteDeviceTypePending());
		await deleteDeviceType(id);
	} catch (error) {
		dispatch(deleteDeviceTypePending(false));
		const errorResult = await dispatch(addErrorAsync({ slice, error }));

		return errorResult;
	}

	dispatch(deleteDeviceTypeSucces());
	dispatch(setResetAndReload());
};

export const selectDeviceType = (state) => {
	return {
		dataRow: state.wmbusDeviceType.deviceType,
		fetchPending: state.wmbusDeviceType.fetchPending,
		fetchSuccess: state.wmbusDeviceType.fetchSuccess
	};
};

export const selectDeviceTypeAdd = (state) => {
	return {
		addPending: state.wmbusDeviceType.addPending,
		addSuccess: state.wmbusDeviceType.addSuccess
	};
};

export const selectDeleteDeviceTypePending = (state) => {
	return {
		deletePending: state.wmbusDeviceType.deletePending,
		deleteSuccess: state.wmbusDeviceType.deleteSuccess
	};
};

export default wmbusDeviceTypeSlice.reducer;
