import { lazy } from 'react';
import { Features } from '@sonar/auth';

const HeatallocatorPage = lazy(() => import('./Heatallocator/HeatallocatorPage'));

export default [
	{
		element: HeatallocatorPage,
		feature: Features.HeatallocatorPage.name,
		path: `heatallocators/:id`,
		exact: true,
		name: '{0}'
	}
];
