import React, { useEffect, useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Fade } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useLocale from '@sonar-web/common/src/hooks/useLocale';
import ConfigurationDatasGrid from './ConfigurationDatasGrid';
import ConfigurationDatasContext from './ConfigurationDatasContext';
import DeviceConfiguratorCover from '../DeviceConfiguratorCover';
import DatasForm from './DatasForm';
import ConfigurationDatasHeader from './ConfigurationDatasHeader';
import MultiActionButton from '../../../../components/Buttons/MultiActionButton';
import useConfiguratorParams from '../useConfiguratorParams';

const useStyles = makeStyles((theme) => ({
	editModeWrapperRoot: {
		padding: 0,
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: theme.spacing(1.25),
		marginBottom: theme.spacing(2)
	}
}));

const DeviceConfigurationDatas = ({
	deviceData,
	configData,
	disableProfiles,
	disableHeader,
	configuratorActions,
	defaultFormValues,
	overrideValues, //Dane, którymi można nadpisać nawet te wczytane do edycji - użyte dla wyboru wodomierza w kopiowaniu. W przeciwnym wypadku brane są zawsze dane z pobranej konfiguracji
	noMeasurementDeviceRegistersToRemove, //tablica numerów rejestrów, które mają być usunięte z konfiguratora
	hasBottomBar,
	readOnly
}) => {
	const classes = useStyles();
	const { translate } = useLocale();
	const dispatch = useDispatch();
	const { devicePreview } = useConfiguratorParams();
	const { api, handleShowProfiles } = useContext(ConfigurationDatasContext);
	const [selectedSection, setSelectedSection] = useState();
	const [reload, setReload] = useState(false);

	const profileData = configuratorActions.getCurrentData();
	const profileId = profileData?.id;
	const datas = configuratorActions.datasForm?.sections;
	const isSectionEditorVisible = configuratorActions.isInSectionEditMode && selectedSection;

	useEffect(() => {
		configuratorActions.toggleSectionEditMode(false);

		dispatch(
			configuratorActions.fetchDatasFormAsync({
				api,
				deviceTypeName: deviceData.deviceTypeName,
				defaultFormValues,
				overrideValues,
				noMeasurementDeviceRegistersToRemove,
				readOnly,
				devicePreview
			})
		);
	}, [deviceData.id, reload]);

	const handleEditConfigurationData = (section) => {
		setSelectedSection(section);
		configuratorActions.toggleSectionEditMode(true);
	};

	const loadDefaultConfig = () => {
		configuratorActions.enterManualMode();
		setReload(!reload);
	};

	return (
		<>
			{isSectionEditorVisible && (
				<Box position='relative' height='100%'>
					<DeviceConfiguratorCover style={{ paddingBottom: hasBottomBar ? 10 : 0 }}>
						<DatasForm section={selectedSection} />
					</DeviceConfiguratorCover>
				</Box>
			)}
			<Fade in={!isSectionEditorVisible} enter={false}>
				<Box
					position='relative'
					display='flex'
					flexDirection='column'
					overflow='auto'
					flex={1}
					pr={1}
					pt={!isSectionEditorVisible ? 3 : 0}>
					{!disableHeader && <ConfigurationDatasHeader profileData={profileData} configData={configData} />}
					{!disableProfiles && (
						<Box className={classes.editModeWrapperRoot}>
							<MultiActionButton
								options={[
									{
										elementName: 'DeviceConfigurator_ActionSelectProfile',
										value: translate('Common_Configurator_ChooseProfile'),
										onSelect: handleShowProfiles
									},
									{
										elementName: 'DeviceConfigurator_ActionSelectDefaultConfiuration',
										value: translate('Common_Configurator_LoadDefaultConfiguration'),
										onSelect: loadDefaultConfig
									}
								]}
								menuButtonName='DeviceConfigurator_ActionSelectMore'
							/>
						</Box>
					)}
					<ConfigurationDatasGrid
						configuratorActions={configuratorActions}
						profileId={profileId}
						records={datas}
						handleEditConfigurationData={handleEditConfigurationData}
					/>
				</Box>
			</Fade>
		</>
	);
};

export default DeviceConfigurationDatas;
