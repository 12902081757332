import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectDeleteLocationNodePending, deleteLocationNodeAsync } from './slice';
import DeletePage from '@sonar-web/common/src/features/DeletePage/DeletePage';

const LocationNodeDelete = ({ data, onSuccess, onCancel }) => {
	const dispatch = useDispatch();
	const { deletePending } = useSelector(selectDeleteLocationNodePending);

	const handleSubmit = () => {
		dispatch(deleteLocationNodeAsync(data.id)).then((err) => {
			if (!err) onSuccess();
		});
	};

	return (
		<DeletePage
			primaryText='LocationNodes_LocationNodeDeleteQ'
			primaryValue={data.name}
			onCancel={onCancel}
			onSubmit={handleSubmit}
			pending={deletePending}
		/>
	);
};

export default LocationNodeDelete;
