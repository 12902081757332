import React from 'react';
import BaseIcon from './BaseIcon';

export default function FiltersIcon(props) {
	return (
		<BaseIcon {...props}>
			<path d='m 4.2746203,3.0433978 c -0.381956,-7.227e-4 -0.732984,0.2146344 -0.907437,0.5541595 -0.175558,0.3415554 -0.145695,0.7533236 0.07966,1.0667571 h -0.0035 l 5.662904,7.9764336 c 8.5e-5,1.19e-4 0.0055,0.0051 0.0069,0.0069 0.0012,0.0012 0.0023,0.0023 0.0035,0.0035 0.168021,0.226843 0.259196,0.501241 0.259763,0.786214 v 6.500985 c -7.71e-4,0.270003 0.104466,0.529277 0.294396,0.720408 0.191383,0.192606 0.4525297,0.297859 0.7204077,0.297859 0.133619,0 0.267693,-0.02351 0.39484,-0.0762 l 3.158722,-1.201833 c 0.412602,-0.125852 0.678845,-0.525225 0.678845,-0.962853 v -5.278367 c 4.25e-4,-0.284482 0.09179,-0.55926 0.259761,-0.786214 0.0012,-0.0011 0.0023,-0.0023 0.0035,-0.0035 0.0015,-0.0018 0.0069,-0.0068 0.0069,-0.0069 l 5.662817,-7.9764331 h -0.0035 c 0.2255,-0.313392 0.255245,-0.7251513 0.07966,-1.0667571 -0.174405,-0.3394302 -0.52538,-0.554882 -0.907436,-0.5541595 z m 0.852019,1.4615956 H 18.873258 l -5.171,7.2802706 c -0.35138,0.478547 -0.539159,1.058528 -0.540306,1.652088 v 4.970119 l -2.324007,0.886654 v -5.856773 c -0.0011,-0.593497 -0.188691,-1.17348 -0.540305,-1.652088 l -0.0035,-0.0035 z' />
		</BaseIcon>
	);
}
