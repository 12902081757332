import React from 'react';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { fillDecimalPlaces, TableListColumn } from '@sonar-web/common';
import { useLocale } from '@sonar-web/common/src/hooks';

const useStyles = makeStyles((theme) => ({
	reverseVolumeLabel: {
		color: theme.palette.text.secondary
	}
}));

const ReadValuesColumn = ({ volume, reverseVolume, flex = false }) => {
	const classes = useStyles();
	const { translate } = useLocale();

	return (
		<TableListColumn flex={flex} width={flex ? 'auto' : 200}>
			<Box display='flex' flexDirection='column' alignItems='flex-end'>
				<Typography variant='body2' color='textPrimary' data-at='volume'>
					{fillDecimalPlaces(volume, 3)}
				</Typography>
				{reverseVolume !== null && (
					<Typography variant='caption' color='textPrimary' data-at='reverseVolume'>
						<span className={classes.reverseVolumeLabel}>{translate('Watermeters_ReverseVolume')}</span>{' '}
						{fillDecimalPlaces(reverseVolume, 3)}
					</Typography>
				)}
			</Box>
		</TableListColumn>
	);
};

export default ReadValuesColumn;
