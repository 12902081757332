import { useSelector } from 'react-redux';
import { useLocale } from '@sonar-web/common/src/hooks';
import { selectLocationNodeLevels } from './slice';

const LocationNodeLevelName = ({ locationNodeLevelId }) => {
	const { translate } = useLocale();
	const locationNodeLevels = useSelector(selectLocationNodeLevels);

	if (!locationNodeLevels?.length) return '';

	const name = locationNodeLevels.find((lnl) => lnl.id === locationNodeLevelId)?.name;
	return translate(name);
};

export default LocationNodeLevelName;
