import { createSelector, createSlice } from '@reduxjs/toolkit';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import SetsService from './SetsService';
import { SLICES } from './Constants';
import MeasuringKind from '@sonar-web/common/src/enums/MeasuringKind';

const slice = SLICES.sets;

const initialPairData = {
	inputDevice: null,
	outputDevice: null,
	correction: null,
	startReadValue: null,
	secondStartReadValue: null,
	endReadValue: null,
	secondEndReadValue: null,
	sealNumbers: null,
	orientation: null,
	inputInterfaceType: null,
	transmissionDeviceEnabled: false,
	measurementInterfacesPending: false
};

const initialState = {
	pending: false,
	currentSet: null,
	historySets: null
};

export const setsSlice = createSlice({
	name: slice,
	initialState,
	reducers: {
		fetchSetsSucces: (state, { payload }) => {
			const fetched = prepareFetchedData(payload);
			if (!fetched) {
				for (let key in initialState) {
					state[key] = initialState[key];
				}
				return;
			}

			state.currentSet = fetched.currentSet;
			state.historySets = fetched.historySets;
			state.pending = false;
		},
		fetchSetsForDeviceSucces: (state, { payload }) => {
			if (!payload || payload.length === 0) {
				for (let key in initialState) {
					state[key] = initialState[key];
				}
				return;
			}

			const setsByLocation = payload.map((sets) => prepareFetchedData(sets));
			const currentSet = setsByLocation.find((sets) => sets.currentSet)?.currentSet || null;
			const historySets = setsByLocation.reduce((acc, sets) => {
				if (sets.historySets) acc.push(...sets.historySets);
				return acc;
			}, []);

			state.currentSet = currentSet;
			state.historySets = historySets;
			state.pending = false;
		},
		fetchSetsPending: (state, { payload }) => {
			state.pending = payload;
		},
		resetSets: (state) => {
			for (let key in initialState) {
				state[key] = initialState[key];
			}
		},
		fetchMeasurementInterfacesPending: (state, action) => {
			state.measurementInterfacesPending = action.payload;
		}
	}
});

export const {
	fetchSetsSucces,
	fetchSetsForDeviceSucces,
	fetchSetsPending,
	resetSets,
	fetchMeasurementInterfacesPending
} = setsSlice.actions;

export const fetchSetsAsync = (locationId) => async (dispatch) => {
	try {
		dispatch(fetchSetsPending(true));
		const response = await SetsService.fetchSets(locationId);
		dispatch(fetchSetsSucces(response));
	} catch (error) {
		dispatch(fetchSetsPending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const fetchSetsForDeviceAsync = (locationId) => async (dispatch) => {
	try {
		dispatch(fetchSetsPending(true));
		const response = await SetsService.fetchSetsForDevice(locationId);
		dispatch(fetchSetsForDeviceSucces(response));
	} catch (error) {
		dispatch(fetchSetsPending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const fetchMeasurementInterfacesAsync = (locationId) => async (dispatch) => {
	try {
		dispatch(fetchMeasurementInterfacesPending(true));
		const response = await SetsService.fetchMeasurementInterfaces(locationId);
		dispatch(fetchMeasurementInterfacesPending(false));
		return response;
	} catch (error) {
		dispatch(fetchMeasurementInterfacesPending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const selectSets = createSelector(
	(state) => state.sets,
	(sets) => ({
		pending: sets.pending,
		current: sets.currentSet,
		history: sets.historySets
	})
);

export const selectSelected = (state) => state.sets.selected;

export default setsSlice.reducer;

const prepareFetchedData = (sets) => {
	if (!sets) return null;

	const locationData = {
		locationId: sets.locationId,
		addressDisplayName: sets.addressDisplayName
	};

	let currentSet = null;
	if (sets.measurementSetCurrent)
		currentSet = {
			...locationData,
			startDate: sets.measurementSetCurrent.startDate,
			endDate: sets.measurementSetCurrent.endDate,
			measurementPairs: prepareSetPairs(sets.measurementSetCurrent.measurementPairs)
		};

	const historySets = sets.measurementSetHistory.map((set) => ({
		...locationData,
		startDate: set.startDate,
		endDate: set.endDate,
		measurementPairs: prepareSetPairs(set.measurementPairs)
	}));

	return { currentSet, historySets };
};

const getDeviceObject = (device) => {
	if (!device) return null;
	return {
		id: device.id,
		number: device.number,
		typeName: device.typeName,
		groupTypeName: device.groupTypeName,
		interfaceType: device.interfaceType,
		interfaceNumber: device.interfaceNumber,
		sourceService: device.sourceService
	};
};

const getPairObject = (pair) => {
	return {
		...initialPairData,
		inputDevice: getDeviceObject(pair.inputDevice),
		outputDevice: getDeviceObject(pair.outputDevice),
		startReadValue: pair.inputDevice.startReadValue,
		secondStartReadValue: pair.inputDevice.secondStartReadValue,
		endReadValue: pair.inputDevice.endReadValue,
		secondEndReadValue: pair.inputDevice.secondEndReadValue,
		sealNumbers: pair.inputDevice.sealNumbers,
		orientation: pair.inputDevice.orientation,
		correction: pair.correction,
		inputInterfaceType: pair.inputInterfaceType,
		transmissionDeviceEnabled: pair.outputDevice !== null && pair.inputDevice !== null,
		measuringKind: pair.measuringKind
	};
};

const prepareSetPairs = (setPairs) => {
	const pairs = sortByMeasuringKind(setPairs);

	if (pairs.length === 1) {
		//obejście na zwrotkę pary wodomierz nakładka, która przychodzi jako jeden obiekt {input,output} zamiast dwóch {input} {input,output}
		if (pairs[0].inputDevice && pairs[0].outputDevice)
			return [
				getPairObject({
					...pairs[0],
					inputDevice: pairs[0].outputDevice,
					outputDevice: pairs[0].inputDevice
				})
			];
		return [getPairObject(pairs[0])];
	}

	const groupByDeviceId = pairs.reduce((acc, pair) => {
		const watermeterId = pair.outputDevice ? pair.outputDevice.id : pair.inputDevice.id;
		if (!acc[watermeterId]) acc[watermeterId] = [pair];
		else acc[watermeterId].push(pair);
		return acc;
	}, {});

	const mappedPairs = Object.values(groupByDeviceId).map((devicePairs) => {
		if (devicePairs.length === 1) return getPairObject(devicePairs[0]);

		const sortedPairs = devicePairs.slice().sort((a) => {
			if (a.inputDevice && !a.outputDevice) return -1;
			return 1;
		});

		return getPairObject({
			...sortedPairs[0],
			inputDevice: sortedPairs[0].inputDevice,
			outputDevice: sortedPairs[1].inputDevice
		});
	});

	return mappedPairs;
};

const sortByMeasuringKind = (pairs) => {
	if (pairs.length === 1) return pairs;

	const measuringKindOrder = [
		null,
		undefined,
		MeasuringKind.Aggregated.name,
		MeasuringKind.Main.name,
		MeasuringKind.Additional.name,
		MeasuringKind.Standard.name
	];

	return pairs.slice().sort((a, b) => {
		return measuringKindOrder.indexOf(a.measuringKind) - measuringKindOrder.indexOf(b.measuringKind);
	});
};
