import React, { useEffect, useState } from 'react';
import { FormControl, FormControlLabel, Switch } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '../../../../hooks';
import { BaseDateTimePicker, dateAsIsoStringLocal, dateAsIsoString } from '../../../..';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
	formControlRoot: {
		display: 'none'
	},
	formControlLabelRoot: {
		marginLeft: -6
	}
}));

const ConfiguratorMonitoringToggleableDatetimefield = ({ name, value, form, element, error, helperText }) => {
	const classes = useStyles();
	const { translate } = useLocale();
	const { option, dependsOn } = element;
	const [isOpen, setIsOpen] = useState(false);
	const dependsValue = form.values[dependsOn.key] === dependsOn.value;
	const [switchValue, setSwitchValue] = useState(!(option && value === option.offValue));
	const switchLabel = switchValue ? option.onLabel : option.offLabel;
	const isUTC = option.offValue ? option.offValue.split('').pop() === 'Z' : false;

	const getResultDate = (data) => (isUTC ? dateAsIsoString(data) : dateAsIsoStringLocal(data));

	const handleOnChange = (v) => {
		form.setFieldValue(name, getResultDate(v));
	};

	const handleSwitchChange = () => {
		form.setFieldValue(name, switchValue ? option.offValue : getResultDate(new Date()));
		setSwitchValue(!switchValue);
	};

	useEffect(() => {
		if (dependsValue === false) form.setFieldValue(name, option.offValue);
		else form.setFieldValue(name, value !== '0' ? value : switchValue ? getResultDate(new Date()) : '0');
	}, [dependsValue]);

	return (
		<FormControl fullWidth margin='normal' classes={{ root: clsx({ [classes.formControlRoot]: !dependsValue }) }}>
			<FormControlLabel
				name={name}
				label={translate(switchLabel)}
				classes={{ root: classes.formControlLabelRoot }}
				control={<Switch size='small' color='primary' checked={switchValue} onChange={handleSwitchChange} />}
			/>
			{switchValue && (
				<BaseDateTimePicker
					open={isOpen}
					onOpen={() => setIsOpen(true)}
					name={name}
					value={value}
					helperText={helperText}
					error={error}
					variant='inline'
					strictCompareDates
					fullWidth
					onChange={handleOnChange}
					onClose={() => setIsOpen(false)}
				/>
			)}
		</FormControl>
	);
};

export default ConfiguratorMonitoringToggleableDatetimefield;
