import React, { useState } from 'react';
import { Field } from 'formik';
import { FormControl, FormControlLabel, Switch } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '../../../../hooks';
import TextFieldMaskedByteArray from '../../../../components/TextFieldMaskedByteArray/TextFieldMaskedByteArray';

const useStyles = makeStyles((theme) => ({
	formControlRoot: {
		marginLeft: theme.spacing(1)
	}
}));

const ConfiguratorToggleableAeskey = ({ name, value, form, element }) => {
	const classes = useStyles();
	const { translate } = useLocale();
	const { option } = element;
	const [switchValue, setSwitchValue] = useState(!(option && value === option.offValue));
	const switchLabel = switchValue ? option.onLabel : option.offLabel;

	const handleSwitchChange = () => {
		form.setFieldValue(name, switchValue ? option.offValue : option.onValue);
		setSwitchValue(!switchValue);
	};

	return (
		<FormControl fullWidth margin='normal' classes={{ root: classes.formControlRoot }}>
			<FormControlLabel
				name={name}
				label={translate(switchLabel)}
				control={<Switch size='small' color='primary' checked={switchValue} onChange={handleSwitchChange} />}
			/>
			{switchValue && (
				<Field name={name}>
					{({ field }) => (
						<TextFieldMaskedByteArray
							{...field}
							name={name}
							arrayLength={8}
							label={translate(name)}
							value={value}
							formik={form}
						/>
					)}
				</Field>
			)}
		</FormControl>
	);
};

export default ConfiguratorToggleableAeskey;
