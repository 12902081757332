import React, { useEffect } from 'react';
import { FormControl, FormControlLabel, FormHelperText, Typography, Box } from '@mui/material';
import { useLocale } from '../../../../hooks';
import BaseCheckbox from '../../../../components/BaseCheckbox/BaseCheckbox';

const ConfiguratorCheckboxGroup = ({ name, error, helperText, element, dataAtError, form, value }) => {
	const { translate } = useLocale();
	const { options = [] } = element;

	const [selected, setSelected] = React.useState(value ?? []);

	const handleClick = (e) => {
		const checked = e.target.checked;

		if (checked) setSelected([...selected, e.target.value]);
		else setSelected(selected.filter((item) => item !== e.target.value));
	};

	useEffect(() => {
		if (value !== selected) form.setFieldValue(name, selected);
	}, [selected]);

	return (
		<FormControl fullWidth component='fieldset' margin='normal' error={error}>
			<Box display='inline-flex' alignItems='center' mb='5px'>
				<Typography variant='caption' color='textSecondary'>
					{translate(name)}
				</Typography>
			</Box>
			{options.map((opt) => {
				const isChecked = selected.includes(opt.value);
				return (
					<FormControlLabel
						key={opt.value}
						label={translate(opt.label)}
						control={<BaseCheckbox name={opt.value} checked={isChecked} setChecked={handleClick} />}
					/>
				);
			})}
			<FormHelperText data-at-error={dataAtError}>{helperText}</FormHelperText>
		</FormControl>
	);
};

export default ConfiguratorCheckboxGroup;
