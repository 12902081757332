import React from 'react';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useBuilingVisualization from './useBuilingVisualization';
import { VisualizationType } from '../../Enums/VisualizationType';

const useStyles = makeStyles(() => ({
	root: {
		width: ({ visualizationType }) => {
			if (visualizationType === VisualizationType.All.value) return 15;
			if (visualizationType === VisualizationType.Details.value) return 22;
			return 60;
		},
		backgroundColor: ({ visualizationType }) =>
			visualizationType === VisualizationType.All.value ? '#fff' : '#F9F9F9',
		borderRight: ({ visualizationType }) =>
			`1px solid ${visualizationType !== VisualizationType.All.value ? '#E2E6EC' : '#fff'}`
	}
}));

const FloorStaircase = () => {
	const { visualizationType } = useBuilingVisualization();
	const classes = useStyles({ visualizationType });

	return <Box className={`floor-staircase ${classes.root}`} />;
};

export default FloorStaircase;
