export default {
	LastDay: {
		name: 'LastDay',
		value: 1
	},
	CurrentDay: {
		name: 'Day',
		value: 2
	},
	LastWeek: {
		name: 'Week',
		value: 3
	},
	Last30Days: {
		name: 'Last30Days',
		value: 4
	},
	LastMonth: {
		name: 'Month',
		value: 5
	},
	Own: {
		name: 'Own',
		value: 6
	}
};
