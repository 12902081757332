import { forwardRef } from 'react';
import { merge } from 'lodash-es';
import makeStyles from '@mui/styles/makeStyles';
import BaseButton from './BaseButton';

const useStyles = makeStyles((theme) => ({
	root: {
		minWidth: 'auto',
		padding: 0,
		color: theme.palette.primary.main,
		transition: theme.transitions.create(['color'], {
			easing: theme.transitions.easing.easeInOut,
			duration: theme.transitions.duration.short
		}),
		'&:hover': {
			backgroundColor: 'transparent',
			color: theme.palette.primary.dark,
			textDecoration: 'underline'
		},
		'&:active': {
			color: theme.palette.primary.dark,
			textDecoration: 'none'
		},
		'&:disabled': {
			'&:hover': {
				color: theme.palette.action.disabled
			}
		}
	}
}));

const ButtonLinkAlt = forwardRef(function ButtonLinkAlt({ classes = {}, ...props }, ref) {
	const styles = useStyles();

	return (
		<BaseButton
			ref={ref}
			variant='text'
			disableRipple
			disableFocusRipple
			classes={merge(styles, classes)}
			{...props}
		/>
	);
});

export default ButtonLinkAlt;
