import { Typography } from '@mui/material';
import { useLocale } from '@sonar-web/common/src/hooks';

const ContentDescription = ({ text }) => {
	const { translate } = useLocale();

	return (
		<Typography variant='body2' color='textSecondary' marginBottom='2rem'>
			{translate(text)}
		</Typography>
	);
};

export default ContentDescription;
