import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { DateHelpers, fillDecimalPlaces } from '@sonar-web/common';
import { useLocale } from '@sonar-web/common/src/hooks';

const Wrapper = styled('div')({
	paddingTop: '1rem'
});

const HeatallocatorReadCollapsed = ({ periodStartDate, averageTemperature }) => {
	const { translate } = useLocale();

	return (
		<Wrapper>
			<DataRow
				primaryData={translate('PeriodStartDate') + ' '}
				secondaryData={DateHelpers.formatDateTime(periodStartDate, 'date')}
			/>
			<DataRow
				primaryData={translate('Common_AverageTemperature') + ' [°C] '}
				secondaryData={fillDecimalPlaces(averageTemperature, 1)}
			/>
		</Wrapper>
	);
};

export default HeatallocatorReadCollapsed;

const DataRow = ({ primaryData, secondaryData }) => {
	const { translate } = useLocale();

	return (
		<Typography
			variant='body2'
			sx={{
				lineHeight: 2
			}}>
			<Typography component='span' variant='body2' color='textSecondary'>
				{primaryData}
			</Typography>
			{secondaryData || translate('Common_NoData')}
		</Typography>
	);
};
