const FilterOperator = {
	Lt: {
		value: 0,
		name: 'lt'
	},
	Le: {
		value: 1,
		name: 'le'
	},
	Equal: {
		value: 2,
		name: 'equal'
	},
	NotEqual: {
		value: 3,
		name: 'notEqual'
	},
	Ge: {
		value: 4,
		name: 'ge'
	},
	Gt: {
		value: 5,
		name: 'gt'
	},
	EndsWith: {
		value: 7,
		name: 'endsWith'
	},
	Like: {
		value: 8,
		name: 'like'
	},
	NotContains: {
		value: 9,
		name: 'notContains'
	},
	IsNull: {
		value: 12,
		name: 'isNull'
	},
	IsNotNull: {
		value: 13,
		name: 'isNotNull'
	},
	In: {
		value: 16,
		name: 'in'
	},
	NotIn: {
		value: 17,
		name: 'notIn'
	},
	CollectionContain: {
		value: 18,
		name: 'collectionContain'
	},
	CollectionNotContain: {
		value: 19,
		name: 'collectionNotContain'
	}
};

export default FilterOperator;
