import React, { useState } from 'react';
import { Box, Collapse } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CustomSelect from './CustomSelect';
import ManualModeActions from './ManualModeActions';
import { dateTimeToString } from './helpers';

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: 16,
		marginBottom: 8
	},
	'@keyframes borderDance': {
		'0%': {
			backgroundPosition: '0 0, 100% 100%, 0 100%, 100% 0'
		},
		'100%': {
			backgroundPosition: '100% 0, 0 100%, 0 0, 100% 100%'
		}
	},
	manualModeBody: {
		borderRadius: '5px',
		margin: '10px 0px',
		animation: '$borderDance 35s infinite linear',
		background: `linear-gradient(90deg, ${theme.palette.grey[400]} 60%, transparent 40%), linear-gradient(90deg, ${theme.palette.grey[400]} 60%, transparent 40%), linear-gradient(0deg, ${theme.palette.grey[400]} 60%, transparent 40%), linear-gradient(0deg, ${theme.palette.grey[400]} 60%, transparent 40%)`,
		backgroundRepeat: 'repeat-x, repeat-x, repeat-y, repeat-y',
		backgroundSize: '8px 1px, 8px 1px, 1px 8px, 1px 8px',
		padding: '5px 15px 15px 15px'
	},
	mainSelectInputManualMode: {
		'& .MuiInput-underline:before': {
			borderBottomColor: 'transparent'
		},
		'& .MuiInput-underline:hover:before': {
			borderBottomColor: 'transparent'
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: 'transparent'
		}
	}
}));

const SettingsTemplate = ({
	sampleDate,
	current,
	updateSettings,
	defaultManualSettings,
	predefinedOptions,
	settingName,
	label,
	renderManualModeItems,
	manualSettingsToFormat
}) => {
	const classes = useStyles();

	const [manualMode, setManualMode] = useState(false);

	const [currentManualSettings, setCurrentManualSettings] = useState(defaultManualSettings);

	const currentOption = predefinedOptions?.find((o) => o.value == current[settingName]);

	const [selectedOption, setSelectedOption] = useState(currentOption ?? null);

	const updateCurrentManualSettings = (name, value) => {
		setCurrentManualSettings({ ...currentManualSettings, [name]: value });
	};

	const handleChangePredefinedValue = (_, value) => {
		const format = value.value;
		updateSettings(settingName, format);
	};

	const onConfirmManualSettings = () => {
		const format = manualSettingsToFormat(currentManualSettings);

		const optionValue = predefinedOptions?.find((o) => o.value == format);

		setSelectedOption(optionValue ?? null);

		updateSettings(settingName, format);
	};

	const formatDisplayValue = (value) => {
		if (current[settingName]) {
			return dateTimeToString(sampleDate, current[settingName]);
		}

		return value;
	};

	return (
		<Box className={classes.root}>
			<CustomSelect
				selectedOption={selectedOption}
				setSelectedOption={setSelectedOption}
				formatDisplayValue={formatDisplayValue}
				canOpen={!manualMode}
				label={label}
				options={predefinedOptions}
				name={settingName}
				renderActions={() => (
					<ManualModeActions
						manualMode={manualMode}
						setManualMode={setManualMode}
						onConfirm={() => {
							onConfirmManualSettings();
						}}
					/>
				)}
				onChange={handleChangePredefinedValue}
				inputParams={{
					classes: {
						root: manualMode ? classes.mainSelectInputManualMode : null
					}
				}}
			/>
			<Collapse in={manualMode}>
				<Box className={classes.manualModeBody}>
					{renderManualModeItems({
						currentManualSettings,
						updateCurrentManualSettings
					})}
				</Box>
			</Collapse>
		</Box>
	);
};

export default SettingsTemplate;
