import React, { useState } from 'react';
import clsx from 'clsx';
import { Box, ListItem, ListItemText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TemplateEdit from './TemplateEdit';
import TemplateDelete from './TemplateDelete';
import { PanelListItemActionDelete, PanelListItemActionEdit, VerticalSpacer } from '@sonar-web/common';

const useStyles = makeStyles((theme) => ({
	listItemRoot: {
		background: '#fff',
		borderRadius: '5px',
		borderColor: 'transparent',
		padding: theme.spacing(1),
		transition: 'background 300ms ease',
		cursor: 'pointer',
		'&:hover': {
			background: theme.palette.primaryLight.light,
			'& .actions': {
				visibility: 'visible'
			}
		}
	},
	listItemRootDelete: {
		background: '#BC0A29 linear-gradient(90deg, #DB2242, #BC0A29)',
		color: theme.palette.common.white
	},
	listContainer: {
		borderBottom: 'none'
	},
	listItemTextRoot: {
		borderColor: 'transparent'
	},
	selected: {
		background: theme.palette.primaryLight.main,
		'&:hover': {
			background: theme.palette.primaryLight.dark
		}
	},
	action: {
		color: theme.palette.text.secondary
	},
	hiddenActions: {
		visibility: 'hidden !important'
	}
}));

const TemplateListItem = ({ record, selected, onClick, props, setElements }) => {
	const classes = useStyles();
	const [deleteMode, setDeleteMode] = useState(false);
	const [editMode, setEditMode] = useState(false);

	const handleToggleEdit = (e) => {
		e.stopPropagation();
		setEditMode(true);
	};

	const handleToggleDelete = (e) => {
		e.stopPropagation();
		setDeleteMode(true);
	};

	const handleDeleteSuccess = () => {
		setElements((prev) => prev.filter((p) => p.id !== record.id));
	};

	const handleEditSuccess = (newTemplate) => {
		setElements((prev) => {
			const copyPrev = [...prev];
			const index = copyPrev.findIndex((cp) => cp.id === record.id);

			copyPrev[index] = newTemplate;

			return copyPrev;
		});

		setEditMode(false);
	};

	return (
		<ListItem
			dense
			onClick={() => onClick(record)}
			classes={{
				root: clsx(classes.listItemRoot, { [classes.selected]: selected }),
				container: classes.listContainer
			}}>
			{!editMode && (
				<ListItemText
					classes={{ root: classes.listItemTextRoot }}
					primary={record.templateName}
					primaryTypographyProps={{
						variant: 'caption'
					}}
				/>
			)}
			{!editMode && !deleteMode && (
				<Box
					display='flex'
					visibility='hidden'
					className={clsx('actions', { [classes.hiddenActions]: selected })}>
					<PanelListItemActionEdit
						onClick={handleToggleEdit}
						iconButtonProps={{ className: classes.action }}
					/>
					<VerticalSpacer size={1} />
					<PanelListItemActionDelete
						onClick={handleToggleDelete}
						iconButtonProps={{ className: classes.action }}
					/>
				</Box>
			)}
			{editMode && (
				<TemplateEdit record={record} setEditMode={setEditMode} props={props} onSuccess={handleEditSuccess} />
			)}
			{deleteMode && (
				<TemplateDelete
					record={record}
					setDeleteMode={setDeleteMode}
					props={props}
					onSuccess={handleDeleteSuccess}
				/>
			)}
		</ListItem>
	);
};

export default TemplateListItem;
