import { createSelector, createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash-es';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import {
	fetchTemplate,
	addTemplate,
	editTemplate,
	fetchDefaultTemplate,
	fetchTimezones,
	addExport,
	editExport
} from '../Service/Api';
import { slices } from '../Constants/Module';

const slice = slices.exportConfigurator;
const initialConfiguratorData = {};
const initialState = {
	steps: [],
	initialData: null,
	editing: false,
	activeStep: 0,
	valid: [],
	visited: [],
	addOrEditPending: false,
	addOrEditSuccess: false,
	addOrEditTemplatePending: false,
	addOrEditTemplateSuccess: false,
	defaultTemplateFetchPending: false,
	fetchPending: false,
	fetchSuccess: false,
	timezones: [],
	timezonesFetchPending: false,
	invalidColumns: [],
	selectedColumns: [],
	columnsSystemNames: [],
	selectedTemplate: null,
	configuratorOptions: {
		decimalPart: true,
		decimalPlaces: true,
		decimalSeparator: true,
		readPeriod: false,
		appendUnit: true,
		energyUnit: false,
		interfaceTypes: false
	}
};

export const exportConfiguratorSlice = createSlice({
	name: slice,
	initialState: {
		...initialState,
		configurator: initialConfiguratorData
	},
	reducers: {
		setupConfigurator: (state, { payload }) => {
			const { steps, initialData, options } = payload;
			const editing = initialData.id != null;

			state.configuratorOptions = { ...state.configuratorOptions, ...options };
			state.initialData = { ...initialData, templateId: null, templateName: null };
			state.editing = editing;
			state.configurator = { ...state.configurator, ...initialData };
			state.steps = steps;
			state.valid = steps.reduce((acc) => {
				acc.push(editing);
				return acc;
			}, []);
			state.visited = steps.reduce((acc, _, i) => {
				acc.push(i === 0 ? true : editing);
				return acc;
			}, []);
		},
		setActiveStep: (state, { payload }) => {
			state.activeStep = payload;
		},
		fetchDataSucces: (state, { payload }) => {
			state.configurator = payload;
			state.fetchPending = false;
			state.fetchSuccess = true;
		},
		fetchDataPending: (state) => {
			state.fetchPending = true;
			state.fetchSuccess = false;
		},
		resetFetchSuccess: (state) => {
			state.addOrEditSuccess = false;
		},
		addOrEditSuccess: (state, { payload }) => {
			state.addOrEditPending = false;
			state.addOrEditSuccess = payload;
		},
		addOrEditPending: (state, { payload }) => {
			state.addOrEditPending = payload;
		},
		resetAddOrEditSuccess: (state) => {
			state.addOrEditSuccess = false;
		},
		addOrEditTemplateSuccess: (state, { payload }) => {
			state.addOrEditTemplatePending = false;
			state.addOrEditTemplateSuccess = payload;
		},
		addOrEditTemplatePending: (state, { payload }) => {
			state.addOrEditTemplatePending = payload;
		},
		resetAddOrEditTemplateSuccess: (state) => {
			state.addOrEditTemplateSuccess = false;
		},
		updateValid: (state, { payload }) => {
			let newValid = [...state.valid];

			for (let p in payload) {
				newValid[p] = payload[p];
			}

			state.valid = newValid;
		},
		updateVisited: (state, { payload }) => {
			let newVisited = [...state.visited];

			for (let p in payload) {
				newVisited[p] = payload[p];
			}

			state.visited = newVisited;
		},
		setConfiguratorData: (state, { payload }) => {
			state.configurator = payload;
		},
		resetConfiguratorData: (state) => {
			state.configurator = initialConfiguratorData;

			for (let is in initialState) {
				state[is] = initialState[is];
			}
		},
		editConfiguratorData: (state, { payload }) => {
			const { data, nestedProperty, merge } = payload;
			const property = Object.keys(data)[0];

			//update roota state.configurator
			if (property === '') {
				if (!merge) state.configurator = Object.values(data)[0];
				else state.configurator = { ...state.configurator, ...Object.values(data)[0] };
				return;
			}

			let previous = nestedProperty
				? cloneDeep(state.configurator[property][nestedProperty])
				: cloneDeep(state.configurator[property]);
			const payloadData = nestedProperty ? data[property][nestedProperty] : data[property];

			if (Array.isArray(previous) && payloadData !== null) {
				if (!merge) previous = payloadData;
				else previous = [...previous, ...payloadData];
			} else if (typeof previous === 'object' && previous !== null && payloadData !== null) {
				if (!merge) previous = payloadData;
				else previous = { ...previous, ...payloadData };
			} else previous = payloadData;

			if (nestedProperty)
				state.configurator = { ...state.configurator, ...{ [property]: { [nestedProperty]: previous } } };
			else state.configurator = { ...state.configurator, ...{ [property]: previous } };
		},
		loadConfiguratorInitialData: (state) => {
			state.configurator = { ...state.initialData };
			state.valid = state.steps.reduce((acc, _, i) => {
				if (i === 0 && !state.editing) acc.push(true);
				else acc.push(state.editing);
				return acc;
			}, []);
			state.visited = state.steps.reduce((acc, _, i) => {
				acc.push(i === 0 ? true : state.editing);
				return acc;
			}, []);
		},
		setTimezonesFetchPending: (state, { payload }) => {
			state.timezonesFetchPending = payload;
		},
		fetchTimezonesSuccess: (state, { payload }) => {
			state.timezones = payload;
		},
		setMappingInvalidColumns: (state, { payload }) => {
			state.invalidColumns = payload;
		},
		setMappingSelectedColumns: (state, { payload }) => {
			state.selectedColumns = payload;
		},
		setMappingSystemNames: (state, { payload }) => {
			state.columnsSystemNames = payload;
		},
		setDefaultTemplateFetchPending: (state, { payload }) => {
			state.defaultTemplateFetchPending = payload;
		},
		setSelectedTemplate: (state, { payload }) => {
			state.selectedTemplate = payload;
		}
	}
});

export const {
	setupConfigurator,
	setActiveStep,
	fetchDataSucces,
	fetchDataPending,
	resetFetchSuccess,
	addOrEditSuccess,
	addOrEditPending,
	addOrEditTemplateSuccess,
	addOrEditTemplatePending,
	resetAddOrEditTemplateSuccess,
	resetAddOrEditSuccess,
	updateValid,
	updateVisited,
	setConfiguratorData,
	resetConfiguratorData,
	editConfiguratorData,
	loadConfiguratorInitialData,
	setTimezonesFetchPending,
	fetchTimezonesSuccess,
	setMappingInvalidColumns,
	setMappingSelectedColumns,
	setMappingSystemNames,
	setDefaultTemplateFetchPending,
	setSelectedTemplate
} = exportConfiguratorSlice.actions;

//pobranie domyślnego szablonu i ustawienie danych w wejściowych w konfiguratorze
export const fetchDefaultTemplateAsync =
	({ steps, initialData, options }) =>
	async (dispatch) => {
		try {
			dispatch(setDefaultTemplateFetchPending(true));

			const { id, ...rest } = await fetchDefaultTemplate(initialData.exportType);

			dispatch(
				setupConfigurator({ steps, options, initialData: { ...initialData, ...rest, generateNow: null } })
			);

			dispatch(setDefaultTemplateFetchPending(false));
		} catch (error) {
			dispatch(setDefaultTemplateFetchPending(false));
			return await dispatch(addErrorAsync({ slice, error }));
		}
	};

//pobranie szablonu po id
export const fetchDataAsync = (id) => async (dispatch) => {
	try {
		dispatch(fetchDataPending(true));

		const response = await fetchTemplate(id);

		dispatch(fetchDataSucces(response));
	} catch (error) {
		dispatch(fetchDataPending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

//dodanie lub edycja exportu
export const addOrEditAsync = (isEdit, submitData) => async (dispatch) => {
	try {
		dispatch(addOrEditPending(true));

		if (isEdit) await editExport(submitData);
		else await addExport(submitData);

		dispatch(addOrEditSuccess(true));
		setTimeout(() => dispatch(resetAddOrEditSuccess(false)), 100);
	} catch (error) {
		dispatch(addOrEditPending(false));
		return dispatch(addErrorAsync({ slice, error }));
	}
};

//dodanie lub edycja szablonu
export const addOrEditTemplateAsync = (isEdit, submitData) => async (dispatch) => {
	try {
		dispatch(addOrEditTemplatePending(true));

		const { templateId, ...values } = submitData;

		if (isEdit) await editTemplate({ ...values, id: templateId, name: values.templateName });
		else await addTemplate({ ...values, name: values.templateName });

		dispatch(addOrEditTemplateSuccess(true));
		setTimeout(() => dispatch(resetAddOrEditTemplateSuccess(false)), 10);
	} catch (error) {
		dispatch(addOrEditTemplatePending(false));
		return dispatch(addErrorAsync({ slice, error }));
	}
};

export const fetchTimezonesAsync = () => async (dispatch) => {
	try {
		dispatch(setTimezonesFetchPending(true));

		const response = await fetchTimezones();

		dispatch(setTimezonesFetchPending(false));
		dispatch(fetchTimezonesSuccess(response));
	} catch (error) {
		dispatch(setTimezonesFetchPending(false));
		return await dispatch(addErrorAsync({ slice, error }));
	}
};

//selectors

export const selectData = createSelector(
	(state) => state.exportConfigurator,
	(exportConfigurator) => ({
		data: exportConfigurator.configurator,
		fetchPending: exportConfigurator.fetchPending,
		fetchSuccess: exportConfigurator.fetchSuccess
	})
);

//mapping
export const selectMappingColumns = (state) => state.exportConfigurator.configurator.columns;

export const selectMappingInvalidColumns = (state) => state.exportConfigurator.invalidColumns;

export const selectMappingSelectedColumns = (state) => state.exportConfigurator.selectedColumns;

export const selectMappingSystemNames = (state) => state.exportConfigurator.columnsSystemNames;
//end mapping

export const selectAddOrEdit = createSelector(
	(state) => state.exportConfigurator,
	(exportConfigurator) => ({
		addOrEditPending: exportConfigurator.addOrEditPending,
		addOrEditSuccess: exportConfigurator.addOrEditSuccess
	})
);

export const selectAddOrEditTemplate = createSelector(
	(state) => state.exportConfigurator,
	(exportConfigurator) => ({
		addOrEditTemplatePending: exportConfigurator.addOrEditTemplatePending,
		addOrEditTemplateSuccess: exportConfigurator.addOrEditTemplateSuccess
	})
);

export const selectIsEditing = (state) => state.exportConfigurator.editing;

export const selectSteps = (state) => state.exportConfigurator.steps;

export const selectActiveStep = (state) => state.exportConfigurator.activeStep;

export const selectValid = (state) => state.exportConfigurator.valid;

export const selectVisited = (state) => state.exportConfigurator.visited;

export const selectConfiguratorOptions = (state) => state.exportConfigurator.configuratorOptions;

export const selectDefaultTemplateFetchPending = (state) => state.exportConfigurator.defaultTemplateFetchPending;

export const selectSelectedTemplate = (state) => state.exportConfigurator.selectedTemplate;

export const selectTimezones = createSelector(
	(state) => state.exportConfigurator,
	(exportConfigurator) => ({
		pending: exportConfigurator.timezonesFetchPending,
		timezones: exportConfigurator.timezones
	})
);

export default exportConfiguratorSlice.reducer;
