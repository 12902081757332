import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNetworkPropertiesAsync, selectNetworkProperties } from '../networkSlice';

const FetchNetworkProperties = ({ children }) => {
	const dispatch = useDispatch();
	const { networkProperties, networkPropertiesPending } = useSelector(selectNetworkProperties);

	useEffect(() => {
		if (networkProperties.length > 0 || networkPropertiesPending) return;
		dispatch(fetchNetworkPropertiesAsync());
	}, [dispatch]);

	return children({ networkPropertiesPending, networkProperties });
};

export default FetchNetworkProperties;
