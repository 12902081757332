import { apiBase } from '@sonar-web/common';
import { REST_API_PATHS } from '@sonar-web/common/src/constants/system';

const path = REST_API_PATHS.TCP;

export async function fetchTcpDeviceTypes(pageDescriptor) {
	const { data } = await apiBase(`deviceTypes`, 'get', {
		path,
		params: { pageDescriptor }
	});

	return data;
}
