import React from 'react';
import BaseIcon from './BaseIcon';

export default function FloorIcon(props) {
	return (
		<BaseIcon {...props}>
			<path d='m 6.0042413,3.5625298 c -1.343687,0 -2.441711,1.0975 -2.441711,2.44119 V 17.99575 c 0,1.34369 1.098024,2.44172 2.441711,2.44172 H 17.996274 c 1.343688,0 2.441195,-1.09803 2.441195,-2.44172 V 6.0037198 c 0,-1.34369 -1.097507,-2.44119 -2.441195,-2.44119 z m 0,0.99994 H 17.996274 c 0.806985,0 1.441256,0.63426 1.441256,1.44125 v 3.39566 h -0.440283 -4.03748 -0.499711 -0.500228 v 0.49971 0.5002302 3.53673 H 10.423093 9.9228653 9.4226373 v 0.50023 0.50023 4.03696 0.46406 h -3.418396 c -0.806986,0 -1.441772,-0.63479 -1.441772,-1.44178 V 6.0037198 c 0,-0.80699 0.634786,-1.44125 1.441772,-1.44125 z M 14.959767,10.39932 h 4.03748 0.440283 v 7.59643 c 0,0.80699 -0.634271,1.44178 -1.441256,1.44178 h -7.573181 v -0.46406 -4.03696 h 4.036963 0.499711 v -1.00046 z' />
		</BaseIcon>
	);
}
