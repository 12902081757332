import React from 'react';
import BaseIcon from './BaseIcon';

export default function StaircaseIcon(props) {
	return (
		<BaseIcon {...props}>
			<path d='m 6.0021743,3.5625298 c -1.343688,0 -2.439644,1.09751 -2.439644,2.4412 V 17.99628 c 0,1.34369 1.095956,2.44119 2.439644,2.44119 H 17.996274 c 1.343688,0 2.441195,-1.0975 2.441195,-2.44119 V 6.0037298 c 0,-1.34369 -1.097507,-2.4412 -2.441195,-2.4412 z m 0,0.99994 H 17.996274 c 0.806986,0 1.441256,0.63427 1.441256,1.44126 V 17.99628 c 0,0.80699 -0.63427,1.44125 -1.441256,1.44125 H 6.0021743 c -0.806986,0 -1.439705,-0.63426 -1.439705,-1.44125 v -4.01784 h 0.139009 9.3244957 l -2.400887,2.39933 -0.353467,0.35347 0.70745,0.70693 0.353467,-0.35346 2.854089,-2.85254 10e-4,10e-4 0.70745,-0.70693 -10e-4,-10e-4 10e-4,-10e-4 -0.70745,-0.70693 -10e-4,10e-4 -2.854089,-2.8546102 -0.353467,-0.35346 -0.70745,0.7069302 0.353983,0.35347 2.307353,2.30787 H 4.7014783 4.5624693 V 6.0037398 c 0,-0.80699 0.632719,-1.44126 1.439705,-1.44126 z' />
		</BaseIcon>
	);
}
