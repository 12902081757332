import React from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '../../../../../hooks';

const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		marginRight: 10
	},
	circle: {
		height: 10,
		width: 10,
		borderRadius: '50%',
		marginRight: 6,
		backgroundColor: ({ color }) => color,
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: ({ borderColor, color }) => borderColor ?? color
	},
	text: {
		lineHeight: 1
	}
}));

const LegendItem = ({ color, borderColor, text }) => {
	const classes = useStyles({ color, borderColor });
	const { translate } = useLocale();

	return (
		<div className={classes.root}>
			<div className={classes.circle} />
			<Typography
				variant='caption'
				color='textPrimary'
				className={classes.text}
				dangerouslySetInnerHTML={{
					__html: translate(text)
				}}
			/>
		</div>
	);
};

export default LegendItem;
