import { useEffect } from 'react';
import useConfigurator from '../useConfigurator';

const SchemaValidationUpdater = ({ formValues, updateSchema }) => {
	const { datasFormRegisters } = useConfigurator();

	const datasFromRegistersIncludes = datasFormRegisters
		? datasFormRegisters.map((dfr) => dfr.includeInConfiguration + '').join(',')
		: null;

	//update schemy walidacji po włączeniu/wyłączeniu rejestru (grupy)
	useEffect(() => {
		if (!datasFromRegistersIncludes) return;
		updateSchema(formValues);
	}, [datasFromRegistersIncludes]);

	return null;
};

export default SchemaValidationUpdater;
