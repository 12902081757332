import React from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useLocale } from '@sonar-web/common/src/hooks';

const useStyles = makeStyles((theme) => ({
	timelineDot: {
		borderRadius: '50%',
		height: 8,
		width: 8,
		display: 'flex',
		backgroundColor: theme.palette.primary.main
	},
	x3: {
		fontSize: '0.75rem',
		fontWeight: theme.typography.fontWeightMedium,
		color: theme.palette.primary.main,
		marginLeft: 2,
		marginRight: theme.spacing(1)
	}
}));

const TimelineLegend = ({ ...rest }) => {
	const { translate } = useLocale();
	const classes = useStyles();

	return (
		<Box display='flex' flexDirection='column' {...rest}>
			<Box display='flex' flexDirection='row' alignItems='center'>
				<Box display='flex' flexDirection='row' alignItems='center' justifyContent='center' width={30}>
					<Box className={classes.timelineDot} mr={1}></Box>
				</Box>
				<Typography variant='caption'>{translate('Stationary_LegendOneConnectionWithServer')}</Typography>
			</Box>
			<Box display='flex' flexDirection='row' alignItems='center'>
				<Box display='flex' flexDirection='row' alignItems='center' justifyContent='center' width={30}>
					<Box className={classes.timelineDot}></Box>
					<Typography className={classes.x3}>{'3x'}</Typography>
				</Box>
				<Typography variant='caption'>{translate('Stationary_LegendMultipleConnectionsWithServer')}</Typography>
			</Box>
		</Box>
	);
};

export default TimelineLegend;
