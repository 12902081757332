import moment from 'moment';
import { dayMonthFormats, minuteHourFormats, yearFormats, timeFormats } from './constants';

export const getDateFormat = ({ dateUnitOrder, dateUnitSeparator, dayMonthFormat, yearFormat }) => {
	let format = '';

	for (let i = 0; i < dateUnitOrder.length; i++) {
		if (dateUnitOrder.charAt(i) === 'd') {
			format += dayMonthFormat == dayMonthFormats.single ? 'd' : 'dd';
		} else if (dateUnitOrder.charAt(i) === 'm') {
			format += dayMonthFormat == dayMonthFormats.single ? 'M' : 'MM';
		} else if (dateUnitOrder.charAt(i) === 'y') {
			format += yearFormat == yearFormats.short ? 'yy' : 'yyyy';
		}

		if (i != dateUnitOrder.length - 1) {
			format += dateUnitSeparator;
		}
	}

	return format;
};

export const getTimeFormat = ({ minuteHourFormat, timeUnitSeparator, timeFormat }) => {
	const m = 'm';
	const h = timeFormat == timeFormats.time24h ? 'H' : 'h';

	const amPm = timeFormat == timeFormats.time24h ? '' : ' tt';

	const hh = minuteHourFormat == minuteHourFormats.single ? h : h + h;
	const mm = minuteHourFormat == minuteHourFormats.single ? m : m + m;

	return hh + timeUnitSeparator + mm + amPm;
};

const toMomentDateTimeFormat = (datetimeFormat) => {
	return datetimeFormat.replaceAll('d', 'D').replaceAll('y', 'Y').replaceAll('tt', 'a');
};

export const dateTimeToString = (date, format) => {
	const formatNew = toMomentDateTimeFormat(format);

	return moment(date).format(formatNew).toUpperCase();
};

export const capitalize = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

export const splitDateTimeFormat = (format) => {
	const firstSpaceIdx = format.indexOf(' ');

	return {
		dateFormat: format.slice(0, firstSpaceIdx),
		timeFormat: format.slice(firstSpaceIdx + 1)
	};
};

export const replaceTimeFormat = (dateTimeFormat, timeFormat) => {
	const { dateFormat } = splitDateTimeFormat(dateTimeFormat);

	return dateFormat.concat(' ', timeFormat);
};

export const replaceDateFormat = (dateTimeFormat, dateFormat) => {
	const { timeFormat } = splitDateTimeFormat(dateTimeFormat);

	return dateFormat.concat(' ', timeFormat);
};
