import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import BaseDialog from '@sonar-web/common/src/components/BaseDialog/BaseDialog';
import BaseButton from '@sonar-web/common/src/components/Buttons/BaseButton';
import EventBus from '@sonar-web/common/src/helpers/EventBus';
import { useLocale } from '@sonar-web/common/src/hooks';
import Events from '../Constants/Events';
import useCurrentTenant from '../Hooks/useCurrentTenant';

const TenantChangedAlert = () => {
	const { translate } = useLocale();
	const { storeTenant } = useCurrentTenant();

	const [open, setOpen] = useState(false);
	const [newTenant, setNewTenant] = useState(null);

	const handleClose = (_, reason) => {
		if (reason === 'backdropClick') return;

		storeTenant(newTenant);
		window.location = window.location.origin;
	};

	const onTenantChanged = ({ detail }) => {
		setNewTenant(detail);
		setOpen(true);
	};

	useEffect(() => {
		EventBus.on(Events.TenantChanged, onTenantChanged);

		return () => {
			EventBus.off(Events.TenantChanged, onTenantChanged);
		};
	}, []);

	return (
		<BaseDialog
			title='TenantChangedTitle'
			open={open}
			onClose={handleClose}
			actions={() => <BaseButton onClick={handleClose}>{translate('Understand')}</BaseButton>}>
			{() => <Typography>{translate('TenantChangedDescription', newTenant?.name)}</Typography>}
		</BaseDialog>
	);
};

export default TenantChangedAlert;
