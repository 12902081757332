import FromScratchIcon from '@sonar-web/common/src/icons/FromScratchIcon';
import TileButtonSelectable from '@sonar-web/common/src/features/Tiles/TileButtonSelectable';
import WorkflowType from './WorkflowType';

const FromScratch = ({ toggleList, setToggleList }) => {
	const handleClick = () => {
		if (toggleList !== WorkflowType.FromScratch.value) setToggleList(WorkflowType.FromScratch.value);
	};

	return (
		<TileButtonSelectable
			Icon={FromScratchIcon}
			title='Common_StartFromScratch'
			selected={toggleList === WorkflowType.FromScratch.value}
			onClick={handleClick}
			data-at='Common_StartFromScratch'
		/>
	);
};

export default FromScratch;
