let token = null;
let tokenParsed = null;
let refreshToken = null;
let sessionState = null;
let tenants = null;
let groups = null;
let roles = null;
let locale = 'pl';

const setToken = (newToken) => {
	token = newToken;
};

const getToken = () => token;

const setTokenParsed = (newTokenParsed) => {
	tokenParsed = newTokenParsed;
};

const getTokenParsed = () => tokenParsed;

const setRefreshToken = (newRefreshToken) => {
	refreshToken = newRefreshToken;
};

const getRefreshToken = () => refreshToken;

const setSessionState = (newSessionState) => {
	sessionState = newSessionState;
};

const getSessionState = () => sessionState;

const setTenants = (newTenants) => {
	tenants = newTenants;
};

const getTenants = () => tenants;

const setGroups = (newGroups) => {
	groups = newGroups;
};

const getGroups = () => groups;

const setRoles = (newRoles) => {
	roles = newRoles;
};

const getRoles = () => roles;

const setLocale = (newLocale) => {
	locale = newLocale;
};

const getLocale = () => locale;

const clearAuthData = () => {
	token = null;
	tokenParsed = null;
	refreshToken = null;
	sessionState = null;
	tenants = null;
	groups = null;
	roles = null;
	locale = 'pl';
};

const AuthService = {
	setToken,
	getToken,
	setTokenParsed,
	getTokenParsed,
	setRefreshToken,
	getRefreshToken,
	setSessionState,
	getSessionState,
	setTenants,
	getTenants,
	setGroups,
	getGroups,
	setRoles,
	getRoles,
	setLocale,
	getLocale,
	clearAuthData
};

export default AuthService;
