import React from 'react';
import { Box, Fade } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSidebar } from '../../features';
import { BaseTooltipFollowCursor } from '../BaseTooltip/BaseTooltipFollowCursor';

const useStyles = makeStyles(() => ({
	root: {
		backgroundColor: 'rgba(255,255,255,0.75)',
		cursor: 'not-allowed'
	}
}));

const PanelListMask = ({ text, panelName }) => {
	const classes = useStyles();
	const { isExclusiveMode } = useSidebar();

	const MaskedContent = React.forwardRef(function MaskedContent(_, ref) {
		return (
			<BaseTooltipFollowCursor
				text={text}
				color='error'
				render={(setShowTooltip) => (
					<Box
						ref={ref}
						position='absolute'
						top={0}
						left={0}
						width='100%'
						height='100%'
						zIndex={1}
						className={classes.root}
						onClick={() => setShowTooltip(true)}
					/>
				)}
			/>
		);
	});

	return (
		<Fade in={isExclusiveMode(panelName)} mountOnEnter unmountOnExit>
			<MaskedContent />
		</Fade>
	);
};

export default PanelListMask;
