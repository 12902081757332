import React from 'react';
import { Box } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

const DashboardTitleSkeleton = () => {
	return (
        <Box display='flex' height={48} alignItems='center' mb={2}>
			<Box flex='1' alignItems='center'>
				<Skeleton animation='wave' variant='text' width='30%' height={18} />
				<Skeleton animation='wave' variant='text' width='50%' height={30} />
			</Box>
			<Skeleton animation='wave' variant="circular" height={30} width={30} />
			<Skeleton animation='wave' variant="circular" height={30} width={30} style={{ marginLeft: 5 }} />
		</Box>
    );
};

export default DashboardTitleSkeleton;
