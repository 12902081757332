import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, Select, FormHelperText } from '@mui/material';
import { useLocale } from '../../../../hooks';
import BaseSelectMenuItem from '../../../../components/BaseSelectMenuItem/BaseSelectMenuItem';

const ConfiguratorSelectfield = ({ name, value, error, helperText, form, element, dataAtError }) => {
	const { translate } = useLocale();
	const { options = [], dependsOn, defaultValue } = element;
	const [dependencyVisibility, setDependencyVisibility] = useState(false);

	useEffect(() => {
		if (!dependsOn) return;

		setDependencyVisibility(
			dependsOn.isEqual
				? dependsOn.value === form.values[dependsOn.key]
				: dependsOn.value !== form.values[dependsOn.key]
		);
	}, [form.values[dependsOn?.key]]);

	useEffect(() => {
		if (!dependsOn) return;
		if (dependencyVisibility === true) form.setFieldValue(name, value ?? defaultValue);
	}, [dependencyVisibility]);

	const handleChange = (e) => {
		form.setFieldValue(name, e.target.value);
	};

	if (dependsOn && !dependencyVisibility) return null;
	return (
		<FormControl fullWidth margin='normal' error={error}>
			<InputLabel id={name}>{translate(name)}</InputLabel>
			<Select labelId={name} value={value ?? ''} onChange={handleChange}>
				{options.map((opt, index) => (
					<BaseSelectMenuItem key={index} value={opt.value}>
						{translate(opt.label)}
					</BaseSelectMenuItem>
				))}
			</Select>
			<FormHelperText data-at-error={dataAtError}>{helperText}</FormHelperText>
		</FormControl>
	);
};

export default ConfiguratorSelectfield;
