import React from 'react';
import BaseIcon from './BaseIcon';

export default function MarkerIcon(props) {
	return (
		<BaseIcon {...props}>
			<path d='m 6.0037249,3.4002648 c -1.4303407,0 -2.6034587,1.17312 -2.6034587,2.60346 V 17.996275 c 0,1.43034 1.173118,2.60346 2.6034587,2.60346 H 17.996275 c 1.43034,0 2.603458,-1.17312 2.603458,-2.60346 V 6.0037248 c 0,-1.43034 -1.173118,-2.60346 -2.603458,-2.60346 z m 0,1.32447 H 17.996275 c 0.720332,0 1.278991,0.55866 1.278991,1.27899 V 17.996275 c 0,0.72033 -0.558659,1.27899 -1.278991,1.27899 H 16.123004 V 9.2086948 a 0.66152613,0.66152613 0 0 0 -0.661975,-0.66197 H 8.5389704 a 0.66152613,0.66152613 0 0 0 -0.6619748,0.66197 V 19.275265 H 6.0037249 c -0.7203327,0 -1.2789917,-0.55866 -1.2789917,-1.27899 V 6.0037248 c 0,-0.72033 0.558659,-1.27899 1.2789917,-1.27899 z m 3.1972205,5.14645 H 14.798537 V 19.275265 H 9.2009454 Z m 3.2597496,2.9316102 v 0.66197 1.84175 0.66043 h 1.3224 v -0.66043 -1.84175 -0.66197 z' />
		</BaseIcon>
	);
}
