import { ListFilters, FiltersControls } from '@sonar-web/common';
import ReadTypePeriod from '../Enums/ReadTypePeriod';
import { slices } from '../Constants/Module';

const slice = slices.heatallocatorReads;

const HeatallocatorReadsLocationFilters = () => {
	return (
		<ListFilters
			slice={slice}
			controls={[
				{
					control: FiltersControls.date,
					member: { name: 'date', type: 'datetime' },
					label: 'Common_MeasurementDateFrom',
					type: 'ge',
					withPredefined: true
				},
				{
					control: FiltersControls.date,
					member: { name: 'date', type: 'datetime' },
					label: 'Common_MeasurementDateTo',
					type: 'le'
				},
				{
					control: FiltersControls.textSingle,
					member: { name: 'heatAllocatorNumber', type: 'string' },
					label: 'HeatAllocators_SerialNumber'
				},
				{
					control: FiltersControls.comboSingle,
					member: { name: 'type', type: 'int' },
					label: 'HeatAllocators_ReadType',
					options: [
						{
							text: 'HeatAllocators_ReadInstantMonth',
							value: ReadTypePeriod.InstantMonth.value
						},
						{
							text: 'HeatAllocators_ReadInstantYear',
							value: ReadTypePeriod.InstantYear.value
						},
						{
							text: 'HeatAllocators_ReadLoggedMonth',
							value: ReadTypePeriod.LoggedMonth.value
						},
						{
							text: 'HeatAllocators_ReadLoggedYear',
							value: ReadTypePeriod.LoggedYear.value
						}
					]
				}
			]}
		/>
	);
};

export default HeatallocatorReadsLocationFilters;
