import React from 'react';
import { Button, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '../../hooks';
import { merge } from 'lodash-es';

const useStyles = makeStyles(() => ({
	buttonRoot: {
		position: 'relative'
	},
	progressRoot: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12
	},
	transparentBorder: {
		borderColor: 'transparent'
	}
}));

const BaseButton = React.forwardRef(function BaseButton(
	{
		children,
		size = 'small',
		progress = false,
		pending = false,
		disabled = false,
		borderOnHover = false,
		classes = {},
		sx = {},
		...rest
	},
	ref
) {
	const styles = useStyles();
	const { translate } = useLocale();
	const progressSize = 22;

	return (
		<Button
			ref={ref}
			size={size}
			disabled={disabled || pending}
			color='primary'
			variant='contained'
			classes={merge(
				{
					root: styles.buttonRoot
				},
				classes
			)}
			sx={{
				...sx,
				...(borderOnHover && {
					borderColor: 'transparent'
				})
			}}
			{...rest}>
			{translate(children)}
			{progress && pending && <CircularProgress size={progressSize} classes={{ root: styles.progressRoot }} />}
		</Button>
	);
});

BaseButton.muiName = Button.muiName;
export default BaseButton;
