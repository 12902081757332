import { Typography } from '@mui/material';

const FormTitle = ({ children, sx = {}, ...rest }) => {
	return (
		<Typography
			variant='h6'
			sx={{
				mb: '1rem',
				...sx
			}}
			{...rest}>
			{children}
		</Typography>
	);
};

export default FormTitle;
