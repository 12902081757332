import SettlementStatus from '../../Enums/SettlementStatus';
import billingContentState from './billingContentState';

export const billingContentStateName = 'billingContent';

export const initialBillingContentData = {
	previousState: billingContentState.Initial,
	state: billingContentState.Initial,
	activeTab: null,
	approvePending: false,
	fetchErrorDetailsPending: false,
	fetchErrorFilePending: false,
	inputFilesLoaded: false,
	fetchSettlementReportsPending: false,
	fetchFlatReportsPending: false,
	fetchTokenPending: false,
	summary: {},
	generateReportsPackagePending: false
};

export const billingContentReducers = {
	changeActiveState: (state, { payload }) => {
		state[billingContentStateName].previousState = state[billingContentStateName].state;
		state[billingContentStateName].state = payload;
	},
	changeToPreviousState: (state) => {
		state[billingContentStateName].state = state[billingContentStateName].previousState;
	},
	changeActiveTab: (state, { payload }) => {
		state[billingContentStateName].activeTab = payload;
	},
	resetBillingContentData: (state) => {
		state[billingContentStateName] = initialBillingContentData;
	},
	setApprovePending: (state, { payload }) => {
		state[billingContentStateName].approvePending = payload;
	},
	setApproveSuccess: (state) => {
		state[billingContentStateName].approvePending = false;
		state.billing.status = SettlementStatus.WaitingForUserToRunReportsPackage2.value;
		state.billing.isApproved = true;
	},
	setFetchErrorDetailsPending: (state, { payload }) => {
		state[billingContentStateName].fetchErrorDetailsPending = payload;
	},
	setFetchErrorFilePending: (state, { payload }) => {
		state[billingContentStateName].fetchErrorFilePending = payload;
	},
	setFetchSettlementReportsPending: (state, { payload }) => {
		state[billingContentStateName].fetchSettlementReportsPending = payload;
	},
	setFetchFlatReportsPending: (state, { payload }) => {
		state[billingContentStateName].fetchFlatReportsPending = payload;
	},
	setInputFilesLoaded: (state, { payload }) => {
		state[billingContentStateName].inputFilesLoaded = payload;
	},
	setFetchTokenPending: (state, { payload }) => {
		state[billingContentStateName].fetchTokenPending = payload;
	},
	setFetchSummaryPending: (state, { payload }) => {
		state[billingContentStateName].fetchSummaryPending = payload;
	},
	setFetchSummarySuccess: (state, { payload }) => {
		state[billingContentStateName].fetchSummaryPending = false;
		state[billingContentStateName].summary = payload;
	},
	resetSummaries: (state) => {
		state[billingContentStateName].summary = {};
	},
	setGenerateReportsPackagePending: (state, { payload }) => {
		state[billingContentStateName].generateReportsPackagePending = payload;
	},
	setGenerateReportsPackageSuccess: (state) => {
		state[billingContentStateName].generateReportsPackagePending = false;
		state.billing.status = SettlementStatus.GeneratingReportsPackage2.value;
	}
};
