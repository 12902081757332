import { createSelector, createSlice } from '@reduxjs/toolkit';
import { pageDescriptor, getSliceFilters } from '@sonar-web/common';
import { fetchDeviceTypes } from './api';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';

const slice = 'wmbusDeviceTypesSearch';

export const deviceTypesSearchSlice = createSlice({
	name: slice,
	initialState: {
		pageDescriptor,
		fetchPending: false,
		deviceTypes: [],
		totalCount: 0,
		hasMore: true,
		reload: false,
		reset: true
	},
	reducers: {
		fetchDeviceTypesSuccess: (state, action) => {
			let fetched = [...action.payload.elements];

			state.deviceTypes = state.reset ? fetched : state.deviceTypes.concat(fetched);
			state.totalCount = action.payload.totalCount;
			state.fetchPending = false;
		},
		fetchDeviceTypesPending: (state) => {
			state.fetchPending = true;
		},
		setHasMore: (state) => {
			state.hasMore = state.totalCount > state.pageDescriptor.Offset + state.pageDescriptor.Limit;
		},
		setResetAndReload: (state) => {
			state.deviceTypes = [];
			state.reload = true;
			state.reset = true;
			state.hasMore = true;
			state.pageDescriptor.Offset = 0;
			state.pageDescriptor.FilterDescriptors = [];
			state.pageDescriptor.SortDescriptors = [];
		},
		setResetToFirstPage: (state) => {
			state.reload = true;
			state.reset = true;
			state.hasMore = true;
			state.pageDescriptor.Offset = 0;
		},
		setAppend: (state, action) => {
			state.reset = !action.payload;
			if (action.payload) state.pageDescriptor.Offset += state.pageDescriptor.Limit;
			else state.pageDescriptor.Offset = 0;
		},
		setReload: (state) => {
			state.reload = true;
		}
	}
});

export const {
	fetchDeviceTypesSuccess,
	fetchDeviceTypesPending,
	setHasMore,
	setResetAndReload,
	setResetToFirstPage,
	setAppend,
	setReload
} = deviceTypesSearchSlice.actions;

export const fetchDeviceTypesAsync = () => async (dispatch, getState) => {
	let response;

	try {
		dispatch(fetchDeviceTypesPending());

		let pd = Object.assign({}, getState().wmbusDeviceTypesSearch.pageDescriptor);

		pd.FilterDescriptors = getSliceFilters(getState(), slice);
		pd.SortDescriptors = [{ Member: 'nameToSort', ListSortDirection: 1 }];

		response = await fetchDeviceTypes(pd);
	} catch (error) {
		dispatch(addErrorAsync({ slice, error }));
		return;
	}

	dispatch(fetchDeviceTypesSuccess(response));
	dispatch(setHasMore());
};

export const fetchDeviceTypeByIdAsync = (filter) => async (dispatch, getState) => {
	let response;

	try {
		let pd = Object.assign({}, getState().wmbusDeviceTypesSearch.pageDescriptor);

		pd.FilterDescriptors = filter;
		pd.SortDescriptors = [];

		response = await fetchDeviceTypes(pd);
	} catch (error) {
		dispatch(addErrorAsync({ slice, error }));
		return;
	}

	return response;
};

export const selectDeviceTypes = createSelector(
	(state) => state[slice],
	(types) => ({
		dataRows: types.deviceTypes,
		pending: types.fetchPending,
		totalCount: types.totalCount,
		hasMore: types.hasMore,
		reload: types.reload
	})
);

export default deviceTypesSearchSlice.reducer;
