import { Slide, toast } from 'react-toastify';
import ErrorNotification from './ErrorNotification';
import Info from './Info';
import NotificationClose from './NotificationClose';
import Success from './Success';
import Warning from './Warning';

const success = ({ title, message, onClose, options = {} }) =>
	toast.success(<Success title={title} message={message} />, {
		position: 'top-center',
		transition: Slide,
		theme: 'colored',
		icon: false,
		autoClose: 10000,
		hideProgressBar: false,
		closeOnClick: false,
		pauseOnHover: true,
		draggable: false,
		closeButton: ({ closeToast }) => <NotificationClose onClose={onClose} closeToast={closeToast} />,
		...options
	});

const info = ({ title, message, onClose, options = {} }) =>
	toast.info(<Info title={title} message={message} />, {
		position: 'top-center',
		transition: Slide,
		theme: 'colored',
		icon: false,
		autoClose: 10000,
		hideProgressBar: false,
		closeOnClick: false,
		pauseOnHover: true,
		draggable: false,
		closeButton: ({ closeToast }) => <NotificationClose onClose={onClose} closeToast={closeToast} />,
		...options
	});

const warning = ({ title, message, onClose, options = {} }) =>
	toast.warning(<Warning title={title} message={message} />, {
		position: 'top-center',
		transition: Slide,
		theme: 'colored',
		icon: false,
		autoClose: 10000,
		hideProgressBar: false,
		closeOnClick: false,
		pauseOnHover: true,
		draggable: false,
		closeButton: ({ closeToast }) => <NotificationClose onClose={onClose} closeToast={closeToast} />,
		...options
	});

const error = ({ title, message, onClose, options = {} }) =>
	toast.error(<ErrorNotification title={title} message={message} />, {
		position: 'top-center',
		transition: Slide,
		theme: 'colored',
		icon: false,
		autoClose: 10000,
		hideProgressBar: false,
		closeOnClick: false,
		pauseOnHover: true,
		draggable: false,
		closeButton: ({ closeToast }) => <NotificationClose onClose={onClose} closeToast={closeToast} />,
		...options
	});

const Notification = {
	success,
	info,
	warning,
	error
};

export default Notification;
