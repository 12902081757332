export default {
	required: 'Common_ValidationRequired',
	whitespace: 'Common_ValidationWhitespaces',
	shortStringMin: 'Common_ValidationShortStringMin',
	shortStringMax: 'Common_ValidationShortStringMax',
	longStringMin: 'Common_ValidationLongStringMin',
	longStringMax: 'Common_ValidationLongStringMax',
	emailFormat: 'Common_EmailValidationFormat',
	usernameMax: 'Common_LoginValidationMax',
	usernameFormat: 'Common_LoginValidationFormat',
	passwordFormat: 'Common_PasswordValidationFormat',
	passwordSimilarity: 'PasswordSimilarityValidation'
};
