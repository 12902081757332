import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Collapse, TextField } from '@mui/material';
import { useDebounce, useIsMount, useLocale } from '@sonar-web/common/src/hooks';
import { YupSchemaDefinitions } from '@sonar-web/common';
import SaveAsNewTemplate from './SaveAsNewTemplate';
import SaveInCurrentTemplate from './SaveInCurrentTemplate';
import SaveSettingsType from './SaveSettingsType';
import DontSave from './DontSave';

const Wrapper = styled('div')({
	display: 'flex',
	flexDirection: 'column',
	'& .tileButtonSelectableWrapper': {
		marginBottom: '0.8rem'
	}
});

const nameSchema = YupSchemaDefinitions.shortString();
/**
 * addTemplate
 * - null - dodaj sam raport
 * - true - dodaj nowy szablon
 * - false - edytuj istniejący szablon
 */
const SaveSettings = ({ stepIndex, useConfigurator, configuratorData, updateConfiguratorData }) => {
	const isMount = useIsMount();
	const { translate } = useLocale();
	const { updateStepValid, isEditing } = useConfigurator();

	const [toggleName, setToggleName] = useState(
		configuratorData['addTemplate'] ?? isEditing ? SaveSettingsType.DontSave.value : SaveSettingsType.Empty.value
	);
	const [templateName, setTemplateName] = useState(configuratorData['templateName'] ?? '');
	const [error, setError] = useState('');
	const debounceTemplateName = useDebounce(templateName);

	const handleValidation = (v, err) => {
		const isValid = v !== '' && !err;

		updateStepValid(stepIndex, isValid);
		setTemplateName(v);
		setError(isValid ? '' : err);
	};

	const handleChange = (e) => {
		const name = e.target.value;

		try {
			nameSchema.validateSync(name);
			handleValidation(name);
		} catch (err) {
			handleValidation(name, err.errors[0]);
		}
	};

	useEffect(() => {
		updateConfiguratorData({ ['addTemplate']: toggleName });
		updateStepValid(
			stepIndex,
			(toggleName === SaveSettingsType.SaveAsNewTemplate.value && templateName !== '' && error === '') ||
				toggleName === SaveSettingsType.DontSave.value ||
				toggleName === SaveSettingsType.SaveInCurrentTemplate.value
		);
	}, [toggleName]);

	useEffect(() => {
		if (isMount) return;
		updateConfiguratorData({ ['templateName']: templateName });
	}, [debounceTemplateName]);

	return (
		<Wrapper>
			<DontSave toggleName={toggleName} setToggleName={setToggleName} />
			{!isEditing && configuratorData['templateId'] != null && (
				<SaveInCurrentTemplate toggleName={toggleName} setToggleName={setToggleName} />
			)}
			<SaveAsNewTemplate toggleName={toggleName} setToggleName={setToggleName} />
			<Collapse in={toggleName === SaveSettingsType.SaveAsNewTemplate.value} mountOnEnter>
				<div>
					<TextField
						autoFocus
						margin='normal'
						name='templateName'
						label={translate('Common_TemplateName')}
						type='text'
						size='small'
						fullWidth
						value={templateName}
						onChange={handleChange}
						error={error !== ''}
						helperText={error !== '' && translate(error)}
					/>
				</div>
			</Collapse>
		</Wrapper>
	);
};

export default SaveSettings;
