/* eslint-disable react/display-name */
import React from 'react';
import { Chip } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(3),
		backgroundColor: '#f1f1f1'
	},
	label: {
		fontSize: theme.typography.caption.fontSize
	}
}));

const ProfileCardChip = (props) => {
	const classes = useStyles();

	return <Chip size='small' classes={{ root: classes.root, label: classes.label }} {...props} />;
};

export default ProfileCardChip;
