import BaseButton from '../Buttons/BaseButton';

const FormCancel = ({ children, onClick, name = 'formCancel', pending = false, ...rest }) => {
	return (
		<BaseButton name={name} pending={pending} variant='outlined' onClick={onClick} {...rest}>
			{children || 'Common_Cancel'}
		</BaseButton>
	);
};

export default FormCancel;
