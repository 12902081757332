import React from 'react';
import { CircularProgress, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { merge } from 'lodash-es';

const useStyles = makeStyles(() => ({
	buttonRoot: {
		position: 'relative'
	},
	progressRoot: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -16,
		marginLeft: -16
	}
}));

const BaseIconButton = React.forwardRef(function BaseIconButton(
	{ icon, iconProps, disabled = false, pending = false, progress = false, classes, ...rest },
	ref
) {
	const styles = useStyles();
	const Icon = icon;
	const progressSize = 32;

	return (
		<IconButton
			ref={ref}
			size='small'
			disabled={disabled || pending}
			classes={merge(
				{
					root: styles.buttonRoot
				},
				classes
			)}
			{...rest}>
			<>
				<Icon fontSize='small' {...iconProps} />
				{progress && pending && (
					<CircularProgress size={progressSize} classes={{ root: styles.progressRoot }} />
				)}
			</>
		</IconButton>
	);
});

BaseIconButton.muiName = IconButton.muiName;
export default BaseIconButton;
