import { useRef, useState } from 'react';
import {
	Button,
	ButtonGroup,
	ClickAwayListener,
	Grow,
	Paper,
	Popper,
	MenuItem,
	MenuList,
	Typography
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export default function MultiActionButton({
	color = 'primary',
	variant = 'outlined',
	options,
	onOptionClick,
	menuButtonName = 'multiActionButton_Menu',
	menuButtonProps = {},
	...rest
}) {
	const [open, setOpen] = useState(false);
	const anchorRef = useRef(null);

	const handleMenuItemClick = (onSelect) => {
		onSelect();
		setOpen(false);
	};

	const handleToggle = () => setOpen((prevOpen) => !prevOpen);

	const handleClose = (event) => {
		if (anchorRef.current?.contains(event.target)) return;
		setOpen(false);
	};

	return (
		<>
			<ButtonGroup ref={anchorRef}>
				<Button
					name={options[0].elementName}
					color={color}
					variant={variant}
					onClick={options[0].onSelect}
					size='small'
					sx={{
						fontSize: 'caption.fontSize',
						paddingInline: '3rem'
					}}
					{...rest}>
					{options[0].value}
				</Button>
				<Button
					name={menuButtonName}
					style={{ width: 15 }}
					color={color}
					variant={variant}
					aria-controls={open ? 'split-button-menu' : undefined}
					aria-expanded={open ? 'true' : undefined}
					aria-haspopup='menu'
					onClick={handleToggle}
					{...menuButtonProps}>
					<ArrowDropDownIcon />
				</Button>
			</ButtonGroup>
			<Popper
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
				style={{ zIndex: 1501 }}>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
						}}>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList id='split-button-menu'>
									{options.slice(1).map((option) => (
										<MenuItem
											key={option.value}
											data-at={option.elementName}
											onClick={() => handleMenuItemClick(option.onSelect)}
											sx={{
												fontSize: 'caption.fontSize'
											}}
											{...rest}>
											<Typography variant='inherit' color='primary'>
												{option.value}
											</Typography>
										</MenuItem>
									))}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</>
	);
}
