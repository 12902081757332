import { Collapse } from '@mui/material';
import { useLocale } from '@sonar-web/common/src/hooks';
import SettingsTimeFormat from './SettingsTimeFormat';
import SettingsTimeUnitSeparator from './SettingsTimeUnitSeparator';
import SettingsTemplate from './SettingsTemplate';
import SettingsMinuteHourFormat from './SettingsMinuteHourFormat';
import { minuteHourFormats, timeFormats, timeUnitSeparators, sampleDate } from './constants';
import { getTimeFormat, dateTimeToString } from './helpers';

const SettingsTime = ({ updateSettings, current, dependsValue = true, settingName = 'timeFormat' }) => {
	const { translate } = useLocale();

	const predefinedOptions = [
		{
			value: 'HH:mm',
			label: `${translate('Common_TimeFormat24H')} (${dateTimeToString(sampleDate, 'HH:mm')})`
		},
		{
			value: 'hh:mm tt',
			label: `${translate('Common_TimeFormat12h')} (${dateTimeToString(sampleDate, 'hh:mm tt')})`
		}
	];

	const defaultManualSettings = {
		timeFormat: timeFormats.time24h,
		minuteHourFormat: minuteHourFormats.double,
		timeUnitSeparator: timeUnitSeparators.colon
	};

	return (
		<Collapse in={Boolean(dependsValue)} mountOnEnter unmountOnExit>
			<div>
				<SettingsTemplate
					sampleDate={sampleDate}
					updateSettings={updateSettings}
					current={current}
					defaultManualSettings={defaultManualSettings}
					predefinedOptions={predefinedOptions}
					manualSettingsToFormat={getTimeFormat}
					settingName={settingName}
					label={'Common_TimeFormat'}
					renderManualModeItems={({ currentManualSettings, updateCurrentManualSettings }) => (
						<>
							<SettingsTimeFormat
								updateSettings={updateCurrentManualSettings}
								current={currentManualSettings}
							/>
							<SettingsMinuteHourFormat
								updateSettings={updateCurrentManualSettings}
								current={currentManualSettings}
							/>
							<SettingsTimeUnitSeparator
								updateSettings={updateCurrentManualSettings}
								current={currentManualSettings}
							/>
						</>
					)}
				/>
			</div>
		</Collapse>
	);
};

export default SettingsTime;
