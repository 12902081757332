import { lazy, Suspense, useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from '@emotion/styled';
import useError from './useError';
import { ERROR_TYPES } from '../../constants/errorTypes';

const Wrapper = styled('div')(({ theme }) => ({
	position: 'absolute',
	top: 0,
	left: 0,
	height: '100%',
	width: '100%',
	background: theme.palette.background.default,
	display: 'grid',
	placeItems: 'center',
	zIndex: theme.zIndex.modal + 1,
	overflow: 'auto',
	padding: '2rem 0'
}));

const ErrorNotifier = () => {
	const { clearPendingError, pending, error } = useError();
	const isServerError = !error || error?.type === ERROR_TYPES.server;

	const container = document.getElementById('root');

	useEffect(() => {
		document.body.style.overflow = pending ? 'hidden' : 'initial';
		document.documentElement.style.overflow = pending ? 'hidden' : 'initial';
	}, [pending, container]);

	useEffect(() => {
		return () => {
			if (pending) clearPendingError();
			document.body.style.overflow = 'initial';
			document.documentElement.style.overflow = 'initial';
		};
	}, []);

	if (!pending || !isServerError) return null;

	const ErrorView = viewByCode(error?.code);

	return createPortal(
		<Wrapper className='errorNotifier'>
			<Suspense fallback=''>
				<ErrorView error={error} />
			</Suspense>
		</Wrapper>,
		container
	);
};

export default ErrorNotifier;

function viewByCode(code) {
	let cmp = '';

	switch (code) {
		case 401:
			cmp = 'ErrorView401';
			break;
		case 403:
			cmp = 'ErrorView403';
			break;
		case 404:
			cmp = 'ErrorView404';
			break;
		case 500:
			cmp = 'ErrorView500';
			break;
		case 503:
			cmp = 'ErrorView503';
			break;
		case code > 500:
			cmp = 'ErrorView5xx';
			break;

		default:
			cmp = 'ErrorView4xx';
			break;
	}

	return lazy(() => import(`./ErrorViews/${cmp}`));
}
