import React from 'react';
import { SortersSelector } from '..';
import ToolbarDivider from '../ToolbarDivider/ToolbarDivider';

const Sorting = ({ slice, sorters, filters, noData }) => {
	if (!sorters) return null;
	return (
		<>
			<SortersSelector slice={slice} sorters={sorters} hide={noData} />
			{filters && <ToolbarDivider hide={noData} />}
		</>
	);
};

export default Sorting;
