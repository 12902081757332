import React from 'react';
import { Typography } from '@mui/material';

const ReportSubmitSummaryItem = ({ text, dataat }) => {
	return (
		<Typography component='li' color='textSecondary' variant='body2' style={{ marginBottom: 10 }} data-at={dataat}>
			{text}
		</Typography>
	);
};

export default ReportSubmitSummaryItem;
