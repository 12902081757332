import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TabPanelBase from '../TabPanelBase/TabPanelBase';
import clsx from 'clsx';
import { useLocale } from '../../hooks';

const useStyles = makeStyles((theme) => ({
	root: {
		borderBottom: `1px solid ${theme.palette.grey[400]}`
	},
	hidden: {
		display: 'none'
	}
}));

const TabsBase = ({ tabs, hidden, initialTabValue = 0, handleTabChange, ...other }) => {
	const classes = useStyles();
	const [tabValue, setTabValue] = useState(initialTabValue);
	const { translate } = useLocale();

	const handleChange = (_, newValue) => (handleTabChange ? handleTabChange(newValue) : setTabValue(newValue));

	useEffect(() => {
		if (tabValue !== initialTabValue) setTabValue(initialTabValue);
	}, [initialTabValue]);

	return (
		<>
			<Tabs
				value={hidden ? false : tabValue}
				onChange={handleChange}
				indicatorColor='primary'
				classes={{ root: classes.root }}
				className={clsx({ [classes.hidden]: hidden })}
				{...other}>
				{tabs.map((tab) => {
					if (tab.tabConfig) return tab.tabConfig(tab.Id);
					return <Tab name={tab.name || null} key={tab.Id} label={translate(tab.label)} value={tab.Id} />;
				})}
			</Tabs>
			{tabs.map((tab) => {
				return (
					<TabPanelBase
						key={tab.Id}
						value={tabValue}
						index={tab.Id}
						disableRemoveFromDOM={tab.disableRemoveFromDOM}
						disableInnerTopPadding={tab.disableInnerTopPadding}>
						{tab.content}
					</TabPanelBase>
				);
			})}
		</>
	);
};

export default TabsBase;
