import React from 'react';
import { Collapse } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import HorizontalSpacer from '../HorizontalSpacer/HorizontalSpacer';

const useStyles = makeStyles((theme) => ({
	emhesizeText: { fontWeight: theme.typography.fontWeightMedium },
	sizeSm: {
		maxWidth: theme.breakpoints.values.sm,
		margin: '0 auto'
	},
	collapseWrapper: {
		position: 'relative'
	},
	'@keyframes slideInArrow': {
		'0%': {
			opacity: 0,
			transform: 'translateX(0)'
		},
		'100%': {
			opacity: 1,
			transform: 'translateX(10vw)'
		}
	},
	'@keyframes slideInInnerArrow': {
		'0%': {
			transform: 'translateX(0)'
		},
		'100%': {
			transform: 'translateX(calc(10vw - 3px))'
		}
	},
	inlineWrapper: {
		'&::before': {
			content: '""',
			position: 'absolute',
			width: 0,
			height: 0,
			left: 0,
			top: 0,
			opacity: 0,
			animation: `$slideInArrow 500ms ${theme.transitions.easing.easeInOut}`,
			animationIterationCount: 1,
			animationFillMode: 'forwards',
			animationDelay: '0.3s',
			borderTop: `20px solid ${theme.palette.primary.main}`,
			borderLeft: '20px solid transparent',
			borderRight: '0px solid transparent'
		},
		'&::after': {
			content: '""',
			position: 'absolute',
			width: 0,
			height: 0,
			top: -4,
			left: 0,
			animation: `$slideInInnerArrow 500ms ${theme.transitions.easing.easeInOut}`,
			animationIterationCount: 1,
			animationFillMode: 'forwards',
			animationDelay: '0.3s',
			borderTop: `22px solid ${theme.palette.primaryLight.main}`,
			borderLeft: '22px solid transparent',
			borderRight: '0px solid transparent'
		}
	}
}));

const Collapsible = ({ toggle, children, includeListItemAnimation, size, ...rest }) => {
	const classes = useStyles();
	const isInline = size === 'sm';

	return (
		<Collapse in={toggle} {...rest} classes={{ wrapper: classes.collapseWrapper }}>
			{isInline && <HorizontalSpacer size={6} />}
			<div
				className={clsx({
					[classes.inlineWrapper]: includeListItemAnimation,
					[classes.sizeSm]: isInline
				})}>
				{children}
			</div>
			<HorizontalSpacer size={6} />
		</Collapse>
	);
};

export default Collapsible;
