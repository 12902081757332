import { createSlice } from '@reduxjs/toolkit';
import { pageDescriptor, getSliceFilters } from '@sonar-web/common';
import { fetchLocationNodeReadsDevices } from './api';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';

const slice = 'locationNodeReadsDevicesSearch';

export const locationNodeReadsDevicesSearchSlice = createSlice({
	name: slice,
	initialState: {
		pageDescriptor,
		fetchPending: false,
		locationNodeReadsDevices: [],
		totalCount: 0,
		hasMore: true,
		reload: false,
		reset: true
	},
	reducers: {
		fetchLocationNodeReadsDevicesSuccess: (state, action) => {
			let fetched = [...action.payload.elements];

			state.locationNodeReadsDevices = state.reset ? fetched : state.locationNodeReadsDevices.concat(fetched);
			state.totalCount = action.payload.totalCount;
			state.fetchPending = false;
		},
		fetchLocationNodeReadsDevicesPending: (state) => {
			state.fetchPending = true;
		},
		setHasMore: (state) => {
			state.hasMore = state.totalCount > state.pageDescriptor.Offset + state.pageDescriptor.Limit;
		},
		setResetAndReload: (state) => {
			state.locationNodeReadsDevices = [];
			state.reload = true;
			state.reset = true;
			state.hasMore = true;
			state.pageDescriptor.Offset = 0;
			state.pageDescriptor.FilterDescriptors = [];
			state.pageDescriptor.SortDescriptors = [];
		},
		setResetToFirstPage: (state) => {
			state.locationNodeReadsDevices = [];
			state.reload = true;
			state.reset = true;
			state.hasMore = true;
			state.pageDescriptor.Offset = 0;
		},
		setAppend: (state, action) => {
			state.reset = !action.payload;
			if (action.payload) state.pageDescriptor.Offset += state.pageDescriptor.Limit;
			else state.pageDescriptor.Offset = 0;
		},
		setReload: (state) => {
			state.reload = true;
		}
	}
});

export const {
	fetchLocationNodeReadsDevicesSuccess,
	fetchLocationNodeReadsDevicesPending,
	setHasMore,
	setResetAndReload,
	setResetToFirstPage,
	setAppend,
	setReload
} = locationNodeReadsDevicesSearchSlice.actions;

export const fetchLocationNodeReadsDevicesAsync = () => async (dispatch, getState) => {
	let response;

	try {
		dispatch(fetchLocationNodeReadsDevicesPending());

		const locationNode = getState().locationNodes.selected;
		let pd = Object.assign({}, getState().locationNodeReadsDevicesSearch.pageDescriptor);

		pd.FilterDescriptors = getSliceFilters(getState(), slice);
		pd.SortDescriptors = [{ Member: 'numberToSort', ListSortDirection: 1 }];

		response = await fetchLocationNodeReadsDevices(locationNode.id, pd);
	} catch (error) {
		dispatch(addErrorAsync({ slice, error }));
		return;
	}

	dispatch(fetchLocationNodeReadsDevicesSuccess(response));
	dispatch(setHasMore());
};

export const selectLocationNodeReadsDevices = (state) => {
	return {
		dataRows: state.locationNodeReadsDevicesSearch.locationNodeReadsDevices,
		pending: state.locationNodeReadsDevicesSearch.fetchPending,
		hasMore: state.locationNodeReadsDevicesSearch.hasMore,
		reload: state.locationNodeReadsDevicesSearch.reload,
		totalCount: state.locationNodeReadsDevicesSearch.totalCount
	};
};

export default locationNodeReadsDevicesSearchSlice.reducer;
