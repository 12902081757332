import { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import DatePeriod from '../../enums/DatePeriod';
import TileButtonSelectable from '../Tiles/TileButtonSelectable';
import DatePeriodRangeDays from './DatePeriodRangeDays';

const Wrapper = styled('div')({
	'& .cardsGridBase': {
		flex: 0
	}
});

const Grid = styled('div')({
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'wrap',
	gap: '1rem',
	'& .tileButtonSelectableWrapper': {
		flex: 1,
		minWidth: '300px'
	}
});

const DatePeriodRange = ({ options, selected, setSelected }) => {
	const [customValue, setCustomValue] = useState(selected?.days ?? null);
	const [dateRangePickerVisible, setDateRangePickerVisible] = useState(false);

	const customSelected = selected?.option === DatePeriod.Own.name;

	const handleClick = (record) => {
		setDateRangePickerVisible(record.value === DatePeriod.Own.name);
		if (selected?.option === record.value) return;

		const customClicked = record.value === DatePeriod.Own.name;
		const newCustomValue = customClicked ? 1 : null;

		setSelected({ option: record.value, days: newCustomValue });
		setCustomValue(newCustomValue);
	};

	const handleConfirmRange = (days) => {
		setCustomValue(days);
		setDateRangePickerVisible(false);
	};

	useEffect(() => {
		if (!customSelected) return;
		if (selected.days === customValue) return;

		setSelected({ option: selected.option, days: customValue });
	}, [customValue]);

	useEffect(() => {
		if (!selected) return;
		if (selected.days === customValue) return;

		setCustomValue(selected.days);
	}, [selected?.days]);

	return (
		<Wrapper>
			<Grid>
				{options.map((option) => {
					const isOwn = option.value === DatePeriod.Own.name;
					return (
						<TileButtonSelectable
							key={option.value}
							title={option.name}
							subtitle={option.rangeFormat(customSelected ? selected.days : null)}
							radio={true}
							selected={selected?.option === option.value}
							onClick={() => handleClick(option)}
							subtitleProps={{ whiteSpace: 'break-spaces' }}
							data-at={`${option.value}${isOwn ? selected?.days : ''}`}
						/>
					);
				})}
			</Grid>
			<DatePeriodRangeDays value={customValue} onChange={handleConfirmRange} show={dateRangePickerVisible} />
		</Wrapper>
	);
};

export default DatePeriodRange;
