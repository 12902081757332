import { apiBase } from '@sonar-web/common';
import { REST_API_PATHS } from '@sonar-web/common/src/constants/system';
import AuthService from '@sonar/auth/src/AuthService';

const path = REST_API_PATHS.REPORTS;

export async function fetchReport(id) {
	const { data } = await apiBase(`reports/${id}`, 'get', {
		path
	});

	return data;
}

export async function fetchReportJsonData(url, config) {
	const token = AuthService.getToken();

	const { data } = await apiBase(`${url}&token=${token}`, 'get', { path: '', ...config });

	return data;
}
