import React from 'react';
import ConfigurationDataCardStatusItem from './ConfigurationDataCardStatusItem';
import CardBase from '../../../Cards/CardBase';
import useConfiguratorValidation from '../useConfiguratorValidation';

const ConfigurationDataCard = ({ record, handleEditConfigurationData, ...rest }) => {
	const { getSectionValidationStatus } = useConfiguratorValidation();
	const statusProps = getSectionValidationStatus(record);

	const handleEditConfigurationDataClick = (e) => {
		e.stopPropagation();

		if (handleEditConfigurationData) handleEditConfigurationData(record.id);
	};

	return (
		<CardBase
			record={record}
			renderStatus={() => <ConfigurationDataCardStatusItem {...statusProps} />}
			onClick={handleEditConfigurationDataClick}
			{...rest}
		/>
	);
};

export default ConfigurationDataCard;
