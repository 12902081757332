import { apiBase } from '@sonar-web/common';
import { REST_API_PATHS } from '@sonar-web/common/src/constants/system';

const path = REST_API_PATHS.HEATMETER;

async function fetchRead(id) {
	const { data } = await apiBase(`reads/${id}`, 'get', {
		path
	});

	return data;
}

async function addRead(read) {
	const response = await apiBase(`reads`, 'post', {
		path,
		data: read
	});

	const readHeader = response.headers.location;

	if (readHeader)
		return {
			url: readHeader,
			id: readHeader.slice(readHeader.indexOf('/') + 1)
		};

	return response;
}

async function editRead(read) {
	return await apiBase(`reads/${read.id}`, 'put', {
		path,
		data: read
	});
}

async function deleteRead(id) {
	return await apiBase(`reads/${id}`, 'delete', {
		path
	});
}

const ReadService = {
	fetchRead,
	addRead,
	editRead,
	deleteRead
};

export default ReadService;
