import { apiBase } from '@sonar-web/common';
import { REST_API_PATHS } from '@sonar-web/common/src/constants/system';

const path = REST_API_PATHS.STATIONARY;

export async function fetchDiagnosticTerminalReads({ networkId, deviceId, pageDescriptor }) {
	const { data } = await apiBase(`networks/${networkId}/terminal/${deviceId}/diagnosticreads`, 'get', {
		path,
		params: { pageDescriptor }
	});
	return data;
}

export async function fetchTerminalNetworks({ deviceId }) {
	const { data } = await apiBase(`terminal/${deviceId}/networks`, 'get', {
		path
	});
	return data;
}
