const FilterTypes = {
	string: 0,
	int: 1,
	long: 2,
	datetime: 3,
	boolean: 4,
	bool: 4,
	guid: 5
};

export default FilterTypes;
