import { apiBase } from '@sonar-web/common';
import { REST_API_PATHS } from '@sonar-web/common/src/constants/system';

const path = REST_API_PATHS.TCP;

export async function fetchTcpDevice(id) {
	const { data } = await apiBase(`devices/${id}`, 'get', {
		path
	});

	return data;
}

export async function addTcpDevice(tcpDevice) {
	const response = await apiBase(`devices`, 'post', {
		path,
		data: tcpDevice
	});

	const tcpDeviceHeader = response.headers.location;

	if (tcpDeviceHeader)
		return {
			url: tcpDeviceHeader,
			id: tcpDeviceHeader.slice(tcpDeviceHeader.indexOf('/') + 1)
		};

	return response;
}

export async function editTcpDevice(tcpDevice) {
	const data = await apiBase(`devices/${tcpDevice.id}`, 'put', {
		path,
		data: tcpDevice
	});

	return data;
}

export async function deleteTcpDevice(id) {
	const data = await apiBase(`devices/${id}`, 'delete', {
		path
	});

	return data;
}

export async function assignrootdevice(payload) {
	return await apiBase(`networks/${payload.networkId}/assignrootdevice`, 'put', {
		path: REST_API_PATHS.STATIONARY,
		data: payload
	});
}

export async function reassignrootdevice(payload) {
	return await apiBase(`networks/${payload.networkId}/changerootdevice`, 'put', {
		path: REST_API_PATHS.STATIONARY,
		data: payload
	});
}

export async function removeFromNetworkAsync(devices) {
	return await apiBase(`devices/unassignfromnetwork`, 'put', {
		path,
		data: devices
	});
}
