import { Box, Typography } from '@mui/material';
import { formatDateTimeSecondsPrecision, TableListColumn } from '@sonar-web/common';
import ReadTypeByPeriod from './ReadTypeByPeriod';

const DateAndTypeColumn = ({ type, period, date }) => {
	return (
		<TableListColumn width={220} disableLeftPadding>
			<Box display='flex' flexDirection='column'>
				<Typography variant='body2' color='textPrimary' data-at='date'>
					{formatDateTimeSecondsPrecision(date)}
				</Typography>
				<Typography variant='caption' color='textSecondary' data-at-params={`type|${type}|${period}`}>
					<ReadTypeByPeriod type={type} period={period} />
				</Typography>
			</Box>
		</TableListColumn>
	);
};

export default DateAndTypeColumn;
