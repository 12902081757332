import { slices } from '../Constants/Module';
import { filterOperators, pageDescriptor } from '@sonar-web/common';
import { useFilterDescriptor } from '@sonar-web/common/src/hooks';
import { fetchHeatallocatorReads } from '../Reads/heatallocatorReadsApi';
import HeatallocatorReadsLocationPage from '../Reads/HeatallocatorReadsLocationPage';

const useLocationNodeReadsDeps = () => {
	const { createFilter } = useFilterDescriptor();

	const fetchLastRead = (deviceId, locationId, params) => {
		const filterConfig = [
			{
				member: { name: 'heatAllocatorId', type: 'guid' },
				value: deviceId,
				operator: filterOperators.equal
			},
			{
				member: { name: 'measurementPointId', type: 'guid' },
				value: locationId,
				operator: filterOperators.equal
			}
		];

		const filters = createFilter(filterConfig);

		if (params?.['date']) filters.push(params['date'][0]);

		return fetchHeatallocatorReads({
			...pageDescriptor,
			FilterDescriptors: filters,
			Limit: 1,
			SortDescriptors: [{ Member: 'date', ListSortDirection: 'Desc' }]
		});
	};

	return {
		Component: HeatallocatorReadsLocationPage,
		slice: slices.heatallocatorReads,
		fetchLastRead: fetchLastRead,
		getLink: (deviceId) => `/heatallocators/${deviceId}`,
		readObjectMap: {
			value: (data) => data.bearing,
			unit: '',
			deviceId: 'heatAllocatorId'
		}
	};
};

export default useLocationNodeReadsDeps;
