import { fetchWatermeterTypes } from '../WatermeterTypesSearch/api';
import { sidebarComponents, sidebarType } from '../WatermeterType/sidebar';
import {
	deleteWatermeterTypeAsync,
	selectDeleteWatermeterTypePending,
	resetWatermeterTypeData
} from '../WatermeterType/watermeterTypeSlice';

export default () => {
	return {
		sidebarType: sidebarType,
		sidebarComponents: sidebarComponents,
		fetchDeviceTypes: fetchWatermeterTypes,
		deleteDeviceTypeAsync: deleteWatermeterTypeAsync,
		selectDeleteDeviceTypePending: selectDeleteWatermeterTypePending,
		resetDeviceTypeData: resetWatermeterTypeData
	};
};
