import React from 'react';
import clsx from 'clsx';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useLocale } from '@sonar-web/common/src/hooks';

const useStyles = makeStyles((theme) => ({
	'@keyframes arrowUp': {
		'0%': {
			opacity: 0,
			transform: 'translateY(5px)'
		},
		'50%': {
			opacity: 1,
			transform: 'translateY(0px)'
		},
		'100%': {
			opacity: 0,
			transform: 'translateY(-5px)'
		}
	},
	'@keyframes arrowDown': {
		'0%': {
			opacity: 0,
			transform: 'translateY(-5px) rotate(180deg)'
		},
		'50%': {
			opacity: 1,
			transform: 'translateY(0px) rotate(180deg) '
		},
		'100%': {
			opacity: 0,
			transform: 'translateY(5px) rotate(180deg)'
		}
	},
	header: {
		width: '100%',
		fontWeight: theme.typography.fontWeightRegular,
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer'
	},
	openCloseIcon: {
		marginLeft: theme.spacing(2)
	},
	openCloseIconUp: {
		animation: '$arrowUp 1500ms ease-in-out infinite'
	},
	openCloseIconDown: {
		animation: '$arrowDown 1500ms ease-in-out infinite'
	}
}));

const BottomBarOpener = ({ text, collapsed, setCollapsed }) => {
	const classes = useStyles();
	const { translate } = useLocale();

	const handleCollapse = () => setCollapsed(!collapsed);

	return (
		<Typography variant='h6' classes={{ root: classes.header }} onClick={handleCollapse} data-at='bottomBarName'>
			{translate(text)}
			<ExpandLessIcon
				classes={{
					root: clsx(classes.openCloseIcon, {
						[classes.openCloseIconUp]: collapsed,
						[classes.openCloseIconDown]: !collapsed
					})
				}}
			/>
		</Typography>
	);
};

export default BottomBarOpener;
