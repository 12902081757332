import { useMemo } from 'react';
import { useLocale } from '@sonar-web/common/src/hooks';
import StatsHelper from '../Helpers/StatsHelper';
import StatisticsSettingsItem from './StatisticsSettingsItem';
import LayerTypeIcon from '../Icons/LayerTypeIcon';

const StatisticsSettingsLayer = ({ filters }) => {
	const { translate } = useLocale();

	const { text, type } = useMemo(() => {
		const key = StatsHelper.getSettingsLayerTextByInterfaceType(Number(filters.layer));
		return { type: key, text: translate(key) };
	}, [filters.layer]);

	return <StatisticsSettingsItem Icon={LayerTypeIcon} text={text} dataat={`deviceOption|${type}`} />;
};

export default StatisticsSettingsLayer;
