import LicenseItem from './LicenseItem';
import BillingCoinIcon from '@sonar-web/common/src/icons/BillingCoinIcon';

const Billing = ({ pending, data }) => {
	if (!data) return <LicenseItem pending={pending} data={null} />;

	const billingText = data.billingEnabled ? 'Enabled' : 'Disabled';
	const billingCenterText = data.billingCenterEnabled ? 'Enabled' : 'Disabled';

	return (
		<LicenseItem
			pending={pending}
			data={{
				Icon: BillingCoinIcon,
				title: 'AtBillings',
				description: 'LicenseBillingDescription',
				primaryData: {
					value: billingText,
					text: 'BillingOwn'
				},
				secondaryData: {
					value: billingCenterText,
					text: 'BillingCenter'
				},
				visualizationData: {
					value: null
				}
			}}
		/>
	);
};

export default Billing;
