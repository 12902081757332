import React, { useState, useEffect } from 'react';
import { TextField, Autocomplete } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '@sonar-web/common/src/hooks';
import BaseSelectMenuItem from '@sonar-web/common/src/components/BaseSelectMenuItem/BaseSelectMenuItem';

const useStyles = makeStyles((theme) => ({
	option: {
		padding: '0px',
		fontSize: '14px',
		// Hover with light-grey
		'&[data-focus="true"]': {
			backgroundColor: `${theme.palette.grey[100]} !important`
		},
		// Selected has dark-grey
		'&[aria-selected="true"]': {
			backgroundColor: `${theme.palette.primaryLight.dark} !important`
		}
	},
	paper: {
		boxShadow:
			'0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)'
	},
	textField: {
		'& .MuiInputBase-input::selection': {
			backgroundColor: 'transparent'
		}
	},
	inputRootDisabledOpen: {
		paddingRight: '0px !important'
	}
}));

const CustomSelect = ({
	label,
	options,
	name,
	selectedOption,
	setSelectedOption,
	onChange,
	renderActions,
	displayValue = 'label',
	inputParams,
	formatDisplayValue,
	canOpen = true
}) => {
	const { translate } = useLocale();

	const classes = useStyles();

	const [open, setOpen] = useState(false);

	const onOpen = () => {
		if (canOpen) {
			setOpen(true);
		}
	};

	const onClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		setOpen(false);
	}, [canOpen]);

	return (
        (<Autocomplete
			classes={{
				option: classes.option,
				paper: classes.paper,
				inputRoot: canOpen ? null : classes.inputRootDisabledOpen
			}}
			disableClearable
			open={open}
			onOpen={onOpen}
			onClose={onClose}
			isOptionEqualToValue={(option, value) =>
				value != null ? option[displayValue] === value[displayValue] : false
			}
			value={selectedOption}
			onChange={(event, value) => {
				setSelectedOption(value);
				if (onChange) {
					onChange(event, value);
				}
			}}
			options={options}
			getOptionLabel={(option) => option[displayValue] ?? ''}
			renderInput={(params) => {
				const paramsNew = {
					...params,
					...{
						inputProps: {
							...params.inputProps,
							...{
								value: formatDisplayValue
									? formatDisplayValue(params.inputProps.value)
									: params.inputProps.value
							}
						}
					}
				};

				return (
                    (<TextField
						{...inputParams}
						{...paramsNew}
						name={name}
						label={translate(label)}
						fullWidth
						classes={{
							root: classes.textField
						}}
						slotProps={{
                            input: {
                                ...params.InputProps,
                                readOnly: true,
                                endAdornment: (
                                    <>
                                        {renderActions && renderActions()}
                                        {canOpen ? params.InputProps.endAdornment : null}
                                    </>
                                )
                            }
                        }}
					/>)
                );
			}}
			renderOption={(props, opt) => {
				return (
					<BaseSelectMenuItem component={'div'} value={opt.value} {...props} key={props.id}>
						{translate(opt[displayValue])}
					</BaseSelectMenuItem>
				);
			}}
		/>)
    );
};

export default CustomSelect;
