import moment from 'moment';

export const getStartOfDay = (date) => {
	return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export const getEndOfDay = (date) => {
	return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
};

export const addDays = (date, value) => {
	var result = new Date(date.getTime());
	result.setDate(result.getDate() + value);
	return result;
};

export const addHours = (date, value) => {
	var result = new Date(date.getTime());
	result.setHours(result.getHours() + value);
	return result;
};

export const formatDate = (date) => {
	if (!date) return null;

	return moment(date).format(moment.localeData().longDateFormat('L'));
};

export const isReadDateInRange = (date, start, end) =>
	date.getTime() >= start.getTime() && date.getTime() < end.getTime();

export const groupReadsByPeriod = (readsItems, range, periodInMs) => {
	let groups = [];
	let start = range.start;

	while (1) {
		const end = new Date(start.getTime() + periodInMs);

		const group = {
			range: {
				start,
				end
			},
			reads: readsItems.filter((r) => isReadDateInRange(r.date, start, end))
		};

		groups.push(group);

		if (end.getTime() >= range.end.getTime()) {
			break;
		}

		start = new Date(start.getTime() + periodInMs);
	}

	return groups;
};
