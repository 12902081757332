import { Box } from '@mui/material';
import ButtonOnlyIconCancel from '@sonar-web/common/src/components/Buttons/ButtonOnlyIconCancel';
import ButtonOnlyIconDelete from '../Buttons/ButtonOnlyIconDelete';
import ButtonOnlyIconSubmit from '../Buttons/ButtonOnlyIconSubmit';

const InfoListItemDeleteActions = ({ onCancel, onSubmit, pending, question = false, color }) => {
	const buttonColor = color ?? (question ? 'success' : 'error');

	return (
		<Box display='flex' flex={1} justifyContent='flex-end' alignItems='center' gap='.5rem'>
			<ButtonOnlyIconCancel
				pending={pending}
				name='infoListItemDeleteActionsCancel'
				onClick={onCancel}
				color={buttonColor}
			/>
			{question ? (
				<ButtonOnlyIconSubmit
					progress
					progressProps={{ sx: { color: 'white' } }}
					pending={pending}
					name='infoListItemDeleteActionsSubmit'
					onClick={onSubmit}
					color={buttonColor}
				/>
			) : (
				<ButtonOnlyIconDelete
					progress
					progressProps={{ sx: { color: 'white' } }}
					pending={pending}
					name='infoListItemDeleteActionsSubmit'
					onClick={onSubmit}
					color={buttonColor}
				/>
			)}
		</Box>
	);
};

export default InfoListItemDeleteActions;
