import React from 'react';
import { FormControl, TextField } from '@mui/material';
import { useLocale } from '../../hooks';

const TextFieldMasked = ({ name, label, value, description, formik, MaskComponent, ...rest }) => {
	const { translate } = useLocale();
	const { errors, touched } = formik;
	const error = errors[name];
	const touch = touched[name];
	const hasError = error && error !== '' && touch;

	const errorValue = React.useMemo(() => {
		if (hasError && description) return `${translate(error)} (${translate(description)})`;
		if (hasError) return translate(error);
		if (description) return translate(description);
		return '';
	}, [error, touch]);

	return (
		<FormControl variant='standard'>
			<TextField
				{...rest}
				name={name}
				label={translate(label)}
				data-at-error={error}
				error={hasError}
				helperText={errorValue}
			/>
		</FormControl>
	);
};

export default TextFieldMasked;
