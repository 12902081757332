import { apiBase } from '@sonar-web/common';
import { REST_API_PATHS } from '@sonar-web/common/src/constants/system';

const path = REST_API_PATHS.REPORTS;

async function fetchCurrentNumberOfReports() {
	const { data } = await apiBase(`currentNumberOfReports`, 'get', {
		path
	});

	return data;
}

export async function fetchReportTypes(pageDescriptor) {
	const { data } = await apiBase(`reporttypes`, 'get', {
		path,
		params: { pageDescriptor }
	});

	return data;
}

async function fetchReportType(id) {
	const { data } = await apiBase(`reporttypes/${id}`, 'get', {
		path
	});

	let columns = [];

	try {
		const parsed = JSON.parse(data.data);
		if (data) columns = parsed.columns;
	} catch (e) {
		console.log(e);
	}

	return {
		...data,
		columns: columns
	};
}

const ReportsService = {
	fetchCurrentNumberOfReports,
	fetchReportTypes,
	fetchReportType
};

export default ReportsService;
