import { createContext, useContext, useState } from 'react';

const ListFiltersContext = createContext();

export const ListFiltersContextProvider = ({ children }) => {
	const [anchor, setAnchor] = useState(null);

	const openFilters = (e) => setAnchor(e.currentTarget);

	const closeFilters = () => setAnchor(null);

	const value = { anchor, setAnchor, openFilters, closeFilters };

	return <ListFiltersContext.Provider value={value}>{children}</ListFiltersContext.Provider>;
};

export const useListFiltersContext = () => {
	return useContext(ListFiltersContext);
};

export default ListFiltersContext;
