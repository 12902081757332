import { TableListColumn } from '../../components';
import { Avatar, ListItemAvatar, Typography } from '@mui/material';
import { useLocale } from '../../hooks';
import WarningIcon from '../../icons/WarningIcon';
import styled from '@emotion/styled';

const Wrapper = styled('div')({
	position: 'absolute',
	bottom: 0,
	left: 0,
	width: '100%',
	padding: '10px 20px',
	display: 'flex',
	alignItems: 'center',
	backgroundColor: '#BC0A29',
	borderRadius: '0 0 5px 5px',
	'& svg': {
		color: '#fff'
	},
	'& span': {
		color: '#fff'
	}
});

const Filler = styled('div')({
	width: '100%',
	height: 72,
	display: 'flex'
});

const FileUploadError = ({ values }) => {
	const { translate } = useLocale();

	if (values.size && values.extension) return null;

	const text = errorText(values.size, values.extension);

	return (
		<>
			<Filler />
			<Wrapper>
				<ListItemAvatar>
					<Avatar>
						<WarningIcon fontSize='s' />
					</Avatar>
				</ListItemAvatar>
				<TableListColumn flex centerVertically alignItems='flex-start'>
					<Typography variant='caption' color='textSecondary'>
						{translate(text)}
						<br />
						{translate('Imports_SelectDifferentFile')}
					</Typography>
				</TableListColumn>
			</Wrapper>
		</>
	);
};

export default FileUploadError;

function errorText(size, ext) {
	if (!size && !ext) return 'SelectedFileIsInWrongFormatAndIsTooBig';
	if (!size) return 'SelectedFileIsTooBig';
	return 'SelectedFileHasTheWrongFormat';
}
