import { createSlice } from '@reduxjs/toolkit';
import { slices } from '../Constants/Module';

export const slice = slices.devicesConfigurationsTypes;

export const initialState = {
	selected: null
};

export const devicesConfigurationsTypesSlice = createSlice({
	name: slice,
	initialState: initialState,
	reducers: {
		setSelectedDeviceType: (state, { payload }) => {
			state.selected = payload;
		}
	}
});

export const { setSelectedDeviceType } = devicesConfigurationsTypesSlice.actions;

export const selectSelectedDeviceType = (state) => state.devicesConfigurationsTypes.selected;

export default devicesConfigurationsTypesSlice.reducer;
