import { UserManager, WebStorageStateStore } from 'oidc-client-ts';
import { AUTHORITY, CLIENT_ID } from '@sonar-web/common/src/constants/system';

const getRedirectUri = () => {
	const { origin, pathname, hash } = window.location;
	return `${origin}${pathname}${hash}`;
};

export const userManager = new UserManager({
	authority: AUTHORITY,
	client_id: CLIENT_ID,
	redirect_uri: getRedirectUri(),
	post_logout_redirect_uri: window.location.origin,
	userStore: new WebStorageStateStore({ store: window.sessionStorage }),
	monitorSession: true
});

userManager.events.addAccessTokenExpired(() => {
	console.warn('addAccessTokenExpired');
	userManager.signinRedirect();
});

userManager.events.addSilentRenewError(() => {
	console.error('addSilentRenewError');
	userManager.signinRedirect();
});

export const onSigninCallback = () => {
	window.history.replaceState({}, document.title, window.location.pathname + window.location.hash);
};
