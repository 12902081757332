import { useEffect } from 'react';
import EventBus from '../../helpers/EventBus';

const useListFiltering = ({ slice, callback }) => {
	useEffect(() => {
		const onFilteringChanged = ({ detail }) => {
			if (detail.slice === slice) callback();
		};

		EventBus.on(EventBus.messages.FilteringChanged, onFilteringChanged);

		return () => EventBus.off(EventBus.messages.FilteringChanged, onFilteringChanged);
	}, [callback]);
};

export default useListFiltering;
