import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import styled from '@emotion/styled';
import { getDayOfWeekByValue } from '../../../../../enums/DaysOfWeek';
import { getMonthByValue } from '../../../../../enums/Months';
import CalendarData from './CalendarData';
import Month from './Month';

const Wrapper = styled('div')({
	display: 'grid',
	gridTemplateColumns: 'repeat(4, min-content)',
	justifyContent: 'space-between',
	paddingRight: 24
});

let changeCounter = 0;

const Calendar = ({ year, formValues }) => {
	const [months, setMonths] = useState(null);

	useEffect(() => {
		setMonths(generateData(year, formValues));
		changeCounter++;
	}, [
		formValues.hours?.length,
		formValues.weekdays?.length,
		formValues.monthdays?.length,
		formValues.months?.length,
		year
	]);

	return useMemo(() => {
		if (!months) return null;
		return (
			<Wrapper>
				{months.map((month) => (
					<Month key={month.name} {...month} formValues={formValues} />
				))}
			</Wrapper>
		);
	}, [changeCounter, year]);
};

export default Calendar;

function generateData(year, formValues) {
	let data = [];
	const monthsNames = moment.months();

	for (let i = 0; i < 12; i++) {
		data.push({
			name: monthsNames[i],
			days: generateDays(year, i, formValues)
		});
	}

	return data;
}

function generateDays(year, month, formValues) {
	const date = moment(`${year}-${month + 1}`, 'YYYY-MM');
	const daysCount = date.daysInMonth();
	const firstDay = date.startOf('month').day();

	const days = [];

	if (firstDay !== 1) {
		const max = firstDay === 0 ? 6 : firstDay - 1;
		for (let i = 0; i < max; i++) {
			days.push({
				value: null,
				data: CalendarData.Empty.text
			});
		}
	}

	for (let i = 1; i <= daysCount; i++) {
		days.push({
			value: i,
			data: getCalendarDataByFormValues(year, month, i, formValues)
		});
	}

	return days;
}

function getCalendarDataByFormValues(year, month, day, values) {
	const dayOfWeek = moment(`${year}-${month + 1}-${day}`, 'YYYY-MM-DD').isoWeekday();

	if (values.months?.length > 0) {
		const monthName = monthByNumber(month);
		if (!values.months.includes(monthName)) return CalendarData.MonthWorkMode.text;
	}

	if (values.monthdays?.length > 0) {
		if (!values.monthdays.includes(day)) return CalendarData.DayOfMonthWorkMode.text;
	}

	if (values.weekdays?.length > 0) {
		const dayName = dayOfWeekByNumber(dayOfWeek);
		if (!values.weekdays.includes(dayName)) return CalendarData.DayOfWeekWorkMode.text;
	}

	if (values.hours?.length > 0) return CalendarData.FirstHourWorkMode.text;

	return CalendarData.SecondHourWorkMode.text;
}

function monthByNumber(months) {
	return getMonthByValue(months + 1).name;
}

function dayOfWeekByNumber(days) {
	return getDayOfWeekByValue(days).name;
}
