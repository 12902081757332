import { createSlice, createSelector } from '@reduxjs/toolkit';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import MeshService from './MeshService';
import Module from './Constants/Module';
import { timeout } from '@sonar-web/common';

const slice = Module.slices.hub;
const initialHubData = null;

const getHub = (getState) => getState().hub.hub;

export const hubSlice = createSlice({
	name: slice,
	initialState: {
		addPending: false,
		fetchPending: false,
		deletePending: false,
		deleteSuccess: false,
		hub: initialHubData,
		hubConfigurationState: null,
		fetchHubConfigurationStatePending: false,
		hubInfo: null,
		fetchHubInfoPending: false,
		hubPendingConfigurationPending: false,
		hubPendingConfigurationSuccess: false,
		hubTypePending: false,
		hubType: null,
		assignPending: false
	},
	reducers: {
		fetchHubSucces: (state, { payload }) => {
			state.hub = payload;
			state.fetchPending = false;
		},
		fetchHubPending: (state, { payload }) => {
			state.fetchPending = payload;
		},
		addOrEditHubPending: (state, { payload }) => {
			state.addPending = payload;
		},
		deleteHubSucces: (state) => {
			state.deletePending = false;
			state.deleteSuccess = true;
		},
		deleteHubPending: (state, { payload }) => {
			state.deletePending = payload;
		},
		setHubData: (state, action) => {
			state.hub = action.payload;
		},
		resetHubData: (state) => {
			state.hub = initialHubData;
			state.deleteSuccess = false;
		},
		fetchHubConfigurationStateSucces: (state, { payload }) => {
			state.hubConfigurationState = payload;
			state.fetchHubConfigurationStatePending = false;
		},
		fetchHubConfigurationStatePending: (state, { payload }) => {
			state.fetchHubConfigurationStatePending = payload;
		},
		fetchHubInfoSucces: (state, { payload }) => {
			state.hubInfo = payload;
			state.fetchHubInfoPending = false;
		},
		fetchHubInfoPending: (state, { payload }) => {
			state.fetchHubInfoPending = payload;
		},
		deleteHubPendingConfigurationSucces: (state) => {
			state.hubPendingConfigurationPending = false;
			state.hubPendingConfigurationSuccess = true;
		},
		deleteHubPendingConfigurationPending: (state, { payload }) => {
			state.hubPendingConfigurationPending = payload;
		},
		fetchHubTypeSuccess: (state, { payload }) => {
			state.hubTypePending = false;
			state.hubType = payload;
		},
		fetchHubTypePending: (state, { payload }) => {
			state.hubTypePending = payload;
		},
		resetHubType: (state) => {
			state.hubTypePending = false;
			state.hubType = null;
		},
		assignHubPending: (state, { payload }) => {
			state.assignPending = payload;
		},
		updateHubData: (state, { payload }) => {
			state.hub = { ...state.hub, ...payload };
		}
	}
});

export const {
	fetchHubSucces,
	fetchHubPending,
	addOrEditHubPending,
	deleteHubSucces,
	deleteHubPending,
	setHubData,
	resetHubData,
	fetchHubConfigurationStateSucces,
	fetchHubConfigurationStatePending,
	fetchHubInfoSucces,
	fetchHubInfoPending,
	deleteHubPendingConfigurationSucces,
	deleteHubPendingConfigurationPending,
	fetchHubTypeSuccess,
	fetchHubTypePending,
	resetHubType,
	assignHubPending,
	updateHubData
} = hubSlice.actions;

export const fetchHubAsync = (id) => async (dispatch) => {
	try {
		dispatch(fetchHubPending(true));
		const response = await MeshService.fetchHub(id);
		dispatch(fetchHubSucces(response));
	} catch (error) {
		dispatch(fetchHubPending(false));
		return dispatch(addErrorAsync({ slice, error }));
	}
};

export const addHubAsync = () => async (dispatch, getState) => {
	const hub = getHub(getState);

	try {
		dispatch(addOrEditHubPending(true));
		const response = await MeshService.addHub(hub);
		dispatch(addOrEditHubPending(false));
		return response;
	} catch (error) {
		dispatch(addOrEditHubPending(false));
		return dispatch(addErrorAsync({ slice, error, skipNotificationMembers: ['number', 'hashId'] }));
	}
};

export const editHubAsync = () => async (dispatch, getState) => {
	const hub = getHub(getState);

	try {
		dispatch(addOrEditHubPending(true));
		await MeshService.editHub(hub);
		dispatch(addOrEditHubPending(false));
	} catch (error) {
		dispatch(addOrEditHubPending(false));
		return dispatch(addErrorAsync({ slice, error, skipNotificationMembers: ['number', 'hashId'] }));
	}
};

export const deleteHubAsync = (id) => async (dispatch) => {
	try {
		dispatch(deleteHubPending(true));
		await MeshService.deleteHub(id);
		dispatch(deleteHubSucces());
	} catch (error) {
		dispatch(deleteHubPending(false));
		return dispatch(addErrorAsync({ slice, error }));
	}
};

export const fetchHubConfigurationStateAsync = (id) => async (dispatch) => {
	try {
		dispatch(fetchHubConfigurationStatePending(true));
		const response = await MeshService.fetchHubConfigurationState(id);
		dispatch(fetchHubConfigurationStateSucces(response));
	} catch (error) {
		dispatch(fetchHubConfigurationStatePending(false));
		return dispatch(addErrorAsync({ slice, error }));
	}
};

export const fetchHubInfoAsync = (id) => async (dispatch) => {
	try {
		dispatch(fetchHubInfoPending(true));
		const response = await MeshService.fetchHubInfo(id);
		dispatch(fetchHubInfoSucces(response));
	} catch (error) {
		dispatch(fetchHubInfoPending(false));
		return dispatch(addErrorAsync({ slice, error }));
	}
};

export const deleteHubPendingConfigurationAsync = (id) => async (dispatch) => {
	try {
		dispatch(deleteHubPendingConfigurationPending(true));
		await MeshService.deleteHubPendingConfiguration(id);
		dispatch(deleteHubPendingConfigurationSucces());
		return true;
	} catch (error) {
		dispatch(deleteHubPendingConfigurationPending(false));
		return dispatch(addErrorAsync({ slice, error }));
	}
};

export const fetchHubTypeAsync = (id) => async (dispatch) => {
	try {
		dispatch(fetchHubTypePending(true));
		const response = await MeshService.fetchHubType(id);
		dispatch(fetchHubTypeSuccess(response));
	} catch (error) {
		dispatch(fetchHubTypePending(false));
		return dispatch(addErrorAsync({ slice, error }));
	}
};

export const assignHubAsync = (data) => async (dispatch) => {
	try {
		dispatch(assignHubPending(true));
		const response = await MeshService.assignHubToNetwork(data);
		await timeout(500);
		dispatch(assignHubPending(false));
		return response;
	} catch (error) {
		dispatch(assignHubPending(false));
		return dispatch(addErrorAsync({ slice, error, skipNotificationMembers: ['number'] }));
	}
};

export const replaceHubAsync = (data) => async (dispatch) => {
	try {
		dispatch(assignHubPending(true));
		await MeshService.replaceHubInNetwork(data);
		await timeout(500);
		dispatch(assignHubPending(false));
	} catch (error) {
		dispatch(assignHubPending(false));
		return dispatch(addErrorAsync({ slice, error, skipNotificationMembers: ['number'] }));
	}
};

export const selectHub = createSelector(
	(state) => state.hub,
	(hub) => ({
		hub: hub.hub,
		fetchPending: hub.fetchPending
	})
);

export const selectHubAdd = createSelector(
	(state) => state.hub,
	(hub) => ({
		addPending: hub.addPending,
		addSuccess: hub.addSuccess
	})
);

export const selectDeleteHub = createSelector(
	(state) => state.hub,
	(hub) => ({
		deletePending: hub.deletePending,
		deleteSuccess: hub.deleteSuccess
	})
);

export const selectHubConfigurationState = createSelector(
	(state) => state.hub,
	(hub) => ({
		hubConfigurationState: hub.hubConfigurationState,
		fetchHubConfigurationStatePending: hub.fetchHubConfigurationStatePending
	})
);

export const selectHubInfo = createSelector(
	(state) => state.hub,
	(hub) => ({
		hubInfo: hub.hubInfo,
		fetchHubInfoPending: hub.fetchHubInfoPending
	})
);

export const selectDeleteHubPendingConfiguration = createSelector(
	(state) => state.hub,
	(hub) => ({
		deleteHubPendingConfigurationPending: hub.deleteHubPendingConfigurationPending,
		deleteHubPendingConfigurationSucces: hub.deleteHubPendingConfigurationSucces
	})
);

export const selectHubType = createSelector(
	(state) => state.hub,
	(hub) => ({
		hubTypePending: hub.hubTypePending,
		hubType: hub.hubType
	})
);

export const selectHubAssign = createSelector(
	(state) => state.hub,
	(hub) => ({
		assignPending: hub.assignPending
	})
);

export default hubSlice.reducer;
