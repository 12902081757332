import LocationNodeAdd from '../LocationNode/LocationNodeAdd';
import LocationNodeEdit from '../LocationNode/LocationNodeEdit';
import LocationNodeMove from '../LocationNodeMove/LocationNodeMove';
import LocationNodeDelete from '../LocationNode/LocationNodeDelete';

export const locationNodesSidebarComponents = {
	NodeAdd: {
		id: 2,
		name: 'NodeAdd',
		component: LocationNodeAdd
	},
	NodeEdit: {
		id: 3,
		name: 'NodeEdit',
		component: LocationNodeEdit
	},
	NodeMove: {
		id: 4,
		name: 'NodeMove',
		component: LocationNodeMove
	},
	NodeDelete: {
		id: 5,
		name: 'NodeDelete',
		component: LocationNodeDelete
	}
};

export const getLocationNodesSidebarComponents = () => {
	return Object.values(locationNodesSidebarComponents);
};
