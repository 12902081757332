import { useMemo, useState } from 'react';
import moment from 'moment';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '../../hooks';
import TileBase from '../../features/Tiles/TileBase';
import MonthItem from './MonthItem';

const useStyles = makeStyles((theme) => ({
	titleRoot: {
		fontWeight: theme.typography.fontWeightMedium
	},
	grid: {
		display: 'grid',
		gridTemplateColumns: 'repeat(7, 1fr)',
		alignItems: 'center',
		gap: `12px`,
		padding: theme.spacing(1),
		[theme.breakpoints.down('lg')]: {
			gridTemplateColumns: '1fr 1fr 1fr 1fr'
		}
	},
	tile3Columns: {
		gridColumnEnd: 'span 3'
	}
}));

const monthsValues = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December'
];

const SelectMonthOfYear = ({ single = false, inverse = false, initial = [], onChange, disableTitle, ...rest }) => {
	const classes = useStyles();
	const { translate } = useLocale();

	const [months, setMonths] = useState(initial);

	const monthsTiles = useMemo(
		() =>
			moment.monthsShort().map((m, i) => {
				return { name: m, value: monthsValues[i] };
			}),
		[]
	);

	const handleClick = (v) => {
		if (single) singleSelection(v);
		else multipleSelection(v);
	};

	function singleSelection(v) {
		if (months.length === 1 && months[0] === v) return;

		setMonths([v]);

		if (onChange) onChange([v]);
	}

	function multipleSelection(v) {
		const isSelected = months.includes(v);
		const newMonths = isSelected ? months.filter((d) => d !== v) : [...months, v];

		setMonths(newMonths);

		if (onChange) onChange(newMonths);
	}

	return (
		<TileBase
			hoverEffect={false}
			renderContent={() => {
				return (
					<Box display='flex' flexDirection='column' flex={1} pr={5} pl={5} pt={4} pb={4}>
						{disableTitle && (
							<Typography variant='body2' classes={{ root: classes.titleRoot }}>
								{translate('Common_MonthOfYear')}
							</Typography>
						)}
						<Box className={classes.grid}>
							{monthsTiles.map((month, index) => {
								const selected = inverse ? !months.includes(month.value) : months.includes(month.value);

								return (
									<MonthItem key={index} month={month} onClick={handleClick} selected={selected} />
								);
							})}
						</Box>
					</Box>
				);
			}}
			{...rest}
		/>
	);
};

export default SelectMonthOfYear;
