import { apiBase } from '@sonar-web/common';
import { useFormat } from '@sonar-web/common/src/hooks';

const { format } = useFormat();

export async function addConfig({ api, config }) {
	const { data } = await apiBase(api.add.url, 'post', {
		path: api.add.path,
		data: config
	});

	return data;
}

export async function editConfig({ api, config }) {
	const { data } = await apiBase(api.edit.url.replace('{0}', config.id), 'put', {
		path: api.edit.path,
		data: config
	});

	return data;
}

export async function fetchConfig({ api, deviceId, configData }) {
	const { data } = await apiBase(api.fetch.url.replace('{0}', deviceId), 'get', {
		path: api.fetch.path,
		params: { pendingConfig: configData.pendingConfig }
	});

	return data;
}

export async function fetchDatasForm({ api, deviceTypeName }) {
	const url = format(api.getConfigurationForm.url, deviceTypeName);

	const { data } = await apiBase(url, 'get', {
		path: api.getConfigurationForm.path
	});

	if (typeof data === 'string') return JSON.parse(data);
	return data.configurationForm;
}
