import React from 'react';
import { Typography, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useLocale from '@sonar-web/common/src/hooks/useLocale';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const useStyles = makeStyles(() => ({
	root: {
		fontSize: '1rem',
		margin: '0 2px 0 -1px'
	}
}));

const CardStatusItem = ({ text, color }) => {
	const classes = useStyles();
	const { translate } = useLocale();

	return (
		<Box display='flex' alignItems='center'>
			<FiberManualRecordIcon classes={{ root: classes.root }} style={{ color: color }} />
			<Typography variant='caption' color='textPrimary' data-at={text}>
				{translate(text)}
			</Typography>
		</Box>
	);
};

export default CardStatusItem;
