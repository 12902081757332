import CycleLength from '../../../../../../sonar-web-core/src/mesh/Components/CycleLength';

const ConfiguratorMeshCycleLength = ({ name, form, value, error, helperText }) => {
	const onSelect = (v) => {
		form.setFieldValue(name, v, true);
	};

	return <CycleLength onSelect={onSelect} value={value} error={error} helperText={helperText} />;
};

export default ConfiguratorMeshCycleLength;
