import React from 'react';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TileBase from '../../../Tiles/TileBase';

const useStyles = makeStyles((theme) => ({
	titleRoot: {
		fontWeight: theme.typography.fontWeightMedium
	}
}));

const SelectControlBase = ({ renderContent, title, ...rest }) => {
	const classes = useStyles();

	return (
		<TileBase
			hoverEffect={false}
			renderContent={() => {
				return (
					<Box display='flex' flexDirection='column' flex={1} pr={5} pl={5} pt={4} pb={4}>
						<Typography variant='body2' classes={{ root: classes.titleRoot }}>
							{title}
						</Typography>
						{renderContent()}
					</Box>
				);
			}}
			{...rest}
		/>
	);
};

export default SelectControlBase;
