import React from 'react';

import { useThemeBackroundColorForWhiteFont } from '@sonar-web/common/src/hooks/useThemeColor';
import { useAvatarLetters } from '@sonar-web/common/src/hooks';
import { Avatar, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
	rootText: {
		color: theme.palette.common.white
	}
}));

const PanelListItemAvatarLetter = ({ value = '??' }) => {
	const classes = useStyles();
	const avatarLetters = useAvatarLetters(value);
	const avatarBackground = useThemeBackroundColorForWhiteFont(avatarLetters);

	return (
		<Avatar style={{ backgroundColor: avatarBackground }}>
			<Typography variant='body2' classes={{ root: classes.rootText }}>
				{avatarLetters}
			</Typography>
		</Avatar>
	);
};

export default PanelListItemAvatarLetter;
