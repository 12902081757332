import { lazy } from 'react';
import { Features } from '@sonar/auth';
import { ROUTES } from './Constants/Routes';

const WatermeterPage = lazy(() => import('./Watermeter/WatermeterPage'));
const WaterMeterEdit = lazy(() => import('./Watermeter/WaterMeterEdit'));

export default [
	{
		element: WatermeterPage,
		feature: Features.WatermeterPage.name,
		path: ROUTES.waterMeterDashboard(),
		exact: true,
		name: '{0}'
	},
	{
		element: WaterMeterEdit,
		feature: Features.WatermeterModification.name,
		path: ROUTES.waterMeterEdit(),
		exact: true,
		name: '{0}'
	}
];
