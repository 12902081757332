import { BaseLinearProgress } from '@sonar-web/common';
import AddressesList from './AddressesList';
import FetchServersAddressesData from './FetchServersAddressesData';
import ServersAddressesHeader from './ServersAddressesHeader';

const ServersAddresses = () => {
	return (
		<>
			<ServersAddressesHeader />
			<br />
			<FetchServersAddressesData>
				{({ pending, data }) => (
					<>
						<BaseLinearProgress pending={pending} />
						{data && <AddressesList data={data} />}
					</>
				)}
			</FetchServersAddressesData>
		</>
	);
};

export default ServersAddresses;
