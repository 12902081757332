import { SaveInCurrentIcon } from '@sonar-web/common';
import SaveSettingsType from './SaveSettingsType';
import TileButtonSelectable from '@sonar-web/common/src/features/Tiles/TileButtonSelectable';

const SaveInCurrentTemplate = ({ toggleName, setToggleName }) => {
	const value = SaveSettingsType.SaveInCurrentTemplate.value;

	const handleClick = () => {
		if (toggleName !== value) setToggleName(value);
	};

	return (
		<TileButtonSelectable
			Icon={SaveInCurrentIcon}
			title='Common_SaveInCurrentTemplate'
			selected={toggleName === value}
			onClick={handleClick}
			radio={true}
			data-at='Common_SaveInCurrentTemplate'
		/>
	);
};

export default SaveInCurrentTemplate;
