export default {
	Edit: {
		name: 'edit'
	},
	Preview: {
		name: 'preview'
	},
	Copy: {
		name: 'copy'
	},
	FromDevice: {
		name: 'fromDevice'
	}
};
