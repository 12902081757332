import { useState, useEffect } from 'react';
import FilterTypes from '../constants/filterTypes';

export default (filters) => {
	const [filterDescriptor, setFilterDescriptor] = useState([]);

	const buildDescriptor = (data) => {
		const dataFilter = data ?? filters;

		let fd = [];

		for (let fItem of dataFilter) {
			if (!fItem) {
				fd.push(null);
				continue;
			}

			if (Array.isArray(fItem)) {
				fd.push(
					fItem.map((f) => {
						if (!f) return null;
						return {
							member: f.member.name,
							value: f.value,
							filterType: FilterTypes[f.member.type],
							filterOperator: f.operator,
							logicalOperator: f.logicalOperator,
							replace: f.replace ?? true
						};
					})
				);
			} else {
				fd.push({
					member: fItem.member.name,
					value: fItem.value,
					filterType: FilterTypes[fItem.member.type],
					filterOperator: fItem.operator,
					logicalOperator: fItem.logicalOperator,
					except: fItem.except,
					replace: fItem.replace ?? true
				});
			}
		}

		return fd;
	};

	const createFilter = (data) => {
		return buildDescriptor(data);
	};

	useEffect(() => {
		if (!filters) return;

		const fd = buildDescriptor();

		setFilterDescriptor(fd);
	}, []);

	return { filterDescriptor, createFilter };
};
