import React from 'react';
import { Box } from '@mui/material';

const TimelinePoint = ({ text, color, size, ...rest }) => {
	if (text) {
		return (
			<Box display='flex' flexDirection='row' alignItems='center'>
				<Box
					{...rest}
					sx={{
						borderRadius: '50%',
						height: size,
						width: size,
						display: 'flex',
						backgroundColor: color
					}}
				/>
				<Box
					{...rest}
					sx={{
						fontSize: (theme) => theme.typography.pxToRem(10),
						color: color,
						fontWeight: 'medium',
						minHeight: size,
						paddingLeft: '1px',
						paddingRight: '1px'
					}}>
					{text}
				</Box>
			</Box>
		);
	}

	return (
		<Box
			{...rest}
			sx={{
				borderRadius: '50%',
				height: size,
				width: size,
				display: 'flex',
				backgroundColor: color
			}}
		/>
	);
};

export default TimelinePoint;
