import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography, Fade } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '@sonar-web/common/src/hooks';
import ScheduleContext from '../scheduleContext';
import SelectDayOfTheWeek from './SelectDayOfTheWeek';
import SelectTime from './SelectTime/SelectTime';
import { isDefaultCronObject } from '../../cronParser';

const useStyles = makeStyles((theme) => ({
	textBold: {
		fontWeight: theme.typography.fontWeightMedium
	}
}));

const EveryWeekControls = (data) => {
	const classes = useStyles();
	const { translate } = useLocale();
	const { updateSchedule, setIsValid } = useContext(ScheduleContext);

	const [days, setDays] = useState([]);

	const [times, setTimes] = useState([]);

	const onDaysChange = (values) => {
		setDays(values);
	};

	const onTimeChange = (values) => {
		setTimes(values);
	};

	const setValues = (cronObjects) => {
		if (cronObjects.length == 0) return;

		const firstCornObject = cronObjects[0];

		let newDays = [];

		for (const day of firstCornObject.dayOfTheWeek) {
			const numberParsed = Number.parseInt(day);

			if (!Number.isNaN(numberParsed)) {
				newDays.push(numberParsed);
			}
		}

		let newTimes = [];

		for (const cornObject of cronObjects) {
			let date = new Date();
			date.setHours(cornObject.hour[0]);
			date.setMinutes(cornObject.minute[0]);
			newTimes.push(date);
		}
		setDays(newDays);
		setTimes(newTimes);
	};

	useEffect(() => {
		setIsValid(false);
		if (data && isDefaultCronObject(data.cronObjects)) return;

		if (data.type != 'everyWeek') return;

		setValues(data.cronObjects);
	}, []);

	useEffect(() => {
		updateSchedule('everyWeek', { days, times });
		setIsValid(times.length > 0 && days.length > 0);
	}, [days, times]);

	return (
		<Box paddingY={2}>
			<Fade in={true} timeout={1000} appear={true}>
				<div>
					<SelectDayOfTheWeek selectedValues={days} onChange={onDaysChange} data={data} />
				</div>
			</Fade>
			<Fade in={days.length > 0} timeout={500}>
				<Box>
					<SelectTime
						mt={1}
						values={times}
						onChange={onTimeChange}
						maxValuesCount={5}
						title={'Common_ScheduleStartMoment1'}
					/>
					{days.length > 0 && times.length > 0 && (
						<Box mt={3}>
							<Typography variant='caption' component='span' color='textSecondary'>
								{translate('Common_ScheduleDescriptionText1')}{' '}
								{translate(
									days.length > 1
										? 'Common_ScheduleDescriptionText7'
										: 'Common_ScheduleDescriptionText8'
								)}
								{': ('}
							</Typography>
							<Typography variant='caption' component='span' classes={{ root: classes.textBold }}>
								{(() => {
									let daysNumbers = days.map((day) => ((Number.parseInt(day) + 6) % 7) + 1);
									daysNumbers.sort((a, b) => a - b);

									let formattedDays = daysNumbers.map((n) =>
										translate('Common_DayOfWeekShort' + n).toLowerCase()
									);

									return formattedDays.join(', ');
								})()}
							</Typography>
							<Typography variant='caption' component='span' color='textSecondary'>
								{') '}
								{translate(
									times.length > 1
										? 'Common_ScheduleDescriptionText2'
										: 'Common_ScheduleDescriptionText3'
								)}
								{': '}
							</Typography>
							<Typography variant='caption' component='span' classes={{ root: classes.textBold }}>
								{(() => {
									let formattedTimes = times.map(
										(time) =>
											(time.getHours() + '').padStart(2, '0') +
											':' +
											(time.getMinutes() + '').padStart(2, '0')
									);
									formattedTimes.sort();

									return formattedTimes.join(', ');
								})()}
							</Typography>
						</Box>
					)}
				</Box>
			</Fade>
		</Box>
	);
};

export default EveryWeekControls;
