import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { PanelContentWrapper } from '@sonar-web/common';
import ConfiguratorStepper from './ConfiguratorStepper';
import ConfiguratorControl from './ConfiguratorControl';
import ConfiguratorContent from './ConfiguratorContent';
import { resetConfiguratorData, setupConfigurator } from './configuratorSlice';
import ConfiguratorTitle from './ConfiguratorTitle';
import ConfiguratorSubtitle from './ConfiguratorSubtitle';
import ConfiguratorVisitControl from './ConfiguratorVisitControl';

const useStyles = makeStyles((theme) => ({
	left: {
		width: 300
	},
	right: {
		flex: 2,
		display: 'flex',
		flexDirection: 'column',
		paddingLeft: '5%',
		marginLeft: theme.spacing(5),
		borderLeft: `1px solid ${theme.palette.divider}`
	}
}));

const Configurator = ({ steps, title, subtitle, initialData }) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setupConfigurator({ steps, initialData }));
		return () => {
			dispatch(resetConfiguratorData());
		};
	}, []);

	return (
		<PanelContentWrapper
			mainContent={
				<>
					<Box display='flex' height='100%' data-at-params={`configurator|${title}|${subtitle}`}>
						<ConfiguratorVisitControl />
						<div className={classes.left}>
							<ConfiguratorTitle text={title} />
							<ConfiguratorSubtitle text={subtitle} />
							<ConfiguratorStepper steps={steps} />
						</div>
						<div className={classes.right}>
							<ConfiguratorControl />
							<ConfiguratorContent />
						</div>
					</Box>
				</>
			}
		/>
	);
};

export default Configurator;
