import { createSlice } from '@reduxjs/toolkit';

const slice = 'locales';
const initialState = {
	defaultLocale: 'pl',
	locale: null,
	data: []
};

export const localesSlice = createSlice({
	name: slice,
	initialState,
	reducers: {
		setLocale: (state, { payload }) => {
			state.locale = payload.localeName;
			state.data = { ...state.data, ...payload.localeData };
		}
	}
});

export const { setLocale } = localesSlice.actions;

export const setupLocales = (localeName, localeData) => async (dispatch) => {
	dispatch(setLocale({ localeName, localeData }));
};

export const selectLocale = (state) => state.locales.locale;
export const selectLocaleData = (state) => state.locales.data;

export default localesSlice.reducer;
