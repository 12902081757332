import React from 'react';
import { useTheme } from '@mui/material';

const HorizontalSpacer = ({ size }) => {
	const theme = useTheme();
	const height = theme.spacing(size ?? 2);

	return <div style={{ height: height }}></div>;
};

export default HorizontalSpacer;
