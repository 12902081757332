import { generateBlob } from '../utils';

export const fileTypes = {
	imgJpeg: 'image/jpeg',
	textCsv: {
		toBlob: (content) => {
			const bom = '\uFEFF';
			const blob = new Blob([`${bom}${content}`], { type: 'text/csv;charset=utf-8' });
			return blob;
		}
	},
	textCsvClean: {
		toBlob: (content) => {
			const blob = new Blob([content], { type: 'text/csv' });
			return blob;
		}
	},
	textCsvRaw: {
		toBlob: (content) => {
			return content;
		}
	},
	xml: {
		toBlob: (content) => {
			const blob = new Blob([content], { type: 'text/xml;charset=utf-8' });
			return blob;
		}
	},
	zip: {
		toBlob: (content) => {
			const type = 'application/zip';
			const blob = generateBlob(type, content);

			return blob;
		}
	},
	json: {
		toBlob: (content) => {
			return new Blob([JSON.stringify(content, undefined, 2)], { type: 'application/json' });
		}
	}
};
