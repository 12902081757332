import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Virtuoso } from 'react-virtuoso';
import {
	filterOperators,
	addFilters,
	ListNoRecords,
	BaseLinearProgress,
	BaseCircularProgress,
	PanelList,
	listScrollToElement
} from '@sonar-web/common';
import {
	selectLocationNodes,
	fetchLocationNodesAsync,
	setOffset,
	setResetAndReload,
	selectSelectedBreadcrumb
} from './slice';
import LocationNodesMoveListItem from './LocationNodesMoveListItem';
import { useFilterDescriptor } from '@sonar-web/common/src/hooks';
import useLocationNodeLevels from '../LocationNodeLevels/useLocationNodeLevels';

const slice = 'locationNodesMove';

const LocationNodesMoveList = ({ nodeToMove }) => {
	const dispatch = useDispatch();
	const { createFilter } = useFilterDescriptor();
	const { canChangeLevel } = useLocationNodeLevels();
	const { dataRows, hasMore, pending, reset } = useSelector(selectLocationNodes);
	const currentBreadcrumb = useSelector(selectSelectedBreadcrumb);
	const [isReloading, setIsReloading] = useState(true);

	const createFilters = () => {
		return createFilter([
			{
				member: { name: 'parentLocationNodeId', type: 'guid' },
				value: currentBreadcrumb.id,
				operator: filterOperators.equal
			}
		]);
	};

	const fetchData = (append = false) => {
		if (append) {
			if (!hasMore) return;
		} else {
			listScrollToElement(0, 'LocationNodesMoveList');
		}

		dispatch(setOffset(append));
		setIsReloading(!append);
		dispatch(fetchLocationNodesAsync());
	};

	useEffect(() => {
		const filtersData = createFilters();

		dispatch(addFilters({ slice, filtersData }));
		dispatch(setResetAndReload());
	}, [currentBreadcrumb]);

	useEffect(() => {
		if (reset) fetchData();
	}, [reset]);

	if (!dataRows) return null;
	const hasRecords = dataRows && dataRows.length !== 0;

	const Components = React.useMemo(() => {
		return {
			List: React.forwardRef(({ style, children, className }, listRef) => {
				return (
					<PanelList
						id='LocationNodesMoveList'
						ref={listRef}
						style={style}
						disablePadding
						className={className}>
						{children}
					</PanelList>
				);
			}),
			Footer: () => <BaseCircularProgress pending={pending && !isReloading} paddingTop={3} />,
			EmptyPlaceholder: () => (
				<ListNoRecords show={!pending && !hasRecords} text='LocationNodes_LocationNodeIsEmpty' />
			)
		};
	}, [dataRows]);

	const InnerItem = React.memo(({ row }) => {
		return (
			<LocationNodesMoveListItem
				row={row}
				canMoveInto={canChangeLevel(nodeToMove.locationNodeLevelId, row.locationNodeLevelId)}
			/>
		);
	});

	const itemContent = (index, row) => {
		return <InnerItem row={row} />;
	};

	return (
		<>
			<BaseLinearProgress pending={pending && isReloading} />
			<Virtuoso
				style={{ minHeight: 225 }}
				data={dataRows}
				components={Components}
				itemContent={itemContent}
				totalCount={dataRows.length}
				endReached={() => fetchData(true)}
			/>
		</>
	);
};

export default LocationNodesMoveList;
