import React from 'react';
import BaseIcon from './BaseIcon';

export default function InfoIcon(props) {
	return (
		<BaseIcon {...props}>
			<path d='m 12,2.0000005 c -5.514939,0 -10,4.485061 -10,10.0000005 C 2,17.514939 6.485061,22 12,22 17.514939,22 22,17.514939 22,12.000001 22,6.4850615 17.514939,1.9999995 12,2.0000005 Z m 0,1.338541 c 4.79322,0 8.671875,3.868239 8.671875,8.6614595 0,4.79322 -3.878655,8.671875 -8.671875,8.671874 -4.79322,0 -8.661458,-3.878654 -8.661458,-8.671874 C 3.338541,7.2067805 7.20678,3.3385415 12,3.3385415 Z m -0.619792,3.572917 v 1.4375 h 1.25 v -1.4375 z m 0,2.807292 V 17.09375 h 1.25 V 9.7187505 Z' />
		</BaseIcon>
	);
}
