import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { ControlledCollapsibleBottomBar } from '@sonar-web/common';
import useConfigurator from './useConfigurator';
import { selectDatasForm } from './deviceConfiguratorSlice';

const useStyles = makeStyles((theme) => ({
	container: {
		paddingTop: theme.spacing(1),
		display: 'flex',
		flexDirection: 'column',
		flex: 1
	}
}));

const DeviceConfiguratorBottomBar = ({ CustomBottomBar, readOnly }) => {
	const classes = useStyles();
	const actions = useConfigurator();
	const { fetchDatasFormPending, deviceConfigurationItems } = useSelector(selectDatasForm);

	if (!CustomBottomBar || fetchDatasFormPending || deviceConfigurationItems?.length === 0) return null;

	return (
		<ControlledCollapsibleBottomBar collapsed={false}>
			<div className={classes.container}>{<CustomBottomBar actions={actions} readOnly={readOnly} />}</div>
		</ControlledCollapsibleBottomBar>
	);
};

export default DeviceConfiguratorBottomBar;
