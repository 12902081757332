import { Box } from '@mui/material';
import { usePanelContentContext } from './panelContentContext';

const PanelContentCover = () => {
	const { panelCover } = usePanelContentContext();

	if (!panelCover) return null;
	return (
		<Box display='flex' flex={1} flexDirection='column' height='100%' width='100%'>
			{panelCover}
		</Box>
	);
};

export default PanelContentCover;
