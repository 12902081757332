import { useLocale } from '@sonar-web/common/src/hooks';
import ReadPeriod from '../Enums/ReadPeriod';
import ReadType from '../Enums/ReadType';

const ReadTypeByPeriod = ({ type, period }) => {
	const { translate } = useLocale();

	return translate(getReadTypeByPeriod(type, period));
};

export default ReadTypeByPeriod;

export function getReadTypeByPeriod(type, period) {
	if (type === ReadType.Instant.name)
		return period === ReadPeriod.Month.name ? 'HeatAllocators_ReadInstantMonth' : 'HeatAllocators_ReadInstantYear';
	return period === ReadPeriod.Month.name ? 'HeatAllocators_ReadLoggedMonth' : 'HeatAllocators_ReadLoggedYear';
}
