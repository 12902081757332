import { SYSTEM_MESSAGES } from '../constants/SystemMessages';

const EventBus = {
	on(eventType, callback) {
		document.addEventListener(eventType, callback);
	},

	off(eventType, callback) {
		document.removeEventListener(eventType, callback);
	},

	dispatch(eventType, data) {
		const event = new CustomEvent(eventType, { detail: data });
		document.dispatchEvent(event);
	},

	messages: SYSTEM_MESSAGES
};

export default EventBus;
