import { useNavigate, useSearchParams } from 'react-router-dom';

import moment from 'moment';
import { guidEmpty } from '@sonar-web/common';
import {
	DEFAULT_MAP_FILTER_DATE_RANGE_OPTION,
	DEFAULT_MAP_FILTER_LAYER,
	DEFAULT_MAP_FILTER_READ_STATE
} from '@sonar/stats/src/Constants/Module';

const useLocationNavigation = () => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const b = searchParams.get('b'); // breadcrumb
	const f = searchParams.get('f'); // filters
	const breadcrumb = fromBase64(b);
	const filters = getMapFiltersValue(fromBase64(f));

	const currentLocation = breadcrumb ? breadcrumb[breadcrumb.length - 1] : { id: guidEmpty };
	const parentLocation = breadcrumb?.length > 1 ? breadcrumb[breadcrumb.length - 2] : { id: guidEmpty };
	const isRoot = currentLocation.id === guidEmpty;

	const addBreadcrumb = (newBreadcrumb) => {
		if (!newBreadcrumb) return;

		const bEncoded = breadcrumb ? toBase64([...breadcrumb, newBreadcrumb]) : toBase64([newBreadcrumb]);

		const newSearchParams = new URLSearchParams(location.search);
		newSearchParams.set('b', bEncoded);

		navigate({
			pathname: window.location.pathname,
			search: newSearchParams.toString()
		});
	};

	const removeBreadcrumb = () => {
		if (!breadcrumb) return;

		const newBreadcrumb = breadcrumb.slice(0, -1);
		const bEncoded = newBreadcrumb.length > 0 ? toBase64(newBreadcrumb) : null;
		const newSearchParams = new URLSearchParams(location.search);

		if (!bEncoded) newSearchParams.delete('b');
		else newSearchParams.set('b', bEncoded);

		navigate({
			pathname: window.location.pathname,
			search: newSearchParams.toString()
		});
	};

	const replaceBreadcrumb = ({ city, street, building, staircase, floor, local, custom }) => {
		const newBreadcrumb = [];
		if (city) newBreadcrumb.push(city);
		if (street) newBreadcrumb.push(street);
		if (building) newBreadcrumb.push(building);
		if (staircase) newBreadcrumb.push(staircase);
		if (floor) newBreadcrumb.push(floor);
		if (local) newBreadcrumb.push(local);
		if (custom) newBreadcrumb.push(custom);

		const newSearchParams = new URLSearchParams(location.search);

		const bEncoded = toBase64(newBreadcrumb);
		newSearchParams.set('b', bEncoded);

		navigate({
			pathname: window.location.pathname,
			search: newSearchParams.toString()
		});
	};

	const setFilters = (filters) => {
		if (!filters) return;

		const newSearchParams = new URLSearchParams(location.search);
		newSearchParams.set('f', toBase64(filters));

		navigate({
			pathname: window.location.pathname,
			search: newSearchParams.toString()
		});
	};

	const removeFilters = () => {
		const newSearchParams = new URLSearchParams(location.search);
		newSearchParams.delete('f');

		navigate({
			pathname: window.location.pathname,
			search: newSearchParams.toString()
		});
	};

	return {
		breadcrumb,
		isRoot,
		currentLocation,
		parentLocation,
		filters,
		addBreadcrumb,
		removeBreadcrumb,
		replaceBreadcrumb,
		setFilters,
		removeFilters
	};
};

export default useLocationNavigation;

const toBase64 = (value) => {
	return btoa(encodeURIComponent(JSON.stringify(value)));
};

const fromBase64 = (value) => {
	if (!value) return null;
	const valueEncoded = atob(value);
	if (!valueEncoded) return null;
	return JSON.parse(decodeURIComponent(valueEncoded));
};

const getMapFiltersValue = (value) => {
	return {
		dateRangeDate:
			value?.dateRangeDate ||
			moment()
				.subtract(DEFAULT_MAP_FILTER_DATE_RANGE_OPTION, 'days')
				.set('seconds', 0)
				.set('milliseconds', 0)
				.toISOString(),
		dateRangeOption: value?.dateRangeOption || DEFAULT_MAP_FILTER_DATE_RANGE_OPTION,
		layer: value?.layer || DEFAULT_MAP_FILTER_LAYER,
		readState: value?.readState || DEFAULT_MAP_FILTER_READ_STATE
	};
};
