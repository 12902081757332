import React from 'react';
import { ScheduleContextProvider } from './scheduleContext';
import ScheduleForm from './ScheduleForm';
import ScheduleTopActionsToolbar from './ScheduleTopActionsToolbar';

const Schedule = ({
	data,
	entryData,
	onAdd,
	onEdit,
	onCancel,
	enableEveryHour = true,
	enableEveryDay = true,
	enableEveryWeek = true,
	enableEveryMonth = true,
	enableOneTime = true,
	showToolbar = true
}) => {
	const typesSettings = {
		everyHour: enableEveryHour,
		everyDay: enableEveryDay,
		everyWeek: enableEveryWeek,
		everyMonth: enableEveryMonth,
		oneTime: enableOneTime
	};

	return (
		<ScheduleContextProvider data={data} entryData={entryData}>
			<>
				{showToolbar && (
					<ScheduleTopActionsToolbar data={data} onCancel={onCancel} onAdd={onAdd} onEdit={onEdit} />
				)}
				<ScheduleForm typesSettings={typesSettings} />
			</>
		</ScheduleContextProvider>
	);
};

export default Schedule;
