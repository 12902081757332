export default {
	None: {
		name: 'None',
		value: null
	},
	Now: {
		name: 'Now',
		value: '1'
	},
	BySchedule: {
		name: 'BySchedule',
		value: '2'
	}
};

export function getByValue(value) {
	if (value === null) return '';
	if (value === true) return '1';
	if (value === false) return '2';
}
