import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { addErrorAsync } from '../features/Errors/errorsSlice';

function useFetch({ disable = false, persist = false, api, slice }) {
	const dispatch = useDispatch();
	const [data, setData] = useState(null);
	const [pending, setPending] = useState(false);
	const [error, setError] = useState(null);

	const onSuccess = (res) => {
		setData(res);
		setPending(false);
	};

	const onError = async (res) => {
		const err = await dispatch(addErrorAsync({ slice, error: res }));

		setError(err);
		setPending(false);
	};

	const fetch = async (token) => {
		try {
			const response = await api({ cancelToken: token.token });
			onSuccess(response);
		} catch (er) {
			onError(er);
		}
	};

	useEffect(() => {
		if (disable) return;
		if (persist && data) return;

		setPending(true);
		setData(null);
		setError(null);

		const source = axios.CancelToken.source();

		if (pending) fetch(source);

		return () => {
			source.cancel();
		};
	}, [api, disable]);

	return { data, pending, error };
}

export default useFetch;
