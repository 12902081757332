import { InterfaceType, ToDoIcon } from '@sonar-web/common';
import HeatMeterIcon from '@sonar-web/common/src/icons/HeatMeterIcon';
import ColdWaterIcon from '../Icons/ColdWaterIcon';
import HeatCostAllocatorIcon from '../Icons/HeatCostAllocatorIcon';
import WarmWaterIcon from '../Icons/WarmWaterIcon';

const getSettingsLayerTextByInterfaceType = (type) => {
	switch (type) {
		case InterfaceType.ColdWaterMeasurement.value:
			return 'ColdWatermeters';
		case InterfaceType.WarmWaterMeasurement.value:
			return 'WarmWatermeters';
		case InterfaceType.HeatAllocating.value:
			return 'Heatallocators';
		case InterfaceType.AllHeatmeters.value:
			return 'HeatMeters';
		case InterfaceType.Everything.value:
			return 'AllDevices';
		default:
			return '';
	}
};

const getIconByInterfaceType = (type) => {
	switch (type) {
		case InterfaceType.ColdWaterMeasurement.name:
			return ColdWaterIcon;
		case InterfaceType.WarmWaterMeasurement.name:
			return WarmWaterIcon;
		case InterfaceType.HeatAllocating.name:
			return HeatCostAllocatorIcon;
		case InterfaceType.HeatMeasurement.name:
		case InterfaceType.ColdMeasurement.name:
		case InterfaceType.HeatAndColdMeasurement.name:
			return HeatMeterIcon;
		default:
			return ToDoIcon;
	}
};

const getMeasurementSetNameByInterfaceType = (type) => {
	switch (type) {
		case InterfaceType.ColdWaterMeasurement.name:
			return 'MeasurementSetNameColdWater';
		case InterfaceType.WarmWaterMeasurement.name:
			return 'MeasurementSetNameWarmWater';
		case InterfaceType.HeatAllocating.name:
			return 'MeasurementSetNameHeatCostAllocator';
		case InterfaceType.HeatMeasurement.name:
		case InterfaceType.ColdMeasurement.name:
		case InterfaceType.HeatAndColdMeasurement.name:
			return 'MeasurementSetNameHeatMeter';
		default:
			return '';
	}
};

const StatsHelper = {
	getSettingsLayerTextByInterfaceType,
	getIconByInterfaceType,
	getMeasurementSetNameByInterfaceType
};

export default StatsHelper;
