import React from 'react';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ScheduleType from './ScheduleType';
import { types } from './constants';

const useStyles = makeStyles((theme) => ({
	root: {
		'& .tileBase': {
			marginRight: theme.spacing(2),
			marginBottom: theme.spacing(2),
			minWidth: 125,
			maxWidth: '25%',
			flex: 1
		},
		'& .tileBase:last-child': {
			marginRight: 0
		}
	}
}));

const ScheduleTypes = ({ typesSettings }) => {
	const classes = useStyles();

	const availableTypes = Object.entries(types).reduce((acc, v) => {
		const key = v[0];
		const value = v[1];

		if (typesSettings[key]) acc.push(value);

		return acc;
	}, []);

	return (
		<Box display='flex' flexWrap='wrap' className={classes.root} paddingTop={2}>
			{availableTypes.map((t, index) => {
				return <ScheduleType key={index} {...t} />;
			})}
		</Box>
	);
};

export default ScheduleTypes;
