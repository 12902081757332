export const moduleName = 'mesh';

export const slices = {
	hub: 'hub',
	meshNetworks: 'meshNetworks',
	meshNetwork: 'meshNetwork',
	meshTerminal: 'meshTerminal',
	meshNetworkAvailableItems: 'meshNetworkAvailableItems',
	meshNetworkCurrentItems: 'meshNetworkCurrentItems',
	meshNetworkHubItems: 'meshNetworkHubItems',
	meshNetworkHubUnknownItems: 'meshNetworkHubUnknownItems',
	meshTerminalTypesSearch: 'meshTerminalTypesSearch',
	routingTables: 'routingTables',
	transmits: 'transmits',
	hubTemperatureProfiles: 'hubTemperatureProfiles',
	terminalTransmits: 'terminalTransmits',
	hubMultiOperations: 'hubMultiOperations'
};

export const routes = {
	hubNew: () => '/hubs',
	hubDetails: (props) => (props ? `/hubs/${props.hubId}` : `/hubs/:hubId`),
	hubEdit: (props) => (props ? `/hubs/${props.hubId}/edit` : `/hubs/:hubId/edit`),
	hubDelete: (props) => (props ? `/hubs/${props.hubId}/delete` : `/hubs/:hubId/delete`),
	hubConfigurationNew: (props) => (props ? `/hubs/${props.hubId}/configuration` : `/hubs/:hubId/configuration`),
	hubConfigurationNewProfile: (props) =>
		props ? `/hubs/${props.hubId}/configuration?type=profiles` : `/hubs/:hubId/configuration?type=profiles`,
	hubConfigurationEdit: (props) =>
		props
			? `/hubs/${props.hubId}/configuration/edit?pending=${props.pending === true}`
			: `/hubs/:hubId/configuration/edit`,
	meshNetworkHubAssign: (props) => (props ? `/meshNetworks/${props.networkId}/hub` : `/meshNetworks/:networkId/hub`),
	meshNetworkHubReplace: (props) =>
		props ? `/meshNetworks/${props.networkId}/hub/${props.hubId}` : `/meshNetworks/:networkId/hub/:hubId`,
	meshNetworks: () => '/meshNetworks',
	meshNetworkAdd: () => '/meshNetworks/add',
	meshNetworkDetails: (props) => (props ? `/meshNetworks/${props.networkId}` : `/meshNetworks/:networkId`),
	meshNetworkStructure: (props) =>
		props ? `/meshNetworks/${props.networkId}/structure` : `/meshNetworks/:networkId/structure`,
	meshNetworkSchedule: (props) =>
		props ? `/meshNetworks/${props.networkId}/schedule` : `/meshNetworks/:networkId/schedule`,
	meshNetworkScheduleEdit: (props) =>
		props
			? `/meshNetworks/${props.networkId}/schedule/${props.scheduleId}`
			: `/meshNetworks/:networkId/schedule/:scheduleId`,
	meshNetworkEdit: (props) => (props ? `/meshNetworks/${props.networkId}/edit` : `/meshNetworks/:networkId/edit`),
	meshNetworkDelete: (props) =>
		props ? `/meshNetworks/${props.networkId}/delete` : `/meshNetworks/:networkId/delete`,
	terminalDetails: (props) => (props ? `/meshterminals/${props.terminalId}` : `/meshterminals/:terminalId`),
	terminalEdit: (props) => (props ? `/meshterminals/${props.terminalId}/edit` : `/meshterminals/:terminalId/edit`),
	terminalDelete: (props) =>
		props ? `/meshterminals/${props.terminalId}/delete` : `/meshterminals/:terminalId/delete`,
	routingTables: (props) =>
		props ? `/meshNetworks/${props.networkId}/routingTables` : `/meshNetworks/:networkId/routingTables`,
	routingTablesVisualization: (props) =>
		props
			? `/meshNetworks/${props.networkId}/routingTables/${props.routingTableId}/visualization`
			: `/meshNetworks/:networkId/routingTables/:routingTableId/visualization`,
	transmits: (props) => (props ? `/meshNetworks/${props.networkId}/transmits` : `/meshNetworks/:networkId/transmits`),
	transmitNew: (props) =>
		props
			? `/meshNetworks/${props.networkId}/transmit/${props.transmitType}`
			: `/meshNetworks/:networkId/transmit/:transmitType`,
	transmitEdit: (props) =>
		props
			? `/meshNetworks/${props.networkId}/transmit/${props.transmitType}/${props.transmitId}/edit`
			: `/meshNetworks/:networkId/transmit/:transmitType/:transmitId/edit`,
	transmitPreview: (props) =>
		props
			? `/meshNetworks/${props.networkId}/transmit/${props.transmitType}/${props.transmitId}/preview`
			: `/meshNetworks/:networkId/transmit/:transmitType/:transmitId/preview`
};

const Module = {
	moduleName,
	slices,
	routes
};

export default Module;
