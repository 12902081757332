import { useEffect, useState, useMemo } from 'react';
import { InputAdornment, TextField, Typography } from '@mui/material';
import { useLocale } from '../../../../hooks';

const ConfiguratorNumberfieldInt = ({
	onChange,
	onBlur,
	name,
	value,
	error,
	helperText,
	element,
	dataAtError,
	form,
	group,
	forceUpdateValidationSchema
}) => {
	const { translate } = useLocale();
	const { dependsOn, unit, description = '', defaultValue, isReadOnly, step } = element;
	const isDependent = dependsOn !== null;
	const dependencyValue = isDependent ? dependsOn.value === form.values[dependsOn.key] : false;

	const helperTextValue = useMemo(() => {
		if (helperText && description) return `${helperText} (${translate(description, element.min, element.max)})`;
		if (helperText) return helperText;
		if (description) return translate(description, element.min, element.max);
		return '';
	}, [helperText]);

	const [dependencyVisibility, setDependencyVisibility] = useState(dependencyValue);

	const handleChange = (e) => {
		const nextValue = e.target.value;
		const isMatch = /^-?\d*$/.test(nextValue);

		if (isMatch) onChange(e);
	};

	useEffect(() => {
		if (!dependsOn) return;

		setDependencyVisibility(dependencyValue);
		if (group.included) forceUpdateValidationSchema(form.values);
	}, [form.values[dependsOn?.key]]);

	useEffect(() => {
		if (!dependsOn) return;
		if (!dependencyVisibility) form.setFieldValue(name, defaultValue);
	}, [dependencyVisibility]);

	if (dependsOn && !dependencyVisibility) return null;
	return (
        (<TextField
            margin='normal'
            size='small'
            type='number'
            fullWidth
            label={translate(name)}
            name={name}
            value={value ?? ''}
            disabled={Boolean(isReadOnly)}
            onChange={handleChange}
            onBlur={onBlur}
            error={error}
            helperText={helperTextValue}
            slotProps={{
                input: {
                    endAdornment: unit ? (
                        <InputAdornment position='end'>
                            <Typography variant='caption' color='textSecondary'>
                                {translate(unit)}
                            </Typography>
                        </InputAdornment>
                    ) : null
                },

                htmlInput: {
                    inputMode: 'numeric',
                    step: step ?? 1
                },

                formHelperText: {
                    'data-at-error': dataAtError
                }
            }} />)
    );
};

export default ConfiguratorNumberfieldInt;
