import React from 'react';
import BaseIcon from './BaseIcon';

export default function HomeIcon(props) {
	return (
		<BaseIcon {...props}>
			<path d='M 12.039062,2.0065167 C 11.77142,2.0051867 11.51441,2.1111713 11.325521,2.3007876 l -9.695313,9.6927084 1.414063,1.414062 1.401041,-1.401041 v 8.986979 c 5.6e-5,0.55226 0.447738,0.999944 1,1 h 13.109376 c 0.55226,-5.6e-5 0.999944,-0.44774 1,-1 V 11.941412 L 20.958333,13.345058 22.369792,11.9336 12.739583,2.3007876 C 12.553882,2.1143603 12.302189,2.0086308 12.039062,2.0065167 Z m -0.0078,2.4140625 5.523438,5.5208333 V 19.993496 H 6.445312 v -9.986979 z m -0.723958,6.4583328 a 1.0001,1.0001 0 0 0 -1,1 v 4.489584 a 1.0001,1.0001 0 0 0 1,1 h 3.283853 a 1.0001,1.0001 0 0 0 1,-1 v -4.489584 a 1.0001,1.0001 0 0 0 -1,-1 z m 1,2 h 1.283854 v 2.489584 h -1.283854 z' />
		</BaseIcon>
	);
}
