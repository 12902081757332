import { Features } from '@sonar/auth';
import { lazy } from 'react';

const TcpDevicePage = lazy(() => import('./TcpDevice/TcpDevicePage'));

export default [
	{
		element: TcpDevicePage,
		feature: Features.TcpDevicePage.name,
		path: `/tcpdevices/:id`,
		exact: true,
		name: '{0}'
	}
];
