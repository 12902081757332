import { createSelector, createSlice } from '@reduxjs/toolkit';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import { parseError } from '../billingErrors';
import BillingService from '../BillingService';
import Module from '../Constants/Module';

const slice = Module.slices.billingClient;

const initialData = {
	nip: '',
	name: '',
	address: '',
	city: '',
	postalCode: '',
	hasImage: false
};

export const billingClient = createSlice({
	name: slice,
	initialState: {
		editPending: false,
		editSuccess: false,
		fetchPending: false,
		fetchSuccess: false,
		client: initialData,
		logotypePending: false,
		logotypeFetchSuccess: false
	},
	reducers: {
		fetchLogotypeSuccess: (state) => {
			state.logotypePending = false;
			state.logotypeFetchSuccess = true;
		},
		fetchLogotypePending: (state, { payload }) => {
			state.logotypePending = payload;
		},
		fetchClientSuccess: (state, { payload }) => {
			state.client = payload;
			state.fetchPending = false;
			state.fetchSuccess = true;
		},
		fetchClientPending: (state, { payload }) => {
			state.fetchPending = payload;
			state.fetchSuccess = false;
		},
		editClientSuccess: (state, { payload }) => {
			if (payload?.id) state.client.id = payload.id;
			state.editPending = false;
			state.editSuccess = true;
			state.client.hasClient = true;
		},
		editClientPending: (state, { payload }) => {
			state.editPending = payload;
		},
		setClientData: (state, { payload }) => {
			state.client = payload;
		},
		resetClientData: (state) => {
			state.client = state.client.hasClient ? { ...initialData, hasClient: true } : initialData;
			state.editSuccess = false;
			state.fetchSuccess = false;
			state.logotypeFetchSuccess = false;
		}
	}
});

export const {
	fetchLogotypePending,
	fetchLogotypeSuccess,
	fetchClientSuccess,
	fetchClientPending,
	editClientSuccess,
	editClientPending,
	setClientData,
	resetClientData
} = billingClient.actions;

export const fetchLogotypeAsync = (clientId) => async (dispatch) => {
	try {
		dispatch(fetchLogotypePending(true));
		const response = await BillingService.fetchLogotype(clientId);
		dispatch(fetchLogotypeSuccess());
		return response;
	} catch (error) {
		dispatch(fetchLogotypePending(false));
		dispatch(addErrorAsync({ slice, error: parseError(error) }));
	}
};

export const fetchClientAsync = (id) => async (dispatch) => {
	try {
		dispatch(fetchClientPending(true));
		const response = await BillingService.fetchClient(id);

		dispatch(
			fetchClientSuccess({
				...response,
				hasClient: !!response?.id
			})
		);
	} catch (error) {
		dispatch(fetchClientPending(false));
		dispatch(addErrorAsync({ slice, error: parseError(error) }));
	}
};

export const addClientAsync = (logotypeFile) => async (dispatch, getState) => {
	try {
		const clientData = getState().billingClient.client;
		dispatch(editClientPending(true));
		const response = await BillingService.addClient({ values: clientData, logotypeFile });
		dispatch(editClientSuccess({ id: response.id ?? null }));
	} catch (error) {
		dispatch(editClientPending(false));
		return dispatch(
			addErrorAsync({
				slice,
				error: parseError(error),
				skipNotificationMembers: ['name', 'nip', 'address', 'postalCode', 'city']
			})
		);
	}
};

export const editClientAsync = (logotypeFile) => async (dispatch, getState) => {
	try {
		const clientData = getState().billingClient.client;
		dispatch(editClientPending(true));
		await BillingService.editClient({ values: clientData, logotypeFile });
		dispatch(editClientSuccess());
	} catch (error) {
		dispatch(editClientPending(false));
		return dispatch(
			addErrorAsync({
				slice,
				error: parseError(error),
				skipNotificationMembers: ['name', 'nip', 'address', 'postalCode', 'city']
			})
		);
	}
};

export const selectBillingClient = createSelector(
	(state) => state.billingClient,
	(billingClient) => ({
		dataRow: billingClient.client,
		editPending: billingClient.editPending,
		editSuccess: billingClient.editSuccess,
		fetchPending: billingClient.fetchPending,
		fetchSuccess: billingClient.fetchSuccess,
		fetchLogotypePending: billingClient.logotypePending,
		fetchLogotypeSuccess: billingClient.logotypeFetchSuccess
	})
);

export default billingClient.reducer;
