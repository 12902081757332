import { GROUP_TYPE_NAME, DEVICE_KINDS, modules } from '../Constants';

const getDeviceKind = (device) => {
	const groupType = GROUP_TYPE_NAME.find((gtn) => gtn.name === device.groupTypeName);

	return groupType ? groupType.type : null;
};

const isMeterDevice = (device) => {
	const deviceKind = getDeviceKind(device);

	return deviceKind === DEVICE_KINDS.meter;
};

const isTransmissionDevice = (device) => {
	const deviceKind = getDeviceKind(device);

	return deviceKind === DEVICE_KINDS.transmission;
};

const getMeterTrasmissionDevicePair = (outputDevice, inputDevice) => {
	let result = {};

	if (outputDevice) {
		if (isMeterDevice(outputDevice)) {
			result.meterDevice = {
				device: { ...outputDevice },
				isOutput: true
			};
		} else if (isTransmissionDevice(outputDevice)) {
			result.transmissionDevice = {
				device: { ...outputDevice },
				isOutput: true
			};
		}
	}

	if (inputDevice) {
		if (isMeterDevice(inputDevice)) {
			result.meterDevice = {
				device: { ...inputDevice },
				isInput: true
			};
		} else if (isTransmissionDevice(inputDevice)) {
			result.transmissionDevice = {
				device: { ...inputDevice },
				isInput: true
			};
		}
	}

	return result;
};

const groupDevices = (dataRows) => {
	/*
		Zestaw:
		pp -> w -> na

		Polaczenia (pary):
		pp (output) -> w(input)
		w (output) -> nak(input)
	*/

	let devicesGroups = {};

	for (let item of dataRows) {
		let mtPair = getMeterTrasmissionDevicePair(item.outputDevice, item.inputDevice);
		let meterDevice = mtPair.meterDevice;
		let transmissionDevice = mtPair.transmissionDevice;

		let key;

		if (transmissionDevice) {
			key = transmissionDevice.device.id;
		} else {
			key = 'no_transmissionDevice';

			// czy urzadzenie wystepuje jako output device w parze w (output) -> nak(input)
			const isInOutput = dataRows.findIndex((el) => el.outputDevice?.id == meterDevice.device.id) != -1;

			if (isInOutput) {
				// pomijamy
				continue;
			}
		}

		if (meterDevice) {
			const inputDevice = dataRows.find((el) => el.inputDevice?.id == meterDevice.device.id);

			if (inputDevice) {
				for (const prop in inputDevice.inputDevice) {
					meterDevice.device[prop] = inputDevice.inputDevice[prop];
				}
			}
		}

		let group = devicesGroups[key];

		if (!group) {
			devicesGroups[key] = group = {};
		}

		if (meterDevice) {
			if (!group.meterDevices) {
				group.meterDevices = {};
			}

			group.meterDevices[meterDevice.device.id] = {
				device: meterDevice.device,
				pair: item
			};
		}

		if (transmissionDevice) {
			if (!group.transmissionDevices) {
				group.transmissionDevices = {};
			}

			group.transmissionDevices[transmissionDevice.device.id] = {
				device: transmissionDevice.device,
				pair: item
			};
		}
	}

	return Object.values(devicesGroups).map(function (item) {
		if (item.transmissionDevices) {
			item.transmissionDevices = Object.values(item.transmissionDevices);
		}

		if (item.meterDevices) {
			item.meterDevices = Object.values(item.meterDevices);
		}

		return item;
	});
};

const getTransmissionDevicesFromSet = (set) => {
	if (!set || !set?.measurementSetCurrent) return [];

	const pairs = set.measurementSetCurrent.measurementPairs;

	return pairs.reduce((acc, pair) => {
		let transmission = null;

		if (pair.outputDevice && isTransmissionDevice(pair.outputDevice)) transmission = pair.outputDevice;
		else if (isTransmissionDevice(pair.inputDevice)) transmission = pair.inputDevice;

		if (transmission) acc.push(transmission);

		return acc;
	}, []);
};

const getDeviceRouteBySourceService = (sourceService) => {
	const device = modules[sourceService] ?? modules.other;

	return device.devicesRoute;
};

export default () => {
	return {
		groupDevices,
		getTransmissionDevicesFromSet,
		getDeviceRouteBySourceService
	};
};
