import { useRef, useState } from 'react';
import { Divider, Menu } from '@mui/material';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import { TableListItem } from '@sonar-web/common';
import useLocationNavigation from '@sonar-web/location/src/Hooks/useLocationNavigation';
import FiltersLayer from '../../Statistics/Filters/FiltersLayer';
import FiltersDateRange from '../../Statistics/Filters/FiltersDateRange';
import StatisticsSettingsLayer from '../../Statistics/StatisticsSettingsLayer';
import StatisticsSettingsDate from '../../Statistics/StatisticsSettingsDate';

const Wrapper = styled('div')({
	'& .buildingVisualizationFilters': {
		margin: 0,
		padding: 0
	}
});

const TileContent = styled('div')({
	padding: '5px 25px',
	display: 'flex',
	gap: '1rem'
});

const PaperContent = styled('div')({
	padding: '2rem',
	display: 'flex',
	gap: '2rem'
});

const BuildingFilters = () => {
	const { filters, setFilters } = useLocationNavigation();
	const ref = useRef(null);
	const { id } = useParams();

	const [anchor, setAnchor] = useState(null);
	const [open, setOpen] = useState(false);

	const showFilters = () => {
		setAnchor(ref.current);
		setOpen(true);
	};

	const handleClose = () => setOpen(false);

	if (!id) return <div />;
	return (
		<Wrapper ref={ref}>
			<TableListItem
				onClick={showFilters}
				data-at='BuildingVisualizationFilters'
				classNames='buildingVisualizationFilters'>
				{() => (
					<TileContent>
						<StatisticsSettingsLayer filters={filters} />
						<Divider orientation='vertical' flexItem />
						<StatisticsSettingsDate filters={filters} />
					</TileContent>
				)}
			</TableListItem>
			<Menu anchorEl={anchor} open={open} onClose={handleClose}>
				<PaperContent>
					<FiltersLayer filters={filters} setFilters={setFilters} />
					<FiltersDateRange filters={filters} setFilters={setFilters} />
				</PaperContent>
			</Menu>
		</Wrapper>
	);
};

export default BuildingFilters;
