import { forwardRef, useLayoutEffect } from 'react';
import { List } from '@mui/material';
import styled from '@emotion/styled';

const StyledList = styled(List)({
	paddingRight: 0,
	'&.withScroll': {
		paddingRight: '1rem'
	},
	'& div[data-index="0"] li': {
		marginTop: 5
	}
});

const PanelList = forwardRef((props, ref) => {
	const { children, withPageScroll, ...rest } = props;

	useLayoutEffect(() => {
		const list = document.getElementById(props.id);
		const listHeader = document.getElementById(`${props.id}Header`);
		const scroller = list.parentElement.parentElement;

		const scrollerObserver = createScrollerObserver(list, listHeader);
		scrollerObserver?.observe(scroller);

		if (withPageScroll) {
			const listObserver = createListObserver(scroller);
			listObserver?.observe(list);

			scroller.style.transition = 'min-height 0.3s ease-in-out';
			scroller.addEventListener('transitionstart', (e) => {
				if (e.propertyName === 'min-height') scroller.style.overflowY = 'hidden';
			});
			scroller.addEventListener('transitionend', (e) => {
				if (e.propertyName === 'min-height') scroller.style.overflowY = 'auto';
			});
		}
	}, []);

	return (
		<StyledList ref={ref} {...rest}>
			{children}
		</StyledList>
	);
});

export default PanelList;

function createScrollerObserver(list, listHeader) {
	return new ResizeObserver((entries) => {
		for (let entry of entries) {
			if (!entry.borderBoxSize) return;

			const borderBoxSize = Array.isArray(entry.borderBoxSize) ? entry.borderBoxSize[0] : entry.borderBoxSize;

			if (borderBoxSize.inlineSize > list.clientWidth) {
				if (list.classList.contains('withScroll')) return;
				list.classList.add('withScroll');
				listHeader?.classList.add('withScroll');
			} else {
				if (!list.classList.contains('withScroll')) return;
				list.classList.remove('withScroll');
				listHeader?.classList.remove('withScroll');
			}
		}
	});
}

function createListObserver(scroller) {
	return new ResizeObserver((entries) => {
		for (let entry of entries) {
			if (!entry.borderBoxSize) return;

			const windowHeight = window.innerHeight;
			if (windowHeight > 900) {
				scroller.style.minHeight = '';
				return;
			}

			const borderBoxSize = Array.isArray(entry.borderBoxSize) ? entry.borderBoxSize[0] : entry.borderBoxSize;
			const shouldShowPageScroll = borderBoxSize.blockSize > windowHeight / 2;
			const previousHeight = scroller.getAttribute('data-previous-height');

			if (shouldShowPageScroll) {
				scroller.style.minHeight =
					borderBoxSize.blockSize > windowHeight ? `${windowHeight}px` : `${borderBoxSize.blockSize + 10}px`;
			} else if (previousHeight) scroller.style.minHeight = `${previousHeight}px`;

			scroller.setAttribute('data-previous-height', borderBoxSize.blockSize + 10);
		}
	});
}
