import { useContext, useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import IconButtonMore from '@sonar-web/common/src/components/Buttons/IconButtonMore';
import { useLocale } from '@sonar-web/common/src/hooks';
import { TableListColumn } from '@sonar-web/common';
import DashboardContentContext from '@sonar-web/common/src/components/Dashboard/dashboardContentContext';
import ReadType from '../Enums/ReadType';
import ReadSource from '../Enums/ReadSource';
import ReadForm from '../Read/ReadForm';
import useHeatallocators from '../useHeatallocators';
import { slices } from '../Constants/Module';

const ManualReadColumn = ({ id, type, source, setDeleteMode }) => {
	const { translate } = useLocale();
	const { setCoverComponent } = useContext(DashboardContentContext);
	const { setNotification } = useHeatallocators(slices.heatallocatorReads);
	const [anchor, setAnchor] = useState(null);

	const isManualRead =
		type === ReadType.Instant.name && (source === ReadSource.Inkasent.name || source === ReadSource.Tenant.name);

	const openMenu = (event) => setAnchor(event.currentTarget);

	const closeMenu = () => setAnchor(null);

	const closeCover = () => setCoverComponent(null);

	const handleDeleteOpen = () => {
		closeMenu();
		setDeleteMode(true);
	};

	const handleEditOpen = () => {
		closeMenu();
		setCoverComponent(
			<ReadForm
				id={id}
				onClose={closeCover}
				onSuccess={(dataRow) =>
					setTimeout(() => setNotification('HeatallocatorReadEdited', { ...dataRow }), 50)
				}
			/>
		);
	};

	return (
		<TableListColumn width={40} style={{ visibility: isManualRead ? 'visible' : 'hidden' }}>
			<IconButtonMore name='manualReadMenu' onClick={openMenu} />
			<Menu
				anchorEl={anchor}
				keepMounted
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				transformOrigin={{ vertical: 'top', horizontal: 'left' }}
				open={Boolean(anchor)}
				onClose={closeMenu}
				data-at='manualItemMenu'>
				<MenuItem dense onClick={handleEditOpen} data-at='editManualRead'>
					{translate('Common_Edit')}
				</MenuItem>
				<MenuItem dense onClick={handleDeleteOpen} data-at='deleteManualRead'>
					{translate('Common_Delete')}
				</MenuItem>
			</Menu>
		</TableListColumn>
	);
};

export default ManualReadColumn;
