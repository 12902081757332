import React from 'react';
import { useLocale } from '../../../../hooks';
import useCustomElementSettings from '../useCustomElementSettings';
import useConfiguratorControls from './useConfiguratorControls';

const ConfiguratorElement = ({ element, form, group, forceUpdateValidationSchema }) => {
	const { translate } = useLocale();
	const { getCustomSettings } = useCustomElementSettings();

	const { key, name, display, ...rest } = element;
	const { values, handleChange, handleBlur, errors, touched } = form;

	const settings = getCustomSettings(key, element.settings);
	const Control = useConfiguratorControls(settings?.type);

	if (display === false) return null;
	return (
		<Control
			name={key}
			value={values[key]}
			label={name}
			onChange={handleChange}
			onBlur={handleBlur}
			error={touched[key] && errors[key] != null}
			helperText={touched[key] && errors[key] && translate(errors[key])}
			form={form}
			element={{ name, ...settings, ...rest }}
			dataAtError={errors[key]}
			group={group}
			forceUpdateValidationSchema={forceUpdateValidationSchema}
		/>
	);
};

export default ConfiguratorElement;
