import { colors } from '@mui/material';
import { createTheme, getContrastRatio } from '@mui/material/styles';

const defaultTheme = createTheme();

const getThemeBackgroundColorsPalette = (foregroundColor) => {
	let allColors = [];

	const threshold = defaultTheme.palette.contrastThreshold;

	for (let key1 of Object.keys(colors)) {
		const colorAray = colors[key1];

		for (let key2 of Object.keys(colorAray)) {
			const backgroundColor = colorAray[key2];

			const ratio = getContrastRatio(foregroundColor, backgroundColor);

			if (ratio >= threshold) {
				//console.log(backgroundColor);
				allColors.push(backgroundColor);
			}
		}
	}

	allColors.sort();

	return allColors;
};

const WhiteFontBackgroundColorPalette = getThemeBackgroundColorsPalette('#FFFFFF');
const BlackFontBackgroundColorPalette = getThemeBackgroundColorsPalette('#000000');

const getStringHashCode = (str) => {
	var hash = 0;
	if (str.length == 0) return hash;
	for (var i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash);
		hash = hash & hash; // Convert to 32bit integer
	}
	return hash;
};

const getPaletteColor = (value, palette) => {
	if (Number.isInteger(value)) {
		return palette[value % palette.length];
	}

	const number = getStringHashCode(value);
	return palette[number % palette.length];
};

export const useThemeBackroundColorForWhiteFont = (value) => {
	return getPaletteColor(value, WhiteFontBackgroundColorPalette);
};

export const useThemeBackroundColorForBlackFont = (value) => {
	return getPaletteColor(value, BlackFontBackgroundColorPalette);
};
