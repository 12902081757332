import { Typography, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { formatDateTimeSecondsPrecision } from '@sonar-web/common';
import useLocale from '@sonar-web/common/src/hooks/useLocale';
import useConfiguratorValidation from '../useConfiguratorValidation';

const useStyles = makeStyles((theme) => ({
	titleRoot: {
		marginBottom: theme.spacing(0.5)
	},
	profileName: {
		fontWeight: theme.typography.fontWeightMedium,
		color: theme.palette.text.primary
	}
}));

const ConfigurationDatasHeader = ({ profileData, configData }) => {
	const classes = useStyles();
	const { translate } = useLocale();
	const { isCurrentConfigurationModified } = useConfiguratorValidation();

	const pendingConfig = configData?.pendingConfig;
	const isNewConfiguration = !configData;
	const isCurrentConfiguration = configData && pendingConfig === false;
	const isPendingConfiguration = configData && pendingConfig === true;
	const profileName = profileData?.name ?? configData?.profileName;
	const readDate = configData?.readDate;
	const plannedSaveDate = configData?.plannedSaveDate;

	const renderCurrentConfiguration = () => (
		<Typography data-at='Configurator_CurrentConfiguration1' variant='body1' component='span'>
			{translate('Configurator_CurrentConfiguration1')}
			{profileName && (
				<>
					{' '}
					{translate('Common_Configurator_CompatibleWithProfile')} <strong>{profileName}</strong>
				</>
			)}
			{readDate &&
				` (${translate('Configurator_CurrentConfiguration2')} ${formatDateTimeSecondsPrecision(readDate)})`}
		</Typography>
	);

	const renderPendingConfiguration = () => (
		<>
			<Box data-at='Common_Configurator_PendingConfiguration' display='inline' className={classes.titleRoot}>
				<Typography variant='body1' component='span'>
					{translate('Common_Configurator_PendingConfiguration')}
				</Typography>
				{profileName ? (
					<>
						<Typography
							variant='body1'
							component='span'
							data-at-params={`Common_Configurator_CompatibleWithProfile|${profileName}`}>
							{` ${translate('Common_Configurator_CompatibleWithProfile')} `}
						</Typography>
						<Typography
							variant='body1'
							component='span'
							classes={{
								root: classes.profileName
							}}>
							{profileName}
						</Typography>
					</>
				) : (
					<Typography
						variant='body1'
						component='span'
						data-at-params={`Common_Configurator_CompatibleWithProfile|Common_Configurator_Custom`}>
						{` (${translate('Common_Configurator_Custom')})`}
					</Typography>
				)}
				{plannedSaveDate && (
					<>
						<Typography variant='body1' component='span' color='textSecondary'>
							{` - ${translate('Common_Configurator_WillBeSent')} ${formatDateTimeSecondsPrecision(
								plannedSaveDate
							)}`}
						</Typography>
					</>
				)}
			</Box>
			<Typography variant='body2' color='textSecondary' component='p'>
				{translate('Common_Configurator_PendingConfiguration_WillBeSentAccordingToSchedule')}
			</Typography>
		</>
	);

	const renderNewConfiguration = () => (
		<>
			<Box data-at='Common_Configurator_NewConfiguration' display='inline' className={classes.titleRoot}>
				<Typography variant='body1' component='span'>
					{translate('Common_Configurator_NewConfiguration')}
				</Typography>
			</Box>
			<Box>
				{profileName &&
					(isCurrentConfigurationModified() ? (
						<>
							<Typography
								variant='body2'
								color='textSecondary'
								component='span'
								data-at-params={`Common_Configurator_ConfigurationBasedOnProfile|${profileName}`}>
								{`${translate('Common_Configurator_ConfigurationBasedOnProfile')} `}
							</Typography>
							<Typography
								variant='body2'
								color='textSecondary'
								component='span'
								classes={{
									root: classes.profileName
								}}>
								{profileName}
							</Typography>
						</>
					) : (
						<>
							<Typography
								variant='body2'
								color='textSecondary'
								component='span'
								data-at-params={`Common_Configurator_ConfigurationCompatibleWithProfile|${profileName}`}>
								{`${translate('Common_Configurator_ConfigurationCompatibleWithProfile')} `}
							</Typography>
							<Typography
								variant='body2'
								color='textSecondary'
								component='span'
								classes={{
									root: classes.profileName
								}}>
								{profileName}
							</Typography>
						</>
					))}
			</Box>
		</>
	);

	return (
		<>
			{isCurrentConfiguration && renderCurrentConfiguration()}
			{isPendingConfiguration && renderPendingConfiguration()}
			{isNewConfiguration && renderNewConfiguration()}
		</>
	);
};

export default ConfigurationDatasHeader;
