import DOMPurify from 'dompurify';
import EventBus from '@sonar-web/common/src/helpers/EventBus';
import { useCrossTabState } from '@sonar-web/common/src/hooks';
import useCurrentTenant from '../Hooks/useCurrentTenant';
import TenantChangedAlert from './TenantChangedAlert';
import Events from '../Constants/Events';

const TenantListener = () => {
	const { storeTenant } = useCurrentTenant();

	useCrossTabState('spidap_tenant', (v) => {
		try {
			EventBus.dispatch(Events.TenantChanged, JSON.parse(DOMPurify.sanitize(v)));
		} catch (e) {
			storeTenant(null);
		}
	});

	return <TenantChangedAlert />;
};

export default TenantListener;
