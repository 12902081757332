import { Typography } from '@mui/material';
import styled from '@emotion/styled';
import { useLocale } from '../../../../../hooks';
import BaseTooltip from '../../../../../components/BaseTooltip/BaseTooltip';
import CalendarData from './CalendarData';

const DayItem = styled(Typography)(({ theme }) => ({
	width: 21,
	height: 21,
	borderRadius: 3,
	border: '1px solid #ffffff',
	textAlign: 'center',
	backgroundColor: '#ffffff',
	'&.DayOfMonthWorkMode': {
		backgroundColor: CalendarData.DayOfMonthWorkMode.color,
		color: CalendarData.DayOfMonthWorkMode.textColor
	},
	'&.DayOfWeekWorkMode': {
		backgroundColor: CalendarData.DayOfWeekWorkMode.color,
		color: CalendarData.DayOfWeekWorkMode.textColor
	},
	'&.FirstHourWorkMode': {
		backgroundColor: CalendarData.FirstHourWorkMode.color,
		color: CalendarData.FirstHourWorkMode.textColor
	},
	'&.MonthWorkMode': {
		backgroundColor: CalendarData.MonthWorkMode.color,
		color: CalendarData.MonthWorkMode.textColor
	},
	'&.SecondHourWorkMode': {
		backgroundColor: CalendarData.SecondHourWorkMode.color,
		color: CalendarData.SecondHourWorkMode.textColor
	},
	'&:hover': {
		backgroundColor: theme.palette.secondary.main,
		color: '#ffffff'
	},
	'&.Empty': {
		backgroundColor: CalendarData.Empty.color,
		color: CalendarData.Empty.textColor,
		'&:hover': {
			backgroundColor: 'inherit',
			color: 'inherit'
		}
	}
}));

const Day = ({ value, data, formValues }) => {
	const { translate } = useLocale();

	if (data === CalendarData.Empty.text)
		return (
			<DayItem variant='caption' className={data}>
				{value}
			</DayItem>
		);

	const hoursText = () => {
		if (!formValues?.hours) return '';

		const hours = formValues.hours.map((h) => formatHourStart(h) + ' - ' + formatHourEnd(h));
		return hours.join(', ');
	};

	const text =
		formValues.hours?.length > 0 ? translate(CalendarData[data].tooltip, hoursText) : translate(data.tooltip);
	return (
		<BaseTooltip text={text} closerTooltip={true}>
			<DayItem variant='caption' className={data}>
				{value}
			</DayItem>
		</BaseTooltip>
	);
};

export default Day;

function formatHourStart(h) {
	const hour = h <= 9 ? `0${h}` : h;
	return `${hour}:00`;
}

function formatHourEnd(h) {
	const hour = h <= 9 ? `0${h}` : h;
	return `${hour}:59`;
}
