import { slices } from '../Constants/Module';
import { useFilterDescriptor, useLocale } from '@sonar-web/common/src/hooks';
import { fillDecimalPlaces, filterOperators, pageDescriptor } from '@sonar-web/common';
import { fetchWatermeterReads } from '../Reads/watermeterReadsApi';
import WatermeterReadsLocationPage from '../Reads/WatermeterReadsLocationPage';
import ReadType from '../Enums/ReadType';

const useLocationNodeReadsDeps = () => {
	const { translate } = useLocale();
	const { createFilter } = useFilterDescriptor();

	const fetchLastRead = (deviceId, locationId, params) => {
		const filterConfig = [
			{
				member: { name: 'watermeterId', type: 'guid' },
				value: deviceId,
				operator: filterOperators.equal
			},
			{
				member: { name: 'type', type: 'int' },
				value: ReadType.Monitoring.value,
				operator: filterOperators.notEqual
			},
			{
				member: { name: 'measurementPointId', type: 'guid' },
				value: locationId,
				operator: filterOperators.equal
			}
		];

		const filters = createFilter(filterConfig);

		if (params?.['date']) filters.push(params['date'][0]);

		return fetchWatermeterReads({
			...pageDescriptor,
			FilterDescriptors: filters,
			Limit: 1,
			SortDescriptors: [{ Member: 'date', ListSortDirection: 'Desc' }]
		});
	};

	return {
		Component: WatermeterReadsLocationPage,
		slice: slices.watermeterReads,
		fetchLastRead: fetchLastRead,
		getLink: (deviceId) => `/watermeters/${deviceId}`,
		readObjectMap: {
			value: (data) => fillDecimalPlaces(data.volume, 3),
			unit: `${translate('Watermeters_m3')}`,
			deviceId: 'watermeterId'
		}
	};
};

export default useLocationNodeReadsDeps;
