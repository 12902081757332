import { saveAs } from 'file-saver';
import { dataURItoBlob } from '.';

export const generateBlob = (type, content) => {
	const dataURI = type ? `data:${type};base64,${content}` : content;

	return dataURItoBlob(dataURI);
};

export const saveFile = (type, content, name) => {
	const blob = type.toBlob(content);

	saveAs(blob, name, true);
};

export const downloadFile = (file) => {
	saveAs(file);
};

export const filesizeConvert = {
	bytesToMegabytes: (value) => value / 1024 / 1024,
	megabytesToBytes: (value) => value * 1024 * 1024
};

export const createDownloadLink = (data, fileName, asLink = false) => {
	const url = asLink ? data : window.URL.createObjectURL(new Blob([data]));
	const link = document.createElement('a');

	link.href = url;
	link.setAttribute('download', fileName);
	document.body.appendChild(link);
	link.click();

	setTimeout(() => link.remove(), 1000);
};

export const createFile = (content, name) => {
	return new File([content], name);
};

export const downloadCSVFile = (data, fileName) => {
	const bom = '\uFEFF';
	const csvContent = 'data:text/csv;charset=utf-8,' + bom + data;

	const downloadLink = document.createElement('a');
	downloadLink.href = encodeURI(csvContent);
	downloadLink.download = `${fileName}.csv`;
	downloadLink.click();
	setTimeout(() => downloadLink.remove(), 1000);
};
