import { useEffect } from 'react';
import useList from '@sonar-web/common/src/features/List/useList';
import { slices } from '../Constants/Module';
import useHeatallocators from '../useHeatallocators';

const slice = slices.heatallocatorReads;

const ReadsUpdater = () => {
	const { notificationSubscribe, listSelector, deleteRecord, updateRecord, resetList } = useList(slice);
	const { resetNotification } = useHeatallocators(slice);
	const { elements } = listSelector;

	const readAdded = notificationSubscribe('HeatallocatorReadAdded');

	const readEdited = notificationSubscribe('HeatallocatorReadEdited');

	const readDeleted = notificationSubscribe('HeatallocatorReadDeleted');

	const deleteNotificationHandler = (name, data) => {
		if (!data) return;

		const { id } = data;
		const found = elements.some((e) => e.id === id);

		if (!found) return;

		deleteRecord(id);
		resetNotification(name);
	};

	const addNotificationHandler = (name) => {
		resetList();
		resetNotification(name);
	};

	const updateNotificationHandler = (name, data) => {
		if (!data) return;

		const { id, ...value } = data;
		const found = elements.some((e) => e.id === id);

		if (!found) return;

		updateRecord({ id, value });
		resetNotification(name);
	};

	useEffect(() => {
		if (readAdded) addNotificationHandler('HeatallocatorReadAdded');
		if (readEdited) updateNotificationHandler('HeatallocatorReadEdited', readEdited);
		if (readDeleted) deleteNotificationHandler('HeatallocatorReadDeleted', readDeleted);
	}, [readAdded, readEdited, readDeleted]);

	return null;
};

export default ReadsUpdater;
