import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography, Fade } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '@sonar-web/common/src/hooks';
import ScheduleContext from '../scheduleContext';
import SelectDate from './SelectDate/SelectDate';
import { isDefaultCronObject } from '../../cronParser';
import { formatDateTime } from '@sonar-web/common';

const useStyles = makeStyles((theme) => ({
	textBold: {
		fontWeight: theme.typography.fontWeightMedium
	}
}));

const OneTimeControls = (data) => {
	const classes = useStyles();
	const { translate } = useLocale();
	const { updateSchedule, setIsValid } = useContext(ScheduleContext);

	const [dates, setDates] = useState([]);

	const onDateChange = (values) => {
		setDates(values);
	};

	const setValues = (cronObjects) => {
		if (cronObjects.length == 0) return;

		let newTimes = [];

		for (const cornObject of cronObjects) {
			let date = new Date();
			date.setFullYear(cornObject.year[0]);
			date.setMonth(cornObject.month[0] - 1);
			date.setDate(cornObject.dayOfTheMonth[0]);
			date.setHours(cornObject.hour[0]);
			date.setMinutes(cornObject.minute[0]);
			date.setSeconds(0);
			newTimes.push(date);
		}

		setDates(newTimes);
	};

	useEffect(() => {
		setIsValid(false);

		if (data && isDefaultCronObject(data.cronObjects)) return;

		if (data.type != 'oneTime') return;

		setValues(data.cronObjects);
	}, []);

	useEffect(() => {
		updateSchedule('oneTime', dates);
		setIsValid(dates.length > 0);
	}, [dates]);

	return (
		<Box paddingY={2}>
			<Fade in={true} timeout={1000} appear={true}>
				<div>
					<SelectDate
						values={dates}
						onChange={onDateChange}
						maxValuesCount={4}
						title={'Common_ScheduleStartMoment4'}
					/>
				</div>
			</Fade>
			{dates.length > 0 && (
				<Box mt={3}>
					<Typography variant='caption' component='span' color='textSecondary'>
						{translate('Common_ScheduleDescriptionText1')}
						{': '}
					</Typography>
					<Typography variant='caption' component='span' classes={{ root: classes.textBold }}>
						{(() => {
							let formattedDates = dates.map((date) => formatDateTime(date));
							formattedDates.sort();

							return formattedDates.join(', ');
						})()}
					</Typography>
				</Box>
			)}
		</Box>
	);
};

export default OneTimeControls;
