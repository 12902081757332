function stringifyNoEscapes(data) {
	if (!data) return '';

	const stringified = JSON.stringify(data);

	return stringified
		.replace(/\\n/g, '\\n')
		.replace(/\\'/g, "\\'")
		.replace(/\\"/g, '\\"')
		.replace(/\\&/g, '\\&')
		.replace(/\\r/g, '\\r')
		.replace(/\\t/g, '\\t')
		.replace(/\\b/g, '\\b')
		.replace(/\\f/g, '\\f');
}

const JsonHelper = {
	stringifyNoEscapes
};

export default JsonHelper;
