import React from 'react';
import { Box, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	timelineText: (props) => ({
		fontSize: theme.typography.pxToRem(10),
		color: props.color,
		fontWeight: theme.typography.fontWeightMedium,
		minHeight: props.size,
		//minWidth: props.size,
		paddingLeft: 1,
		paddingRight: 1
	}),
	timelineDot: (props) => ({
		borderRadius: '50%',
		height: props.size,
		width: props.size,
		display: 'flex',
		backgroundColor: props.color
	}),
	timelineDotOutter1: (props) => ({
		padding: 2,
		borderRadius: '50%',
		height: props.size + 4,
		width: props.size + 4,
		backgroundColor: 'white'
	}),
	timelineDotOutter2: (props) => ({
		padding: 2,
		borderRadius: '50%',
		height: props.size + 8,
		width: props.size + 8,
		backgroundColor: props.color
	}),
	tooltip: (props) => ({
		backgroundColor: props.color,
		fontSize: theme.typography.pxToRem(10),
		fontWeight: theme.typography.fontWeightLight,
		color: 'white'
	}),
	tooltipArrow: (props) => ({
		color: props.color
	})
}));

const TimelinePoint = ({ text, tooltipText, color = 'black', size = 8, doubleBorderDot = false, ...rest }) => {
	const classes = useStyles({
		size,
		color
	});

	const ParentElement = ({ children }) =>
		tooltipText ? (
			<Tooltip
				title={tooltipText}
				arrow
				placement='bottom'
				classes={{ arrow: classes.tooltipArrow, tooltip: classes.tooltip }}>
				{children}
			</Tooltip>
		) : (
			<React.Fragment>{children}</React.Fragment>
		);

	if (text) {
		return (
			<ParentElement>
				<Box {...rest} className={classes.timelineText}>
					{text}
				</Box>
			</ParentElement>
		);
	} else {
		if (doubleBorderDot) {
			return (
				<ParentElement>
					<Box {...rest} className={classes.timelineDotOutter2}>
						<Box className={classes.timelineDotOutter1}>
							<Box className={classes.timelineDot}></Box>
						</Box>
					</Box>
				</ParentElement>
			);
		} else {
			return (
				<ParentElement>
					<Box {...rest} className={classes.timelineDot}></Box>
				</ParentElement>
			);
		}
	}
};

export default TimelinePoint;
