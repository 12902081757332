import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';

const NotificationClose = ({ closeToast, onClose }) => {
	const handleClose = () => {
		if (onClose) onClose();
		closeToast();
	};

	return (
		<IconButton
			onClick={handleClose}
			size='small'
			sx={{
				alignSelf: 'center',
				color: 'white',
				marginRight: '0.5rem',
				'&:hover': {
					backgroundColor: 'rgba(255, 255, 255, 0.08)'
				}
			}}>
			<Close fontSize='small' />
		</IconButton>
	);
};

export default NotificationClose;
