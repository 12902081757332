import { useState, useEffect, forwardRef, useRef, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select, FormControl, Typography } from '@mui/material';
import { useSorterDescriptor } from '@sonar-web/common/src/hooks';
import { addSorters } from './sortersSlice';
import useLocale from '../../hooks/useLocale';
import BaseSelectMenuItem from '../BaseSelectMenuItem/BaseSelectMenuItem';
import EventBus from '../../helpers/EventBus';

const SortersSelector = ({ slice, sorters, hide }) => {
	const comboRef = useRef();
	const activeSorters = useSelector((state) => state.sorters[slice]);
	const activeSortersKey = activeSorters?.map((s) => s.member + s.listSortDirection).join('');

	useEffect(() => {
		if (!activeSortersKey) {
			comboRef.current.resetSorter(sorters.initial || 0);
			return;
		}

		const { member, listSortDirection } = activeSorters[0];
		const idx = sorters.sorterData.findIndex((s) => s.member === member && s.direction === listSortDirection);
		if (idx >= 0) comboRef.current.updateSelectedSorterId(idx);
	}, [activeSortersKey]);

	return (
		<FormControl name='sortersSelector' style={{ display: hide ? 'none' : 'initial' }}>
			<Combo slice={slice} sorters={sorters} ref={comboRef} />
		</FormControl>
	);
};

export default SortersSelector;

const Combo = forwardRef(({ slice, sorters }, ref) => {
	const initialValue = sorters.initial || 0;
	const dispatch = useDispatch();
	const { translate } = useLocale();
	const { createSorter } = useSorterDescriptor();
	const [selectedSorterId, setSelectedSorterId] = useState(initialValue);

	useImperativeHandle(
		ref,
		() => {
			return {
				updateSelectedSorterId: (id) => {
					setSelectedSorterId((_) => id);
				},
				resetSorter: () => {
					setSelectedSorterId((_) => initialValue);
				}
			};
		},
		[]
	);

	const handleChange = (event) => {
		const newSorterId = event.target.value;
		setSelectedSorterId(newSorterId);
	};

	useEffect(() => {
		const sortersData = createSorter(sorters.sorterData[selectedSorterId]);

		dispatch(addSorters({ slice, sortersData }));
		EventBus.dispatch(EventBus.messages.SortingChanged, { slice, sortersData });
	}, [selectedSorterId]);

	return (
		<Select ref={ref} value={selectedSorterId} onChange={handleChange} disableUnderline>
			{sorters.sorterData.map((sd, index) => {
				return (
					<BaseSelectMenuItem key={String(index) + sd.text} value={index}>
						<Typography
							component='span'
							variant='body2'
							color='textSecondary'
							data-at-params={dataAtParams(sd)}>
							{translate(sd.text)}
						</Typography>
						<Member sorter={sd} />
					</BaseSelectMenuItem>
				);
			})}
		</Select>
	);
});

function Member({ sorter }) {
	if (!sorter.member) return null;

	return (
		<Typography component='span' variant='body2'>
			<Direction direction={sorter.directionText} />
		</Typography>
	);
}

function Direction({ direction }) {
	const { translate } = useLocale();

	if (!direction) return null;
	return <strong>: {translate(direction).toLowerCase()}</strong>;
}

function dataAtParams(data) {
	const direction = data.direction ?? 'null';
	return `${data.text}|${direction}`;
}
