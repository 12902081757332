export const reportTypesStateName = 'reportTypes';

export const reportTypesInitialState = {
	selectedReportType: null,
	fetchPending: false,
	reportTypes: []
};

export const reportTypesReducers = {
	setSelectedReportType: (state, { payload }) => {
		state[reportTypesStateName].selectedReportType = payload;
	},
	resetSelectedReportType: (state) => {
		state[reportTypesStateName].selectedReportType = null;
	},
	setReportTypesSuccess: (state, { payload }) => {
		state[reportTypesStateName].reportTypes = payload.elements.map((r) => {
			let columns = [];
			let data = {};

			try {
				data = JSON.parse(r.data);
				if (data) columns = data.columns;
			} catch (e) {
				columns = [];
			}

			return {
				...r,
				name: `Reports_${r.reportType}`,
				columns: columns
			};
		});
		state[reportTypesStateName].fetchPending = false;
	},
	setReportTypesPending: (state, { payload }) => {
		state[reportTypesStateName].fetchPending = payload;
	}
};
