import { createSelector, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import CustomerType from '@sonar-web/common/src/enums/CustomerType';
import { POINT_CUSTOMERS_VIEW, SLICES } from './Constants';
import CustomersService from './CustomersService';

const slice = SLICES.CUSTOMERS;

const initialState = {
	pending: true,
	currentResident: null,
	currentOwner: null,
	currentCustomers: null,
	historyCustomers: null,
	totalCount: null,
	currentView: POINT_CUSTOMERS_VIEW.MAIN,
	customerAddOpen: false,
	customerEditOpen: false,
	currentResidentMinFromDate: null,
	currentOwnerMinFromDate: null,
	historyResidentMinFromDate: null,
	historyOwnerMinFromDate: null
};

export const customersSlice = createSlice({
	name: slice,
	initialState,
	reducers: {
		fetchCustomersSucces: (state, { payload }) => {
			const { current, history } = payload;
			state.currentCustomers = current;
			state.historyCustomers = history;

			const resident = current?.find((customer) => customer.type === CustomerType.Resident.name);
			const owner = current?.find((customer) => customer.type === CustomerType.Owner.name);
			state.currentResident = resident;
			state.currentOwner = owner;

			//min from dates for current customers
			const lastHistoryResident = history?.find((customer) => customer.type === CustomerType.Resident.name);
			const lastHistoryOwner = history.find((customer) => customer.type === CustomerType.Owner.name);
			state.currentResidentMinFromDate = resident ? moment(resident.from).add(1, 'days').toISOString() : null;
			state.currentOwnerMinFromDate = owner ? moment(owner.from).add(1, 'days').toISOString() : null;
			state.historyResidentMinFromDate = lastHistoryResident
				? moment(lastHistoryResident.to).add(1, 'days').toISOString()
				: null;
			state.historyOwnerMinFromDate = lastHistoryOwner
				? moment(lastHistoryOwner.to).add(1, 'days').toISOString()
				: null;
		},
		fetchCustomersPending: (state, { payload }) => {
			state.pending = payload;
		},
		resetCustomers: (state) => {
			for (let key in initialState) {
				state[key] = initialState[key];
			}
		},
		changeView: (state, { payload }) => {
			state.currentView = payload;
		},
		setCustomerAddOpen: (state, { payload }) => {
			state.customerAddOpen = payload;
		},
		setCustomerEditOpen: (state, { payload }) => {
			state.customerEditOpen = payload;
		},
		updateCurrentResident: (state, { payload }) => {
			if (!state.currentResident) return;
			state.currentResident = { ...state.currentResident, ...payload };
		},
		updateCurrentOwner: (state, { payload }) => {
			state.currentOwner = { ...state.currentOwner, ...payload };
		}
	}
});

export const {
	fetchCustomersSucces,
	fetchCustomersPending,
	resetCustomers,
	changeView,
	setCustomerAddOpen,
	setCustomerEditOpen,
	updateCurrentResident,
	updateCurrentOwner
} = customersSlice.actions;

export const fetchCustomersForLocationAsync = (locationId) => async (dispatch) => {
	try {
		dispatch(fetchCustomersPending(true));
		const response = await CustomersService.fetchCustomersForLocation(locationId);
		dispatch(fetchCustomersSucces(response));
		dispatch(fetchCustomersPending(false));
	} catch (error) {
		dispatch(fetchCustomersPending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const selectCurrentCustomers = createSelector(
	(state) => state.customers,
	(customers) => ({
		pending: customers.pending,
		currentResident: customers.currentResident,
		currentOwner: customers.currentOwner
	})
);

export const selectCurrentView = createSelector(
	(state) => state.customers,
	(customers) => customers.currentView
);

export const selectHistoryCustomers = createSelector(
	(state) => state.customers,
	(customers) => ({
		pending: customers.pending,
		historyCustomers: customers.historyCustomers
	})
);

export const selectCustomerAddOpen = createSelector(
	(state) => state.customers,
	(customers) => customers.customerAddOpen
);

export const selectCustomerEditOpen = createSelector(
	(state) => state.customers,
	(customers) => customers.customerEditOpen
);

export const selectMinFromDates = createSelector(
	(state) => state.customers,
	(customers) => ({
		currentResidentMinFromDate: customers.currentResidentMinFromDate,
		currentOwnerMinFromDate: customers.currentOwnerMinFromDate,
		historyResidentMinFromDate: customers.historyResidentMinFromDate,
		historyOwnerMinFromDate: customers.historyOwnerMinFromDate
	})
);

export default customersSlice.reducer;
