import { ERROR_TYPES } from '..';

export default () => {
	const handleApiErrors = (form, error) => {
		if (!error || error?.type !== ERROR_TYPES.api) return;

		form.setSubmitting(false);
		form.setFieldTouched(error.member.uncapitalize(), true);
		setTimeout(() => form.setFieldError(error.member.uncapitalize(), error.message), 100);
	};

	return { handleApiErrors };
};
