import React from 'react';
import { Typography } from '@mui/material';
import { useLocale } from '../../hooks';
import ToolbarDivider from '../ToolbarDivider/ToolbarDivider';

const TotalCount = ({ isSidebar, totalCount, sorters, noData }) => {
	const { translate } = useLocale();

	if (noData) return null;
	return (
		<>
			<Typography variant='body2' color='textSecondary' data-at-params={`itemCount|${totalCount}`}>
				{translate('Common_Elements')}: {totalCount}
			</Typography>
			{sorters && !isSidebar && <ToolbarDivider />}
		</>
	);
};

export default TotalCount;
