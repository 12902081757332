import { Skeleton, Typography } from '@mui/material';
import { useLocale } from '@sonar-web/common/src/hooks';

const LicenseItemSecondary = ({ pending, value, text }) => {
	const { translate } = useLocale();
	const hasValue = value !== null && !pending;

	return (
		<>
			<br />
			{hasValue ? (
				<Typography variant='subtitle1'>{translate(String(value))}</Typography>
			) : (
				<Skeleton variant='text' height={28} width={100} />
			)}
			<Typography variant='caption' color='textSecondary'>
				{translate(text)}
			</Typography>
		</>
	);
};

export default LicenseItemSecondary;
