import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PanelListItemAction from './PanelListItemAction';

const PanelListItemActionBack = ({ onClick, ...rest }) => {
	return (
		<PanelListItemAction
			icon={<ArrowBackIcon fontSize='small' />}
			tooltip={'Common_Cancel'}
			onClick={onClick}
			{...rest}
		/>
	);
};

export default PanelListItemActionBack;
