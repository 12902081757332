import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SidebarContext from './sidebarContext';
import {
	toggleSidebar,
	closeSidebar,
	selectSidebar,
	updateDefaultTab,
	toggleExclusiveMode,
	selectExclusiveMode,
	toggleCoverMode
} from './sidebarSlice';

export default () => {
	const dispatch = useDispatch();
	const { setCoverComponent } = useContext(SidebarContext);
	const sidebarState = useSelector(selectSidebar);
	const exclusiveMode = useSelector(selectExclusiveMode);

	const currentDefaultTab = sidebarState.defaultTab;

	const loadSidebar = (type, data, isExclusive) => {
		dispatch(toggleSidebar({ type, data }));
		dispatch(toggleExclusiveMode(isExclusive ?? false));
	};

	const unloadSidebar = (type, preventTabReset) => {
		dispatch(closeSidebar({ type, preventTabReset }));
		dispatch(toggleExclusiveMode(false));
	};

	const getComponentInfo = (data, props) => {
		const componentInfo = { ...data };

		delete componentInfo.component;
		return props ? { ...componentInfo, props } : componentInfo;
	};

	const isSidebarActive = (type) => {
		if (!type) return false;
		return sidebarState[type] != null;
	};

	const isSidebarComponentActive = (type, component) => {
		if (sidebarState[type] == null) return false;
		return sidebarState[type].name === component;
	};

	const changeDefaultSidebarTab = (index) => {
		dispatch(updateDefaultTab(index));
	};

	const toggleExclusive = (data) => dispatch(toggleExclusiveMode(data));

	const isExclusiveMode = (data) => exclusiveMode === data;

	const toggleCover = (data, type) => {
		dispatch(toggleCoverMode(data === null ? false : type ?? true));
		setCoverComponent(data);
	};

	return {
		loadSidebar,
		getComponentInfo,
		unloadSidebar,
		isSidebarActive,
		isSidebarComponentActive,
		changeDefaultSidebarTab,
		currentDefaultTab,
		isExclusiveMode,
		toggleExclusive,
		toggleCover
	};
};
