import React from 'react';
import BaseIcon from './BaseIcon';

export default function WmbusTerminalIcon(props) {
	return (
		<BaseIcon {...props}>
			<path d='M 11.976562,2.0225614 C 8.2541302,2.0426272 4.6968227,3.5655833 2.111328,6.2432645 l 1.6015625,1.5429688 c 2.169841,-2.2471231 5.1512809,-3.5202936 8.2753905,-3.5371094 3.124041,-0.016885 6.125362,1.2254252 8.320313,3.4492188 l 1.580078,-1.5625 C 19.273499,3.4863073 15.698988,2.0025026 11.976562,2.0225614 Z m 0.04297,5.8300781 C 9.878106,7.8343992 7.8168741,8.6782647 6.3027343,10.192483 l 1.5742187,1.572266 c 1.0929956,-1.0931 2.578805,-1.7004 4.125,-1.6875 1.546194,0.01304 3.02043,0.646626 4.095703,1.757812 l 1.59961,-1.546875 C 16.207885,8.7491844 14.160959,7.8703865 12.019532,7.8526395 Z M 12,12.555764 C 10.682359,12.555196 9.6135426,13.624843 9.6132811,14.942483 9.61351,16.260177 10.682359,17.327797 12,17.327249 13.317119,17.327022 14.38646,16.259656 14.386719,14.942483 14.386492,13.62539 13.317119,12.555973 12,12.555764 Z M 5.2929687,15.72178 v 3.646484 c 0,1.426984 1.1823909,2.609375 2.609375,2.609375 h 8.1953123 c 1.426984,0 2.609375,-1.182391 2.609375,-2.609375 V 15.72178 h -2.152343 v 3.646484 c 0,0.271346 -0.185686,0.457031 -0.457032,0.457031 H 7.9023437 c -0.271346,0 -0.4570313,-0.185685 -0.4570313,-0.457031 V 15.72178 Z' />
		</BaseIcon>
	);
}
