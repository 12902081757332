import React from 'react';
import clsx from 'clsx';
import { Box, Container } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
	drawer: {
		minHeight: 50,
		height: 50,
		flexShrink: 0,
		bottom: 0,
		left: 0,
		width: '100%',
		position: 'fixed',
		background: theme.palette.common.white,
		boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
		zIndex: theme.zIndex.drawer + 1
	},
	drawerOpen: {
		minHeight: 'auto',
		height: 'auto',
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		transition: theme.transitions.create(['height', 'min-height', 'padding-top'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	drawerClose: {
		height: ({ height }) => height,
		minHeight: 50,
		transition: theme.transitions.create(['height', 'min-height', 'padding-top'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		})
	}
}));

const ControlledCollapsibleBottomBar = ({ collapsed, children, height }) => {
	const contrainerRef = React.useRef(null);
	const classes = useStyles({ height });

	return (
		<Box
			className={clsx('drawer', classes.drawer, {
				[classes.drawerOpen]: !collapsed,
				[classes.drawerClose]: collapsed
			})}>
			<Container ref={contrainerRef}>{children}</Container>
		</Box>
	);
};

export default ControlledCollapsibleBottomBar;
