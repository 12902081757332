import React from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '../../hooks';

const useStyles = makeStyles((theme) => ({
	emphesized: {
		fontWeight: theme.typography.fontWeightMedium,
		margin: 0
	}
}));

const TypographyEmphesizeWords = ({ text, words, breakRowOnWords, ...rest }) => {
	const classes = useStyles();
	const { translate } = useLocale();
	let translatedText = translate(text);

	const generateText = () => {
		let value = [];
		let sliced = translatedText.slice(0);

		words.forEach((x, index) => {
			const keyIndex = sliced.indexOf(`{${index}}`);

			value.push({
				normal: sliced.slice(0, keyIndex),
				emphesized: breakRowOnWords ? (
					<p className={classes.emphesized}>{translate(x)}</p>
				) : (
					<span className={classes.emphesized}>{translate(x)}</span>
				)
			});
			sliced = sliced.slice(keyIndex + 3);
		});

		return value;
	};

	return (
		<Typography variant='caption' {...rest}>
			{generateText().map((t, index) => {
				return (
					<span key={index}>
						{t.normal}
						{t.emphesized}
					</span>
				);
			})}
		</Typography>
	);
};

export default TypographyEmphesizeWords;
