import styled from '@emotion/styled';

const Wrapper = styled('button')(({ theme }) => ({
	all: 'unset',
	background: '#fff',
	borderRadius: '5px',
	boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.03)',
	border: `1px solid ${theme.palette.border.main}`,
	transition: 'background 300ms ease, border-color 300ms ease',
	cursor: 'pointer',
	position: 'relative',
	'&.selected': {
		borderColor: theme.palette.border.selected,
		background: theme.palette.primaryLight.main
	},
	'&:hover': {
		background: theme.palette.primaryLight.light,
		borderColor: theme.palette.border.hover,
		cursor: 'pointer'
	},
	'&[data-readonly="true"]': {
		cursor: 'initial',
		'&:hover': {
			border: `1px solid ${theme.palette.border.main}`,
			background: 'initial'
		},
		'&.selected:hover': {
			borderColor: theme.palette.border.selected,
			background: theme.palette.primaryLight.main
		}
	}
}));

const Content = styled('div')({
	height: 'inherit',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	padding: '1rem 1.5rem'
});

const Left = styled('div')({
	display: 'grid',
	placeItems: 'center',
	padding: '0 1rem 0 0'
});

const Right = styled('div')({
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'flex-start'
});

const IndicatorArea = styled('div', {
	shouldForwardProp: (prop) => prop !== 'selected'
})(({ theme, selected }) => ({
	marginLeft: '1rem',
	display: 'flex',
	'& svg': {
		fontSize: '1.25rem',
		color: selected ? theme.palette.primary.main : theme.palette.grey[400]
	}
}));

const TileButtonSelectable_Styles = {
	Wrapper,
	Content,
	Left,
	Right,
	IndicatorArea
};

export default TileButtonSelectable_Styles;
