import DOMPurify from 'dompurify';
import LocalStorageHelper from '@sonar-web/common/src/helpers/LocalStorageHelper';
import useRolesAccess from '../Helpers/useRolesAccess';

const Restricted = ({ to, children, fallback }) => {
	const { hasAccess } = useRolesAccess(getTenant());

	if (hasAccess(to)) return children;
	if (fallback) return fallback;
	return null;
};

export default Restricted;

function getTenant() {
	const tenant = LocalStorageHelper.getValue('spidap_tenant');

	if (tenant == null) return null;

	try {
		return JSON.parse(DOMPurify.sanitize(tenant));
	} catch (e) {
		return null;
	}
}
