import React from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CloseIcon from '@mui/icons-material/Close';
import EventIcon from '@mui/icons-material/Event';
import { selectLocale } from '../../features/Locales/localesSlice';
import { useLocale } from '../../hooks';

const useStyles = makeStyles((theme) => ({
	adornmentIcon: {
		padding: '2px',
		marginRight: '-2px'
	},
	inputRoot: {
		width: 140
	},
	labelShrink: { width: 'auto' },
	badgeRoot: {
		top: -5,
		right: 1,
		backgroundColor: theme.palette.pastel.orange.main,
		padding: 4,
		border: `3px solid ${theme.palette.common.white}`,
		borderRadius: '50%'
	},
	badgeRootHidden: {
		display: 'none'
	}
}));

const BaseMonthYearPicker = ({
	label,
	value,
	onChange,
	onClear,
	name,
	error,
	helperText,
	highlight,
	fullWidth,
	textFieldProps = {},
	...rest
}) => {
	const classes = useStyles();
	const locale = useSelector(selectLocale);
	const { translate } = useLocale();

	const handleChange = (v) => onChange(v);

	return (
		<LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
			<DatePicker
				name={name}
				value={value}
				variant='inline'
				openTo='year'
				views={['year', 'month']}
				label={translate(label)}
				onChange={handleChange}
				renderInput={(params) => (
					<TextField {...params} error={error} helperText={translate(helperText)} {...textFieldProps} />
				)}
				InputLabelProps={{
					classes: {
						root: classes.labelRoot,
						shrink: classes.labelShrink
					}
				}}
				InputProps={{
					classes: { root: clsx({ [classes.inputRoot]: !fullWidth }) },
					endAdornment:
						value && onClear ? (
							<InputAdornment position='end'>
								<IconButton
									size='small'
									aria-label='filter_date_clear'
									classes={{ root: classes.adornmentIcon }}
									onClick={(e) => {
										e.stopPropagation();
										onClear();
									}}>
									<CloseIcon fontSize='small' />
								</IconButton>
							</InputAdornment>
						) : (
							<InputAdornment position='end'>
								<IconButton
									size='small'
									aria-label='filter_date_select'
									classes={{ root: classes.adornmentIcon }}>
									<EventIcon fontSize='small' />
								</IconButton>
							</InputAdornment>
						)
				}}
				className='monthYearPicker'
				{...rest}
			/>
		</LocalizationProvider>
	);
};

export default BaseMonthYearPicker;
