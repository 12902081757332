import { Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const LinkRouter = ({ disableLink, ...rest }) => {
	if (disableLink)
		return (
			<Typography variant='body2' {...rest}>
				{rest.children}
			</Typography>
		);
	return <Link variant='body2' underline='hover' component={RouterLink} color='#165ca7' {...rest} />;
};

export default LinkRouter;
