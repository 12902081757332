const MeasuringKind = {
	Standard: {
		name: 'Standard',
		value: 0
	},
	Aggregated: {
		name: 'Aggregated',
		value: 1
	},
	Main: {
		name: 'Main',
		value: 2
	},
	Additional: {
		name: 'Additional',
		value: 3
	}
};

export default MeasuringKind;
