import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Box } from '@mui/material';
import { Formik } from 'formik';
import useLocale from '@sonar-web/common/src/hooks/useLocale';
import { ERROR_TYPES } from '@sonar-web/common/src/constants/errorTypes';
import { BaseGrowAnimation, FormTopActionsToolbar } from '@sonar-web/common';
import EditSkeleton from './EditSkeleton';
import {
	setLocationNodeData,
	selectLocationNode,
	resetLocationNodeData,
	fetchLocationNodeAsync,
	selectEditLocationNode,
	editLocationNodeAsync
} from './slice';
import { setResetAndReload } from '../LocationNodes/slice';
import { useIsMount, usePrevious } from '@sonar-web/common/src/hooks';
import { nodelevelTypeLocalName } from '../constants';
import useValidation from './useValidation';

const LocationNodeEdit = ({ id, onClose, onSuccessCallback }) => {
	const dispatch = useDispatch();
	const { translate } = useLocale();
	const isMount = useIsMount();
	const previousId = usePrevious(id);
	const { editPending, editSuccess } = useSelector(selectEditLocationNode);
	const { dataRow, fetchSuccess } = useSelector(selectLocationNode);
	const validationSchema = useValidation(dataRow?.locationNodeLevelTypeName);
	const nameInput = React.createRef();

	const sliceCleanup = () => dispatch(resetLocationNodeData());

	const handleClose = () => onClose();

	const handleApiErrors = (err, formikActions) => {
		formikActions.setSubmitting(false);

		if (err && err.type === ERROR_TYPES.api) {
			formikActions.setFieldTouched(err.member.uncapitalize(), true);
			setTimeout(() => formikActions.setFieldError(err.member.uncapitalize(), err.message), 100);
		}
	};

	const handleOnSubmit = async (values, actions) => {
		actions.setSubmitting(true);

		dispatch(setLocationNodeData(values));
		dispatch(editLocationNodeAsync()).then((err) => {
			handleApiErrors(err, actions);
			if (!err) handleClose();
		});
	};

	const handleOnSuccess = () => {
		dispatch(resetLocationNodeData());

		setTimeout(() => {
			dispatch(setResetAndReload());
		}, 300);
	};

	useEffect(() => {
		setTimeout(() => {
			if (id && id !== previousId) {
				if (isMount || (!isMount && previousId)) dispatch(fetchLocationNodeAsync(id));
			}
		}, 0);

		return () => sliceCleanup();
	}, [id]);

	return id && fetchSuccess ? (
		<BaseGrowAnimation show={true}>
			<Box flex='1' alignSelf='center' width={500} data-at='LocationNodeEdit'>
				<Formik
					initialValues={{
						...dataRow,
						hasZipCode: dataRow.level.hasZipCode
					}}
					enableReinitialize
					validationSchema={validationSchema}
					onSubmit={handleOnSubmit}>
					{({ handleSubmit, handleChange, handleBlur, values, errors, touched }) => {
						return (<>
                            <form onSubmit={handleSubmit}>
                                <FormTopActionsToolbar
                                    title={'LocationNodes_EditLocationNode'}
                                    handleCancel={handleClose}
                                    handleSubmit={handleSubmit}
                                    pending={editPending}
                                    success={editSuccess}
                                    onSuccess={() =>
                                        onSuccessCallback
                                            ? onSuccessCallback(addSuccess, submitMany)
                                            : handleOnSuccess()
                                    }
                                    disableSubmitMany
                                />
                                <TextField
                                    autoFocus
                                    margin='normal'
                                    name='name'
                                    label={translate(dataRow.level.name)}
                                    type='text'
                                    size='small'
                                    fullWidth
                                    inputRef={nameInput}
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.name && touched.name}
                                    helperText={errors.name && touched.name && translate(errors.name)}
                                    slotProps={{
                                        formHelperText: {
											'data-at-error': errors.name
										}
                                    }}
                                />
                                {dataRow.level.hasZipCode && (
                                    <TextField
                                        margin='normal'
                                        name='zipCode'
                                        label={translate('LocationNodes_ZipCode')}
                                        type='text'
                                        size='small'
                                        fullWidth
                                        value={values.zipCode}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={errors.zipCode && touched.zipCode}
                                        helperText={errors.zipCode && touched.zipCode && translate(errors.zipCode)}
                                        slotProps={{
                                            formHelperText: {
												'data-at-error': errors.zipCode
											}
                                        }}
                                    />
                                )}
                                {dataRow.level.type == nodelevelTypeLocalName && (
                                    <TextField
                                        margin='normal'
                                        name='externalId'
                                        label={translate('LocationNodes_ExternalId')}
                                        type='text'
                                        size='small'
                                        fullWidth
                                        value={values.externalId}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={errors.externalId && touched.externalId}
                                        helperText={
                                            errors.externalId && touched.externalId && translate(errors.externalId)
                                        }
                                        slotProps={{
                                            formHelperText: {
												'data-at-error': errors.externalId
											}
                                        }}
                                    />
                                )}
                            </form>
                        </>);
					}}
				</Formik>
			</Box>
		</BaseGrowAnimation>
	) : (
		<EditSkeleton />
	);
};

export default LocationNodeEdit;
