import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '@sonar-web/common/src/hooks';
import BaseButton from '../Buttons/BaseButton';

const useStyles = makeStyles((theme) => ({
	headerRoot: {
		fontWeight: theme.typography.fontWeightMedium
	},
	descriptionRoot: {
		margin: `${theme.spacing(3)} 0 0 0`,
		lineHeight: 1.3,
		whiteSpace: 'break-spaces'
	},
	description2Root: {
		whiteSpace: 'break-spaces'
	},
	abortButtonRoot: {
		marginRight: 20
	}
}));

const ConfirmationView = ({
	onCancel,
	cancelText,
	onConfirm,
	confirmText,
	header,
	description,
	description2,
	pending = false,
	disableGutter = false,
	center = true,
	initialDisable = false,
	enabledButtons = true,
	children,
	bottomChildren
}) => {
	const classes = useStyles();
	const { translate } = useLocale();
	const [disabled, setDisabled] = useState(initialDisable);

	const isDisabled = typeof disabled === 'boolean' ? disabled : Object.values(disabled).some((val) => val === true);

	return (
		<Box
			display='flex'
			flexDirection='column'
			flex={1}
			width='100%'
			paddingLeft={disableGutter ? 0 : '5%'}
			overflow='auto'
			className='confirmationView'>
			{center && <Box flex={1} />}
			{header && (
				<Typography variant='h5' classes={{ root: classes.headerRoot }} data-at={header}>
					{translate(header)}
				</Typography>
			)}
			{description && (
				<Typography variant='subtitle1' classes={{ root: classes.descriptionRoot }} data-at={description}>
					{translate(description)}
				</Typography>
			)}
			{description2 && (
				<Typography
					variant='subtitle2'
					color='textSecondary'
					classes={{ root: classes.description2Root }}
					data-at={description2}>
					{translate(description2)}
				</Typography>
			)}
			{children && children({ setDisabled, disabled, cls: classes })}
			{enabledButtons && (
				<Box mt={5}>
					{onCancel && (
						<BaseButton
							name='ConfirmationView_Cancel'
							variant='outlined'
							onClick={onCancel}
							pending={pending}
							classes={{ root: classes.abortButtonRoot }}>
							{translate(cancelText)}
						</BaseButton>
					)}
					<BaseButton
						type='submit'
						name='ConfirmationView_Submit'
						variant='contained'
						onClick={onConfirm}
						progress
						pending={pending}
						disabled={isDisabled}>
						{translate(confirmText)}
					</BaseButton>
				</Box>
			)}
			{bottomChildren && bottomChildren({ setDisabled, disabled, cls: classes })}
			{center && <Box flex={2} />}
		</Box>
	);
};

export default ConfirmationView;
