import React from 'react';
import { Box } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

const WatermeterTypeSkeleton = () => {
	return (
		<Box flex='1' width={500} mt={2}>
			<Skeleton animation='wave' variant='text' height={40} />
		</Box>
	);
};

export default WatermeterTypeSkeleton;
