import moment from 'moment';

export const formatDateTimeSecondsPrecision = (date) => {
	if (!date) return null;

	return `${moment(date).format(moment.localeData().longDateFormat('L'))} ${moment(date).format(
		moment.localeData().longDateFormat('LT')
	)}`;
};

export const formatDateTime = (date, resultType = 'full') => {
	if (!date) return null;

	const momentDate = moment(date);

	if (resultType === 'full') {
		return `${momentDate.format(moment.localeData().longDateFormat('L'))} ${momentDate.format(
			moment.localeData().longDateFormat('LT')
		)}`;
	}

	if (resultType === 'date') {
		return momentDate.format(moment.localeData().longDateFormat('L'));
	}

	if (resultType === 'time') {
		return momentDate.format(moment.localeData().longDateFormat('LT'));
	}

	if (resultType === 'timeminutes') {
		let time = momentDate.format(moment.localeData().longDateFormat('LT'));
		return time.split(':')[1];
	}

	if (resultType === 'fullMonthName') {
		return momentDate.format('D MMMM');
	}
};

export const dateAsIsoStringLocal = (date, includeFractionSeconds, includeTimezone) => {
	if (includeFractionSeconds && includeTimezone) return moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSSSSZ');
	if (includeFractionSeconds) return moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSSSS');
	if (includeTimezone) return moment(date).format('YYYY-MM-DDTHH:mm:ssZ');

	return moment(date).format('YYYY-MM-DDTHH:mm:ss');
};

export const dateAsIsoString = (date, resetSeconds, resetMinutes, resetHours) => {
	let result = moment(date);

	if (resetSeconds) result = result.set({ second: 0 });
	if (resetMinutes) result = result.set({ minute: 0 });
	if (resetHours) result = result.set({ hour: 0 });

	return result.toISOString();
};

export const dateOnlyTime = (date, resetSeconds, resetMinutes, resetHours) => {
	let result = moment(date);

	if (resetSeconds) result = result.set({ second: 0 });
	if (resetMinutes) result = result.set({ minute: 0 });
	if (resetHours) result = result.set({ hour: 0 });

	return result.format('HH:mm:ss');
};

export const remainingDays = (to) => {
	const now = moment(new Date().toISOString()).set({ second: 0, minute: 0, hour: 0 });
	const target = moment(to).set({ second: 0, minute: 0, hour: 0 });

	const result = now.diff(dateAsIsoString(target), 'days');

	if (result === 0) return 0;
	return result * -1;
};

export const resetValues = (date, resetMonths, resetDays, resetHours, resetMinutes, resetSeconds) => {
	let result = moment(date);

	if (resetSeconds) result = result.set({ second: 0 });
	if (resetMinutes) result = result.set({ minute: 0 });
	if (resetHours) result = result.set({ hour: 0 });
	if (resetDays) result = result.set({ date: 1 });
	if (resetMonths) result = result.set({ month: 0 });

	return result;
};

export const dayNames = () =>
	Array.apply(null, Array(7)).map(function (_, i) {
		return moment(i, 'e')
			.startOf('week')
			.isoWeekday(i + 1)
			.format('dd');
	});

const getLastDayOfMonth = (date) => {
	let tmp = new Date(date.getFullYear(), date.getMonth() + 1, 1);

	tmp.setDate(tmp.getDate() - 1);

	return tmp.getDate();
};

const getBeginingOfDay = (date) => {
	return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

const getBeginingOfMonth = (date) => {
	return new Date(date.getFullYear(), date.getMonth(), 1);
};

const getBeginingOfYear = (value) => {
	const year = Number.isInteger(value) ? value : value.getFullYear();

	return new Date(year, 0, 1);
};

const getEndOfDay = (date) => {
	return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
};

const getEndOfMonth = (date) => {
	const lastDayOfMonth = getLastDayOfMonth(date);

	return new Date(date.getFullYear(), date.getMonth(), lastDayOfMonth, 23, 59, 59, 999);
};

const getEndOfYear = (value) => {
	const year = Number.isInteger(value) ? value : value.getFullYear();
	let date = new Date(year, 11, 1, 23, 59, 59, 999);
	const lastDayOfMonth = getLastDayOfMonth(date);

	date.setDate(lastDayOfMonth);

	return date;
};

const addDays = (date, value) => {
	let result = new Date(date.getTime());
	result.setDate(result.getDate() + value);
	return result;
};

const addMonths = (date, value) => {
	let result = new Date(date.getTime());
	result.setMonth(result.getMonth() + value);
	return result;
};

const addYears = (date, value) => {
	let result = new Date(date.getTime());
	result.setFullYear(result.getFullYear() + value);
	return result;
};

const remainingTime = (from, to, humanize = true) => {
	const remaining = moment.duration(moment(from).diff(to ?? moment()));

	if (humanize) return remaining.humanize();
	return remaining;
};

const getLastWeekStart = (from) => moment(from).subtract(1, 'weeks').startOf('isoWeek').toDate();

const getLastWeekEnd = (from) => moment(from).subtract(1, 'weeks').endOf('isoWeek').toDate();

const getLastMonthStart = (from) => moment(from).subtract(1, 'months').startOf('month').toDate();

const getLastMonthEnd = (from) => moment(from).subtract(1, 'months').endOf('month').toDate();

const previousMonth = (date) => {
	date.setDate(1);
	date.setMonth(date.getMonth() - 1);
	return date;
};

const monthName = (date) => {
	return moment(date).format('MMMM');
};

export const formatFullMonthFullYear = (date) => {
	if (!date) return null;
	return moment(date).format('MMMM YYYY');
};

export const formatFullYear = (date) => {
	if (!date) return null;
	return moment(date).format('YYYY');
};

export const timestamp = (date) => {
	const dateObj = date ?? moment();
	return moment(dateObj).format('YYYYMMDDHHmmss');
};

export const DateHelpers = {
	formatDateTimeSecondsPrecision,
	formatDateTime,
	dateAsIsoStringLocal,
	dateAsIsoString,
	dateOnlyTime,
	remainingDays,
	resetValues,
	dayNames,
	getLastDayOfMonth,
	getBeginingOfDay,
	getBeginingOfMonth,
	getBeginingOfYear,
	getEndOfDay,
	getEndOfMonth,
	getEndOfYear,
	addDays,
	addMonths,
	addYears,
	remainingTime,
	getLastWeekStart,
	getLastWeekEnd,
	getLastMonthStart,
	getLastMonthEnd,
	previousMonth,
	monthName,
	formatFullMonthFullYear,
	formatFullYear,
	timestamp
};
