import { createSelector, createSlice } from '@reduxjs/toolkit';
import { addErrorAsync } from '../Errors/errorsSlice';
import { parseCronStringEntries } from './cronParser';
import { fetchSchedules } from './schedulesApi';

const slice = 'schedules';
const initialState = {
	schedules: [],
	listPending: false,
	resetList: false,
	pending: false
};

export const schedulesSlice = createSlice({
	name: slice,
	initialState,
	reducers: {
		setListFetchSuccess: (state, { payload }) => {
			state.listPending = false;
			state.resetList = false;
			const schedules = [];

			for (let p of payload) {
				if (p.entries.length > 0) {
					schedules.push({ ...p, parsed: parseCronStringEntries(p.entries) });
				}
			}
			state.schedules = schedules;
		},
		setListPending: (state, { payload }) => {
			state.listPending = payload;
		},
		setPending: (state, { payload }) => {
			state.pending = payload;
		},
		resetAndReloadSchedules: (state) => {
			state.listPending = false;
			state.schedules = [];
			state.resetList = true;
		},
		resetSchedules: (state) => {
			state.schedules = [];
		}
	}
});

export const {
	resetSchedule,
	setListFetchSuccess,
	setListPending,
	setPending,
	resetAndReloadSchedules,
	resetSchedules
} = schedulesSlice.actions;

export const selectSchedules = createSelector(
	(state) => state.schedules,
	(schedules) => ({
		dataRows: schedules.schedules,
		pending: schedules.listPending,
		reset: schedules.resetList
	})
);

export const fetchSchedulesAsync = (data) => async (dispatch) => {
	try {
		dispatch(setListPending(true));

		const response = await fetchSchedules(data);

		dispatch(setListFetchSuccess(response));
	} catch (error) {
		dispatch(setListPending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

export default schedulesSlice.reducer;
