import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import NotificationWrapper from './NotificationWrapper';

const Info = ({ title, message }) => {
	const dataAtInfo = typeof message === 'string' ? `notification|info|${message}` : null;

	return (
		<NotificationWrapper
			Icon={AnnouncementOutlinedIcon}
			iconProps={{ color: 'info' }}
			title={title}
			message={message}
			wrapperProps={{
				'data-at': dataAtInfo
			}}
		/>
	);
};

export default Info;
