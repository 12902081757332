import { useContext } from 'react';
import { Box, Fade, Tab, Tabs, useTheme } from '@mui/material';
import TabPanelBase from '../TabPanelBase/TabPanelBase';
import DashboardContentContext from './dashboardContentContext';

const DashboardContent = ({ activeView, setActiveView, content, disableTabpanel }) => {
	const theme = useTheme();
	const { coverComponent } = useContext(DashboardContentContext);
	const contentElement = typeof content === 'object' ? content : content();

	return (
		<Box className='dashboardContent' position='relative' height='100%' minHeight={0}>
			{disableTabpanel ? (
				<Fade in={coverComponent === null} style={{ height: '100%' }}>
					<Box flexDirection='column' height='100%' display={coverComponent === null ? 'flex' : 'none'}>
						{contentElement}
					</Box>
				</Fade>
			) : (
				<>
					<Tabs value={false} onChange={setActiveView} sx={{ display: 'none' }}>
						{content.map((_, index) => (
							<Tab key={index} />
						))}
					</Tabs>
					{content.map((c) => {
						return (
							<TabPanelBase
								key={c.id}
								value={activeView}
								index={c.id}
								disableRemoveFromDOM={c.disableRemoveFromDOM === true ? true : false}
								disableInnerTopPadding
								style={{ display: coverComponent === null ? 'initial' : 'none' }}>
								{c.cmp}
							</TabPanelBase>
						);
					})}
				</>
			)}
			<Fade in={coverComponent !== null} mountOnEnter unmountOnExit style={{ height: '100%' }}>
				<Box
					position='absolute'
					width='100%'
					height='100%'
					top={0}
					left={0}
					zIndex={theme.zIndex.drawer}
					display='flex'
					flexDirection='column'
					style={{ backgroundColor: theme.palette.background.paper }}>
					{coverComponent}
				</Box>
			</Fade>
		</Box>
	);
};

export default DashboardContent;
