import { createSelector, createSlice } from '@reduxjs/toolkit';
import { pageDescriptor, getSliceFilters } from '@sonar-web/common';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import { slices } from '../../Constants/Module';

const slice = slices.eventsTypes;

export const eventsTypesSlice = createSlice({
	name: slice,
	initialState: {
		pageDescriptor,
		fetchPending: false,
		elements: [],
		totalCount: 0,
		hasMore: true,
		reload: false,
		reset: true
	},
	reducers: {
		fetchEventsTypesSuccess: (state, { payload }) => {
			state.elements = state.reset ? payload.elements : state.elements.concat(payload.elements);
			state.totalCount = payload.totalCount;
			state.fetchPending = false;
		},
		fetchEventsTypesPending: (state) => {
			state.fetchPending = true;
		},
		setHasMore: (state) => {
			state.hasMore = state.totalCount > state.pageDescriptor.Offset + state.pageDescriptor.Limit;
		},
		setResetAndReload: (state) => {
			state.elements = [];
			state.reload = true;
			state.reset = true;
			state.hasMore = true;
			state.pageDescriptor.Offset = 0;
			state.pageDescriptor.FilterDescriptors = [];
			state.pageDescriptor.SortDescriptors = [];
		},
		setResetToFirstPage: (state) => {
			state.reload = true;
			state.reset = true;
			state.hasMore = true;
			state.pageDescriptor.Offset = 0;
		},
		setAppend: (state, action) => {
			state.reset = !action.payload;
			if (action.payload) state.pageDescriptor.Offset += state.pageDescriptor.Limit;
			else state.pageDescriptor.Offset = 0;
		},
		setReload: (state) => {
			state.reload = true;
		}
	}
});

export const {
	fetchEventsTypesSuccess,
	fetchEventsTypesPending,
	setHasMore,
	setResetAndReload,
	setResetToFirstPage,
	setAppend,
	setReload
} = eventsTypesSlice.actions;

export const fetchEventsTypesAsync = (api) => async (dispatch, getState) => {
	try {
		dispatch(fetchEventsTypesPending());

		const pd = {
			...getState()[slice].pageDescriptor,
			FilterDescriptors: getSliceFilters(getState(), slice),
			SortDescriptors: [{ Member: 'nameToSort', ListSortDirection: 1 }]
		};

		const response = await api(pd);

		dispatch(fetchEventsTypesSuccess(response));
		dispatch(setHasMore());
	} catch (error) {
		dispatch(addErrorAsync({ slice, error }));
		return;
	}
};

export const selectEventsTypes = createSelector(
	(state) => state[slice],
	(types) => ({
		elements: types.elements,
		pending: types.fetchPending,
		totalCount: types.totalCount,
		hasMore: types.hasMore,
		reload: types.reload
	})
);

export default eventsTypesSlice.reducer;
