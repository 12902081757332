import PublicIcon from '@mui/icons-material/Public';
import {
	CityIcon,
	StreetIcon,
	BuildingIcon,
	StaircaseIcon,
	LocalIcon,
	MeasurementPointIcon,
	MarkerIcon,
	FloorIcon
} from '@sonar-web/common';
import MissingLocationLevelTypeIcon from '@sonar-web/common/src/icons/MissingLocationLevelTypeIcon';
import { useTheme } from '@mui/material';

export default () => {
	const theme = useTheme();
	const colors = theme.palette.pastel;

	const defaultLevel = {
		value: 99,
		name: 'Default',
		color: colors.grey.main,
		background: colors.grey.light,
		icon: <MissingLocationLevelTypeIcon />,
		Icon: MissingLocationLevelTypeIcon
	};

	const levels = {
		Country: {
			value: 1,
			name: 'Country',
			color: colors.grey.main,
			background: colors.grey.light,
			icon: <PublicIcon />,
			Icon: PublicIcon
		},
		City: {
			value: 2,
			name: 'City',
			color: colors.green.main,
			background: colors.green.light,
			icon: <CityIcon />,
			Icon: CityIcon
		},
		Street: {
			value: 3,
			name: 'Street',
			color: colors.red.main,
			background: colors.red.light,
			icon: <StreetIcon />,
			Icon: StreetIcon
		},
		Building: {
			value: 4,
			name: 'Building',
			color: colors.purple.main,
			background: colors.purple.light,
			icon: <BuildingIcon />,
			Icon: BuildingIcon
		},
		Staircase: {
			value: 5,
			name: 'Staircase',
			color: colors.orange.main,
			background: colors.orange.light,
			icon: <StaircaseIcon />,
			Icon: StaircaseIcon
		},
		Floor: {
			value: 6,
			name: 'Floor',
			color: colors.lightOrange.main,
			background: colors.lightOrange.light,
			icon: <FloorIcon />,
			Icon: FloorIcon
		},
		Local: {
			value: 7,
			name: 'Local',
			color: colors.lightgreen.main,
			background: colors.lightgreen.light,
			icon: <LocalIcon />,
			Icon: LocalIcon
		},
		Custom: {
			value: 8,
			name: 'Custom',
			color: colors.lightblue.main,
			background: colors.lightblue.light,
			icon: <MarkerIcon />,
			Icon: MarkerIcon
		},
		MeasurementPoint: {
			value: 9,
			name: 'MeasurementPoint',
			color: colors.blue.main,
			background: colors.blue.light,
			icon: <MeasurementPointIcon />,
			Icon: MeasurementPointIcon
		}
	};

	const getDefinition = (levelType) => {
		return levels[levelType] ?? defaultLevel;
	};

	return { getDefinition, defaultLevel, levels };
};
