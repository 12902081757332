import { useMemo } from 'react';
import clsx from 'clsx';
import { Radio, FormControlLabel } from '@mui/material';
import { useLocale } from '@sonar-web/common/src/hooks';
import { formatDateTime } from '@sonar-web/common';
import { DateRangeOptions } from '@sonar-web/common/src/features/DateRange/DateRangeOptions';

const FiltersDateRangeTile = ({ title, value, dataat, selected, selectedDateRangeDate }) => {
	const { translate } = useLocale();

	const isOwn = value === String(DateRangeOptions.Own.value);

	const text = useMemo(() => {
		if (!selected) return translate(title);
		const date = formatDateTime(selectedDateRangeDate);
		return `${translate(title)} (${date} - ${translate('Now')})`;
	}, [selectedDateRangeDate, selected]);

	return (
		<FormControlLabel
			className={clsx('filtersSectionItem', { 'filtersSectionItem-selected': selected })}
			value={value}
			classes={{
				label: 'filtersSectionItemLabel'
			}}
			control={
				<Radio
					color='primary'
					size='small'
					data-at={selected ? 'radioSelected' : 'radioNotSelected'}
					sx={{
						padding: '4px',
						marginRight: '2px',
						'& svg': {
							fontSize: '1rem'
						}
					}}
				/>
			}
			label={translate(text)}
			data-at={isOwn ? `Date_${formatDateTime(selectedDateRangeDate, 'date')}` : dataat}
		/>
	);
};

export default FiltersDateRangeTile;
