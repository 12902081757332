import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import NotificationWrapper from './NotificationWrapper';

const Success = ({ title, message }) => {
	const dataAtSuccess = typeof message === 'string' ? `notification|success|${message}` : null;

	return (
		<NotificationWrapper
			Icon={CheckCircleOutlineOutlinedIcon}
			iconProps={{ color: 'success' }}
			title={title}
			message={message}
			wrapperProps={{
				'data-at': dataAtSuccess
			}}
		/>
	);
};

export default Success;
