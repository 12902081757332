import { InterfaceType } from '@sonar-web/common';
import { DateRangeOptions } from '@sonar-web/common/src/features/DateRange/DateRangeOptions';
import { ReadFilterType } from '../Enums/ReadFilterType';

export const slices = {
	statsLocation: 'statsLocation',
	statistics: 'statistics'
};

export const DEFAULT_MAP_FILTER_LAYER = String(InterfaceType.Everything.value);
export const DEFAULT_MAP_FILTER_READ_STATE = ReadFilterType.None.name;
export const DEFAULT_MAP_FILTER_DATE_RANGE_OPTION = String(DateRangeOptions.Days7.value);
