import React from 'react';
import { CircularProgress } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
	root: {
		position: 'absolute'
	}
}));

const IconButtonProgress = ({ pending, size }) => {
	const classes = useStyles();

	if (!pending) return null;

	return <CircularProgress size={size ?? 44} classes={{ root: classes.root }} />;
};

export default IconButtonProgress;
