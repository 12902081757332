import React from 'react';
import { ListItemAvatar, Avatar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import useLocationNodeLevels from './useLocationNodeLevels';

const useStyles = makeStyles((theme) => ({
	small: {
		height: theme.spacing(4),
		width: theme.spacing(4),
		'& > svg': {
			fontSize: '1.25rem'
		}
	}
}));

const LocationNodeLevelAvatar = ({ level, size }) => {
	const classes = useStyles();
	const { getLevelPresentationInfo } = useLocationNodeLevels();
	const def = getLevelPresentationInfo(level.type);

	return (
		<ListItemAvatar data-at-params={`LocationNodeLevelAvatar|${level.type}`}>
			<Avatar
				className={clsx({ [classes.small]: size === 'small' })}
				style={{ backgroundColor: def.background, color: def.color }}>
				{def.icon}
			</Avatar>
		</ListItemAvatar>
	);
};

export default LocationNodeLevelAvatar;
