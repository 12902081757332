import { Box } from '@mui/system';
import { PanelListItemActionDelete, TableListColumn, TableListItem } from '../../components';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { Avatar, ListItemAvatar, Typography } from '@mui/material';
import { formatDateTime } from '../../helpers';
import { useLocale } from '../../hooks';
import ButtonLinkAlt from '../../components/Buttons/ButtonLinkAlt';
import { downloadFile } from '../../utils/file';

const FileSelected = ({ values, resetValues, isReadOnly }) => {
	const { translate } = useLocale();

	const download = () => {
		downloadFile(values.file);
	};

	if (!values?.file) return null;
	return (
		<TableListItem disbleHover>
			{() => (
				<Box display='flex' alignItems='center'>
					<ListItemAvatar>
						<Avatar>
							<InsertDriveFileOutlinedIcon fontSize='medium' />
						</Avatar>
					</ListItemAvatar>
					<TableListColumn flex centerVertically alignItems='flex-start'>
						<ButtonLinkAlt onClick={download}>{values.file.name}</ButtonLinkAlt>
						<Typography variant='caption' color='textSecondary'>
							{translate('FileLastModifiedDate')} {formatDateTime(new Date(values.file.lastModified))}
						</Typography>
					</TableListColumn>
					{!isReadOnly && (
						<TableListColumn centerVertically>
							<PanelListItemActionDelete onClick={resetValues} />
						</TableListColumn>
					)}
				</Box>
			)}
		</TableListItem>
	);
};

export default FileSelected;
