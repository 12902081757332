import React from 'react';
import BaseIcon from './BaseIcon';

export default function WarningIcon(props) {
	return (
		<BaseIcon {...props}>
			<path d='m 12,2.0002287 c -5.5223835,0 -9.9999995,4.477616 -9.9999995,10.0000003 0,1.620162 0.3864047,3.185065 1.1145833,4.591145 l -1.0677084,3.822917 c -0.061455,0.219781 -0.061445,0.451893 0,0.671875 0.1856545,0.664844 0.8768028,1.052872 1.5416666,0.867188 l 3.8255208,-1.06771 c 1.4048413,0.72654 2.9677742,1.11198 4.5859372,1.11198 5.522334,0 10,-4.475061 10,-9.997395 C 22,6.4778447 17.522334,2.0002287 12,2.0002287 Z m 0,1.4999999 c 4.694004,0 8.5,3.8059761 8.5,8.5000004 0,4.694004 -3.805995,8.497395 -8.499999,8.497395 -1.467676,0 -2.879949,-0.371772 -4.1328132,-1.070313 L 7.5989587,19.278874 3.6119796,20.390854 4.7239588,16.406479 4.5729171,16.135645 C 3.8727161,14.881651 3.5000004,13.469705 3.5000004,12.000229 3.5000004,7.3062047 7.3059766,3.5002286 12,3.5002286 Z m 0,2.9999999 c -0.414165,0 -0.75,0.335825 -0.75,0.75 v 6.2500005 c 0,0.414165 0.335835,0.75 0.75,0.75 0.414165,0 0.75,-0.335835 0.75,-0.75 V 7.2502285 c 0,-0.414175 -0.335835,-0.75 -0.75,-0.75 z m 0,8.9973965 c -0.552253,0 -1,0.447746 -1,1 0,0.552253 0.447747,1 1,1 0.552253,0 1,-0.447747 1,-1 0,-0.552254 -0.447747,-1 -1,-1 z' />
		</BaseIcon>
	);
}
