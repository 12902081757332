import React, { useEffect, useState } from 'react';
import { BaseMonthYearPicker } from '../../../../components';
import { FormControl } from '@mui/material';
import { DateHelpers } from '../../../..';
import styled from '@emotion/styled';

const StyledFormControl = styled(FormControl)({
	'& .monthYearPicker': {
		width: 350
	}
});

const ConfiguratorMonthYearfield = ({ name, value, error, helperText, form, element }) => {
	const { dependsOn, defaultValue } = element;
	const isDependent = dependsOn !== null;
	const dependencyValue = isDependent ? dependsOn.value === form.values[dependsOn.key] : false;

	const [dependencyVisibility, setDependencyVisibility] = useState(dependencyValue);

	useEffect(() => {
		if (!dependsOn) return;

		setDependencyVisibility(dependencyValue);
	}, [form.values[dependsOn?.key]]);

	useEffect(() => {
		if (!dependsOn) return;
		if (!dependencyVisibility) form.setFieldValue(name, defaultValue);
	}, [dependencyVisibility]);

	if (dependsOn && !dependencyVisibility) return null;

	const getResultDate = (data) =>
		DateHelpers.dateAsIsoStringLocal(DateHelpers.resetValues(data, false, true, true, true, true), false, true);

	const handleOnChange = (v) => {
		form.setFieldTouched(name, true);
		form.setFieldValue(name, getResultDate(v));
	};

	return (
		<StyledFormControl margin='normal'>
			<BaseMonthYearPicker
				name={name}
				value={value}
				label={name}
				helperText={helperText}
				error={error}
				variant='inline'
				strictCompareDates
				fullWidth
				onChange={handleOnChange}
			/>
		</StyledFormControl>
	);
};

export default ConfiguratorMonthYearfield;
