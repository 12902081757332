import { apiBase } from '@sonar-web/common';
import { REST_API_PATHS } from '@sonar-web/common/src/constants/system';

const path = REST_API_PATHS.BILLING;

//Pobiera rozliczenia danego typu dla klienta
async function fetchBillings(pageDescriptor, config) {
	const { data } = await apiBase(`billings/settlement/${config.clientId}/all`, 'get', {
		path,
		params: { pageDescriptor, type: config.billingType }
	});

	return data;
}

//Rozpoczyna proces rozliczenia
export async function approveBilling(settlementId) {
	const { data } = await apiBase(`billings/settlement/approve/${settlementId}`, 'post', {
		path,
		data: { settlementId }
	});

	return data;
}

//Rozpoczyna proces rozliczenia
export async function startStage2(settlementId) {
	const { data } = await apiBase(`billings/settlement/generateReports/${settlementId}`, 'post', {
		path,
		data: { settlementId }
	});

	return data;
}

//Pobiera klientów
async function fetchClients(pageDescriptor) {
	const { data } = await apiBase(`billings/clients`, 'get', {
		path,
		params: { pageDescriptor }
	});

	return data;
}

//Usuwa klienta
async function deleteClient(id) {
	return await apiBase(`billings/client/${id}`, 'delete', {
		path
	});
}

//Pobiera dane jednego klienta
async function fetchClient(id) {
	const { data } = await apiBase(`billings/client/${id}`, 'get', {
		path
	});

	return data;
}

//Pobiera logo klienta
async function fetchLogotype(clientId) {
	const { data } = await apiBase(`billings/client/image/${clientId}`, 'get', {
		path,
		responseType: 'blob'
	});

	return data;
}

//Pobiera listę podmiotów rozliczających?
async function fetchSettlementEntities() {
	const { data } = await apiBase(`billings/billingEntities`, 'get', {
		path
	});

	return data;
}

//Pobiera dane podmiotu
async function fetchSettlementEntity(id) {
	const { data } = await apiBase(`billings/billingEntity/${id}`, 'get', {
		path
	});

	return data;
}

//Pobiera dane klienta tenanta
async function fetchClientTenant() {
	const { data } = await apiBase(`billings/client/tenant`, 'get', {
		path
	});

	return data;
}

//Pobiera logo klienta
async function fetchSettlementEntityLogotype(entityId) {
	const { data } = await apiBase(`billings/billingEntity/image/${entityId}`, 'get', {
		path,
		responseType: 'blob'
	});

	return data;
}

//Usuwa podmiot rozliczający
async function deleteSettlementEntity(id) {
	return await apiBase(`billings/deleteBillingEntity/${id}`, 'delete', {
		path
	});
}

//Dodaje podmiot rozliczający
async function addSettlementEntity({ values, logotypeFile }) {
	const { nip, name, address1, address2 } = values;

	const formData = new FormData();
	formData.append('image', logotypeFile);
	formData.append('nip', nip);
	formData.append('name', name);
	formData.append('address1', address1);
	formData.append('address2', address2);

	const response = await apiBase(`/billings/billingEntity`, 'post', {
		path,
		data: formData
	});

	const header = response.headers.location;

	if (header)
		return {
			url: header,
			id: header.slice(header.indexOf('/') + 1)
		};

	return response.data;
}

//Edytuje podmiot rozliczający
async function editSettlementEntity({ values, logotypeFile }) {
	const { id, nip, name, address1, address2 } = values;

	const formData = new FormData();
	formData.append('image', logotypeFile);
	formData.append('nip', nip);
	formData.append('name', name);
	formData.append('address1', address1);
	formData.append('address2', address2);
	formData.append('id', id);

	const { data } = await apiBase(`billings/billingEntity`, 'put', {
		path,
		data: formData
	});

	return data;
}

//Dodaje klienta
async function addClient({ values, logotypeFile }) {
	const { nip, name, address, city, postalCode, isTenant } = values;

	const formData = new FormData();
	formData.append('image', logotypeFile);
	formData.append('nip', nip);
	formData.append('name', name);
	formData.append('address', address);
	formData.append('city', city);
	formData.append('postalCode', postalCode);
	if (isTenant != null) formData.append('isTenant', isTenant);

	const response = await apiBase(`billings/client`, 'post', {
		path,
		data: formData
	});

	const header = response.headers.location;

	if (header)
		return {
			url: header,
			id: header.slice(header.indexOf('/') + 1)
		};

	return response.data;
}

//Edytuje klienta
async function editClient({ values, logotypeFile }) {
	const { nip, name, address, city, postalCode, id } = values;

	const formData = new FormData();
	formData.append('image', logotypeFile);
	formData.append('nip', nip);
	formData.append('name', name);
	formData.append('address', address);
	formData.append('city', city);
	formData.append('postalCode', postalCode);
	formData.append('id', id);

	const { data } = await apiBase(`billings/client`, 'put', {
		path,
		data: formData
	});

	return data;
}

//Dodaje rozliczenie
async function addBilling(billing) {
	const response = await apiBase(`billings/settlement`, 'post', {
		path,
		data: billing
	});

	const billingHeader = response.headers.location;

	if (billingHeader)
		return {
			url: billingHeader,
			id: billingHeader.slice(billingHeader.lastIndexOf('/') + 1)
		};

	return response;
}

//Pobiera rozliczenie
async function fetchBilling(id) {
	const { data } = await apiBase(`billings/settlement/${id}/getDetails`, 'get', {
		path
	});

	return data;
}

//Usuwa rozliczenie
export async function deleteBilling(id) {
	return await apiBase(`billings/settlement/${id}`, 'delete', {
		path
	});
}

//Edytuje rozliczenie
async function editBilling(billing, id) {
	const { data } = await apiBase(`billings/settlement/update/${id}`, 'put', {
		path,
		data: billing
	});

	return data;
}

//Pobiera logo podmiotu dla rozliczenia
async function fetchBillingClientLogotype(billing) {
	const { data } = await apiBase(`billings/settlement/${billing.id}/billingClientImage`, 'get', {
		path,
		responseType: 'blob'
	});

	return data;
}

const BillingService = {
	fetchBillings,
	fetchBilling,
	deleteBilling,
	approveBilling,
	startStage2,
	fetchClients,
	deleteClient,
	fetchClient,
	addClient,
	editClient,
	fetchLogotype,
	fetchSettlementEntity,
	fetchSettlementEntityLogotype,
	fetchSettlementEntities,
	addSettlementEntity,
	editSettlementEntity,
	deleteSettlementEntity,
	fetchClientTenant,
	addBilling,
	editBilling,
	fetchBillingClientLogotype
};

export default BillingService;
