import { useMemo } from 'react';
import { useLocale } from '@sonar-web/common/src/hooks';
import { DateRangeOptions } from '@sonar-web/common/src/features/DateRange/DateRangeOptions';
import { formatDateTime } from '@sonar-web/common';
import StatisticsSettingsItem from './StatisticsSettingsItem';
import DateRangeIcon from '../Icons/DateRangeIcon';

const StatisticsSettingsDate = ({ filters }) => {
	const { translate } = useLocale();
	const { dateRangeDate, dateRangeOption } = filters;

	const text = useMemo(() => {
		const option = Object.values(DateRangeOptions).find((dro) => String(dro.value) === dateRangeOption);
		const date = formatDateTime(dateRangeDate);

		return `${translate(option.description)} (${date} - ${translate('Now')})`;
	}, [dateRangeDate, dateRangeOption]);

	const rangeOption =
		dateRangeOption == 0 ? `fromDate_${formatDateTime(dateRangeDate, 'date')}` : `lastDays${dateRangeOption}`;

	return <StatisticsSettingsItem Icon={DateRangeIcon} text={text} dataat={`dataRangeOption|${rangeOption}`} />;
};

export default StatisticsSettingsDate;
