import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { filterOperators } from '@sonar-web/common';
import { useFilterDescriptor, usePrevious } from '@sonar-web/common/src/hooks';
import { isEqual } from 'lodash';
import { guidEmpty, pageDescriptor } from '@sonar-web/common';
import ButtonLinkAlt from '@sonar-web/common/src/components/Buttons/ButtonLinkAlt';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
	selectConverterDiagnosticReads,
	fetchConverterDiagnosticReadsAsync
} from './converterDiagnosticReadsTimelineSlice';
import { getStartOfDay, getEndOfDay, addDays, formatDate } from './helpers';

const DatePeriodPager = ({ deviceId, range, setRange }) => {
	const daysRange = 5;

	const previousDeviceId = usePrevious(deviceId);
	const previousRange = usePrevious(range);
	const dispatch = useDispatch();
	const { reset, reads, firstEntryDate, pending } = useSelector(selectConverterDiagnosticReads);
	const { createFilter } = useFilterDescriptor();

	useEffect(() => {
		const date = new Date();
		const start = addDays(getStartOfDay(date), -(daysRange - 1));

		const end = getEndOfDay(date);
		setRange({
			start: start,
			end: end
		});
	}, []);

	useEffect(() => {
		if (pending) return;
	}, [reads]);

	const [buttonStates, setButtonStates] = useState({
		backwardDisabled: true,
		forwardDisabled: true
	});
	const updateDateRange = (days) => {
		const start = addDays(range.start, days);
		const end = addDays(range.end, days);

		setRange({
			start: start,
			end: end
		});

		const now = new Date();

		const firstReadDateObj = firstEntryDate ? new Date(Date.parse(firstEntryDate)) : null;
		setButtonStates({
			backwardDisabled:
				!firstReadDateObj ||
				(end.getTime() > firstReadDateObj.getTime() && start.getTime() <= firstReadDateObj.getTime()),
			forwardDisabled: end.getTime() >= now.getTime()
		});
	};

	useEffect(() => {
		if (pending) return;
		if (!range) return;

		updateDateRange(0);
	}, [pending]);

	useEffect(() => {
		if (!deviceId || deviceId === guidEmpty) return;
		if (!range) {
			return;
		}
		const noEqualDeviceId = !isEqual(deviceId, previousDeviceId);
		const noEqualRange = !isEqual(range, previousRange);

		if (noEqualDeviceId || noEqualRange) {
			{
				const filtersData = createFilter([
					{
						member: { name: 'converterId', type: 'guid' },
						value: deviceId,
						operator: filterOperators.equal
					},
					{
						member: { name: 'date', type: 'date' },
						value: range.start,
						operator: filterOperators.ge
					},
					{
						member: { name: 'date', type: 'date' },
						value: range.end,
						operator: filterOperators.le
					}
				]);

				dispatch(
					fetchConverterDiagnosticReadsAsync({
						pageDescriptor: {
							...pageDescriptor,
							FilterDescriptors: filtersData,
							SortDescriptors: [{ Member: 'date', ListSortDirection: 'Asc' }],
							Limit: 1000000
						}
					})
				);
			}
		}
	}, [deviceId, reset, range]);
	if (!range) {
		return null;
	}
	return (
		<Box display='flex' flexDirection='row'>
			{!firstEntryDate ? null : (
				<>
					<ButtonLinkAlt
						disabled={buttonStates.backwardDisabled || pending}
						endIcon={<ArrowBackIosIcon fontSize='small' />}
						onClick={() => updateDateRange(-daysRange)}>
						{'Stationary_DatePeriodPagerBack'}
					</ButtonLinkAlt>
					<Typography variant='button' color='textSecondary'>{`${formatDate(range.start)} - ${formatDate(
						range.end
					)}`}</Typography>
					<ButtonLinkAlt
						disabled={buttonStates.forwardDisabled || pending}
						startIcon={<ArrowForwardIosIcon fontSize='small' />}
						onClick={() => updateDateRange(daysRange)}>
						{'Stationary_DatePeriodPagerForward'}
					</ButtonLinkAlt>
				</>
			)}
		</Box>
	);
};

export default DatePeriodPager;
