import { useEffect } from 'react';
import { useFetchOnDemand, useLocale } from '@sonar-web/common/src/hooks';
import Slices from '../Constants/Slices';
import LicenseItemSecondary from './LicenseItemSecondary';
import LicenseItemProgress from './LicenseItemProgress';
import MeasurementSetService from '../../../sonar-web-core/src/Services/MeasurementSet';

const slice = Slices.licenses;

const MaxDevicesCount = ({ maxNumberOfMeters }) => {
	const { translate } = useLocale();

	const {
		fetchFunc,
		data: devicesCountData,
		pending: devicesCountPending
	} = useFetchOnDemand({
		api: () => MeasurementSetService.fetchActiveMeasurementSetDevicesCount(),
		slice
	});

	useEffect(() => {
		fetchFunc();
	}, []);

	const count = devicesCountData && devicesCountData.count !== null ? devicesCountData.count : null;
	const leftDevices = count !== null ? maxNumberOfMeters - count : null;
	const progress = count ? Math.floor((count / maxNumberOfMeters) * 100) : 0;

	return (
		<>
			<LicenseItemSecondary pending={devicesCountPending} value={leftDevices} text='RemainToUse' />
			<LicenseItemProgress tooltip={translate('UsedXofY', count ?? '', maxNumberOfMeters)} progress={progress} />
		</>
	);
};

export default MaxDevicesCount;
