import moment from 'moment';
import { createSlice, createSelector } from '@reduxjs/toolkit';
import { slices } from '../Constants/Module';
import {
	reportTypesInitialState,
	reportTypesStateName,
	reportTypesReducers
} from '../ReportTypes/ReportTypes/reportTypesSlice';
import {
	reportTypeSearchInitialState,
	reportTypeSearchReducers,
	reportTypesSearchStateName
} from '../ReportTypes/ReportTypesSearch/reportTypesSearchSlice';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import { fetchReport, fetchReportJsonData } from './reportApi';

const slice = slices.report;

export const reportSlice = createSlice({
	name: slice,
	initialState: {
		[reportTypesStateName]: reportTypesInitialState,
		[reportTypesSearchStateName]: reportTypeSearchInitialState,
		fetchReportPending: false,
		fetchReportSuccess: false,
		fetchReportDataPending: false,
		fetchReportDataSuccess: false,
		fetchReportDataProgress: 0,
		report: null,
		reportData: null
	},
	reducers: {
		...reportTypeSearchReducers,
		...reportTypesReducers,
		fetchReportSucces: (state, action) => {
			state.report = action.payload;
			state.fetchReportPending = false;
			state.fetchReportSuccess = true;
		},
		fetchReportPending: (state) => {
			state.fetchReportPending = true;
			state.fetchReportSuccess = false;
		},
		fetchReportDataSucces: (state, action) => {
			state.reportData = action.payload;
			state.fetchReportDataPending = false;
			state.fetchReportDataSuccess = true;
		},
		fetchReportDataPending: (state) => {
			state.fetchReportDataPending = true;
			state.fetchReportDataSuccess = false;
		},
		setFetchReportDataProgress: (state, action) => {
			state.fetchReportDataProgress = action.payload;
		},
		resetReport: (state) => {
			state.report = null;
			state.fetchReportPending = false;
			state.fetchReportSuccess = false;
		},
		setRecipients: (state, action) => {
			state.report = { ...state.report, recipients: action.payload };
		},
		resetReportData: (state) => {
			state.reportData = null;
			state.fetchReportDataPending = false;
			state.fetchReportDataSuccess = false;
			state.fetchReportDataProgress = 0;
		}
	}
});

export const {
	fetchReportSucces,
	fetchReportPending,
	resetReport,
	fetchReportDataSucces,
	fetchReportDataPending,
	setFetchReportDataProgress,
	resetReportData,
	setRecipients
} = reportSlice.actions;

export default reportSlice.reducer;

export const fetchReportAsync = (id) => async (dispatch) => {
	let response;

	try {
		dispatch(fetchReportPending());
		response = await fetchReport(id);
	} catch (error) {
		dispatch(addErrorAsync({ slice, error }));
		return;
	}

	dispatch(fetchReportSucces(response));
};

const parseDateColumns = (response, dateformat) => {
	const dateColumns = response.columns.filter((c) => c?.type.toLowerCase() == 'date');
	let data = response.data;

	for (let i = 0; i < dateColumns.length; i++) {
		const name = dateColumns[i].name;
		let date;
		for (let j = 0; j < data.length; j++) {
			date = data[j][name];

			if (date) {
				let dateString = date;
				if (/^\d{4}$/.test(date)) dateString = `${date}-01-01`;
				else if (/^\d{4}-\d{2}$/.test(date)) dateString = `${date}-01`;

				data[j][name] = moment(dateString).toDate();
			}
		}
	}

	return {
		columns: response.columns,
		data
	};
};

export const fetchReportDataAsync = (fetchUrl, dateFormat, source) => async (dispatch) => {
	let response;

	const onDownloadProgress = (progressEvent) => {
		const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
		dispatch(setFetchReportDataProgress(Number.isFinite(percentCompleted) ? percentCompleted : 100));
	};

	try {
		dispatch(fetchReportDataPending());
		response = await fetchReportJsonData(fetchUrl, { onDownloadProgress, cancelToken: source.token });
	} catch (error) {
		dispatch(addErrorAsync({ slice, error }));
		return;
	}

	response = parseDateColumns(response, dateFormat);

	dispatch(fetchReportDataSucces(response));
};

export const selectReport = createSelector(
	(state) => state.report,
	(report) => ({
		data: report.report,
		fetchPending: report.fetchReportPending,
		fetchSuccess: report.fetchReportSuccess
	})
);

export const selectReportData = createSelector(
	(state) => state.report,
	(report) => ({
		data: report.reportData,
		fetchPending: report.fetchReportDataPending,
		fetchSuccess: report.fetchReportDataSuccess,
		progress: report.fetchReportDataProgress
	})
);
