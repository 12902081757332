import { useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { addSorters, checkListItemIsSelectable } from '../..';
import useFilters from '../../components/Filters/useFilters';
import {
	toggleMultiSelect,
	toggleMultiSelectSelectAll,
	setSelected,
	setAllSelected,
	setShiftSelected,
	setMultiSelectLastTouched,
	setResetAndReload,
	selectSelection,
	selectMultiSelect,
	selectMultiSelectSelectAll,
	selectList,
	selectMultiSelectLastTouched,
	updateSingleRecord,
	deleteSingleRecord,
	setNotifications,
	selectNotifications,
	setElements,
	setCustomProperty,
	selectCustomProperty,
	changeElementOrder,
	selectMetadata,
	updateManyRecords,
	setOffset
} from './genericListSlice';

export default (slice) => {
	if (!slice) return {};
	const dispatch = useDispatch();
	const { sliceFilters } = useFilters(slice);
	const currentSelection = useSelector(selectSelection(slice));
	const isMultiSelect = useSelector(selectMultiSelect(slice));
	const isMultiSelectSelectAll = useSelector(selectMultiSelectSelectAll(slice));
	const multiSelectLastTouched = useSelector(selectMultiSelectLastTouched(slice));

	const hasSelection = currentSelection.length > 0;

	const listSelectorFunction = useMemo(() => selectList(slice), [slice]);

	const listSelector = useSelector(listSelectorFunction, shallowEqual);

	const selectedCount = () => currentSelection.length;

	const findElementById = (id) => {
		const { elements } = listSelector;

		return elements.find((e) => e.id === id);
	};

	const isSelected = useMemo(
		() => (id) => {
			if (currentSelection.length === 0) return false;
			return currentSelection.findIndex((item) => item.id === id) !== -1;
		},
		[currentSelection]
	);

	const getNewSelection = useMemo(
		() => (event, record) => {
			if (checkListItemIsSelectable(event.target) === false) return false;

			if (currentSelection.length === 0) return record;

			const index = currentSelection.findIndex((item) => item.id === record.id);
			return index === -1 ? record : null;
		},
		[currentSelection]
	);

	const selectListItem = (record) => dispatch(setSelected(slice)({ record, add: true }));

	const deselectListItem = (record) => dispatch(setSelected(slice)({ record, add: false }));

	const toggleSelectAllListItems = (value) => {
		dispatch(toggleMultiSelectSelectAll(slice)(value));
		dispatch(setAllSelected(slice)(value));
	};

	const toggleMultipleSelectionMode = (data) => dispatch(toggleMultiSelect(slice)(data));

	const setTouchedListItem = (record) => dispatch(setMultiSelectLastTouched(slice)(record));

	const resetList = (payload) => dispatch(setResetAndReload(slice)(payload));

	const removeSorters = () => dispatch(addSorters({ slice, sortersData: [] }));

	const clearElements = () => dispatch(setElements(slice)([]));

	const handleShiftSelection = (toRecord, doSelect = true) => {
		const fromRecord = multiSelectLastTouched;
		if (!fromRecord) return;

		const { elements } = listSelector;

		const fromIndex = elements.findIndex((e) => e.id === fromRecord.id);
		const toIndex = elements.findIndex((e) => e.id === toRecord.id);

		dispatch(setShiftSelected(slice)({ fromIndex, toIndex, doSelect }));
	};

	const handleMultiSelect = (event, record) => {
		const newSelected = getNewSelection(event, record);

		if (newSelected === false) return;

		if (newSelected === null) deselectListItem(record);
		else selectListItem(newSelected);

		if (event.shiftKey) handleShiftSelection(record, newSelected !== null);

		setTouchedListItem(record);
	};

	//onlyIds = false zwróci równiż tablice selected i deselected z całymi obiektami
	const multiSubmitData = (onlyIds = true, returnSelectedIds = false) => {
		let filterDescriptors = [];
		let deselectedIds = [];
		let selectedIds = [];
		let deselected = [];

		const { elements } = listSelector;

		if (isMultiSelectSelectAll) {
			filterDescriptors = sliceFilters();

			if (returnSelectedIds) selectedIds = currentSelection.map((cs) => cs.id);
			if (currentSelection.length !== elements.length) {
				const ids = currentSelection.map((cs) => cs.id);
				const deselectedRecords = elements.filter((e) => !ids.includes(e.id));

				if (!onlyIds) deselected = deselectedRecords;

				deselectedIds = deselectedRecords.map((dr) => dr.id);
			}
		} else {
			selectedIds = currentSelection.map((cs) => cs.id);
		}

		if (onlyIds) return { filterDescriptors, deselectedIds, selectedIds };
		return { filterDescriptors, deselectedIds, selectedIds, deselected, selected: currentSelection };
	};

	const getSelectedRecordsById = (ids) => {
		const { elements } = listSelector;
		return elements.filter((e) => ids.includes(e.id));
	};

	const updateRecord = (record) => {
		dispatch(updateSingleRecord(slice)(record));
	};

	const updateRecords = (records) => {
		dispatch(updateManyRecords(slice)({ records, overrideAll: true }));
	};

	const deleteRecord = (id) => {
		dispatch(deleteSingleRecord(slice)(id));
	};

	const updateNotifications = (data) => {
		dispatch(setNotifications(slice)(data));
	};

	const notificationSubscribe = (name) => {
		const notifications = useSelector((state) => selectNotifications(slice)(state));

		return notifications?.[name];
	};

	const scrollbarVisible = (panelId) => {
		if (!panelId) return false;

		const listEl = document.getElementById(panelId)?.parentNode?.parentNode;
		if (!listEl) return false;

		return listEl.scrollHeight > listEl.clientHeight;
	};

	const updateCustomProp = (data) => {
		dispatch(setCustomProperty(slice)(data));
	};

	const selectCustomProp = (propName) => useSelector(selectCustomProperty(slice, propName));

	const setElementOrder = (data) => {
		dispatch(changeElementOrder(slice)(data));
	};

	const getMetadata = () => useSelector((state) => selectMetadata(slice)(state));

	const updateRecordsMany = (records) => dispatch(updateManyRecords(slice)({ records, overrideAll: false }));

	const updateOffset = (data) => dispatch(setOffset(slice)(data));

	return {
		listSelector,
		clearElements,
		selectedCount,
		currentSelection,
		hasSelection,
		findElementById,
		isSelected,
		multiSelectLastTouched,
		isMultiSelect,
		isMultiSelectSelectAll,
		getNewSelection,
		selectListItem,
		deselectListItem,
		toggleSelectAllListItems,
		toggleMultipleSelectionMode,
		setTouchedListItem,
		resetList,
		removeSorters,
		handleShiftSelection,
		handleMultiSelect,
		multiSubmitData,
		getSelectedRecordsById,
		updateRecord,
		updateRecords,
		deleteRecord,
		updateNotifications,
		notificationSubscribe,
		scrollbarVisible,
		updateCustomProp,
		selectCustomProp,
		setElementOrder,
		getMetadata,
		updateRecordsMany,
		selectCustomProperty,
		updateOffset
	};
};
