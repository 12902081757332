import { createSlice } from '@reduxjs/toolkit';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import { pageDescriptor } from '@sonar-web/common';
import FilterTypes from '@sonar-web/common/src/constants/filterTypes';
import FilterOperator from '@sonar-web/common/src/enums/FilterOperator';
import SortDirection from '@sonar-web/common/src/enums/SortDirection';
import { slices } from '../Constants/Module';
import HeatMeterService from '../HeatMeterService';

const slice = slices.heatMeterCharts;
const initialState = {
	reads: null,
	readsTotalCount: 0,
	readsFetchPending: false,
	consumptions: null,
	consumptionsFetchPending: false
};

export const heatMeterChartsSlice = createSlice({
	name: slice,
	initialState,
	reducers: {
		fetchReadsSuccess: (state, { payload }) => {
			state.reads = payload.elements;
			state.readsTotalCount = payload.totalCount;
			state.readsFetchPending = false;
		},
		setFetchReadsPending: (state, { payload }) => {
			state.readsFetchPending = payload;
		},
		fetchConsumptionsSuccess: (state, { payload }) => {
			state.consumptions = payload.elements;
			state.consumptionsFetchPending = false;
		},
		setFetchConsumptionsPending: (state, { payload }) => {
			state.consumptionsFetchPending = payload;
		}
	}
});

export const { fetchReadsSuccess, setFetchReadsPending, fetchConsumptionsSuccess, setFetchConsumptionsPending } =
	heatMeterChartsSlice.actions;

export const fetchChartReadsRangeAsync =
	({ measurementPointId, from, to, onPending, onSuccess }) =>
	async (dispatch) => {
		try {
			onPending(true);

			const filterDescriptors = [
				{
					member: 'measurementPointId',
					value: measurementPointId,
					filterType: FilterTypes.guid,
					filterOperator: FilterOperator.Equal.value
				}
			];
			if (from)
				filterDescriptors.push({
					member: 'dateFrom',
					value: from,
					filterType: FilterTypes.datetime,
					filterOperator: FilterOperator.Ge.value
				});
			if (to)
				filterDescriptors.push({
					member: 'dateTo',
					value: to,
					filterType: FilterTypes.datetime,
					filterOperator: FilterOperator.Lt.value
				});

			const pd = {
				...pageDescriptor,
				Limit: Math.pow(2, 31) - 1,
				FilterDescriptors: filterDescriptors,
				SortDescriptors: [{ member: 'date', listSortDirection: SortDirection.Asc.name }]
			};

			const response = await HeatMeterService.fetchChartReads({ pageDescriptor: pd, from, to });

			onSuccess(response?.elements || []);
			onPending(false);
		} catch (error) {
			onPending(false);
			dispatch(addErrorAsync({ slice, error }));
		}
	};

export const fetchConsumptionsRangeAsync =
	({ measurementPointId, from, to, usageType, onPending, onSuccess }) =>
	async (dispatch) => {
		try {
			onPending(true);

			const parameters = {
				measurementPointId: measurementPointId,
				resolution: usageType
			};

			if (from) parameters.dateFrom = from;
			if (to) parameters.dateTo = to;

			const response = await HeatMeterService.fetchChartConsumptions(parameters);

			onSuccess(response?.elements || []);
			onPending(false);
		} catch (error) {
			onPending(false);
			dispatch(addErrorAsync({ slice, error }));
		}
	};

export default heatMeterChartsSlice.reducer;
