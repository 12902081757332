import React, { useEffect } from 'react';
import {
	DashboardTitle,
	DashboardTitleActionEdit,
	DashboardTitleActionDelete,
	useSidebar,
	DashboardContentWrapper
} from '@sonar-web/common';
import { useDispatch, useSelector } from 'react-redux';
import { selectWatermeterType, fetchWatermeterTypeAsync, resetWatermeterTypeData } from './watermeterTypeSlice';
import { sidebarComponents, sidebarType } from './sidebar';
import WatermeterTypeForm from './WatermeterTypeForm';

const WatermeterTypePage = ({ id, onDeleteSuccess, onEditSuccess }) => {
	const dispatch = useDispatch();
	const { loadSidebar, unloadSidebar, getComponentInfo } = useSidebar();
	const { dataRow, fetchPending } = useSelector(selectWatermeterType);
	const [activeView, setActiveView] = React.useState(0);

	const watermeterId = id;

	const handleViewChange = (newValue) => setActiveView(newValue);

	const handleEdit = () => {
		loadSidebar(
			sidebarType,
			getComponentInfo(sidebarComponents.DeviceTypeEdit, {
				id: id,
				onClose: () => {
					loadSidebar(
						sidebarType,
						getComponentInfo(sidebarComponents.Dashboard, {
							id: id,
							onDeleteSuccess: onDeleteSuccess,
							onEditSuccess: onEditSuccess,
							onClose: () => {
								unloadSidebar(sidebarType);
							}
						})
					);
				},
				onSuccessCallback: (addSuccess, submitMany) => {
					if (!submitMany) {
						loadSidebar(
							sidebarType,
							getComponentInfo(sidebarComponents.Dashboard, {
								id: id,
								onDeleteSuccess: onDeleteSuccess,
								onEditSuccess: onEditSuccess,
								onClose: () => {
									unloadSidebar(sidebarType);
								}
							})
						);
					}
					onEditSuccess();
				}
			})
		);
	};

	const handleDashboardEditClose = () => {
		handleViewChange(0);
		setTimeout(() => {
			dispatch(fetchWatermeterTypeAsync(watermeterId));
		}, 500);
	};

	const handleDelete = () => {
		loadSidebar(
			sidebarType,
			getComponentInfo(sidebarComponents.DeviceTypeDelete, {
				data: dataRow,
				onCancel: () => {
					loadSidebar(
						sidebarType,
						getComponentInfo(sidebarComponents.Dashboard, {
							id: id,
							onDeleteSuccess: onDeleteSuccess,
							onEditSuccess: onEditSuccess,
							onClose: () => {
								unloadSidebar(sidebarType);
							}
						})
					);
				},
				onSuccess: () => {
					unloadSidebar(sidebarType);
					dispatch(resetWatermeterTypeData());

					onDeleteSuccess();
				}
			})
		);
	};

	const sliceCleanup = () => dispatch(resetWatermeterTypeData());

	useEffect(() => {
		if (watermeterId) dispatch(fetchWatermeterTypeAsync(watermeterId));
		return () => sliceCleanup();
	}, [watermeterId]);

	if (!dataRow) return null;

	const content = [
		{
			id: 0,
			disableRemoveFromDOM: true,
			cmp: (
				<>
					<DashboardTitle
						pending={fetchPending}
						data={dataRow}
						title='Watermeters_WatermeterType'
						primaryData='name'
						actions={
							<>
								<DashboardTitleActionEdit onClick={handleEdit} show={dataRow.canBeDeleted} />
								<DashboardTitleActionDelete onClick={handleDelete} show={dataRow.canBeDeleted} />
							</>
						}
					/>
					<WatermeterTypeForm readOnly={true} id={watermeterId} onClose={handleDashboardEditClose} />
				</>
			)
		}
	];

	return <DashboardContentWrapper content={content} activeView={activeView} setActiveView={setActiveView} />;
};

export default WatermeterTypePage;
