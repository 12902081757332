import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLocationNodeLevelsAsync, selectFetchLocationNodeLevelsSuccess } from './LocationNodeLevels/slice';

export default () => {
	const dispatch = useDispatch();

	const ensureLocationNodeLevelsLoaded = () => {
		const nodeLevelsFetchSuccess = useSelector(selectFetchLocationNodeLevelsSuccess);

		React.useEffect(() => {
			if (nodeLevelsFetchSuccess) return;

			dispatch(fetchLocationNodeLevelsAsync());
		}, [nodeLevelsFetchSuccess]);

		return nodeLevelsFetchSuccess;
	};

	return { ensureLocationNodeLevelsLoaded };
};
