export const moduleName = 'exports';

export const slices = {
	exportsTemplates: 'exportsTemplates',
	exportsGenerated: 'exportsGenerated',
	exportConfigurator: 'exportConfigurator'
};

export const routes = {
	generatedExports: (props) => {
		return props ? `/exports/done/${props.type}` : `/exports/done/:type`;
	},

	scheduledExports: (props) => {
		return props ? `/exports/templates/${props.type}` : `/exports/templates/:type`;
	},

	newExport: (props) => {
		return props ? `/exports/${props.type}/new` : `/exports/:type/new`;
	}
};
