import React from 'react';
import { useDispatch } from 'react-redux';
import { PanelListItem, PanelListItemActions, PanelListItemText, PanelListItemActionAdd } from '@sonar-web/common';
import LocationNodeLevelAvatar from '@sonar-web/location/src/LocationNodeLevels/LocationNodeLevelAvatar';
import LocationNodeLevelName from '../../LocationNodeLevels/LocationNodeLevelName';
import { addBreadcrumb } from '../../LocationNodes/slice';

const LocationsSelectAllListItem = ({ row, action }) => {
	const dispatch = useDispatch();
	const { hasChildren } = row;

	const handleGoDeeper = () => {
		if (hasChildren) dispatch(addBreadcrumb(row));
	};

	return (
		<PanelListItem
			id={row.id}
			row={row}
			renderFrontItem={(show) => (
				<>
					<LocationNodeLevelAvatar level={row.level} />
					<PanelListItemText
						primaryText={row.name}
						primaryTextAction={hasChildren ? handleGoDeeper : null}
						secondaryText={<LocationNodeLevelName locationNodeLevelId={row.locationNodeLevelId} />}
						secondaryTypographyProps={{ 'data-at-params': `LocationNodeLevelType|${row.level.type}` }}
						disableFlex={false}
					/>
					{action && (
						<PanelListItemActions show={show} shrink={false}>
							<PanelListItemActionAdd
								tooltip='Exports_AddLocationToExport'
								dataat='selectLocation'
								onClick={() => action(row)}
							/>
						</PanelListItemActions>
					)}
				</>
			)}
		/>
	);
};

export default LocationsSelectAllListItem;
