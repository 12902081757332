import { useEffect } from 'react';
import useConfigurator from './useConfigurator';

const RegistersDisabler = ({ disabledRegisters }) => {
	const { getCurrentData, disableAndHideGroupByKey, datasFormRegisters } = useConfigurator();

	const currentData = getCurrentData();
	const hasDatasFormRegisters = datasFormRegisters?.length > 0;
	const hasData = currentData?.deviceConfiguration?.deviceConfigurationItems?.length > 0 && hasDatasFormRegisters;

	useEffect(() => {
		if (!hasData) return;

		disabledRegisters.forEach((dr) => {
			const val = currentData.deviceConfiguration.deviceConfigurationItems.find((dci) => dci.key === dr.key);

			if (!val || val?.value === null) disableAndHideGroupByKey(dr.key);
		});
	}, [hasData]);

	return null;
};

export default RegistersDisabler;
