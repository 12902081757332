import React, { useState } from 'react';
import { Box } from '@mui/material';
import ButtonLinkAlt from '../../../../../components/Buttons/ButtonLinkAlt';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CustomDateTimePicker from './CustomDateTimePicker';

const DateItemAdd = ({ onAdd }) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<>
			<Box>
				<ButtonLinkAlt
					startIcon={<AddCircleOutlineOutlinedIcon fontSize='small' />}
					onClick={() => setIsOpen(true)}>
					Common_Add
				</ButtonLinkAlt>
			</Box>
			<CustomDateTimePicker value={null} onChange={onAdd} isOpen={isOpen} setIsOpen={setIsOpen} />
		</>
	);
};

export default DateItemAdd;
