import { useMemo } from 'react';
import { Box } from '@mui/material';
import { TableListItem } from '@sonar-web/common';
import DateAndTypeColumn from './DateAndTypeColumn';
import SourceColumn from './SourceColumn';
import ReadValuesColumn from './ReadValuesColumn';
import DeviceColumn from './DeviceColumn';

const WatermeterReadsLocationListItem = ({ row }) => {
	return useMemo(
		() => (
			<TableListItem row={row}>
				{() => (
					<Box display='flex' alignItems='center'>
						<DateAndTypeColumn type={row.type} date={row.date} />
						<SourceColumn source={row.source} />
						<DeviceColumn
							number={row.watermeterNumber}
							type={row.watermeterType}
							deviceId={row.watermeterId}
						/>
						<ReadValuesColumn volume={row.volume} reverseVolume={row.reverseVolume} />
					</Box>
				)}
			</TableListItem>
		),
		[row.id]
	);
};

export default WatermeterReadsLocationListItem;
