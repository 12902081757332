import { useEffect } from 'react';
import { useDeps } from '@sonar-web/common/src/features/Dependencies/depsContext';
import { moduleName } from './constants';
import useLocationNodeReadsDeps from './Dependencies/useLocationNodeReadsDeps';
import useMeasurementSetsDeps from './Dependencies/useMeasurementSetsDeps';
import useCoreDeps from './Dependencies/useCoreDeps';

import InterfaceType from '@sonar-web/common/src/enums/InterfaceType';

const RegisterWaterMeterModule = () => {
	const { setDeps, targetModules } = useDeps();
	const locationNodeReadsDeps = useLocationNodeReadsDeps();
	const measurementSetsDeps = useMeasurementSetsDeps();
	const coreDeps = useCoreDeps();

	useEffect(() => {
		setDeps(targetModules.location, moduleName, {
			locationNodeReads: {
				WarmWaterMeasurement: locationNodeReadsDeps,
				ColdWaterMeasurement: locationNodeReadsDeps
			},
			localPage: {
				reads: locationNodeReadsDeps
			}
		});

		setDeps(targetModules.measurementSet, moduleName, {
			measurementSetDevice: {
				addDevice: measurementSetsDeps.addDevice,
				fetchDeviceTypeById: measurementSetsDeps.fetchDeviceTypeById
			}
		});

		setDeps(targetModules.stats, moduleName, {
			buildingVisualization: [
				{
					name: 'Watermeters_ColdWaterMetersReads',
					interfaceType: InterfaceType.ColdWaterMeasurement.value
				},
				{
					name: 'Watermeters_WarmWaterMetersReads',
					interfaceType: InterfaceType.WarmWaterMeasurement.value
				}
			]
		});

		setDeps(targetModules.core, moduleName, {
			deviceTypes: {
				sidebarType: coreDeps.sidebarType,
				sidebarComponents: coreDeps.sidebarComponents,
				fetchDeviceTypes: coreDeps.fetchDeviceTypes,
				deleteDeviceTypeAsync: coreDeps.deleteDeviceTypeAsync,
				selectDeleteDeviceTypePending: coreDeps.selectDeleteDeviceTypePending,
				resetDeviceTypeData: coreDeps.resetDeviceTypeData
			}
		});
	}, []);

	return null;
};

export default RegisterWaterMeterModule;
