import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTenantsModeAsync, selectTenantsMode } from './tenantSlice';

const FetchTenantsMode = ({ children, fallback }) => {
	const dispatch = useDispatch();
	const { pending, mode } = useSelector(selectTenantsMode);

	useEffect(() => {
		if (mode !== null) return;
		dispatch(fetchTenantsModeAsync());
	}, []);

	if (pending || !mode) return fallback ?? null;
	return children;
};

export default FetchTenantsMode;
