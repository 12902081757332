import axios from 'axios';
import AuthService from '@sonar/auth/src/AuthService';
import { API_BASE_URL, TENANT_ENABLED } from '../constants';
import ReduxHelpers from '../helpers/ReduxHelpers';

async function apiBase(
	endpoint,
	method,
	{ overrideBaseUrl, overrideToken, path = '', data, params, ...customConfig } = {}
) {
	const token = overrideToken ?? AuthService.getToken();
	const headers = { 'content-type': 'application/json' };

	if (token) headers.Authorization = `Bearer ${token}`;
	if (TENANT_ENABLED == 1) {
		const tenant = ReduxHelpers.getDirectlyFormStore('tenant.tenant');
		if (tenant?.id) headers['TenantId'] = tenant.id;
		if (tenant?.name) headers['TenantName'] = encodeURIComponent(tenant.name);
	}

	const config = {
		method,
		timeout: 600000,
		...customConfig,
		headers: {
			...headers,
			...customConfig.headers
		}
	};

	if (data) config.data = data;
	if (params) config.params = stringifyPageDescriptor(params);

	const url = overrideBaseUrl ?? getBaseUrl(path);

	return axios(`${url}/${endpoint}`, config);
}

export default apiBase;

export function getBaseUrl(path = '') {
	return `${API_BASE_URL}${path}`;
}

function stringifyPageDescriptor(params) {
	if (!params?.pageDescriptor) return params;
	return {
		...params,
		pageDescriptor: JSON.stringify(params.pageDescriptor)
	};
}
