import React from 'react';
import { Box } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { Spacer } from '@sonar-web/common';

const ReadSkeleton = () => {
	return (
        <Box flex='1' width={500}>
			<Box display='flex'>
				<Skeleton animation='wave' variant='text' height={40} width='50%' />
				<Spacer />
				<Skeleton animation='wave' variant="circular" height={20} width={20} />
				<Skeleton animation='wave' variant="circular" height={20} width={20} />
			</Box>
			<Skeleton animation='wave' variant='text' height={40} />
			<Skeleton animation='wave' variant='text' height={40} />
		</Box>
    );
};

export default ReadSkeleton;
