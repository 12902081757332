import { apiBase } from '@sonar-web/common';
import { REST_API_PATHS } from '@sonar-web/common/src/constants/system';

const path = REST_API_PATHS.WATERMETERS;

export async function fetchWatermeterReads(pageDescriptor) {
	const { data } = await apiBase(`reads`, 'get', {
		path,
		params: { pageDescriptor }
	});

	return data;
}
