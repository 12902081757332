import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { pageDescriptor, getSliceDescriptor } from '@sonar-web/common';
import { cloneDeep } from 'lodash-es';
import { addErrorAsync } from '../Errors/errorsSlice';

const listsByName = {};

//thunks
export const fetchThunk = (sliceName) =>
	createAsyncThunk(
		`${sliceName}/fetchThunk`,
		async ({ endpoint, endpointConfig = {}, payload = {} }, { getState, dispatch }) => {
			try {
				const { defaultFilters, defaultSorters, limit, skipFilters } = payload;
				const pd = getSliceDescriptor(getState, sliceName, defaultFilters, defaultSorters, limit);

				if (skipFilters) pd.FilterDescriptors = [];

				return await endpoint(pd, endpointConfig);
			} catch (error) {
				if (error.message === 'canceled') return;
				dispatch(addErrorAsync({ slice: sliceName, error }));
				throw error;
			}
		}
	);

const initState = {
	pageDescriptor,
	pending: false,
	totalCount: 0,
	hasMore: true,
	reset: false,
	elements: [],
	multiSelect: false,
	multiSelectSelectAll: false,
	multiSelectLastTouched: null,
	selected: [],
	notifications: {},
	metadata: {} //pozostałe dane obok elements i totlacount
};

export const createGenericListSlice = (sliceName) => {
	const thunk = fetchThunk(sliceName);
	return createSlice({
		name: sliceName,
		initialState: initState,
		reducers: {
			setResetAndReload: (state, { payload }) => {
				state.reset = payload ?? true;
				state.pageDescriptor.Offset = 0;
				state.multiSelect = false;
				state.multiSelectSelectAll = false;
				state.multiSelectLastTouched = null;
			},
			setOffset: (state, action) => {
				state.pageDescriptor.Offset = action.payload
					? state.pageDescriptor.Offset + state.pageDescriptor.Limit
					: 0;
			},
			setSelected: (state, action) => {
				const { record, add } = action.payload;

				if (record === null) {
					state.selected = [];
					return;
				}

				if (!state.multiSelect) {
					if (add) state.selected = [record];
					else state.selected = [];
					return;
				}

				if (add) state.selected = [...state.selected, record];
				else state.selected = state.selected.filter((s) => s.id !== record.id);
			},
			setElements: (state, { payload }) => {
				state.elements = payload;
				state.totalCount = payload?.length ?? 0;
			},
			setAllSelected: (state, { payload }) => {
				if (!payload) {
					state.selected = [];
					return;
				}

				const elements = state.elements.slice();
				state.selected = elements;
			},
			setShiftSelected: (state, { payload }) => {
				const { fromIndex, toIndex, doSelect } = payload;
				const start = fromIndex > toIndex ? toIndex : fromIndex;
				const end = fromIndex > toIndex ? fromIndex : toIndex;

				const elements = state.elements.slice(start, end + 1);
				const selected = state.selected.slice();

				if (doSelect) {
					for (const e of elements) {
						const isSelected = selected.some((s) => s.id === e.id);
						if (isSelected) continue;
						selected.push(e);
					}
				} else {
					for (const e of elements) {
						const isSelected = selected.findIndex((s) => s.id === e.id);
						if (isSelected !== -1) selected.splice(isSelected, 1);
					}
				}

				state.selected = selected;
			},
			toggleMultiSelect: (state, action) => {
				if (state.multiSelect || action.payload === false) {
					state.multiSelectSelectAll = false;
					state.multiSelectLastTouched = null;
				}
				state.multiSelect = action.payload === false ? false : !state.multiSelect;
			},
			toggleMultiSelectSelectAll: (state, { payload }) => {
				state.multiSelectSelectAll = payload;
			},
			setMultiSelectLastTouched: (state, { payload }) => {
				state.multiSelectLastTouched = payload;
			},
			updateSingleRecord: (state, { payload }) => {
				const { id, value } = payload;
				const index = state.elements.findIndex((e) => e.id === id);

				if (index === -1) return;
				state.elements[index] = { ...state.elements[index], ...value };
			},
			updateManyRecords: (state, { payload }) => {
				const { records, overrideAll } = payload;

				if (!records) return;
				if (overrideAll) state.elements = records;
				else {
					const prev = [...state.elements];
					for (const r of records) {
						if (!r) continue;
						const index = prev.findIndex((e) => e.id === r.id);
						if (index === -1) continue;
						prev[index] = { ...prev[index], ...r };
					}
					state.elements = prev;
				}
			},
			deleteSingleRecord: (state, { payload }) => {
				const prev = [...state.elements];
				const index = prev.findIndex((e) => e.id === payload);

				if (index === -1) return;

				prev.splice(index, 1);

				state.elements = prev;
				state.totalCount = state.totalCount - 1;
			},
			setNotifications: (state, { payload }) => {
				const { name, value } = payload;

				if (value == null) {
					const previous = cloneDeep(state.notifications);

					if (!previous[name]) return;

					delete previous[name];
					state.notifications = previous;

					return;
				}

				state.notifications[name] = value;
			},
			setCustomProperty: (state, { payload }) => {
				const { name, value, operation } = payload;

				if (!operation) {
					state[name] = value;
					return;
				}
				if (operation === 'subtract') state[name] -= value;
				else if (operation === 'add') state[name] += value;
			},
			changeElementOrder: (state, { payload }) => {
				const { fromIndex, toIndex } = payload;
				const elements = state.elements.slice();
				const element = elements[fromIndex];

				elements.splice(fromIndex, 1);
				elements.splice(toIndex, 0, element);

				state.elements = elements;
			}
		},
		extraReducers: (builder) => {
			builder.addCase(thunk.pending, (state) => {
				state.pending = true;
			});
			builder.addCase(thunk.fulfilled, (state, { payload }) => {
				if (!payload) return;
				const result = Array.isArray(payload) ? { elements: payload, totalCount: payload.length } : payload;
				const { elements, totalCount, ...rest } = result;

				state.elements = state.pageDescriptor.Offset === 0 ? elements : [...state.elements, ...elements];
				state.totalCount = totalCount;
				state.pending = false;
				state.reset = false;
				state.hasMore = totalCount > state.pageDescriptor.Offset + state.pageDescriptor.Limit;

				if (rest) state.metadata = rest;

				if (state.multiSelect && state.multiSelectSelectAll && state.pageDescriptor.Offset !== 0)
					state.selected = [...state.selected, ...elements];
				if (state.multiSelect && state.pageDescriptor.Offset === 0) {
					state.multiSelectSelectAll = false;
					state.selected = [];
				}
			});
			builder.addCase(thunk.rejected, (state) => {
				state.pending = false;
			});
		}
	});
};

//reducers
export const setElements = (listName) => listsByName[listName].actions.setElements;
export const setOffset = (listName) => listsByName[listName].actions.setOffset;
export const setSelected = (listName) => listsByName[listName].actions.setSelected;
export const setAllSelected = (listName) => listsByName[listName].actions.setAllSelected;
export const setShiftSelected = (listName) => listsByName[listName].actions.setShiftSelected;
export const setResetAndReload = (listName) => listsByName[listName].actions.setResetAndReload;
export const toggleMultiSelect = (listName) => listsByName[listName].actions.toggleMultiSelect;
export const toggleMultiSelectSelectAll = (listName) => listsByName[listName].actions.toggleMultiSelectSelectAll;
export const setMultiSelectLastTouched = (listName) => listsByName[listName].actions.setMultiSelectLastTouched;
export const updateSingleRecord = (listName) => listsByName[listName].actions.updateSingleRecord;
export const updateManyRecords = (listName) => listsByName[listName].actions.updateManyRecords;
export const deleteSingleRecord = (listName) => listsByName[listName].actions.deleteSingleRecord;
export const setNotifications = (listName) => listsByName[listName].actions.setNotifications;
export const setCustomProperty = (listName) => listsByName[listName].actions.setCustomProperty;
export const changeElementOrder = (listName) => listsByName[listName].actions.changeElementOrder;

//selectors
export const selectList = (listName) =>
	createSelector(
		(state) => state[listName],
		(listSlice) => ({
			elements: listSlice.elements,
			pending: listSlice.pending,
			totalCount: listSlice.totalCount,
			hasMore: listSlice.hasMore,
			reset: listSlice.reset
		})
	);

export const selectSelection = (listName) => (state) => state[listName].selected;
export const selectMultiSelect = (listName) => (state) => state[listName].multiSelect;
export const selectMultiSelectSelectAll = (listName) => (state) => state[listName].multiSelectSelectAll;
export const selectMultiSelectLastTouched = (listName) => (state) => state[listName].multiSelectLastTouched;
export const selectNotifications = (listName) => (state) => state[listName].notifications;
export const selectCustomProperty = (listName, propertyName) => (state) => state[listName][propertyName];
export const selectMetadata = (listName) => (state) => state[listName].metadata;

//slice generator
const createListSlice = (name) => {
	const slice = createGenericListSlice(name);

	listsByName[name] = {
		actions: slice.actions
	};

	return slice.reducer;
};

export default createListSlice;
