import React from 'react';
import { Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '../../hooks';

const useStyles = makeStyles((theme) => ({
	arrow: {
		color: theme.palette.error.dark
	},
	tooltip: {
		backgroundColor: theme.palette.error.dark
	}
}));

const ErrorTooltip = ({ children, errorMessage, ...rest }) => {
	const classes = useStyles();
	const { translate } = useLocale();

	return (
		<Tooltip arrow placement='top' {...rest} classes={classes} title={translate(errorMessage)}>
			{children}
		</Tooltip>
	);
};

export default ErrorTooltip;
