import { createSlice } from '@reduxjs/toolkit';
import { pageDescriptor, getSliceFilters } from '@sonar-web/common';
import { fetchHeatallocatorTypes } from './api';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';

const slice = 'heatAllocatorTypesSearch';

export const heatAllocatorTypesSearchSlice = createSlice({
	name: slice,
	initialState: {
		pageDescriptor,
		fetchPending: false,
		heatAllocatorTypes: [],
		totalCount: 0,
		hasMore: true,
		reload: false,
		reset: true
	},
	reducers: {
		fetchHeatallocatorTypesSuccess: (state, action) => {
			let fetched = [...action.payload.elements];

			state.heatAllocatorTypes = state.reset ? fetched : state.heatAllocatorTypes.concat(fetched);
			state.totalCount = action.payload.totalCount;
			state.fetchPending = false;
		},
		fetchHeatallocatorTypesPending: (state) => {
			state.fetchPending = true;
		},
		setHasMore: (state) => {
			state.hasMore = state.totalCount > state.pageDescriptor.Offset + state.pageDescriptor.Limit;
		},
		setResetAndReload: (state) => {
			state.heatAllocatorTypes = [];
			state.reload = true;
			state.reset = true;
			state.hasMore = true;
			state.pageDescriptor.Offset = 0;
			state.pageDescriptor.FilterDescriptors = [];
			state.pageDescriptor.SortDescriptors = [];
		},
		setResetToFirstPage: (state) => {
			state.heatAllocatorTypes = [];
			state.reload = true;
			state.reset = true;
			state.hasMore = true;
			state.pageDescriptor.Offset = 0;
		},
		setAppend: (state, action) => {
			state.reset = !action.payload;
			if (action.payload) state.pageDescriptor.Offset += state.pageDescriptor.Limit;
			else state.pageDescriptor.Offset = 0;
		},
		setReload: (state) => {
			state.reload = true;
		}
	}
});

export const {
	fetchHeatallocatorTypesSuccess,
	fetchHeatallocatorTypesPending,
	setHasMore,
	setResetAndReload,
	setResetToFirstPage,
	setAppend,
	setReload
} = heatAllocatorTypesSearchSlice.actions;

export const fetchHeatallocatorTypesAsync = () => async (dispatch, getState) => {
	let response;

	try {
		dispatch(fetchHeatallocatorTypesPending());

		let pd = Object.assign({}, getState().heatAllocatorTypesSearch.pageDescriptor);

		pd.FilterDescriptors = getSliceFilters(getState(), slice);
		pd.SortDescriptors = [{ Member: 'nameToSort', ListSortDirection: 1 }];

		response = await fetchHeatallocatorTypes(pd);
	} catch (error) {
		dispatch(addErrorAsync({ slice, error }));
		return;
	}

	dispatch(fetchHeatallocatorTypesSuccess(response));
	dispatch(setHasMore());
};

export const fetchHeatallocatorDeviceTypeByIdAsync = (filter) => async (dispatch, getState) => {
	try {
		let pd = Object.assign({}, getState().tcpDeviceTypesSearch.pageDescriptor);

		pd.FilterDescriptors = filter;
		pd.SortDescriptors = [];

		return await fetchHeatallocatorTypes(pd);
	} catch (error) {
		dispatch(addErrorAsync({ slice, error }));
		return;
	}
};

export const selectHeatallocatorTypes = (state) => {
	return {
		dataRows: state.heatAllocatorTypesSearch.heatAllocatorTypes,
		pending: state.heatAllocatorTypesSearch.fetchPending,
		hasMore: state.heatAllocatorTypesSearch.hasMore,
		reload: state.heatAllocatorTypesSearch.reload,
		totalCount: state.heatAllocatorTypesSearch.totalCount
	};
};

export default heatAllocatorTypesSearchSlice.reducer;
