import React from 'react';
import { ButtonBase, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import HorizontalSpacer from '../HorizontalSpacer/HorizontalSpacer';

const useStyles = makeStyles((theme) => ({
	buttonBaseRoot: {
		border: `1px solid ${theme.palette.divider}`,
		width: '47%',
		padding: theme.spacing(2),
		borderRadius: 2,
		color: theme.palette.text.secondary,
		transition: `background 300ms ${theme.transitions.easing.easeInOut}`,
		'&:hover': {
			background: theme.palette.primaryLight.light
		}
	},
	active: {
		borderColor: theme.palette.primary.main
	}
}));

const FormToggleButton = ({ icon, text, onValue, value, onClick, ...rest }) => {
	const classes = useStyles();

	const handleOnClick = () => onClick(onValue);

	return (
		<ButtonBase
			className={clsx(classes.buttonBaseRoot, { [classes.active]: value === onValue })}
			onClick={handleOnClick}
			{...rest}>
			<Box display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
				{icon}
				<HorizontalSpacer size={1} />
				{text}
			</Box>
		</ButtonBase>
	);
};

export default FormToggleButton;
