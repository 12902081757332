import React from 'react';
import { FormControl, InputLabel, Select } from '@mui/material';
import { useLocale } from '@sonar-web/common/src/hooks';
import BaseSelectMenuItem from '@sonar-web/common/src/components/BaseSelectMenuItem/BaseSelectMenuItem';
import { timeFormats } from './constants';

const name = 'timeFormat';
const SettingsTimeFormat = ({ updateSettings, current }) => {
	const { translate } = useLocale();

	const options = [
		{ value: timeFormats.time24h, label: translate('Common_TimeFormat24H') },
		{ value: timeFormats.time12h, label: translate('Common_TimeFormat12h') }
	];

	const handleChange = (e) => {
		updateSettings(name, e.target.value);
	};

	return (
		<FormControl fullWidth margin='normal'>
			<InputLabel id={name}>{translate('Common_TimeFormat')}</InputLabel>
			<Select labelId={name} value={current[name]} onChange={handleChange}>
				{options.map((opt, index) => (
					<BaseSelectMenuItem key={index} value={opt.value}>
						{translate(opt.label)}
					</BaseSelectMenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default SettingsTimeFormat;
