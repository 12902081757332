import React from 'react';
import { Box, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { BaseTooltip } from '@sonar-web/common';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const useStyles = makeStyles((theme) => ({
	collapseBtnRoot: {
		display: 'block',
		margin: '0 auto'
	},
	wrapper: {
		transition: theme.transitions.create(['height'], {
			easing: theme.transitions.easing.easeInOut,
			duration: theme.transitions.duration.complex
		})
	}
}));

const CollapsibleComponent = ({ renderContent, toggle, labelShow, labelHide, heightCollapsed }) => {
	const classes = useStyles();
	const [collapse, setCollapse] = React.useState(true);
	const [maxHeight, setMaxHeight] = React.useState('auto');
	const ulRef = React.useRef();

	const onToggleCollapse = () => {
		if (ulRef.current) setMaxHeight(ulRef.current.scrollHeight);
		else setMaxHeight('auto');

		setCollapse(!collapse);
	};

	React.useEffect(() => {
		if (!toggle) setCollapse(true);
	}, [toggle]);

	return (
		<>
			<Box overflow='hidden' height={collapse ? heightCollapsed : maxHeight} className={classes.wrapper}>
				{renderContent()}
			</Box>
			<BaseTooltip text={collapse ? labelShow : labelHide}>
				<IconButton size='small' onClick={onToggleCollapse} classes={{ root: classes.collapseBtnRoot }}>
					{collapse ? <ExpandMoreIcon fontSize='small' /> : <ExpandLessIcon fontSize='small' />}
				</IconButton>
			</BaseTooltip>
		</>
	);
};

export default CollapsibleComponent;
