import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { IconButton, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CloseIcon from '@mui/icons-material/Close';
import { selectLocale } from '../../features/Locales/localesSlice';
import { useLocale } from '../../hooks';

const useStyles = makeStyles(() => ({
	adornmentIcon: {
		padding: '2px',
		marginRight: '-2px'
	},
	labelShrink: { width: 'auto' }
}));

const BaseDatePicker = ({
	label,
	value,
	error,
	helperText,
	FormHelperTextProps,
	onChange,
	onClear,
	fullWidth = false,
	mask = '__.__.__',
	format = moment.localeData().longDateFormat('L'),
	handleOnChange = false,
	textFieldProps = {},
	...rest
}) => {
	const classes = useStyles();
	const locale = useSelector(selectLocale);
	const { translate } = useLocale();

	const handleChange = (v) => {
		const { disableFuture, minDate } = { ...rest };
		let invalidFuture = false;
		let invalidPast = false;

		if (!v?.isValid()) return;
		if (disableFuture) invalidFuture = v.isAfter(moment(), 'day');
		if (minDate) invalidPast = v.isBefore(moment(minDate), 'day');

		if (invalidFuture) onChange(moment());
		else if (invalidPast) onChange(moment(minDate));
		else onChange(v);
	};

	return (
        (<LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
            <DatePicker
				disableMaskedInput
				mask={mask}
				variant='inline'
				format={format}
				label={label ? translate(label) : null}
				value={value}
				onChange={handleOnChange ? onChange : handleChange}
				classes={{ root: classes.textfieldRoot }}
				clearable={false}
				InputLabelProps={{
					classes: {
						root: classes.labelRoot,
						shrink: classes.labelShrink
					}
				}}
				InputAdornmentProps={{
					sx: {
						marginLeft: '-2px'
					}
				}}
				OpenPickerButtonProps={{
					sx: {
						padding: '4px',
						marginRight: '-4px'
					}
				}}
				renderInput={(props) => (
					<TextField
                        {...props}
                        value={value}
                        fullWidth={fullWidth}
                        error={error}
                        helperText={translate(helperText)}
                        {...textFieldProps}
                        slotProps={{
                            input: {
                                ...props.InputProps,
                                endAdornment: (
                                    <>
                                        {value && onClear && (
                                            <IconButton
                                                size='small'
                                                aria-label='filter_date_clear'
                                                style={{ padding: 0 }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    onClear();
                                                }}>
                                                <CloseIcon fontSize='small' />
                                            </IconButton>
                                        )}
                                        {props.InputProps.endAdornment}
                                    </>
                                )
                            },

                            formHelperText: FormHelperTextProps
                        }} />
				)}
				{...rest}
			/>
        </LocalizationProvider>)
    );
};

export default BaseDatePicker;
