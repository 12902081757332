import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { useLocale } from '@sonar-web/common/src/hooks';

const Wrapper = styled('div')({
	display: 'flex',
	alignItems: 'center'
});

const Line = styled('span')({
	flex: 1,
	marginLeft: '1.5rem',
	borderBottom: '1px dotted #e0e0e0'
});

const LicensesHeader = () => {
	const { translate } = useLocale();

	return (
		<Wrapper>
			<Typography variant='h6'>{translate('License')}</Typography>
			<Line />
		</Wrapper>
	);
};

export default LicensesHeader;
