import React from 'react';
import BaseIcon from './BaseIcon';

export default function TcpDevicesIcon(props) {
	return (
		<BaseIcon
			{...props}
			renderMultiPath={({ primaryPathClass, primaryPathColor, secondaryPathClass, secondaryPathColor }) => (
				<path
					className={primaryPathClass}
					style={{ color: primaryPathColor }}
					d='m 6.0037248,3.4002648 c -1.43033,0 -2.60346,1.17366 -2.60346,2.60398 V 17.996275 c 0,1.43032 1.17313,2.60346 2.60346,2.60346 H 17.996275 c 1.43033,0 2.60346,-1.17314 2.60346,-2.60346 V 6.0042448 c 0,-1.43032 -1.17313,-2.60398 -2.60346,-2.60398 z m 0,1.32447 H 17.996275 c 0.72034,0 1.27899,0.55921 1.27899,1.27951 V 17.996275 c 0,0.7203 -0.55865,1.27899 -1.27899,1.27899 h -2.50321 c 0.21529,-0.37855 0.339,-0.81509 0.339,-1.27899 v -7.9375 c 0,-1.4303202 -1.17107,-2.6013902 -2.60139,-2.6013902 H 6.0037248 c -0.46393,0 -0.90042,0.12368 -1.27899,0.339 v -1.79214 c 0,-0.7203 0.55865,-1.27951 1.27899,-1.27951 z m 0,4.05453 h 7.2269502 c 0.72031,0 1.27899,0.5592 1.27899,1.2795102 v 7.9375 c 0,0.7203 -0.55868,1.27899 -1.27899,1.27899 H 6.0037248 c -0.17991,0 -0.34975,-0.03464 -0.50333,-0.09818 h -5.2e-4 c -0.4611,-0.19099 -0.77514,-0.64059 -0.77514,-1.18081 v -7.9375 c 0,-0.7203102 0.55869,-1.2795102 1.27899,-1.2795102 z m 2.08204,5.1366402 c -1.13242,0 -2.06447,0.93205 -2.06447,2.06447 0,1.13243 0.93205,2.06448 2.06447,2.06448 1.13243,0 2.0644802,-0.93205 2.0644802,-2.06448 0,-1.13242 -0.9320502,-2.06447 -2.0644802,-2.06447 z m 0,1.3224 c 0.41747,0 0.74259,0.32461 0.74259,0.74207 0,0.41747 -0.32512,0.74259 -0.74259,0.74259 -0.41746,0 -0.74207,-0.32512 -0.74207,-0.74259 0,-0.41746 0.32461,-0.74207 0.74207,-0.74207 z'
				/>
			)}
		/>
	);
}
