import React from 'react';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '../../../../hooks';
import SelectControlBase from './SelectControlBase';
import TileItem from './TileItem';
import { cronLastDayOfMonth, dayOfTheMonthToString } from '../constants';

const useStyles = makeStyles((theme) => ({
	grid: {
		display: 'grid',
		gridTemplateColumns: 'repeat(7, 1fr)',
		alignItems: 'center',
		gap: `12px`,
		padding: theme.spacing(1),
		[theme.breakpoints.down('lg')]: {
			gridTemplateColumns: '1fr 1fr 1fr 1fr'
		}
	},
	tile3Columns: {
		gridColumnEnd: 'span 3'
	}
}));

const SelectDayOfTheMonth = ({ selectedValues, onChange }) => {
	const classes = useStyles();
	const { translate } = useLocale();

	const toggleSelected = (v) => {
		let newValues = [...selectedValues];

		const index = newValues.indexOf(v);
		if (index > -1) {
			newValues.splice(index, 1);
		} else {
			newValues.push(v);
		}

		onChange(newValues);
	};

	return (
		<SelectControlBase
			title={translate('Common_DayOfMonth')}
			renderContent={() => {
				return (
					<Box display='flex' flexDirection='column' flex={1} paddingY={1} marginX={-5}>
						<Box className={classes.grid}>
							{Array.from({ length: 31 }, (_, i) => i + 1)
								.concat([cronLastDayOfMonth])
								.map((day, index) => {
									const selected = selectedValues.includes(day);
									return (
										<Box
											key={index}
											display='flex'
											justifyContent='center'
											flex={1}
											className={day == cronLastDayOfMonth ? classes.tile3Columns : null}>
											<TileItem
												minWidth={45}
												maxWidth={260}
												flex={day == cronLastDayOfMonth ? 1 : null}
												p={0.5}
												text={dayOfTheMonthToString(day, translate)}
												selected={selected}
												onClick={() => {
													toggleSelected(day);
												}}
											/>
										</Box>
									);
								})}
						</Box>
					</Box>
				);
			}}
		/>
	);
};

export default SelectDayOfTheMonth;
