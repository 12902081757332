import { useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { removeAllSliceFilters, selectFilterByName } from './filtersSlice';
import EventBus from '../../helpers/EventBus';

export default (slice) => {
	if (!slice) return {};
	const dispatch = useDispatch();

	const filtersSelectorFunction = useMemo(() => selectFilterByName(slice), [slice]);
	const filters = useSelector(filtersSelectorFunction, shallowEqual);

	const hasCurrentFilters = (ignored) => {
		if (filters == null) return false;

		const currentFilters = Object.keys(filters);

		if (!ignored) return currentFilters.length;
		return currentFilters.filter((cf) => !ignored.includes(cf));
	};

	const sliceFilters = () => {
		if (!filters) return [];

		let result = [];

		for (const member in filters) {
			const memberFilters = filters[member];

			memberFilters.map((mf) => {
				result = [...result, mf];
			});
		}

		return result;
	};

	const clearAllFilters = () => {
		dispatch(removeAllSliceFilters(slice));
		EventBus.dispatch(EventBus.messages.FilteringChanged, { slice });
	};

	return { sliceFilters, hasCurrentFilters, clearAllFilters };
};
