import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import SearchAutocompleteSoloSimple from './SearchAutocompleteSoloSimple';

const SearchControlSimpleBase = ({
	apiResultObjectname = 'elements',
	slice,
	onSelect,
	api,
	loading,
	disableSearch,
	...rest
}) => {
	const dispatch = useDispatch();
	const [pending, setPending] = useState(false);
	const [elements, setElements] = useState([]);

	const fetch = async (val) => {
		try {
			setPending(true);
			const result = await api(val);

			setElements(result[apiResultObjectname]);
		} catch (error) {
			dispatch(addErrorAsync({ slice, error }));
		}
		setPending(false);
	};

	const handleOnChange = (value) => {
		setElements([]);
		onSelect(value);
	};

	const searchData = (inputValue) => {
		fetch(inputValue);
	};

	return useMemo(() => (
		<SearchAutocompleteSoloSimple
			options={elements}
			loading={pending || loading}
			handleOnChange={handleOnChange}
			searchData={searchData}
			disableSearch={disableSearch}
			{...rest}
		/>
	));
};

export default SearchControlSimpleBase;
