import styled from '@emotion/styled';
import { Box } from '@mui/material';
import FloorStaircase from './FloorStaircase';
import Local from './Local';

const Inner = styled('div')({
	display: 'flex',
	width: '100%',
	gap: 2
});

const Floor = ({ staircase, floor, floorName, locals, maxLocalsPerStaircase }) => {
	const localsCount = locals?.length || 0;
	const emptyLocalsCount = maxLocalsPerStaircase - localsCount;
	const emptyLocals = Array.from(Array(emptyLocalsCount).keys());

	return (
		<Box display='flex' className={`floor_${floorName}`}>
			<FloorStaircase />
			<Inner>
				{locals.map((l) => {
					return <Local key={l.name} staircase={staircase} floor={floor} local={l.name} points={l.points} />;
				})}
				{emptyLocals.map((el) => {
					return <Local key={el} empty={true} />;
				})}
			</Inner>
		</Box>
	);
};

export default Floor;
