import { createSlice } from '@reduxjs/toolkit';
import { pageDescriptor, getSliceDescriptor } from '@sonar-web/common';
import { fetchDevices } from './devicesApi';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';

const slice = 'wmbusDevices';

export const devicesSlice = createSlice({
	name: slice,
	initialState: {
		pageDescriptor,
		pending: false,
		totalCount: 0,
		hasMore: true,
		reset: false,
		Devices: [],
		selected: null
	},
	reducers: {
		fetchDevicesSucces: (state, action) => {
			const { elements, totalCount } = action.payload;

			state.Devices = state.pageDescriptor.Offset === 0 ? elements : [...state.Devices, ...elements];
			state.totalCount = totalCount;
			state.pending = false;
			state.reset = false;
			state.hasMore = totalCount > state.pageDescriptor.Offset + state.pageDescriptor.Limit;
		},
		fetchDevicesPending: (state, action) => {
			state.pending = action.payload;
		},
		fetchDevicesCountSucces: (state, action) => {
			state.totalCount = action.payload;
		},
		setNextPage: (state) => {
			state.pageDescriptor.Offset += state.pageDescriptor.Limit;
		},
		setHasMore: (state) => {
			state.hasMore = state.totalCount > state.pageDescriptor.Offset + state.pageDescriptor.Limit;
		},
		setResetAndReload: (state, action) => {
			state.reset = action.payload ?? true;
			state.pageDescriptor.Offset = 0;
		},
		setOffset: (state, action) => {
			state.pageDescriptor.Offset = action.payload ? state.pageDescriptor.Offset + state.pageDescriptor.Limit : 0;
		},
		setSelected: (state, action) => {
			state.selected = action.payload;
		}
	}
});

export const {
	fetchDevicesSucces,
	fetchDevicesPending,
	setResetAndReload,
	setSelected,
	setOffset
} = devicesSlice.actions;

export const fetchDevicesAsync = () => async (dispatch, getState) => {
	try {
		const pd = getSliceDescriptor(getState, slice);

		dispatch(fetchDevicesPending(true));

		const response = await fetchDevices(pd);

		dispatch(fetchDevicesSucces(response));
	} catch (error) {
		dispatch(fetchDevicesPending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const selectDevices = (state) => {
	return {
		dataRows: state.wmbusDevices.Devices,
		pending: state.wmbusDevices.pending,
		hasMore: state.wmbusDevices.hasMore,
		reset: state.wmbusDevices.reset,
		totalCount: state.wmbusDevices.totalCount
	};
};

export const selectSelected = (state) => state.wmbusDevices.selected;

export default devicesSlice.reducer;
