import React from 'react';
import { Box, Typography } from '@mui/material';
import { TableListColumn } from '@sonar-web/common';

const ReadValuesColumn = ({ bearing, flex = false }) => {
	return (
		<TableListColumn flex={flex} width={flex ? 'auto' : 200}>
			<Box display='flex' flexDirection='column' alignItems='flex-end'>
				<Typography variant='body2' color='textPrimary' data-at='bearing'>
					{bearing}
				</Typography>
			</Box>
		</TableListColumn>
	);
};

export default ReadValuesColumn;
