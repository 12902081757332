import { Box } from '@mui/material';
import { BaseGrowAnimation } from '..';

const FormWrapper = ({ name, children, animationProps, ...rest }) => {
	return (
		<BaseGrowAnimation show={true} {...animationProps}>
			<Box flex='1' alignSelf='center' width={500} margin='0 auto' data-at={name} {...rest}>
				{children}
			</Box>
		</BaseGrowAnimation>
	);
};

export default FormWrapper;
