import React from 'react';
import { BaseIcon } from '@sonar-web/common';

export default function DownloadIcon(props) {
	return (
		<BaseIcon {...props}>
			<path d='M 11 2 L 11 11.794922 L 7.1835938 7.9785156 L 5.7695312 9.3925781 L 12 15.623047 L 18.230469 9.3925781 L 16.816406 7.9785156 L 13 11.794922 L 13 2 L 11 2 z M 2.2167969 15.304688 L 2.2167969 19.423828 C 2.2167969 20.834011 3.3827857 22 4.7929688 22 L 19.205078 22 C 20.615261 22 21.783203 20.834011 21.783203 19.423828 L 21.783203 15.304688 L 19.783203 15.304688 L 19.783203 19.423828 C 19.783203 19.760605 19.541855 20 19.205078 20 L 4.7929688 20 C 4.4561918 20 4.2167969 19.760605 4.2167969 19.423828 L 4.2167969 15.304688 L 2.2167969 15.304688 z ' />
		</BaseIcon>
	);
}
