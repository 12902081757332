import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatDateTimeSecondsPrecision, TableListItemDelete } from '@sonar-web/common';
import { useLocale } from '@sonar-web/common/src/hooks';
import { deleteReadAsync, selectDeleteRead } from '../Read/readSlice';
import { slices } from '../Constants/Module';
import useHeatallocators from '../useHeatallocators';

const HeatallocatorReadsListItemDelete = ({ id, date, deleteMode, setDeleteMode }) => {
	const dispatch = useDispatch();
	const { translate } = useLocale();
	const { setNotification } = useHeatallocators(slices.heatallocatorReads);
	const { deletePending } = useSelector(selectDeleteRead);

	const handleDelete = () => {
		dispatch(deleteReadAsync(id)).then((err) => {
			if (err) return;
			setDeleteMode(false);
			setNotification('HeatallocatorReadDeleted', { id });
		});
	};

	if (!deleteMode) return null;
	return (
		<TableListItemDelete
			deleteMode={deleteMode}
			setDeleteMode={setDeleteMode}
			pending={deletePending}
			onDelete={handleDelete}
			primaryText={translate('HeatAllocators_ManualReadNameParam', formatDateTimeSecondsPrecision(date))}
			secondaryText={translate('HeatAllocators_ManualReadDeleteThisQ')}
		/>
	);
};

export default HeatallocatorReadsListItemDelete;
