import FileUploadTypes from './FileUploadTypes';

export const filesUploadStateName = 'filesUpload';

const initialFileState = {
	name: '',
	required: true,
	isSizeValid: false,
	isExtensionValid: false,
	verified: null,
	verificationError: null
};

export const initialFilesUploadData = {
	fetchPending: {
		[FileUploadTypes.Infrastructure.id]: false,
		[FileUploadTypes.Changes.id]: false,
		[FileUploadTypes.Reads.id]: false
	},
	fetchSuccess: false,
	templatePending: {
		[FileUploadTypes.Infrastructure.name]: false,
		[FileUploadTypes.Changes.name]: false,
		[FileUploadTypes.Reads.name]: false
	},
	valid: false,
	verified: false,
	files: {
		[FileUploadTypes.Infrastructure.name]: initialFileState,
		[FileUploadTypes.Changes.name]: { ...initialFileState, required: false },
		[FileUploadTypes.Reads.name]: initialFileState
	}
};

export const filesUploadReducers = {
	setInputFilesFetchSucces: (state) => {
		state[filesUploadStateName].fetchPending = false;
		state[filesUploadStateName].fetchSuccess = true;
	},
	setInputFilesFetchPending: (state, { payload }) => {
		state[filesUploadStateName].fetchPending[payload.type] = payload.value;
	},
	resetInputFilesFetchSuccess: (state) => {
		state[filesUploadStateName].fetchSuccess = false;
	},
	setTemplateFetchPending: (state, { payload }) => {
		state[filesUploadStateName].templatePending[payload.type] = payload.value;
	},
	resetFilesUploadData: (state) => {
		state[filesUploadStateName] = initialFilesUploadData;
	},
	updateFileState: (state, { payload }) => {
		const { name, values } = payload;
		const previous = state[filesUploadStateName].files[name];

		state[filesUploadStateName].files[name] = { ...previous, ...values };
	},
	updateFilesVerificationState: (state, { payload }) => {
		for (let p in payload) {
			state[filesUploadStateName].files[p].verified = payload[p];
		}
	},
	setValidState: (state, { payload }) => {
		state[filesUploadStateName].valid = payload;
	},
	setVerifiedState: (state, { payload }) => {
		state[filesUploadStateName].verified = payload;
	}
};
