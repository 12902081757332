const CustomerType = {
	Owner: {
		value: 0,
		name: 'Owner'
	},
	Resident: {
		value: 1,
		name: 'Resident'
	}
};

export default CustomerType;
