import React, { useEffect } from 'react';
import { ControlledTabsBase, useSidebar } from '@sonar-web/common';
import LocationsSelectSelected from './Selected/LocationsSelectSelected';
import LocationsSelectAll from './All/LocationsSelectAll';
import { useLocale } from '@sonar-web/common/src/hooks';

const LocationsSelect = ({
	readonly,
	selectedProps,
	allProps,
	objectId,
	isDashboard,
	asSidebar = false,
	additionalTabs = []
}) => {
	const { translate } = useLocale();
	const { currentDefaultTab, changeDefaultSidebarTab } = useSidebar();
	const [tabValue, setTabValue] = React.useState(asSidebar ? currentDefaultTab : 0);

	const tabs = [
		{
			Id: 0,
			label: `${translate(selectedProps.tabTitle)}`,
			name: 'locationsSelectSelected',
			content: (
				<LocationsSelectSelected
					objectId={objectId}
					isDashboard={isDashboard}
					{...selectedProps}
					readonly={readonly}
				/>
			)
		}
	];

	if (!readonly)
		tabs.push(
			{
				Id: 1,
				label: `${translate(allProps.tabTitle)}`,
				name: 'locationsSelectAll',
				content: <LocationsSelectAll objectId={objectId} isDashboard={isDashboard} {...allProps} />
			},
			...additionalTabs
		);

	const handleTabChange = (_, newValue) => {
		if (asSidebar) changeDefaultSidebarTab(newValue);
		else setTabValue(newValue);
	};

	useEffect(() => {
		if (!asSidebar) return;
		setTabValue(currentDefaultTab);
	}, [currentDefaultTab]);

	return (
		<ControlledTabsBase
			tabs={tabs}
			tabValue={tabValue}
			setTabValue={setTabValue}
			handleTabChange={handleTabChange}
		/>
	);
};

export default LocationsSelect;
