import { createSlice } from '@reduxjs/toolkit';
import { fetchMetadatas } from './watermeterQProfilesApi';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';

const slice = 'watermeterQProfiles';

export const watermeterQProfilesSlice = createSlice({
	name: slice,
	initialState: {
		metadatasFetchPending: false,
		metadatasFetchSuccess: false,
		qprofile: null,
		qprofileThresholds: null,
		watermeterEventsThresholds: null
	},
	reducers: {
		fetchMetadatasSucces: (state, action) => {
			const elements = action.payload;

			const qprofile = elements.find((e) => e.metadataName === 'RegQProfile');

			if (qprofile?.currentValue?.json) {
				qprofile.currentValue.json = JSON.parse(qprofile.currentValue.json);
			}

			const qprofileThresholds = elements.find((e) => e.metadataName === 'RegQProfileThresholds');

			if (qprofileThresholds?.currentValue?.json) {
				qprofileThresholds.currentValue.json = JSON.parse(qprofileThresholds.currentValue.json);
			}

			const watermeterEventsThresholds = elements.find((e) => e.metadataName === 'RegWatermeterEventsThresholds');

			if (watermeterEventsThresholds?.currentValue?.json) {
				watermeterEventsThresholds.currentValue.json = JSON.parse(watermeterEventsThresholds.currentValue.json);
			}

			state.qprofile = qprofile;
			state.qprofileThresholds = qprofileThresholds;
			state.watermeterEventsThresholds = watermeterEventsThresholds;
			state.metadatasFetchPending = false;
			state.metadatasFetchSuccess = true;
		},
		fetchMetadatasPending: (state, action) => {
			state.metadatasFetchPending = action.payload;
		},
		resetData: (state) => {
			state.metadatasFetchPending = false;
			state.metadatasFetchSuccess = false;
			state.qprofile = null;
			state.qprofileThresholds = null;
			state.watermeterEventsThresholds = null;
		}
	}
});

export const { fetchMetadatasSucces, fetchMetadatasPending, resetData } = watermeterQProfilesSlice.actions;

export const fetchWatermeterMetadatasAsync = (deviceId) => async (dispatch) => {
	try {
		dispatch(fetchMetadatasPending(true));
		const response = await fetchMetadatas(deviceId);

		dispatch(fetchMetadatasSucces(response));
	} catch (error) {
		dispatch(fetchMetadatasPending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const selectWatermeterMetadatas = (state) => {
	return {
		qprofile: state.watermeterQProfiles.qprofile,
		qprofileThresholds: state.watermeterQProfiles.qprofileThresholds,
		watermeterEventsThresholds: state.watermeterQProfiles.watermeterEventsThresholds,
		pending: state.watermeterQProfiles.metadatasFetchPending,
		success: state.watermeterQProfiles.metadatasFetchSuccess
	};
};

export default watermeterQProfilesSlice.reducer;
