import { useState, useEffect } from 'react';
import { FormControl, Select, InputLabel, IconButton, InputAdornment } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import { useLocale, useIsMount } from '../../hooks';
import { filterOperators } from '../../constants';
import BaseSelectMenuItem from '../BaseSelectMenuItem/BaseSelectMenuItem';

const useStyles = makeStyles((theme) => ({
	clearButton: {
		position: 'absolute',
		right: theme.spacing(3)
	},
	adornmentIcon: {
		padding: '2px',
		marginRight: '-2px'
	}
}));

const FiltersControlComboSingle = ({
	member,
	label,
	operator,
	stateSlice,
	options,
	onChange,
	onClearMemberAll,
	defaultValue,
	disabled = false
}) => {
	const classes = useStyles();
	const isMount = useIsMount();
	const { translate } = useLocale();
	const [value, setValue] = useState(defaultValue ?? '');

	const handleChange = (event) => setValue(event.target.value);

	const handlerClearValue = () => setValue('');

	const handleClearFilter = () => onClearMemberAll(member.name);

	const handleSetFilter = () => {
		const option = options[value];
		const asArray = operator === filterOperators.collectionContain;

		onChange(
			[
				{
					member,
					value: asArray ? [option.value] : option.value,
					operator: operator ?? filterOperators.equal
				}
			],
			member.name
		);
	};

	useEffect(() => {
		if (isMount) return;
		if (value === '') {
			handleClearFilter();
			return;
		}
		handleSetFilter();
	}, [value]);

	useEffect(() => {
		if (stateSlice == null || (stateSlice && Object.keys(stateSlice).length === 0) || !stateSlice[member.name]) {
			handlerClearValue();
		}
	}, [stateSlice]);

	return (
		<FormControl fullWidth disabled={disabled}>
			<InputLabel>{translate(label)}</InputLabel>
			<Select
				value={value}
				onChange={handleChange}
				endAdornment={
					value !== '' ? (
						<InputAdornment position='end' classes={{ root: classes.clearButton }}>
							<IconButton
								size='small'
								className={classes.adornmentIcon}
								aria-label='filte_text_clear'
								onClick={handlerClearValue}>
								<CloseIcon fontSize='small' />
							</IconButton>
						</InputAdornment>
					) : null
				}>
				{options.map((opt, index) => {
					return (
						<BaseSelectMenuItem key={index} value={index}>
							{translate(opt.text)}
						</BaseSelectMenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
};

export default FiltersControlComboSingle;
