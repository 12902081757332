import { useEffect, useState } from 'react';
import { useRequest } from '@sonar-web/common/src/hooks';
import TenantsService from '../TenantsService';
import useCurrentTenant from '../Hooks/useCurrentTenant';
import Slices from '../Constants/Slices';
import Events from '../Constants/Events';
import { useDispatch } from 'react-redux';
import { fetchLicensesSuccess } from '../licensesSlice';

const slice = Slices.tenant;

const FetchLicensesData = ({ children }) => {
	const dispatch = useDispatch();
	const { getCurrentTenant } = useCurrentTenant();
	const tenant = getCurrentTenant();

	const [refresh, setRefresh] = useState(0);

	const { request, data, error, pending } = useRequest({
		api: () => TenantsService.fetchTenantLicense(tenant.id),
		slice,
		callback: (res) => dispatch(fetchLicensesSuccess(res))
	});

	useEffect(() => {
		request();
	}, [refresh]);

	useEffect(() => {
		const handleRefreshRequest = () => setRefresh((prev) => prev + 1);
		const event = Events.LicenseRefreshRequested;

		document.addEventListener(event, handleRefreshRequest);

		return () => {
			document.removeEventListener(event, handleRefreshRequest);
		};
	}, []);

	return children({ pending, data, error });
};

export default FetchLicensesData;
