import { lazy } from 'react';
import { Features } from '@sonar/auth';

const DevicePage = lazy(() => import('./Device/DevicePage'));

export default [
	{
		element: DevicePage,
		feature: Features.DevicePage.name,
		path: `wmbusdevices/:id`,
		exact: true,
		name: '{0}'
	}
];
