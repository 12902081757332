import { apiBase, REST_API_PATHS } from '@sonar-web/common';

const path = REST_API_PATHS.IMPORTS;

//uruchomienie importu po konwersji danych pomiarowych
async function acceptConversion(id) {
	const { data } = await apiBase(`imports/${id}/continue`, 'post', {
		path,
		data: { id }
	});

	return data;
}

//anulowanie importu po konwersji danych pomiarowych
async function abortConversion(id) {
	const { data } = await apiBase(`imports/${id}/abort`, 'post', {
		path,
		data: { id }
	});

	return data;
}

//upload nowego pliku z danymi zestawów do pełnego importu
async function uploadMeasurementSetsFile({ id, file, type }) {
	const formData = new FormData();

	formData.append('formFile', file);
	formData.append('fileName', file.name);
	formData.append('importType', type);
	formData.append('id', id);

	const { data } = await apiBase(`imports/${id}/measurementSetsFile`, 'post', {
		path,
		data: formData
	});

	return data;
}

//dodanie importu
async function addImport(importData, config) {
	const { file, type } = importData;
	const formData = new FormData();

	formData.append('formFile', file);
	formData.append('fileName', file.name);
	formData.append('importType', type);

	const response = await apiBase('imports', 'post', {
		path,
		data: formData,
		...config,
		headers: {
			'content-type': 'multipart/form-data'
		}
	});

	const locationHeader = response.headers.location;

	if (locationHeader)
		return {
			url: locationHeader,
			id: locationHeader.slice(locationHeader.indexOf('/') + 1)
		};

	return response.data;
}

//pobranie przykładowego pliku importu AES
async function fetchAESTemplateFile(type, locale) {
	const { data } = await apiBase(`imports/${type.toLowerCase()}/sample/${locale}`, 'get', { path });

	return data;
}

//pobranie listy importów
async function fetchImports(pageDescriptor) {
	const { data } = await apiBase(`imports`, 'get', {
		path,
		params: { pageDescriptor }
	});

	return data;
}

//pobranie listy importów dla importType = xxxFull
async function fetchFullImports(pageDescriptor) {
	const { data } = await apiBase(`fullImports`, 'get', {
		path,
		params: { pageDescriptor }
	});

	return data;
}

//pobranie listy szablonów
async function fetchTemplates(templateType, importType) {
	const { data } = await apiBase(`imports/template`, 'get', { path, params: { templateType, importType } });

	return data;
}

//pobranie danych szablonu
async function fetchTemplate(id) {
	const { data } = await apiBase(`imports/template/${id}`, 'get', { path });

	return data;
}

//dodanie szablonu
async function addTemplate(template) {
	const response = await apiBase('imports/template', 'post', {
		path,
		data: template
	});

	const locationHeader = response.headers.location;

	if (locationHeader)
		return {
			url: locationHeader,
			id: locationHeader.slice(locationHeader.lastIndexOf('/') + 1)
		};

	return response;
}

//edycja szablonu
async function editTemplate(template) {
	const { data } = await apiBase(`imports/template/${template.id}`, 'put', {
		path,
		data: template
	});

	return data;
}

//usunięcie szablonu
async function deleteTemplate(id) {
	const { data } = await apiBase(`imports/template/${id}`, 'delete', { path });

	return data;
}

//pobranie przykładowych danych z importowanego pliku
async function fetchFileColumns(importId, formatProperties) {
	const { data } = await apiBase(`imports/${importId}/decode`, 'get', { path, params: { ...formatProperties } });

	return data;
}

//rozpoczęcie procesu importu
async function startImport(template) {
	const { data } = await apiBase(`imports/start`, 'post', { path, data: { ...template } });

	return data;
}

const ImportService = {
	acceptConversion,
	abortConversion,
	uploadMeasurementSetsFile,
	addImport,
	fetchAESTemplateFile,
	fetchImports,
	fetchFullImports,
	fetchTemplates,
	fetchTemplate,
	addTemplate,
	editTemplate,
	deleteTemplate,
	fetchFileColumns,
	startImport
};

export default ImportService;
