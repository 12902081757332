import { apiBase } from '@sonar-web/common';
import { REST_API_PATHS } from '@sonar-web/common/src/constants/system';

const path = REST_API_PATHS.BILLING;

export async function fetchBilling(id) {
	const { data } = await apiBase(`billings/settlement/${id}/getdetails`, 'get', {
		path
	});

	return data;
}

export async function addBilling(billing) {
	const response = await apiBase(`billings/settlement`, 'post', {
		path,
		data: billing
	});

	const billingHeader = response.headers.location;

	if (billingHeader)
		return {
			url: billingHeader,
			id: billingHeader.slice(billingHeader.lastIndexOf('/') + 1)
		};

	return response;
}

export async function addBillingWithTemplate(billing) {
	const response = await apiBase(`billings/settlement/addWithTemplate`, 'post', {
		path,
		data: billing
	});

	const billingHeader = response.headers.location;

	if (billingHeader)
		return {
			url: billingHeader,
			id: billingHeader.slice(billingHeader.lastIndexOf('/') + 1)
		};

	return response;
}

export async function editBilling(billing, id) {
	const { data } = await apiBase(`billings/settlement/update/${id}`, 'put', {
		path,
		data: billing
	});

	return data;
}

export async function editBillingInputFiles(billing) {
	const { data } = await apiBase(`billings/settlement/updatefiles`, 'put', {
		path,
		data: billing
	});

	return data;
}

export async function deleteBilling(id) {
	const { data } = await apiBase(`billings/settlement/${id}`, 'delete', {
		path
	});

	return data;
}

//Pobranie progressu generowana indywidualek
export async function fetchSettlementProgress(settlementId) {
	const { data } = await apiBase(`api/settlement/getprogress/${settlementId}`, 'get', {
		path
	});

	return data;
}
