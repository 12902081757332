import React from 'react';
import clsx from 'clsx';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useLocale from '../../hooks/useLocale';
import { BaseTooltip } from '..';

const useStyles = makeStyles((theme) => ({
	root: ({ highlight, tooltip }) => ({
		display: 'flex',
		alignItems: 'center',
		color: theme.palette.text.secondary,
		'& > span': {
			color: `${highlight ? theme.palette.info.main : theme.palette.text.primary} !important`,
			fontWeight: `${theme.typography.fontWeightMedium} !important`
		},
		borderBottom: tooltip ? `1px dashed #CFCFCF` : 'none'
	}),
	disableFlex: {
		flex: 0,
		alignSelf: 'flex-start'
	}
}));

const LabelValueItem = ({
	label,
	value,
	tooltip,
	tooltipProps = {},
	highlight = false,
	disableFlex = false,
	className = '',
	...rest
}) => {
	const classes = useStyles({ highlight, tooltip });
	const { translate } = useLocale();

	if (value === null || value === '') return null;

	return (
		<BaseTooltip text={tooltip} hide={!tooltip} {...tooltipProps}>
			<Typography
				variant='caption'
				classes={{
					root: classes.root
				}}
				className={clsx(className, { [classes.disableFlex]: disableFlex })}
				{...rest}>
				{translate(label)}
				{'\u00a0'}
				<span>{value}</span>
			</Typography>
		</BaseTooltip>
	);
};

export default LabelValueItem;
