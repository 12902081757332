const OverlaysGroupTypes = {
	WaterMeterTransmissionDevices: {
		name: 'WaterMeterTransmissionDevices',
		value: 2
	},
	GasMeterTransmissionDevices: {
		name: 'GasMeterTransmissionDevices',
		value: 4
	}
};

export default OverlaysGroupTypes;
