import { useEffect } from 'react';
import { Toolbar, Box, Stack } from '@mui/material';
import { Spacer } from '@sonar-web/common';
import ListFiltersToggle from '../Filters/ListFiltersToggle';
import { useSidebar } from '../../features';
import useFilters from '../Filters/useFilters';
import Actions from './Actions';
import TotalCount from './TotalCount';
import Sorting from './Sorting';
import MultiSelect from './MultiSelect';
import { ListFiltersContextProvider } from '../Filters/listFiltersContext';

const ListActionToolbar = ({
	slice,
	pending,
	actions,
	sorters,
	filters,
	filtersProps = {},
	totalCount,
	multipleSelect,
	multipleSelectParams,
	toggleMultipleSelectionModeEnabled = true,
	sidebar,
	forceSidebar = false,
	filtersIgnored,
	forceNoData,
	children
}) => {
	const { isSidebarActive } = useSidebar();
	const { hasCurrentFilters, clearAllFilters } = useFilters(slice);

	const isSidebar = forceSidebar ? true : isSidebarActive(sidebar);
	const noData = forceNoData != null ? forceNoData : totalCount === 0 && !pending && !hasCurrentFilters();

	useEffect(() => {
		return () => {
			clearAllFilters();
		};
	}, []);

	return (
		<Box>
			<Toolbar disableGutters variant='dense' data-at='ListActionToolbar'>
				<Actions actions={actions} noData={noData} />
				{children && (
					<Stack direction='row' gap='.5rem'>
						{children}
					</Stack>
				)}
				<Spacer />
				{!isSidebar && (
					<TotalCount isSidebar={isSidebar} totalCount={totalCount} sorters={sorters} noData={noData} />
				)}
				<Sorting sorters={sorters} slice={slice} filters={filters} noData={noData} />
				<ListFiltersContextProvider>
					<ListFiltersToggle
						slice={slice}
						filtersIgnored={filtersIgnored}
						noData={noData}
						Filters={filters}
						filtersProps={filtersProps}
					/>
				</ListFiltersContextProvider>
				{!isSidebar && (
					<MultiSelect
						isSidebar={isSidebar}
						sidebar={sidebar}
						slice={slice}
						multipleSelect={multipleSelect}
						multipleSelectParams={multipleSelectParams}
						toggleMultipleSelectionModeEnabled={toggleMultipleSelectionModeEnabled}
						noData={noData}
					/>
				)}
			</Toolbar>
			{isSidebar && (
				<Box pt={1} display='flex' alignItems='center'>
					<MultiSelect
						isSidebar={isSidebar}
						sidebar={sidebar}
						slice={slice}
						multipleSelect={multipleSelect}
						multipleSelectParams={multipleSelectParams}
						toggleMultipleSelectionModeEnabled={toggleMultipleSelectionModeEnabled}
						noData={noData}
					/>
					<TotalCount isSidebar={isSidebar} totalCount={totalCount} sorters={sorters} noData={noData} />
				</Box>
			)}
		</Box>
	);
};

export default ListActionToolbar;
