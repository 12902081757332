import { createSlice } from '@reduxjs/toolkit';
import { pageDescriptor, getSliceFilters } from '@sonar-web/common';
import { fetchWatermeterTypes } from './api';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';

const slice = 'watermeterTypesSearch';

export const watermeterTypesSearchSlice = createSlice({
	name: slice,
	initialState: {
		pageDescriptor,
		fetchPending: false,
		watermeterTypes: [],
		totalCount: 0,
		hasMore: true,
		reload: false,
		reset: true
	},
	reducers: {
		fetchWatermeterTypesSuccess: (state, action) => {
			let fetched = [...action.payload.elements];

			state.watermeterTypes = state.reset ? fetched : state.watermeterTypes.concat(fetched);
			state.totalCount = action.payload.totalCount;
			state.fetchPending = false;
		},
		fetchWatermeterTypesPending: (state) => {
			state.fetchPending = true;
		},
		setHasMore: (state) => {
			state.hasMore = state.totalCount > state.pageDescriptor.Offset + state.pageDescriptor.Limit;
		},
		setResetAndReload: (state) => {
			state.watermeterTypes = [];
			state.reload = true;
			state.reset = true;
			state.hasMore = true;
			state.pageDescriptor.Offset = 0;
			state.pageDescriptor.FilterDescriptors = [];
			state.pageDescriptor.SortDescriptors = [];
		},
		setResetToFirstPage: (state) => {
			state.watermeterTypes = [];
			state.reload = true;
			state.reset = true;
			state.hasMore = true;
			state.pageDescriptor.Offset = 0;
		},
		setAppend: (state, action) => {
			state.reset = !action.payload;
			if (action.payload) state.pageDescriptor.Offset += state.pageDescriptor.Limit;
			else state.pageDescriptor.Offset = 0;
		},
		setReload: (state) => {
			state.reload = true;
		}
	}
});

export const {
	fetchWatermeterTypesSuccess,
	fetchWatermeterTypesPending,
	setHasMore,
	setResetAndReload,
	setResetToFirstPage,
	setAppend,
	setReload
} = watermeterTypesSearchSlice.actions;

export const fetchWatermeterTypesAsync = () => async (dispatch, getState) => {
	let response;

	try {
		dispatch(fetchWatermeterTypesPending());

		let pd = Object.assign({}, getState().watermeterTypesSearch.pageDescriptor);

		pd.FilterDescriptors = getSliceFilters(getState(), slice);
		pd.SortDescriptors = [{ Member: 'nameToSort', ListSortDirection: 1 }];

		response = await fetchWatermeterTypes(pd);
	} catch (error) {
		dispatch(addErrorAsync({ slice, error }));
		return;
	}

	dispatch(fetchWatermeterTypesSuccess(response));
	dispatch(setHasMore());
};

export const selectWatermeterTypes = (state) => {
	return {
		dataRows: state.watermeterTypesSearch.watermeterTypes,
		pending: state.watermeterTypesSearch.fetchPending,
		hasMore: state.watermeterTypesSearch.hasMore,
		reload: state.watermeterTypesSearch.reload,
		totalCount: state.watermeterTypesSearch.totalCount
	};
};

export default watermeterTypesSearchSlice.reducer;
