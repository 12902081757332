import { useDispatch, useSelector } from 'react-redux';
import DOMPurify from 'dompurify';
import LocalStorageHelper from '@sonar-web/common/src/helpers/LocalStorageHelper';
import { selectCurrentTenant, setTenant } from '../Tenants/tenantSlice';

export default () => {
	const dispatch = useDispatch();
	const currentTenant = useSelector(selectCurrentTenant);

	const storeTenant = (tenant) => {
		dispatch(setTenant(tenant));
		LocalStorageHelper.setValue('spidap_tenant', JSON.stringify({ name: tenant.name, id: tenant.id }));
	};

	const getStoredTenant = () => {
		const tenant = LocalStorageHelper.getValue('spidap_tenant');

		if (tenant == null) return null;

		try {
			return JSON.parse(DOMPurify.sanitize(tenant));
		} catch (e) {
			return null;
		}
	};

	const removeCurrentTenant = () => {
		dispatch(setTenant(null));
		LocalStorageHelper.removeValue('spidap_tenant');
	};

	const getCurrentTenant = () => currentTenant;

	return {
		storeTenant,
		getStoredTenant,
		getCurrentTenant,
		removeCurrentTenant
	};
};
