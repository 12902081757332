import styled from '@emotion/styled';
import { Toolbar, Typography } from '@mui/material';
import { useLocale } from '../../hooks';
import { Spacer } from '..';
import DashboardTitleSkeleton from './DashboardTitleSkeleton';
import { guidEmpty } from '../../helpers';
import LinkRouter from '../Router/LinkRouter';

const StyledToolbar = styled(Toolbar)({
	paddingBottom: '1rem'
});

const DashboardTitle = ({
	title,
	hideTitle = false,
	pending,
	data,
	primaryData,
	secondaryData,
	actions,
	handleDashboard,
	pageName,
	AdditionalActions
}) => {
	const { translate } = useLocale();

	if (pending || !data || data?.id === guidEmpty) return <DashboardTitleSkeleton />;

	const primary = data[primaryData];
	const secondary = data[secondaryData];

	return (
		<StyledToolbar disableGutters variant='dense' data-pagename={pageName}>
			<div>
				{title && (
					<Typography
						variant='body2'
						color='textSecondary'
						data-at-params={`Dashboard-title|${title}`}
						style={{ display: hideTitle ? 'none' : 'block' }}>
						{translate(title)}
					</Typography>
				)}
				<div data-at='dashboard-record'>
					{handleDashboard ? (
						<LinkRouter to={handleDashboard} variant='h5' data-primary={primaryData}>
							{primary}
						</LinkRouter>
					) : (
						<Typography variant='h5' component='span' data-primary={primaryData}>
							{primary}
						</Typography>
					)}{' '}
					{secondaryData && (
						<Typography variant='h5' component='span' color='textSecondary' data-secondary={secondaryData}>
							{`(${secondary})`}
						</Typography>
					)}
				</div>
			</div>
			{AdditionalActions}
			<Spacer />
			{actions}
		</StyledToolbar>
	);
};

export default DashboardTitle;
