import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import GenerateScheduleOption, { getByValue } from './GenerateScheduleOption';
import { useIsMount } from '@sonar-web/common/src/hooks';
import { parseCronStringEntries } from '@sonar-web/common/src/features/Scheduler/cronParser';
import Schedule from '@sonar-web/common/src/features/Scheduler/Schedule/Schedule';
import { resetSchedule, setIsValid, selectSchedule } from '@sonar-web/common/src/features/Scheduler/scheduleSlice';
import EveryDayIcon from '@sonar-web/common/src/icons/EveryDayIcon';
import EveryMonthIcon from '@sonar-web/common/src/features/Scheduler/Schedule/Icons/EveryMonthIcon';
import TileButtonSelectable from '@sonar-web/common/src/features/Tiles/TileButtonSelectable';

const Options = styled('div')({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	gap: '1rem',
	'& .tileButtonSelectableWrapper': {
		flex: 1
	},
	'& + .scheduleForm': {
		marginTop: '2.5rem'
	}
});

const ReportScheduler = ({ stepIndex, useConfigurator, configuratorData, updateConfiguratorData, property }) => {
	const dispatch = useDispatch();
	const isMount = useIsMount();
	const { updateStepValid, activeStep, isEditing } = useConfigurator();

	const scheduleData = useSelector(selectSchedule);

	const generateNow = configuratorData['generateNow'];
	const cronTime = configuratorData[property];
	const scheduleOption = getByValue(generateNow);

	const initialData = useMemo(() => {
		if (!cronTime || cronTime?.length === 0) return null;

		const entries = cronTime.reduce((acc, v) => {
			acc.push({ cronTime: v });
			return acc;
		}, []);

		return {
			entries: entries,
			parsed: parseCronStringEntries(entries)
		};
	}, [cronTime]);

	const handleChange = (v) => updateConfiguratorData({ ['generateNow']: v });

	useEffect(() => {
		updateStepValid(stepIndex, generateNow ? true : cronTime?.length > 0);

		if (generateNow === true) {
			if (scheduleData.isValid === true) dispatch(setIsValid(false));
			if (scheduleData.schedule?.entries?.length > 0) dispatch(resetSchedule());
		}
	}, [cronTime, generateNow]);

	useEffect(() => {
		if (isMount && isEditing) {
			updateConfiguratorData({ [property]: cronTime }, false);
			return;
		}

		if (scheduleData.isValid && !scheduleData.schedule.entries) return;

		const newCronValue = scheduleData.isValid ? scheduleData.schedule.entries.map((e) => e.cronTime) : null;

		updateConfiguratorData({ [property]: newCronValue }, false);
	}, [scheduleData.isValid, scheduleData.schedule.entries]);

	useEffect(() => {
		return () => {
			dispatch(resetSchedule());
		};
	}, []);

	return (
		<>
			<Options data-at='reportScheduler'>
				<TileButtonSelectable
					Icon={EveryMonthIcon}
					title='OnlyOnce'
					subtitle='Reports_RightAfterReportAdded'
					selected={scheduleOption === GenerateScheduleOption.Now.value}
					onClick={() => handleChange(true)}
					radio={true}
					data-at='onlyNow'
				/>
				<TileButtonSelectable
					Icon={EveryDayIcon}
					title='ScheduleStart'
					subtitle='AtScheduledTimes'
					selected={scheduleOption === GenerateScheduleOption.BySchedule.value}
					onClick={() => handleChange(false)}
					radio={true}
					data-at='scheduleGenerate'
				/>
			</Options>
			{generateNow === false && stepIndex === activeStep && (
				<Schedule data={initialData} entryData={{}} showToolbar={false} enableEveryHour={false} />
			)}
		</>
	);
};

export default ReportScheduler;
