const SortDirection = {
	Asc: {
		value: 1,
		name: 'Asc'
	},
	Desc: {
		value: 2,
		name: 'Desc'
	}
};

export default SortDirection;
