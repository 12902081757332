import { useEffect, useState } from 'react';

export default (value) => {
	const [avatarLetters, setAvatarLetters] = useState(value);

	useEffect(() => {
		if (!value) return;

		const valueArray = value.split(' ');
		let first = '';
		let second = '';

		if (valueArray.length >= 2) {
			first = valueArray[0].charAt(0).toUpperCase();
			second = valueArray[1].charAt(0).toUpperCase();
		} else {
			first = valueArray[0].charAt(0).toUpperCase();
			second = valueArray[0].charAt(1).toUpperCase();
		}

		setAvatarLetters(`${first}${second}`);
	}, [value]);

	return avatarLetters;
};
