import { initialViewsState, views } from './constants';

export const billingConfiguratorStateName = 'billingConfigurator';

export const initialBillingConfigurator = {
	activeView: views.basic,
	locationChanged: false,
	isValid: initialViewsState
};

export const billingConfiguratorReducers = {
	changeActiveView: (state, { payload }) => {
		state[billingConfiguratorStateName].activeView = payload;
	},
	resetBillingConfigurator: (state) => {
		state[billingConfiguratorStateName] = initialBillingConfigurator;
	},
	updateIsValid: (state, { payload }) => {
		if (state[billingConfiguratorStateName].isValid[Object.keys(payload)[0]] !== Object.values(payload)[0]) {
			state[billingConfiguratorStateName].isValid = {
				...state[billingConfiguratorStateName].isValid,
				...payload
			};
		}
	}
};
