import moment from 'moment';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { InterfaceType } from '@sonar-web/common';
import { DateRangeOptions } from '@sonar-web/common/src/features/DateRange/DateRangeOptions';
import { slices } from '../Constants/Module';
import { ReadFilterType } from '../Enums/ReadFilterType';

const slice = slices.statistics;

const initialState = {
	dateRangeOption: DateRangeOptions.Days7.value,
	dateRangeDate: getDateByDateRangeOption(DateRangeOptions.Days7.value),
	layer: InterfaceType.Everything.value,
	locationsReadState: ReadFilterType.None.name,
	mapCenter: null,
	mapZoom: 7
};

export const statisticsSlice = createSlice({
	name: slice,
	initialState,
	reducers: {
		resetStatistics: (state) => {
			state.dateRangeOption = initialState.dateRangeOption;
			state.dateRangeDate = initialState.dateRangeDate;
			state.layer = initialState.layer;
		},
		setDateRange: (state, { payload }) => {
			const { option, date } = payload;

			state.dateRangeOption = option;
			state.dateRangeDate = date ?? getDateByDateRangeOption(option);
		},
		setLayer: (state, { payload }) => {
			state.layer = payload;
		},
		setLocationsReadState: (state, { payload }) => {
			state.locationsReadState = payload;
		},
		setMapCenter: (state, { payload }) => {
			state.mapCenter = payload;
		},
		setMapZoom: (state, { payload }) => {
			state.mapZoom = payload;
		}
	}
});

export const { resetStatistics, setDateRange, setLayer, setLocationsReadState, setMapCenter, setMapZoom } =
	statisticsSlice.actions;

//Selectors
export const selectLayer = (state) => state.statistics.layer;

export const selectLocationsReadState = (state) => state.statistics.locationsReadState;

export const selectRange = createSelector(
	(state) => state.statistics,
	(statistics) => ({
		dateRangeOption: statistics.dateRangeOption,
		dateRangeDate: statistics.dateRangeDate
	})
);

export const selectMapSettings = createSelector(
	(state) => state.statistics,
	(statistics) => ({
		mapCenter: statistics.mapCenter,
		mapZoom: statistics.mapZoom
	})
);

export default statisticsSlice.reducer;

function getDateByDateRangeOption(dateRangeOption) {
	return moment().subtract(dateRangeOption, 'days').set('seconds', 0).set('milliseconds', 0).toISOString();
}
