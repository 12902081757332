export const sampleDate = new Date(2020, 4, 17, 16, 2);

export const yearFormats = {
	full: 'full',
	short: 'short'
};

export const dayMonthFormats = {
	single: 'single',
	double: 'double'
};

export const minuteHourFormats = {
	single: 'single',
	double: 'double'
};

export const dateUnitSeparators = {
	dot: '.',
	dash: '-',
	slash: '/'
};

export const dateUnitOrders = {
	dmy: 'dmy',
	dym: 'dym',
	mdy: 'mdy',
	myd: 'myd',
	ydm: 'ydm',
	ymd: 'ymd'
};

export const timeUnitSeparators = {
	colon: ':',
	dot: '.',
	dash: '-',
	slash: '/'
};

export const timeFormats = {
	time24h: '24h',
	time12h: '12h'
};
