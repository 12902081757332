import React from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useLocale from '../../hooks/useLocale';

const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.palette.text.secondary,
		'& > span': {
			color: theme.palette.text.primary
		}
	}
}));

const LabelValueUnitItem = ({ label, value, unit }) => {
	const classes = useStyles();
	const { translate } = useLocale();

	if (value === null || value === '') return null;

	return (
		<Typography
			variant='caption'
			classes={{
				root: classes.root
			}}>
			{translate(label)}
			{':'} <span>{value}</span> {translate(unit)}
		</Typography>
	);
};

export default LabelValueUnitItem;
