import { useEffect, useState } from 'react';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { jwtDecode } from 'jwt-decode';
import AuthService from '@sonar/auth/src/AuthService';
import { BaseLinearProgress } from '@sonar-web/common';

const App = ({ children }) => {
	const auth = useAuth();
	const [hasTriedSignin, setHasTriedSignin] = useState(false);

	useEffect(() => {
		auth.clearStaleState().catch((error) => {
			console.error('clearStaleState error:', error);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!(hasAuthParams() || auth.isAuthenticated || auth.activeNavigator || auth.isLoading || hasTriedSignin)) {
			auth.signinRedirect();
			setHasTriedSignin(true);
		}
	}, [auth, hasTriedSignin]);

	useEffect(() => {
		if (!auth.isAuthenticated || !auth.user) return;
		const token = auth.user?.access_token;
		AuthService.setToken(token);

		if (token) {
			const decodedToken = jwtDecode(token);
			AuthService.setTokenParsed(decodedToken);

			const roles = decodedToken.realm_access?.roles || [];
			AuthService.setRoles(roles);
		}

		AuthService.setRefreshToken(auth.user?.refresh_token);
		AuthService.setSessionState(auth.user?.session_state);
		AuthService.setGroups(distinctGroupsWithoutRoles(auth.user?.profile?.groups));
	}, [auth.isAuthenticated, auth.user]);

	useEffect(() => {
		if (!auth.error) return;
		console.error('LoginError:', auth.error);
		auth.signinRedirect();
	}, [auth.error]);

	if (auth.isLoading) return <BaseLinearProgress pending={true} />;

	if (auth.isAuthenticated) return children;

	return null;
};

export default App;

const distinctGroupsWithoutRoles = (groups) => {
	if (!groups) return [];
	const keycloakRoles = ['offline_access', 'uma_authorization', 'default-roles-sonar-web'];
	const withoutKeycloakRoles = groups.filter((group) => !keycloakRoles.includes(group));
	const withoutRoles = withoutKeycloakRoles.map((wkc) => {
		const hasPipe = wkc.lastIndexOf('|');
		return hasPipe > -1 ? wkc.substring(0, hasPipe) : wkc;
	});

	return [...new Set(withoutRoles)];
};
