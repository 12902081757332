import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Menu, MenuItem, Toolbar } from '@mui/material';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import useConfigurator from './useConfigurator';
import { selectActiveTab, selectLastActiveTab, selectSectionsValidationStatus } from './deviceConfiguratorSlice';
import { BaseTooltip, Spacer } from '../../../components';
import ButtonText from '../../../components/Buttons/ButtonText';
import ButtonOnlyIconPrevious from '../../../components/Buttons/ButtonOnlyIconPrevious';
import BaseIconButton from '../../../components/Buttons/BaseIconButton';
import { useLocale } from '../../../hooks';
import BaseButton from '../../../components/Buttons/BaseButton';
import DeviceConfiguratorHeader from './DeviceConfiguratorHeader';
import useConfiguratorValidation from './useConfiguratorValidation';
import useConfiguratorParams from './useConfiguratorParams';

const DeviceConfiguratorControl = ({ CustomHeader, deviceData, headerData, onCancel, disableProfiles, readOnly }) => {
	const { translate } = useLocale();
	const {
		openCancelTab,
		openSubmitTab,
		isInEditMode,
		toggleProfileSubmitMode,
		getCurrentData,
		getProfileData,
		hasConfiguration,
		openDatasTab,
		isReadOnly
	} = useConfigurator();
	const { isCurrentConfigurationValid, isCurrentConfigurationModified } = useConfiguratorValidation();
	const { isCopy } = useConfiguratorParams();

	const activeTab = useSelector(selectActiveTab);
	const lastActiveTab = useSelector(selectLastActiveTab);
	const sectionsValidationStatus = useSelector(selectSectionsValidationStatus);
	const [anchorEl, setAnchorEl] = useState(null);

	const { isSystemProfile, name } = getCurrentData();
	const profileData = getProfileData();
	const isModified = isCurrentConfigurationModified();
	const isValid = isCurrentConfigurationValid();
	const canSubmit = activeTab === 1 && isValid;

	const canLeaveConfigurator =
		(!isInEditMode && activeTab === 1) ||
		(activeTab === 0 && !hasConfiguration) ||
		(activeTab === 0 && lastActiveTab === 0) ||
		(activeTab === 0 && sectionsValidationStatus && Object.keys(sectionsValidationStatus).length === 0);

	const canGoBackFromProfiles =
		isInEditMode &&
		activeTab === 0 &&
		lastActiveTab === 1 &&
		sectionsValidationStatus &&
		Object.keys(sectionsValidationStatus).length > 0;

	const handleProfilesMenu = (event) => {
		setAnchorEl(event ? event.currentTarget : null);
	};

	const handleSaveProfile = (newProfile) => {
		toggleProfileSubmitMode(newProfile ? 'new' : 'current');
		handleProfilesMenu(false);
	};

	const handleSubmit = () => openSubmitTab();

	const handleCancel = () => openCancelTab();

	if (activeTab === 2 || activeTab === 3) return null;

	const datasTabNavi = () => {
		if (activeTab !== 1) return null;

		if (!isModified && profileData === null) {
			if (isCopy) {
				return (
					<ButtonText name='deviceConfiguratorCancelConfiguratorWork' onClick={onCancel}>
						Common_Cancel
					</ButtonText>
				);
			}

			return (
				<BaseTooltip text='Common_Back'>
					<div>
						<ButtonOnlyIconPrevious name='deviceConfiguratorBack' disabled={false} onClick={onCancel} />
					</div>
				</BaseTooltip>
			);
		}
		return (
			<>
				<ButtonText name='deviceConfiguratorCancelConfiguratorWork' pending={false} onClick={handleCancel}>
					Common_Cancel
				</ButtonText>
				{!disableProfiles && (
					<>
						<BaseTooltip text='Common_SaveProfile'>
							<div>
								<BaseIconButton
									name='deviceConfiguratorSaveProfile'
									icon={SaveOutlinedIcon}
									color='primary'
									onClick={handleProfilesMenu}
								/>
							</div>
						</BaseTooltip>
						<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => handleProfilesMenu(false)}>
							<MenuItem data-at='SaveProfile_AsNew' onClick={() => handleSaveProfile(true)}>
								{translate('Common_SaveProfileAsNew')}
							</MenuItem>
							{profileData !== null && isSystemProfile === false && (
								<MenuItem data-at='SaveProfile_AsCurrent' onClick={() => handleSaveProfile(false)}>
									{translate('Common_SaveProfileAsCurrent')}
								</MenuItem>
							)}
						</Menu>
					</>
				)}
			</>
		);
	};

	return (
		<Toolbar variant='dense' disableGutters>
			{CustomHeader ? (
				<CustomHeader deviceData={deviceData} configurationName={name} readOnly={readOnly} />
			) : (
				<DeviceConfiguratorHeader deviceData={deviceData} headerData={headerData} />
			)}
			<Spacer />
			{datasTabNavi()}
			{canGoBackFromProfiles && (
				<BaseTooltip text='Common_Back'>
					<div>
						<ButtonOnlyIconPrevious name='deviceConfiguratorBack' disabled={false} onClick={openDatasTab} />
					</div>
				</BaseTooltip>
			)}
			{canLeaveConfigurator && (
				<ButtonText pending={false} onClick={onCancel}>
					Common_Cancel
				</ButtonText>
			)}
			{!isReadOnly && canSubmit && (
				<Box ml={1}>
					<BaseButton name='deviceConfiguratorSubmit' onClick={handleSubmit}>
						Common_Confirm
					</BaseButton>
				</Box>
			)}
		</Toolbar>
	);
};

export default DeviceConfiguratorControl;
