import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Toolbar, Typography } from '@mui/material';
import useLocale from '@sonar-web/common/src/hooks/useLocale';
import { BaseTooltip, Spacer } from '../../../components';
import ButtonOnlyIconSubmit from '../../../components/Buttons/ButtonOnlyIconSubmit';
import ButtonText from '../../../components/Buttons/ButtonText';
import { addScheduleAsync, updateScheduleAsync } from '../scheduleSlice';
import ScheduleContext from './scheduleContext';
import { resetSchedules, resetAndReloadSchedules } from '../schedulesSlice';
import { stringifyCronObject } from '../cronParser';

const ScheduleTopActionsToolbar = ({ data, onCancel, onAdd, onEdit }) => {
	const dispatch = useDispatch();
	const { translate } = useLocale();
	const { isValid, scheduleId, cronObjects } = useContext(ScheduleContext);

	const isInEditMode = data;

	const handleSubmit = () => {
		const submitFunction = isInEditMode ? updateScheduleAsync : addScheduleAsync;

		const cronTime = cronObjects.map((co) => stringifyCronObject(co));

		dispatch(submitFunction()).then((err) => {
			if (err) return;

			if (isInEditMode) {
				dispatch(resetAndReloadSchedules());
				onCancel();
				if (onEdit) onEdit(scheduleId, cronTime);
				return;
			}

			dispatch(resetSchedules());
			onAdd(scheduleId, cronTime);
			onCancel();
		});
	};

	return (
		<Toolbar variant='dense' disableGutters>
			<Typography variant='h5'>{translate('Common_Schedule')}</Typography>
			<Spacer />
			<ButtonText pending={false} onClick={onCancel}>
				Common_Cancel
			</ButtonText>
			<BaseTooltip text='Common_Confirm'>
				<Box ml={1}>
					<ButtonOnlyIconSubmit name='scheduleSubmit' onClick={handleSubmit} disabled={!isValid} />
				</Box>
			</BaseTooltip>
		</Toolbar>
	);
};

export default ScheduleTopActionsToolbar;
