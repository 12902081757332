import { createSlice } from '@reduxjs/toolkit';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import ReadType from '@sonar-web/common/src/enums/ReadType';
import ReadService from './ReadService';
import Module from '../Constants/Module';

const slice = Module.slices.heatMeterRead;
const getRead = (getState) => getState().heatMeterRead.read;

const initialReadData = {
	heatMeterId: null,
	coolingEnergy: '',
	heatEnergy: '',
	date: null,
	source: '',
	type: ReadType.Instant.name
};

export const heatMeterReadSlice = createSlice({
	name: slice,
	initialState: {
		addPending: false,
		fetchPending: false,
		fetchSuccess: false,
		deletePending: false,
		deleteSuccess: false,
		read: initialReadData
	},
	reducers: {
		fetchReadSucces: (state, { payload }) => {
			state.read = payload;
			state.fetchPending = false;
			state.fetchSuccess = true;
		},
		fetchReadPending: (state, { payload }) => {
			state.fetchPending = payload;
			state.fetchSuccess = false;
		},
		addReadPending: (state, { payload }) => {
			state.addPending = payload ?? true;
		},
		deleteReadSucces: (state) => {
			state.deletePending = false;
			state.deleteSuccess = true;
		},
		deleteReadPending: (state, { payload }) => {
			state.deletePending = payload ?? true;
		},
		setReadData: (state, { payload }) => {
			state.read = payload;
		},
		resetReadData: (state) => {
			state.read = initialReadData;
			state.deleteSuccess = false;
			state.fetchSuccess = false;
		}
	}
});

export const {
	fetchReadSucces,
	fetchReadPending,
	addReadPending,
	deleteReadSucces,
	deleteReadPending,
	setReadData,
	resetReadData
} = heatMeterReadSlice.actions;

export const fetchReadAsync = (id) => async (dispatch) => {
	try {
		dispatch(fetchReadPending(true));

		const response = await ReadService.fetchRead(id);

		dispatch(fetchReadSucces(response));
	} catch (error) {
		dispatch(fetchReadPending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const addReadAsync = () => async (dispatch, getState) => {
	try {
		const read = { ...getRead(getState) };

		dispatch(addReadPending(true));
		await ReadService.addRead({ reads: [read] });
		dispatch(addReadPending(false));
		return true;
	} catch (error) {
		dispatch(addReadPending(false));
		return await dispatch(addErrorAsync({ slice, error }));
	}
};

export const editReadAsync = () => async (dispatch, getState) => {
	try {
		dispatch(addReadPending(true));
		await ReadService.editRead(getRead(getState));
		dispatch(addReadPending(false));
		return true;
	} catch (error) {
		dispatch(addReadPending(false));
		return await dispatch(addErrorAsync({ slice, error }));
	}
};

export const deleteReadAsync = (id) => async (dispatch) => {
	try {
		dispatch(deleteReadPending(true));

		await ReadService.deleteRead(id);

		dispatch(deleteReadSucces());
	} catch (error) {
		dispatch(deleteReadPending(false));

		return await dispatch(addErrorAsync({ slice, error }));
	}
};

export const selectRead = (state) => {
	return {
		dataRow: state.heatMeterRead.read,
		fetchPending: state.heatMeterRead.fetchPending,
		fetchSuccess: state.heatMeterRead.fetchSuccess
	};
};

export const selectReadAddPending = (state) => state.heatMeterRead.addPending;

export const selectDeleteRead = (state) => {
	return {
		deletePending: state.heatMeterRead.deletePending,
		deleteSuccess: state.heatMeterRead.deleteSuccess
	};
};

export default heatMeterReadSlice.reducer;
