import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';
import './notification_styles.css';

const NotificationContainer = () => {
	return <ToastContainer />;
};

export default NotificationContainer;
