import Notification from '../components/Notification/Notification';

const useRequestSuccessWarning = () => {
	return { handleResponseWarning };
};

export default useRequestSuccessWarning;

export const handleResponseWarning = (response) => {
	if (response?.code === 'DataWarning') Notification.warning({ message: response.reason });
};
