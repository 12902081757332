import React, { useEffect, useState } from 'react';
import { BaseDateTimePicker } from '../../../../components';
import { dateAsIsoStringLocal, dateAsIsoString } from '@sonar-web/common/src/helpers/date';
import { FormControl } from '@mui/material';

const ConfiguratorDatetimefield = ({
	name,
	value,
	error,
	helperText,
	form,
	element,
	group,
	forceUpdateValidationSchema
}) => {
	const [selected, setSelected] = useState(null);
	const [isOpen, setIsOpen] = useState(false);

	const { dependsOn, defaultValue, unit } = element;
	const isUTC = unit === 'utc';
	const isDependent = dependsOn !== null;
	const dependencyValue = isDependent ? dependsOn.value === form.values[dependsOn.key] : false;

	const [dependencyVisibility, setDependencyVisibility] = useState(dependencyValue);

	useEffect(() => {
		if (!dependsOn) return;

		setDependencyVisibility(dependencyValue);
		if (group.included) forceUpdateValidationSchema(form.values);
	}, [form.values[dependsOn?.key]]);

	useEffect(() => {
		if (!dependsOn) return;
		if (!dependencyVisibility) form.setFieldValue(name, defaultValue);
	}, [dependencyVisibility]);

	if (dependsOn && !dependencyVisibility) return null;

	const getResultDate = (data) => (isUTC ? dateAsIsoString(data) : dateAsIsoStringLocal(data));

	const handleOnChange = (v) => setSelected(v);

	return (
		<FormControl fullWidth margin='normal'>
			<BaseDateTimePicker
				open={isOpen}
				onOpen={() => setIsOpen(true)}
				name={name}
				value={value}
				label={name}
				helperText={helperText}
				error={error}
				variant='inline'
				strictCompareDates
				fullWidth
				onChange={handleOnChange}
				onClose={() => {
					if (selected) form.setFieldValue(name, getResultDate(selected));
					setIsOpen(false);
				}}
			/>
		</FormControl>
	);
};

export default ConfiguratorDatetimefield;
