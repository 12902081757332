import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import makeStyles from '@mui/styles/makeStyles';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TextField } from '@mui/material';
import { selectLocale } from '../../../../Locales/localesSlice';

const useStyles = makeStyles(() => ({
	inputRoot: {
		visibility: 'hidden',
		height: 0,
		'& input': {
			height: 0,
			padding: 0
		}
	},
	adormentRoot: {
		display: 'none',
		'& svg': {
			display: 'none'
		}
	}
}));

const CustomDateTimePicker = ({ value, onChange, isOpen, setIsOpen }) => {
	const classes = useStyles();
	const locale = useSelector(selectLocale);
	const [currentValue, setCurrentValue] = useState(value);

	const handleSetCurrentValue = (v) => {
		const now = moment();
		const pastDate = v.isBefore(moment(now), 'minute');

		setCurrentValue(pastDate ? now : v);
	};

	const handleChange = (v) => {
		setTimeout(() => {
			onChange(v);
		}, 100);
	};

	useEffect(() => {
		if (!isOpen && currentValue != value) {
			handleChange(currentValue);
		}
	}, [isOpen]);

	const onClose = () => {
		setIsOpen(false);
	};

	return (
		<LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
			<DateTimePicker
				inputFormat={`${moment.localeData().longDateFormat('L')}`}
				minDate={moment()}
				open={isOpen}
				onOpen={() => setIsOpen(true)}
				onClose={onClose}
				value={currentValue}
				onChange={handleSetCurrentValue}
				renderInput={(params) => <TextField {...params} classes={{ root: classes.inputRoot }} />}
				InputAdornmentProps={{
					classes: {
						root: classes.adormentRoot
					}
				}}
			/>
		</LocalizationProvider>
	);
};

export default CustomDateTimePicker;
