import React from 'react';
import BaseIcon from './BaseIcon';

export default function HeatAllocatorUnmountedIcon(props) {
	return (
		<BaseIcon
			{...props}
			renderMultiPath={({ primaryPathClass, primaryPathColor, secondaryPathClass, secondaryPathColor }) => (
				<>
					<path
						className={primaryPathClass}
						style={{ color: primaryPathColor }}
						d='m 6.8576656,2.0447412 c -1.9957946,0 -3.6302079,1.6344066 -3.6302079,3.6302079 V 18.109845 c 0,1.995805 1.6344133,3.625 3.6302079,3.625 H 10.815999 A 6.4014089,6.4014089 0 0 1 10.052978,20.182762 H 6.8576656 c -1.1615746,0 -2.0755213,-0.911333 -2.0755213,-2.072917 V 5.6749491 c 0,-1.1615813 0.9139467,-2.07552 2.0755213,-2.07552 h 8.2890624 c 1.161576,0 2.070312,0.9139373 2.070312,2.07552 v 4.9348959 a 5.5979279,5.5979279 0 0 1 1.554688,0.36198 V 5.6749491 c 0,-1.9958013 -1.629205,-3.6302079 -3.625,-3.6302079 z m 2.070312,5.7031252 A 1.0362293,1.0362293 0 0 0 7.8915203,8.784325 1.0362293,1.0362293 0 0 0 8.9279776,9.8181783 H 13.073811 A 1.0362293,1.0362293 0 0 0 14.107665,8.784325 1.0362293,1.0362293 0 0 0 13.073811,7.7478664 Z'
					/>
					<path
						className={secondaryPathClass}
						style={{ color: secondaryPathColor }}
						d='m 16.126709,12.663592 c -2.5663,0 -4.64584,2.079529 -4.64584,4.645833 0,2.566305 2.07954,4.645833 4.64584,4.645833 2.5663,0 4.64583,-2.079528 4.64583,-4.645833 0,-2.566306 -2.07953,-4.645833 -4.64583,-4.645833 z m -1.52084,2.226563 a 0.66667,0.66667 0 0 1 0.48177,0.226562 l 1.03907,1.182291 1.03906,-1.182291 a 0.66667,0.66667 0 0 1 0.5,-0.226562 0.66667,0.66667 0 0 1 0.4401,0.166666 0.66667,0.66667 0 0 1 0.0599,0.940104 l -1.15365,1.3125 1.15365,1.3125 a 0.66667,0.66667 0 0 1 -0.0599,0.942708 0.66667,0.66667 0 0 1 -0.9401,-0.0625 l -1.03906,-1.182292 -1.03907,1.182292 a 0.66667,0.66667 0 0 1 -0.9401,0.0625 0.66667,0.66667 0 0 1 -0.0625,-0.942708 l 1.15364,-1.3125 -1.15364,-1.3125 a 0.66667,0.66667 0 0 1 0.0625,-0.940104 0.66667,0.66667 0 0 1 0.45833,-0.166666 z'
					/>
				</>
			)}
		/>
	);
}
