import { SaveAsNewIcon } from '@sonar-web/common';
import SaveSettingsType from './SaveSettingsType';
import TileButtonSelectable from '@sonar-web/common/src/features/Tiles/TileButtonSelectable';

const SaveAsNewTemplate = ({ toggleName, setToggleName }) => {
	const value = SaveSettingsType.SaveAsNewTemplate.value;

	const handleClick = () => {
		if (toggleName !== value) setToggleName(value);
	};

	return (
		<TileButtonSelectable
			Icon={SaveAsNewIcon}
			title='Common_SaveAsNewTemplate'
			selected={toggleName === value}
			onClick={handleClick}
			radio={true}
			data-at='Common_SaveAsNewTemplate'
		/>
	);
};

export default SaveAsNewTemplate;
