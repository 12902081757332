export const CustomElementSettings = new Map([
	//Ustawienia autokasowania zdarzeń
	[
		'auto_reset_events_volume_property',
		(_, elementSettings, data) => {
			const regValueThresholds = { min: 1, max: 255 };
			const volume_per_revolution_property = data.find((d) => d.key === 'volume_per_revolution_property');
			if (!volume_per_revolution_property) return elementSettings;
			return {
				...elementSettings,
				min: Number((volume_per_revolution_property.value * regValueThresholds.min) / 1000).toFixed(3),
				max: Number((volume_per_revolution_property.value * regValueThresholds.max) / 1000).toFixed(3)
			};
		}
	],
	//Objętość
	[
		'volume_property',
		(_, elementSettings, data) => {
			const regValueThresholds = { min: 1, max: 4294967295 };
			const volume_per_revolution_property = data.find((d) => d.key === 'volume_per_revolution_property');
			if (!volume_per_revolution_property) return elementSettings;
			return {
				...elementSettings,
				min: Number((volume_per_revolution_property.value * regValueThresholds.min) / 1000).toFixed(3),
				max: Number((volume_per_revolution_property.value * regValueThresholds.max) / 1000).toFixed(3)
			};
		}
	],
	//Próg postoju / Próg pomiaru bez zmian
	[
		'measurement_unchanged_max_volume_days_property',
		(_, elementSettings, data) => {
			const regValueThresholds = { min: 1, max: 255 };
			const volume_per_revolution_property = data.find((d) => d.key === 'volume_per_revolution_property');
			if (!volume_per_revolution_property) return elementSettings;
			return {
				...elementSettings,
				min: Number((volume_per_revolution_property.value * regValueThresholds.min) / 1000).toFixed(3),
				max: Number((volume_per_revolution_property.value * regValueThresholds.max) / 1000).toFixed(3)
			};
		}
	],
	[
		'measurement_unchanged_max_volume_given_days_property',
		(_, elementSettings, data) => {
			const regValueThresholds = { min: 1, max: 255 };
			const volume_per_revolution_property = data.find((d) => d.key === 'volume_per_revolution_property');
			if (!volume_per_revolution_property) return elementSettings;
			return {
				...elementSettings,
				min: Number((volume_per_revolution_property.value * regValueThresholds.min) / 1000).toFixed(3),
				max: Number((volume_per_revolution_property.value * regValueThresholds.max) / 1000).toFixed(3)
			};
		}
	],
	//Próg przepływu minimalnego
	[
		'minimum_flow_threshold_volume_property',
		(_, elementSettings, data) => {
			const regValueThresholds = { min: 1, max: 255 };
			const volume_per_revolution_property = data.find((d) => d.key === 'volume_per_revolution_property');
			if (!volume_per_revolution_property) return elementSettings;
			return {
				...elementSettings,
				min: Number((volume_per_revolution_property.value * regValueThresholds.min) / 1000).toFixed(3),
				max: Number((volume_per_revolution_property.value * regValueThresholds.max) / 1000).toFixed(3)
			};
		}
	],
	//Próg przepływu minimalnego w orientacji poziomej
	[
		'minimum_flow_threshold_threshold_property_H',
		(_, elementSettings, data) => {
			const [minimum_flow_threshold_threshold_property_H, maximum_flow_threshold_threshold_property] =
				data.filter(
					(d) =>
						d.key === 'minimum_flow_threshold_threshold_property_H' ||
						d.key === 'maximum_flow_threshold_threshold_property'
				);
			return {
				...elementSettings,
				min: minimum_flow_threshold_threshold_property_H?.value ?? elementSettings.min,
				max: maximum_flow_threshold_threshold_property?.value ?? elementSettings.max
			};
		}
	],
	[
		'minimum_flow_threshold_volume_property_H',
		(_, elementSettings, data) => {
			const regValueThresholds = { min: 1, max: 255 };
			const volume_per_revolution_property = data.find((d) => d.key === 'volume_per_revolution_property');
			if (!volume_per_revolution_property) return elementSettings;
			return {
				...elementSettings,
				min: Number((volume_per_revolution_property.value * regValueThresholds.min) / 1000).toFixed(3),
				max: Number((volume_per_revolution_property.value * regValueThresholds.max) / 1000).toFixed(3)
			};
		}
	],
	//Próg przepływu minimalnego w orientacji pionowej
	[
		'minimum_flow_threshold_threshold_property_V',
		(_, elementSettings, data) => {
			const [minimum_flow_threshold_threshold_property_V, maximum_flow_threshold_threshold_property] =
				data.filter(
					(d) =>
						d.key === 'minimum_flow_threshold_threshold_property_V' ||
						d.key === 'maximum_flow_threshold_threshold_property'
				);
			return {
				...elementSettings,
				min: minimum_flow_threshold_threshold_property_V?.value ?? elementSettings.min,
				max: maximum_flow_threshold_threshold_property?.value ?? elementSettings.max
			};
		}
	],
	[
		'minimum_flow_threshold_volume_property_V',
		(_, elementSettings, data) => {
			const regValueThresholds = { min: 1, max: 255 };
			const volume_per_revolution_property = data.find((d) => d.key === 'volume_per_revolution_property');
			if (!volume_per_revolution_property) return elementSettings;
			return {
				...elementSettings,
				min: Number((volume_per_revolution_property.value * regValueThresholds.min) / 1000).toFixed(3),
				max: Number((volume_per_revolution_property.value * regValueThresholds.max) / 1000).toFixed(3)
			};
		}
	],
	//Próg przepływu wstecznego
	[
		'reverse_flow_threshold_threshold_property',
		(_, elementSettings, data) => {
			const regValueThresholds = { min: 1, max: 255 };
			const volume_per_revolution_property = data.find((d) => d.key === 'volume_per_revolution_property');
			if (!volume_per_revolution_property) return elementSettings;
			return {
				...elementSettings,
				min: Number((volume_per_revolution_property.value * regValueThresholds.min) / 1000).toFixed(3),
				max: Number((volume_per_revolution_property.value * regValueThresholds.max) / 1000).toFixed(3)
			};
		}
	],
	[
		'reverse_flow_threshold_volume_property',
		(_, elementSettings, data) => {
			const regValueThresholds = { min: 1, max: 255 };
			const volume_per_revolution_property = data.find((d) => d.key === 'volume_per_revolution_property');
			if (!volume_per_revolution_property) return elementSettings;
			return {
				...elementSettings,
				min: Number((volume_per_revolution_property.value * regValueThresholds.min) / 1000).toFixed(3),
				max: Number((volume_per_revolution_property.value * regValueThresholds.max) / 1000).toFixed(3)
			};
		}
	],
	[
		'leakage_flow_threshold_hours_property',
		(_, elementSettings) => {
			const regValueThresholds = { min: 1, max: 255 };
			return {
				...elementSettings,
				min: Number((10 * regValueThresholds.min) / 60).toFixed(3),
				max: Number((10 * regValueThresholds.max) / 60).toFixed(3)
			};
		}
	]
]);
