import React from 'react';
import { FormControl, InputLabel, Select } from '@mui/material';
import { useLocale } from '@sonar-web/common/src/hooks';
import BaseSelectMenuItem from '@sonar-web/common/src/components/BaseSelectMenuItem/BaseSelectMenuItem';
import { dayMonthFormats } from './constants';

const options = [
	{ value: dayMonthFormats.single, label: 'Common_DayMonthFormatSingle' },
	{ value: dayMonthFormats.double, label: 'Common_DayMonthFormatDouble' }
];

const name = 'dayMonthFormat';
const SettingsDayMonthFormat = ({ updateSettings, current }) => {
	const { translate } = useLocale();

	const handleChange = (e) => {
		updateSettings(name, e.target.value);
	};

	return (
		<FormControl fullWidth margin='normal'>
			<InputLabel id={name}>{translate('Common_DayMonthFormat')}</InputLabel>
			<Select labelId={name} value={current[name]} onChange={handleChange}>
				{options.map((opt, index) => (
					<BaseSelectMenuItem key={index} value={opt.value}>
						{translate(opt.label)}
					</BaseSelectMenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default SettingsDayMonthFormat;
