export const moduleName = 'stationary';

export const slices = {
	stationaryNetworks: 'stationaryNetworks',
	stationaryNetwork: 'stationaryNetwork'
};

const Module = {
	moduleName,
	slices
};

export default Module;
