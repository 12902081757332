import React from 'react';
import PanelListItemAction from './PanelListItemAction';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const PanelListItemActionDashboard = ({ onClick }) => {
	return (
		<PanelListItemAction
			icon={<OpenInNewIcon fontSize='small' />}
			tooltip={'Common_Details'}
			onClick={onClick}
			renderLink
		/>
	);
};

export default PanelListItemActionDashboard;
