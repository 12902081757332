export default {
	Standalone: {
		value: 1,
		name: 'Standalone'
	},
	Order: {
		value: 2,
		name: 'Order'
	}
};
