import { apiBase } from '@sonar-web/common';
import { REST_API_PATHS } from '@sonar-web/common/src/constants/system';

const path = REST_API_PATHS.WATERMETERS;

export async function fetchMetadatas(deviceId) {
	const data = await apiBase(`device/${deviceId}/metadatas`, 'get', {
		path
	});

	return data.data;
}
