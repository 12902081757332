import React from 'react';
import { useLocale } from '@sonar-web/common/src/hooks';
import Button from './Button';

const ManualModeActions = ({ manualMode, setManualMode, onConfirm }) => {
	const { translate } = useLocale();

	return (
		<>
			{manualMode ? (
				<>
					<Button
						onClick={() => {
							setManualMode(false);
						}}>
						{translate('Common_Cancel')}
					</Button>
					&nbsp;&nbsp;
					<Button
						active={true}
						onClick={() => {
							setManualMode(false);

							onConfirm();
						}}>
						{translate('Common_Confirm')}
					</Button>
				</>
			) : (
				<Button
					onClick={() => {
						setManualMode(true);
					}}>
					{translate('Common_ManualSettings')}
				</Button>
			)}
		</>
	);
};

export default ManualModeActions;
