import React, { useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '../../hooks';
import TileBase from '../../features/Tiles/TileBase';
import HourOfDayItem from './HourOfDayItem';

const useStyles = makeStyles((theme) => ({
	titleRoot: {
		fontWeight: theme.typography.fontWeightMedium
	},
	grid: {
		display: 'grid',
		gridTemplateColumns: 'repeat(7, 1fr)',
		alignItems: 'center',
		gap: `12px`,
		padding: theme.spacing(1),
		[theme.breakpoints.down('lg')]: {
			gridTemplateColumns: '1fr 1fr 1fr 1fr'
		}
	},
	tile3Columns: {
		gridColumnEnd: 'span 3'
	}
}));

const SelectHourOfDay = ({ single = false, initial = [], onChange, disableTitle, ...rest }) => {
	const classes = useStyles();
	const { translate } = useLocale();

	const [hours, setHours] = useState(initial);

	const hoursTiles = useMemo(() => {
		const items = Array.from({ length: 24 }, (_, i) => i);

		return items.map((h) => ({ name: formatHour(h), value: h }));
	}, []);

	const handleClick = (v) => {
		if (single) singleSelection(v);
		else multipleSelection(v);
	};

	function singleSelection(v) {
		if (hours.length === 1 && hours[0] === v) return;

		setHours([v]);

		if (onChange) onChange([v]);
	}

	function multipleSelection(v) {
		const isSelected = hours.includes(v);
		const newHours = isSelected ? hours.filter((d) => d !== v) : [...hours, v];

		setHours(newHours);

		if (onChange) onChange(newHours);
	}

	return (
		<TileBase
			hoverEffect={false}
			renderContent={() => {
				return (
					<Box display='flex' flexDirection='column' flex={1} pr={5} pl={5} pt={4} pb={4}>
						{disableTitle && (
							<Typography variant='body2' classes={{ root: classes.titleRoot }}>
								{translate('Common_HourOfWeek')}
							</Typography>
						)}
						<Box className={classes.grid}>
							{hoursTiles.map((hour, index) => {
								return (
									<HourOfDayItem
										key={index}
										hour={hour}
										onClick={handleClick}
										selected={hours.includes(hour.value)}
									/>
								);
							})}
						</Box>
					</Box>
				);
			}}
			{...rest}
		/>
	);
};

export default SelectHourOfDay;

function formatHour(h) {
	const hour = h <= 9 ? `0${h}` : h;
	return `${hour}:00`;
}
