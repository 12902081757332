import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLocationNodeLevelsAsync, selectLocationLevels } from './slice';

const LocationNodeLevelsLoader = ({ children }) => {
	const dispatch = useDispatch();
	const { fetchSuccess, elements, fetchPending } = useSelector(selectLocationLevels);

	useEffect(() => {
		if (!elements || elements.length === 0) dispatch(fetchLocationNodeLevelsAsync());
	}, []);

	if (!fetchSuccess) return null;
	if (typeof children === 'function')
		return children({ levelTypes: elements, locationLevelsFetchpending: fetchPending });
	return children;
};

export default LocationNodeLevelsLoader;
