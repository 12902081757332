import { useEffect } from 'react';
import { useFetchOnDemand, useLocale } from '@sonar-web/common/src/hooks';
import { ExportsService } from '@sonar/exports';
import Slices from '../Constants/Slices';
import LicenseItemSecondary from './LicenseItemSecondary';
import LicenseItemProgress from './LicenseItemProgress';

const slice = Slices.licenses;

const ExportsCount = ({ maxNumberOfExports }) => {
	const { translate } = useLocale();

	const {
		fetchFunc,
		data: exportsCount,
		pending: devicesCountPending
	} = useFetchOnDemand({
		api: () => ExportsService.fetchCurrentNumberOfExports(),
		slice
	});

	useEffect(() => {
		fetchFunc();
	}, []);

	const count = exportsCount ?? 0;
	const left = count !== null ? maxNumberOfExports - count : null;
	const progress = count ? Math.floor((count / maxNumberOfExports) * 100) : 0;

	return (
		<>
			<LicenseItemSecondary pending={devicesCountPending} value={left} text='RemainToUse' />
			<LicenseItemProgress tooltip={translate('UsedXofY', count ?? '', maxNumberOfExports)} progress={progress} />
		</>
	);
};

export default ExportsCount;
