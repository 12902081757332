export const DaysOfWeek = {
	Monday: {
		name: 'Monday',
		value: 1
	},
	Tuesday: {
		name: 'Tuesday',
		value: 2
	},
	Wednesday: {
		name: 'Wednesday',
		value: 3
	},
	Thursday: {
		name: 'Thursday',
		value: 4
	},
	Friday: {
		name: 'Friday',
		value: 5
	},
	Saturday: {
		name: 'Saturday',
		value: 6
	},
	Sunday: {
		name: 'Sunday',
		value: 7
	}
};

export const getDayOfWeekByValue = (value) => {
	return Object.values(DaysOfWeek).find((day) => day.value === value);
};
