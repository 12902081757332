export default {
	Tenant: {
		value: 1,
		name: 'Tenant'
	},
	Inkasent: {
		value: 2,
		name: 'Inkasent'
	}
};
