import React from 'react';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '../../../../../hooks';
import SelectControlBase from '../SelectControlBase';
import DateItem from './DateItem';
import DateItemAdd from './DateItemAdd';

const useStyles = makeStyles((theme) => ({
	grid: {
		display: 'grid',
		gridTemplateColumns: `repeat(auto-fill, minmax(150px, 1fr))`,
		alignItems: 'center',
		gap: `5px`,
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1)
	}
}));

const SelectDate = ({ values, onChange, maxValuesCount, title, ...rest }) => {
	const classes = useStyles();
	const { translate } = useLocale();

	const handleChange = (value, index) => {
		let newValues = [...values];
		newValues[index] = value instanceof Date ? value : value.toDate();
		if (onChange) onChange(newValues);
	};

	const handleRemove = (index) => {
		let newValues = [...values];
		newValues.splice(index, 1);
		if (onChange) onChange(newValues);
	};

	const handleAdd = (value) => {
		let newValues = [...values];
		newValues.push(value instanceof Date ? value : value.toDate());
		if (onChange) onChange(newValues);
	};

	const newHidden = values.length >= maxValuesCount;
	return (
		<SelectControlBase
			title={translate(title)}
			{...rest}
			renderContent={() => {
				return (
					<>
						<Box className={classes.grid}>
							{values.map((value, index) => {
								return (
									<DateItem
										key={index}
										value={value}
										onChange={(v) => {
											handleChange(v, index);
										}}
										onRemove={() => {
											handleRemove(index);
										}}
									/>
								);
							})}
						</Box>
						{!newHidden && <DateItemAdd onAdd={handleAdd} />}
					</>
				);
			}}
		/>
	);
};

export default SelectDate;
