import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { Typography, buttonBaseClasses } from '@mui/material';
import ButtonLinkAlt from '@sonar-web/common/src/components/Buttons/ButtonLinkAlt';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import { useLocale } from '@sonar-web/common/src/hooks';
import Events from '../Constants/Events';
import Slices from '../Constants/Slices';
import TenantsService from '../TenantsService';
import FetchTenantsMode from '../Tenants/FetchTenantsMode';

const Wrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	[`& .${buttonBaseClasses.root}`]: {
		marginBottom: '1px',
		marginLeft: '4px',
		textDecoration: 'underline',
		textTransform: 'lowercase',
		fontSize: theme.typography.caption.fontSize
	}
}));

const slice = Slices.licenses;

const LicenseRefresh = () => {
	const { translate } = useLocale();
	const dispatch = useDispatch();
	const [refreshPending, setRefreshPending] = useState(false);

	const handleRefresh = async () => {
		try {
			setRefreshPending(true);
			await TenantsService.forceRefreshLicense();
			setRefreshPending(false);
			document.dispatchEvent(new Event(Events.LicenseRefreshRequested));
		} catch (error) {
			setRefreshPending(false);
			dispatch(addErrorAsync({ slice, error }));
		}
	};

	return (
		<Wrapper>
			<FetchTenantsMode fallback={<div style={{ height: '21px' }} />}>
				<Typography variant='caption' component='span'>
					{translate('LicenseRefreshDescription')}
				</Typography>
				<ButtonLinkAlt onClick={handleRefresh} progress pending={refreshPending}>
					Refresh
				</ButtonLinkAlt>
			</FetchTenantsMode>
		</Wrapper>
	);
};

export default LicenseRefresh;
