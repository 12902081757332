import { useSelector } from 'react-redux';
import { selectLevelsData } from './slice';
import useLocationNodeLevelsDefinitions from './useLocationNodeLevelsDefinitions';

export default () => {
	const { getDefinition, levels: levelsDictionary } = useLocationNodeLevelsDefinitions();
	const { levels, map } = useSelector(selectLevelsData);

	const canChangeLevel = (sourceLevelId, targetLevelId) => {
		const targetMapIndex = map[targetLevelId];
		const targetLevel = levels[targetMapIndex];

		if (sourceLevelId === targetLevelId) return false;
		if (targetLevel.leaves.length === 0) return false;

		const found = targetLevel.leaves.find((l) => l.id === sourceLevelId);

		return found !== undefined;
	};

	const getPossibleLevelsToMoveTo = (sourceLevelId) => {
		const mapIndex = map[sourceLevelId];
		const level = levels[mapIndex];
		const leavesIds = level.leaves.map((l) => l.id);

		let notSourceOrLeaves = levels.filter((l) => l.id !== sourceLevelId);
		notSourceOrLeaves = notSourceOrLeaves.filter((nsol) => !leavesIds.includes(nsol.id));

		return notSourceOrLeaves;
	};

	const canHaveChildren = (sourceLevelId) => {
		const mapIndex = map[sourceLevelId];
		const level = levels[mapIndex];

		return level.leaves.length > 0;
	};

	const getLevelPresentationInfo = (type) => {
		return getDefinition(type);
	};

	const isTopNodeLevel = (locationNodeLevelId) => {
		return locationNodeLevelId === Object.keys(map)[1];
	};

	const isLocal = (name) => name === levelsDictionary.Local.name;

	return {
		canChangeLevel,
		getPossibleLevelsToMoveTo,
		canHaveChildren,
		getLevelPresentationInfo,
		isTopNodeLevel,
		isLocal
	};
};
