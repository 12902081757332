import React, { useEffect, useState } from 'react';
import { BaseDateTimePicker } from '../../../../components';
import { dateAsIsoStringLocal } from '@sonar-web/common/src/helpers/date';
import { FormControl } from '@mui/material';

const timeProperty = 'time_hour_property';

const ConfiguratorOmsDatetime = ({
	name,
	value,
	error,
	helperText,
	form,
	element,
	group,
	forceUpdateValidationSchema
}) => {
	const { dependsOn, defaultValue } = element;
	const isDependent = dependsOn !== null;
	const dependencyValue = isDependent ? dependsOn.value === form.values[dependsOn.key] : false;

	const [dependencyVisibility, setDependencyVisibility] = useState(dependencyValue);

	useEffect(() => {
		if (!dependsOn) return;

		setDependencyVisibility(dependencyValue);
		if (group.included) forceUpdateValidationSchema(form.values);
	}, [form.values[dependsOn?.key]]);

	useEffect(() => {
		if (!dependsOn) return;
		if (!dependencyVisibility) {
			form.setFieldValue(name, defaultValue);
			form.setFieldValue(timeProperty, defaultValue);
		}
	}, [dependencyVisibility]);

	if (dependsOn && !dependencyVisibility) return null;

	const handleOnChange = (v) => {
		form.setFieldTouched(name, true);
		form.setFieldValue(name, dateAsIsoStringLocal(v, false, true), true);
	};

	return (
		<FormControl margin='normal'>
			<BaseDateTimePicker
				name={name}
				value={value}
				label={name}
				helperText={helperText}
				error={error}
				variant='inline'
				strictCompareDates
				fullWidth
				onChange={handleOnChange}
			/>
		</FormControl>
	);
};

export default ConfiguratorOmsDatetime;
