import { apiBase } from '@sonar-web/common';
import { REST_API_PATHS } from '@sonar-web/common/src/constants/system';

const path = REST_API_PATHS.STATIONARY;

export async function fetchNetwork(id) {
	const { data } = await apiBase(`networks/${id}`, 'get', {
		path
	});

	return data;
}

export async function addNetwork(network) {
	const response = await apiBase(`networks`, 'post', {
		path,
		data: network
	});

	const networkHeader = response.headers.location;

	if (networkHeader)
		return {
			url: networkHeader,
			id: networkHeader.slice(networkHeader.indexOf('/') + 1)
		};

	return response;
}

export async function editNetwork(network) {
	const data = await apiBase(`networks/${network.id}`, 'put', {
		path,
		data: network
	});

	return data;
}

export async function deleteNetwork(id) {
	const data = await apiBase(`networks/${id}`, 'delete', {
		path
	});

	return data;
}
