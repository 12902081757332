import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
	iconRoot: {
		color: '#b8b8b8'
	}
}));

const ListNoRecordsIcon = ({ children, fontSize = 'xl', ...rest }) => {
	const classes = useStyles();

	return children({ fontSize, classes, ...rest });
};

export default ListNoRecordsIcon;
