import { Box, Typography } from '@mui/material';
import { formatDateTimeSecondsPrecision, TableListColumn } from '@sonar-web/common';
import { useLocale } from '@sonar-web/common/src/hooks';

const DateAndTypeColumn = ({ type, date }) => {
	const { translate } = useLocale();

	return (
		<TableListColumn width={200} disableLeftPadding>
			<Box display='flex' flexDirection='column'>
				<Typography variant='body2' color='textPrimary' data-at='date'>
					{formatDateTimeSecondsPrecision(date)}
				</Typography>
				<Typography variant='caption' color='textSecondary' data-at-params={`type|${type}`}>
					{translate('Watermeters_ReadType_' + type)}
				</Typography>
			</Box>
		</TableListColumn>
	);
};

export default DateAndTypeColumn;
