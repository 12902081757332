import React from 'react';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Skeleton from '@mui/material/Skeleton';

const useStyles = makeStyles((theme) => ({
	skeletonRoot: {
		padding: 18,
		minHeight: 128,
		boxShadow: '0 0 8px 0 rgba(0,0,0,0.03)',
		border: `1px solid ${theme.palette.border.main}`,
		'& > *': {
			marginBottom: 10,
			maxWidth: 500
		},
		'&:last-child': {
			marginBottom: '0 !important'
		}
	}
}));

const ProfileCardSkeleton = () => {
	const classes = useStyles();

	return (
		<Box className={classes.skeletonRoot}>
			<Skeleton animation='wave' variant='rectangular' height={20} />
			<Skeleton animation='wave' variant='rectangular' height={30} />
		</Box>
	);
};

export default ProfileCardSkeleton;
