import React, { useEffect } from 'react';
import clsx from 'clsx';
import { ClickAwayListener } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ListItemBase } from '@sonar-web/common';

const useStyles = makeStyles(() => ({
	growRoot: {
		width: '100%',
		display: 'flex',
		position: 'relative',
		alignItems: 'center',
		justifyContent: 'flex-start'
	},
	growRootHidden: {
		display: 'none'
	},
	fullWidth: {
		width: '100%'
	}
}));

const PanelListItem = ({
	row,
	renderFrontItem,
	renderDeleteItem,
	selected,
	onClick,
	hoverBehavior = true,
	...rest
}) => {
	const classes = useStyles();
	const [show, setShow] = React.useState(false);
	const [inlineDeleteAction, setInlineDeleteAction] = React.useState(false);
	const noPendingAction = !inlineDeleteAction;

	const handleOnMouseOver = () => {
		if (!show) setShow(true);
	};
	const handleOnMouseLeave = () => setShow(false);
	const handleClickAway = () => setInlineDeleteAction(false);
	const handleInlineDelete = () => setInlineDeleteAction(true);

	useEffect(() => {
		if (inlineDeleteAction) setInlineDeleteAction(false);
	}, [row?.id]);

	return (
		<ListItemBase
			{...rest}
			hoverBehavior={hoverBehavior}
			onMouseOver={handleOnMouseOver}
			onMouseLeave={handleOnMouseLeave}
			onClick={(e) => {
				if (!onClick) return;
				onClick(e, row);
			}}
			selected={selected}>
			{noPendingAction && renderFrontItem(show, handleInlineDelete)}
			{renderDeleteItem && inlineDeleteAction && (
				<div
					className={clsx(classes.fullWidth, {
						[classes.growRootHidden]: !inlineDeleteAction
					})}>
					{inlineDeleteAction && (
						<ClickAwayListener onClickAway={handleClickAway}>
							<div className={classes.growRoot}>
								{renderDeleteItem(inlineDeleteAction, setInlineDeleteAction)}
							</div>
						</ClickAwayListener>
					)}
				</div>
			)}
		</ListItemBase>
	);
};

export default PanelListItem;
