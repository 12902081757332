import { createContext, useContext, useState } from 'react';

const DashboardContentContext = createContext({});

export const DashboardContentContextProvider = ({ children }) => {
	const [coverComponent, setCoverComponent] = useState(null);

	return (
		<DashboardContentContext.Provider
			value={{
				coverComponent,
				setCoverComponent
			}}>
			{children}
		</DashboardContentContext.Provider>
	);
};

export const useDashboardContentContext = () => useContext(DashboardContentContext);

export default DashboardContentContext;
