import styled from '@emotion/styled';
import { Stack, Typography } from '@mui/material';

const Wrapper = styled(Stack)({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	height: '28px',
	'& svg': {
		marginRight: '.5rem'
	}
});

const StatisticsSettingsItem = ({ Icon, text, dataat }) => {
	return (
		<Wrapper data-at='statisticsItem'>
			<Icon fontSize='small' />
			<Typography variant='caption' color='textSecondary' data-at-params={dataat}>
				{text}
			</Typography>
		</Wrapper>
	);
};

export default StatisticsSettingsItem;
