import { apiBase } from '@sonar-web/common';
import { REST_API_PATHS } from '@sonar-web/common/src/constants/system';

const path = REST_API_PATHS.WATERMETERS;

export async function fetchWaterMeterMetaData(id) {
	const { data } = await apiBase(`watermeters/${id}/properties`, 'get', {
		path
	});

	return data;
}

export async function editWaterMeterMetaData(id, metadata) {
	const data = await apiBase(`watermeters/${id}/properties`, 'put', {
		path,
		data: metadata
	});

	return data;
}

export async function fetchWatermeterReads(pageDescriptor) {
	const { data } = await apiBase(`reads`, 'get', {
		path,
		params: { pageDescriptor }
	});

	return data;
}

export async function addWaterMeter(watermeter) {
	const response = await apiBase(`watermeters`, 'post', {
		path,
		data: watermeter
	});

	const watermeterHeader = response.headers.location;

	if (watermeterHeader)
		return {
			url: watermeterHeader,
			id: watermeterHeader.slice(watermeterHeader.indexOf('/') + 1)
		};

	return response;
}

export async function fetchWatermeterTypes(pageDescriptor) {
	const { data } = await apiBase(`watermeterTypes`, 'get', {
		path,
		params: { pageDescriptor }
	});

	return data;
}

export async function fetchDiameterAndLength() {
	const { data } = await apiBase('diameterAndLengthDictionary', 'get', {
		path
	});

	return data;
}

export async function deleteWatermeter(id) {
	const data = await apiBase(`watermeters/${id}`, 'delete', {
		path
	});

	return data;
}

const WaterMetersService = {
	fetchWaterMeterMetaData,
	editWaterMeterMetaData,
	fetchWatermeterReads,
	addWaterMeter,
	fetchWatermeterTypes,
	fetchDiameterAndLength,
	deleteWatermeter
};

export default WaterMetersService;
