import { useMemo } from 'react';
import { Box } from '@mui/material';
import { TableListColumn, TableListItem } from '@sonar-web/common';
import useList from '@sonar-web/common/src/features/List/useList';
import ReadSource from '@sonar-web/common/src/enums/ReadSource';
import DateAndTypeColumn from './DateAndTypeColumn';
import SourceColumn from './SourceColumn';
import ReadValuesColumn from './ReadValuesColumn';
import ManualReadColumn from './ManualReadColumn';
import HeatallocatorReadsListItemDelete from './HeatallocatorReadsListItemDelete';
import Module from '../Constants/Module';
import HeatallocatorReadCollapsed from './HeatallocatorReadCollapsed';
import DeviceColumn from './DeviceColumn';

const HeatallocatorReadsListItem = ({ row, manualRead = true, deviceInfo = false }) => {
	const { updateRecord } = useList(Module.slices.heatallocatorReads);

	const hasAdditionalData = useMemo(() => checkHasAdditionalData({ source: row.source }), [row.source]);

	const updateCollapsedState = (rowCollapsed) => {
		updateRecord({ id: row.id, value: { rowCollapsed } });
	};

	return useMemo(
		() => (
			<TableListItem
				row={row}
				collapsedChildren={hasAdditionalData ? () => <HeatallocatorReadCollapsed {...row} /> : null}
				setCollapsed={updateCollapsedState}>
				{({ deleteMode, setDeleteMode }) => (
					<Box display='flex' alignItems='center'>
						{!deleteMode && (
							<>
								<DateAndTypeColumn type={row.type} period={row.period} date={row.date} />
								<SourceColumn source={row.source} />
								{deviceInfo && (
									<DeviceColumn
										number={row.heatAllocatorNumber}
										type={row.heatAllocatorType}
										deviceId={row.heatAllocatorId}
									/>
								)}
								{!deviceInfo && <TableListColumn flex />}
								<ReadValuesColumn bearing={row.bearing} />
								{manualRead && (
									<ManualReadColumn
										id={row.id}
										type={row.type}
										source={row.source}
										setDeleteMode={setDeleteMode}
									/>
								)}
							</>
						)}
						<HeatallocatorReadsListItemDelete
							id={row.id}
							date={row.date}
							deleteMode={deleteMode}
							setDeleteMode={setDeleteMode}
						/>
					</Box>
				)}
			</TableListItem>
		),
		[row.id, row.type, row.bearing, row.date, row.rowCollapsed]
	);
};

export default HeatallocatorReadsListItem;

function checkHasAdditionalData({ source }) {
	const isManualRead = source === ReadSource.Inkasent.name || source === ReadSource.Tenant.name;
	const isImportRead = source === ReadSource.Import.name;
	const isSetRead =
		source === ReadSource.StartMeasurementSetRead.name || source === ReadSource.EndMeasurementSetRead.name;

	return !(isManualRead || isSetRead || isImportRead);
}
