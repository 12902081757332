import { apiBase } from '@sonar-web/common';
import { REST_API_PATHS } from '@sonar-web/common/src/constants/system';
import { fillMissingDates } from '@sonar-web/location/src/LocationNodeCharts/Utils/ChartSettings';

const path = REST_API_PATHS.WATERMETERS;

async function fetchChartReads({ pageDescriptor, from, to }) {
	const { data } = await apiBase(`reads`, 'get', {
		path,
		params: { pageDescriptor }
	});

	// const withKinds = data.elements.map((item) => {
	// 	return { ...item, measuringKind: item.watermeterType === 'JS 2,5-02 Smart C+' ? 'Main' : 'Additional' };
	// });

	// const mainWithMissingDates = fillMissingDates(
	// 	withKinds.filter((wk) => wk.measuringKind === 'Main'),
	// 	from,
	// 	to
	// ).map((item) => ({ ...item, measuringKind: 'Main' }));

	// const additionalWithMissingDates = fillMissingDates(
	// 	withKinds.filter((wk) => wk.measuringKind === 'Additional'),
	// 	from,
	// 	to
	// ).map((item) => ({ ...item, measuringKind: 'Additional' }));

	// const elements = [...mainWithMissingDates, ...additionalWithMissingDates].sort(
	// 	(a, b) => new Date(a.date) - new Date(b.date)
	// );

	return { elements: fillMissingDates(data.elements, from, to) };
}

async function fetchChartConsumptions(params) {
	const { data } = await apiBase(`consumptions`, 'get', {
		path,
		params
	});

	return data;
}

const WaterMetersService = {
	fetchChartReads,
	fetchChartConsumptions
};

export default WaterMetersService;
