import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PanelListItemActionMore from './PanelListItemActionMore';

const useStyles = makeStyles((theme) => ({
	floatingRoot: {
		padding: '10px 0',
		background: `linear-gradient(90deg, rgba(255,255,255,0) 0%, ${theme.palette.primaryLight.light} 60%)`,
		transition: theme.transitions.create(['width', 'padding'], {
			easing: theme.transitions.easing.easeInOut,
			duration: theme.transitions.duration.short
		}),
		display: 'flex',
		justifyContent: 'flex-end'
	},
	floatingRootVisible: {
		padding: '10px 16px'
	},
	floatingRootSelected: {
		background: `linear-gradient(90deg, rgba(255,255,255,0) 0%, ${theme.palette.primaryLight.dark} 60%)`
	},
	listItemActions: {
		display: 'flex'
	}
}));

const PanelListItemActions = ({ show, shrink, selected, children }) => {
	const classes = useStyles();
	const [toggleVisibility, setToggleVisibility] = useState(true);

	useEffect(() => {
		if (!show && toggleVisibility) setToggleVisibility(false);
	}, [show]);

	if (!show) return null;

	if (shrink)
		return (
			<>
				{show && (
					<Box
						display='flex'
						position='absolute'
						overflow='hidden'
						right={16}
						width={toggleVisibility ? '100%' : 0}
						className={clsx(classes.floatingRoot, {
							[classes.floatingRootVisible]: toggleVisibility,
							[classes.floatingRootSelected]: selected
						})}>
						{children}
					</Box>
				)}
				{(show || selected) && (
					<PanelListItemActionMore onClick={() => setToggleVisibility(!toggleVisibility)} />
				)}
			</>
		);

	return (
		<Box marginLeft={3} display='none' className={clsx({ [classes.listItemActions]: show || selected })}>
			{children}
		</Box>
	);
};

export default PanelListItemActions;
