import React from 'react';
import BaseIcon from './BaseIcon';

export default function CloseIcon(props) {
	return (
		<BaseIcon {...props}>
			<path d='m 2.6536457,1.9984474 a 0.66771646,0.66771646 0 0 0 -0.460937,0.20052 0.66771646,0.66771646 0 0 0 0,0.945313 l 8.8619783,8.8593746 -8.8619783,8.85677 a 0.66771646,0.66771646 0 0 0 0,0.945312 0.66771646,0.66771646 0 0 0 0.945312,0 l 8.8619783,-8.859374 8.864584,8.859374 a 0.66771646,0.66771646 0 0 0 0.942708,0 0.66771646,0.66771646 0 0 0 0,-0.945312 l -8.861979,-8.85677 8.861979,-8.8593746 a 0.66771646,0.66771646 0 0 0 0,-0.945313 0.66771646,0.66771646 0 0 0 -0.411459,-0.197916 0.66771646,0.66771646 0 0 0 -0.531249,0.197916 L 11.999999,11.058342 3.1380208,2.1989674 a 0.66771646,0.66771646 0 0 0 -0.403646,-0.197916 0.66771646,0.66771646 0 0 0 -0.08073,-0.0026 z' />
		</BaseIcon>
	);
}
