import React from 'react';
import { MenuItem } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
	menuItemSelected: {
		borderBottomColor: 'transparent !important'
	}
}));

const BaseSelectMenuItem = React.forwardRef(function BaseSelectMenuItem({ children, ...rest }, ref) {
	const classes = useStyles();

	return (
		<MenuItem
			ref={ref}
			style={{ whiteSpace: 'break-spaces' }}
			classes={{ selected: classes.menuItemSelected }}
			{...rest}>
			{children}
		</MenuItem>
	);
});

export default BaseSelectMenuItem;
