import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PanelContentWrapper } from '@sonar-web/common';
import LocationNodesList from './LocationNodesList';
import LocationNodesBreadcrumb from './LocationNodesBreadcrumb';
import {
	selectFetchLocationNodeLevelsSuccess,
	fetchLocationNodeLevelsTreeAsync,
	selectFetchLocationNodeLevelsTreeSucces
} from '../LocationNodeLevels/slice';
import { setResetAndReload, setSelected } from './slice';
import { getLocationNodesSidebarComponents } from './locationNodesSidebar';
import { sidebars } from '../constants';
import useLocationNodes from '../useLocationNodes';

const LocationNodesPage = ({
	additionMode = false,
	additionModeOnSelect = null,
	disableSubmitMany = false,
	nodeAddOnClose,
	addActionTooltip = 'Common_Confirm'
}) => {
	const dispatch = useDispatch();
	const { ensureLocationNodeLevelsLoaded } = useLocationNodes();

	ensureLocationNodeLevelsLoaded();

	const nodeLevelsFetchSuccess = useSelector(selectFetchLocationNodeLevelsSuccess);
	const nodeLevelsFetchTreeSuccess = useSelector(selectFetchLocationNodeLevelsTreeSucces);
	const emptyLevels = useSelector((state) => state.locationNodeLevels.locationNodeLevelsEmpty);

	useEffect(() => {
		return () => {
			dispatch(setResetAndReload(false));
			dispatch(setSelected(null));
		};
	}, []);

	useEffect(() => {
		if (!nodeLevelsFetchSuccess) return;
		dispatch(fetchLocationNodeLevelsTreeAsync());
	}, [nodeLevelsFetchSuccess]);

	return (
		<>
			{emptyLevels && <div>Brak LocationNodeLevels...</div>}
			{nodeLevelsFetchSuccess && nodeLevelsFetchTreeSuccess && (
				<LocationNodesBreadcrumb
					submitActionTooltip={addActionTooltip}
					disableSubmitMany={disableSubmitMany}
					nodeAddOnClose={nodeAddOnClose}
				/>
			)}
			<PanelContentWrapper
				mainContent={
					nodeLevelsFetchSuccess &&
					nodeLevelsFetchTreeSuccess && (
						<LocationNodesList additionMode={additionMode} additionModeOnSelect={additionModeOnSelect} />
					)
				}
				sidebarType={sidebars.locationNodes}
				sidebarComponents={getLocationNodesSidebarComponents(additionModeOnSelect == null)}
			/>
		</>
	);
};

export default LocationNodesPage;
