import { Box, Typography } from '@mui/material';
import ConfirmationView from '../../../components/Confirmation/ConfirmationView';
import { useLocale } from '../../../hooks';
import DeviceConfigurationName from './DeviceConfigurationName';
import DeviceConfigurationConsents from './DeviceConfigurationConsents';

const SaveWithName = ({ handleSubmit, handleBack, pending, isEdit, submitConsents }) => {
	const { translate } = useLocale();
	const initialDisable = submitConsents ? { consents: true, name: true } : true;

	return (
		<ConfirmationView
			pending={pending}
			onCancel={handleBack}
			cancelText='Common_WaitLetMeChange'
			onConfirm={handleSubmit}
			confirmText='Common_ConfiguratorSubmitNewConfiguration'
			header='Common_EverythingIsReady'
			initialDisable={initialDisable}>
			{({ setDisabled, disabled }) => (
				<Box>
					<Typography variant='caption' color='textSecondary'>
						{translate(
							isEdit
								? 'Common_ConfiguratorSubmitReadySubtitleEdit'
								: 'Common_ConfiguratorSubmitReadySubtitleAdd'
						)}
					</Typography>
					<DeviceConfigurationName
						disabled={disabled}
						setDisabled={setDisabled}
						handleSubmit={handleSubmit}
					/>
					{submitConsents && (
						<DeviceConfigurationConsents setDisabled={setDisabled} consents={submitConsents} />
					)}
				</Box>
			)}
		</ConfirmationView>
	);
};

export default SaveWithName;
