import { Typography } from '@mui/material';
import { useLocale } from '@sonar-web/common/src/hooks';
import { TableListColumn, TableListHeader } from '@sonar-web/common';

const HeatallocatorReadsLocationHeader = () => {
	const { translate } = useLocale();

	return (
		<TableListHeader id='HeatallocatorReadsListHeader'>
			<TableListColumn width={220} disableLeftPadding>
				<Typography variant='caption' color='textSecondary'>
					{translate('HeatAllocators_ReadDateAndType')}
				</Typography>
			</TableListColumn>
			<TableListColumn width={250}>
				<Typography variant='caption' color='textSecondary'>
					{translate('HeatAllocators_ReadSource')}
				</Typography>
			</TableListColumn>
			<TableListColumn flex>
				<Typography variant='caption' color='textSecondary'>
					{translate('HeatAllocators_SerialNumber')}
				</Typography>
			</TableListColumn>
			<TableListColumn width={200} style={{ textAlign: 'end' }}>
				<Typography variant='caption' color='textSecondary'>
					{translate('HeatAllocators_Bearing')}
				</Typography>
			</TableListColumn>
		</TableListHeader>
	);
};

export default HeatallocatorReadsLocationHeader;
