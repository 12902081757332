import { DateHelpers } from '@sonar-web/common';
import LicenseItem from './LicenseItem';
import TimeLicenseIcon from '../Icons/TimeLicenseIcon';

const Time = ({ pending, data }) => {
	return (
		<LicenseItem
			pending={pending}
			data={
				data
					? {
							Icon: TimeLicenseIcon,
							title: 'Time',
							description: 'LicenseTimeDescription',
							primaryData: {
								value: DateHelpers.formatDateTime(data.validTo, 'date'),
								text: 'TimeLicenseExpiryDate'
							},
							secondaryData: {
								value: DateHelpers.remainingTime(
									new Date(data.validTo.substr(0, data.validTo.indexOf('T'))),
									null,
									true
								),
								text: 'TimeLicenseRemainingTime'
							},
							visualizationData: {
								value: null
							}
					  }
					: null
			}
		/>
	);
};

export default Time;
