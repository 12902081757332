export default {
	Empty: {
		color: '#FFFFFF',
		textColor: '#FFFFFF',
		text: 'Empty',
		tooltip: null
	},
	FirstHourWorkMode: {
		color: '#D8F1FF',
		textColor: '#293D4D',
		text: 'FirstHourWorkMode',
		tooltip: 'FirstHourWorkMode_Tooltip'
	},
	SecondHourWorkMode: {
		color: '#F7F7F7',
		textColor: '#293D4D',
		text: 'SecondHourWorkMode',
		tooltip: 'SecondHourWorkMode_Tooltip'
	},
	DayOfWeekWorkMode: {
		color: '#043C77',
		textColor: '#F7FBFF',
		text: 'DayOfWeekWorkMode',
		tooltip: 'DayOfWeekWorkMode_Tooltip'
	},
	DayOfMonthWorkMode: {
		color: '#5AB5DC',
		textColor: '#F7FBFF',
		text: 'DayOfMonthWorkMode',
		tooltip: 'DayOfMonthWorkMode_Tooltip'
	},
	MonthWorkMode: {
		color: '#00152C',
		textColor: '#F7FBFF',
		text: 'MonthWorkMode',
		tooltip: 'MonthWorkMode_Tooltip'
	}
};
