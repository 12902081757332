import React from 'react';
import BaseIcon from './BaseIcon';

export default function MainMenuIcon(props) {
	return (
		<BaseIcon {...props}>
			<path d='M 5.3333335,5.3333333 V 8 H 18.666666 V 5.3333333 Z m 0,5.3333337 v 2.666666 H 18.666666 v -2.666666 z m 0,5.333333 v 2.666667 H 18.666666 V 16 Z' />
		</BaseIcon>
	);
}
