import { createSlice } from '@reduxjs/toolkit';
import { pageDescriptor, getSliceDescriptor } from '@sonar-web/common';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import { fetchDiagnosticTerminalReads } from '../terminalDiagnosticApi';

const slice = 'terminalDiagnosticReads';

export const terminalDiagnosticReadsSlice = createSlice({
	name: slice,
	initialState: {
		pageDescriptor,
		pending: false,
		totalCount: 0,
		hasMore: true,
		reset: false,
		WmbusDeviceDiagnosticReads: [],
		selected: null
	},
	reducers: {
		fetchWmbusDeviceDiagnosticReadsSucces: (state, action) => {
			const { elements, totalCount } = action.payload;

			state.WmbusDeviceDiagnosticReads =
				state.pageDescriptor.Offset === 0 ? elements : [...state.WmbusDeviceDiagnosticReads, ...elements];
			state.totalCount = totalCount;
			state.pending = false;
			state.reset = false;
			state.hasMore = totalCount > state.pageDescriptor.Offset + state.pageDescriptor.Limit;
		},
		fetchWmbusDeviceDiagnosticReadsPending: (state, action) => {
			state.pending = action.payload;
		},
		setResetAndReload: (state) => {
			state.reset = true;
			state.pageDescriptor.Offset = 0;
		},
		setOffset: (state, action) => {
			state.pageDescriptor.Offset = action.payload ? state.pageDescriptor.Offset + state.pageDescriptor.Limit : 0;
		},
		setSelected: (state, action) => {
			state.selected = action.payload;
		}
	}
});

export const {
	fetchWmbusDeviceDiagnosticReadsSucces,
	fetchWmbusDeviceDiagnosticReadsPending,
	setResetAndReload,
	setSelected,
	setOffset
} = terminalDiagnosticReadsSlice.actions;

export const fetchTerminalDiagnosticReadsAsync = ({ deviceId, networkId }) => async (dispatch, getState) => {
	try {
		const pageDescriptor = getSliceDescriptor(getState, slice);

		dispatch(fetchWmbusDeviceDiagnosticReadsPending(true));
		const response = await fetchDiagnosticTerminalReads({ networkId, deviceId, pageDescriptor });

		dispatch(fetchWmbusDeviceDiagnosticReadsSucces(response));
	} catch (error) {
		dispatch(fetchWmbusDeviceDiagnosticReadsPending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const selectTerminalDiagnosticReads = (state) => {
	return {
		dataRows: state.terminalDiagnosticReads.WmbusDeviceDiagnosticReads,
		pending: state.terminalDiagnosticReads.pending,
		hasMore: state.terminalDiagnosticReads.hasMore,
		reset: state.terminalDiagnosticReads.reset,
		totalCount: state.terminalDiagnosticReads.totalCount
	};
};

export const selectSelected = (state) => state.terminalDiagnosticReads.selected;

export default terminalDiagnosticReadsSlice.reducer;
