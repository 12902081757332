import React from 'react';
import { Box, Button, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	buttonRoot: {
		minWidth: 'auto',
		padding: 6,
		boxShadow: '0 0 2px 0 rgba(0,0,0,0.2)',
		'& span > svg.MuiSvgIcon-root > path': {
			color: 'white !important'
		}
	},
	buttonRootSmaller: {
		padding: 3
	},
	buttonStarticon: {
		marginRight: 0,
		marginLeft: '0px !important'
	},
	contained: {
		border: `1px solid transparent !important`
	},
	buttonLabel: {},
	buttonIconSmall: {
		'& *:first-child': {
			fontSize: theme.typography.pxToRem(20)
		}
	},
	progressRoot: {
		position: 'absolute',
		top: -4,
		left: -4
	},
	progressPositionSmall: {}
}));

const BaseButtonOnlyIcon = React.forwardRef(function BaseButtonOnlyIcon(
	{ size = 'small', icon, iconProps, progress = false, progressProps, pending = false, disabled = false, ...rest },
	ref
) {
	const classes = useStyles();
	const Icon = icon;
	const progressSize = size === 'smaller' ? 36 : 40;

	return (
		<Box position='relative'>
			<Button
				ref={ref}
				color='primary'
				size={size}
				disabled={disabled || pending}
				variant='contained'
				startIcon={<Icon {...iconProps} />}
				classes={{
					root: clsx(classes.buttonRoot, { [classes.buttonRootSmaller]: size === 'smaller' }),
					startIcon: classes.buttonStarticon,
					label: classes.buttonLabel,
					iconSizeSmall: classes.buttonIconSmall,
					contained: classes.contained
				}}
				{...rest}
			/>
			{progress && pending && (
				<CircularProgress size={progressSize} classes={{ root: classes.progressRoot }} {...progressProps} />
			)}
		</Box>
	);
});

BaseButtonOnlyIcon.muiName = Button.muiName;
export default BaseButtonOnlyIcon;
