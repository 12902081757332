import React from 'react';
import BaseTooltip from '../BaseTooltip/BaseTooltip';
import IconButtonDelete from '../Buttons/IconButtonDelete';

const DashboardTitleActionDelete = ({ onClick, show = true }) => {
	return (
		<BaseTooltip text='Common_Delete'>
			<span>
				<IconButtonDelete color='primary' onClick={onClick} name='dashboardActionDelete' disabled={!show} />
			</span>
		</BaseTooltip>
	);
};

export default DashboardTitleActionDelete;
