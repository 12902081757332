import { createSlice } from '@reduxjs/toolkit';

const slice = 'sidebar';
export const sidebarSlice = createSlice({
	name: slice,
	initialState: {
		defaultTab: 0,
		exclusiveMode: false,
		coverMode: null
	},
	reducers: {
		toggleSidebar: (state, action) => {
			const { type, data } = action.payload;

			if (!state[type]) state[type] = {};
			state[type] = data;
		},
		closeSidebar: (state, action) => {
			const { type, preventTabReset } = action.payload;

			delete state[type];
			if (!preventTabReset) state.defaultTab = 0;
		},
		updateDefaultTab: (state, action) => {
			state.defaultTab = action.payload;
		},
		toggleExclusiveMode: (state, action) => {
			state.exclusiveMode = action.payload;
		},
		toggleCoverMode: (state, action) => {
			state.coverMode = action.payload;
		}
	}
});

export const {
	toggleSidebar,
	closeSidebar,
	updateDefaultTab,
	toggleExclusiveMode,
	toggleCoverMode
} = sidebarSlice.actions;

export const selectSidebar = (state) => state.sidebar;
export const selectExclusiveMode = (state) => state.sidebar.exclusiveMode;
export const selectCoverMode = (state) => state.sidebar.coverMode;

export default sidebarSlice.reducer;
