import { useLocale } from '@sonar-web/common/src/hooks';
import { FormControl, FormHelperText, InputLabel, Select, Skeleton } from '@mui/material';
import BaseSelectMenuItem from '@sonar-web/common/src/components/BaseSelectMenuItem/BaseSelectMenuItem';
import FetchNetworkProperties from '../Components/FetchNetworkProperties';

const CycleLength = ({ onSelect, value, error, helperText, readOnly = false }) => {
	const { translate } = useLocale();

	const onChange = (e) => {
		onSelect(e.target.value);
	};

	return (
		<FetchNetworkProperties>
			{({ networkPropertiesPending, networkProperties }) => {
				if (networkPropertiesPending || networkProperties.length === 0)
					return <Skeleton animation='wave' variant='text' />;

				return (
					<FormControl fullWidth margin='normal' error={error}>
						<InputLabel id='CycleLength'>{translate('CycleLength')}</InputLabel>
						<Select
							name='CycleLength'
							labelId='CycleLength'
							value={value}
							onChange={onChange}
							disabled={readOnly}
							readOnly={readOnly}>
							{networkProperties.map((opt) => (
								<BaseSelectMenuItem key={opt.name} value={opt.name}>
									{translate(opt.name, opt.maxDevicesSlots)}
								</BaseSelectMenuItem>
							))}
						</Select>
						<FormHelperText data-at-error={helperText}>{error && translate(helperText)}</FormHelperText>
					</FormControl>
				);
			}}
		</FetchNetworkProperties>
	);
};

export default CycleLength;
