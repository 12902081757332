import { useMemo, useState } from 'react';
import moment from 'moment';
import styled from '@emotion/styled';
import { Collapse, Typography } from '@mui/material';
import { useLocale } from '@sonar-web/common/src/hooks';
import { InfoIcon, Spacer } from '@sonar-web/common';
import LocalStorageHelper from '@sonar-web/common/src/helpers/LocalStorageHelper';
import RemindButton from './RemindButton';
import useCurrentTenant from '../Hooks/useCurrentTenant';

const Wrapper = styled('div')(({ theme }) => ({
	zIndex: theme.zIndex.modal - 20,
	background: theme.palette.primary.main,
	width: '100%',
	color: '#ffffff',
	display: 'flex',
	alignItems: 'center',
	padding: '0.5rem',
	paddingLeft: '1rem',
	position: 'sticky',
	'& > svg': {
		marginRight: '0.5rem'
	}
}));

const TimeLicenseNotifier = () => {
	const { translate } = useLocale();
	const { getCurrentTenant } = useCurrentTenant();
	const currentTenant = getCurrentTenant();
	const [remindDate, setRemindDate] = useState(LocalStorageHelper.getValue('spidap_license_reminder'));

	const licenseRemainingDays = currentTenant.days;
	const shouldShow = licenseRemainingDays <= 60;

	const show = useMemo(() => {
		if (!shouldShow) return false;
		if (!remindDate) return true;

		return moment(remindDate).isBefore();
	}, [remindDate]);

	const warningText = () => {
		if (licenseRemainingDays === 0) return translate('Common_LicenseWarningToday');
		if (licenseRemainingDays === 1) return translate('Common_LicenseWarning1Day');
		return translate('Common_LicenseWarningXDays', licenseRemainingDays);
	};

	const handleRemind = () => {
		const newRemindDate = moment().add(1, 'days').toISOString();

		LocalStorageHelper.setValue('spidap_license_reminder', newRemindDate);
		setRemindDate(newRemindDate);
	};

	return (
		<Collapse in={show} id='LicenseNotifier'>
			<Wrapper>
				<InfoIcon />
				<Typography variant='body2'>{warningText()}</Typography>
				<Spacer />
				<RemindButton onClick={handleRemind} />
			</Wrapper>
		</Collapse>
	);
};

export default TimeLicenseNotifier;
