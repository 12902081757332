import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Fade } from '@mui/material';
import WorkflowType from './WorkflowType';
import { useIsMount } from '@sonar-web/common/src/hooks';
import FromScratch from './FromScratch';
import TemplateSelect from './TemplateSelect';
import TemplatesList from './TemplatesList';

const Wrapper = styled('div')({
	display: 'flex',
	flexDirection: 'column',
	'& .tileButtonSelectableWrapper:first-of-type': {
		marginBottom: '0.8rem'
	}
});

const Workflow = ({ stepIndex, useConfigurator, configuratorData, props }) => {
	const isMount = useIsMount();
	const { defaultConfiguratorData, templateConfiguratorData, updateStepValid } = useConfigurator();
	const [toggleList, setToggleList] = useState(WorkflowType.None.value);
	const [template, setTemplate] = useState(null);

	useEffect(() => {
		if (isMount) return;
		updateStepValid(
			stepIndex,
			(toggleList === WorkflowType.FromTemplate.value && template) ||
				toggleList === WorkflowType.FromScratch.value
		);

		if (!toggleList) setTemplate(null);
	}, [toggleList]);

	useEffect(() => {
		if (isMount) return;
		if (!template) defaultConfiguratorData();
		else {
			templateConfiguratorData(template, props.serializedProperties);
			updateStepValid(stepIndex, true);
		}
	}, [template]);

	return (
		<Wrapper>
			<FromScratch toggleList={toggleList} setToggleList={setToggleList} />
			<TemplateSelect toggleList={toggleList} setToggleList={setToggleList} />
			<Fade in={Boolean(toggleList)} mountOnEnter>
				<div>
					<TemplatesList
						template={template}
						setTemplate={setTemplate}
						props={props}
						configuratorData={configuratorData}
					/>
				</div>
			</Fade>
		</Wrapper>
	);
};

export default Workflow;
