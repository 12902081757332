import LicenseItem from './LicenseItem';
import ExportsCount from './ExportsCount';
import ExportsLicenseIcon from '../Icons/ExportsLicenseIcon';

const Exports = ({ pending, data }) => {
	return (
		<LicenseItem
			pending={pending}
			data={
				data
					? {
							Icon: ExportsLicenseIcon,
							title: 'Exports',
							description: 'LicenseExportsDescription',
							primaryData: {
								value: data.maxNumberOfExports,
								text: 'Limit'
							}
					  }
					: null
			}>
			{data && !pending && <ExportsCount maxNumberOfExports={data.maxNumberOfExports} />}
		</LicenseItem>
	);
};

export default Exports;
