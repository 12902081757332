import { Box, RadioGroup, Typography } from '@mui/material';
import { useLocale } from '@sonar-web/common/src/hooks';
import { InterfaceType } from '@sonar-web/common';
import FiltersLayersTile from './FiltersLayersTile';

const options = [
	{
		value: InterfaceType.Everything.value,
		title: 'AllDevices'
	},
	{
		value: InterfaceType.ColdWaterMeasurement.value,
		title: 'ColdWatermeters'
	},
	{
		value: InterfaceType.WarmWaterMeasurement.value,
		title: 'WarmWatermeters'
	},
	{
		value: InterfaceType.HeatAllocating.value,
		title: 'Heatallocators'
	},
	{
		value: InterfaceType.AllHeatmeters.value,
		title: 'HeatMeters'
	}
];

const FiltersLayer = ({ filters, setFilters }) => {
	const { translate } = useLocale();
	const selectedLayer = filters.layer;

	const onLayerChange = (e) => setFilters({ ...filters, layer: e.target.value });

	return (
		<Box display='flex' flexDirection='column' rowGap={1} className='filtersSection filtersSectionFirst'>
			<Typography variant='body2'>{translate('Common_Devices')}</Typography>
			<RadioGroup value={selectedLayer} onChange={onLayerChange} className='filtersSectionItems'>
				{options.map((o) => {
					return <FiltersLayersTile key={o.value} {...o} selected={selectedLayer === String(o.value)} />;
				})}
			</RadioGroup>
		</Box>
	);
};

export default FiltersLayer;
