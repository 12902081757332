import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TextField } from '@mui/material';
import { useLocale } from '../../hooks';
import { selectLocale } from '../../features/Locales/localesSlice';

const BaseTimePicker = ({ name, value, error, helperText, label, onChange, ...rest }) => {
	const { translate } = useLocale();
	const locale = useSelector(selectLocale);

	const handleOnChange = (v) => {
		onChange(v);
	};

	return (
		<LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale} dateLibInstance={momentTimezone}>
			<TimePicker
				name={name}
				value={value}
				ampm={moment.localeData().longDateFormat('LT').includes('A')}
				format={moment.localeData().longDateFormat('LT')}
				label={translate(label)}
				openTo='hours'
				views={['hours', 'minutes']}
				fullWidth
				onChange={handleOnChange}
				renderInput={(params) => (
					<TextField name={name} {...params} error={error} helperText={translate(helperText)} />
				)}
				{...rest}
			/>
		</LocalizationProvider>
	);
};

export default BaseTimePicker;
