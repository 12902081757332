import { Stack, Typography } from '@mui/material';
import { useLocale } from '../../../hooks';
import LinkRouter from '../../../components/Router/LinkRouter';

const DeviceConfiguratorHeader = ({ deviceData, headerData }) => {
	const { translate } = useLocale();

	if (!deviceData || !headerData) return null;
	const { title, primaryData, secondaryData } = headerData;

	return (
		<Stack>
			<Typography variant='body2' color='textSecondary'>
				{translate(title)}
			</Typography>
			{secondaryData ? (
				<LinkRouter to={headerData.url} variant='h5'>
					<strong>{deviceData[primaryData]}</strong> {`(${deviceData[secondaryData]})`}
				</LinkRouter>
			) : (
				<LinkRouter to={headerData.url} variant='h5'>
					{deviceData[primaryData]}
				</LinkRouter>
			)}
		</Stack>
	);
};

export default DeviceConfiguratorHeader;
