import React from 'react';
import { Divider } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
	dividerRoot: {
		height: 25,
		marginLeft: 10,
		marginRight: 10
	}
}));

const ToolbarDivider = ({ hide }) => {
	const classes = useStyles();

	if (hide) return null;
	return <Divider orientation='vertical' classes={{ root: classes.dividerRoot }} />;
};

export default ToolbarDivider;
