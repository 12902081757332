import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { ToggleButton } from '@mui/material';

const useStyles = makeStyles(() => ({
   toggleButtonRoot: {
      borderRadius: '50%',
      borderColor: 'transparent'
   }
}));

const BaseToggleButton = React.forwardRef(function BaseToggleButton({ icon, iconProps, pending, ...rest }, ref) {
   const classes = useStyles();
   const Icon = icon;

   return (
      <ToggleButton
         ref={ref}
         size='small'
         disabled={pending}
         {...rest}
         classes={{ root: classes.toggleButtonRoot, selected: 'toggled' }}>
         {<Icon fontSize='small' {...iconProps} />}
      </ToggleButton>
   );
});

BaseToggleButton.muiName = ToggleButton.muiName;
export default BaseToggleButton;
