import React from 'react';
import { ButtonGroup, ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList, useTheme } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import BaseButton from '../Buttons//BaseButton';
import { useLocale } from '../../hooks';

export default function CustomSelect({
	variant = 'outlined',
	options,
	value,
	onChange,
	width,
	height,
	ref,
	closeOnSelect = true,
	disableClickAway = false,
	...rest
}) {
	const theme = useTheme();
	const { translate } = useLocale();
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef(null);

	const handleMenuItemClick = (option, e) => {
		onChange(option, () => setOpen(false), e);
		if (closeOnSelect) setOpen(false);
	};

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (disableClickAway) return;
		if (anchorRef.current && anchorRef.current.contains(event.target)) return;

		setOpen(false);
	};

	return (
		<>
			<ButtonGroup ref={anchorRef}>
				<BaseButton
					ref={ref}
					endIcon={<ArrowDropDownIcon />}
					name={value?.label}
					variant={variant}
					onClick={handleToggle}
					style={{
						minWidth: width,
						height: height,
						justifyContent: 'space-between',
						paddingRight: theme.spacing(3),
						paddingLeft: theme.spacing(3)
					}}
					aria-controls={open ? 'split-button-menu' : undefined}
					aria-expanded={open ? 'true' : undefined}
					aria-haspopup='menu'
					{...rest}>
					<div style={{ alignText: 'center', width: '100%', marginRight: theme.spacing(1) }}>
						{translate(value?.label)}
					</div>
				</BaseButton>
			</ButtonGroup>
			<Popper
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
				style={{ zIndex: 1501 }}>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
						}}>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList id='split-button-menu'>
									{options.map((option, index) => {
										return (
											<MenuItem
												key={index}
												data-at={option.label}
												onClick={(e) => handleMenuItemClick(option, e)}
												style={{ minWidth: width, height: height }}
												{...rest}>
												{translate(option.label)}
											</MenuItem>
										);
									})}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</>
	);
}
