import React from 'react';
import BaseIcon from './BaseIcon';

export default function ReadByTenantIcon(props) {
	return (
		<BaseIcon
			{...props}
			renderMultiPath={({ secondaryPathClass, secondaryPathColor }) => (
				<>
					<path d='m 8.8940678,4.2237806 c -0.194457,0.065885 -0.386519,0.141216 -0.576021,0.236141 -3.190548,1.59822 -3.882756,5.7685954 -1.379992,8.3086204 l 0.615605,0.626525 -0.738454,0.349435 c -0.406714,0.192662 -0.967197,0.50485 -1.24486,0.693409 -0.656952,0.446128 -1.667386,1.415311 -2.166219,2.077495 -1.035057,1.374001 -1.7567267,3.387469 -1.7567267,4.901637 v 0.470917 h 0.7097877 0.708424 l 0.04913,-0.701598 c 0.05983,-0.867659 0.288154,-1.702294 0.700235,-2.557969 0.367009,-0.762085 1.119561,-1.793506 1.661177,-2.276784 0.623326,-0.556185 1.765289,-1.254945 2.469245,-1.509665 2.4258432,-0.877772 4.9958142,-0.54359 7.0446522,0.9159 0.559995,0.39891 1.497494,1.354381 1.875479,1.909604 0.666805,0.979475 1.159171,2.3796 1.259875,3.587162 l 0.05187,0.63335 h 0.709792 0.70979 v -0.429968 c 0,-0.986338 -0.38809,-2.507499 -0.902252,-3.536658 -0.907067,-1.815615 -2.482994,-3.342959 -4.355644,-4.220511 l -0.653821,-0.307121 0.300296,-0.301659 a 3.4365655,3.4365655 0 0 1 -1.627053,-0.66338 c -0.344053,0.190389 -0.709888,0.334193 -1.086531,0.404034 -0.76618,0.142068 -1.4951731,0.02905 -2.3177322,-0.360355 -2.23492,-1.058023 -2.778659,-4.1004094 -1.05785,-5.9199084 0.296101,-0.313084 0.636735,-0.551303 0.997799,-0.737089 z' />
					<path
						className={secondaryPathClass}
						style={{ color: secondaryPathColor }}
						d='m 11.683326,2.1120396 c -0.817842,0 -1.5,0.680853 -1.5,1.498697 v 5.053386 c 0,0.817843 0.682158,1.4986974 1.5,1.4986974 h 1.500001 l -0.02083,1.459635 a 0.69893687,0.69893687 0 0 0 1.147135,0.546875 l 2.401042,-2.00651 h 4.143229 c 0.817843,0 1.498697,-0.6808544 1.498697,-1.4986974 v -5.053386 c 1e-6,-0.817844 -0.680858,-1.498697 -1.4987,-1.498697 z m 0,1.398437 h 9.170573 c 0.06768,0 0.10026,0.03258 0.10026,0.100261 v 5.053385 c 0,0.06768 -0.03259,0.10026 -0.10026,0.10026 h -4.394531 a 0.69893687,0.69893687 0 0 0 -0.447917,0.164063 l -1.427083,1.1940104 0.0079,-0.6497404 a 0.69893687,0.69893687 0 0 0 -0.700607,-0.708333 h -2.208334 c -0.06768,0 -0.101559,-0.03259 -0.101559,-0.10026 v -5.053386 c 0,-0.06768 0.03389,-0.10026 0.101558,-0.10026 z m 2.096355,1.649739 c -0.386242,3.76e-4 -0.698673,0.31428 -0.697918,0.700521 3.76e-4,0.385442 0.312476,0.697541 0.697918,0.697917 h 2.505207 c 0.386241,7.533e-4 0.700146,-0.311675 0.700521,-0.697917 8e-4,-0.387042 -0.313479,-0.701278 -0.700521,-0.700521 z'
					/>
				</>
			)}
		/>
	);
}
