import React from 'react';
import BaseIcon from './BaseIcon';

export default function PartialMatchIcon(props) {
	return (
		<BaseIcon {...props}>
			<path d='M 3.6171875 4.5117188 L 3.6171875 19.488281 L 7.3691406 19.488281 L 7.3691406 18.289062 L 4.8183594 18.289062 L 4.8183594 5.7109375 L 7.3691406 5.7109375 L 7.3691406 4.5117188 L 3.6171875 4.5117188 z M 16.630859 4.5117188 L 16.630859 5.7109375 L 19.181641 5.7109375 L 19.181641 18.289062 L 16.630859 18.289062 L 16.630859 19.488281 L 20.382812 19.488281 L 20.382812 4.5117188 L 16.630859 4.5117188 z M 9.328125 13.509766 C 8.8718345 13.510085 8.5022726 13.881601 8.5019531 14.337891 C 8.5022722 14.794182 8.8718343 15.163743 9.328125 15.164062 C 9.7849778 15.164537 10.15593 14.794744 10.15625 14.337891 C 10.15593 13.881039 9.7849776 13.509291 9.328125 13.509766 z M 12.207031 13.509766 C 11.750178 13.509291 11.379227 13.881039 11.378906 14.337891 C 11.379226 14.794744 11.750178 15.164537 12.207031 15.164062 C 12.663322 15.163744 13.032884 14.794182 13.033203 14.337891 C 13.032884 13.881601 12.663321 13.510085 12.207031 13.509766 z M 14.828125 13.509766 C 14.371272 13.509291 14.000321 13.881039 14 14.337891 C 14.00032 14.794744 14.371272 15.164537 14.828125 15.164062 C 15.284416 15.163744 15.653977 14.794182 15.654297 14.337891 C 15.653977 13.881601 15.284415 13.510085 14.828125 13.509766 z' />
		</BaseIcon>
	);
}
