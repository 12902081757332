import { useState, useEffect, useMemo } from 'react';
import { Autocomplete, TextField, Typography } from '@mui/material';
import { useLocale, useIsMount } from '../../hooks';
import { filterOperators } from '../../constants';
import EventBus from '../../helpers/EventBus';

const FiltersControlComboMulti = ({
	slice,
	member,
	label,
	stateSlice,
	options,
	onChange,
	onClearMemberAll,
	defaultValue = [],
	disabled = false,
	additionalInputProps = {}
}) => {
	const isMount = useIsMount();
	const { translate } = useLocale();
	const [value, setValue] = useState(defaultValue ?? []);

	const defaultautoCompleteValue = useMemo(() => {
		return defaultValue.map((fv) => {
			const state = options.find((msa) => msa.value === fv);
			return { text: state.text, value: state.value };
		});
	}, [defaultValue]);

	const autoCompleteValue = useMemo(() => {
		return value.map((fv) => {
			const state = options.find((msa) => msa.value === fv);
			return { text: state.text, value: state.value };
		});
	}, [value]);

	const handlerClearValue = () => setValue([]);

	const handleClearFilter = () => onClearMemberAll(member.name);

	const handleSetFilter = () => {
		onChange(
			[
				{
					member,
					value: value,
					operator: filterOperators.collectionContain
				}
			],
			member.name
		);
	};

	useEffect(() => {
		if (isMount) {
			if (defaultValue.length > 0)
				setTimeout(() => {
					EventBus.dispatch(EventBus.messages.FilteringChanged, { slice, member });
				}, 100);
			return;
		}
		if (value.length === 0) {
			handleClearFilter();
			return;
		}
		handleSetFilter();
	}, [value]);

	useEffect(() => {
		if (isMount) return;
		if (stateSlice == null || (stateSlice && Object.keys(stateSlice).length === 0) || !stateSlice[member.name]) {
			handlerClearValue();
		}
	}, [stateSlice]);

	return (
		<Autocomplete
			multiple
			options={options}
			getOptionLabel={(option) => translate(option.text)}
			isOptionEqualToValue={(option, value) => option.value === value.value}
			value={defaultValue.length > 0 ? defaultautoCompleteValue : autoCompleteValue}
			defaultValue={defaultautoCompleteValue}
			size='small'
			noOptionsText={translate('Common_NoResults')}
			onChange={(_, selected) => {
				setValue(selected.map((s) => s.value));
			}}
			disabled={disabled}
			renderInput={(params) => (
				<TextField
					{...params}
					label={translate(label)}
					variant='standard'
					disabled={disabled}
					{...additionalInputProps}
				/>
			)}
			renderOption={(props, option) => {
				return (
					<Typography
						{...props}
						key={props.id}
						component='li'
						color='primaryText'
						data-at={option.value}
						variant='body2'>
						{translate(option.text)}
					</Typography>
				);
			}}
		/>
	);
};

export default FiltersControlComboMulti;
