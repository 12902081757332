export default {
	Tenant: {
		value: 1,
		name: 'Tenant'
	},
	Inkasent: {
		value: 2,
		name: 'Inkasent'
	},
	StartMeasurementSetRead: {
		value: 3,
		name: 'StartMeasurementSetRead'
	},
	EndMeasurementSetRead: {
		value: 4,
		name: 'EndMeasurementSetRead'
	},
	Import: {
		value: 5,
		name: 'Import'
	}
};
