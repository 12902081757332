import Resizer from 'react-image-file-resizer';

const resizeAsync = ({
	file,
	format = 'PNG',
	maxWidth = 300,
	maxHeight = 300,
	outputType = 'blob',
	minWidth = null,
	minHeight = null
}) =>
	new Promise((resolve) => {
		Resizer.imageFileResizer(
			file,
			maxWidth,
			maxHeight,
			format,
			100,
			0,
			(uri) => {
				resolve(uri);
			},
			outputType,
			minWidth,
			minHeight
		);
	});

const resize = ({
	file,
	format = 'PNG',
	maxWidth = 300,
	maxHeight = 300,
	outputType = 'blob',
	callback,
	minWidth = null,
	minHeight = null
}) =>
	Resizer.imageFileResizer(
		file,
		maxWidth,
		maxHeight,
		format,
		100,
		0,
		(uri) => {
			callback(uri);
		},
		outputType,
		minWidth,
		minHeight
	);

const ImageResize = {
	resizeAsync,
	resize
};

export default ImageResize;
