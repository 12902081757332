import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import CalendarData from './CalendarData';
import LegendItem from './LegendItem';

const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		marginTop: 10
	}
}));

const Legend = () => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<LegendItem {...CalendarData.FirstHourWorkMode} />
			<LegendItem {...CalendarData.SecondHourWorkMode} />
			<LegendItem {...CalendarData.DayOfWeekWorkMode} />
			<LegendItem {...CalendarData.DayOfMonthWorkMode} />
			<LegendItem {...CalendarData.MonthWorkMode} />
		</div>
	);
};

export default Legend;
