import { createSlice } from '@reduxjs/toolkit';
import { fetchWatermeterType, addWatermeterType, editWatermeterType, deleteWatermeterType } from './watermeterTypeApi';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
const slice = 'watermeterType';
const getWatermeterType = (getState) => getState().watermeterType.watermeterType;

const initialWatermeterData = {
	id: null,
	name: '',
	isCoupled: false
};

export const watermeterTypeSlice = createSlice({
	name: slice,
	initialState: {
		addPending: false,
		addSuccess: false,
		fetchPending: false,
		fetchSuccess: false,
		deletePending: false,
		deleteSuccess: false,
		watermeterType: initialWatermeterData
	},
	reducers: {
		fetchWatermeterTypeSucces: (state, action) => {
			state.watermeterType = action.payload;
			state.fetchPending = false;
			state.fetchSuccess = true;
		},
		fetchWatermeterTypePending: (state) => {
			state.fetchPending = true;
			state.fetchSuccess = false;
		},
		addWatermeterTypeSucces: (state, action) => {
			state.addPending = false;
			state.addSuccess = action.payload;
		},
		addWatermeterTypePending: (state, action) => {
			state.addPending = action.payload ?? true;
		},
		deleteWatermeterTypeSucces: (state) => {
			state.deletePending = false;
			state.deleteSuccess = true;
		},
		deleteWatermeterTypePending: (state, action) => {
			state.deletePending = action.payload ?? true;
		},
		setWatermeterTypeData: (state, action) => {
			state.watermeterType = action.payload;
		},
		resetWatermeterTypeData: (state, action) => {
			const { isInSubmitManyMode } = action.payload || {};

			state.watermeterType = isInSubmitManyMode
				? {
						...initialWatermeterData,
						name: state.watermeterType.name
				  }
				: initialWatermeterData;
			state.addSuccess = false;
			state.deleteSuccess = false;
			state.fetchSuccess = false;
		},
		resetAddSuccess: (state) => {
			state.addSuccess = false;
		}
	}
});

export const {
	fetchWatermeterTypeSucces,
	fetchWatermeterTypePending,
	addWatermeterTypeSucces,
	addWatermeterTypePending,
	deleteWatermeterTypeSucces,
	deleteWatermeterTypePending,
	setWatermeterTypeData,
	resetWatermeterTypeData,
	resetAddSuccess
} = watermeterTypeSlice.actions;

export const fetchWatermeterTypeAsync = (id) => async (dispatch) => {
	let response;

	try {
		dispatch(fetchWatermeterTypePending());
		response = await fetchWatermeterType(id);
	} catch (error) {
		dispatch(fetchWatermeterTypePending(false));
		dispatch(addErrorAsync({ slice, error }));
		return;
	}

	dispatch(fetchWatermeterTypeSucces(response));
};

export const addWatermeterTypeAsync = () => async (dispatch, getState) => {
	const watermeterType = { ...getWatermeterType(getState) };
	let response;
	let result = Object.assign({}, watermeterType);

	try {
		dispatch(addWatermeterTypePending());

		response = await addWatermeterType(watermeterType);
	} catch (error) {
		dispatch(addWatermeterTypePending(false));

		const errorResult = await dispatch(addErrorAsync({ slice, error, skipNotificationMembers: ['name'] }));

		return errorResult;
	}

	result.id = response.id;

	dispatch(addWatermeterTypeSucces(response.id ? result : true));
};

export const editWatermeterTypeAsync = () => async (dispatch, getState) => {
	const watermeterType = getWatermeterType(getState);

	try {
		dispatch(addWatermeterTypePending());
		await editWatermeterType(watermeterType);
	} catch (error) {
		dispatch(addWatermeterTypePending(false));

		const errorResult = await dispatch(addErrorAsync({ slice, error, skipNotificationMembers: ['name'] }));

		return errorResult;
	}

	dispatch(addWatermeterTypeSucces(true));
};

export const deleteWatermeterTypeAsync = (id) => async (dispatch) => {
	try {
		dispatch(deleteWatermeterTypePending());
		await deleteWatermeterType(id);
	} catch (error) {
		dispatch(deleteWatermeterTypePending(false));
		const errorResult = await dispatch(addErrorAsync({ slice, error }));

		return errorResult;
	}

	dispatch(deleteWatermeterTypeSucces());
};

export const selectWatermeterType = (state) => {
	return {
		dataRow: state.watermeterType.watermeterType,
		fetchPending: state.watermeterType.fetchPending,
		fetchSuccess: state.watermeterType.fetchSuccess
	};
};

export const selectWatermeterTypeAdd = (state) => {
	return {
		addPending: state.watermeterType.addPending,
		addSuccess: state.watermeterType.addSuccess
	};
};

export const selectDeleteWatermeterTypePending = (state) => {
	return {
		deletePending: state.watermeterType.deletePending,
		deleteSuccess: state.watermeterType.deleteSuccess
	};
};

export default watermeterTypeSlice.reducer;
