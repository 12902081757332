import { useSelector, useDispatch } from 'react-redux';
import { selectPendingError, closeError, addErrorAsync } from './errorsSlice';

export default () => {
	const dispatch = useDispatch();
	const { pending, error } = useSelector(selectPendingError);

	const clearPendingError = () => {
		if (pending) dispatch(closeError());
	};

	const setApplicationError = (slice, message) => {
		dispatch(addErrorAsync({ slice, error: { message } }));
	};

	const setUserError = (slice, message) => {
		dispatch(addErrorAsync({ slice, error: { message, code: 'USER' } }));
	};

	return { clearPendingError, setApplicationError, setUserError, pending, error };
};
