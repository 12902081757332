import React from 'react';
import BaseIcon from './BaseIcon';

export default function SubmitManyIcon(props) {
	return (
		<BaseIcon {...props}>
			<path d='M 1.9999997,2.8681636 V 4.8681635 H 22 V 2.8681636 Z m 0,5.1347658 V 10.002929 H 11.453125 V 8.0029294 Z m 12.4355473,0 V 13.569335 H 8.8691403 v 2 h 5.5664067 v 5.562501 h 2.000001 V 15.569335 H 22 v -2 H 16.435548 V 8.0029294 Z M 1.9999997,13.567382 v 2 h 3.8749996 v -2 z' />
		</BaseIcon>
	);
}
