import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { setupLocales } from '@sonar-web/common/src/features/Locales/localesSlice';
import { selectSupportedLanguages } from '@sonar-web/common/src/appSlice';
import { useAuth } from 'react-oidc-context';

const defaultLocale = 'pl';

const TranslationsLoader = ({ children }) => {
	const dispatch = useDispatch();
	const auth = useAuth();
	const tokenLocale = auth.user?.profile?.locale;
	const supportedLanguages = useSelector(selectSupportedLanguages);
	const localeName = supportedLanguages.includes(tokenLocale) ? tokenLocale : defaultLocale;

	useEffect(() => {
		loadDateLocale(localeName);

		import(`../../../public/languages/${localeName}.json`).then((data) => {
			dispatch(setupLocales(localeName, data.default));
		});
	}, [dispatch, localeName]);

	return children;
};

export default TranslationsLoader;

async function loadDateLocale(locale) {
	if (locale !== 'en') await import(`moment/locale/${locale}`);

	moment.locale(locale);
}
