import moment from 'moment';

const TimeRange = {
	Weeks_1: {
		name: 'Weeks_1',
		nameShort: 'Weeks_1-short',
		nameLong: 'Weeks_1-long',
		value: (toDate) => moment(moment(toDate).subtract(1, 'week').startOf('day'))
	},
	Months_1: {
		name: 'Months_1',
		nameShort: 'Months_1-short',
		nameLong: 'Months_1-long',
		value: (toDate) => moment(moment(toDate).subtract(1, 'month').startOf('day'))
	},
	Months_2: {
		name: 'Months_2',
		nameShort: 'Months_2-short',
		nameLong: 'Months_2-long',
		value: (toDate) => moment(moment(toDate).subtract(2, 'month').startOf('day'))
	},
	Months_3: {
		name: 'Months_3',
		nameShort: 'Months_3-short',
		nameLong: 'Months_3-long',
		value: (toDate) => moment(moment(toDate).subtract(3, 'month').startOf('day'))
	},
	FullMonths_3: {
		name: 'FullMonths_3',
		nameShort: 'Months_3-short',
		nameLong: 'Months_3-long',
		value: (toDate) => moment(moment(toDate).subtract(2, 'month').startOf('month'))
	},
	Months_6: {
		name: 'Months_6',
		nameShort: 'Months_6-short',
		nameLong: 'Months_6-long',
		value: (toDate) => moment(moment(toDate).subtract(6, 'month').startOf('day'))
	},
	FullMonths_6: {
		name: 'FullMonths_6',
		nameShort: 'Months_6-short',
		nameLong: 'Months_6-long',
		value: (toDate) => moment(moment(toDate).subtract(5, 'month').startOf('month'))
	},
	Years_1: {
		name: 'FullYears_1',
		nameShort: 'Years_1-short',
		nameLong: 'Years_1-long',
		value: (toDate) => moment(moment(toDate).subtract(1, 'year').startOf('day'))
	},
	FullYears_1: {
		name: 'FullYears_1',
		nameShort: 'Years_1-short',
		nameLong: 'Years_1-long',
		value: (toDate) => moment(moment(toDate).subtract(11, 'month').startOf('month'))
	},
	FullHistory: {
		name: 'FullHistory',
		nameShort: 'FullHistory-short',
		nameLong: 'FullHistory-long',
		value: () => moment(0).startOf('day')
	}
};

export default TimeRange;
