import { createSlice } from '@reduxjs/toolkit';
import { pageDescriptor, guidEmpty, getSliceDescriptor } from '@sonar-web/common';
import { fetchLocationNodes } from './api';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';

const slice = 'locationNodesMove';
const defaultSorters = [
	{ Member: 'locationNodeLevelType', ListSortDirection: 1 },
	{ Member: 'displayNameToSort', ListSortDirection: 1 }
];

export const locationNodesMoveSliceInitialState = {
	pageDescriptor,
	pending: false,
	totalCount: 0,
	hasMore: true,
	reset: false,
	locationNodes: [],
	selectedNodeToMoveTo: null,
	breadcrumbs: [{ locationNodeLevelId: null, id: guidEmpty }],
	selectedBreadcrumbIndex: 0,
	selectedBreadcrumb: { locationNodeLevelId: null, id: guidEmpty }
};

export const locationNodesMoveSlice = createSlice({
	name: slice,
	initialState: locationNodesMoveSliceInitialState,
	reducers: {
		fetchLocationNodesSucces: (state, action) => {
			const { response, levels } = action.payload;
			const { elements, totalCount } = response;

			elements.forEach((e) => {
				e.level = levels.filter((l) => l.id === e.locationNodeLevelId)[0];
			});

			state.locationNodes = state.pageDescriptor.Offset === 0 ? elements : [...state.locationNodes, ...elements];
			state.totalCount = totalCount;
			state.pending = false;
			state.reset = false;
			state.hasMore = totalCount > state.pageDescriptor.Offset + state.pageDescriptor.Limit;
		},
		fetchLocationNodesPending: (state, action) => {
			state.pending = action.payload;
		},
		setResetAndReload: (state,action) => {
			state.reset = action.payload ?? true;
			state.pageDescriptor.Offset = 0;
		},
		setOffset: (state, action) => {
			state.pageDescriptor.Offset = action.payload ? state.pageDescriptor.Offset + state.pageDescriptor.Limit : 0;
		},
		setSelectedNodeToMoveTo: (state, action) => {
			if (action.payload === null) state.selectedNodeToMoveTo = null;
			else {
				state.selectedNodeToMoveTo =
					state.selectedNodeToMoveTo === null
						? action.payload
						: state.selectedNodeToMoveTo.id === action.payload.id
						? null
						: action.payload;
			}
		},
		addBreadcrumb: (state, action) => {
			state.breadcrumbs = Array.isArray(action.payload)
				? [...state.breadcrumbs, ...action.payload]
				: [...state.breadcrumbs, action.payload];
			state.selectedBreadcrumbIndex = state.breadcrumbs.length - 1;
			state.selectedBreadcrumb = state.breadcrumbs[state.selectedBreadcrumbIndex];
		},
		resetToBreadcrumb: (state, action) => {
			const index = action.payload;
			state.breadcrumbs = state.breadcrumbs.slice(0, index + 1);
			state.selectedBreadcrumb = state.breadcrumbs[index];
			state.selectedBreadcrumbIndex = index;
		},
		setSelectedBreadcrumbIndex: (state, action) => {
			state.selectedBreadcrumbIndex = action.payload;
		},
		deleteLocationNodeFromList: (state, action) => {
			const filtered = state.locationNodes.filter((ln) => ln.id !== action.payload);

			state.locationNodes = filtered;
		}
	}
});

export const {
	fetchLocationNodesSucces,
	fetchLocationNodesPending,
	setResetAndReload,
	setSelectedNodeToMoveTo,
	setOffset,
	addBreadcrumb,
	resetToBreadcrumb,
	setSelectedBreadcrumbIndex,
	deleteLocationNodeFromList
} = locationNodesMoveSlice.actions;

export const fetchLocationNodesAsync = () => async (dispatch, getState) => {
	try {
		const pd = getSliceDescriptor(getState, slice, null, defaultSorters);

		dispatch(fetchLocationNodesPending(true));
		const response = await fetchLocationNodes(pd);

		const levels = getState().locationNodeLevels.locationNodeLevels;
		dispatch(fetchLocationNodesSucces({ response, levels }));
	} catch (error) {
		dispatch(fetchLocationNodesPending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const selectLocationNodes = (state) => {
	return {
		dataRows: state.locationNodesMove.locationNodes,
		pending: state.locationNodesMove.pending,
		hasMore: state.locationNodesMove.hasMore,
		reset: state.locationNodesMove.reset
	};
};

export const selectSelectedNodeToMoveTo = (state) => state.locationNodesMove.selectedNodeToMoveTo;
export const selectSelectedBreadcrumb = (state) => state.locationNodesMove.selectedBreadcrumb;
export const selectBreadcrumbs = (state) => state.locationNodesMove.breadcrumbs;
export const selectSelectedBreadcrumbIndex = (state) => state.locationNodesMove.selectedBreadcrumbIndex;

export default locationNodesMoveSlice.reducer;
