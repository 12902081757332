import { apiBase } from '@sonar-web/common';
import { REST_API_PATHS } from '@sonar-web/common/src/constants/system';

const path = REST_API_PATHS.ORDER;

export async function fetchTasks(pageDescriptor) {
	const { data } = await apiBase(`Specifications/lite`, 'get', {
		path,
		params: { pageDescriptor }
	});

	return { ...data, elements: data.specifications };
}

export async function fetchTasksDetails({ pageDescriptor }) {
	const { data } = await apiBase(`Specifications`, 'get', {
		path,
		params: { pageDescriptor }
	});

	return { ...data, elements: data.specifications };
}

export async function fetchTask(id) {
	const { data } = await apiBase(`Specification/${id}`, 'get', {
		path
	});

	return data;
}

export async function fetchTaskLite(id) {
	const { data } = await apiBase(`Specifications/lite`, 'get', {
		path,
		params: {
			pageDescriptor: {
				FilterDescriptors: [{ member: 'id', value: [id], filterType: 5, filterOperator: 16, replace: true }],
				SortDescriptors: [],
				IncludeTotalRowCount: true,
				Limit: 20,
				Offset: 0
			}
		}
	});

	return data.specifications?.[0];
}

export async function addTask(task) {
	const response = await apiBase(`Specification`, 'post', {
		data: task,
		path
	});

	const locationHeader = response.headers.location;

	if (locationHeader) {
		return {
			...task,
			id: locationHeader.slice(locationHeader.lastIndexOf('/') + 1)
		};
	}

	return response.data;
}

export async function editTask(task) {
	const response = await apiBase(`Specification`, 'put', {
		data: task,
		path
	});

	const locationHeader = response.headers.location;

	if (locationHeader)
		return {
			url: locationHeader,
			id: locationHeader.slice(locationHeader.lastIndexOf('/') + 1)
		};

	return response.data;
}

export async function deleteTask(taskId) {
	const { data } = await apiBase(`Specification/${taskId}`, 'delete', {
		path
	});

	return data;
}

export async function fetchDeviceTypeGroups(pageDescriptor) {
	const { data } = await apiBase(`DeviceTypeGroups`, 'get', {
		path,
		params: { pageDescriptor }
	});

	return sortDeviceTypes(data.deviceTypeGroups);
}

function sortDeviceTypes(deviceTypeGroups) {
	if (!deviceTypeGroups || deviceTypeGroups.length === 0) return deviceTypeGroups;

	const sortedGroups = deviceTypeGroups.slice().sort((a, b) => a.name.localeCompare(b.name));

	for (const sg of sortedGroups) {
		if (!sg.deviceTypes || sg.deviceTypes.length === 0) continue;
		sg.deviceTypes.sort((a, b) => a.name.localeCompare(b.name));
	}

	return sortedGroups;
}
