import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Box, TextField } from '@mui/material';
import { useLocale } from '../../../../hooks';
import { FormTopActionsToolbar } from '../../../../components';
import { addProfileAsync, editProfileAsync, selectProfileSubmit } from './deviceConfigProfilesSlice';
import { guidEmpty } from '../../../../helpers';
import { ERROR_TYPES } from '../../../../constants';
import useConfigurator from '../useConfigurator';

const validationSchema = Yup.object().shape({
	name: Yup.string()
		.min(3, 'Common_NameValidationMin')
		.max(50, 'Common_NameValidationMax')
		.required('Common_ValidationRequired')
		.matches(/^[^\s].+[^\s]$/, 'Common_NameValidationWhitespaces'),
	description: Yup.string().min(3, 'Common_DescriptionValidationMin').max(100, 'Common_DescriptionValidationMax')
});

const ProfileSubmit = ({ api }) => {
	const dispatch = useDispatch();
	const { translate } = useLocale();
	const nameInput = useRef(null);
	const formikRef = useRef();
	const { toggleProfileSubmitMode, isInProfileSubmitMode, getProfileSubmitData, updateProfileData } =
		useConfigurator();
	const { name, description, ...rest } = getProfileSubmitData();
	const { pending, success } = useSelector(selectProfileSubmit);

	const isNewProfile = isInProfileSubmitMode === 'new';
	const initialValues = isNewProfile ? { name: '', description: '' } : { name, description };

	const handleApiErrors = (err, formikActions) => {
		formikActions.setSubmitting(false);

		if (err && err.type === ERROR_TYPES.api) {
			formikActions.setFieldTouched(err.member.uncapitalize(), true);
			setTimeout(() => formikActions.setFieldError(err.member.uncapitalize(), err.message), 100);
		}
	};

	const handleOnSubmit = (values, formikActions) => {
		const profile = { ...rest, ...values };
		const action = isNewProfile ? addProfileAsync : editProfileAsync;

		if (isNewProfile) profile.id = guidEmpty;

		dispatch(action({ api, profile })).then((res) => {
			if (res?.code) handleApiErrors(res, formikActions);

			if (res) {
				toggleProfileSubmitMode(false);
				if (isNewProfile) updateProfileData({ ...profile, id: res.id, isSystemProfile: false }, true);
				else updateProfileData({ ...profile, isSystemProfile: false });
			}
		});
	};

	const onCancel = () => toggleProfileSubmitMode(false);

	useEffect(() => {
		if (!nameInput.current) return;
		nameInput.current.focus();
		formikRef.current?.setFieldTouched('name', false);
	}, [nameInput.current, formikRef.current]);

	return (
		<Box width={500} margin='0 auto'>
			<Formik
				initialValues={initialValues}
				enableReinitialize
				validationSchema={validationSchema}
				onSubmit={handleOnSubmit}>
				{(formik) => {
					const { handleSubmit, handleChange, handleBlur, values, errors, touched } = formik;
					if (!formikRef.current) formikRef.current = formik;

					return (
						<form onSubmit={handleSubmit}>
							<FormTopActionsToolbar
								title={isNewProfile ? 'Common_AddProfile' : 'Common_EditProfile'}
								handleCancel={onCancel}
								handleSubmit={handleSubmit}
								pending={pending}
								success={success}
								disableSubmitMany={true}
							/>
							<TextField
								autoFocus
								margin='normal'
								name='name'
								label={translate('Common_Name')}
								type='text'
								size='small'
								fullWidth
								inputRef={nameInput}
								value={values.name}
								onChange={handleChange}
								onBlur={handleBlur}
								error={errors.name && touched.name}
								helperText={errors.name && touched.name && translate(errors.name)}
							/>
							<TextField
								margin='normal'
								name='description'
								label={translate('Common_Description')}
								type='text'
								size='small'
								fullWidth
								value={values.description}
								onChange={handleChange}
								onBlur={handleBlur}
								error={errors.description && touched.description}
								helperText={errors.description && touched.description && translate(errors.description)}
							/>
						</form>
					);
				}}
			</Formik>
		</Box>
	);
};

export default ProfileSubmit;
