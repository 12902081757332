import { DateHelpers } from '@sonar-web/common';

const getLastDayRange = (currentDate) => {
	const previousDay = DateHelpers.addDays(currentDate, -1);

	return [DateHelpers.getBeginingOfDay(previousDay), DateHelpers.getEndOfDay(previousDay)];
};

const getCurrentDayRange = (currentDate) => {
	return [DateHelpers.getBeginingOfDay(currentDate), currentDate];
};

const getLastFullWeekRange = (currentDate) => {
	return [DateHelpers.getLastWeekStart(currentDate), DateHelpers.getLastWeekEnd(currentDate)];
};

const getLast30DaysRange = (currentDate) => {
	return [
		DateHelpers.getBeginingOfDay(DateHelpers.addDays(currentDate, -30)),
		DateHelpers.getEndOfDay(DateHelpers.addDays(currentDate, -1))
	];
};

const getLastMonthRange = (currentDate) => {
	return [DateHelpers.getLastMonthStart(currentDate), DateHelpers.getLastMonthEnd(currentDate)];
};

const DatePeriodRangeHelper = {
	getLastDayRange,
	getCurrentDayRange,
	getLastFullWeekRange,
	getLast30DaysRange,
	getLastMonthRange
};

export default DatePeriodRangeHelper;
