import { createSlice } from '@reduxjs/toolkit';

const slice = 'app';
export const appSlice = createSlice({
	name: slice,
	initialState: {
		mainMenu: false,
		systemSearch: {
			state: false
		},
		objectSucessfullyDeleted: null,
		supportedLanguages: ['pl', 'en'],
		currentUser: null
	},
	reducers: {
		setMainMenu: (state, action) => {
			state.mainMenu = action.payload;
		},
		setSystemSearch: (state, action) => {
			state.systemSearch.state = action.payload;
		},
		setObjectSucessfullyDeleted: (state, action) => {
			state.objectSucessfullyDeleted = action.payload;
		},
		resetObjectSucessfullyDeleted: (state) => {
			state.objectSucessfullyDeleted = null;
		},
		setCurrentUser: (state, { payload }) => {
			state.currentUser = state.currentUser ? { ...state.currentUser, ...payload } : payload;
		}
	}
});

export const {
	setMainMenu,
	setSystemSearch,
	setObjectSucessfullyDeleted,
	resetObjectSucessfullyDeleted,
	setCurrentUser
} = appSlice.actions;

export const selectMainMenu = (state) => state.app.mainMenu;
export const selectSystemSearch = (state) => state.app.systemSearch.state;
export const selectObjectSucessfullyDeleted = (state) => state.app.objectSucessfullyDeleted;
export const selectSupportedLanguages = (state) => state.app.supportedLanguages;
export const selectCurrentUser = (state) => state.app.currentUser;

export default appSlice.reducer;
