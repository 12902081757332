import apiBase from '../../api/apiBase';
import { REST_API_PATHS } from '../../constants';
import { addErrorAsync } from '../Errors/errorsSlice';
import { pageDescriptor, getSliceDescriptor } from '@sonar-web/common';
import { createSelector, createSlice } from '@reduxjs/toolkit';

const slice = 'systemSearch';

export const initialState = {
	pageDescriptor,
	state: false,
	success: false,
	includeTotalRowCount: true,
	pending: false,
	totalCount: 0,
	hasMore: true,
	reset: false,
	resetSearchValue: false,
	lastSearchValue: '',
	elements: []
};

export const systemSearchSlice = createSlice({
	name: slice,
	initialState,
	reducers: {
		searchSuccess: (state, action) => {
			const { elements, totalCount } = action.payload;

			state.elements = state.pageDescriptor.Offset === 0 ? elements : [...state.elements, ...elements];
			state.totalCount = totalCount;
			state.pending = false;
			state.success = false;
			state.reset = false;
			state.hasMore = totalCount > state.pageDescriptor.Offset + state.pageDescriptor.Limit;
		},
		searchPending: (state, action) => {
			state.pending = action.payload;
		},
		setOffset: (state, action) => {
			state.pageDescriptor.Offset = action.payload ? state.pageDescriptor.Offset + state.pageDescriptor.Limit : 0;
		},
		resetSearch: (state) => {
			state.elements = [];
			state.totalCount = 0;
		},
		setResetSearchValue: (state, { payload }) => {
			state.resetSearchValue = payload;
			if (payload === true) state.lastSearchValue = '';
		},
		setLastSearchValue: (state, { payload }) => {
			state.lastSearchValue = payload;
		}
	}
});

export const { searchSuccess, searchPending, setOffset, resetSearch, setResetSearchValue, setLastSearchValue } =
	systemSearchSlice.actions;

//CALLS
export const fetchSearch = (cancellationToken) => async (dispatch, getState) => {
	try {
		dispatch(searchPending(true));

		const pd = getSliceDescriptor(getState, slice, null, [{ Member: 'tagLength', ListSortDirection: 1 }]);
		const response = await fetchSearchResults(pd, { cancelToken: cancellationToken.token });

		dispatch(searchSuccess(response));
	} catch (error) {
		if (error?.message === 'Axios_CancelRequest') return;
		dispatch(searchPending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

//SELECTORS

export const selectSearch = createSelector(
	(state) => state.systemSearch,
	(systemSearch) => ({
		elements: systemSearch.elements,
		pending: systemSearch.pending,
		success: systemSearch.success,
		totalCount: systemSearch.totalCount,
		hasMore: systemSearch.hasMore,
		reset: systemSearch.reset
	})
);

export const selectLastSearchValue = (state) => state.systemSearch.lastSearchValue;

//API
const path = REST_API_PATHS.SEARCH;
async function fetchSearchResults(pd, config) {
	const { data } = await apiBase(`documents`, 'get', {
		path,
		params: { pageDescriptor: pd },
		...config
	});

	return data;
}

export default systemSearchSlice.reducer;
