import React from 'react';
import BaseIcon from './BaseIcon';

export default function HeatAllocatorMountedIcon(props) {
	return (
		<BaseIcon
			{...props}
			renderMultiPath={({ primaryPathClass, primaryPathColor, secondaryPathClass, secondaryPathColor }) => (
				<>
					<path
						className={primaryPathClass}
						style={{ color: primaryPathColor }}
						d='m 6.8576656,2.0447412 c -1.9957946,0 -3.6302079,1.6344066 -3.6302079,3.6302079 V 18.109845 c 0,1.995805 1.6344133,3.625 3.6302079,3.625 H 10.815999 A 6.4014089,6.4014089 0 0 1 10.052978,20.182762 H 6.8576656 c -1.1615746,0 -2.0755213,-0.911333 -2.0755213,-2.072917 V 5.6749491 c 0,-1.1615813 0.9139467,-2.07552 2.0755213,-2.07552 h 8.2890624 c 1.161576,0 2.070312,0.9139373 2.070312,2.07552 v 4.9348959 a 5.5979279,5.5979279 0 0 1 1.554688,0.36198 V 5.6749491 c 0,-1.9958013 -1.629205,-3.6302079 -3.625,-3.6302079 z m 2.070312,5.7031252 A 1.0362293,1.0362293 0 0 0 7.8915203,8.784325 1.0362293,1.0362293 0 0 0 8.9279776,9.8181783 H 13.073811 A 1.0362293,1.0362293 0 0 0 14.107665,8.784325 1.0362293,1.0362293 0 0 0 13.073811,7.7478664 Z'
					/>
					<path
						className={secondaryPathClass}
						style={{ color: secondaryPathColor }}
						d='m 16.126709,12.663591 c -2.566305,0 -4.645833,2.07953 -4.645833,4.645834 0,2.566305 2.079528,4.645833 4.645833,4.645833 2.566306,0 4.645834,-2.079527 4.645834,-4.645833 0,-2.566306 -2.079528,-4.645834 -4.645834,-4.645834 z m 1.979167,2.536459 a 0.66666501,0.66666501 0 0 1 0.440104,0.161459 0.66666501,0.66666501 0 0 1 0.0651,0.942708 l -2.526041,2.888021 a 0.66673166,0.66673166 0 0 1 -0.994792,0.01302 l -1.434896,-1.557291 a 0.66666501,0.66666501 0 0 1 0.03906,-0.942709 0.66666501,0.66666501 0 0 1 0.942708,0.03906 l 0.932292,1.010417 2.036459,-2.330729 a 0.66666501,0.66666501 0 0 1 0.5,-0.223959 z'
					/>
				</>
			)}
		/>
	);
}
