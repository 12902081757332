import React from 'react';
import BaseIcon from './BaseIcon';

export default function BuildingIcon(props) {
	return (
		<BaseIcon {...props}>
			<path d='m 6.0039833,3.5129248 c -1.370522,0 -2.492354,1.11925 -2.492354,2.48977 V 17.997305 c 0,1.37053 1.121832,2.48977 2.492354,2.48977 H 17.996016 c 1.370523,0 2.492354,-1.11924 2.492354,-2.48977 V 6.0026948 c 0,-1.37052 -1.121831,-2.48977 -2.492354,-2.48977 z m 0,1.09915 H 17.996016 c 0.78015,0 1.390613,0.61047 1.390613,1.39062 V 17.997305 c 0,0.78015 -0.610463,1.39062 -1.390613,1.39062 h -2.784843 v -6.40426 a 0.55005501,0.55005501 0 0 0 -0.156579,-0.38293 L 10.175822,7.5731348 a 0.55005501,0.55005501 0 0 0 -0.7617107,-0.0274 L 4.6133703,11.851925 V 6.0026748 c 0,-0.78015 0.610463,-1.39062 1.390613,-1.39062 z m 3.752226,4.1057 4.3532227,4.4881002 v 6.18205 H 9.9102053 v -0.11731 -4.49016 -0.55087 h -1.099675 v 0.55087 4.49016 0.11731 h -2.806547 c -0.78015,0 -1.390613,-0.61047 -1.390613,-1.39062 v -4.66638 l 0.07803,-0.0703 z' />
		</BaseIcon>
	);
}
