import React from 'react';
import { FormControl, InputLabel, Select } from '@mui/material';
import { useLocale } from '@sonar-web/common/src/hooks';
import BaseSelectMenuItem from '@sonar-web/common/src/components/BaseSelectMenuItem/BaseSelectMenuItem';
import { yearFormats } from './constants';

const name = 'yearFormat';
const SettingsYearFormat = ({ updateSettings, current }) => {
	const { translate } = useLocale();

	const options = [
		{ value: yearFormats.full, label: `${translate('Common_YearFormatFull')} (2018)` },
		{ value: yearFormats.short, label: `${translate('Common_YearFormatShort')} (18)` }
	];

	const handleChange = (e) => {
		updateSettings(name, e.target.value);
	};

	return (
		<FormControl fullWidth margin='normal'>
			<InputLabel id={name}>{translate('Common_YearFormat')}</InputLabel>
			<Select labelId={name} value={current[name]} onChange={handleChange}>
				{options.map((opt, index) => (
					<BaseSelectMenuItem key={index} value={opt.value}>
						{translate(opt.label)}
					</BaseSelectMenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default SettingsYearFormat;
