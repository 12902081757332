import styled from '@emotion/styled';

const Header = styled('div')(({ theme }) => ({
	position: 'sticky',
	top: 0,
	zIndex: 2,
	display: 'flex',
	backgroundColor: theme.palette.background.default,
	padding: '0 20px 10px',
	'&.withScroll': {
		marginRight: 26
	}
}));

const TableListHeader = ({ id, children, ...rest }) => {
	return (
		<Header id={id} {...rest}>
			{children}
		</Header>
	);
};

export default TableListHeader;
