import { apiBase } from '@sonar-web/common';
import { REST_API_PATHS } from '@sonar-web/common/src/constants/system';

const path = REST_API_PATHS.HEATALLOCATORS;

export async function fetchHeatallocator(id) {
	const { data } = await apiBase(`heatallocators/${id}`, 'get', {
		path
	});

	return data;
}

export async function addHeatallocator(heatallocator) {
	const response = await apiBase(`heatallocators`, 'post', {
		path,
		data: heatallocator
	});

	const heatallocatorHeader = response.headers.location;

	if (heatallocatorHeader)
		return {
			url: heatallocatorHeader,
			id: heatallocatorHeader.slice(heatallocatorHeader.indexOf('/') + 1)
		};

	return response;
}

export async function editHeatallocator(heatallocator) {
	const data = await apiBase(`heatallocators/${heatallocator.id}`, 'put', {
		path,
		data: heatallocator
	});

	return data;
}

export async function deleteHeatallocator(id) {
	const data = await apiBase(`heatallocators/${id}`, 'delete', {
		path
	});

	return data;
}
