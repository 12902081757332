import * as Yup from 'yup';

export default Yup.object().shape({
	name: Yup.string()
		.nullable()
		.min(1, 'Watermeters_NameValidationMin')
		.max(50, 'Watermeters_NameValidationMax')
		.required('Common_ValidationRequired')
		.matches(/^(?!.*;).*$/, 'Watermeters_NameValidation'),
	isCoupled: Yup.boolean()
});
