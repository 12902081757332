import React, { useState, useEffect, useRef, useCallback } from 'react';
import clsx from 'clsx';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField, CircularProgress, Typography, InputAdornment, IconButton } from '@mui/material';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { useLocale, useDebounce } from '../../hooks';

const useStyles = makeStyles((theme) => ({
	root: {
		flex: 1,
		fontSize: theme.typography.body2.fontSize,
		'& input': {
			fontSize: theme.typography.body2.fontSize
		}
	},
	loading: { padding: 0 },
	option: {
		paddingTop: 4,
		paddingBottom: 4,
		'& span': {
			fontWeight: theme.typography.fontWeightLight
		}
	},
	highlight: {
		fontWeight: `${theme.typography.fontWeightMedium} !important`,
		color: theme.palette.primary.main
	}
}));

function debounce(func, wait) {
	let timeout;
	return function (...args) {
		const context = this;
		clearTimeout(timeout);
		timeout = setTimeout(() => func.apply(context, args), wait);
	};
}

const SearchAutocompleteSoloSimple = ({
	label,
	options,
	loading,
	hasMore,
	clearable = true,
	handleOnChange,
	displayValue = 'displayName',
	defaultValue,
	setNewOptionObject = null,
	translateDisplayValue,
	searchData,
	ownInput,
	inputName,
	setInputRef,
	error,
	helperText,
	customRenderOption,
	autompleteRef,
	disableSearch
}) => {
	const classes = useStyles();
	const { translate } = useLocale();
	const [open, setOpen] = useState(false);
	const [value, setValue] = useState(defaultValue ?? null);
	const [inputValue, setInputValue] = useState('');
	const debounceSearchTerm = useDebounce(inputValue, 300);
	const listboxRef = useRef(null);

	const handleOpen = (state) => {
		setOpen(state);
	};

	const handleOnInputChange = (e, v, r) => {
		if (r === 'reset') {
			setInputValue('');
			setValue(null);
		} else setInputValue(v);
	};

	const handleScroll = useCallback(
		debounce((event) => {
			if (!hasMore) return;
			const listboxNode = event.currentTarget || listboxRef.current;
			if (!listboxNode) return;

			const { scrollHeight, scrollTop, clientHeight } = listboxNode;
			if (scrollHeight - scrollTop - clientHeight < 100) {
				searchData(inputValue, true);
			}
		}, 100),
		[hasMore, searchData, inputValue]
	);

	useEffect(() => {
		if (disableSearch) return;
		if (open) searchData(inputValue);
	}, [debounceSearchTerm, open]);

	return (
        (<Autocomplete
			open={open}
			value={value}
			ref={autompleteRef}
			freeSolo
			onOpen={() => handleOpen(true)}
			onClose={() => handleOpen(false)}
			onInputChange={handleOnInputChange}
			onChange={(e, v) => {
				if (typeof v === 'string') {
					setValue(value ?? null);
					return;
				}
				handleOnChange(v);
				setValue(null);
			}}
			isOptionEqualToValue={() => false}
			getOptionLabel={() => ''}
			filterOptions={(opts, params) => {
				if (!ownInput) return opts;

				let filtered = opts;

				if (params.inputValue !== '') {
					const newOptionObject = setNewOptionObject(params.inputValue, opts);

					if (newOptionObject) filtered = [newOptionObject, ...filtered];
				}

				return filtered;
			}}
			options={options}
			loading={loading}
			loadingText={null}
			size='small'
			noOptionsText={translate('Common_NoResults')}
			autoHighlight
			openOnFocus
			blurOnSelect
			clearOnBlur={true}
			clearOnEscape
			includeInputInList
			disableClearable
			classes={{ root: classes.root, loading: classes.loading, option: classes.option }}
			ListboxProps={{
				'data-at': 'AutocompleteListbox',
				onScroll: handleScroll,
				ref: listboxRef
			}}
			clearIcon={<CloseIcon fontSize='small' />}
			renderInput={(params) => (
				<TextField
                    {...params}
                    inputRef={(input) => {
						if (setInputRef) setInputRef(input);
					}}
                    name={inputName ?? null}
                    label={translate(label)}
                    fullWidth
                    margin='normal'
                    error={error}
                    helperText={translate(helperText)}
                    slotProps={{
                        input: {
                            ...params.InputProps,
                            readOnly: translateDisplayValue === true,
                            endAdornment: (
                                <>
                                    {loading ? <CircularProgress color='primary' size={20} /> : null}
                                    {value && clearable && (
                                        <InputAdornment
                                            position='end'
                                            style={{ height: 'auto' }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleOnChange(null);
                                            }}>
                                            <IconButton
                                                name={`${inputName}Clear`}
                                                style={{ padding: 2, marginRight: -2 }}
                                                size='large'>
                                                <CloseIcon fontSize='small' />
                                            </IconButton>
                                        </InputAdornment>
                                    )}
                                    <ArrowDropDownOutlinedIcon
                                        onClick={() => setOpen(!open)}
                                        color='action'
                                        style={{
                                            cursor: 'pointer',
                                            transform: open ? 'rotate(180deg)' : 'rotate(0deg)'
                                        }}
                                    />
                                </>
                            )
                        },

                        formHelperText: {
                            'data-at-error': helperText
                        }
                    }} />
			)}
			renderOption={(props, option) => {
				const optionValue =
					typeof translateDisplayValue === 'string'
						? translate(`Access_${option[displayValue]}`)
						: option[displayValue];
				const matches = match(optionValue, inputValue);
				const parts = parse(optionValue, matches).map((part) => {
					part.text = part.text?.replaceAll(' ', '\u00a0');
					return part;
				});

				return customRenderOption ? (
					customRenderOption({ option, parts, classes, props })
				) : (
					<li {...props} key={props.id}>
						{parts.map((part, index) => (
							<Typography
								data-at={option[displayValue]}
								variant='body2'
								component='span'
								key={index}
								className={clsx({ [classes.highlight]: part.highlight })}>
								{part.text}
							</Typography>
						))}
					</li>
				);
			}}
		/>)
    );
};

export default SearchAutocompleteSoloSimple;
