import FileUploadTypes from '../Billing/BillingContent/FilesUpload/FileUploadTypes';

const billingHasFiles = (data) => {
	if (!data) return false;
	return (
		data[`${FileUploadTypes.Infrastructure.name}Name`] != null && data[`${FileUploadTypes.Reads.name}Name`] != null
	);
};

const BillingHelpers = {
	billingHasFiles
};

export default BillingHelpers;
