import { useEffect } from 'react';
import { useDeps } from '@sonar-web/common/src/features/Dependencies/depsContext';
import useMeasurementSetsDeps from './Dependencies/useMeasurementSetsDeps';
import { moduleName } from './constants';

const RegisterTcpDevicesModule = () => {
	const { setDeps, targetModules } = useDeps();
	const measurementSetsDeps = useMeasurementSetsDeps();

	useEffect(() => {
		setDeps(targetModules.measurementSet, moduleName, {
			measurementSetDevice: {
				addDevice: measurementSetsDeps.addDevice,
				fetchDeviceTypeById: measurementSetsDeps.fetchDeviceTypeById
			}
		});
	}, []);

	return null;
};

export default RegisterTcpDevicesModule;
