import { apiBase } from '@sonar-web/common';
import { REST_API_PATHS } from '@sonar-web/common/src/constants/system';

const path = REST_API_PATHS.WMBUS;

export async function fetchDeviceType(id) {
	const { data } = await apiBase(`deviceTypes/${id}`, 'get', {
		path
	});

	return data;
}

export async function addDeviceType(deviceType) {
	const response = await apiBase(`deviceTypes`, 'post', {
		path,
		data: deviceType
	});

	const deviceHeader = response.headers.location;

	if (deviceHeader)
		return {
			url: deviceHeader,
			id: deviceHeader.slice(deviceHeader.indexOf('/') + 1)
		};

	return response;
}

export async function editDeviceType(deviceType) {
	const data = await apiBase(`deviceTypes/${deviceType.id}`, 'put', {
		path,
		data: deviceType
	});

	return data;
}

export async function deleteDeviceType(id) {
	const data = await apiBase(`deviceTypes/${id}`, 'delete', {
		path
	});

	return data;
}
