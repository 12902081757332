import { useMemo } from 'react';
import ReportSubmitSummaryItem from './ReportSubmitSummaryItem';
import { useLocale } from '@sonar-web/common/src/hooks';
import { DatePeriodRangeTypes, datePeriodRangeTypesArray } from '@sonar-web/common';

const ReportSubmitSummary = ({ configuratorData }) => {
	const { translate } = useLocale();

	const timePeriod = useMemo(
		() => getTimePeriod(configuratorData.timePeriod, translate),
		[configuratorData.timePeriod?.option, configuratorData.timePeriod?.days]
	);

	const recipients = useMemo(
		() => getRecipients(configuratorData.recipientIds, translate),
		[configuratorData.recipientIds?.length]
	);

	const scheduled = useMemo(
		() => getSchedules(configuratorData.generateNow, translate),
		[configuratorData.generateNow]
	);

	return (
		<ul style={{ paddingBottom: '2%', paddingLeft: '20px' }} data-at='reportSummary'>
			{configuratorData.timePeriod && (
				<ReportSubmitSummaryItem
					text={timePeriod}
					dataat={`${configuratorData.timePeriod?.option}${
						configuratorData.timePeriod?.days ? configuratorData.timePeriod.days : ''
					}`}
				/>
			)}
			<ReportSubmitSummaryItem
				text={recipients}
				dataat={
					configuratorData.recipientIds?.length === 0
						? 'notShared'
						: `sharedTo${configuratorData.recipientIds.length}`
				}
			/>
			<ReportSubmitSummaryItem
				text={scheduled}
				dataat={configuratorData.generateNow ? 'generateNow' : 'generationScheduled'}
			/>
		</ul>
	);
};

export default ReportSubmitSummary;

const getTimePeriod = (timePeriod, translate) => {
	const timePeriodOption = timePeriod?.option;
	const timePeriodDays = timePeriod?.days;
	const datePeriodType = timePeriodOption ? datePeriodRangeTypesArray.find((d) => d.value === timePeriodOption) : '';

	if (!datePeriodType) return '';
	return datePeriodType.value === DatePeriodRangeTypes.Own.value
		? `${translate(DatePeriodRangeTypes.Own.name)}: ${timePeriodDays}`
		: translate(datePeriodType.name);
};

const getRecipients = (recipientIds, translate) => {
	if (!recipientIds || recipientIds.length === 0) return translate('Common_NotSharedToAnyone');
	if (recipientIds.length === 1) return translate('Common_SharedToOnePerson');
	if (recipientIds.length > 1) return translate('Common_SharedToXPersons', recipientIds.length);
};

const getSchedules = (generateNow, translate) => {
	return generateNow ? translate('Common_GenerationNotScheduled') : translate('Common_GenerationScheduled');
};
