import { List, Typography, Box } from '@mui/material';
import { useLocale } from '../../../../../hooks';
import { PanelListItemActionDelete, TableListColumn, TableListItem } from '../../../../..';

const SelectedList = ({ name, selected, onDelete }) => {
	const { translate } = useLocale();

	const selectedCount = selected.length;

	if (selectedCount === 0) return null;
	return (
		<List>
			{selected.map((item) => {
				const disabledDelete = disableDelete(name, item);

				return (
					<TableListItem key={item.value} row={item}>
						{() => (
							<Box display='flex'>
								<TableListColumn flex centerVertically disableLeftPadding>
									<Typography variant='body2'>{translate(item.label)}</Typography>
								</TableListColumn>
								{!disabledDelete && (
									<TableListColumn width={50}>
										<PanelListItemActionDelete onClick={(e) => onDelete(e, item)} />
									</TableListColumn>
								)}
							</Box>
						)}
					</TableListItem>
				);
			})}
		</List>
	);
};

export default SelectedList;

const disabledDeleteByRegister = new Map([
	//Dane transmitowane zgodnie z M-Bus
	//10 - Objętość, 04 - Zegarek standardowy M-Bus
	['transmission_mbus_registers_selection_property', ['Registry_10', 'Registry_04']],
	//Dane transmitowane w ramce producenta
	//60 - Flagi zdarzeń
	['transmission_registers_selection_property', ['Registry_10', 'Registry_60']]
]);

function disableDelete(propName, listItem) {
	const propData = disabledDeleteByRegister.get(propName);

	if (!propData) return false;
	return propData.includes(listItem.value);
}
