import styled from '@emotion/styled';
import { useLocale } from '../../hooks';

const CollapseIndicator = styled('button')(({ theme }) => ({
	position: 'absolute',
	paddingInline: '1rem',
	height: 20,
	left: '50%',
	transform: 'translateX(-50%)',
	bottom: '-10px',
	opacity: 0,
	borderWidth: 1,
	borderStyle: 'solid',
	borderColor: 'inherit',
	borderRadius: theme.shape.borderRadius,
	backgroundColor: theme.palette.background.default,
	textAlign: 'center',
	fontSize: theme.typography.caption.fontSize,
	color: theme.palette.text.primary,
	transition: 'opacity 100ms ease-out',
	lineHeight: 'normal',
	cursor: 'pointer'
}));

const CollapseIndicatorText = ({ text = 'Details', row, onClick, ...rest }) => {
	const { translate } = useLocale();

	return (
		<CollapseIndicator
			className='collapseIndicatorText'
			onClick={onClick ?? null}
			{...rest}
			data-at='expandDetails'>
			{translate(text)}
		</CollapseIndicator>
	);
};

export default CollapseIndicatorText;
