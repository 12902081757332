import { apiBase } from '@sonar-web/common';
import { REST_API_PATHS } from '@sonar-web/common/src/constants/system';

const path = REST_API_PATHS.WATERMETERS;

export async function fetchRead(id) {
	const { data } = await apiBase(`reads/${id}`, 'get', {
		path
	});

	return data;
}

export async function addRead(read) {
	const response = await apiBase(`reads`, 'post', {
		path,
		data: read
	});

	const readHeader = response.headers.location;

	if (readHeader)
		return {
			url: readHeader,
			id: readHeader.slice(readHeader.indexOf('/') + 1)
		};

	return response;
}

export async function editRead(read) {
	const data = await apiBase(`reads/${read.id}`, 'put', {
		path,
		data: read
	});

	return data;
}

export async function deleteRead(id) {
	const data = await apiBase(`reads/${id}`, 'delete', {
		path
	});

	return data;
}
