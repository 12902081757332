import React from 'react';
import ConfirmationView from '../../../components/Confirmation/ConfirmationView';

const SaveToDevice = ({ handleSubmit, handleBack, pending }) => {
	return (
		<ConfirmationView
			pending={pending}
			onCancel={handleBack}
			cancelText='Common_ContinueWork'
			onConfirm={handleSubmit}
			confirmText='Common_ConfiguratorSubmitNewConfiguration'
			header='Common_ConfiguratorSubmitQuestion'
			description='Common_ConfiguratorAfterSubmitNextConnection'
			description2='Common_ConfiguratorAfterSubmitRedirect'
		/>
	);
};

export default SaveToDevice;
