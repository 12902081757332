import React from 'react';
import { SvgIcon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	smaller: {
		fontSize: '1rem !important'
	},
	small: {
		fontSize: '1.25rem'
	},
	large: {
		fontSize: theme.typography.pxToRem(35)
	},
	default: {
		fontSize: theme.typography.pxToRem(20)
	},
	xxxs: {
		fontSize: '0.875rem !important'
	},
	xxs: {
		fontSize: '1.0rem !important'
	},
	xs: {
		fontSize: '1.25rem'
	},
	sm: {
		fontSize: '1.85rem'
	},
	s: {
		fontSize: '2rem'
	},
	m: {
		fontSize: '2.5rem'
	},
	l: {
		fontSize: '3rem'
	},
	xl: {
		fontSize: '4rem'
	},
	xxl: {
		fontSize: '6rem'
	},
	xxxl: {
		fontSize: '10rem'
	},
	xxxxl: {
		fontSize: '12rem'
	},
	backgroundSize: {
		fontSize: '25rem',
		[theme.breakpoints.down('lg')]: {
			fontSize: '20rem'
		}
	},
	primaryColor: {
		color: theme.palette.primary.main
	},
	secondaryColor: {
		color: theme.palette.secondary.main
	},
	errorColor: {
		color: theme.palette.error.main
	},
	successColor: {
		color: theme.palette.success.main
	},
	primaryTextColor: {
		color: theme.palette.text.primary
	},
	secondaryTextColor: {
		color: theme.palette.text.secondary
	},
	dividerColor: { color: theme.palette.divider },
	barelyVisibleColor: { color: theme.palette.grey[50] },
	primaryLightColor: {
		color: theme.palette.primaryLight.dark
	},
	backgroundColor: {
		color: '#f7f7f8'
	}
}));

const BaseIcon = ({
	fontSize,
	renderMultiPath,
	children,
	primaryPathClass,
	primaryPathColor,
	secondaryPathClass,
	secondaryPathColor,
	classes,
	className,
	...rest
}) => {
	const styles = useStyles();

	return (
		<SvgIcon
			{...rest}
			classes={{ ...(classes || {}) }}
			className={clsx(className, {
				[styles[fontSize]]: fontSize !== undefined
			})}>
			{children
				? children
				: renderMultiPath({
						primaryPathClass: primaryPathClass ? styles[primaryPathClass] : null,
						primaryPathColor,
						secondaryPathClass: secondaryPathClass ? styles[secondaryPathClass] : null,
						secondaryPathColor
					})}
		</SvgIcon>
	);
};

export default BaseIcon;
