import React from 'react';
import BaseIcon from './BaseIcon';

export default function LocalIcon(props) {
	return (
		<BaseIcon {...props}>
			<path d='m 6.0021743,3.5625298 c -1.343687,0 -2.439644,1.09803 -2.439644,2.44172 V 17.99628 c 0,1.34369 1.095957,2.44119 2.439644,2.44119 h 8.9591427 0.999939 2.035018 c 1.343688,0 2.441195,-1.0975 2.441195,-2.44119 V 6.0042498 c 0,-1.34369 -1.097507,-2.44172 -2.441195,-2.44172 z m 0,0.99994 H 17.996274 c 0.806987,0 1.441256,0.63479 1.441256,1.44178 V 17.99628 c 0,0.80699 -0.634269,1.44125 -1.441256,1.44125 H 15.961256 V 9.2092198 a 0.50005,0.50005 0 0 0 -0.500227,-0.50023 H 8.5374203 a 0.50005,0.50005 0 0 0 -0.500228,0.50023 V 19.43753 h -2.035018 c -0.806985,0 -1.439705,-0.63426 -1.439705,-1.44125 V 6.0042498 c 0,-0.80699 0.63272,-1.44178 1.439705,-1.44178 z m 3.034957,5.14698 H 14.961317 V 19.43753 H 9.0371313 Z M 12.621409,12.96506 v 0.50023 1.83968 0.49971 h 0.999939 v -0.49971 -1.83968 -0.50023 z' />
		</BaseIcon>
	);
}
