export default () => {
	const createSorter = (data) => {
		return [
			{
				member: data.member,
				listSortDirection: data.direction
			}
		];
	};

	return { createSorter };
};
