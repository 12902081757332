import clsx from 'clsx';
import { Box, Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		overflow: 'auto',
		position: 'relative'
	},
	container: {
		minWidth: '400px',
		padding: '1.5rem 1.5rem 0 1.5rem',
		margin: '0 auto',
		flex: 1,
		backgroundColor: theme.palette.background.default
	},
	containerStandalone: {
		padding: 0,
		height: '100%',
		width: '100%',
		overflow: 'hidden',
		flexDirection: 'row',
		'& .map': {
			width: '100%'
		},
		'& .map-shrink': {
			width: 'clamp(400px, 50vw, 470px)'
		}
	}
}));

const ContentWrapper = ({ standalone, children }) => {
	const classes = useStyles();

	return (
		<>
			<Box id='contentWrapper' className={clsx(classes.root, { [classes.containerStandalone]: standalone })}>
				{standalone ? children : <Container className={classes.container}>{children}</Container>}
			</Box>
			{!standalone && <div className='footer' style={{ paddingTop: '1.5rem' }} />}
		</>
	);
};

export default ContentWrapper;
