import { createSelector, createSlice } from '@reduxjs/toolkit';
import { fetchHeatallocator, addHeatallocator, editHeatallocator, deleteHeatallocator } from './heatallocatorApi';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import { getSliceDescriptor, guidEmpty } from '@sonar-web/common';
import { slices } from '../Constants/Module';
import HeatAllocatorsService from '../HeatAllocatorsService';

const slice = 'heatallocator';
const getHeatallocator = (getState) => getState().heatallocator.heatallocator;

const initialHeatallocatorData = {
	id: guidEmpty,
	number: '',
	heatAllocatorTypeId: '',
	heatAllocatorTypeName: ''
};

export const heatallocatorSlice = createSlice({
	name: slice,
	initialState: {
		addPending: false,
		addSuccess: false,
		fetchPending: false,
		fetchSuccess: false,
		deletePending: false,
		deleteSuccess: false,
		heatallocator: initialHeatallocatorData,
		exportReadsPending: false,
		exportReads: null
	},
	reducers: {
		fetchHeatallocatorSucces: (state, action) => {
			state.heatallocator = action.payload;
			state.fetchPending = false;
			state.fetchSuccess = true;
		},
		fetchHeatallocatorPending: (state) => {
			state.fetchPending = true;
			state.fetchSuccess = false;
		},
		addHeatallocatorSucces: (state, action) => {
			state.addPending = false;
			state.addSuccess = action.payload;
		},
		addHeatallocatorPending: (state, action) => {
			state.addPending = action.payload ?? true;
		},
		deleteHeatallocatorSucces: (state) => {
			state.deletePending = false;
			state.deleteSuccess = true;
		},
		deleteHeatallocatorPending: (state, action) => {
			state.deletePending = action.payload ?? true;
		},
		setHeatallocatorData: (state, action) => {
			state.heatallocator = action.payload;
		},
		resetHeatallocatorData: (state, action) => {
			const { isInSubmitManyMode } = action.payload || {};

			state.heatallocator = isInSubmitManyMode
				? {
						...initialHeatallocatorData,
						heatAllocatorTypeId: state.heatallocator.heatAllocatorTypeId,
						heatAllocatorTypeName: state.heatallocator.heatAllocatorTypeName
				  }
				: initialHeatallocatorData;
			state.addSuccess = false;
			state.deleteSuccess = false;
			state.fetchSuccess = false;
		},
		resetAddSuccess: (state) => {
			state.addSuccess = false;
		},
		fetchExportReadsSuccess: (state, { payload }) => {
			state.exportReadsPending = false;
			state.exportReads = payload?.elements;
		},
		fetchExportReadsPending: (state, { payload }) => {
			state.exportReadsPending = payload;
		}
	}
});

export const {
	fetchHeatallocatorSucces,
	fetchHeatallocatorPending,
	addHeatallocatorSucces,
	addHeatallocatorPending,
	deleteHeatallocatorSucces,
	deleteHeatallocatorPending,
	setHeatallocatorData,
	resetHeatallocatorData,
	resetAddSuccess,
	fetchExportReadsPending,
	fetchExportReadsSuccess
} = heatallocatorSlice.actions;

export const fetchHeatallocatorAsync = (id) => async (dispatch) => {
	let response;

	try {
		dispatch(fetchHeatallocatorPending());
		response = await fetchHeatallocator(id);
	} catch (error) {
		dispatch(fetchHeatallocatorPending(false));
		dispatch(addErrorAsync({ slice, error }));
		return;
	}

	dispatch(fetchHeatallocatorSucces(response));
};

export const addHeatallocatorAsync = () => async (dispatch, getState) => {
	const heatallocator = { ...getHeatallocator(getState) };
	let response;
	let result = Object.assign({}, heatallocator);

	delete heatallocator.heatAllocatorTypeName;

	try {
		dispatch(addHeatallocatorPending());

		response = await addHeatallocator(heatallocator);
	} catch (error) {
		dispatch(addHeatallocatorPending(false));

		const errorResult = await dispatch(addErrorAsync({ slice, error, skipNotificationMembers: ['number'] }));

		return errorResult;
	}

	result.id = response.id;

	dispatch(addHeatallocatorSucces(response.id ? result : true));
};

export const editHeatallocatorAsync = () => async (dispatch, getState) => {
	const heatallocator = getHeatallocator(getState);

	try {
		dispatch(addHeatallocatorPending());
		await editHeatallocator(heatallocator);
	} catch (error) {
		dispatch(addHeatallocatorPending(false));

		const errorResult = await dispatch(addErrorAsync({ slice, error, skipNotificationMembers: ['number'] }));

		return errorResult;
	}

	dispatch(addHeatallocatorSucces(true));
};

export const deleteHeatallocatorAsync = (id) => async (dispatch) => {
	try {
		dispatch(deleteHeatallocatorPending());
		await deleteHeatallocator(id);
	} catch (error) {
		dispatch(deleteHeatallocatorPending(false));
		const errorResult = await dispatch(addErrorAsync({ slice, error }));

		return errorResult;
	}

	dispatch(deleteHeatallocatorSucces());
};

export const fetchHeatAllocatorExportReadsAsync = (filters) => async (dispatch, getState) => {
	try {
		const pd = getSliceDescriptor(getState, slices.heatallocatorReads, null, null, Math.pow(2, 31) - 1);
		pd.FilterDescriptors = [...pd.FilterDescriptors, ...filters];
		pd.Offset = 0;

		dispatch(fetchExportReadsPending(true));
		const response = await HeatAllocatorsService.fetchHeatAllocatorReads(pd);
		dispatch(fetchExportReadsSuccess(response));
		return response;
	} catch (error) {
		dispatch(fetchExportReadsPending(false));
		dispatch(addErrorAsync({ slice: slices.watermeterReads, error }));
	}
};

export const selectHeatallocator = (state) => {
	return {
		dataRow: state.heatallocator.heatallocator,
		fetchPending: state.heatallocator.fetchPending,
		fetchSuccess: state.heatallocator.fetchSuccess
	};
};

export const selectHeatallocatorAdd = (state) => {
	return {
		addPending: state.heatallocator.addPending,
		addSuccess: state.heatallocator.addSuccess
	};
};

export const selectDeleteHeatallocatorPending = (state) => {
	return {
		deletePending: state.heatallocator.deletePending,
		deleteSuccess: state.heatallocator.deleteSuccess
	};
};

export const selectHeatAllocatorExportReads = createSelector(
	(state) => state.heatallocator,
	(heatAllocator) => ({
		pending: heatAllocator.exportReadsPending,
		exportReads: heatAllocator.exportReads
	})
);

export default heatallocatorSlice.reducer;
