import React, { useEffect, useState } from 'react';
import ConfiguratorGroup from './ConfiguratorGroup';

const ConfiguratorTab = ({ tabIndex, groups, form, updateTabValidity, ...rest }) => {
	const [tabElements, setTabElements] = useState([]);

	useEffect(() => {
		let hasErrors = false;

		for (const e in form.errors) {
			if (tabElements.includes(e)) {
				hasErrors = true;
				break;
			}
		}

		updateTabValidity(tabIndex, !hasErrors);
	}, [form.errors]);

	useEffect(() => {
		let elements = [];
		for (const g of groups) {
			for (const e of g.elements) {
				elements.push(e.key);
			}
		}
		setTabElements(elements);
	}, []);

	return groups.map((group, index) => {
		let display = false;

		for (const e of group.elements) {
			if (e.display === true) {
				display = true;
				break;
			}
		}

		if (display === false) return null;
		return <ConfiguratorGroup key={index} {...group} form={form} {...rest} />;
	});
};

export default ConfiguratorTab;
