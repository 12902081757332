import React from 'react';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '../../../../hooks';
import SelectControlBase from './SelectControlBase';
import TileItem from './TileItem';

const useStyles = makeStyles((theme) => ({
	grid: {
		display: 'grid',
		gridTemplateColumns: 'repeat(7, 1fr)',
		alignItems: 'center',
		justifyItems: 'stretch',
		gap: `30px`,
		padding: theme.spacing(1),
		[theme.breakpoints.down('lg')]: {
			gridTemplateColumns: '1fr 1fr 1fr 1fr'
		}
	}
}));

const SelectDayOfTheWeek = ({ selectedValues, onChange }) => {
	const classes = useStyles();
	const { translate } = useLocale();

	const toggleSelected = (v) => {
		let newValues = [...selectedValues];

		const index = newValues.indexOf(v);
		if (index > -1) {
			newValues.splice(index, 1);
		} else {
			newValues.push(v);
		}

		onChange(newValues);
	};

	return (
		<SelectControlBase
			title={translate('Common_DayOfWeek')}
			renderContent={() => {
				return (
					<Box display='flex' flexDirection='column' flex={1} paddingY={1} marginX={-3.5}>
						<Box className={classes.grid} flex={1}>
							{[1, 2, 3, 4, 5, 6, 0].map((day, index) => {
								const selected = selectedValues.includes(day);
								return (
									<Box key={index} display='flex' justifyContent='center' flex={1}>
										<TileItem
											width={45}
											p={0.5}
											text={translate(
												'Common_DayOfWeekShort' + (((day + 6) % 7) + 1)
											).toUpperCase()}
											selected={selected}
											onClick={() => {
												toggleSelected(day);
											}}
										/>
									</Box>
								);
							})}
						</Box>
					</Box>
				);
			}}
		/>
	);
};

export default SelectDayOfTheWeek;
