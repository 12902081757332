import { DontSaveIcon } from '@sonar-web/common';
import SaveSettingsType from './SaveSettingsType';
import TileButtonSelectable from '@sonar-web/common/src/features/Tiles/TileButtonSelectable';

const DontSave = ({ toggleName, setToggleName }) => {
	const value = SaveSettingsType.DontSave.value;

	const handleClick = () => {
		if (toggleName !== value) setToggleName(value);
	};

	return (
		<TileButtonSelectable
			Icon={DontSaveIcon}
			title='DontSave'
			selected={toggleName === value}
			onClick={handleClick}
			radio={true}
			data-at='DontSave'
		/>
	);
};

export default DontSave;
