import useList from '../../features/List/useList';
import ListNoRecords from './ListNoRecords';

const ListEmptyPlaceholder = ({ slice, text, canShow = false, ...rest }) => {
	const { listSelector } = useList(slice);

	const { elements, pending } = listSelector;
	const elementsCount = elements?.length;
	const hasRecords = elementsCount !== 0;

	return <ListNoRecords show={canShow || (!pending && !hasRecords)} text={text} {...rest} />;
};

export default ListEmptyPlaceholder;
