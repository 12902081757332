export default {
	Instant: {
		value: 1,
		name: 'Instant'
	},
	Logged: {
		value: 2,
		name: 'Logged'
	},
	Monitoring: {
		value: 3,
		name: 'Monitoring'
	}
};
