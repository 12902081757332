import React, { createContext, useContext, useState } from 'react';

const DepsContext = createContext({});

export const useDeps = () => {
	return useContext(DepsContext);
};

export const targetModules = {
	measurementSet: 'measurementSet',
	location: 'location',
	systemSearch: 'systemSearch',
	stats: 'stats',
	imports: 'imports',
	exports: 'exports',
	stationary: 'stationary',
	wmbus: 'wmbus',
	tcp: 'tcp',
	core: 'core',
	watermeter: 'watermeter',
	heatmeter: 'heatmeter'
};

export const DepsContextProvider = ({ children, modulesPaths }) => {
	//zależności dla modułu MeasurementSet
	const [measurementSet, setMeasurementSet] = useState({});
	//zależności dla modułu Location
	const [location, setLocation] = useState({});
	//zależności dla modułu Wyszukiwarki systemowej
	const [systemSearch, setSystemSearch] = useState({});
	//zależności dla modułu Statystyk
	const [stats, setStats] = useState({});
	//zależności dla modułu Importów
	const [imports, setImports] = useState({});
	//zależności dla modułu Eksportów
	const [exports, setExports] = useState({});
	//zależności dla modułu Sieci stacjonarnych
	const [stationary, setStationary] = useState({});
	//zależności dla modułu Urządzeń wmbus
	const [wmbus, setWmbus] = useState({});
	//zależności dla modułu Urządzeń watermeters
	const [watermeter, setWatermeter] = useState({});
	//zależności dla modułu Urządzeń tcp
	const [tcp, setTcp] = useState({});
	//zależności dla modułu core
	const [core, setCore] = useState({});
	//zależności dla modułu heatmeter
	const [heatmeter, setHeatmeter] = useState({});
	/**
	 * rejestracja zależności do modułów
	 */
	const setDeps = (targetModule, moduleName, data) => {
		const targetModuleState = getStateByModuleName(targetModule);
		if (!targetModuleState) return null;

		targetModuleState.setState((previous) => ({ ...previous, [moduleName]: data }));
	};

	/**
	 * pobranie zgłoszonych zlaeżności dla modułu
	 */
	const getDepsByModule = (targetModule, moduleName, section, name) => {
		if (!targetModule || !moduleName) return null;

		const targetModuleState = getStateByModuleName(targetModule);
		if (!targetModuleState) return null;

		const moduleDeps = targetModuleState.state[moduleName];

		if (section && name) return moduleDeps[section]?.[name];
		if (section) return moduleDeps[section];
		return moduleDeps;
	};

	/**
	 * pobranie zgłoszonych zlaeżności od wszystkich modułów dla danej sekcji
	 */
	const getDepsAllModules = (targetModule, section) => {
		if (!targetModule) return null;

		const targetModuleState = getStateByModuleName(targetModule);
		if (!targetModuleState) return null;

		return Object.entries(targetModuleState.state).map((tms) => tms[1][section]);
	};

	/**
	 * funkcja prywatna do wyboru stanu modułu, do którego odnosi się żądanie
	 */
	function getStateByModuleName(moduleName) {
		if (!moduleName) return null;

		switch (moduleName) {
			case targetModules.measurementSet:
				return { state: measurementSet, setState: setMeasurementSet };
			case targetModules.location:
				return { state: location, setState: setLocation };
			case targetModules.systemSearch:
				return { state: systemSearch, setState: setSystemSearch };
			case targetModules.stats:
				return { state: stats, setState: setStats };
			case targetModules.imports:
				return { state: imports, setState: setImports };
			case targetModules.exports:
				return { state: exports, setState: setExports };
			case targetModules.stationary:
				return { state: stationary, setState: setStationary };
			case targetModules.wmbus:
				return { state: wmbus, setState: setWmbus };
			case targetModules.watermeter:
				return { state: watermeter, setState: setWatermeter };
			case targetModules.tcp:
				return { state: tcp, setState: setTcp };
			case targetModules.core:
				return { state: core, setState: setCore };
			case targetModules.heatmeter:
				return { state: heatmeter, setState: setHeatmeter };
			default:
				return null;
		}
	}

	return (
		<DepsContext.Provider
			value={{
				setDeps,
				getDepsByModule,
				targetModules,
				getDepsAllModules
			}}>
			{modulesPaths.map((mp, index) => {
				const Component = mp;
				return <Component key={index} />;
			})}
			{children}
		</DepsContext.Provider>
	);
};

export default DepsContext;
