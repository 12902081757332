import { Skeleton, Typography } from '@mui/material';
import { formatDateTime } from '@sonar-web/common';
import { useLocale } from '@sonar-web/common/src/hooks';
import { useSelector } from 'react-redux';
import TimeLicenseStatus from '../Enums/TimeLicenseStatus';
import { selectTimeLicenses } from '../licensesSlice';

const TimeLicenseStatusText = ({ status, timeLicence }) => {
	const { translate } = useLocale();
	const { timeLicensesPending, timeLicenses } = useSelector(selectTimeLicenses);

	const text = `TimeLicenseStatusText${status}`;

	const color = () => {
		if (status === TimeLicenseStatus.Expired.name) return 'error';
		if (status === TimeLicenseStatus.Expiring.name) return '#ff6b34';
		return 'textSecondary';
	};

	if (timeLicensesPending && !timeLicenses) return <Skeleton variant='text' width='100%' />;
	return (
		<Typography variant='caption' color={color()}>
			{!timeLicence
				? translate('TimeLicenseStatusTextUnavailable')
				: translate(text, formatDateTime(timeLicence))}
		</Typography>
	);
};

export default TimeLicenseStatusText;
