import { Typography } from '@mui/material';
import { useLocale } from '@sonar-web/common/src/hooks';
import { TableListColumn, TableListHeader } from '@sonar-web/common';

const ServersAddressesListHeader = () => {
	const { translate } = useLocale();

	return (
		<TableListHeader id='ServersAddressesListHeader'>
			<TableListColumn flex />
			<TableListColumn width='auto' sx={{ flex: 'none' }}>
				<Typography variant='caption' color='textSecondary'>
					{translate('DomainAddress')}
				</Typography>
			</TableListColumn>
			<TableListColumn width={150} disableRightPadding>
				<Typography variant='caption' color='textSecondary'>
					{translate('IpAddress')}
				</Typography>
			</TableListColumn>
		</TableListHeader>
	);
};

export default ServersAddressesListHeader;
