import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLastActiveTab, setActiveTab } from './deviceConfiguratorSlice';
import ConfirmationView from '../../../components/Confirmation/ConfirmationView';

const DeviceConfiguratorCancel = ({ onCancel }) => {
	const dispatch = useDispatch();
	const lastActiveTab = useSelector(selectLastActiveTab);

	const handleBack = () => dispatch(setActiveTab(lastActiveTab));

	const handleSubmit = () => onCancel();

	return (
		<ConfirmationView
			onCancel={handleBack}
			cancelText='Common_ContinueWork'
			onConfirm={handleSubmit}
			confirmText='Common_ConfiguratorCancelYourWork'
			header='Common_ConfiguratorCancelProgressQuestion'
			description='Common_ConfiguratorChangesWillBeLost'
			description2='Common_ConfiguratorAfterCancel'
		/>
	);
};

export default DeviceConfiguratorCancel;
