import { DateHelpers } from '../../../../../helpers';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';

const DaysWrapper = styled('div')({
	display: 'flex',
	flexWrap: 'wrap'
});

const Day = styled(Typography)({
	width: 21,
	height: 21,
	borderRadius: 3,
	border: '1px solid #ffffff',
	textAlign: 'center',
	backgroundColor: '#ffffff'
});

const DayNames = () => {
	const daysNames = DateHelpers.dayNames();
	return (
		<DaysWrapper>
			{daysNames.map((d) => (
				<Day key={d} variant='caption' color='textSecondary'>
					{d}
				</Day>
			))}
		</DaysWrapper>
	);
};

export default DayNames;
