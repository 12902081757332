import { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Typography } from '@mui/material';
import {
	FormToggleButton,
	TypographyEmphesizeWords,
	FormikDateTimePicker,
	ReadByInkasentIcon,
	ReadByTenantIcon,
	FormWrapper
} from '@sonar-web/common';
import useLocale from '@sonar-web/common/src/hooks/useLocale';
import { useForm, useRequest } from '@sonar-web/common/src/hooks';
import FormTextField from '@sonar-web/common/src/components/FormTextField/FormTextField';
import ReadSource from '@sonar-web/common/src/enums/ReadSource';
import ReadType from '@sonar-web/common/src/enums/ReadType';
import FormComponents from '@sonar-web/common/src/components/FormComponents/FormComponents';
import { addRead } from './readApi';
import { slices } from '../Constants/Module';

const ReadFormAggregated = ({ main, additional, startDate, onClose, onSuccess }) => {
	const { translate } = useLocale();
	const { handleApiErrors } = useForm();
	const { pending, request, error } = useRequest({
		api: addRead,
		slice: slices.watermeterRead,
		callback: onSuccess
	});

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			main: '',
			additional: '',
			date: null,
			source: ''
		},
		validationSchema: Yup.object().shape({
			main: Yup.string()
				.required('Common_ValidationRequired')
				.matches(/^-?(?=.*\d)\d{1,8}(?:[,|.]\d{1,3})?$/, 'Watermeters_VolumeValidationDecimal'),
			additional: Yup.string()
				.required('Common_ValidationRequired')
				.matches(/^-?(?=.*\d)\d{1,8}(?:[,|.]\d{1,3})?$/, 'Watermeters_VolumeValidationDecimal'),
			date: Yup.date().nullable().required('Common_ValidationRequired'),
			source: Yup.string().required('Watermeters_ChooseReadProvider')
		}),
		onSubmit: (submitValues) => {
			const requestData = {
				reads: [
					{
						date: submitValues.date,
						waterMeterId: main.id,
						volume: Number(submitValues.main.replace(',', '.')),
						source: submitValues.source,
						type: ReadType.Instant.name
					},
					{
						date: submitValues.date,
						waterMeterId: additional.id,
						volume: Number(submitValues.additional.replace(',', '.')),
						source: submitValues.source,
						type: ReadType.Instant.name
					}
				]
			};

			request(requestData);
		}
	});

	const { values, errors, touched, setFieldValue } = formik;

	useEffect(() => {
		if (error) handleApiErrors(formik, error);
	}, [error]);

	return (
		<FormWrapper name='readFormAggregated'>
			<form onSubmit={formik.handleSubmit}>
				<FormComponents.Title>{translate('AddAgregatedWaterMeterReads')}</FormComponents.Title>
				<FormTextField
					autoFocus
					name='main'
					label={`${main.number} (${main.typeName}) - ${translate('Watermeters_VolumeWithUnit')}`}
					variant='outlined'
					formik={formik}
				/>
				<FormTextField
					name='additional'
					label={`${additional.number} (${additional.typeName}) - ${translate('Watermeters_VolumeWithUnit')}`}
					variant='outlined'
					formik={formik}
				/>
				<FormikDateTimePicker
					name='date'
					disableFuture
					field={{ name: 'date', value: values.date }}
					form={formik}
					label='Watermeters_MeasurementDateAndTime'
					FormHelperTextProps={{
						'data-at-error': errors.date
					}}
					textFieldProps={{
						margin: 'normal',
						variant: 'outlined',
						size: 'small'
					}}
					minDate={startDate}
				/>
				<Box display='flex' justifyContent='space-between' marginTop='2rem'>
					<FormToggleButton
						value={values.source}
						onValue={ReadSource.Inkasent.name}
						name={`readSource_${ReadSource.Inkasent.name}`}
						icon={<ReadByInkasentIcon fontSize='m' secondaryPathClass='primaryColor' />}
						text={
							<TypographyEmphesizeWords
								text='Watermeters_ReadBy'
								words={['Watermeters_ByInkasent']}
								breakRowOnWords
							/>
						}
						onClick={(val) => setFieldValue('source', val)}
					/>
					<FormToggleButton
						value={values.source}
						onValue={ReadSource.Tenant.name}
						name={`readSource_${ReadSource.Tenant.name}`}
						icon={<ReadByTenantIcon fontSize='m' secondaryPathClass='primaryColor' />}
						text={
							<TypographyEmphesizeWords
								text='Watermeters_ReadBy'
								words={['Watermeters_ByTenant']}
								breakRowOnWords
							/>
						}
						onClick={(val) => setFieldValue('source', val)}
					/>
				</Box>
				{touched.source && errors.source && (
					<Typography variant='caption' color='error' data-at-error={errors.source}>
						{translate(errors.source)}
					</Typography>
				)}
				<FormComponents.Toolbar>
					<FormComponents.CancelButton pending={pending} onClick={onClose} />
					<FormComponents.SubmitButton pending={pending} />
				</FormComponents.Toolbar>
			</form>
		</FormWrapper>
	);
};

export default ReadFormAggregated;
