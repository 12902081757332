import { getScheduleTypeDefinition } from './Schedule/constants';

//string wejściowy do obiektu - trzeba wycinać rok, bo cron-buildre nie obsługuje

export const parseCronString = (cron) => {
	const parts = cron.split(' ');

	const parsePart = (p) => p.split(',').map((v) => (Number.isInteger(v) ? Number.parseInt(v) : v));

	return {
		minute: parsePart(parts[1]),
		hour: parsePart(parts[2]),
		dayOfTheMonth: parsePart(parts[3]),
		month: parsePart(parts[4]),
		dayOfTheWeek: parsePart(parts[5]),
		year: parts.length == 7 ? parsePart(parts[6]) : null
	};
};

export const parseCronStringEntries = (entries) => {
	let parsed = [];

	for (const key in entries) {
		parsed.push(parseCronString(entries[key].cronTime));
	}

	return parsed;
};

//znalezienie typu harmonogramu po obiekcie cron
export const getScheduleTypeFromCronObject = (cronExp) => {
	if (cronExp.year && cronExp.year[0] !== '*') return 'oneTime';
	if (cronExp.dayOfTheMonth[0] !== '*') return 'everyMonth';
	if (cronExp.dayOfTheWeek[0] !== '*') return 'everyWeek';
	if (cronExp.hour[0] === '*') return 'everyHour';

	return 'everyDay';
};

//obiekt cron do stringa do wysłania
export const stringifyCronObject = (cronObject) => {
	const { minute, hour, dayOfTheMonth, month, dayOfTheWeek, year } = cronObject;

	return `0 ${minute.join(',')} ${hour.join(',')} ${dayOfTheMonth.join(',')} ${month.join(',')} ${dayOfTheWeek.join(
		','
	)} ${year.join(',')}`;
};

//wywołanie funkcji aktualizującej dane w obiekcie crona dla danego typu
export const updateCronObjects = (cronObjects, type, data) => {
	const typeDef = getScheduleTypeDefinition(type);
	if (typeDef) return typeDef.updateCronData(cronObjects, data);

	return cronObjects;
};

export const isDefaultCronObject = (cronObjectEntries) => {
	const firstCornObject = cronObjectEntries[0];

	let result = true;

	for (let co in firstCornObject) {
		if (firstCornObject[co][0] !== '*') {
			result = false;
			break;
		}
	}

	return result;
};
