import { apiBase } from '@sonar-web/common';
import { REST_API_PATHS } from '@sonar-web/common/src/constants/system';

const path = REST_API_PATHS.WATERMETERS;

export async function fetchWatermeterType(id) {
	const { data } = await apiBase(`watermeterTypes/${id}`, 'get', {
		path
	});

	return data;
}

export async function addWatermeterType(watermeterType) {
	const response = await apiBase(`watermeterTypes`, 'post', {
		path,
		data: watermeterType
	});

	const watermeterHeader = response.headers.location;

	if (watermeterHeader)
		return {
			url: watermeterHeader,
			id: watermeterHeader.slice(watermeterHeader.indexOf('/') + 1)
		};

	return response;
}

export async function editWatermeterType(watermeterType) {
	const data = await apiBase(`watermeterTypes/${watermeterType.id}`, 'put', {
		path,
		data: watermeterType
	});

	return data;
}

export async function deleteWatermeterType(id) {
	const data = await apiBase(`watermeterTypes/${id}`, 'delete', {
		path
	});

	return data;
}
