import * as Yup from 'yup';
import useConfigurator from '../useConfigurator';
import useCustomElementSettings from '../useCustomElementSettings';
import { useLocale } from '../../../../hooks';

export default () => {
	const { translate } = useLocale();
	const { updateDatasFormElementsSettings } = useConfigurator();
	const { getCustomSettings } = useCustomElementSettings();

	//formValues aktualne wartości w formularzu
	//za każdą zmianą toggle, który ukrywa jakąś zależną od niego kontrolkę, trzba zmienić schemę walidacji, żeby móc przejść formularz
	//jak włączymy dajemy dla zaleznego pola required
	//super obejścia, nie mam absolutnie innego pomysłu jak to zrobić
	const createSchema = (section, formValues, elementsCurrentOptions = [], elementSettings = {}) => {
		const { sectionElements, name } = section;
		let shape = {};
		const newElementOptions = [];

		for (const e of sectionElements) {
			const elementCurrentOptions = elementsCurrentOptions.find((eCS) => eCS.key === e.key);
			const elementDependsOn = e.settings.dependsOn;
			let isRequired = true;

			//zakładam, że jeśli element ma dependsOn oraz wartość pola z depends on jest !== od dependsOn.value, to isRequired = false
			//to sytuacja kiedy włączamy/wyłączamy rejestr, a pole jest ukryte np. przez toggle, nie chcemy go walidować
			if (elementDependsOn && elementCurrentOptions?.includeInConfiguration && formValues) {
				const dependentValueIsEqualFormValue =
					elementDependsOn.isEqual === false
						? elementDependsOn.value !== formValues[elementDependsOn.key]
						: elementDependsOn.value === formValues[elementDependsOn.key];
				if (!dependentValueIsEqualFormValue) isRequired = false;
			}

			//zmiana schema w przypadku wlączania/wyłączania rejestrów - nie działa kiedy wyłączymy flagę enableRegisterSelection w konfiguratorze
			const elementSettingsValues = elementSettings.key === e.key ? elementSettings.value : {};
			const elementOptions = {
				...e,
				settings: { ...getCustomSettings(e.key, e.settings), ...elementSettingsValues }
			};

			//zapewnia poprawne ustawienie isRequired dla elementu w zależności od tego czy dana grupa jest wzięta do konfiguracji czy nie
			if (elementCurrentOptions) {
				elementOptions.isRequired = elementCurrentOptions.includeInConfiguration
					? isRequired
					: elementCurrentOptions.includeInConfiguration;
			}

			newElementOptions.push(elementOptions);

			const schema = generateSchema(elementOptions, elementCurrentOptions?.includeInConfiguration, translate);
			if (schema) shape[e.key] = schema;
		}

		updateDatasFormElementsSettings({ name, sectionElements: newElementOptions });

		return generateSchemaShape(shape);
	};

	return { createSchema };
};

export function generateSchemaShape(schemaObj) {
	return Yup.object().shape(schemaObj);
}

export function generateSchema(element, includeInConfiguration, translate) {
	const { type, min, max, regex } = element.settings;

	switch (type) {
		case 'number':
		case 'omsBatteryLifeThreshold': {
			let schema = element.nullable ? Yup.string().nullable() : Yup.string();

			if (element.isRequired) schema = schema.required('Common_ValidationRequired');
			if (regex == null && min == null && max == null) return schema;
			if (!includeInConfiguration) return schema;

			return schema.test('is-decimal', 'Common_InvalidDataFormat', (value) => {
				let isMatch = true;
				let isInRange = true;

				const valueAsNumber = value ? Number(value.replace(',', '.')) : null;

				if (element.isRequired && element.nullable && valueAsNumber == null) return false;
				if (!element.isRequired && valueAsNumber == null) return true;
				if (regex) isMatch = (valueAsNumber + '').match(/^-?\d{0,10}[.,]?\d{0,3}$/);
				if (min !== null && max !== null)
					isInRange = Number(valueAsNumber) >= min && Number(valueAsNumber) <= max;

				return isMatch && isInRange;
			});
		}

		case 'omsFrameSendingPeriod': {
			let schema = element.nullable ? Yup.number().nullable() : Yup.number();

			if (element.isRequired) schema = schema.required('Common_ValidationRequired');
			if (regex == null && min == null && max == null) return schema;
			if (!includeInConfiguration) return schema;

			return schema.test('is-valid-period-value', 'Dconf_FramesSendingPeriodInvalidFormat', (value) => {
				let isInRange = true;

				if (element.isRequired && element.nullable && value == null) return false;
				if (!element.isRequired && value == null) return true;
				if (min !== null && max !== null) isInRange = Number(value) >= min && Number(value) <= max;

				const isCorrectValue = Number(value) % 125 === 0;

				return isCorrectValue && isInRange;
			});
		}

		case 'numberInt': {
			let schema = element.nullable ? Yup.number().nullable() : Yup.number();

			if (element.isRequired) schema = schema.required('Common_ValidationRequired');
			if (regex == null && min == null && max == null) return schema;
			if (!includeInConfiguration) return schema;

			return schema.test('is-int', 'Common_InvalidDataFormat', (value) => {
				let isMatch = true;
				let isInRange = true;

				if (element.isRequired && element.nullable && value == null) return false;
				if (!element.isRequired && value == null) return true;
				if (regex) isMatch = (value + '').match(/^(?:-)?[\d{1,2}]+(?:\.\d)?$/);
				if (min !== null && max !== null) isInRange = Number(value) >= min && Number(value) <= max;

				return isMatch && isInRange;
			});
		}

		case 'hourOfDay':
		case 'dayOfWeek':
		case 'monthOfYear':
		case 'dayOfMonth': {
			return element.nullable ? Yup.array().nullable() : Yup.array();
		}

		case 'checkboxGroup':
		case 'list': {
			let schema = element.nullable ? Yup.array().nullable() : Yup.array();

			if (element.isRequired && !element.nullable)
				schema = schema.min(1, 'Common_ValidationRequired').required('Common_ValidationRequired');

			return schema;
		}

		case 'omsScheduleCalendar':
			break;

		case 'omsDatetime': {
			let schema = element.nullable ? Yup.string().nullable() : Yup.string();

			if (element.isRequired) schema = schema.required('Common_ValidationRequired');

			return schema.test('is-date', 'Common_InvalidDataFormat', (value) => {
				if (element.isRequired && element.nullable && value == null) return false;
				if (!element.isRequired && value == null) return true;

				return value !== 'Invalid date';
			});
		}

		case 'monthYear': {
			let schema = element.nullable ? Yup.string().nullable() : Yup.string();

			if (element.isRequired) schema = schema.required('Common_ValidationRequired');

			return schema.test('is-monthYearFormat', 'Common_InvalidDataFormat', (value) => {
				if (element.isRequired && element.nullable && value == null) return false;
				if (!element.isRequired && value == null) return true;

				return value !== 'Invalid date';
			});
		}

		case 'aesKey': {
			let schema = Yup.string().matches(
				/^(([0-9ABCDEFabcdef]){2} ){15}([0-9ABCDEFabcdef]){2}$/,
				'Common_InvalidDataFormat'
			);

			if (element.nullable) schema = schema.nullable();
			if (element.isRequired) schema = schema.required('Common_ValidationRequired');

			return schema;
		}

		case 'text': {
			let schema = element.nullable ? Yup.string().nullable() : Yup.string();

			if (element.isRequired) schema = schema.required('Common_ValidationRequired');
			if (min !== null) schema = schema.min(min, translate('Common_ValidationMinLength', min));
			if (max !== null) schema = schema.max(max, translate('Common_ValidationMaxLength', max));

			return schema;
		}

		default: {
			let schema = element.nullable ? Yup.string().nullable() : Yup.string();

			if (element.isRequired) schema = schema.required('Common_ValidationRequired');

			return schema;
		}
	}
}
