import React from 'react';
import { FormControl, FormHelperText, useTheme } from '@mui/material';
import SelectDayOfMonth from '../../../../components/SelectDayOfMonth/SelectDayOfMonth';
import ConfiguratorControlLabel from './ConfiguratorControlLabel';

const ConfiguratorDayOfMonthSingle = ({ name, value, element, form, dataAtError, helperText }) => {
	const { defaultValue, max, description } = element;
	const theme = useTheme();

	const initial = value || defaultValue;

	const handleOnChange = (v) => form.setFieldValue(name, v[0]);

	return (
		<FormControl fullWidth margin='normal'>
			<ConfiguratorControlLabel label={description ?? 'Common_DayOfMonth'} marginBottom={10} />
			<SelectDayOfMonth single max={max} initial={[initial]} includeLastDay={false} onChange={handleOnChange} />
			<FormHelperText data-at-error={dataAtError} style={{ color: theme.palette.error.main }}>
				{helperText}
			</FormHelperText>
		</FormControl>
	);
};

export default ConfiguratorDayOfMonthSingle;
