import React from 'react';
import BaseIcon from './BaseIcon';

export default function ArrowRightIcon(props) {
   return (
      <BaseIcon {...props}>
         <path d='m 12.941406,4.7353516 -1.414062,1.4140625 4.851562,4.8515629 H 3.7929689 v 1.999999 H 16.378906 l -4.851562,4.849609 1.414062,1.414063 5.851563,-5.851563 1.414062,-1.412109 -1.414062,-1.412108 z' />
      </BaseIcon>
   );
}
