import React, { useState } from 'react';
import { FormControl, FormControlLabel, Switch } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '../../../../hooks';
import { BaseDateTimePicker, dateAsIsoStringLocal, dateAsIsoString } from '../../../..';

const useStyles = makeStyles(() => ({
	formControlLabelRoot: {
		marginLeft: -6
	}
}));

const ConfiguratorToggleableDatetimefield = ({ name, value, form, element, error, helperText }) => {
	const classes = useStyles();
	const { translate } = useLocale();
	const { option } = element;
	const [selected, setSelected] = useState(null);
	const [isOpen, setIsOpen] = useState(false);
	const [switchValue, setSwitchValue] = useState(!(option && value === option.offValue));
	const switchLabel = switchValue ? option.onLabel : option.offLabel;
	const isUTC = option.offValue ? option.offValue.split('').pop() === 'Z' : false;

	const getResultDate = (data) => (isUTC ? dateAsIsoString(data) : dateAsIsoStringLocal(data));

	const handleOnChange = (v) => setSelected(v);

	const handleSwitchChange = () => {
		form.setFieldValue(name, switchValue ? option.offValue : getResultDate(new Date()));
		setSwitchValue(!switchValue);
	};

	return (
		<FormControl fullWidth margin='normal'>
			<FormControlLabel
				name={name}
				label={translate(switchLabel)}
				classes={{ root: classes.formControlLabelRoot }}
				control={<Switch size='small' color='primary' checked={switchValue} onChange={handleSwitchChange} />}
			/>
			{switchValue && (
				<BaseDateTimePicker
					open={isOpen}
					onOpen={() => setIsOpen(true)}
					name={name}
					value={value}
					helperText={helperText}
					error={error}
					variant='inline'
					strictCompareDates
					fullWidth
					onChange={handleOnChange}
					onClose={() => {
						if (selected) form.setFieldValue(name, getResultDate(selected));
						setIsOpen(false);
					}}
				/>
			)}
		</FormControl>
	);
};

export default ConfiguratorToggleableDatetimefield;
