import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, TextField } from '@mui/material';
import {
	apiBase,
	PanelListItemActionBack,
	PanelListItemActionConfirm,
	VerticalSpacer,
	YupSchemaDefinitions
} from '@sonar-web/common';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import { useLocale } from '@sonar-web/common/src/hooks';

const slice = 'configurator';

const TemplateEdit = ({ record, setEditMode, props, onSuccess }) => {
	const dispatch = useDispatch();
	const { translate } = useLocale();
	const [pending, setPending] = useState(false);
	const [templateName, setTemplateName] = useState(record.templateName);
	const [error, setError] = useState('');

	const handleCancel = (e) => {
		e.stopPropagation();
		setEditMode(false);
	};

	const handleEdit = async (e) => {
		e.stopPropagation();

		try {
			setPending(true);
			const newTemplate = { ...record, templateName };
			delete newTemplate.recipients;

			await editTemplate(props, newTemplate);
			setPending(false);
			onSuccess(newTemplate);
		} catch (err) {
			dispatch(addErrorAsync({ slice, error: err }));
			setPending(false);
		}
	};

	const handleValidation = (v, err) => {
		const isValid = v !== '' && !err;

		setTemplateName(v);
		setError(isValid ? '' : err);
	};

	const handleChange = (e) => {
		const name = e.target.value;
		const nameSchema = YupSchemaDefinitions.shortString();

		try {
			nameSchema.validateSync(name);
			handleValidation(name);
		} catch (err) {
			handleValidation(name, err.errors[0]);
		}
	};

	return (
		<>
			<TextField
				autoFocus
				margin='normal'
				name='templateName'
				type='text'
				size='small'
				fullWidth
				value={templateName}
				onChange={handleChange}
				error={error !== ''}
				helperText={error !== '' && translate(error)}
			/>
			<Box display='flex' alignSelf={error === '' ? 'flex-end' : 'center'}>
				<PanelListItemActionBack onClick={handleCancel} disabled={pending} />
				<VerticalSpacer size={1} />
				<PanelListItemActionConfirm onClick={handleEdit} disabled={pending || error !== ''} />
			</Box>
		</>
	);
};

export default TemplateEdit;

async function editTemplate(props, record) {
	const { servicePath, templateEditEndpoint, templateEditEndpointPathParams } = props;

	let url = templateEditEndpoint;

	if (templateEditEndpointPathParams?.length > 0)
		templateEditEndpointPathParams.forEach((ep) => {
			url = url.replace(`{${ep}}`, `${record[ep]}`);
		});

	const { data } = await apiBase(url, 'put', {
		path: servicePath,
		data: record
	});

	return data;
}
