import React from 'react';
import { Toolbar, Button, Box } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { Spacer, VerticalSpacer } from '@sonar-web/common';

const EditSkeleton = () => {
	return (
		<Box flex='1' width={500} alignSelf='center'>
			<Skeleton animation='wave' variant='text' height={50} />
			<Skeleton animation='wave' variant='text' height={50} />
			<Toolbar disableGutters>
				<Spacer />
				<Skeleton>
					<Button />
				</Skeleton>
				<VerticalSpacer size={2} />
				<Skeleton>
					<Button />
				</Skeleton>
			</Toolbar>
		</Box>
	);
};

export default EditSkeleton;
