import React from 'react';
import BaseIcon from './BaseIcon';

export default function ReadInstantIcon(props) {
	return (
		<BaseIcon {...props}>
			<path d='m 12,2.2473962 c -5.3814176,0 -9.7526039,4.371187 -9.7526039,9.7526038 10e-8,5.381417 4.3711864,9.752604 9.7526039,9.752604 5.381418,0 9.752604,-4.371187 9.752604,-9.752604 0,-5.3814167 -4.371186,-9.7526037 -9.752604,-9.7526038 z m 0,0.669271 c 5.021124,0 9.083333,4.062209 9.083333,9.0833328 0,5.021124 -4.062209,9.083333 -9.083333,9.083333 C 6.9788759,21.083333 2.916667,17.021124 2.9166668,12 2.9166668,6.9788762 6.9788759,2.9166672 12,2.9166672 Z m 0.04167,2.484374 a 0.33333348,0.33333348 0 0 0 -0.335938,0.333334 v 6.0572908 l -2.5364578,2.536459 a 0.33333348,0.33333348 0 0 0 0,0.471353 0.33333348,0.33333348 0 0 0 0.4713542,0 L 12.372396,12.065104 V 5.7343752 a 0.33333348,0.33333348 0 0 0 -0.330729,-0.333334 z' />
		</BaseIcon>
	);
}
