export const API_BASE_URL = process.env.REACT_APP_REST_API ?? window.location.origin;
export const AUTHORITY = `${API_BASE_URL}/auth/realms/sonar-web`;
export const CLIENT_ID = 'sonar-web-client';

export const REST_API_PATHS = {
	ACCESS: `${process.env.REACT_APP_REST_API_ACCESS}`,
	LOCATION: `${process.env.REACT_APP_REST_API_LOCATION}`,
	WATERMETERS: `${process.env.REACT_APP_REST_API_WATERMETERS}`,
	HEATALLOCATORS: `${process.env.REACT_APP_REST_API_HEATALLOCATORS}`,
	WMBUS: `${process.env.REACT_APP_REST_API_WMBUS}`,
	TCP: `${process.env.REACT_APP_REST_API_TCP}`,
	SETS: `${process.env.REACT_APP_REST_API_SETS}`,
	IMPORTS: `${process.env.REACT_APP_REST_API_IMPORTS}`,
	SEARCH: `${process.env.REACT_APP_REST_API_SEARCH}`,
	EXPORT: `${process.env.REACT_APP_REST_API_EXPORT}`,
	STATIONARY: `${process.env.REACT_APP_REST_API_STATIONARY}`,
	STATS: `${process.env.REACT_APP_REST_API_STATS}`,
	BILLING: `${process.env.REACT_APP_REST_API_BILLING}`,
	LORA: `${process.env.REACT_APP_REST_API_LORA}`,
	REPORTS: `${process.env.REACT_APP_REST_API_REPORTS}`,
	EVENTS: `${process.env.REACT_APP_REST_API_EVENTS}`,
	DEVICECONFIGS: `${process.env.REACT_APP_REST_API_DEVICECONFIGS}`,
	ORDER: `${process.env.REACT_APP_REST_API_ORDER}`,
	TENANT: `${process.env.REACT_APP_REST_API_TENANT}`,
	HEATMETER: `${process.env.REACT_APP_REST_API_HEATMETER}`,
	MESHHUB: `${process.env.REACT_APP_REST_API_MESHHUB}`,
	MESHNETWORK: `${process.env.REACT_APP_REST_API_MESHNETWORK}`,
	MESHTERMINAL: `${process.env.REACT_APP_REST_API_MESHTERMINAL}`,
	CUSTOMER: `${process.env.REACT_APP_REST_API_CUSTOMER}`,
	USAGE: `${process.env.REACT_APP_REST_API_USAGE}`
};

export const XBI_SERVER = process.env.REACT_APP_XBI_SERVER;

export const APP_VERSION = process.env.REACT_APP_VERSION || '';

export const SCHEDULER_API = process.env.REACT_APP_REST_API_SCHEDULER;

export const TENANT_ENABLED = process.env.REACT_APP_ENABLE_TENANT;

export const AUTHORIZATION_DISABLED = process.env.REACT_APP_DISABLE_AUTH === '1';
