import { filterOperators } from '@sonar-web/common';
import { useFilterDescriptor } from '@sonar-web/common/src/hooks';
import { addDevice } from '../Device/deviceApi';
import { fetchDeviceTypes } from '../DeviceTypesSearch/api';

export default () => {
	const { createFilter } = useFilterDescriptor();

	const addDeviceNew = (values) =>
		addDevice({
			number: values.number,
			deviceTypeId: values.deviceTypeId
		});

	const fetchDeviceTypeById = (deviceTypeId) => {
		const pageDescriptor = {
			Offset: 0,
			SortDescriptors: [],
			IncludeTotalRowCount: true,
			FilterDescriptors: createFilter([
				{
					member: { name: 'id', type: 'guid' },
					value: deviceTypeId,
					operator: filterOperators.equal
				}
			])
		};

		return fetchDeviceTypes(pageDescriptor);
	};

	return {
		addDevice: addDeviceNew,
		fetchDeviceTypeById: fetchDeviceTypeById
	};
};
