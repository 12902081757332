import React from 'react';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
	containerRoot: {
		minHeight: (props) => props.minHeight
	},
	grid: {
		display: 'grid',
		gridTemplateColumns: '1fr 1fr 1fr',
		gridAutoRows: (props) => `minmax(${props.minCardHeight}px, auto)`,
		alignItems: 'center',
		gap: '10px',
		padding: (props) => props.gridPadding,
		[theme.breakpoints.down('md')]: {
			gridTemplateColumns: '1fr 1fr'
		}
	}
}));

const CardsGridBase = ({ minHeight = 0, gridPadding, minCardHeight = 50, children, ...rest }) => {
	const classes = useStyles({
		gridPadding: gridPadding,
		minCardHeight: minCardHeight,
		minHeight: minHeight
	});

	return (
		<Box
			display='flex'
			flexDirection='column'
			flex={1}
			className={'cardsGridBase ' + classes.containerRoot}
			{...rest}>
			<Box className={classes.grid}>{children}</Box>
		</Box>
	);
};

export default CardsGridBase;
