import { createSlice } from '@reduxjs/toolkit';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import { fetchDiagnosticConverterReads } from '../converterDiagnosticApi';
const slice = 'converterDiagnosticReadsTimeline';

export const converterDiagnosticReadsTimelineSlice = createSlice({
	name: slice,
	initialState: {
		pending: false,
		reset: false,
		reads: [],
		firstEntryDate: null,
		lastEntry: null
	},
	reducers: {
		fetchConverterDiagnosticReadsSucces: (state, action) => {
			const { elements, firstEntryDate, lastEntry } = action.payload;

			state.reads = elements;
			state.firstEntryDate = firstEntryDate;
			state.lastEntry = lastEntry;
			state.pending = false;
			state.reset = false;
		},
		fetchConverterDiagnosticReadsPending: (state, action) => {
			state.pending = action.payload;
		},
		setResetAndReload: (state) => {
			state.reset = true;
		}
	}
});

export const {
	fetchConverterDiagnosticReadsSucces,
	fetchConverterDiagnosticReadsPending,
	setResetAndReload
} = converterDiagnosticReadsTimelineSlice.actions;

export const fetchConverterDiagnosticReadsAsync = ({ pageDescriptor }) => async (dispatch) => {
	try {
		dispatch(fetchConverterDiagnosticReadsPending(true));
		const response = await fetchDiagnosticConverterReads(pageDescriptor);

		dispatch(fetchConverterDiagnosticReadsSucces(response));
	} catch (error) {
		dispatch(fetchConverterDiagnosticReadsPending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const selectConverterDiagnosticReads = (state) => {
	return {
		firstEntryDate: state.converterDiagnosticReadsTimeline.firstEntryDate,
		lastEntry: state.converterDiagnosticReadsTimeline.lastEntry,
		reads: state.converterDiagnosticReadsTimeline.reads,
		pending: state.converterDiagnosticReadsTimeline.pending,
		reset: state.converterDiagnosticReadsTimeline.reset
	};
};

export default converterDiagnosticReadsTimelineSlice.reducer;
