export const Months = {
	January: {
		name: 'January',
		value: 1
	},
	February: {
		name: 'February',
		value: 2
	},
	March: {
		name: 'March',
		value: 3
	},
	April: {
		name: 'April',
		value: 4
	},
	May: {
		name: 'May',
		value: 5
	},
	June: {
		name: 'June',
		value: 6
	},
	July: {
		name: 'July',
		value: 7
	},
	August: {
		name: 'August',
		value: 8
	},
	September: {
		name: 'September',
		value: 9
	},
	October: {
		name: 'October',
		value: 10
	},
	November: {
		name: 'November',
		value: 11
	},
	December: {
		name: 'December',
		value: 12
	}
};

export const getMonthByValue = (value) => {
	return Object.values(Months).find((month) => month.value === value);
};
