import styled from '@emotion/styled';
import { Skeleton, Typography, Breadcrumbs } from '@mui/material';
import { useLocale } from '@sonar-web/common/src/hooks';
import LinkRouter from './LinkRouter';

const StyledBreadcrumb = styled(Breadcrumbs)({
	paddingBottom: '1rem',
	'& li a': {
		textDecoration: 'none'
	}
});

const NavigationBreadcrumbs = ({ routes, lastRoute, homepage = true, pending }) => {
	const { translate } = useLocale();

	if (pending)
		return (
			<StyledBreadcrumb maxItems={9} separator='›' aria-label='breadcrumb loading'>
				<Skeleton width={150} />
				<Skeleton width={150} />
				<Skeleton width={150} />
			</StyledBreadcrumb>
		);

	return (
		<StyledBreadcrumb maxItems={9} separator='›' aria-label='breadcrumb' className='navigationBreadcrumbs'>
			{homepage && (
				<LinkRouter to='/' color='textSecondary'>
					{translate('Common_DashboardPage')}
				</LinkRouter>
			)}
			{routes?.map((r) => {
				return (
					<LinkRouter to={r.path} key={r.name} color='textSecondary'>
						{translate(r.name)}
					</LinkRouter>
				);
			})}
			{lastRoute && (
				<Typography variant='body2' component='a' color='textPrimary' fontWeight='medium'>
					{translate(lastRoute)}
				</Typography>
			)}
		</StyledBreadcrumb>
	);
};

export default NavigationBreadcrumbs;
