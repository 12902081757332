import LicenseItem from './LicenseItem';
import MaxDevicesCount from './MaxDevicesCount';
import MaxDevicesLicenseIcon from '../Icons/MaxDevicesLicenseIcon';

const MaxDevices = ({ pending, data }) => {
	return (
		<LicenseItem
			pending={pending}
			data={
				data
					? {
							Icon: MaxDevicesLicenseIcon,
							title: 'MeasuringDevices',
							description: 'LicenseMaxDevicesDescription',
							primaryData: {
								value: data.maxNumberOfMeters,
								text: 'Limit'
							}
					  }
					: null
			}>
			{data && !pending && <MaxDevicesCount maxNumberOfMeters={data.maxNumberOfMeters} />}
		</LicenseItem>
	);
};

export default MaxDevices;
