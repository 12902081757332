import React from 'react';
import clsx from 'clsx';
import { Box, Typography, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '../../../../hooks';
import ConfiguratorElement from './ConfiguratorElement';
import useConfigurator from '../useConfigurator';
import ConfiguratorGroupSelection from './ConfiguratorGroupSelection';

const useStyles = makeStyles((theme) => ({
	groupWrapper: {
		display: 'flex',
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
		borderBottom: `1px solid ${theme.palette.divider}`,
		'&$groupWrapper:last-of-type': {
			borderBottomColor: 'transparent'
		}
	},
	disabled: {
		display: 'none'
	},
	groupLeft: {
		padding: `${theme.spacing(2)} ${theme.spacing(4)} ${theme.spacing(2)} 0`,
		width: 280
	},
	groupRight: {
		padding: `0 ${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(4)}`,
		flex: 1,
		position: 'relative'
	},
	nameRoot: {
		fontWeight: theme.typography.fontWeightMedium
	},
	descriptionRoot: {
		lineHeight: 1.3,
		marginTop: 4
	},
	readOnlyPane: {
		position: 'absolute',
		top: 0,
		left: 0,
		zIndex: 10,
		width: '100%',
		height: '100%',
		backgroundColor: 'rgba(255, 255, 255, 0.4)'
	}
}));

const ConfiguratorGroup = ({ name, description, form, elements, forceUpdateValidationSchema }) => {
	const classes = useStyles();
	const { translate } = useLocale();
	const { isReadOnly, getGroupIncludeInConfiguration, isRegisterSelectionModeEnabled, isGroupDisabled } =
		useConfigurator();
	const disabledGroup = isGroupDisabled(name);

	const isGroupIncludedInConfiguration = isRegisterSelectionModeEnabled ? getGroupIncludeInConfiguration(name) : true;

	return (
		<Box
			className={clsx(`${classes.groupWrapper} groupWrapper`, { [classes.disabled]: disabledGroup })}
			data-at={`configuratorGroup|${name}`}>
			<Box className={classes.groupLeft}>
				<Typography variant='body2' component='p' classes={{ root: classes.nameRoot }}>
					{translate(name)}
				</Typography>
				<Typography variant='caption' component='p' classes={{ root: classes.descriptionRoot }}>
					{translate(description)}
				</Typography>
				<ConfiguratorGroupSelection
					form={form}
					groupName={name}
					isGroupIncludedInConfiguration={isGroupIncludedInConfiguration}
				/>
			</Box>
			<Divider orientation='vertical' flexItem />
			<Box className={classes.groupRight}>
				{(isReadOnly || !isGroupIncludedInConfiguration) && (
					<Box className={classes.readOnlyPane} onClick={(e) => e.stopPropagation()} />
				)}
				{elements.map((element, index) => {
					return (
						<ConfiguratorElement
							key={index}
							element={element}
							form={form}
							group={{ name, included: isGroupIncludedInConfiguration }}
							forceUpdateValidationSchema={forceUpdateValidationSchema}
						/>
					);
				})}
			</Box>
		</Box>
	);
};

export default ConfiguratorGroup;
