import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
	background: {
		width: '100%',
		height: '100%',
		maxHeight: 310,
		marginBottom: '4vh',
		marginTop: '10vh',
		backgroundImage: ({ background }) => `url(${background})`,
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center'
	},
	confirmationWrapper: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
		minHeight: 400
	},
	backgroundHorizontal: {
		margin: 0,
		maxHeight: 100,
		width: 200
	},
	horizontal: {
		flexDirection: 'row',
		alignItems: 'center',
		minHeight: '100%',
		justifyContent: 'center'
	},
	children: {
		textAlign: 'center',
		marginBottom: '10vh'
	},
	childrenHorizontal: {
		textAlign: 'left',
		marginBottom: 0
	}
}));

const ConfirmationPage = ({ children, background, horizontalBackground, slices, ...rest }) => {
	const [horizontal, setHorizontal] = React.useState(false);
	const classes = useStyles({ background: horizontal ? horizontalBackground : background });

	const wrapperRef = React.useRef(null);
	const horizontalStateRef = React.useRef(horizontal);

	const setHorizontalState = (data) => {
		horizontalStateRef.current = data;
		setHorizontal(data);
	};

	const onResize = () => {
		const { height } = wrapperRef.current.getBoundingClientRect();
		const currentHeight = Math.floor(height);

		if (currentHeight === 400 && !horizontalStateRef.current) setHorizontalState(true);

		if (currentHeight > 400 && horizontalStateRef.current) setHorizontalState(false);
	};

	useEffect(() => {
		const { height } = wrapperRef.current.getBoundingClientRect();
		if (height <= 400) setHorizontalState(true);

		window.addEventListener('resize', onResize);
		return () => window.removeEventListener('resize', onResize);
	}, []);

	return (
		<div
			ref={wrapperRef}
			className={clsx(classes.confirmationWrapper, { [classes.horizontal]: horizontal })}
			{...rest}>
			<div className={clsx(classes.background, { [classes.backgroundHorizontal]: horizontal })} />
			<div className={clsx(classes.children, { [classes.childrenHorizontal]: horizontal })}>
				{children({ horizontal })}
			</div>
		</div>
	);
};

export default ConfirmationPage;
