import { useDispatch, useSelector } from 'react-redux';
import {
	setActiveTab,
	setData,
	selectIsInEditMode,
	setEditMode,
	selectIsInProfileSubmitMode,
	setProfileSubmitMode,
	selectIsInSectionEditMode,
	setSectionEditMode,
	fetchDatasFormAsync,
	selectDatasForm,
	setProfileData,
	setManualMode,
	setReadOnlyMode,
	selectConfigurationData,
	selectCurrentData,
	selectProfileSubmit,
	selectProfileData,
	selectReadOnly,
	selectConfigSubmit,
	configSubmitPending,
	setRegisterSelectionMode,
	setDatasFormRegister,
	selectDatasFormRegisters,
	selectRegisterSelectionMode,
	setConfigurationName,
	setDisabledRegisters,
	selectDsiabledRegisters,
	setDatasFormElementsSettings
} from './deviceConfiguratorSlice';

const slice = 'deviceConfigurator';

export default () => {
	const dispatch = useDispatch();
	const isInProfileSubmitMode = useSelector(selectIsInProfileSubmitMode);
	const isInEditMode = useSelector(selectIsInEditMode);
	const isInSectionEditMode = useSelector(selectIsInSectionEditMode);
	const currentData = useSelector(selectCurrentData);
	const profileData = useSelector(selectProfileData);
	const isReadOnly = useSelector(selectReadOnly);
	const submitConfigPending = useSelector(selectConfigSubmit);
	const datasFormRegisters = useSelector(selectDatasFormRegisters);
	const isRegisterSelectionModeEnabled = useSelector(selectRegisterSelectionMode);
	const disabledRegisters = useSelector(selectDsiabledRegisters);
	const sectionsElements = useSelector((state) => state.deviceConfigurator.datasFormElementsSettings);

	const { datasForm, fetchDatasFormPending } = useSelector(selectDatasForm);

	const getDatasFormElementsSettings = (name) => sectionsElements[name];

	const updateDatasFormElementsSettings = (data) => dispatch(setDatasFormElementsSettings(data));

	const loadData = (data) => dispatch(setData(data));

	const updateConfigurationName = (name) => dispatch(setConfigurationName(name));

	const openProfilesTab = () => dispatch(setActiveTab(0));

	const openDatasTab = () => dispatch(setActiveTab(1));

	const openCancelTab = () => dispatch(setActiveTab(2));

	const openSubmitTab = () => dispatch(setActiveTab(3));

	const toggleEditMode = (data) => dispatch(setEditMode(data));

	const toggleProfileSubmitMode = (data) => dispatch(setProfileSubmitMode(data));

	const toggleSectionEditMode = (data) => dispatch(setSectionEditMode(data));

	const getProfileSubmitData = () => useSelector(selectProfileSubmit);

	const updateProfileData = (data, isFetchedProfile) => dispatch(setProfileData({ ...data, isFetchedProfile }));

	const selectSectionDatas = (section) => {
		const keysArray = [];

		for (const t of section.tabs) {
			for (const g of t.groups) {
				for (const e of g.elements) {
					keysArray.push(e.key);
				}
			}
		}

		const values = currentData?.deviceConfiguration?.deviceConfigurationItems;

		const result = {};

		if (values.length === 0) {
			for (const ka of keysArray) {
				result[ka] = '';
			}
			return result;
		}

		const sectionValues = values.filter((v) => keysArray.includes(v.key));

		for (const sv of sectionValues) {
			result[sv.key] = sv.value;
		}

		return result;
	};

	const enterManualMode = () => dispatch(setManualMode());

	const enterReadOnlyMode = () => dispatch(setReadOnlyMode());

	const getCurrentData = () => currentData;

	const getProfileData = () => profileData;

	const getCurrentKeyValue = (key) =>
		currentData.deviceConfiguration.deviceConfigurationItems.find((dci) => dci.key === key)?.value;

	const hasConfiguration = () => useSelector(selectConfigurationData) !== null;

	const updateSubmitPending = (data) => dispatch(configSubmitPending(data));

	const updateSubmitError = (error) => dispatch(addErrorAsync({ slice, error }));

	//obiekt przekazywany do fukcji onSubmit w przypadku własnej obsługi
	const submitConfigState = () => {
		let values = currentData.deviceConfiguration.deviceConfigurationItems;

		if (isRegisterSelectionModeEnabled) {
			const keysToInclude = datasFormRegisters
				.filter((dfr) => dfr.includeInConfiguration === true)
				.map((dfr) => dfr.key);

			values = values.filter((v) => keysToInclude.includes(v.key));
		}

		return {
			pending: submitConfigPending,
			setPending: updateSubmitPending,
			setError: updateSubmitError,
			values: values,
			allValues: currentData.deviceConfiguration.deviceConfigurationItems,
			name: currentData.name,
			id: currentData.id
		};
	};

	//włącza możliwość wyboru grup rejestrów - odpala się raz na początku, w zależności od danych wejściowych
	const enableRegisterSelection = () => dispatch(setRegisterSelectionMode(true));

	//wł/wył grupę rejestrów
	const updateGroupIncludeInConfiguration = (groupName, value) => {
		dispatch(setDatasFormRegister({ groupName, value }));
	};

	//pobiera tylko te rejestry, które mają być zapisane w konfiguracji
	const getGroupIncludeInConfiguration = (groupName) => {
		if (datasFormRegisters.length === 0) return true;
		return datasFormRegisters.find((dfr) => dfr.group === groupName && dfr.key != null)?.includeInConfiguration;
	};

	const revertChangesToDatasFormRegisters = (initialRegisters) => {
		dispatch(setDatasFormRegister({ updateAll: initialRegisters }));
	};

	const disableAndHideGroupByKey = (key) => {
		const group = datasFormRegisters.find((dfr) => dfr.key === key)?.group;
		if (group) dispatch(setDisabledRegisters(group));
	};

	const isGroupDisabled = (groupName) => disabledRegisters.includes(groupName);

	return {
		openProfilesTab,
		openDatasTab,
		openCancelTab,
		openSubmitTab,
		loadData,
		updateConfigurationName,
		toggleEditMode,
		toggleProfileSubmitMode,
		toggleSectionEditMode,
		getProfileSubmitData,
		getCurrentData,
		getProfileData,
		getCurrentKeyValue,
		updateProfileData,
		selectSectionDatas,
		enterManualMode,
		enterReadOnlyMode,
		hasConfiguration,
		isInEditMode,
		isInProfileSubmitMode,
		isInSectionEditMode,
		isReadOnly,
		fetchDatasFormAsync,
		datasForm,
		fetchDatasFormPending,
		submitConfigState,
		submitConfigPending,
		updateGroupIncludeInConfiguration,
		enableRegisterSelection,
		isRegisterSelectionModeEnabled,
		datasFormRegisters,
		getGroupIncludeInConfiguration,
		revertChangesToDatasFormRegisters,
		disableAndHideGroupByKey,
		isGroupDisabled,
		getDatasFormElementsSettings,
		updateDatasFormElementsSettings
	};
};
