import TileButtonSelectable from '@sonar-web/common/src/features/Tiles/TileButtonSelectable';
import TemplateIcon from '@sonar-web/common/src/icons/TemplateIcon';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import WorkflowType from './WorkflowType';

const TemplateSelect = ({ toggleList, setToggleList }) => {
	const handleClick = () => {
		if (toggleList !== WorkflowType.FromTemplate.value) setToggleList(WorkflowType.FromTemplate.value);
	};

	return (
		<TileButtonSelectable
			Icon={TemplateIcon}
			EndIcon={ArrowDropDownOutlinedIcon}
			endIconProps={{ style: { fontSize: '1.85rem' } }}
			title='Common_UseTemplate'
			selected={toggleList === WorkflowType.FromTemplate.value}
			onClick={handleClick}
			data-at='Common_UseTemplate'
		/>
	);
};

export default TemplateSelect;
