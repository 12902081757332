import React, { useState } from 'react';
import clsx from 'clsx';
import { FormControl, FormControlLabel, Switch } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '../../../../hooks';

const useStyles = makeStyles(() => ({
	switchHidden: {
		display: 'none'
	}
}));

const ConfiguratorTogglegroup = ({ name, value, form, element }) => {
	const classes = useStyles();
	const { translate } = useLocale();
	const { options } = element;
	const [regValue, setRegValue] = useState(value);
	const [toggleAll, setToggleAll] = useState(getToggleAllState(value, options));

	React.useEffect(() => {
		setToggleAll(getToggleAllState(value, options));
	}, [value]);

	const handleChangeAll = () => {
		setToggleAll(!toggleAll);

		const newValueSource = !toggleAll ? 'onValue' : 'offValue';
		let newValue = regValue;

		for (const [index, option] of options.entries()) {
			if (newValue[index] == option[newValueSource]) continue;

			newValue = getNewSwitchValue(index, option[newValueSource], newValue);
		}

		setRegValue(newValue);
		form.setFieldValue(name, newValue);
	};

	const handleChange = (e, v, index) => {
		const optionValue = options[index][v ? 'onValue' : 'offValue'];
		const newValue = getNewSwitchValue(index, optionValue, regValue);

		setRegValue(newValue);
		form.setFieldValue(name, newValue);
	};

	return (
		<FormControl fullWidth margin='normal'>
			<FormControlLabel
				label={translate(toggleAll ? 'Common_ToggleAllOff' : 'Common_ToggleAllOn')}
				control={<Switch size='small' color='primary' checked={toggleAll} onChange={handleChangeAll} />}
			/>
			{options.map((option, index) => {
				const isChecked = Boolean(regValue[index] == 1);

				return (
					<FormControlLabel
						key={index}
						label={translate(isChecked ? option.onLabel : option.offLabel)}
						className={clsx({ [classes.switchHidden]: !option.display })}
						control={
							<Switch
								size='small'
								color='primary'
								checked={isChecked}
								onChange={(e, v) => handleChange(e, v, index)}
							/>
						}
					/>
				);
			})}
		</FormControl>
	);
};

export default ConfiguratorTogglegroup;

function getToggleAllState(value, options) {
	let state = true;

	for (const [index, option] of options.entries()) {
		if (value[index] == 0 && option.display === true) {
			state = false;
			break;
		}
	}
	return state;
}

function getNewSwitchValue(index, optionValue, source) {
	return source.substr(0, index) + optionValue + source.substr(index + 1, source.length);
}
