import { CustomElementSettings } from './CustomElementSettings';
import useConfigurator from './useConfigurator';

const useCustomElementSettings = () => {
	const { getCurrentData } = useConfigurator();

	const getCustomSettings = (key, elementSettings) => {
		const customSettings = CustomElementSettings.get(key);
		if (!customSettings) return elementSettings;

		const data = getCurrentData().deviceConfiguration.deviceConfigurationItems;
		return customSettings(key, elementSettings, data);
	};

	return { getCustomSettings };
};

export default useCustomElementSettings;
