import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import PanelListItemAction from './PanelListItemAction';

const PanelListItemActionConfirm = ({ onClick, ...rest }) => {
	return (
		<PanelListItemAction
			icon={<CheckIcon fontSize='small' />}
			tooltip={'Common_Confirm'}
			onClick={onClick}
			{...rest}
		/>
	);
};

export default PanelListItemActionConfirm;
