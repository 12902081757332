import { useEffect } from 'react';
import { useDeps } from '@sonar-web/common/src/features/Dependencies/depsContext';
import ConverterDiagnostic from './Diagnostic/Converter/ConverterDiagnostic';
import TerminalDiagnostic from './Diagnostic/Terminal/TerminalDiagnostic';
import Module from './Constants/Module';

const RegisterStationaryNetworkModule = () => {
	const { setDeps, targetModules } = useDeps();

	useEffect(() => {
		setDeps(targetModules.tcp, Module.moduleName, {
			converterDiagnostic: {
				ConverterDiagnostic: ConverterDiagnostic
			}
		});
		setDeps(targetModules.wmbus, Module.moduleName, {
			terminalDiagnostic: {
				TerminalDiagnostic: TerminalDiagnostic
			}
		});
	}, []);

	return null;
};

export default RegisterStationaryNetworkModule;
