import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import BaseButton from '@sonar-web/common/src/components/Buttons/BaseButton';
const useStyles = makeStyles((theme) => ({
	root: {
		fontSize: '12px',
		minWidth: 'auto',
		transition: theme.transitions.create(['color'], {
			easing: theme.transitions.easing.easeInOut,
			duration: theme.transitions.duration.short
		}),
		color: theme.palette.action.active,
		'&:hover': {
			backgroundColor: `transparent !important`,
			color: theme.palette.text.primary
		}
	},
	active: {
		color: `${theme.palette.primary.main} !important`
	}
}));

const Button = React.forwardRef(function ButtonText({ size = 'medium', active, ...rest }, ref) {
	const classes = useStyles();

	return (
		<BaseButton
			ref={ref}
			variant='text'
			size={size}
			disableRipple
			disableFocusRipple
			classes={{
				root: active ? `${classes.root} ${classes.active}` : classes.root
			}}
			{...rest}
		/>
	);
});

export default Button;
