import { useEffect, useState } from 'react';
import { FormControl, FormControlLabel, FormHelperText, Radio, Typography, Box, RadioGroup } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '../../../../hooks';

const useStyles = makeStyles((theme) => ({
	radioGroupRoot: {
		paddingTop: 5
	},
	formControlLabelRoot: {
		marginRight: 0,
		marginLeft: -5,
		alignSelf: 'flex-start'
	},
	radioRoot: {
		padding: 4,
		'& svg': {
			fontSize: '1rem'
		}
	},
	removeSelectionBtn: {
		padding: 0,
		margin: 0,
		marginLeft: theme.spacing(1),
		background: 'transparent',
		border: 'none',
		cursor: 'pointer',
		color: theme.palette.primary.light,
		'&:hover': {
			color: theme.palette.primary.dark
		}
	}
}));

const ConfiguratorRadiogroup = ({ name, error, helperText, element, dataAtError, form, value }) => {
	const classes = useStyles();
	const { translate } = useLocale();
	const { options = [], nullable, description } = element;
	const [selected, setSelected] = useState(value);
	const emptyValue = nullable ? null : 'Null';

	const handleClick = (e) => {
		const newValue = !nullable || e.target.value !== selected ? e.target.value : emptyValue;
		setSelected(newValue);
	};

	const handleRemoveSelection = () => setSelected(emptyValue);

	useEffect(() => {
		if (value === selected) return;
		form.setFieldValue(name, selected);
		onBroadcastPropertyChange(name, selected, form);
	}, [selected]);

	return (
		<FormControl fullWidth component='fieldset' margin='normal' error={error}>
			<Box display='inline-flex' alignItems='center'>
				<Typography variant='caption' color='textSecondary'>
					{translate(name)}
				</Typography>
				{nullable && selected !== emptyValue && (
					<Typography
						variant='caption'
						component='button'
						onClick={handleRemoveSelection}
						classes={{ root: classes.removeSelectionBtn }}>
						{translate('Common_RemoveSelection')}
					</Typography>
				)}
			</Box>
			<RadioGroup value={selected} classes={{ root: classes.radioGroupRoot }}>
				{options.map((opt) => {
					return (
						<FormControlLabel
							key={opt.value}
							value={opt.value ?? 'Null'}
							control={
								<Radio
									color='primary'
									size='small'
									classes={{ root: classes.radioRoot }}
									onClick={handleClick}
								/>
							}
							label={translate(opt.label)}
							classes={{ root: classes.formControlLabelRoot }}
						/>
					);
				})}
			</RadioGroup>
			{description && (
				<Typography variant='caption' color='textSecondary' marginTop='0.5rem'>
					{translate(description)}
				</Typography>
			)}
			<FormHelperText data-at-error={dataAtError}>{helperText}</FormHelperText>
		</FormControl>
	);
};

export default ConfiguratorRadiogroup;

const onBroadcastPropertyChange = (name, value, form) => {
	if (name !== 'broadcast_property') return;
	form.setFieldValue('radio_receiving_property', value);
};
