import { Features, Restricted } from '@sonar/auth';
import { routes } from './Constants/Module';
import ConfigurationType from './Enums/ConfigurationType';
import DevicesConfigurationsIcon from '@sonar-web/common/src/icons/DevicesConfigurationsIcon';

const DevicesConfigurationsMenuItem = ({ MenuItemCmp }) => {
	return (
		<Restricted to={Features.DeviceConfigurationsPage.name}>
			<MenuItemCmp
				icon={DevicesConfigurationsIcon}
				header='Dconf_DeviceTypes'
				url={`${routes.configurations({ configType: ConfigurationType.Standalone.value })}`}
			/>
		</Restricted>
	);
};

export default DevicesConfigurationsMenuItem;
