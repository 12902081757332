export default () => {
	const format = (text, ...rest) => {
		const params = rest;
		let result = text;

		for (let i = 0; i < params.length; i++) {
			result = result.replace('{' + i + '}', params[i]);
		}

		return result;
	};

	return { format };
};
