export const VisualizationType = {
	All: {
		value: 1,
		name: 'All'
	},
	Details: {
		value: 2,
		name: 'Details'
	},
	Points: {
		value: 3,
		name: 'Points'
	}
};
