export const views = {
	basic: 0,
	templates: 1,
	additional: 2,
	clientData: 3,
	files: 4,
	submit: 5
};

export const initialViewsState = Object.entries(views)
	.map((o) => o[1])
	.reduce((acc, v) => {
		acc[v] = false;
		return acc;
	}, {});
