import React, { useEffect, useState } from 'react';
import { isEqual } from 'lodash-es';
import { DatePeriod, datePeriodRangeTypesArray, DatePeriodRange } from '@sonar-web/common';

const DatePeriodRangeDays = ({ stepIndex, useConfigurator, configuratorData, updateConfiguratorData, property }) => {
	const { updateStepValid } = useConfigurator();
	const values = configuratorData[property];
	const [settings, setSettings] = useState(values ?? null);

	useEffect(() => {
		if (settings == null) return;
		if (isEqual(values, settings)) return;

		updateConfiguratorData({ [property]: settings });
		updateStepValid(
			stepIndex,
			(settings?.option === DatePeriod.Own.name && settings?.days != null) || settings?.option != null
		);
	}, [settings]);

	useEffect(() => {
		if (isEqual(values, settings)) return;

		setSettings(values);
		updateStepValid(
			stepIndex,
			(values?.option === DatePeriod.Own.name && values?.days != null) || values?.option != null
		);
	}, [values]);

	return <DatePeriodRange options={datePeriodRangeTypesArray} selected={settings} setSelected={setSettings} />;
};

export default DatePeriodRangeDays;
