import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addErrorAsync } from '../features/Errors/errorsSlice';

export default function useFetchOnDemand({ api, slice }) {
	const dispatch = useDispatch();
	const [data, setData] = useState(null);
	const [pending, setPending] = useState(false);
	const [error, setError] = useState(null);

	const onSuccess = (res) => {
		setData(res);
		setPending(false);
	};

	const onError = async (res) => {
		const err = await dispatch(addErrorAsync({ slice, error: res }));

		setError(err);
		setPending(false);
	};

	const fetchFunc = useCallback(
		async (params) => {
			setPending(true);
			setData(null);
			setError(null);

			try {
				const response = await api(params);
				onSuccess(response);
			} catch (er) {
				onError(er);
			}
		},
		[api]
	);

	return { fetchFunc, data, pending, error };
}
