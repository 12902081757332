import React from 'react';
import BaseIcon from './BaseIcon';

export default function ReadByInkasentIcon(props) {
	return (
		<BaseIcon
			{...props}
			renderMultiPath={({ secondaryPathClass, secondaryPathColor }) => (
				<>
					<path d='M 10.354857,4.2341423 C 9.8468625,4.3016663 9.3765125,4.437082 8.9160545,4.667736 c -3.043536,1.5245773 -3.703849,5.502793 -1.316406,7.925781 l 0.587239,0.597657 -0.704426,0.333333 c -0.387975,0.183785 -0.922632,0.481589 -1.187501,0.661458 -0.626682,0.425572 -1.590558,1.350099 -2.066406,1.981771 -0.987364,1.31069 -1.675781,3.231382 -1.675781,4.675781 v 0.449219 h 0.677083 0.675781 l 0.04687,-0.669271 c 0.05707,-0.827679 0.274876,-1.623855 0.667969,-2.440104 0.350098,-0.72697 1.067976,-1.710866 1.584636,-2.171875 0.594604,-0.530558 1.683948,-1.19712 2.355468,-1.440104 2.3140665,-0.837326 4.7656205,-0.518542 6.7200525,0.873698 0.534192,0.38053 1.428495,1.291974 1.789063,1.821614 0.63608,0.934343 1.10576,2.269954 1.201823,3.421875 l 0.04948,0.604167 h 0.677083 0.677084 V 20.88258 c 0,-0.94089 -0.370208,-2.39196 -0.860678,-3.373698 -0.865272,-1.731956 -2.368583,-3.188924 -4.154947,-4.026042 l -0.623698,-0.292969 0.552083,-0.553385 c 0.605201,-0.607815 1.005404,-1.260274 1.278646,-2.083334 0.0039,-0.01169 0.0054,-0.02112 0.0091,-0.03255 a 4.7643424,4.7643424 0 0 1 -1.378906,-0.238281 c -0.0024,0.0068 -0.0028,0.01401 -0.0052,0.02083 -0.407848,1.160394 -1.54104,2.125943 -2.757812,2.351563 -0.730876,0.135522 -1.42628,0.02771 -2.2109385,-0.34375 C 7.3908665,11.301691 6.8721835,8.3994902 8.5137015,6.6638289 8.8214055,6.3384771 9.1759155,6.089295 9.5553675,5.9073184 A 6.289104,6.289104 0 0 1 10.354847,4.2341413 Z' />
					<path
						className={secondaryPathClass}
						style={{ color: secondaryPathColor }}
						d='m 16.60401,2.447736 c -1.503462,0 -2.951053,0.5391881 -4.170573,1.5481771 -0.916373,0.758237 -1.382657,1.499141 -1.421875,1.5625 l -0.110677,0.1796875 a 0.34616326,0.34616326 0 0 0 -0.0026,0.3658854 l 0.113281,0.1835938 c 0.03932,0.06354 0.505503,0.8042669 1.421875,1.5625003 1.21952,1.00899 2.667111,1.5481766 4.170573,1.5481766 1.503726,0 2.946938,-0.5407953 4.160156,-1.5507813 0.911881,-0.75922 1.380662,-1.5143918 1.410156,-1.5624998 L 22.285004,6.103986 a 0.34616326,0.34616326 0 0 0 0,-0.3632812 L 22.174322,5.5584131 C 22.144358,5.5096501 21.675821,4.7575453 20.764166,3.9985173 19.550948,2.9885323 18.107736,2.447736 16.60401,2.447736 Z m 0,1.3867188 c 1.187429,0 2.268652,0.4010978 3.255208,1.2161458 0.412553,0.340874 0.637858,0.6318738 0.826823,0.8710938 -0.189318,0.240131 -0.41461,0.5331879 -0.826823,0.8736976 -0.986438,0.8151074 -2.067763,1.2161461 -3.255208,1.2161461 -1.187671,0 -2.275676,-0.4025327 -3.269531,-1.2187501 C 12.919738,6.4522071 12.69135,6.1613024 12.499843,5.9216944 12.691368,5.6820734 12.919775,5.3912086 13.334479,5.0506006 14.328156,4.2346146 15.4159,3.8344548 16.60401,3.8344548 Z m -0.04557,0.8528646 a 1.2362932,1.2362932 0 0 0 -1.203125,1.235677 1.2362932,1.2362932 0 0 0 1.236979,1.236979 1.2362932,1.2362932 0 0 0 1.235677,-1.236979 1.2362932,1.2362932 0 0 0 -1.235677,-1.235677 1.2362932,1.2362932 0 0 0 -0.03385,0 z'
					/>
				</>
			)}
		/>
	);
}
