import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import ConverterDiagnosticList from './DiagnosticReadsList/ConverterDiagnosticList';
import DeviceDiagnosticReadsTimeline from './DiagnosticReadsTimeline/DeviceDiagnosticReadsTimeline';
import DiagnosticMoreDataInfo from './DiagnosticMoreDataInfo';

const ConverterDiagnostic = ({ deviceId }) => {
	const [showHistory, setShowHistory] = useState(false);

	useEffect(() => {
		return () => {
			setShowHistory(false);
		};
	}, []);
	return (
		<Box display='flex' flexDirection='column' height='100%'>
			<DeviceDiagnosticReadsTimeline deviceId={deviceId} />
			{showHistory ? (
				<ConverterDiagnosticList deviceId={deviceId} />
			) : (
				<DiagnosticMoreDataInfo
					onClick={() => {
						setShowHistory(true);
					}}
				/>
			)}
		</Box>
	);
};

export default ConverterDiagnostic;
