import * as Yup from 'yup';
import validationMessages from './validationMessages';
import { stringSimilarity } from 'string-similarity-js';

export const YupSchemaDefinitions = {
	/**
	 * Tekst krótki
	 * @param {object} overrides
	 * @param {boolean} checkWhitespace
	 * @returns obiekt Yup
	 */
	shortString: (overrides = {}, checkWhitespace = false) => {
		const options = {
			min: { value: 3, message: validationMessages.shortStringMin },
			max: { value: 50, message: validationMessages.shortStringMax },
			req: { value: true, message: validationMessages.required },

			...overrides
		};
		const { min, max, req } = options;

		let baseYup = Yup.string().min(min.value, min.message).max(max.value, max.message);

		if (checkWhitespace) baseYup = baseYup.matches(/^[^\s].+[^\s]$/, validationMessages.whitespace);

		if (options.req.value) return baseYup.required(req.message);
		return baseYup;
	},

	/**
	 * Tekst krótki
	 * @param {object} overrides
	 * @param {boolean} checkWhitespace
	 * @returns obiekt Yup
	 */
	longString: (overrides = {}, checkWhitespace = false) => {
		const options = {
			min: { value: 1, message: validationMessages.longStringMin },
			max: { value: 150, message: validationMessages.longStringMax },
			req: { value: true, message: validationMessages.required },
			...overrides
		};
		const { min, max, req } = options;

		let baseYup = Yup.string().min(min.value, min.message).max(max.value, max.message);

		if (checkWhitespace) baseYup = baseYup.matches(/^[^\s].+[^\s]$/, validationMessages.whitespace);

		if (options.req.value) return baseYup.required(req.message);
		return baseYup;
	},

	/**
	 * Stan początkowy zestawu - decimal
	 * @param {object} overrides
	 * @returns obiekt Yup
	 */
	initialStateDecimal: (translate, overrides = {}) => {
		const options = {
			req: { value: false, message: validationMessages.required },
			...overrides
		};
		const { req } = options;

		let baseYup = Yup.string().matches(
			/^(?=.*\d)\d{1,8}(?:[\,|\.]\d{1,3})?$/,
			translate('MeasurementSet_InitialStateValidation')
				.replace('{0}', '0')
				.replace('{1}', '99999999,999'.replace(',', translate('MeasurementSet_DecimalSeparator')))
		);

		if (options.req.value) return baseYup.required(req.message);
		return baseYup;
	},

	/**
	 * Stan początkowy zestawu ujemny - decimal
	 * @param {object} overrides
	 * @returns obiekt Yup
	 */
	initialStateDecimalNegative: (translate, overrides = {}) => {
		const options = {
			req: { value: false, message: validationMessages.required },
			...overrides
		};
		const { req } = options;

		let baseYup = Yup.string().matches(
			/^-?(?=.*\d)\d{1,8}(?:[\,|\.]\d{1,3})?$/,
			translate('MeasurementSet_InitialStateValidation')
				.replace('{0}', '-99999999,999')
				.replace('{1}', '99999999,999'.replace(',', translate('MeasurementSet_DecimalSeparator')))
		);

		if (options.req.value) return baseYup.required(req.message);
		return baseYup;
	},

	/**
	 * Stan końcowy zestawu - decimal
	 * @param {object} overrides
	 * @returns obiekt Yup
	 */
	endStateDecimal: (translate, overrides = {}) => {
		const options = {
			req: { value: false, message: validationMessages.required },
			...overrides
		};
		const { req } = options;

		let baseYup = Yup.string().matches(
			/^(?=.*\d)\d{1,8}(?:[\,|\.]\d{1,3})?$/,
			translate('MeasurementSet_EndStateValidation')
				.replace('{0}', '0')
				.replace('{1}', '99999999,999'.replace(',', translate('MeasurementSet_DecimalSeparator')))
		);

		if (options.req.value) return baseYup.required(req.message);
		return baseYup;
	},

	/**
	 * Stan końcowy zestawu ujemny - decimal
	 * @param {object} overrides
	 * @returns obiekt Yup
	 */
	endStateDecimalNegative: (translate, overrides = {}) => {
		const options = {
			req: { value: false, message: validationMessages.required },
			...overrides
		};
		const { req } = options;

		let baseYup = Yup.string().matches(
			/^-?(?=.*\d)\d{1,8}(?:[\,|\.]\d{1,3})?$/,
			translate('MeasurementSet_EndStateValidation')
				.replace('{0}', '-99999999,999')
				.replace('{1}', '99999999,999'.replace(',', translate('MeasurementSet_DecimalSeparator')))
		);

		if (options.req.value) return baseYup.required(req.message);
		return baseYup;
	},

	/**
	 * Stan początkowy zestawu - integer
	 * @param {object} overrides
	 * @returns obiekt Yup
	 */
	initialStateInt: (translate, overrides = {}) => {
		const options = {
			req: { value: false, message: validationMessages.required },
			...overrides
		};
		const { req } = options;

		let baseYup = Yup.string().matches(
			/^(?=.*\d)\d{1,8}$/,
			translate('MeasurementSet_InitialStateValidationInteger').replace('{0}', '0').replace('{1}', '99999999')
		);

		if (options.req.value) return baseYup.required(req.message);
		return baseYup;
	},

	/**
	 * Stan końcowy zestawu - integer
	 * @param {object} overrides
	 * @returns obiekt Yup
	 */
	endStateInt: (translate, overrides = {}) => {
		const options = {
			req: { value: false, message: validationMessages.required },
			...overrides
		};
		const { req } = options;

		let baseYup = Yup.string().matches(
			/^(?=.*\d)\d{1,8}$/,
			translate('MeasurementSet_EndStateValidationInteger').replace('{0}', '0').replace('{1}', '99999999')
		);

		if (options.req.value) return baseYup.required(req.message);
		return baseYup;
	},

	/**
	 * Stan początkowy zestawu - int
	 * @param {object} overrides
	 * @returns obiekt Yup
	 */
	correction: (translate, overrides = {}) => {
		const options = {
			req: { value: false, message: validationMessages.required },
			...overrides
		};
		const { req } = options;

		let baseYup = Yup.string().matches(
			/^\-?(?=.*\d)\d{1,8}(?:[\,|\.]\d{1,3})?$/,
			translate('MeasurementSet_CorrectionValidationDecimal')
		);

		if (options.req.value) return baseYup.required(req.message);
		return baseYup;
	},

	/**
	 * Stan początkowy zestawu - int
	 * @param {object} overrides
	 * @returns obiekt Yup
	 */
	sealNumbers: (translate, overrides = {}) => {
		const options = {
			req: { value: false, message: validationMessages.required },
			...overrides
		};
		const { req } = options;

		let baseYup = Yup.string().max(20, 'MeasurementSet_SealValidation');

		if (options.req.value) return baseYup.required(req.message);
		return baseYup;
	},

	/**
	 * Adres email
	 * @param {object} opts
	 * @returns obiekt Yup
	 */
	email: (opts) => {
		const options = {
			req: { value: true, message: validationMessages.required },
			...opts
		};

		const userRegex =
			/^[-!#$%&'*+/=?^_`{}|~0-9A-Z]+(\.[-!#$%&'*+/=?^_`{}|~0-9A-Z]+)*$|^"([\x01-\x08\x0b\x0c\x0e-\x1f!#-[\]-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*"$/i;
		const domainRegex = /^(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+(?:[A-Z0-9-]{2,63}(?<!-))$/i;
		const literalRegex = /^\[([A-F0-9:.]+)\]$/i;

		let schema = Yup.string();

		if (options.req?.value) schema = schema.required(options.req.message);

		schema = schema.test('is-valid-email', validationMessages.emailFormat, (value) => {
			if (!value && options.req?.value === false) return true;
			if (!value) return false;
			const [userPart, domainPart] = value.split('@');

			if (!userPart || !domainPart) return false;
			return userRegex.test(userPart) && (domainRegex.test(domainPart) || literalRegex.test(domainPart));
		});

		return schema;
	},

	/**
	 * Username
	 * @param {object} opts
	 * @returns obiekt Yup
	 */
	username: () => {
		return Yup.string()
			.required(validationMessages.required)
			.max(150, validationMessages.usernameMax)
			.matches(/^[\w.@+-]+$/, validationMessages.usernameFormat);
	},

	/**
	 * Password
	 * @param {object} opts
	 * @returns obiekt Yup
	 */
	password: (opts) => {
		const options = {
			similarityCheck: true,
			...opts
		};

		const checkSimilarity = (password, attributes) => {
			const threshold = 0.7;
			for (let attr of attributes) {
				if (!attr) continue;
				if (stringSimilarity(password, attr) > threshold) {
					return false;
				}
			}
			return true;
		};

		let schema = Yup.string()
			.nullable()
			.required(validationMessages.required)
			.matches(
				/^(?=.*\d)(?=.*[A-Z])[\da-zA-Z !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/,
				validationMessages.passwordFormat
			);

		if (options.similarityCheck)
			schema = schema.test('is-not-similar', validationMessages.passwordSimilarity, function (value) {
				if (!value) return true;
				const { username, email, firstName, lastName } = this.parent;
				return checkSimilarity(value, [username, email, firstName, lastName]);
			});

		return schema;
	}
};
