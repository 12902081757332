import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash-es';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import { add, addTemplate, edit, editTemplate } from './configuratorApi';

const slice = 'configurator';
const initialConfiguratorData = {};
const initialState = {
	steps: [],
	initialData: null,
	editing: false,
	activeStep: 0,
	valid: [],
	visited: [],
	addOrEditPending: false,
	addOrEditSuccess: false,
	addOrEditTemplatePending: false,
	addOrEditTemplateSuccess: false,
	fetchPending: false,
	fetchSuccess: false
};

export const configuratorSlice = createSlice({
	name: slice,
	initialState: {
		...initialState,
		configurator: initialConfiguratorData
	},
	reducers: {
		setupConfigurator: (state, { payload }) => {
			const { steps, initialData } = payload;
			const editing = initialData.id != null;

			state.initialData = { ...initialData, templateId: null, templateName: null };
			state.editing = editing;
			state.configurator = { ...state.configurator, ...initialData };
			state.steps = steps;
			state.valid = steps.reduce((acc) => {
				acc.push(editing);
				return acc;
			}, []);
			state.visited = steps.reduce((acc, v, i) => {
				acc.push(i === 0 ? true : editing);
				return acc;
			}, []);
		},
		setActiveStep: (state, { payload }) => {
			state.activeStep = payload;
		},
		fetchDataSucces: (state, { payload }) => {
			state.configurator = payload;
			state.fetchPending = false;
			state.fetchSuccess = true;
		},
		fetchDataPending: (state) => {
			state.fetchPending = true;
			state.fetchSuccess = false;
		},
		resetFetchSuccess: (state) => {
			state.addOrEditSuccess = false;
		},
		addOrEditSuccess: (state, { payload }) => {
			state.addOrEditPending = false;
			state.addOrEditSuccess = payload;
		},
		addOrEditPending: (state, { payload }) => {
			state.addOrEditPending = payload;
		},
		resetAddOrEditSuccess: (state) => {
			state.addOrEditSuccess = false;
		},
		addOrEditTemplateSuccess: (state, { payload }) => {
			state.addOrEditTemplatePending = false;
			state.addOrEditTemplateSuccess = payload;
		},
		addOrEditTemplatePending: (state, { payload }) => {
			state.addOrEditTemplatePending = payload;
		},
		resetAddOrEditTemplateSuccess: (state) => {
			state.addOrEditTemplateSuccess = false;
		},
		updateValid: (state, { payload }) => {
			let newValid = [...state.valid];

			for (let p in payload) {
				newValid[p] = payload[p];
			}

			state.valid = newValid;
		},
		updateVisited: (state, { payload }) => {
			let newVisited = [...state.visited];

			for (let p in payload) {
				newVisited[p] = payload[p];
			}

			state.visited = newVisited;
		},
		setConfiguratorData: (state, { payload }) => {
			state.configurator = payload;
		},
		resetConfiguratorData: (state) => {
			state.configurator = initialConfiguratorData;

			for (let is in initialState) {
				state[is] = initialState[is];
			}
		},
		editConfiguratorData: (state, { payload }) => {
			const { data, nestedProperty, merge } = payload;
			const property = Object.keys(data)[0];

			//update roota state.configurator
			if (property === '') {
				if (!merge) state.configurator = Object.values(data)[0];
				else state.configurator = { ...state.configurator, ...Object.values(data)[0] };
				return;
			}

			let previous = nestedProperty
				? cloneDeep(state.configurator[property][nestedProperty])
				: cloneDeep(state.configurator[property]);
			const payloadData = nestedProperty ? data[property][nestedProperty] : data[property];

			if (Array.isArray(previous) && payloadData !== null) {
				if (!merge) previous = payloadData;
				else previous = [...previous, ...payloadData];
			} else if (typeof previous === 'object' && previous !== null && payloadData !== null) {
				if (!merge) previous = payloadData;
				else previous = { ...previous, ...payloadData };
			} else previous = payloadData;

			if (nestedProperty)
				state.configurator = { ...state.configurator, ...{ [property]: { [nestedProperty]: previous } } };
			else state.configurator = { ...state.configurator, ...{ [property]: previous } };
		},
		loadConfiguratorInitialData: (state) => {
			state.configurator = { ...state.initialData };
			state.valid = state.steps.reduce((acc, v, i) => {
				if (i === 0 && !state.editing) acc.push(true);
				else acc.push(state.editing);
				return acc;
			}, []);
			state.visited = state.steps.reduce((acc, v, i) => {
				acc.push(i === 0 ? true : state.editing);
				return acc;
			}, []);
		}
	}
});

export const {
	setupConfigurator,
	setActiveStep,
	fetchDataSucces,
	fetchDataPending,
	resetFetchSuccess,
	addOrEditSuccess,
	addOrEditPending,
	addOrEditTemplateSuccess,
	addOrEditTemplatePending,
	resetAddOrEditTemplateSuccess,
	resetAddOrEditSuccess,
	updateValid,
	updateVisited,
	setConfiguratorData,
	resetConfiguratorData,
	editConfiguratorData,
	loadConfiguratorInitialData
} = configuratorSlice.actions;

//pobranie danych do edycji
export const fetchDataAsync = (api, id) => async (dispatch) => {
	try {
		dispatch(fetchDataPending(true));

		const response = await api(id);

		dispatch(fetchDataSucces(response));
	} catch (error) {
		dispatch(fetchDataPending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

//dodanie lub edycja raportu
export const addOrEditAsync = (isEdit, props, submitData) => async (dispatch) => {
	try {
		dispatch(addOrEditPending(true));

		if (isEdit) await edit(props, submitData);
		else await add(props, submitData);

		dispatch(addOrEditSuccess(true));
		setTimeout(() => dispatch(resetAddOrEditSuccess(false)), 100);
	} catch (error) {
		dispatch(addOrEditPending(false));
		return dispatch(addErrorAsync({ slice, error }));
	}
};

//dodanie lub edycja szablonu
export const addOrEditTemplateAsync = (isEdit, props, submitData) => async (dispatch) => {
	try {
		dispatch(addOrEditTemplatePending(true));

		const { templateId, ...values } = submitData;

		if (isEdit) await editTemplate(props, { ...values, id: templateId });
		else await addTemplate(props, values);

		dispatch(addOrEditTemplateSuccess(true));
		setTimeout(() => dispatch(resetAddOrEditTemplateSuccess(false)), 10);
	} catch (error) {
		dispatch(addOrEditTemplatePending(false));
		return dispatch(addErrorAsync({ slice, error }));
	}
};

//selectors
export const selectData = (state) => {
	return {
		data: state.configurator.configurator,
		fetchPending: state.configurator.fetchPending,
		fetchSuccess: state.configurator.fetchSuccess
	};
};

export const selectAddOrEdit = (state) => {
	return {
		addOrEditPending: state.configurator.addOrEditPending,
		addOrEditSuccess: state.configurator.addOrEditSuccess
	};
};

export const selectAddOrEditTemplate = (state) => {
	return {
		addOrEditTemplatePending: state.configurator.addOrEditTemplatePending,
		addOrEditTemplateSuccess: state.configurator.addOrEditTemplateSuccess
	};
};

export const selectIsEditing = (state) => state.configurator.editing;

export const selectSteps = (state) => state.configurator.steps;

export const selectActiveStep = (state) => state.configurator.activeStep;

export const selectValid = (state) => state.configurator.valid;

export const selectVisited = (state) => state.configurator.visited;

export default configuratorSlice.reducer;
