export const setErrorReason = (error, reason) => {
	if (!error.response) return { ...error, response: { status: 409, data: { reason } } };

	if (typeof error.response.data === 'string') return { ...error, response: { ...error.response, data: { reason } } };

	return { ...error, response: { ...error.response, data: { ...error.response.data, reason } } };
};

export const getMemberMessage = ({ reason }) => {
	const splitReason = reason?.split(':');

	return {
		message: splitReason.length === 1 ? splitReason[0] : splitReason[1],
		member: splitReason.length === 1 ? null : splitReason[0]
	};
};

export const getMemberReason = ({ member, message }) => {
	if (member) {
		return `${member}:${message}`;
	}
	return message;
};

export const billingErrors = {
	unknownError: ':Billing_UnknownError',
	unknownExternalApiError: ':Billing_UnknownExternalServiceError'
};

export const getInvalidDataErrorReason = ({ code, error }) => {
	const data = error.response.data;
	if (!data.code?.toString().includes(code)) {
		return null;
	}

	if (!data.reason) {
		return null;
	}

	const { member, message } = getMemberMessage({ reason: data.reason });

	return getMemberReason({ member, message: `Billing_${message}` });
};

function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export const isErrorCode = ({ code, error }) => {
	const data = error.response.data;

	return data?.code?.toString().includes(code);
};

export const getCombinedInvalidDataErrorReason = ({ code, error }) => {
	const data = error.response.data;
	if (!isErrorCode({ code, error })) {
		return null;
	}

	if (!data.reason) {
		return null;
	}

	const { member, message } = getMemberMessage({ reason: data.reason });

	if (member) {
		return getMemberReason({ member, message: `Billing_${capitalizeFirstLetter(member)}${message}` });
	}
	return getMemberReason({ member, message: `Billing_${message}` });
};
