import NotificationImportantOutlinedIcon from '@mui/icons-material/NotificationImportantOutlined';
import NotificationWrapper from './NotificationWrapper';

const Warning = ({ title, message }) => {
	const dataAtWarning = typeof message === 'string' ? `notification|warning|${message}` : null;

	return (
		<NotificationWrapper
			Icon={NotificationImportantOutlinedIcon}
			iconProps={{ color: 'warning' }}
			title={title}
			message={message}
			wrapperProps={{
				'data-at': dataAtWarning
			}}
		/>
	);
};

export default Warning;
