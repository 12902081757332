import React from 'react';
import BaseIcon from './BaseIcon';

export default function SaveInCurrentIcon(props) {
	return (
		<BaseIcon {...props}>
			<path d='M 4.836712,2.0001024 C 3.2763795,2.0001024 2,3.2730023 2,4.8333153 V 16.916658 c 0,1.560332 1.2763795,2.836729 2.836712,2.83671 h 12.059729 c 1.560351,0 2.836711,-1.276378 2.836711,-2.83671 V 6.8731536 6.8695442 h -0.0036 l -4.816196,-4.8398108 -0.03062,0.030621 V 2 h -2.71338 -4.423449 z m 0,1.1904931 h 1.7004483 c -1.152e-4,0.00683 -0.00176,0.01353 -0.00176,0.020115 v 3.1769741 c 0,0.6609225 0.5496836,1.209733 1.2106071,1.209733 H 12.16946 c 0.660905,0 1.209734,-0.5488105 1.209734,-1.209733 V 3.2107114 c 0,-0.00683 -0.0018,-0.01353 -0.0018,-0.020115 h 1.01205 l 4.154033,4.1732762 v 9.5527854 c 0,0.920768 -0.726311,1.647096 -1.647097,1.647096 h -0.138222 c 2.55e-4,-0.01021 0.0036,-0.01939 0.0036,-0.02973 V 12.43375 c 0,-0.660928 -0.549704,-1.209734 -1.210609,-1.209734 H 6.1854935 c -0.6609263,0 -1.213235,0.548806 -1.213235,1.209734 v 6.100277 c 0,0.01021 0.00176,0.01957 0.00361,0.02973 H 4.8367822 c -0.9207687,0 -1.6435938,-0.726326 -1.6435938,-1.647094 V 4.8333143 c 0,-0.9207551 0.7228251,-1.6427188 1.6435938,-1.6427188 z m 2.9093081,0 h 4.4234509 c 0.02131,0 0.01925,-0.00137 0.01925,0.020115 v 3.1769741 c 0,0.021315 0.0018,0.019247 -0.01925,0.019247 H 7.7460201 c -0.021315,0 -0.020115,0.00176 -0.020115,-0.019247 V 3.2107114 c 0,-0.021315 -0.00137,-0.020115 0.020115,-0.020116 z M 6.1855267,12.414489 h 9.3656003 c 0.02132,0 0.0201,-0.0018 0.0201,0.01925 v 6.100278 c 0,0.02132 0.0014,0.01925 -0.0201,0.01925 H 6.1855267 c -0.021315,0 -0.020095,0.0018 -0.020095,-0.01925 V 12.43374 c 0,-0.02132 -0.00137,-0.01925 0.020095,-0.01925 z' />
		</BaseIcon>
	);
}
