import { useEffect } from 'react';
import { useFetchOnDemand, useLocale } from '@sonar-web/common/src/hooks';
import { ReportsService } from '@sonar/reports';
import Slices from '../Constants/Slices';
import LicenseItemSecondary from './LicenseItemSecondary';
import LicenseItemProgress from './LicenseItemProgress';

const slice = Slices.licenses;

const ReportsCount = ({ maxNumberOfReports }) => {
	const { translate } = useLocale();

	const {
		fetchFunc,
		data: reportsCount,
		pending: devicesCountPending
	} = useFetchOnDemand({
		api: () => ReportsService.fetchCurrentNumberOfReports(),
		slice
	});

	useEffect(() => {
		fetchFunc();
	}, []);

	const count = reportsCount ?? 0;
	const left = count !== null ? maxNumberOfReports - count : null;
	const progress = count ? Math.floor((count / maxNumberOfReports) * 100) : 0;

	return (
		<>
			<LicenseItemSecondary pending={devicesCountPending} value={left} text='RemainToUse' />
			<LicenseItemProgress tooltip={translate('UsedXofY', count ?? '', maxNumberOfReports)} progress={progress} />
		</>
	);
};

export default ReportsCount;
