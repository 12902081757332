import FormCancel from './FormCancel';
import FormSubmit from './FormSubmit';
import FormTitle from './FormTitle';
import FormToolbar from './FormToolbar';

const FormComponents = {
	Toolbar: FormToolbar,
	CancelButton: FormCancel,
	SubmitButton: FormSubmit,
	Title: FormTitle
};

export default FormComponents;
