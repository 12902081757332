import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { dateOnlyTime } from '@sonar-web/common/src/helpers/date';
import { FormControl } from '@mui/material';
import BaseTimePicker from '../../../../components/BaseTimePicker/BaseTimePicker';

const ConfiguratorTimefield = ({ name, value, error, helperText, element, form }) => {
	const { dependsOn, defaultValue } = element;
	const [dependencyVisibility, setDependencyVisibility] = useState(false);

	const valueParts = value ? value.split(':') : defaultValue ? defaultValue.split(':') : '00:00:00'.split(':');
	const [selected, setSelected] = useState(
		moment().set({ hour: valueParts[0], minute: valueParts[1], second: valueParts[2] })
	);

	useEffect(() => {
		if (!dependsOn) return;

		setDependencyVisibility(dependsOn.value === form.values[dependsOn.key]);
	}, [form.values[dependsOn?.key]]);

	useEffect(() => {
		if (!dependsOn) return;
		if (!dependencyVisibility) form.setFieldValue(name, value ?? defaultValue);
	}, [dependencyVisibility]);

	const handleOnChange = (v) => setSelected(v);

	if (dependsOn && !dependencyVisibility) return null;
	return (
		<FormControl fullWidth margin='normal'>
			<BaseTimePicker
				name={name}
				value={selected}
				label={name}
				helperText={helperText}
				error={error}
				onChange={handleOnChange}
				variant='inline'
				onClose={() => {
					if (selected) form.setFieldValue(name, dateOnlyTime(selected, true));
				}}
			/>
		</FormControl>
	);
};

export default ConfiguratorTimefield;
