const Role = {
	Administrator: {
		name: 'Administrator'
	},
	NetworkOperator: {
		name: 'NetworkOperator'
	},
	CustomerService: {
		name: 'CustomerService'
	},
	ExecutiveAdministrator: {
		name: 'ExecutiveAdministrator'
	},
	ResourcesAdministrator: {
		name: 'ResourcesAdministrator'
	}
};

export const RoleArray = Object.entries(Role).map((r) => r[0]);

export default Role;
