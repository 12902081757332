import React from 'react';
import { Typography } from '@mui/material';
import { TableListColumn } from '@sonar-web/common';
import { useLocale } from '@sonar-web/common/src/hooks';

const SourceColumn = ({ source }) => {
	const { translate } = useLocale();

	return (
		<TableListColumn width={250}>
			<Typography variant='body2' color='textPrimary' data-at-params={`source|${source}`}>
				{translate('HeatAllocators_ReadSource_' + source)}
			</Typography>
		</TableListColumn>
	);
};

export default SourceColumn;
