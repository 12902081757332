import { Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '../../hooks';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	root: {
		whiteSpace: 'break-spaces',
		maxWidth: 400
	},
	arrowPrimary: {
		color: theme.palette.primary.main
	},
	tooltipPrimary: {
		backgroundColor: theme.palette.primary.main
	},
	arrowSecondary: {
		color: theme.palette.secondary.main
	},
	tooltipSecondary: {
		backgroundColor: theme.palette.secondary.main
	},
	arrowDefault: {
		color: theme.palette.action.active
	},
	tooltipDefault: {
		backgroundColor: theme.palette.action.active
	},
	closerTooltip: {
		marginBottom: 5
	}
}));

const BaseTooltip = ({ children, text, hide, color = 'primary', placement = 'top', closerTooltip, ...rest }) => {
	const classes = useStyles();
	const { translate } = useLocale();

	const title = typeof text === 'string' ? translate(text) : text;
	const arrowClass = `arrow${color.capitalize()}`;
	const tooltipClass = `tooltip${color.capitalize()}`;

	return hide ? (
		<>{children}</>
	) : (
		<Tooltip
			arrow
			placement={placement}
			classes={{
				arrow: classes[arrowClass],
				tooltip: `${classes.root} ${classes[tooltipClass]}`,
				tooltipPlacementTop: clsx({ [classes.closerTooltip]: closerTooltip })
			}}
			title={title}
			{...rest}>
			{children}
		</Tooltip>
	);
};

export default BaseTooltip;
