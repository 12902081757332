import React, { useState } from 'react';
import { Box } from '@mui/material';
import ButtonLinkAlt from '../../../../../components/Buttons/ButtonLinkAlt';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CustomTimePicker from './CustomTimePicker';

const TimeItemAdd = ({ onAdd, minutesOnly = false }) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<>
			<Box>
				<ButtonLinkAlt
					startIcon={<AddCircleOutlineOutlinedIcon fontSize='small' />}
					onClick={() => setIsOpen(true)}>
					Common_Add
				</ButtonLinkAlt>
			</Box>
			<CustomTimePicker
				value={null}
				onChange={onAdd}
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				minutesOnly={minutesOnly}
			/>
		</>
	);
};

export default TimeItemAdd;
