import React from 'react';
import BaseIcon from './BaseIcon';

export default function CityIcon(props) {
	return (
		<BaseIcon {...props}>
			<path d='m 6.0042413,3.5625298 c -1.343688,0 -2.441711,1.0975 -2.441711,2.44119 V 17.99782 c 0,1.34369 1.098023,2.43965 2.441711,2.43965 H 17.996274 c 1.343688,0 2.441195,-1.09596 2.441195,-2.43965 V 6.0037198 c 0,-1.34369 -1.097507,-2.44119 -2.441195,-2.44119 z m 0,0.99994 H 17.996274 c 0.806985,0 1.441256,0.63426 1.441256,1.44125 v 2.40451 l -2.330091,-2.33009 a 0.55005501,0.55005501 0 0 0 -0.777213,0 L 11.849879,10.55848 9.9435363,8.6697098 a 0.55005501,0.55005501 0 0 0 -0.75396,-0.0176 L 4.5624693,12.79346 V 6.0036898 c 0,-0.80699 0.634787,-1.44125 1.441772,-1.44125 z m 10.7145917,2.68149 2.439644,2.43964 0.279053,0.27905 V 17.99782 c 0,0.80699 -0.634271,1.43971 -1.441256,1.43971 H 15.062603 V 13.97274 A 0.55005501,0.55005501 0 0 0 14.900856,13.58207 L 12.63071,11.3326 Z m -7.1814737,2.57245 4.4255687,4.3847402 v 5.23638 H 9.9104633 v -0.16588 -2.67219 -0.54881 h -1.099675 v 0.54881 2.67219 0.16588 h -2.806547 c -0.806985,0 -1.441772,-0.63272 -1.441772,-1.43971 v -3.72897 a 0.55000001,0.55000001 0 0 0 0.00413,-0.003 z M 16.01965,10.29493 v 0.55087 1.28106 0.55087 h 1.101742 V 12.12686 10.8458 10.29493 Z' />
		</BaseIcon>
	);
}
