import { createSelector, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import {
	addMeasurementSet,
	fetchConnectionRules,
	editMeasurementSet,
	closeMeasurementSet,
	deleteMeasurementSet,
	checkDevicesAvailability
} from './SetsService';
import { pageDescriptor } from '@sonar-web/common';
import { SLICES, SET_WIZARD_MODE } from './Constants';
import { fixDecimalSeparator } from '../Utils/utils.numbers';
import MeasuringKind from '@sonar-web/common/src/enums/MeasuringKind';
import GroupTypeName from '@sonar-web/common/src/enums/GroupTypeName';

const slice = SLICES.setWizard;

const pairMetadata = {
	correction: null,
	startReadValue: null,
	secondStartReadValue: null,
	endReadValue: null,
	secondEndReadValue: null,
	sealNumbers: null,
	orientation: null
};

const initialPairData = {
	inputDevice: null,
	outputDevice: null,
	...pairMetadata,
	inputInterfaceType: null,
	transmissionDeviceEnabled: false,
	measuringKind: MeasuringKind.Standard.name
};

const initial = {
	locationId: '',
	addressDisplayName: '',
	startDate: null,
	endDate: null,
	measurementPairs: [initialPairData]
};

export const setWizardSlice = createSlice({
	name: slice,
	initialState: {
		measurementSet: initial,
		fetchedMeasurementSet: null,
		pending: false,
		success: false,
		deletePending: false,
		measurementPoint: null,
		mode: SET_WIZARD_MODE.add,
		pairsState: {},
		invalidDevices: [],
		invalidDevicesPending: false
	},
	reducers: {
		setMeasurementDeiceInPair: (state, { payload }) => {
			const { pairIndex, device } = payload;
			const pair = state.measurementSet.measurementPairs[pairIndex];

			if (!pair) {
				state.measurementSet.measurementPairs = [
					{
						...initialPairData,
						inputDevice: device,
						outputDevice: null,
						inputInterfaceType: null,
						measuringKind: device.isCoupled ? MeasuringKind.Aggregated.name : MeasuringKind.Standard.name
					}
				];
				return;
			}

			/* Obsługa pary MeasuringKind.Aggregated - sprzężonych wodomierzy
			 * Jeśli ustawiamy pierwszą parę i posiada ona wodomierz sprzężony, albo zmianiamy ze sprzężonego na normalny wodomierz
			 * to usuwamy wszystkie pary i tworzymy nową z pierwszą parą
			 */
			if (pairIndex === 0 && (device.isCoupled || pair.measuringKind === MeasuringKind.Aggregated.name)) {
				state.measurementSet.measurementPairs = [
					{
						...initialPairData,
						inputDevice: device,
						outputDevice: null,
						inputInterfaceType: pair.inputInterfaceType,
						measuringKind: device.isCoupled ? MeasuringKind.Aggregated.name : MeasuringKind.Standard.name
					}
				];
				return;
			}

			const newPairData = {
				...pair,
				inputDevice: device,
				outputDevice: pair.outputDevice ?? null,
				inputInterfaceType: [GroupTypeName.HeatCostAllocators.name, GroupTypeName.HeatMeters.name].includes(
					device.groupTypeName
				)
					? device.interfaceType
					: pair.inputInterfaceType,
				measuringKind:
					(device.isCoupled ? MeasuringKind.Aggregated.name : pair.measuringKind) ??
					MeasuringKind.Standard.name
			};

			state.measurementSet.measurementPairs[pairIndex] = newPairData;
		},
		removeMeasurementDeviceInPair: (state, { payload }) => {
			const { pairIndex } = payload;
			const pair = state.measurementSet.measurementPairs[pairIndex];

			if (pair.measuringKind === MeasuringKind.Aggregated.name) {
				state.measurementSet.measurementPairs = [initialPairData];
				return;
			}

			const isPartOfAggregatedWaterMeter = [MeasuringKind.Main.name, MeasuringKind.Additional.name].includes(
				pair.measuringKind
			);

			state.measurementSet.measurementPairs[pairIndex] = {
				...initialPairData,
				inputDevice: null,
				outputDevice: pair.outputDevice ?? null,
				transmissionDeviceEnabled: pair.transmissionDeviceEnabled,
				measuringKind: pair.measuringKind ?? MeasuringKind.Standard.name,
				inputInterfaceType: isPartOfAggregatedWaterMeter
					? state.measurementSet.measurementPairs[0].inputInterfaceType
					: null
			};
		},
		setTransmissionDeviceInPair: (state, { payload }) => {
			const { pairIndex, device } = payload;
			const pair = state.measurementSet.measurementPairs[pairIndex];

			state.measurementSet.measurementPairs[pairIndex] = {
				...initialPairData,
				inputDevice: pair.inputDevice ?? null,
				outputDevice: device,
				inputInterfaceType: pair.inputInterfaceType,
				transmissionDeviceEnabled: pair.transmissionDeviceEnabled,
				measuringKind: pair.measuringKind ?? MeasuringKind.Standard.name
			};
		},
		removeTransmissionDeviceInPair: (state, { payload }) => {
			const { pairIndex } = payload;
			const pair = state.measurementSet.measurementPairs[pairIndex];

			state.measurementSet.measurementPairs[pairIndex] = {
				...initialPairData,
				inputDevice: pair.inputDevice ?? null,
				outputDevice: null,
				inputInterfaceType: pair.inputInterfaceType,
				transmissionDeviceEnabled: pair.transmissionDeviceEnabled,
				measuringKind: pair.measuringKind ?? MeasuringKind.Standard.name
			};
		},
		setPairTransmissionDeviceEnabled: (state, { payload }) => {
			const { pairIndex, enabled } = payload;
			state.measurementSet.measurementPairs[pairIndex].transmissionDeviceEnabled = enabled;
		},
		setPairMetadata: (state, { payload }) => {
			const { pairIndex, metadata } = payload;
			state.measurementSet.measurementPairs[pairIndex] = {
				...state.measurementSet.measurementPairs[pairIndex],
				...transformMetadataValues(metadata)
			};
		},
		setPairInterfaceType: (state, { payload }) => {
			const { pairIndex, interfaceType } = payload;

			state.measurementSet.measurementPairs[pairIndex].inputInterfaceType = interfaceType;
			state.measurementSet.measurementPairs[pairIndex].inputDevice.interfaceType = interfaceType;
			state.measurementSet.measurementPairs[pairIndex].inputDevice.interfaceNumber = 1;

			const pair = state.measurementSet.measurementPairs[pairIndex];
			if (
				pair.measuringKind === MeasuringKind.Aggregated.name &&
				state.measurementSet.measurementPairs.length > 1
			) {
				const main = state.measurementSet.measurementPairs.find(
					(p) => p.measuringKind === MeasuringKind.Main.name
				);
				const additional = state.measurementSet.measurementPairs.find(
					(p) => p.measuringKind === MeasuringKind.Additional.name
				);

				if (main) {
					main.inputInterfaceType = interfaceType;
					if (main.inputDevice) {
						main.inputDevice.interfaceType = interfaceType;
						main.inputDevice.interfaceNumber = 1;
					}
				}
				if (additional) {
					additional.inputInterfaceType = interfaceType;
					if (additional.inputDevice) {
						additional.inputDevice.interfaceType = interfaceType;
						additional.inputDevice.interfaceNumber = 1;
					}
				}
			}
		},
		setSucces: (state, action) => {
			state.pending = false;
			state.success = action.payload;
		},
		setPending: (state, { payload }) => {
			state.pending = payload;
		},
		updateMeasurementSetLocationNode: (state, { payload }) => {
			state.measurementSet.locationId = payload ? payload.id : null;
			state.measurementSet.addressDisplayName = payload ? payload.displayName : null;
			state.measurementPoint = payload;
		},
		updateMeasurementSetStartDate: (state, { payload }) => {
			if (!payload) {
				state.measurementSet.startDate = null;
				return;
			}

			const isValidDate = moment(payload).isValid();
			if (isValidDate) state.measurementSet.startDate = payload;
		},
		updateMeasurementSetEndDate: (state, { payload }) => {
			if (!payload) {
				state.measurementSet.startDate = null;
				return;
			}

			const isValidDate = moment(payload).isValid();
			if (isValidDate) state.measurementSet.endDate = payload;
		},
		resetMeasurementSet: (state) => {
			state.measurementSet = initial;
			state.fetchedMeasurementSet = null;
			state.success = false;
			state.invalidDevices = [];
			state.measurementPoint = null;
			state.mode = SET_WIZARD_MODE.add;
			state.pairsState = {};
			state.invalidDevices = [];
			state.invalidDevicesPending = false;
		},
		setMeasurementSet: (state, { payload }) => {
			const { set, mode } = payload;
			state.measurementSet = prepareSetDataByMode(set, mode);
			state.fetchedMeasurementSet = set;
			state.mode = mode;
		},
		updateInvalidDevices: (state, { payload }) => {
			if (!payload) {
				state.invalidDevices = [];
				return;
			}

			state.invalidDevices = payload.filter((p) => !p.isAvailable);
		},
		setMeasurementPoint: (state, { payload }) => {
			state.measurementPoint = payload;
		},
		setPairsState: (state, { payload }) => {
			const { pairIndex, pairState } = payload;
			state.pairsState[pairIndex] = pairState;
		},
		setInvalidDevicesPending: (state, { payload }) => {
			state.invalidDevicesPending = payload;
		},
		addPair: (state) => {
			state.measurementSet.measurementPairs.push(initialPairData);
		},
		addCoupledPairs: (state) => {
			const aggregatedPair = state.measurementSet.measurementPairs[0];

			state.measurementSet.measurementPairs.push(
				...[
					{
						...initialPairData,
						measuringKind: MeasuringKind.Main.name,
						inputInterfaceType: aggregatedPair.inputInterfaceType
					},
					{
						...initialPairData,
						measuringKind: MeasuringKind.Additional.name,
						inputInterfaceType: aggregatedPair.inputInterfaceType
					}
				]
			);
		},
		removePair: (state, { payload }) => {
			const { pairIndex } = payload;
			state.measurementSet.measurementPairs.splice(pairIndex, 1);
		}
	}
});

export const {
	setSucces,
	setPending,
	updateMeasurementSetLocationNode,
	updateMeasurementSetStartDate,
	updateMeasurementSetEndDate,
	resetMeasurementSet,
	setMeasurementSet,
	editPair,
	updateEditDeviceValues,
	updateMeasurementDeviceEndState,
	updateMeasurementDeviceInitialState,
	updateInvalidDevices,
	updateMeasurementPairCorrection,
	setMeasurementSetCopy,
	transmissionDeviceReplaced,
	setMeasurementDeiceInPair,
	removeMeasurementDeviceInPair,
	setTransmissionDeviceInPair,
	removeTransmissionDeviceInPair,
	setPairTransmissionDeviceEnabled,
	setPairMetadata,
	setPairInterfaceType,
	setMeasurementPoint,
	setPairsState,
	setInvalidDevicesPending,
	addPair,
	addCoupledPairs
} = setWizardSlice.actions;

export const addMeasurementSetAsync = () => async (dispatch, getState) => {
	try {
		dispatch(setPending(true));
		const requestData = prepareOpenRequest(getState().setWizard.measurementSet);
		await addMeasurementSet(requestData);
		dispatch(setSucces(true));
	} catch (error) {
		dispatch(setPending(false));
		return await dispatch(addErrorAsync({ slice, error }));
	}
};

export const editMeasurementSetAsync = () => async (dispatch, getState) => {
	try {
		const openRequest = prepareOpenRequest(getState().setWizard.measurementSet);
		const closeRequest = prepareCloseRequest(
			getState().setWizard.measurementSet,
			getState().setWizard.fetchedMeasurementSet
		);

		dispatch(setPending(true));
		await editMeasurementSet({ openRequest, closeRequest });
		dispatch(setSucces(true));
	} catch (error) {
		dispatch(setPending(false));
		return await dispatch(addErrorAsync({ slice, error }));
	}
};

export const closeMeasurementSetAsync = () => async (dispatch, getState) => {
	try {
		const closeRequest = prepareCloseRequest(
			getState().setWizard.measurementSet,
			getState().setWizard.fetchedMeasurementSet
		);

		dispatch(setPending(true));
		await closeMeasurementSet(closeRequest);
		dispatch(setSucces(true));
	} catch (error) {
		dispatch(setPending(false));
		return await dispatch(addErrorAsync({ slice, error }));
	}
};

export const deleteMeasurementSetAsync = () => async (dispatch, getState) => {
	try {
		dispatch(setPending(true));
		await deleteMeasurementSet(getState().setWizard.measurementSet.locationId);
		dispatch(setPending(false));
	} catch (error) {
		dispatch(setPending(false));
		return await dispatch(addErrorAsync({ slice, error }));
	}
};

export const fetchConnectionRulesAsync = (filter) => async (dispatch) => {
	const pd = { ...pageDescriptor, FilterDescriptors: filter, Limit: 1000000 };

	try {
		return await fetchConnectionRules(pd);
	} catch (error) {
		await dispatch(addErrorAsync({ slice, error }));
	}
};

export const checkDevicesAvailabilityAsync = (filters) => async (dispatch) => {
	try {
		dispatch(setInvalidDevicesPending(true));
		const response = await checkDevicesAvailability({ ...pageDescriptor, FilterDescriptors: filters });
		dispatch(updateInvalidDevices(response?.elements));
		dispatch(setInvalidDevicesPending(false));
	} catch (error) {
		dispatch(setInvalidDevicesPending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

/* Selectors */
export const selectMeasurementSet = (state) => {
	return {
		measurementSet: state.setWizard.measurementSet,
		success: state.setWizard.success,
		pending: state.setWizard.pending
	};
};

export const selectMeasurementSetDelete = (state) => state.setWizard.deletePending;

export const selectMeasurementPairs = (state) => state.setWizard.measurementSet.measurementPairs;

export const selectStartDate = (state) => state.setWizard.measurementSet.startDate;

export const selectEndDate = (state) => state.setWizard.measurementSet.endDate;

export const selectSetLocation = (state) => {
	return {
		locationId: state.setWizard.measurementSet.locationId,
		addressDisplayName: state.setWizard.measurementSet.addressDisplayName
	};
};

export const selectMeasurementPoint = (state) => state.setWizard.measurementPoint;

export const selectFirstPair = (state) => state.setWizard.measurementSet.measurementPairs?.[0];

export const selectWizardState = createSelector(
	(state) => state.setWizard,
	(setWizard) => ({
		mode: setWizard.mode,
		isValid: setWizard.isValid
	})
);

export const selectPairsState = createSelector(
	(state) => state.setWizard,
	(setWizard) => ({
		pairsState: setWizard.pairsState,
		anyFormOpen: Object.values(setWizard.pairsState).some(
			(pair) => pair.measurementDeviceAddOpen || pair.transmissionDeviceAddOpen || pair.metadataOpen
		)
	})
);

export const selectInvalidDevices = createSelector(
	(state) => state.setWizard,
	(setWizard) => ({
		invalidDevices: setWizard.invalidDevices,
		invalidDevicesPending: setWizard.invalidDevicesPending
	})
);

export default setWizardSlice.reducer;

const prepareOpenRequest = (measurementSet) => {
	const { measurementPairs, ...measurementSetRest } = measurementSet;

	const pairsData = measurementPairs.map((pair) => {
		const singleDevicePair = {
			inputInterfaceType: pair.inputInterfaceType,
			correction: pair.correction,
			measuringKind: pair.measuringKind,
			inputDevice: {
				id: pair.inputDevice.id,
				number: pair.inputDevice.number,
				typeName: pair.inputDevice.typeName,
				groupTypeName: pair.inputDevice.groupTypeName,
				interfaceNumber: pair.inputDevice.interfaceNumber,
				interfaceType: pair.inputDevice.interfaceType,
				startReadValue: pair.startReadValue,
				secondStartReadValue: pair.secondStartReadValue,
				endReadValue: pair.endReadValue,
				secondEndReadValue: pair.secondEndReadValue,
				sealNumbers: pair.sealNumbers,
				orientation: pair.orientation
			}
		};

		if (!pair.outputDevice) return singleDevicePair;

		return [
			singleDevicePair,
			{
				inputInterfaceType: pair.inputInterfaceType,
				correction: pair.correction,
				outputDevice: { ...singleDevicePair.inputDevice },
				measuringKind: pair.measuringKind,
				inputDevice: {
					id: pair.outputDevice.id,
					number: pair.outputDevice.number,
					typeName: pair.outputDevice.typeName,
					groupTypeName: pair.outputDevice.groupTypeName,
					interfaceNumber: pair.outputDevice.interfaceNumber,
					interfaceType: pair.outputDevice.interfaceType,
					startReadValue: pair.startReadValue,
					secondStartReadValue: pair.secondStartReadValue,
					endReadValue: pair.endReadValue,
					secondEndReadValue: pair.secondEndReadValue,
					sealNumbers: pair.sealNumbers,
					orientation: pair.orientation
				}
			}
		];
	});

	return {
		locationId: measurementSetRest.locationId,
		addressDisplayName: measurementSetRest.addressDisplayName,
		measurementSet: {
			startDate: measurementSetRest.startDate,
			endDate: measurementSetRest.endDate,
			measurementPairs: pairsData.flat()
		}
	};
};

const prepareCloseRequest = (newSet, fetchedSet) => {
	let deviceValues = [];

	for (const [index, fetchedPair] of fetchedSet.measurementPairs.entries()) {
		const newPair = newSet.measurementPairs[index];
		const deviceChanged =
			fetchedPair.inputDevice?.id !== newPair.inputDevice.id ||
			fetchedPair.outputDevice?.id !== newPair.outputDevice?.id;
		const endReadSet = newPair.endReadValue !== null || newPair.secondEndReadValue !== null;

		if (deviceChanged || endReadSet) {
			deviceValues.push({
				deviceId: fetchedPair.inputDevice.id,
				endValue: newPair.endReadValue,
				secondEndValue: newPair.secondEndReadValue
			});
		}
	}

	return {
		deviceValues: deviceValues.length > 0 ? deviceValues : null,
		endDate: newSet.endDate ?? moment(newSet.startDate).subtract(1, 'seconds').toISOString(),
		locationId: newSet.locationId
	};
};

const transformMetadataValues = (metadata) => {
	for (const key in metadata) {
		if (metadata.hasOwnProperty(key) && metadata[key] === '') {
			metadata[key] = null;
			continue;
		}

		if (metaDataTransformMap[key]) metadata[key] = metaDataTransformMap[key](metadata[key]);
	}
	return metadata;
};

const metaDataTransformMap = {
	startReadValue: (v) => fixDecimalSeparator(v),
	secondStartReadValue: (v) => fixDecimalSeparator(v),
	endReadValue: (v) => fixDecimalSeparator(v),
	secondEndReadValue: (v) => fixDecimalSeparator(v),
	correction: (v) => fixDecimalSeparator(v),
	sealNumbers: (v) => (v ? [v] : null)
};

const prepareSetDataByMode = (set, mode) => {
	if (mode !== SET_WIZARD_MODE.edit) return set;

	const measurementPairs = set.measurementPairs.map((pair) => ({
		...pair,
		startReadValue: null,
		secondStartReadValue: null,
		correction: null,
		endReadValue: null,
		secondEndReadValue: null,
		sealNumbers: null,
		orientation: null
	}));

	return { ...set, measurementPairs };
};
