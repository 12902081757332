const GroupTypeName = {
	WaterMeters: {
		name: 'WaterMeters',
		value: 1
	},
	WaterMeterTransmissionDevices: {
		name: 'WaterMeterTransmissionDevices',
		value: 2
	},
	HeatCostAllocators: {
		name: 'HeatCostAllocators',
		value: 3
	},
	HeatMeters: {
		name: 'HeatMeters',
		value: 4
	}
};

export default GroupTypeName;
