import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { formatDateTimeSecondsPrecision } from '@sonar-web/common';
import TimelinePoint from './TimelinePoint';
import { readSucceededColor, readFailedColor, largeDotSize, timelineHeight } from './constants';
import Connector from './Connector';

const useStyles = makeStyles(() => ({
	timelinePeriod: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		flex: 1,
		minHeight: timelineHeight
	}
}));

const TimelinePeriod = ({
	reads,
	startConnector = true,
	endConnector = true,
	overflowRight,
	overflowLeft,
	...rest
}) => {
	const classes = useStyles();

	const [width, setWidth] = useState(null);

	const [items, setItems] = useState(null);

	const [boxRef, setBoxRef] = useState(null);

	const [oveflowRightText, setOveflowRightText] = useState(null);
	const [oveflowLeftText, setOveflowLeftText] = useState(null);

	const handleResize = () => {
		if (boxRef) {
			const changeWidthStep = 4;
			const tempWidth = boxRef.getBoundingClientRect().width;
			setWidth((tempWidth / changeWidthStep) * changeWidthStep);
		}
	};

	useEffect(() => {
		if (boxRef) {
			window.addEventListener('resize', handleResize);
		}
		return () => window.removeEventListener('resize', handleResize);
	}, [boxRef]);

	useEffect(() => {
		handleResize();

		if (width) {
			const wCount = Math.round(width / 30);

			const count = reads.length < wCount ? reads.length : wCount;

			if (overflowRight) {
				const itemsSliced = count == 0 ? [] : reads.slice(0, count);

				if (count < reads.length) {
					const tempCount = reads.length - count;
					setOveflowRightText(`${tempCount}+`);
				} else {
					setOveflowRightText(null);
				}
				setItems(itemsSliced);
			} else if (overflowLeft) {
				const itemsSliced = count == 0 ? [] : reads.slice(reads.length - count, reads.length);

				if (count < reads.length) {
					const tempCount = reads.length - count;
					setOveflowLeftText(`${tempCount}+`);
				} else {
					setOveflowLeftText(null);
				}

				setItems(itemsSliced);
			} else {
				setItems(reads);
			}
		}
	}, [width, boxRef]);

	const outterConnectorMaxWidth = items && items.length > 2 ? 4 : null;

	return (
		<Box
			className={classes.timelinePeriod}
			ref={(el) => {
				if (!el) return;
				setBoxRef(el);
			}}
			{...rest}>
			{items &&
				(items.length == 0 ? (
					<Connector />
				) : (
					<>
						{startConnector && <Connector maxWidth={outterConnectorMaxWidth} />}
						{oveflowLeftText && (
							<>
								<Box display='flex' flexDirection='column' justifyContent='flex-end' height='100%'>
									<Box minHeight={timelineHeight} display='flex' alignItems='center'>
										<TimelinePoint size={largeDotSize} text={oveflowLeftText} />
									</Box>
								</Box>
								<Connector maxWidth={4} />
							</>
						)}
						{items?.map((item, index) => {
							return (
								<React.Fragment key={index}>
									{index != 0 && <Connector />}
									<TimelinePoint
										tooltipText={formatDateTimeSecondsPrecision(
											item.terminalReadDate ?? item.networkReadDate
										)}
										color={item.isSuccessfullRead ? readSucceededColor : readFailedColor}
									/>
								</React.Fragment>
							);
						})}
						{oveflowRightText && (
							<>
								<Connector maxWidth={4} />
								<Box display='flex' flexDirection='column' justifyContent='flex-end' height='100%'>
									<Box minHeight={timelineHeight} display='flex' alignItems='center'>
										<TimelinePoint size={largeDotSize} text={oveflowRightText} />
									</Box>
								</Box>
							</>
						)}
						{endConnector && <Connector maxWidth={outterConnectorMaxWidth} />}
					</>
				))}
		</Box>
	);
};

export default TimelinePeriod;
