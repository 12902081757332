import DatePeriod from '../../enums/DatePeriod';
import { DateHelpers } from '../../helpers';
import LanguageHelper from '../../helpers/LanguageHelper';
import DatePeriodRangeHelper from './DatePeriodRangeHelper';

const DatePeriodRangeTypes = {
	LastDay: {
		name: 'Common_LastFullDay',
		value: DatePeriod.LastDay.name,
		rangeFormat: () => {
			const range = DatePeriodRangeHelper.getLastDayRange(new Date());
			return DateHelpers.formatDateTime(range[0], 'date');
		}
	},
	CurrentDay: {
		name: 'Common_CurrentFullDay',
		value: DatePeriod.CurrentDay.name,
		rangeFormat: () => {
			const range = DatePeriodRangeHelper.getCurrentDayRange(new Date());
			return DateHelpers.formatDateTime(range[0], 'date');
		}
	},
	LastWeek: {
		name: 'Common_LastFullWeek',
		value: DatePeriod.LastWeek.name,
		rangeFormat: () => {
			const range = DatePeriodRangeHelper.getLastFullWeekRange(new Date());
			return `${DateHelpers.formatDateTime(range[0], 'date')} - ${DateHelpers.formatDateTime(range[1], 'date')}`;
		}
	},
	Last30Days: {
		name: 'Common_LastFull30Days',
		value: DatePeriod.Last30Days.name,
		rangeFormat: () => {
			const range = DatePeriodRangeHelper.getLast30DaysRange(new Date());
			return `${DateHelpers.formatDateTime(range[0], 'date')} - ${DateHelpers.formatDateTime(range[1], 'date')}`;
		}
	},
	LastMonth: {
		name: 'Common_LastFullMonth',
		value: DatePeriod.LastMonth.name,
		rangeFormat: () => {
			const range = DatePeriodRangeHelper.getLast30DaysRange(new Date());
			const months = LanguageHelper.getMonthNames();

			return `${months[range[0].getMonth()]} ${range[0].getFullYear()}`;
		}
	},
	Own: {
		name: 'Common_CustomNumberOfDays',
		value: DatePeriod.Own.name,
		rangeFormat: (days) => {
			return days ?? 'Select';
		}
	}
};

export default DatePeriodRangeTypes;

export const datePeriodRangeTypesArray = Object.entries(DatePeriodRangeTypes).map((d) => d[1]);
