import React from 'react';
import PanelListItemAction from './PanelListItemAction';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';

const PanelListItemActionMore = ({ onClick }) => {
	return (
		<PanelListItemAction icon={<MoreVertRoundedIcon fontSize='small' />} tooltip={'Common_Manage'} onClick={onClick} />
	);
};

export default PanelListItemActionMore;
