import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { guidEmpty } from '@sonar-web/common';
import useLocale from '@sonar-web/common/src/hooks/useLocale';
import ProfilesGrid from './ProfilesGrid';
import { selectProfiles, fetchProfilesAsync } from './deviceConfigProfilesSlice';
import ProfilesContext from './profilesContext';

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: 0
	},
	titleRoot: {
		marginBottom: theme.spacing(0.5)
	},
	subtitleRoot: {
		marginTop: theme.spacing(1.5),
		fontWeight: theme.typography.fontWeightMedium,
		whiteSpace: 'pre-line',
		lineHeight: 1.3
	},
	linkBtn: {
		padding: '0 0 4px 0',
		'& > span': {
			fontSize: theme.typography.body1.fontSize
		}
	},
	profileName: {
		fontWeight: theme.typography.fontWeightMedium
	}
}));

const DeviceConfigurationProfiles = ({ deviceData, configData }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { translate } = useLocale();
	const { api } = useContext(ProfilesContext);
	const { systemProfiles, userProfiles } = useSelector(selectProfiles);

	const deviceTypeId = deviceData?.deviceTypeId;
	const profileId = configData?.profileId;
	const isPendingConfiguration = configData?.pendingConfig;
	const currentProfileId = isPendingConfiguration ? null : profileId;
	const hasConfiguration = !isPendingConfiguration && profileId && profileId !== guidEmpty;

	useEffect(() => {
		if (deviceTypeId) dispatch(fetchProfilesAsync({ api: api.fetch, deviceTypeId }));
	}, [deviceTypeId]);

	let currentProfile = null;

	if (hasConfiguration) {
		for (const p of systemProfiles) {
			if (p.id === profileId) {
				currentProfile = p;
				break;
			}
		}

		if (currentProfile == null) {
			for (const p of userProfiles) {
				if (p.id === profileId) {
					currentProfile = p;
					break;
				}
			}
		}
	}

	const renderProfileInformationTitle = ({ outterText, profileName }) => {
		const array = translate(outterText).split('{0}');
		const firstText = array[0];
		const secondText = array.length > 0 ? array[1] : null;

		return (
			<Box display='inline' className={classes.titleRoot}>
				<>
					<Typography variant='body1' component='span'>
						{firstText}
						{translate('Common_Devices_Configuration')}
					</Typography>
					{secondText && (
						<Typography variant='body1' component='span'>
							{secondText}
						</Typography>
					)}
					{profileName && (
						<Typography
							variant='body1'
							component='span'
							classes={{
								root: classes.profileName
							}}>
							{' '}
							{translate(profileName)}
						</Typography>
					)}
				</>
			</Box>
		);
	};

	return (
		<Box overflow='auto' pr={1} pt={3}>
			{hasConfiguration &&
				(currentProfile
					? renderProfileInformationTitle({
							outterText: 'Common_Devices_ConfigurationCompatibleWithProfileCurrentlyLoaded',
							profileName: currentProfile.name
					  })
					: renderProfileInformationTitle({
							outterText: 'Common_Devices_CustomConfigurationCurrentlyLoaded',
							profileName: null
					  }))}

			<Typography
				variant='body1'
				color='textPrimary'
				component='p'
				classes={{ root: classes.subtitleRoot }}
				data-at='Common_Device_SystemProfiles'>
				{translate('Common_Device_SystemProfiles')}
			</Typography>
			<ProfilesGrid
				currentProfileId={currentProfileId}
				records={systemProfiles}
				textNoData={'Common_Device_NoConfigurationSystemProfiles'}
			/>
			<Typography
				variant='body1'
				color='textPrimary'
				component='p'
				classes={{ root: classes.subtitleRoot }}
				data-at='Common_Device_UserProfiles'>
				{translate('Common_Device_UserProfiles')}
			</Typography>
			<ProfilesGrid
				currentProfileId={currentProfileId}
				records={userProfiles}
				textNoData={'Common_Device_NoConfigurationUserProfiles'}
			/>
		</Box>
	);
};

export default DeviceConfigurationProfiles;
