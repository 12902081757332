import React from 'react';
import { BaseIcon } from '@sonar-web/common';

export default function BillingIndividualsIcon(props) {
	return (
		<BaseIcon {...props}>
			<path d='m 4.91705,1.9999999 c -0.611388,0 -1.111261,0.500107 -1.111261,1.1112605 V 18.110604 18.94535 20.890049 C 3.805789,21.501158 4.306971,22 4.918352,22 h 9.443066 c 0.610853,0 1.475195,-0.341797 1.919917,-0.7604 l 3.10291,-2.922914 c 0.445269,-0.418601 0.809966,-1.260553 0.809966,-1.871662 V 3.1112604 c -4e-5,-0.6112015 -0.498845,-1.1112605 -1.109953,-1.1112605 h -1.9447 -0.833443 z m 0,0.8334435 h 14.167208 c 0.150529,0 0.277817,0.1272832 0.277817,0.277817 l -0.0014,13.3337636 c 0,0.08248 -0.01515,0.178116 -0.03778,0.277818 h -3.156383 c -0.843022,0.0014 -1.525616,0.684264 -1.527327,1.527325 v 2.882485 c -0.09914,0.01994 -0.194774,0.03518 -0.277815,0.03518 H 4.91702 c -0.150857,0 -0.277818,-0.127283 -0.277818,-0.277817 V 3.1112302 c 0,-0.1505289 0.127006,-0.2778171 0.277818,-0.2778171 z m 7.082302,4.3224236 c -1.169907,0 -2.124697,0.9547826 -2.124697,2.12469 0,0.7092671 0.353806,1.33536 0.890836,1.721662 -1.084216,0.476084 -1.844268,1.554157 -1.844268,2.813354 v 1.138648 h 0.670408 v -1.138648 c 0,-1.340214 1.067866,-2.40891 2.407721,-2.410326 h 0.0041 c 1.341097,0 2.40512,1.069224 2.40512,2.410326 v 1.138648 h 0.670401 v -1.138648 c 0,-1.259833 -0.75797,-2.338938 -1.841659,-2.814657 0.535728,-0.38644 0.888224,-1.0121139 0.888224,-1.720359 0,-1.1699074 -0.954783,-2.12469 -2.12469,-2.12469 z m 0,0.6651864 c 0.808997,0 1.459497,0.6505022 1.459497,1.4595036 0,0.809004 -0.6505,1.454282 -1.459497,1.454282 -0.809008,0 -1.459503,-0.645278 -1.459503,-1.454282 0,-0.8090014 0.650495,-1.4594961 1.459503,-1.4595036 z m 4.167208,9.7339246 h 2.779445 c -0.04308,0.05856 -0.087,0.112972 -0.13175,0.155223 l -3.104208,2.921612 c -0.06358,0.05969 -0.14596,0.118954 -0.237378,0.174759 v -2.556408 c 0,-0.190837 0.07653,-0.36266 0.203456,-0.490418 0.128066,-0.12693 0.299581,-0.204768 0.490417,-0.204768 z' />
		</BaseIcon>
	);
}
