import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Formik } from 'formik';
import { FormTopActionsToolbar } from '../../../../components';
import useConfigurator from '../useConfigurator';
import ConfiguratorTabs from '../Controls/ConfiguratorTabs';
import ConfiguratorSectionDescription from '../Controls/ConfiguratorSectionDescription';
import useValidation from './useValidation';
import SchemaValidationUpdater from './SchemaValidationUpdater';

const useStyles = makeStyles(() => ({
	form: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%'
	}
}));

const DatasForm = ({ section }) => {
	const classes = useStyles();
	const {
		toggleSectionEditMode,
		loadData,
		selectSectionDatas,
		isReadOnly,
		isRegisterSelectionModeEnabled,
		datasFormRegisters,
		revertChangesToDatasFormRegisters,
		getDatasFormElementsSettings
	} = useConfigurator();
	const { createSchema } = useValidation();
	const [initialDatasFormRegisters] = useState(datasFormRegisters);

	const [schema, setSchema] = useState(null);

	//update schemy walidacji - może być nawet bezpośrednio odpalone przez kontrolkę
	//działa tylko wtedy gdy jest włączona selekcja rejestrów (grupy)
	const updateSchema = (formValues, elementOptions) => {
		const sectionElements = getDatasFormElementsSettings(section.name);
		setSchema(
			createSchema({ name: section.name, sectionElements }, formValues, datasFormRegisters, elementOptions)
		);
	};

	const handleCancel = () => {
		if (isRegisterSelectionModeEnabled) revertChangesToDatasFormRegisters(initialDatasFormRegisters);
		toggleSectionEditMode(false);
	};

	const handleOnSubmit = (values) => {
		loadData(values);
		toggleSectionEditMode(false);
	};

	useEffect(() => {
		const sectionElements = getDatasFormElementsSettings(section.name);
		setSchema(createSchema({ name: section.name, sectionElements }));
	}, []);

	if (!schema) return null;
	return (
		<Formik
			initialValues={selectSectionDatas(section)}
			enableReinitialize
			onSubmit={handleOnSubmit}
			validationSchema={schema}>
			{({ handleSubmit, ...formActions }) => {
				return (
					<form onSubmit={handleSubmit} className={classes.form}>
						{isRegisterSelectionModeEnabled && (
							<SchemaValidationUpdater formValues={formActions.values} updateSchema={updateSchema} />
						)}
						{section.notification && !isReadOnly && (
							<ConfiguratorSectionDescription text={section.notification} />
						)}
						<FormTopActionsToolbar
							title={section.name}
							handleCancel={handleCancel}
							handleSubmit={handleSubmit}
							pending={false}
							submitDisabled={isReadOnly}
							disableSubmitMany={true}
						/>
						<ConfiguratorTabs
							tabs={section.tabs}
							form={formActions}
							forceUpdateValidationSchema={updateSchema}
						/>
					</form>
				);
			}}
		</Formik>
	);
};

export default DatasForm;
