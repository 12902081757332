import React from 'react';
import { BaseIcon } from '@sonar-web/common';

export default function BillingChangesIcon(props) {
	return (
		<BaseIcon {...props}>
			<path d='m 4.406071,2.0000979 c -0.3607672,0 -0.6664422,0.300316 -0.6664422,0.661096 V 10.04144 c 0,0.360817 0.3056619,0.661094 0.6664422,0.661094 h 10.235644 l -0.972277,1.037722 a 0.41028056,0.41028056 0 0 0 0.01987,0.579628 0.41028056,0.41028056 0 0 0 0.579633,-0.02013 l 1.61601,-1.725528 a 0.41032158,0.41032158 0 0 0 0,-0.560931 l -1.61601,-1.7215261 a 0.41028056,0.41028056 0 0 0 -0.30718,-0.130876 0.41028056,0.41028056 0 0 0 -0.272453,0.110854 0.41028056,0.41028056 0 0 0 -0.01987,0.580966 l 0.966936,1.032377 H 4.56233 v -7.067724 h 10.792568 v 3.675431 a 0.41032029,0.41032029 0 0 0 0.408682,0.414019 h 3.674087 v 1.724195 c 5.71e-4,0.0071 0.0042,0.01281 0.0042,0.02013 v 0.07212 a 0.41028056,0.41028056 0 0 0 -0.0042,0.04674 v 3.2293581 a 0.41028056,0.41028056 0 0 0 0.414025,0.41402 0.41028056,0.41028056 0 0 0 0.408679,-0.41402 v -1.957918 -1.2714451 -1.86309 -0.82136 l -0.0013,-0.0013 v -0.0068 l -4.080104,-4.076096 h -0.0013 l -0.0013,-0.0013 h -0.82136 z m 11.771528,1.160592 2.926184,2.923516 h -2.926184 z m 3.778268,1.657415 0.0094,0.0094 c 0.01255,-0.0013 0.02013,-0.0094 0.03338,-0.0094 z M 4.1536508,12.120886 a 0.41028054,0.41028054 0 0 0 -0.414022,0.414022 v 2.509494 0.247088 6.04202 c 0,0.360836 0.3056619,0.66644 0.6664422,0.66644 h 15.189194 c 0.360767,0 0.665106,-0.305654 0.665106,-0.66644 v -6.289097 c 0,-0.36082 -0.304321,-0.66243 -0.665106,-0.66243 H 9.3636283 l 0.9642667,-1.03238 a 0.41028056,0.41028056 0 0 0 -0.02169,-0.580964 0.41028056,0.41028056 0 0 0 -0.3178637,-0.110854 0.41028056,0.41028056 0 0 0 -0.256419,0.130902 l -1.6160125,1.721521 a 0.41032158,0.41032158 0 0 0 0,0.560931 l 1.6160125,1.725528 a 0.41028056,0.41028056 0 0 0 0.5742877,0.02013 0.41028056,0.41028056 0 0 0 0.0217,-0.579628 L 9.3596303,15.200782 H 19.43768 v 5.976582 H 4.5623353 v -3.55535 c -2.85e-4,-0.0052 -0.00258,-0.0097 -0.00258,-0.01464 v -3.851726 c 0,-0.0052 0.00199,-0.0097 0.00258,-0.01464 V 12.534768 A 0.41028054,0.41028054 0 0 0 4.153656,12.120746 Z' />
		</BaseIcon>
	);
}
