import { apiBase } from '@sonar-web/common';
import { REST_API_PATHS } from '@sonar-web/common/src/constants/system';

const path = REST_API_PATHS.CUSTOMER;

export async function fetchCustomersForLocation(locationId) {
	const { data } = await apiBase(`locationHistory/${locationId}`, 'get', {
		path
	});

	return data;
}

export async function addCustomer(requestData) {
	const response = await apiBase(`customer`, 'post', {
		path,
		data: requestData
	});

	const locationHeader = response.headers.location;

	if (locationHeader) return locationHeader.slice(locationHeader.indexOf('/') + 1);
	return response;
}

export async function editCustomer(requestData) {
	const { data } = await apiBase(`customer/${requestData.id}`, 'put', {
		path,
		data: requestData
	});

	return data;
}

export async function fetchCustomer(customerId) {
	const { data } = await apiBase(`customer/${customerId}`, 'get', {
		path
	});

	return data;
}

export async function customerPointAssignmentAdd(requestData) {
	const { data } = await apiBase(`assignCustomerToLocation`, 'post', {
		path,
		data: requestData
	});

	return data;
}

export async function customerPointAssignmentEdit(requestData) {
	const { data } = await apiBase(`currentCustomerStartDate`, 'put', {
		path,
		data: { id: requestData.id, from: requestData.from }
	});

	return data;
}

export async function customerPointAssignmentClose(requestData) {
	const { data } = await apiBase(`unassignCustomerFromLocation`, 'post', {
		path,
		data: requestData
	});

	return data;
}

export async function customerSearch(pageDescriptor) {
	const { data } = await apiBase(`customerSearch`, 'get', {
		path,
		params: { pageDescriptor }
	});

	return data;
}

const CustomersService = {
	fetchCustomersForLocation,
	addCustomer,
	editCustomer,
	fetchCustomer,
	customerPointAssignmentAdd,
	customerPointAssignmentEdit,
	customerPointAssignmentClose,
	customerSearch
};

export default CustomersService;
