import React from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '../../../../hooks';
import { BaseTooltip } from '../../../..';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	root: {
		alignSelf: 'flex-start'
	},
	withTooltip: {
		borderBottom: `1px dashed ${theme.palette.grey[400]}`
	}
}));

const ConfiguratorLabel = ({ label, tooltip, marginBottom = 0, marginTop = 0 }) => {
	const classes = useStyles();
	const { translate } = useLocale();

	return (
		<BaseTooltip text={translate(tooltip)} hide={!tooltip} placement='right'>
			<Typography
				variant='caption'
				color='textSecondary'
				classes={{ root: classes.root }}
				className={clsx({ [classes.withTooltip]: tooltip })}
				style={{ marginTop }}>
				{translate(label)}
			</Typography>
		</BaseTooltip>
	);
};

export default ConfiguratorLabel;
