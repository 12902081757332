import React from 'react';
import { Typography, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '@sonar-web/common/src/hooks';

const useStyles = makeStyles((theme) => ({}));

const LocationNodeMoveError = ({ message }) => {
	const { translate } = useLocale();

	return (
		<Box height={40} display='flex' alignItems='flex-end' justifyContent='flex-end'>
			<Typography variant='caption' color='error'>
				{translate(message)}
			</Typography>
		</Box>
	);
};

export default LocationNodeMoveError;
