import React from 'react';
import { FormControl, InputLabel, Select } from '@mui/material';
import { useLocale } from '@sonar-web/common/src/hooks';
import BaseSelectMenuItem from '@sonar-web/common/src/components/BaseSelectMenuItem/BaseSelectMenuItem';
import { timeUnitSeparators } from './constants';
import { capitalize } from './helpers';

const options = [
	{ value: timeUnitSeparators.colon, label: 'Common_Colon' },
	{ value: timeUnitSeparators.dot, label: 'Common_Dot' },
	{ value: timeUnitSeparators.dash, label: 'Common_Dash' },
	{ value: timeUnitSeparators.slash, label: 'Common_Slash' }
];

const name = 'timeUnitSeparator';
const SettingsTimeUnitSeparator = ({ updateSettings, current }) => {
	const { translate } = useLocale();

	const handleChange = (e) => {
		updateSettings(name, e.target.value);
	};

	return (
		<FormControl fullWidth margin='normal'>
			<InputLabel id={name}>{translate('Common_TimeUnitSeparator')}</InputLabel>
			<Select labelId={name} value={current[name]} onChange={handleChange}>
				{options.map((opt, index) => (
					<BaseSelectMenuItem key={index} value={opt.value}>
						{capitalize(translate(opt.label))}
					</BaseSelectMenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default SettingsTimeUnitSeparator;
