import { useEffect } from 'react';
import useConfigurator from './useConfigurator';

const ConfiguratorVisitControl = () => {
	const { activeStep, visitedSteps, updateStepVisited } = useConfigurator();

	useEffect(() => {
		if (visitedSteps[activeStep] === true) return;

		updateStepVisited(activeStep, true);
	}, [activeStep]);

	return null;
};

export default ConfiguratorVisitControl;
