import { Box } from '@mui/material';

const TableListColumn = ({
	flex = false,
	width = 'auto',
	centerVertically = false,
	disableLeftPadding = false,
	disableRightPadding = false,
	hide = false,
	wrap = false,
	children,
	...rest
}) => {
	const center = centerVertically ? { display: 'flex', flexDirection: 'column', justifyContent: 'center' } : {};

	return (
		<Box
			flex={flex ? 1 : 0}
			minWidth={width}
			width={width}
			paddingRight={disableRightPadding ? 0 : 2}
			paddingLeft={disableLeftPadding ? 0 : 2}
			sx={{
				visibility: hide ? 'hidden' : 'visible',
				wordBreak: wrap ? 'break-all' : 'normal'
			}}
			{...center}
			{...rest}>
			{children}
		</Box>
	);
};

export default TableListColumn;
