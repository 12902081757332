import { filterOperatorSign } from '../constants/filterOperators';

function urlParamsFromPageDescriptor(pd, membersWithOperatorSigns = []) {
	let params = `limit=${pd.Limit}&offset=${pd.Offset}`;

	if (pd.FilterDescriptors.length > 0) params += getFiltersParams(pd.FilterDescriptors, membersWithOperatorSigns);
	if (pd.SortDescriptors.length > 0) params += getSortersParams(pd.SortDescriptors);

	return params;
}

function getFiltersParams(filters, membersWithOperatorSigns) {
	return filters.reduce((acc, curr) => {
		const member = curr.member;
		const value = Array.isArray(curr.value) ? `[${curr.value.join(',')}]` : curr.value;
		const operatorSign = membersWithOperatorSigns.includes(member) ? filterOperatorSign(curr.filterOperator) : '';

		acc += `&${encodeURIComponent(member)}=${operatorSign}${encodeURIComponent(value)}`;
		return acc;
	}, '');
}

function getSortersParams(sorters) {
	return sorters.reduce((acc, curr) => {
		const member = encodeURIComponent(`orderBy${curr.member.capitalize()}`);
		const value = encodeURIComponent(curr.listSortDirection.capitalize());

		acc += `&${member}=${value}`;
		return acc;
	}, '');
}

const ApiHelpers = {
	urlParamsFromPageDescriptor
};

export default ApiHelpers;
