import { createSelector, createSlice } from '@reduxjs/toolkit';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import { slices } from '../Constants/Module';
import OrdersService from '../OrdersService';

const slice = slices.protocolTemplate;
const initialState = {
	template: null,
	templateFetchPending: false,
	templateSingleValuePending: false,
	protocolTemplateLoadDefaultsPending: false
};

export const protocolTemplateSlice = createSlice({
	name: slice,
	initialState,
	reducers: {
		protocolTemplateFetched: (state, { payload }) => {
			state.template = payload;
			state.templateFetchPending = false;
		},
		setProtocolTemplateFetchPending: (state, { payload }) => {
			state.templateFetchPending = payload;
		},
		setTemplateValue: (state, { payload }) => {
			if (!payload) return;
			state.template.elements[payload.key] = payload.value;
		},
		setTemplateSingleValuePending: (state, { payload }) => {
			state.templateSingleValuePending = payload;
		},
		protocolTemplateLoadDefaultsPending: (state, { payload }) => {
			state.protocolTemplateLoadDefaultsPending = payload;
		},
		protocolTemplatePreviewPending: (state, { payload }) => {
			state.protocolTemplatePreviewPending = payload;
		},
		resetProtocolTemplate: (state) => {
			for (let key in initialState) {
				state[key] = initialState[key];
			}
		}
	}
});

export const {
	protocolTemplateFetched,
	setProtocolTemplateFetchPending,
	setTemplateValue,
	resetProtocolTemplate,
	setTemplateSingleValuePending,
	protocolTemplateLoadDefaultsPending,
	protocolTemplatePreviewPending
} = protocolTemplateSlice.actions;

export const fetchProtocolTemplateAsync = () => async (dispatch) => {
	try {
		dispatch(setProtocolTemplateFetchPending(true));
		const result = await OrdersService.fetchProtocolTemplate();
		dispatch(protocolTemplateFetched(result));
	} catch (error) {
		dispatch(setProtocolTemplateFetchPending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const updateProtocolTemplateSingleValueAsync = (keyValue) => async (dispatch) => {
	try {
		dispatch(setTemplateSingleValuePending(true));
		await OrdersService.updateProtocolTemplateSingleValue(keyValue);
		dispatch(setTemplateSingleValuePending(false));
		dispatch(setTemplateValue(keyValue));
		return true;
	} catch (error) {
		dispatch(setTemplateSingleValuePending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const fetchProtocolTemplateLoadDefaultsAsync = (lang) => async (dispatch) => {
	try {
		dispatch(protocolTemplateLoadDefaultsPending(true));
		await OrdersService.protocolTemplateLoadDefaults(lang);
		dispatch(protocolTemplateLoadDefaultsPending(false));
		return true;
	} catch (error) {
		dispatch(protocolTemplateLoadDefaultsPending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const fetchProtocolTemplatePreviewAsync = () => async (dispatch) => {
	try {
		dispatch(protocolTemplatePreviewPending(true));
		const result = await OrdersService.fetchProtocolTemplatePreview();
		dispatch(protocolTemplatePreviewPending(false));
		return result;
	} catch (error) {
		dispatch(protocolTemplatePreviewPending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const selectProtocolTemplate = createSelector(
	(state) => state.protocolTemplate,
	(protocolTemplate) => ({
		template: protocolTemplate.template,
		pending: protocolTemplate.templateFetchPending
	})
);

export const selectProtocolTemplateSingleValue = createSelector(
	(state) => state.protocolTemplate,
	(protocolTemplate) => ({
		pending: protocolTemplate.templateSingleValuePending
	})
);

export const selectprotocolTemplateLoadDefaults = createSelector(
	(state) => state.protocolTemplate,
	(protocolTemplate) => ({
		pending: protocolTemplate.protocolTemplateLoadDefaultsPending
	})
);

export const selectProtocolTemplatePreview = createSelector(
	(state) => state.protocolTemplate,
	(protocolTemplate) => ({
		pending: protocolTemplate.protocolTemplatePreviewPending
	})
);

export default protocolTemplateSlice.reducer;
