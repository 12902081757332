import React from 'react';
import ConfigurationDataCard from './ConfigurationDataCard';
import ComponentNoData from '../../../../components/NoDataPlaceholders/ComponentNoData';
import ConfigurationDataCardSkeleton from './ConfigurationDataCardSkeleton';
import CardsGrid from '../../../Cards/CardsGrid';

const ConfigurationDatasGrid = ({ records, handleEditConfigurationData, configuratorActions }) => {
	const fetchDatasFormPending = configuratorActions.fetchDatasFormPending;

	if (fetchDatasFormPending) {
		return (
			<CardsGrid
				records={[1, 2, 3, 4, 5, 6]}
				renderCard={(_, index) => {
					return <ConfigurationDataCardSkeleton key={index} />;
				}}
				gridPadding={0}
			/>
		);
	}

	if (!records || records.length == 0) {
		return <ComponentNoData show={true} text='Common_Device_NoConfigurationProfiles' />;
	}

	return (
		<CardsGrid
			data-at='configuratorSections'
			records={records}
			renderCard={(record, index) => {
				return (
					<ConfigurationDataCard
						data-at={`Section_${record.name}`}
						record={record}
						key={index}
						handleEditConfigurationData={() => handleEditConfigurationData(record)}
					/>
				);
			}}
			gridPadding={0}
		/>
	);
};

export default ConfigurationDatasGrid;
