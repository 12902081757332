import styled from '@emotion/styled';
import FloorIndicator from './FloorIndicator';

const Wrapper = styled('div')({
	minWidth: 100,
	position: 'sticky',
	left: 0,
	zIndex: 2,
	backgroundColor: '#fff'
});

const Corner = styled('div')({
	height: 37,
	width: '100%',
	position: 'sticky',
	left: 0,
	zIndex: 2,
	backgroundColor: '#fff'
});

const FloorIndicatorWrapper = ({ allFloors, visualizationType }) => {
	return (
		<Wrapper>
			<Corner />
			{allFloors.map((floor) => (
				<FloorIndicator key={floor} floor={floor} visualizationType={visualizationType} />
			))}
		</Wrapper>
	);
};

export default FloorIndicatorWrapper;
