import { fetchDeviceTypes } from '../DeviceTypesSearch/api';
import { sidebarComponents, sidebarType } from '../DeviceType/sidebar';
import {
	deleteDeviceTypeAsync,
	selectDeleteDeviceTypePending,
	resetDeviceTypeData
} from '../DeviceType/wmbusDeviceTypeSlice';
export default () => {
	return {
		sidebarType: sidebarType,
		sidebarComponents: sidebarComponents,
		fetchDeviceTypes: fetchDeviceTypes,
		deleteDeviceTypeAsync: deleteDeviceTypeAsync,
		selectDeleteDeviceTypePending: selectDeleteDeviceTypePending,
		resetDeviceTypeData: resetDeviceTypeData
	};
};
