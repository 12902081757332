export const sidebars = {
	heatallocator: 'heatallocator',
	heatallocators: 'heatallocators',
	heatallocatorReads: 'heatallocatorReads',
	heatallocatorRead: 'heatallocatorRead',
	heatallocatorEvents: 'heatallocatorEvents',
	heatallocatorEvent: 'heatallocatorEvent'
};

export const readType = {
	instant: { value: 1, name: 'Instant' },
	logged: { value: 2, name: 'Logged' },
	monitoring: { value: 3, name: 'Monitoring' }
};

export const readSource = {
	tenant: { value: 1, name: 'Tenant' },
	inkasent: { value: 2, name: 'Inkasent' }
};

export const readPeriod = {
	Day: { value: 1, name: 'Day' },
	Week: { value: 2, name: 'Week' },
	Month: { value: 3, name: 'Month' },
	Year: { value: 4, name: 'Year' }
};

export const slices = {
	heatallocator: 'heatallocator',
	heatallocators: 'heatallocators',
	heatallocatorRead: 'heatallocatorRead',
	heatallocatorReads: 'heatallocatorReads',
	heatallocatorEvent: 'heatallocatorEvent',
	heatallocatorEvents: 'heatallocatorEvents'
};

export const readTypePeriod = {
	InstantMonth: { value: 1, name: 'InstantMonth' },
	InstantYear: { value: 2, name: 'InstantYear' },
	LoggedMonth: { value: 3, name: 'LoggedMonth' },
	LoggedYear: { value: 4, name: 'LoggedYear' }
};

export const moduleName = 'heatallocator';
