import React from 'react';
import { IconButton, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '@sonar-web/common/src/hooks';
import { RecycleBinIcon } from '@sonar-web/common';
import AvatarLettersNotInSystem from '../AvatarLetters/AvatarLettersNotInSystem';
import AvatarLetters from '../AvatarLetters/AvatarLetters';

const useStyles = makeStyles(() => ({
	listItemRoot: {
		borderBottom: 'none',
		paddingLeft: 0
	},
	listContainer: {
		borderBottom: 'none',
		marginTop: 16
	},
	listItemTextRoot: {
		borderBottom: 'none',
		marginLeft: 8
	}
}));

const ShareUsersListItem = ({ name, email, onDelete, disableSearch, autocompleteProps = {} }) => {
	const classes = useStyles();
	const { translate } = useLocale();
	const notInSystem = !name;

	const handleClick = () => onDelete(email);

	return (
		<ListItem
			dense
			classes={{ root: classes.listItemRoot, container: classes.listContainer }}
			{...autocompleteProps}
			key={autocompleteProps.id}
			secondaryAction={
				onDelete ? (
					<IconButton edge='end' aria-label='delete' onClick={handleClick}>
						<RecycleBinIcon fontSize='small' />
					</IconButton>
				) : null
			}>
			<ListItemAvatar>
				{notInSystem ? (
					<AvatarLettersNotInSystem value={name} tooltipProps={{ hide: true }} />
				) : (
					<AvatarLetters value={name} tooltipProps={{ hide: true }} />
				)}
			</ListItemAvatar>
			<ListItemText
				classes={{ root: classes.listItemTextRoot }}
				primary={
					disableSearch
						? translate('Access_Email')
						: notInSystem
							? translate('Reports_UserNotInSystem')
							: name
				}
				primaryTypographyProps={{ variant: 'body2' }}
				secondary={email}
				secondaryTypographyProps={{ variant: 'caption', color: 'textSecondary' }}
			/>
		</ListItem>
	);
};

export default ShareUsersListItem;
