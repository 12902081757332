import { createSlice } from '@reduxjs/toolkit';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import Module from '../Constants/Module';
import ImportService from '../ImportService';

const slice = Module.slices.import;
export const importSlice = createSlice({
	name: slice,
	initialState: {
		pending: false,
		progress: 0,
		templatePending: null
	},
	reducers: {
		importSucces: (state) => {
			state.pending = false;
			state.cancel = null;
		},
		importPending: (state, { payload }) => {
			state.pending = payload;
		},
		importProgress: (state, { payload }) => {
			state.progress = payload;
		},
		resetProgress: (state) => {
			state.progress = 0;
		},
		fetchTemplateSucces: (state) => {
			state.templatePending = false;
		},
		fetchTemplatePending: (state, { payload }) => {
			const { typeState, type } = payload;
			state.templatePending = { ...state.templatePending, [type]: typeState };
		}
	}
});

export const { importSucces, importPending, importProgress, resetProgress, fetchTemplateSucces, fetchTemplatePending } =
	importSlice.actions;

export const addImportAsync = (file, type, abortController, onSuccess, onFailure) => async (dispatch) => {
	try {
		const onUploadProgress = (progressEvent) => {
			const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
			dispatch(importProgress(Number.isFinite(percentCompleted) ? percentCompleted : 100));
		};

		dispatch(importPending(true));
		const response = await ImportService.addImport(
			{ file, type },
			{ onUploadProgress, signal: abortController.signal }
		);

		dispatch(importSucces(response));
		onSuccess(response);

		return response;
	} catch (error) {
		if (onFailure) onFailure();

		dispatch(importPending(false));
		dispatch(resetProgress());

		if (error.message === 'canceled') return;

		dispatch(addErrorAsync({ slice, error }));
	}
};

export const fetchTemplateAsync = (type) => async (dispatch, getState) => {
	try {
		dispatch(fetchTemplatePending({ type, typeState: true }));

		const locale = getState().locales.locale;
		const response = await ImportService.fetchAESTemplateFile(type, locale);

		dispatch(fetchTemplateSucces({ type, typeState: false }));
		return response;
	} catch (error) {
		dispatch(fetchTemplatePending({ type, typeState: false }));

		const errorResult = await dispatch(addErrorAsync({ slice, error }));
		return errorResult;
	}
};

export const selectProgress = (state) => state.import.progress;
export const selectCancel = (state) => state.import.cancel;
export const selectTemplatePending = (state) => state.import.templatePending;

export default importSlice.reducer;
