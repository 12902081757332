import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const DeviceConfiguratorCover = ({ children, style = {} }) => {
	const theme = useTheme();

	return (
		<Box
			position='absolute'
			width='100%'
			height='100%'
			zIndex={theme.zIndex.drawer}
			display='flex'
			style={{ backgroundColor: theme.palette.background.paper, ...style }}>
			{children}
		</Box>
	);
};

export default DeviceConfiguratorCover;
