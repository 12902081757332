import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FormControlLabel } from '@mui/material';
import { useLocale } from '@sonar-web/common/src/hooks';
import BaseCheckbox from '../../../components/BaseCheckbox/BaseCheckbox';
import styled from '@emotion/styled';
import { useEffect } from 'react';

const StyledForm = styled('form')({
	display: 'flex',
	flexDirection: 'column',
	marginTop: '1.5rem'
});

const getValidationSchema = (consents) =>
	Yup.object().shape(
		consents.reduce((acc, curr) => {
			acc[curr.name] = Yup.boolean().required('Common_ValidationRequired');
			return acc;
		}, {})
	);

const DeviceConfigurationConsents = ({ setDisabled, consents }) => {
	const { translate } = useLocale();

	const { values, setFieldValue } = useFormik({
		validationSchema: getValidationSchema(consents),
		initialValues: consents.reduce((acc, curr) => {
			acc[curr.name] = false;
			return acc;
		}, {})
	});

	const setChecked = (e) => {
		setFieldValue(e.target.name, e.target.checked, true);
	};

	useEffect(() => {
		setDisabled((prev) => ({ ...prev, consents: Object.values(values).some((val) => val === false) }));
	}, [values]);

	return (
		<StyledForm
			onSubmit={(e) => {
				e.preventDefault();
			}}>
			{consents.map((consent) => {
				return (
					<FormControlLabel
						key={consent.name}
						label={translate(consent.text)}
						componentsProps={{
							typography: {
								variant: 'caption',
								color: 'textSecondary'
							}
						}}
						control={
							<BaseCheckbox name={consent.name} checked={values[consent.name]} setChecked={setChecked} />
						}
					/>
				);
			})}
		</StyledForm>
	);
};

export default DeviceConfigurationConsents;
