import EveryDayControls from './Controls/EveryDayControls';
import EveryHourControls from './Controls/EveryHourControls';
import EveryMonthControls from './Controls/EveryMonthControls';
import EveryWeekControls from './Controls/EveryWeekControls';
import OneTimeControls from './Controls/OneTimeControls';
import EveryHourIcon from './Icons/EveryHourIcon';
import EveryDayIcon from './Icons/EveryDayIcon';
import EveryWeekIcon from './Icons/EveryWeekIcon';
import EveryMonthIcon from './Icons/EveryMonthIcon';
import OneTimeIcon from './Icons/OneTimeIcon';
import { formatDateTime } from '@sonar-web/common';
import LanguageHelper from '../../../helpers/LanguageHelper';

export const cronLastDayOfMonth = 'L';

export const indexOfDate = (arr, date) => {
	for (var i = 0; i < arr.length; i++) {
		if (+arr[i] === +date) return i;
	}

	return -1;
};

export const dayOfMonthComparer = (a, b) => {
	if (a == b) return 0;
	if (a == cronLastDayOfMonth) return 1;
	if (b == cronLastDayOfMonth) return -1;

	const a1 = Number.parseInt(a);
	const b1 = Number.parseInt(b);
	if (a1 > b1) return 1;
	if (a1 < b1) return -1;
	return 0;
};

export const dayOfTheMonthToString = (day, translate) =>
	day == cronLastDayOfMonth ? translate('Common_LastDayOfMonth').toLowerCase() : day;

export const types = {
	oneTime: {
		type: 'oneTime',
		icon: OneTimeIcon,
		label: 'Common_OneTime',
		control: OneTimeControls,
		updateCronData: (defaultCorn, data) => {
			if (!data) return;

			return data.map((date) => {
				return {
					...defaultCorn,
					minute: [date.getMinutes()],
					hour: [date.getHours()],
					dayOfTheMonth: [date.getDate()],
					month: [date.getMonth() + 1],
					year: [date.getFullYear()]
				};
			});
		},
		summaryText: (translate) => translate('Common_OneTime'),
		toHumanReadableFormat: (cronObjectEntries, translate) => {
			let dates = cronObjectEntries.map((cronExp) => {
				let date = new Date();

				date.setFullYear(cronExp.year[0]);
				date.setMonth(cronExp.month[0] - 1);
				date.setDate(cronExp.dayOfTheMonth[0]);
				date.setHours(cronExp.hour[0]);
				date.setMinutes(cronExp.minute[0]);
				date.setSeconds(0);

				return `${formatDateTime(date, 'date')} ${translate('Common_At')} ${formatDateTime(date, 'time')}`;
			});

			dates.sort();
			return `${dates.join(', ')}`;
		}
	},
	everyHour: {
		type: 'everyHour',
		icon: EveryHourIcon,
		label: 'Common_EveryHour',
		control: EveryHourControls,
		updateCronData: (defaultCorn, data) => {
			if (!data) return;

			return [
				{
					...defaultCorn,
					minute: data.map((time) => time.getMinutes())
				}
			];
		},
		summaryText: (translate) => translate('Common_EveryHour'),
		toHumanReadableFormat: (cronObjectEntries, translate) => {
			let times = cronObjectEntries.map((cronExp) => cronExp.minute[0] + '');

			times.sort();
			return `${times.join(', ')} ${translate('Common_MinutesAfterFullHour')}`;
		}
	},
	everyDay: {
		type: 'everyDay',
		icon: EveryDayIcon,
		label: 'Common_EveryDay',
		control: EveryDayControls,
		updateCronData: (defaultCorn, data) => {
			if (!data) return;

			return data.map((time) => {
				return {
					...defaultCorn,
					minute: [time.getMinutes()],
					hour: [time.getHours()]
				};
			});
		},
		summaryText: (translate) => translate('Common_EveryDay'),
		toHumanReadableFormat: (cronObjectEntries, translate) => {
			let times = cronObjectEntries.map(
				(cronExp) => (cronExp.hour[0] + '').padStart(2, '0') + ':' + (cronExp.minute[0] + '').padStart(2, '0')
			);

			times.sort();
			return `${translate('Common_At').toUpperCase()} ${times.join(', ')}`;
		}
	},
	everyWeek: {
		type: 'everyWeek',
		icon: EveryWeekIcon,
		label: 'Common_EveryWeek',
		control: EveryWeekControls,
		updateCronData: (defaultCorn, data) => {
			if (!data) return;

			const times = data.times;
			const days = data.days;

			return times.map((time) => {
				return {
					...defaultCorn,
					minute: [time.getMinutes()],
					hour: [time.getHours()],
					dayOfTheWeek: days
				};
			});
		},
		summaryText: (translate) => translate('Common_AtSelectedDaysOfTheWeek'),
		toHumanReadableFormat: (cronObjectEntries, translate) => {
			if (cronObjectEntries.length == 0) return '';

			const daysOfWeekNames = LanguageHelper.getDayNames();
			const daysOfWeek = [...cronObjectEntries[0].dayOfTheWeek];

			daysOfWeek.sort((a, b) => {
				const a1 = (Number.parseInt(a) + 6) % 7;
				const b1 = (Number.parseInt(b) + 6) % 7;
				if (a1 > b1) return 1;
				if (a1 < b1) return -1;
				return 0;
			});

			const days = daysOfWeek.map((day) => {
				const parsedDay = Number.parseInt(day);

				if (Number.isNaN(parsedDay)) return day;

				return daysOfWeekNames[parsedDay === 0 ? 6 : parsedDay - 1];
			});

			let times = cronObjectEntries.map(
				(cronExp) => (cronExp.hour[0] + '').padStart(2, '0') + ':' + (cronExp.minute[0] + '').padStart(2, '0')
			);

			times.sort();

			return `${days.join(', ').capitalize()} ${translate('Common_At')} ${times.join(', ')}`;
		}
	},
	everyMonth: {
		type: 'everyMonth',
		icon: EveryMonthIcon,
		label: 'Common_EveryMonth',
		control: EveryMonthControls,
		updateCronData: (defaultCorn, data) => {
			if (!data) return;

			const times = data.times;
			const days = data.days.filter((d) => d != cronLastDayOfMonth);

			const isLastDayOfMonthSet = days.length != data.days.length;

			let newData =
				days.length > 0
					? times.map((time) => {
							return {
								...defaultCorn,
								minute: [time.getMinutes()],
								hour: [time.getHours()],
								dayOfTheMonth: days
							};
					  })
					: [];

			if (isLastDayOfMonthSet) {
				const lastDayOfMont = times.map((time) => {
					return {
						...defaultCorn,
						minute: [time.getMinutes()],
						hour: [time.getHours()],
						dayOfTheMonth: [cronLastDayOfMonth]
					};
				});
				newData = newData.concat(lastDayOfMont);
			}

			return newData;
		},
		summaryText: (translate) => translate('Common_AtSelectedDaysOfTheMonth'),
		toHumanReadableFormat: (cronObjectEntries, translate) => {
			let dayOfTheMonth = cronObjectEntries
				.map((cron) => cron.dayOfTheMonth)
				.reduce((previousValue, currentValue) => {
					return [...previousValue, ...currentValue];
				})
				.filter((item, pos, arr) => arr.indexOf(item) === pos);

			dayOfTheMonth.sort(dayOfMonthComparer);

			let times = cronObjectEntries.map(
				(cronExp) => (cronExp.hour[0] + '').padStart(2, '0') + ':' + (cronExp.minute[0] + '').padStart(2, '0')
			);

			times.sort();

			let daysText = dayOfTheMonth.map((day) => dayOfTheMonthToString(day, translate)).join('., ');
			if (dayOfTheMonth[dayOfTheMonth.length - 1] !== 'L') daysText += '.';

			const timesText = times.filter((item, pos, arr) => arr.indexOf(item) === pos).join(', ');

			return `${daysText.capitalize()} ${translate('Common_At')} ${timesText}`;
		}
	}
};

export const getScheduleTypeDefinition = (name) => {
	const result = types[name];

	if (!result) throw new Error(`Schedule type ${name} not found`);
	else return result;
};
