import React from 'react';
import { DashboardContentContextProvider } from './dashboardContentContext';
import DashboardContent from './DashboardContent';

const DashboardContentWrapper = (props) => {
	return (
		<DashboardContentContextProvider>
			<DashboardContent {...props} />
		</DashboardContentContextProvider>
	);
};

export default DashboardContentWrapper;
