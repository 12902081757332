import { useLayoutEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { useLocale } from '@sonar-web/common/src/hooks';

const Wrapper = styled('div')({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	backgroundColor: '#fff'
});

const Border = styled('div')({
	width: 17,
	height: '100%',
	border: `1px solid #CACACA`,
	borderRight: 'none',
	borderRadius: '8px 0px 0px 8px',
	margin: '0 10px 0 10px'
});

const FloorIndicator = ({ floor }) => {
	const ref = useRef(null);
	const { translate } = useLocale();

	useLayoutEffect(() => {
		const floorEl = document.querySelector(`.floor_${floor}`);
		const thisEl = ref.current;

		const resizeObserver = createObserver(thisEl);

		if (!floorEl) return;

		resizeObserver.observe(floorEl);
	}, []);

	return (
		<Wrapper className='floor-indicator' ref={ref}>
			<Typography variant='caption' color='textSecondary'>
				{`${translate('Stats_Floor')} ${floor}`}
			</Typography>
			<Border />
		</Wrapper>
	);
};

export default FloorIndicator;

function createObserver(thisEl) {
	return new ResizeObserver((entries) => {
		for (let entry of entries) {
			thisEl.style.height = entry.contentRect.height + 4 + 'px';
		}
	});
}
