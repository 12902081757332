import Point from './Point';
import styled from '@emotion/styled';

const Wrapper = styled('div')({
	display: 'flex',
	flexDirection: 1,
	flex: 1,
	flexWrap: 'wrap-reverse',
	alignContent: 'flex-start',
	columnGap: 4
});

const Points = ({ points }) => {
	return (
		<Wrapper>
			{points.map((p) => {
				return <Point key={p.id} {...p} />;
			})}
		</Wrapper>
	);
};

export default Points;
