import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { FormControl, Slider, TextField, Typography } from '@mui/material';
import ConfiguratorControlLabel from './ConfiguratorControlLabel';
import { useDebounce, useLocale } from '../../../../hooks';

const StyledSlider = styled(Slider)(() => ({
	marginTop: '15px'
}));

const StyledTextField = styled(TextField)({
	marginLeft: '1rem',
	'& input': {
		width: '80px',
		textAlign: 'center',
		paddingLeft: '1rem'
	}
});

const StyledFormControl = styled(FormControl)({
	marginLeft: '0.5rem',
	marginTop: 0,
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'flex-start'
});

const keywords = ['mb']; //mb - marginBottom

const ConfiguratorSlider = ({ name, value, form, element }) => {
	const { translate } = useLocale();
	const { min, max, step, description } = element;
	const [sliderValue, setSliderValue] = useState(value);
	const debouncedSliderValue = useDebounce(sliderValue);

	const descriptionInKeywords = description && keywords.includes(description);

	const handleSliderChange = (_, newValue) => {
		setSliderValue(newValue);
	};

	const handleInputChange = (event) => {
		const newValue = event.target.value === '' ? '' : Number(event.target.value);
		setSliderValue(newValue);
	};

	const handleInputBlur = () => {
		if (value < min) setSliderValue(min);
		else if (value > max) setSliderValue(max);
	};

	useEffect(() => {
		if (value === debouncedSliderValue) return;

		form.setFieldValue(name, debouncedSliderValue);
	}, [debouncedSliderValue]);

	return (
		<>
			{description && !descriptionInKeywords && (
				<Typography variant='body2' style={{ marginBottom: 20 }}>
					{translate(description)}
				</Typography>
			)}
			<ConfiguratorControlLabel label={name} />
			<StyledFormControl
				fullWidth
				margin='normal'
				sx={{
					marginBottom: descriptionInKeywords && description === 'mb' ? '3rem' : '0.5rem'
				}}>
				<StyledSlider
					aria-labelledby={`${name}_slider`}
					valueLabelDisplay='auto'
					value={Number(sliderValue)}
					step={step ?? 1}
					min={min}
					max={max}
					onChange={handleSliderChange}
				/>
				<StyledTextField
					value={typeof sliderValue === 'number' ? sliderValue : min}
					onChange={handleInputChange}
					onBlur={handleInputBlur}
					inputProps={{
						step: step,
						min: min,
						max: max,
						type: 'number'
					}}
				/>
			</StyledFormControl>
			{element.name && (
				<Typography variant='caption' color='textSecondary'>
					{translate(element.name)}
				</Typography>
			)}
		</>
	);
};

export default ConfiguratorSlider;
