import { useEffect, useState } from 'react';

export default function useCrossTabState(stateKey, onChange) {
	const [state, setState] = useState(null);

	useEffect(() => {
		const onReceieveMessage = ({ key, newValue }) => {
			if (key !== stateKey) return;

			if (onChange) onChange(newValue);
		};

		window.addEventListener('storage', onReceieveMessage);

		return () => window.removeEventListener('storage', onReceieveMessage);
	}, [stateKey, setState]);

	return [state, setState];
}
