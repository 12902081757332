import { createSelector, createSlice } from '@reduxjs/toolkit';
import { pageDescriptor } from '@sonar-web/common';
import { fetchLocationNodeLevels, fetchLocationNodeLevelsTree } from './api';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';

const slice = 'locationNodeLevels';

const generateAvailableLevels = (tree) => {
	const levels = [{ ...tree, leaves: [] }];
	const levelsMap = { root: 0 };

	function traverseTree(node) {
		const nodeId = node.id;

		if (levelsMap[nodeId] == null) {
			levelsMap[nodeId] = Object.keys(levelsMap).length;
			levels.push({ ...node, leaves: [] });
		}

		for (const l in levelsMap) {
			const levelIndex = levelsMap[l];
			if (l !== nodeId) levels[levelIndex].leaves.push(node);
		}

		if (node.leaves && node.leaves.length) traverseTree(node.leaves[0]);
	}

	traverseTree(tree);

	return { levels, levelsMap };
};

export const locationNodeLevelsSlice = createSlice({
	name: slice,
	initialState: {
		pageDescriptor,
		pending: false,
		fetchLocationNodeLevelsSuccess: false,
		fetchLocationNodeLevelsTreeSuccess: false,
		locationNodeLevels: [],
		availableLevelsArray: [],
		availableLevelsMap: {},
		availableLocationNodeLevels: [],
		selected: null,
		locationNodeLevelsEmpty: false
	},
	reducers: {
		fetchLocationNodeLevelsSucces: (state, action) => {
			state.locationNodeLevels = action.payload.elements;
			state.pending = false;
			state.fetchLocationNodeLevelsSuccess = true;
		},
		fetchLocationNodeLevelsPending: (state, action) => {
			state.pending = action.payload ?? true;
		},
		fetchLocationNodeLevelsTreeSucces: (state, action) => {
			state.locationNodeLevelsTree = action.payload;
			state.fetchLocationNodeLevelsTreeSuccess = true;

			const { levels, levelsMap } = generateAvailableLevels(action.payload);
			state.availableLevelsMap = levelsMap;
			state.availableLevelsArray = levels;
		},
		setSelected: (state, action) => {
			state.selected = action.payload;
		},
		setAvailableLocationNodeLevels: (state, action) => {
			const nodeLevel = action.payload;
			const nodeLevelId = nodeLevel.locationNodeLevelId;
			const levelMapIndex = state.availableLevelsMap[nodeLevelId] ?? 0;

			state.availableLocationNodeLevels = state.availableLevelsArray[levelMapIndex].leaves;
		},
		resetLocationNodesLevels: (state) => {
			state.availableLocationNodeLevels = [];
		},
		setEmptyLevels: (state) => {
			state.locationNodeLevelsEmpty = true;
		}
	}
});

export const {
	fetchLocationNodeLevelsSucces,
	fetchLocationNodeLevelsPending,
	setSelected,
	setAvailableLocationNodeLevels,
	fetchLocationNodeLevelsTreeSucces,
	resetLocationNodesLevels,
	setEmptyLevels
} = locationNodeLevelsSlice.actions;

export const fetchLocationNodeLevelsAsync = () => async (dispatch, getState) => {
	try {
		const pd = {
			...getState().locationNodeLevels.pageDescriptor,
			SortDescriptors: [{ Member: 'LevelNo', ListSortDirection: 'Asc' }]
		};
		dispatch(fetchLocationNodeLevelsPending());

		const response = await fetchLocationNodeLevels(pd);
		dispatch(fetchLocationNodeLevelsSucces(response));
	} catch (error) {
		dispatch(fetchLocationNodeLevelsPending(false));
		dispatch(addErrorAsync({ slice, error }));
		return;
	}
};

export const fetchLocationNodeLevelsTreeAsync = () => async (dispatch, getState) => {
	let response;
	const levels = getState().locationNodeLevels.locationNodeLevels;
	const topLevel = levels && levels.length > 0 ? levels[0].id : null;

	if (!topLevel) {
		dispatch(setEmptyLevels());
		return;
	}

	try {
		response = await fetchLocationNodeLevelsTree(topLevel);
	} catch (error) {
		dispatch(addErrorAsync({ slice, error }));
		return;
	}

	dispatch(fetchLocationNodeLevelsTreeSucces(response));
};

export const selectLocationNodeLevels = (state) => state.locationNodeLevels.locationNodeLevels;
export const selectFetchLocationNodeLevelsSuccess = (state) => state.locationNodeLevels.fetchLocationNodeLevelsSuccess;
export const selectFetchLocationNodeLevelsTreeSucces = (state) =>
	state.locationNodeLevels.fetchLocationNodeLevelsTreeSuccess;
export const selectSelected = (state) => state.locationNodeLevels.selected;
export const selectAvailableLocationNodeLevels = (state) => state.locationNodeLevels.availableLocationNodeLevels;

export const selectLevelsData = createSelector(
	(state) => state.locationNodeLevels,
	(levels) => ({
		levels: levels.availableLevelsArray,
		map: levels.availableLevelsMap
	})
);

export const selectLocationLevels = createSelector(
	(state) => state.locationNodeLevels,
	(levels) => ({
		elements: levels.locationNodeLevels,
		fetchPending: levels.pending,
		fetchSuccess: levels.fetchLocationNodeLevelsSuccess
	})
);

export default locationNodeLevelsSlice.reducer;
