import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useDropzone } from 'react-dropzone';
import FileInput from './FileInput';
import FileSelected from './FileSelected';
import { Collapse, Stack, Typography, collapseClasses } from '@mui/material';
import { useLocale } from '../../hooks';
import { filesizeConvert } from '../../utils';
import FileUploadError from './FileUploadError';
import FileUploadEvents from './FileUploadEvents';
import DataExchangeIcon from '../../icons/DataExchangeIcon';

const initialValues = {
	size: true,
	extension: true,
	file: null
};

const Wrapper = styled('div', {
	shouldForwardProp: (prop) => prop !== 'isValid' && prop !== 'isDragActive'
})(({ theme, isValid, isDragActive }) => ({
	position: 'relative',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: 5,
	padding: '2rem',
	height: '100%',
	border: isValid ? '1px solid #939393' : '1px dashed #939393',
	backgroundColor: isDragActive ? theme.palette.grey[50] : 'none',
	transition: 'background-color 0.2s ease-in-out'
}));

const StyledCollapse = styled(Collapse)({
	[`& .${collapseClasses.wrapper}`]: {
		height: '100%'
	}
});

const FileUpload = ({
	data,
	onAccept,
	onReject,
	onReset,
	extensions,
	extensionsDescription,
	maxSize,
	isReadOnly = false,
	collapseOnAccept = true,
	flex = false,
	children
}) => {
	const { translate } = useLocale();
	const [values, setValues] = useState(
		data?.file
			? {
					size: true,
					extension: true,
					file: new File([data.file], data.fileName, { lastModified: data.lastModified })
				}
			: initialValues
	);

	const isValid = values.size && values.extension && values.file !== null;

	const resetValues = () => {
		setValues(initialValues);
		if (onReset) onReset();
	};

	const handleDropRejected = (files) => {
		const file = files[0];
		let errors = { size: true, extension: true };

		file.errors.forEach((err) => {
			if (err.code === 'file-invalid-type') errors.extension = false;
			if (err.code === 'file-too-large') errors.size = false;
		});

		setValues({ ...values, ...errors });
		if (onReject) onReject(file);
	};

	const handleDropAccepted = (files) => {
		setValues({
			size: true,
			extension: true,
			file: files[0]
		});

		if (onAccept) onAccept(files[0]);
	};

	const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
		noClick: true,
		noKeyboard: true,
		disabled: isValid,
		accept: extensions,
		maxSize: maxSize,
		onDropAccepted: handleDropAccepted,
		onDropRejected: handleDropRejected
	});

	useEffect(() => {
		if (!data.file && values.file) setValues(initialValues);
	}, [data?.fileName]);

	useEffect(() => {
		const event = FileUploadEvents.UploadApiError.name;

		document.addEventListener(event, resetValues);

		return () => {
			document.removeEventListener(event, resetValues);
		};
	}, []);

	return (
		<>
			{collapseOnAccept && (
				<Collapse in={isValid || isReadOnly}>
					<FileSelected values={values} resetValues={resetValues} isReadOnly={isReadOnly} />
				</Collapse>
			)}
			<StyledCollapse in={collapseOnAccept ? !isValid : true} sx={{ flex: flex ? 1 : 'none' }}>
				<Wrapper isValid={isValid} isDragActive={isDragActive} {...getRootProps()}>
					<Stack width='100%' flex={1} alignItems='center' justifyContent='center'>
						<DataExchangeIcon fontSize='l' color='action' />
						<Typography variant='body2' color='textSecondary' align='center'>
							<br />
							{translate('DragAndDropFilesHere')}
							<br />
							<br />
							{translate('Or_Albo')}
						</Typography>
						<FileInput
							values={values}
							setValues={setValues}
							isValid={isValid}
							inputProps={getInputProps}
							open={open}
						/>
						<Typography variant='body2' color='textSecondary' align='center'>
							{translate(
								'AcceptedFileExtensions',
								extensionsDescription
									? translate(extensionsDescription)
									: Object.values(extensions).join(', ')
							)}
							<br />
							{translate('MaximumFileSize', filesizeConvert.bytesToMegabytes(maxSize))}
						</Typography>
						{typeof children === 'function' ? children({ resetValues, isValid }) : children}
						<FileUploadError values={values} />
					</Stack>
				</Wrapper>
			</StyledCollapse>
		</>
	);
};

export default FileUpload;
