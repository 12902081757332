import React from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import moment from 'moment';
import { Badge, IconButton, InputAdornment, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CloseIcon from '@mui/icons-material/Close';
import EventIcon from '@mui/icons-material/Event';
import { selectLocale } from '../../features/Locales/localesSlice';
import { useLocale } from '../../hooks';

const useStyles = makeStyles((theme) => ({
	adornmentIcon: {
		padding: '2px',
		marginRight: '-2px'
	},
	inputRoot: {
		fontSize: theme.typography.body2.fontSize,
		width: (props) => (props.inputWidth ? props.inputWidth : 150)
	},
	inputAdornment: {
		'& button': {
			padding: 3,
			marginRight: -4,
			marginBottom: -2
		},
		'& svg': {
			fontSize: '1.2rem'
		}
	},
	badgeRoot: {
		top: -5,
		right: 1,
		backgroundColor: theme.palette.pastel.orange.main,
		padding: 4,
		border: `3px solid ${theme.palette.common.white}`,
		borderRadius: '50%'
	},
	badgeRootHidden: {
		display: 'none'
	}
}));

const BaseDateTimePicker = ({
	label,
	value,
	onChange,
	onClear,
	name,
	error,
	helperText,
	FormHelperTextProps,
	highlight,
	fullWidth,
	inputWidth,
	mask = '__.__.__ __:__',
	inputFormat = `${moment.localeData().longDateFormat('L')} ${moment.localeData().longDateFormat('LT')}`,
	textFieldProps = {},
	handleOnChange = false,
	...rest
}) => {
	const classes = useStyles({ inputWidth });
	const locale = useSelector(selectLocale);
	const { translate } = useLocale();

	const handleChange = (v) => {
		const { disableFuture, minDate } = { ...rest };
		let invalidFuture = false;
		let invalidPast = false;

		if (disableFuture && v) invalidFuture = v.isAfter(moment(), 'minute');
		if (minDate && v) invalidPast = v.isBefore(moment(minDate), 'minute');

		if (invalidFuture) onChange(moment());
		else if (invalidPast) onChange(moment(minDate));
		else onChange(v);
	};

	return (
		<LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
			<DateTimePicker
				disableMaskedInput
				name={name}
				value={value}
				mask={mask}
				inputFormat={inputFormat}
				label={translate(label)}
				onChange={handleOnChange ? onChange : handleChange}
				renderInput={(props) => (
					<TextField
						name={name}
						{...props}
						fullWidth={fullWidth}
						classes={{ root: clsx({ [classes.inputRoot]: !fullWidth }) }}
						error={error}
						helperText={translate(helperText)}
						{...textFieldProps}
						slotProps={{
							formHelperText: FormHelperTextProps
						}}
					/>
				)}
				InputAdornmentProps={{
					classes: {
						root: classes.inputAdornment
					}
				}}
				InputProps={{
					classes: { root: clsx({ [classes.inputRoot]: !fullWidth }) },
					endAdornment:
						value && onClear ? (
							<InputAdornment position='end'>
								<IconButton
									size='small'
									aria-label='filter_date_clear'
									classes={{ root: classes.adornmentIcon }}
									onClick={(e) => {
										e.stopPropagation();
										onClear();
									}}>
									<CloseIcon fontSize='small' />
								</IconButton>
							</InputAdornment>
						) : (
							<Badge
								variant='dot'
								color='primary'
								classes={{
									dot: clsx({ [classes.badgeRoot]: highlight, [classes.badgeRootHidden]: !highlight })
								}}>
								<InputAdornment position='end'>
									<IconButton
										size='small'
										aria-label='filter_date_select'
										classes={{ root: classes.adornmentIcon }}>
										<EventIcon fontSize='small' />
									</IconButton>
								</InputAdornment>
							</Badge>
						)
				}}
				{...rest}
			/>
		</LocalizationProvider>
	);
};

export default BaseDateTimePicker;
