/* eslint-disable react/display-name */
import { useEffect, useState, forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import { Virtuoso } from 'react-virtuoso';
import ConverterDiagnosticListItem from './ConverterDiagnosticListItem';
import {
	ListActionToolbar,
	BaseLinearProgress,
	listScrollToElement,
	BaseCircularProgress,
	PanelList,
	filterOperators
} from '@sonar-web/common';
import { useFilterDescriptor } from '@sonar-web/common/src/hooks';
import { converterDiagnosticSorters } from './converterDiagnosticSorters';
import { fetchThunk, setOffset } from '@sonar-web/common/src/features/List/genericListSlice';
import { fetchDiagnosticConverterReads } from '../converterDiagnosticApi';
import useList from '@sonar-web/common/src/features/List/useList';
import useListSorting from '@sonar-web/common/src/components/Sorters/useListSorting';
import ListEmptyPlaceholder from '@sonar-web/common/src/components/NoDataPlaceholders/ListEmptyPlaceholder';

const slice = 'converterDiagnostic';

const ConverterDiagnosticList = ({ deviceId }) => {
	const dispatch = useDispatch();
	const { listSelector } = useList(slice);
	const { createFilter } = useFilterDescriptor();

	const [isReloading, setIsReloading] = useState(true);

	const { elements, hasMore, pending, reset, totalCount } = listSelector;
	const elementsCount = elements?.length;

	const fetchData = (append = false) => {
		if (!append) listScrollToElement(0, 'ConverterDiagnosticList');
		else if (append && !hasMore) return;

		const defaultFilters = createFilter([
			{
				member: { name: 'converterId', type: 'guid' },
				value: deviceId,
				operator: filterOperators.equal
			}
		]);

		dispatch(setOffset(slice)(append));
		setIsReloading(!append);
		dispatch(
			fetchThunk('converterDiagnostic')({ endpoint: fetchDiagnosticConverterReads, payload: { defaultFilters } })
		);
	};

	useListSorting({ slice, callback: fetchData });

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		if (reset) fetchData();
	}, [reset]);

	if (!elements) return null;
	return (
		<>
			<ListActionToolbar
				slice={slice}
				pending={pending}
				sorters={converterDiagnosticSorters}
				totalCount={totalCount}
			/>
			<BaseLinearProgress pending={pending && isReloading} />
			<Virtuoso
				style={{ minHeight: 225 }}
				data={elements}
				components={{ List, EmptyPlaceholder }}
				itemContent={(index) => <ConverterDiagnosticListItem row={elements[index]} />}
				totalCount={elementsCount}
				endReached={() => fetchData(true)}
			/>
			<BaseCircularProgress pending={pending && !isReloading} paddingTop={3} />
		</>
	);
};

export default ConverterDiagnosticList;

const List = forwardRef(({ style, children }, listRef) => {
	return (
		<PanelList id='ConverterDiagnosticList' ref={listRef} style={style} disablePadding>
			{children}
		</PanelList>
	);
});

const EmptyPlaceholder = () => <ListEmptyPlaceholder slice={slice} text='Stationary_ConverterDiagnosticEmpty' />;
