export const SLICES = {
	CUSTOMERS: 'customers'
};

export const POINT_CUSTOMERS_VIEW = {
	MAIN: 'main',
	RESIDENT_ASSIGN: 'resident-assign',
	RESIDENT_ASSIGN_EDIT: 'resident-assign_edit',
	RESIDENT_ASSIGN_CLOSE: 'resident-assign_close',
	OWNER_ASSIGN: 'owner-assign',
	OWNER_ASSIGN_EDIT: 'owner-assign_edit',
	OWNER_ASSIGN_CLOSE: 'owner-assign_close',
	OWNER_EDIT: 'owner-edit'
};
