import { ListFilters, FiltersControls } from '@sonar-web/common';
import { slices } from '../Constants/Module';
import ReadType from '../Enums/ReadType';

const slice = slices.watermeterReads;

const WatermeterReadsLocationFilters = () => {
	return (
		<ListFilters
			slice={slice}
			controls={[
				{
					control: FiltersControls.date,
					member: { name: 'date', type: 'datetime' },
					label: 'Common_MeasurementDateFrom',
					type: 'ge',
					withPredefined: true
				},
				{
					control: FiltersControls.date,
					member: { name: 'date', type: 'datetime' },
					label: 'Common_MeasurementDateTo',
					type: 'le'
				},
				{
					control: FiltersControls.textSingle,
					member: { name: 'watermeterNumber', type: 'string' },
					label: 'Watermeters_SerialNumber'
				},
				{
					control: FiltersControls.comboSingle,
					member: { name: 'type', type: 'int' },
					label: 'Watermeters_ReadType',
					options: [
						{
							text: 'Watermeters_ReadType_Instant',
							value: ReadType.Instant.value
						},
						{
							text: 'Watermeters_ReadType_Logged',
							value: ReadType.Logged.value
						}
					]
				}
			]}
		/>
	);
};

export default WatermeterReadsLocationFilters;
