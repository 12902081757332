import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import Day from './Day';
import DayNames from './DayNames';

const MonthWrapper = styled('div')({
	width: 147,
	marginTop: 24,
	marginBottom: 8
});

const DaysWrapper = styled('div')({
	display: 'flex',
	flexWrap: 'wrap'
});

const Month = ({ name, days, formValues }) => {
	return (
		<MonthWrapper>
			<Typography variant='body2' textTransform='capitalize'>
				{name}
			</Typography>
			<DayNames />
			<DaysWrapper>
				{days.map((day, index) => (
					<Day key={index} {...day} formValues={formValues} />
				))}
			</DaysWrapper>
		</MonthWrapper>
	);
};

export default Month;
