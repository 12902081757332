import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProfileCard from './ProfileCard';
import ProfileCardSkeleton from './ProfileCardSkeleton';
import ComponentNoData from '../../../../components/NoDataPlaceholders/ComponentNoData';
import { CollapsibleComponent } from '@sonar-web/common';
import { selectProfiles, setSelected, fetchProfileAsync, selectProfileSingle } from './deviceConfigProfilesSlice';
import { useTheme } from '@mui/material';
import CardsGrid from '../../../Cards/CardsGrid';
import ProfilesContext from './profilesContext';

const ProfilesGrid = ({ records, currentProfileId, textNoData }) => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const { pending } = useSelector(selectProfiles);
	const { pendingSingle } = useSelector(selectProfileSingle);
	const { api, handleShowProfile, handleSelectProfile } = useContext(ProfilesContext);

	useEffect(() => {
		let currentProfile = null;

		if (currentProfileId) {
			const selectedRecord = records.filter((item) => item.id === currentProfileId);
			if (selectedRecord.length) currentProfile = selectedRecord[0];
		}

		dispatch(setSelected(currentProfile));
	}, [currentProfileId]);

	const handleSelect = (e, record) => {
		if (handleSelectProfile) {
			handleSelectProfile(record);
			dispatch(setSelected(record));
			return;
		}

		if (handleShowProfile && !pendingSingle) {
			dispatch(fetchProfileAsync({ api: api.fetchSingle, profileId: record.id })).then((profile) => {
				dispatch(setSelected(record));
				handleShowProfile(profile);
			});
		}
	};

	const gridPadding = `${theme.spacing(2)} 0`;

	if (pending) {
		return (
			<CardsGrid
				records={[1, 2, 3, 4]}
				renderCard={(record, index) => {
					return <ProfileCardSkeleton key={index} />;
				}}
				gridPadding={gridPadding}
			/>
		);
	}

	if (!records || (records && records.length === 0)) {
		return <ComponentNoData show={true} text={textNoData} />;
	}

	const renderCard = (record, index) => (
		<ProfileCard current={currentProfileId === record.id} record={record} key={index} onClick={handleSelect} />
	);

	return records.length > 4 ? (
		<CollapsibleComponent
			labelShow='Common_ShowAll'
			labelHide='Common_HideAll'
			heightCollapsed='270px'
			renderContent={() => <CardsGrid records={records} renderCard={renderCard} gridPadding={gridPadding} />}
		/>
	) : (
		<CardsGrid records={records} renderCard={renderCard} gridPadding={gridPadding} />
	);
};

export default ProfilesGrid;
