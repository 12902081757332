import React from 'react';
import { Box } from '@mui/material';
import ScheduleSettings from './ScheduleSettings';
import ScheduleTypes from './ScheduleTypes';

const ScheduleForm = ({ typesSettings }) => {
	return (
		<Box overflow='auto' paddingRight={1} className='scheduleForm'>
			<ScheduleTypes typesSettings={typesSettings} />
			<ScheduleSettings />
		</Box>
	);
};

export default ScheduleForm;
