import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { useLocale } from '../../hooks';

const useStyles = makeStyles((theme) => ({
	wrapper: {
		position: 'relative'
	},
	buttonProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12
	}
}));

const SubmitButtonProgress = ({ pending, text, ...rest }) => {
	const classes = useStyles();
	const { translate } = useLocale();

	return (
		<div className={classes.wrapper}>
			<Button
				type='submit'
				size='small'
				variant='contained'
				color='primary'
				disabled={pending}
				{...rest}>
				{translate(text ?? 'Common_Confirm')}
			</Button>
			{pending && <CircularProgress size={24} className={classes.buttonProgress} />}
		</div>
	);
};

export default SubmitButtonProgress;
