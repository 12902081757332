import React, { useState, useEffect } from 'react';
import { FormControl, FormControlLabel, Switch } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '../../../../hooks';
import ConfiguratorLabel from './ConfiguratorLabel';

const useStyles = makeStyles(() => ({
	formControlLabelRoot: {
		marginLeft: -6,
		alignSelf: 'flex-start'
	}
}));

const ConfiguratorTogglefield = ({ name, value, element, form, group, forceUpdateValidationSchema }) => {
	const classes = useStyles();
	const { translate } = useLocale();

	const { option, description, defaultValue, dependsOn } = element;
	const isDependent = dependsOn !== null;
	const dependencyValue = isDependent ? dependsOn.value == form.values[dependsOn.key] : false;

	const [dependencyVisibility, setDependencyVisibility] = useState(dependencyValue);
	const switchValue = Boolean(Number(value ?? defaultValue));
	const label = switchValue ? option.onLabel : option.offLabel;

	const handleOnChange = (_, v) => {
		form.setFieldValue(name, Number(v));
	};

	useEffect(() => {
		if (!dependsOn) return;

		setDependencyVisibility(dependencyValue);
		if (group.included) forceUpdateValidationSchema(form.values);
	}, [form.values[dependsOn?.key]]);

	useEffect(() => {
		if (!dependsOn || dependencyVisibility) return;
		form.setFieldValue(name, defaultValue);
	}, [dependencyVisibility]);

	if (dependsOn && !dependencyVisibility) return null;
	return (
		<FormControl fullWidth margin='normal'>
			{description && <ConfiguratorLabel label={name} tooltip={description === 'null' ? null : description} />}
			<FormControlLabel
				name={name}
				label={label ? translate(label) : ''}
				classes={{ root: classes.formControlLabelRoot }}
				control={<Switch size='small' color='primary' checked={switchValue} onChange={handleOnChange} />}
			/>
		</FormControl>
	);
};

export default ConfiguratorTogglefield;
