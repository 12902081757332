import { apiBase } from '@sonar-web/common';
import { REST_API_PATHS } from '@sonar-web/common/src/constants/system';

const path = REST_API_PATHS.ACCESS;

export async function fetchUser(id, clientId) {
	const { data } = await apiBase(`clients/${clientId}/users/${id}`, 'get', {
		path
	});

	return data;
}

export async function addUser(user) {
	const { data } = await apiBase(`users`, 'post', {
		path,
		data: user
	});

	return data;
}

export async function editUser(user) {
	const data = await apiBase(`users/${user.id}`, 'put', {
		path,
		data: user
	});

	return data;
}

export async function deleteUser(id) {
	const data = await apiBase(`users/${id}`, 'delete', {
		path
	});

	return data;
}
