import React, { useState } from 'react';
import { FormControl, FormControlLabel, Switch } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '../../../../hooks';

const useStyles = makeStyles(() => ({
	formControlLabelRoot: {
		marginLeft: -6
	}
}));

const ConfiguratorMonitoringTogglefield = ({ name, value, element, form }) => {
	const classes = useStyles();
	const { translate } = useLocale();
	const { option } = element;
	const [switchValue, setSwitchValue] = useState(Boolean(Number(value)));
	const label = switchValue ? option.onLabel : option.offLabel;

	const handleOnChange = (e, v) => {
		setSwitchValue(v);
		form.setFieldValue(name, v ? '1' : '0');
	};

	return (
		<FormControl fullWidth margin='normal'>
			<FormControlLabel
				name={name}
				label={translate(label)}
				classes={{ root: classes.formControlLabelRoot }}
				control={<Switch size='small' color='primary' checked={switchValue} onChange={handleOnChange} />}
			/>
		</FormControl>
	);
};

export default ConfiguratorMonitoringTogglefield;
