import React from 'react';
import { BaseIcon } from '@sonar-web/common';

export default function EmptyStateImportIcon(props) {
	return (
		<BaseIcon {...props}>
			<path d='M 11.999997,2.8307352 7.4713515,7.070317 a 0.46667001,0.46667001 0 0 0 -0.02066,0.6588573 0.46667001,0.46667001 0 0 0 0.658852,0.023685 L 11.534027,4.5471296 V 15.169522 a 0.46666447,0.46666447 0 0 0 0.466141,0.466142 0.46666447,0.46666447 0 0 0 0.466142,-0.466142 V 4.5471296 l 3.424479,3.2057247 A 0.46667001,0.46667001 0 0 0 16.549641,7.7291693 0.46667001,0.46667001 0 0 0 16.528981,7.070317 Z M 2.8749975,14.067714 a 0.46666648,0.46666648 0 0 0 -0.466142,0.466142 v 2.294269 3.874999 a 0.46671315,0.46671315 0 0 0 0.466142,0.466141 H 21.125001 a 0.46671315,0.46671315 0 0 0 0.466142,-0.466141 v -3.872393 a 0.46666648,0.46666648 0 0 0 0,-0.0027 v -0.29682 -1.997395 a 0.46666648,0.46666648 0 0 0 -0.466142,-0.466142 0.46666648,0.46666648 0 0 0 -0.466142,0.466142 v 2.294274 3.408852 H 3.3411495 V 16.83069 a 0.46666648,0.46666648 0 0 0 0,-0.0027 v -0.296819 -1.997394 a 0.46666648,0.46666648 0 0 0 -0.466142,-0.466142 z' />
		</BaseIcon>
	);
}
