export const ReadFilterType = {
	None: {
		value: 1,
		name: 'None',
		description: 'LocationReadsNone'
	},
	AllRead: {
		value: 2,
		name: 'AllRead',
		description: 'LocationReadsAllRead'
	},
	PartlyRead: {
		value: 3,
		name: 'PartlyRead',
		description: 'LocationReadsPartlyRead'
	},
	Unread: {
		value: 4,
		name: 'Unread',
		description: 'LocationReadsUnread'
	}
};
