export const filterOperators = {
	lt: 0,
	le: 1,
	equal: 2,
	notEqual: 3,
	ge: 4,
	gt: 5,
	endsWith: 7,
	like: 8,
	notContains: 9,
	isNull: 12,
	isNotNull: 13,
	in: 16,
	notIn: 17,
	collectionContain: 18,
	collectionNotContain: 19
};

export const filterOperatorSign = (operator) => {
	switch (operator) {
		case filterOperators.like:
			return 'Contains|';

		case filterOperators.equal:
			return 'IsEqualTo|';

		default:
			return '';
	}
};

/**
 * public enum FilterOperator
{
[EnumMember(Value = "Unknown")] Unknown = -1, // 0xFFFFFFFF
[EnumMember(Value = "IsLessThan")] IsLessThan = 0,
[EnumMember(Value = "IsLessThanOrEqualTo")] IsLessThanOrEqualTo = 1,
[EnumMember(Value = "IsEqualTo")] IsEqualTo = 2,
[EnumMember(Value = "IsNotEqualTo")] IsNotEqualTo = 3,
[EnumMember(Value = "IsGreaterThanOrEqualTo")] IsGreaterThanOrEqualTo = 4,
[EnumMember(Value = "IsGreaterThan")] IsGreaterThan = 5,
[EnumMember(Value = "StartsWith")] StartsWith = 6,
[EnumMember(Value = "EndsWith")] EndsWith = 7,
[EnumMember(Value = "Contains")] Contains = 8,
[EnumMember(Value = "DoesNotContain")] DoesNotContain = 9,
[EnumMember(Value = "IsNull")] IsNull = 12, // 0x0000000C
[EnumMember(Value = "IsNotNull")] IsNotNull = 13, // 0x0000000D
[EnumMember(Value = "IsEmpty")] IsEmpty = 14, // 0x0000000E
[EnumMember(Value = "IsNotEmpty")] IsNotEmpty = 15, // 0x0000000F
[EnumMember(Value = "IsContainedIn")] IsContainedIn = 16, // 0x00000010
[EnumMember(Value = "IsNotContainedIn")] IsNotContainedIn = 17, // 0x00000011
}
 */
