import BaseButton from '../Buttons/BaseButton';

const FormSubmit = ({ children, onClick, name = 'formSubmit', pending = false, ...rest }) => {
	return (
		<BaseButton type='submit' name={name} pending={pending} onClick={onClick} progress {...rest}>
			{children || 'Common_Confirm'}
		</BaseButton>
	);
};

export default FormSubmit;
