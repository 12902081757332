import React, { useState, useEffect } from 'react';
import { useIsMount } from '../../hooks';
import { filterOperators } from '../../constants';

const FiltersControlAutocompleteMulti = ({
	member,
	label,
	stateSlice,
	onChange,
	onClearMemberAll,
	renderSearchComponent,
	filterOperator,
	valueName = 'id',
	defaultValue,
	disabled,
	valueAsString = false
}) => {
	const isMount = useIsMount();
	const [filterValue, setFilterValue] = useState(defaultValue ?? []);
	const [key, setKey] = useState(0);

	const onSelect = (selected) => {
		const values = selected.map((s) => s[valueName]);
		setFilterValue(valueAsString ? values.join(';') : values);
	};

	const handleClearFilter = () => onClearMemberAll(member.name);

	const handleSetFilter = () => {
		onChange(
			[
				{
					member,
					value: filterValue,
					operator: filterOperator ?? filterOperators.collectionContain
				}
			],
			member.name
		);
	};

	useEffect(() => {
		if (isMount) return;
		if (filterValue.length === 0) {
			handleClearFilter();
			return;
		}
		handleSetFilter();
	}, [filterValue.length]);

	useEffect(() => {
		if (stateSlice == null || (stateSlice && Object.keys(stateSlice).length === 0) || !stateSlice[member.name]) {
			setKey((prev) => prev + 1);
			setFilterValue([]);
		}
	}, [stateSlice]);

	return <React.Fragment key={key}>{renderSearchComponent({ label, onSelect, disabled })}</React.Fragment>;
};

export default FiltersControlAutocompleteMulti;
