import clsx from 'clsx';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '@sonar-web/common/src/hooks';

const useStyles = makeStyles(() => ({
	root: {
		position: 'sticky',
		left: 0,
		top: 0,
		backgroundColor: 'white',
		height: 27,
		paddingTop: 10,
		marginBottom: 10,
		zIndex: 1
	},
	rootFake: {
		visibility: 'hidden'
	},
	border: {
		width: '100%',
		border: `1px solid #CACACA`,
		borderBottom: 'none',
		borderRadius: '8px 8px 0px 0px',
		height: 27,
		backgroundColor: 'white',
		alignSelf: 'center',
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	textRoot: {
		position: 'absolute',
		top: -11,
		background: 'white',
		padding: '0px 10px'
	}
}));

const StaircaseIndicator = ({ name }) => {
	const classes = useStyles();
	const { translate } = useLocale();

	return (
		<Box className={clsx(classes.root, { [classes.rootFake]: name === 'fake' })}>
			<Box className={classes.border}>
				<Typography variant='caption' color='textSecondary' classes={{ root: classes.textRoot }}>
					{`${translate('Stats_Staircase')} ${name}`}
				</Typography>
			</Box>
		</Box>
	);
};

export default StaircaseIndicator;
