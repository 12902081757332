import * as Yup from 'yup';
import useLocationNodeLevelsDefinitions from '../LocationNodeLevels/useLocationNodeLevelsDefinitions';

export default (levelName) => {
	const { levels } = useLocationNodeLevelsDefinitions();

	let validationSchema = {
		name: Yup.string()
			.min(1, 'LocationNodes_NameValidationMin')
			.max(50, 'LocationNodes_NameValidationMax')
			.required('Common_ValidationRequired'),
		hasZipCode: Yup.boolean(),
		zipCode: Yup.string().when('hasZipCode', {
			is: true,
			then: () =>
				Yup.string().min(3, 'LocationNodes_ZipCodeValidationMin').max(12, 'LocationNodes_ZipCodeValidationMax')
		}),
		externalId: Yup.string().nullable().max(20, 'LocationNodes_ExternalIdValidationMax')
	};

	if (levelName === levels.Floor.name) {
		validationSchema = {
			...validationSchema,
			name: Yup.string()
				.required('Common_ValidationRequired')
				.test('isFloor', 'LocationNodes_FloorValidation', (value) => {
					if (!value) return false;
					if (value.match(/\s/g) !== null) return false;

					const num = Number(value);
					if (Number.isNaN(num)) return false;
					return num >= -10 && num <= 200;
				})
		};
	}

	return Yup.object().shape(validationSchema);
};
