import { pageDescriptor } from '@sonar-web/common';

export const reportTypesSearchStateName = 'reportTypesSearch';

export const reportTypeSearchInitialState = {
	pageDescriptor,
	fetchPending: false,
	reportTypes: [],
	totalCount: 0,
	reload: false,
	reset: true
};

export const reportTypeSearchReducers = {
	fetchReportTypesSuccess: (state, action) => {
		let fetched = [...action.payload.elements];

		state[reportTypesSearchStateName].reportTypes = state[reportTypesSearchStateName].reset
			? fetched
			: state[reportTypesSearchStateName].reportTypes.concat(fetched);
		state[reportTypesSearchStateName].fetchPending = false;
	},
	fetchReportTypesPending: (state) => {
		state[reportTypesSearchStateName].fetchPending = true;
	},
	setResetAndReload: (state) => {
		state[reportTypesSearchStateName].reportTypes = [];
		state[reportTypesSearchStateName].reload = true;
		state[reportTypesSearchStateName].reset = true;
		state[reportTypesSearchStateName].pageDescriptor.Offset = 0;
		state[reportTypesSearchStateName].pageDescriptor.FilterDescriptors = [];
		state[reportTypesSearchStateName].pageDescriptor.SortDescriptors = [];
	},
	setResetToFirstPage: (state) => {
		state[reportTypesSearchStateName].reportTypes = [];
		state[reportTypesSearchStateName].reload = true;
		state[reportTypesSearchStateName].reset = true;
		state[reportTypesSearchStateName].pageDescriptor.Offset = 0;
	},
	setAppend: (state, action) => {
		state[reportTypesSearchStateName].reset = !action.payload;
		if (action.payload)
			state[reportTypesSearchStateName].pageDescriptor.Offset +=
				state[reportTypesSearchStateName].pageDescriptor.Limit;
		else state[reportTypesSearchStateName].pageDescriptor.Offset = 0;
	},
	setReload: (state) => {
		state[reportTypesSearchStateName].reload = true;
	}
};
