import React, { useEffect, useState } from 'react';
import { FormControl, Slider, TextField, Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useIsMount, useLocale } from '../../../../hooks';

const useStyles = makeStyles(() => ({
	radioRoot: {
		padding: 4,
		'& svg': {
			fontSize: '1rem'
		}
	},
	sliderRail: {
		height: 4
	},
	sliderTrack: {
		height: 4
	},
	timeTextFields: {
		borderBottom: '1px solid rgba(0, 0, 0, 0.42)'
	},
	timeTextfieldRoot: {
		width: 21,
		'& input': {
			textAlign: 'center'
		}
	},
	timeTextfieldUnderline: {
		'&:before, &:after': {
			content: 'none'
		}
	},
	sliderLabel: {
		position: 'absolute',
		top: -9
	}
}));

const inputSettings = { hours: { min: 0, max: 23 }, minutes: { min: 0, max: 59 }, seconds: { min: 0, max: 59 } };
const noHoursOption = ['RRR_2m', 'RRR_5m', 'RRR_10m', 'RRR_15m', 'RRR_30m', 'RRR_1h'];

const ConfiguratorTimeOffset = ({ name, value, form, element }) => {
	const classes = useStyles();
	const isMount = useIsMount();
	const { dependsOn } = element;
	const { translate, makeBoldParameters } = useLocale();
	const dependsValue = dependsOn ? form.values[dependsOn.key] : '';
	const disableHours = noHoursOption.includes(dependsValue);

	const [seconds, setSeconds] = useState(0);
	const [minutes, setMinutes] = useState(0);
	const [hours, setHours] = useState(0);
	const [maskedValue, setMaskedValue] = useState({
		hours: 0,
		minutes: 0,
		seconds: 0
	});

	useEffect(() => {
		const valueNumber = Number(value);
		const h = Math.floor(valueNumber / 3600);
		const m = Math.floor((valueNumber % 3600) / 60);
		const s = Math.floor((valueNumber % 3600) % 60);

		setSeconds(s);
		setMinutes(m);
		setHours(h);

		setMaskedValue(calculateMaskedValue(h, m, s));
	}, []);

	useEffect(() => {
		if (!isMount && disableHours) {
			const nextMaskedValue = { minutes, seconds, hours: 0 };

			setHours(0);
			setMaskedValue(
				calculateMaskedValue(nextMaskedValue.hours, nextMaskedValue.minutes, nextMaskedValue.seconds)
			);
		}
	}, [disableHours]);

	useEffect(() => {
		const newValue = hours * 3600 + minutes * 60 + seconds;

		if (newValue != value) form.setFieldValue(name, newValue);
	}, [seconds, minutes, hours]);

	const handleSliderChange = (e, v, type, setType) => {
		const nextMaskedValue = { hours, minutes, seconds, [type]: v };

		setType(v);
		setMaskedValue(calculateMaskedValue(nextMaskedValue.hours, nextMaskedValue.minutes, nextMaskedValue.seconds));
	};

	const handleMaskedChange = (e, type, setType) => {
		let nextValue = e.target.value;
		const isMatch = /^\d{0,2}$/.test(nextValue);

		if (nextValue === '') setMaskedValue({ ...maskedValue, [type]: nextValue });
		else if (isMatch) {
			const min = inputSettings[type].min;
			const max = inputSettings[type].max;

			if (Number(nextValue) < min) nextValue = min;
			if (Number(nextValue) > max) nextValue = max;

			setType(Number(nextValue));
			setMaskedValue({ ...maskedValue, [type]: nextValue });
		}
	};

	const handleBlur = (e, type, setType) => {
		if (e.target.value === '') {
			const min = inputSettings[type].min;

			setType(Number(min));
			setMaskedValue({ ...maskedValue, [type]: min });
		}
	};

	return (
		<FormControl fullWidth margin='normal'>
			<Typography variant='caption'>
				{translate(disableHours ? 'Common_TransmissionOffsetMS' : 'Common_TransmissionOffsetHMS')}
			</Typography>
			<Box display='flex' alignItems='center' className={classes.timeTextFields} mt={1}>
				{!disableHours && (
					<>
						<TextField
							margin='none'
							size='small'
							fullWidth={false}
							name={name}
							value={maskedValue.hours}
							classes={{ root: classes.timeTextfieldRoot }}
							onChange={(e) => handleMaskedChange(e, 'hours', setHours)}
							onBlur={(e) => handleBlur(e, 'hours', setHours)}
							slotProps={{
								input: {
									classes: {
										underline: classes.timeTextfieldUnderline
									}
								}
							}}
						/>
						:
					</>
				)}
				<TextField
					margin='none'
					size='small'
					fullWidth={false}
					name={name}
					value={maskedValue.minutes}
					classes={{ root: classes.timeTextfieldRoot }}
					onChange={(e) => handleMaskedChange(e, 'minutes', setMinutes)}
					onBlur={(e) => handleBlur(e, 'minutes', setMinutes)}
					slotProps={{
						input: {
							classes: {
								underline: classes.timeTextfieldUnderline
							}
						}
					}}
				/>
				:
				<TextField
					margin='none'
					size='small'
					fullWidth={false}
					name={name}
					value={maskedValue.seconds}
					classes={{ root: classes.timeTextfieldRoot }}
					onChange={(e) => handleMaskedChange(e, 'seconds', setSeconds)}
					onBlur={(e) => handleBlur(e, 'seconds', setSeconds)}
					slotProps={{
						input: {
							classes: {
								underline: classes.timeTextfieldUnderline
							}
						}
					}}
				/>
			</Box>
			{!disableHours && (
				<Box position='relative' mt={4}>
					<Typography variant='caption' classes={{ root: classes.sliderLabel }}>
						{translate('Common_Hours')}
					</Typography>
					<Slider
						aria-labelledby={`${name}_sliderHours`}
						valueLabelDisplay='auto'
						value={hours}
						step={1}
						min={inputSettings.hours.min}
						max={inputSettings.hours.max}
						onChange={(e, v) => handleSliderChange(e, v, 'hours', setHours)}
						classes={{
							rail: classes.sliderRail,
							track: classes.sliderTrack
						}}
					/>
				</Box>
			)}
			<Box position='relative' mt={4}>
				<Typography variant='caption' classes={{ root: classes.sliderLabel }}>
					{translate('Common_Minutes')}
				</Typography>
				<Slider
					aria-labelledby={`${name}_sliderMinutes`}
					valueLabelDisplay='auto'
					value={minutes}
					step={1}
					min={inputSettings.minutes.min}
					max={inputSettings.minutes.max}
					onChange={(e, v) => handleSliderChange(e, v, 'minutes', setMinutes)}
					classes={{
						rail: classes.sliderRail,
						track: classes.sliderTrack
					}}
				/>
			</Box>
			<Box position='relative' mt={4} mb={2}>
				<Typography variant='caption' classes={{ root: classes.sliderLabel }}>
					{translate('Common_Seconds')}
				</Typography>
				<Slider
					aria-labelledby={`${name}_sliderSeconds`}
					valueLabelDisplay='auto'
					value={seconds}
					step={1}
					min={inputSettings.seconds.min}
					max={inputSettings.seconds.max}
					onChange={(e, v) => handleSliderChange(e, v, 'seconds', setSeconds)}
					classes={{
						rail: classes.sliderRail,
						track: classes.sliderTrack
					}}
				/>
			</Box>
			<Typography
				variant='caption'
				dangerouslySetInnerHTML={{
					__html: disableHours
						? makeBoldParameters(
								translate('Common_TransmissionOffsetMSDescription', minutes, seconds),
								minutes,
								seconds
							)
						: makeBoldParameters(
								translate('Common_TransmissionOffsetHMSDescription', hours, minutes, seconds),
								hours,
								minutes,
								seconds
							)
				}}
			/>
		</FormControl>
	);
};

export default ConfiguratorTimeOffset;

function calculateMaskedValue(h, m, s) {
	const hours = Number(h) < 10 ? `0${h}` : Number(h);
	const minutes = Number(m) < 10 ? `0${m}` : Number(m);
	const seconds = Number(s) < 10 ? `0${s}` : Number(s);

	return {
		hours,
		minutes,
		seconds
	};
}
