import { apiBase } from '@sonar-web/common';

export async function add(props, submitData) {
	const { servicePath, addSubmitEndpoint } = props;

	const { data } = await apiBase(addSubmitEndpoint, 'post', {
		path: servicePath,
		data: submitData
	});

	return data;
}

export async function edit(props, submitData) {
	const { servicePath, editSubmitEndpoint, editSubmitEndpointPathParams } = props;

	let url = editSubmitEndpoint;

	if (editSubmitEndpointPathParams?.length > 0)
		editSubmitEndpointPathParams.forEach((ep) => {
			url = url.replace(`{${ep}}`, `${submitData[ep]}`);
		});

	const { data } = await apiBase(url, 'put', {
		path: servicePath,
		data: submitData
	});

	return data;
}

export async function addTemplate(props, submitData) {
	const { servicePath, addTemplateEndpoint } = props;

	const { data } = await apiBase(addTemplateEndpoint, 'post', {
		path: servicePath,
		data: submitData
	});

	return data;
}

export async function editTemplate(props, submitData) {
	const { servicePath, editTemplateEndpoint, editTemplateEndpointPathParams } = props;

	let url = editTemplateEndpoint;

	if (editTemplateEndpointPathParams?.length > 0)
		editTemplateEndpointPathParams.forEach((ep) => {
			url = url.replace(`{${ep}}`, `${submitData[ep]}`);
		});

	const { data } = await apiBase(url, 'put', {
		path: servicePath,
		data: submitData
	});

	return data;
}
