import React, { useState, useEffect } from 'react';
import { TextField, InputAdornment, IconButton, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { useLocale, useIsMount } from '../../hooks';
import { filterOperators } from '../../constants';

const useStyles = makeStyles(() => ({
	adornmentIcon: {
		padding: 0,
		marginRight: '-2px'
	}
}));

const FiltersControlAutocompleteSingle = ({
	member,
	label,
	stateSlice,
	onChange,
	onClearMemberAll,
	displayValueName,
	valueName,
	renderDisplayValue,
	renderSearchComponent,
	filterOperator,
	...rest
}) => {
	const classes = useStyles();
	const isMount = useIsMount();
	const { translate } = useLocale();
	const [value, setValue] = useState('');
	const [filterValue, setFilterValue] = useState(null);
	const [searchActive, setSearchActive] = useState(true);
	const [inputRef, setInputRef] = useState(null);
	const { textfieldName } = rest;

	const handlerClearValue = () => {
		setValue('');
		setFilterValue(null);
	};

	const handleInputClick = () => {
		setSearchActive(true);

		const interval = setInterval(() => {
			if (inputRef) {
				inputRef.focus();
				clearInterval(interval);
			}
		}, 50);
	};

	const handleSelect = (selected) => {
		if (renderDisplayValue) {
			const displayValue = renderDisplayValue(selected);
			setValue(displayValue);
		} else if (displayValueName) {
			setValue(selected[displayValueName]);
		}

		setFilterValue(selected[valueName]);
		setSearchActive(false);
	};

	const handleCancelSelect = () => setSearchActive(false);

	const handleClearFilter = () => onClearMemberAll(member.name);

	const handleSetFilter = () => {
		onChange(
			[
				{
					member,
					value: filterValue,
					operator: filterOperator ?? filterOperators.equal
				}
			],
			member.name
		);
	};

	useEffect(() => {
		if (isMount) return;
		if (value === '') {
			setSearchActive(true);
			handleClearFilter();
			return;
		}
		handleSetFilter();
	}, [value]);

	useEffect(() => {
		if (stateSlice == null || (stateSlice && Object.keys(stateSlice).length === 0) || !stateSlice[member.name]) {
			handlerClearValue();
		}
	}, [stateSlice]);

	return (<>
        <Box display={searchActive ? 'block' : 'none'}>
            {renderSearchComponent({ label, setInputRef, handleSelect, handleCancelSelect })}
        </Box>
        <Box display={!searchActive ? 'block' : 'none'}>
            <TextField
                name={textfieldName}
                label={translate(label)}
                type='text'
                size='small'
                fullWidth
                value={value}
                slotProps={{
                    input: {
						readOnly: true,
						inputProps: {
							onClick: handleInputClick
						},
						endAdornment: (
							<>
								{value !== '' && (
									<InputAdornment position='end'>
										<IconButton
											size='small'
											className={classes.adornmentIcon}
											aria-label={`filter_${member.name}_clear`}
											onClick={handlerClearValue}>
											<CloseIcon fontSize='small' />
										</IconButton>
									</InputAdornment>
								)}
								<ArrowDropDownOutlinedIcon
									onClick={handleInputClick}
									color='action'
									style={{
										cursor: 'pointer',
										transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
										marginLeft: '2px'
									}}
								/>
							</>
						)
					}
                }}
            />
        </Box>
    </>);
};

export default FiltersControlAutocompleteSingle;
