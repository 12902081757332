export default {
	InstantMonth: {
		value: 1,
		name: 'InstantMonth'
	},
	InstantYear: {
		value: 2,
		name: 'InstantYear'
	},
	LoggedMonth: {
		value: 3,
		name: 'LoggedMonth'
	},
	LoggedYear: {
		value: 4,
		name: 'LoggedYear'
	}
};
