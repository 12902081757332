import React from 'react';
import ListNoRecords from '../NoDataPlaceholders/ListNoRecords';
import ListNoRecordsIcon from '../NoDataPlaceholders/ListNoRecordsIcon';
import UnderConstructionIcon from './UnderConstructionIcon';

const PanelUnderConstruction = () => {
	return (
		<ListNoRecords
			show={true}
			text='Common_UnderConstruction'
			icon={
				<ListNoRecordsIcon>
					{({ fontSize }) => <UnderConstructionIcon secondaryPathClass='primaryColor' fontSize={'xxl'} />}
				</ListNoRecordsIcon>
			}
		/>
	);
};

export default PanelUnderConstruction;
