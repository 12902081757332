import { filterOperators } from '@sonar-web/common';
import LocationsSelectList from '../LocationsSelectList';
import LocationsSelectSelectedListItem from './LocationsSelectSelectedListItem';

const LocationsSelectSelected = ({ data, action, filterMemeber = { name: 'id', type: 'guid' } }) => {
	const filters = (locations) => [
		{
			member: filterMemeber,
			value: locations.join(';'),
			operator: filterOperators.in
		}
	];

	return (
		<LocationsSelectList
			selectedLocations={data}
			asList={true}
			listDefaultFilters={filters}
			listItem={(row) => <LocationsSelectSelectedListItem row={row} action={action} />}
		/>
	);
};

export default LocationsSelectSelected;
