import { VisualizationType } from '../../Enums/VisualizationType';

export const buildingVisualizationStateName = 'buildingVisualization';

export const initialBuildingVisualizationData = {
	pending: false,
	data: null,
	staircases: null,
	floors: null,
	maxLocalsPerStaircase: {},
	visualizationType: VisualizationType.Details.value,
	activeStaircase: null,
	activeFloor: null
};

export const buildingVisualizationReducers = {
	setFetchBuildingVisualizationPending: (state, { payload }) => {
		state[buildingVisualizationStateName].pending = payload;
	},
	setBuildingVisualizationSuccess: (state, { payload }) => {
		state[buildingVisualizationStateName].pending = false;
		state[buildingVisualizationStateName].data = payload;

		const { staircases, floors, maxLocals, maxLocalsPerStaircase, allFloors, allLocalsCount } =
			buildingStatistics(payload);

		state[buildingVisualizationStateName].staircases = staircases;
		state[buildingVisualizationStateName].floors = floors;
		state[buildingVisualizationStateName].allFloors = allFloors;
		state[buildingVisualizationStateName].maxLocalsPerStaircase = maxLocalsPerStaircase;
		state[buildingVisualizationStateName].maxLocals = maxLocals;
		state[buildingVisualizationStateName].allLocalsCount = allLocalsCount;
	},
	resetBuildingVisualization: (state) => {
		state[buildingVisualizationStateName] = initialBuildingVisualizationData;
	},
	updateVisualizationType: (state, { payload }) => {
		state[buildingVisualizationStateName].visualizationType = payload;
	}
};

function buildingStatistics(data) {
	let staircases = [];
	let floors = [];
	let allFloors = new Set();
	let maxLocalsPerStaircase = {};

	for (let d of data) {
		staircases.push(d.name);

		for (let f of d.floors) {
			const currentLocalcount = maxLocalsPerStaircase[d.name] || 0;

			floors.push({ ...f, staircase: d.name });
			allFloors.add(f.name);

			if (f.locals.length > currentLocalcount) maxLocalsPerStaircase[d.name] = f.locals.length;
		}
	}

	const sortedAllFloors = Array.from(allFloors).sort((a, b) => b - a);
	const { maxLocals, allLocalsCount } = Object.values(maxLocalsPerStaircase).reduce(
		(acc, curr) => {
			return {
				maxLocals: Math.max(acc.maxLocals, curr),
				allLocalsCount: acc.allLocalsCount + curr
			};
		},
		{ maxLocals: 0, allLocalsCount: 0 }
	);

	return {
		staircases,
		floors,
		allFloors: sortedAllFloors,
		maxLocalsPerStaircase,
		maxLocals,
		allLocalsCount
	};
}
