import { Avatar, Typography } from '@mui/material';
import { useAvatarLetters } from '@sonar-web/common/src/hooks';
import { useThemeBackroundColorForWhiteFont } from '@sonar-web/common/src/hooks/useThemeColor';
import { BaseTooltip } from '@sonar-web/common';

const AvatarLetters = (props) => {
	if (props.children) return <ContentAvatar {...props}>{props.children}</ContentAvatar>;
	return <ContentUseAvatarLetters {...props} />;
};

export default AvatarLetters;

const ContentAvatar = ({ children, value, avatarStyles = {}, tooltipProps = {} }) => {
	return (
		<BaseTooltip text={value} {...tooltipProps}>
			<Avatar
				sx={{
					backgroundColor: 'background.default',
					'&:hover': {
						zIndex: 1
					},
					border: '1px solid',
					...avatarStyles
				}}>
				{children}
			</Avatar>
		</BaseTooltip>
	);
};

const ContentUseAvatarLetters = ({ value, disableBackground = false, avatarStyles = {}, tooltipProps = {} }) => {
	const avatarLetters = useAvatarLetters(value);
	let avatarBackground = useThemeBackroundColorForWhiteFont(avatarLetters);
	if (disableBackground) avatarBackground = null;

	return (
		<ContentAvatar
			value={value}
			tooltipProps={tooltipProps}
			avatarStyles={{
				...avatarStyles,
				backgroundColor: disableBackground ? 'background.default' : avatarBackground
			}}>
			<Typography variant='body2' color={disableBackground ? 'textSecondary' : 'white'}>
				{avatarLetters}
			</Typography>
		</ContentAvatar>
	);
};
