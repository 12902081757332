import { createSelector, createSlice } from '@reduxjs/toolkit';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import { slices } from '../Constants/Module';
import { fetchNetwork, addNetwork, editNetwork, deleteNetwork } from './networkApi';

const slice = slices.stationaryNetwork;
const getNetwork = (getState) => getState().stationaryNetwork.network;

export const networkSlice = createSlice({
	name: slice,
	initialState: {
		addOrEditPending: false,
		addOrEditSuccess: false,
		fetchPending: false,
		fetchSuccess: false,
		deletePending: false,
		deleteSuccess: false,
		network: null
	},
	reducers: {
		fetchNetworkSucces: (state, action) => {
			state.network = action.payload;
			state.fetchPending = false;
			state.fetchSuccess = true;
		},
		fetchNetworkPending: (state) => {
			state.fetchPending = true;
			state.fetchSuccess = false;
		},
		addNetworkSucces: (state, action) => {
			state.addOrEditPending = false;
			state.addOrEditSuccess = action.payload;
		},
		addNetworkPending: (state, action) => {
			state.addOrEditPending = action.payload ?? true;
		},
		deleteNetworkSucces: (state) => {
			state.deletePending = false;
			state.deleteSuccess = true;
		},
		deleteNetworkPending: (state, action) => {
			state.deletePending = action.payload ?? true;
		},
		setNetworkData: (state, action) => {
			state.network = action.payload;
		},
		resetNetworkData: (state) => {
			state.network = null;
			state.addOrEditSuccess = false;
			state.deleteSuccess = false;
			state.fetchSuccess = false;
		},
		resetAddSuccess: (state) => {
			state.addOrEditSuccess = false;
		},
		updateNetworkData: (state, { payload }) => {
			state.network = { ...state.network, ...payload };
		}
	}
});

export const {
	fetchNetworkSucces,
	fetchNetworkPending,
	addNetworkSucces,
	addNetworkPending,
	deleteNetworkSucces,
	deleteNetworkPending,
	setNetworkData,
	resetNetworkData,
	resetAddSuccess,
	updateNetworkData
} = networkSlice.actions;

export const fetchNetworkAsync = (id) => async (dispatch) => {
	let response;

	try {
		dispatch(fetchNetworkPending());
		response = await fetchNetwork(id);
		dispatch(fetchNetworkSucces(response));
	} catch (error) {
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const addNetworkAsync = () => async (dispatch, getState) => {
	try {
		dispatch(addNetworkPending());

		await addNetwork(getNetwork(getState));

		dispatch(addNetworkSucces(true));
	} catch (error) {
		dispatch(addNetworkPending(false));

		return await dispatch(addErrorAsync({ slice, error, skipNotificationMembers: ['name'] }));
	}
};

export const editNetworkAsync = () => async (dispatch, getState) => {
	try {
		dispatch(addNetworkPending());

		await editNetwork(getNetwork(getState));

		dispatch(addNetworkSucces(true));
	} catch (error) {
		dispatch(addNetworkPending(false));

		return await dispatch(addErrorAsync({ slice, error, skipNotificationMembers: ['name'] }));
	}
};

export const deleteNetworkAsync = (id) => async (dispatch) => {
	try {
		dispatch(deleteNetworkPending());
		await deleteNetwork(id);
		dispatch(deleteNetworkSucces());
	} catch (error) {
		dispatch(deleteNetworkPending(false));
		return await dispatch(addErrorAsync({ slice, error }));
	}
};

export const selectNetwork = createSelector(
	(state) => state.stationaryNetwork,
	(network) => ({
		dataRow: network.network,
		fetchPending: network.fetchPending,
		fetchSuccess: network.fetchSuccess
	})
);

export const selectNetworkAddOrEdit = createSelector(
	(state) => state.stationaryNetwork,
	(network) => ({
		pending: network.addOrEditPending,
		success: network.addOrEditSuccess
	})
);

export const selectNetworkDelete = createSelector(
	(state) => state.stationaryNetwork,
	(network) => ({
		dataRow: network.network,
		deletePending: network.deletePending,
		deleteSuccess: network.deleteSuccess
	})
);

export default networkSlice.reducer;
