import React from 'react';
import clsx from 'clsx';
import { ListItemText, Typography, Icon, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowRightIcon from '../../icons/ArrowRightIcon';

const useStyles = makeStyles((theme) => ({
	noBottomBorder: {
		borderBottomColor: 'transparent'
	},
	linkBehavior: {
		cursor: 'pointer',
		fontWeight: theme.typography.fontWeightMedium
	},
	linkBehaviorWrapper: {
		display: 'inline-flex',
		alignItems: 'center'
	},
	linkBehaviorWrapperUnderlined: {
		display: 'inline-flex',
		alignItems: 'center',
		borderBottom: `1px solid ${theme.palette.underline.light}`,
		'&:hover': {
			borderBottomStyle: 'dashed'
		}
	},
	noFlex: {
		flex: 'none'
	},
	primaryRoot: {
		'& > span': {
			fontWeight: theme.typography.fontWeightMedium
		}
	},
	linkBehaviorIcon: {
		display: 'flex',
		alignItems: 'center',
		transition: `margin 150ms ease`,
		visibility: 'hidden',
		opacity: 0,
		marginLeft: -5,
		width: 10
	}
}));

const ListItemTextBase = ({
	children,
	hideBorder,
	disableFlex,
	primaryTextAction,
	primary,
	primaryUnderlineDiabled,
	secondaryTypographyProps = {},
	...rest
}) => {
	const classes = useStyles();

	let primaryTypographyProps = {
		variant: 'body2',
		display: 'inline',
		'data-at': 'ListItemPrimaryText',
		className: classes.primaryRoot
		// noWrap: true
	};

	if (primaryTextAction) {
		primaryTypographyProps = {
			...primaryTypographyProps,
			'data-at-link': 'ListItemPrimaryTextLink',
			component: 'span',
			onClick: (e) => primaryTextAction(e)
		};
	}

	const PrimaryTextComponent = () => {
		if (!primaryTextAction) return primary;

		return (
			<Box
				className={
					primaryUnderlineDiabled ? classes.linkBehaviorWrapper : classes.linkBehaviorWrapperUnderlined
				}
				data-at-link='ListItemPrimaryTextLink'>
				<Typography
					variant='body2'
					component='span'
					className={classes.linkBehavior}
					data-at-link='ListItemPrimaryTextLink'>
					{primary}
				</Typography>
				<Icon
					fontSize='small'
					className='linkHoverIcon'
					classes={{ root: classes.linkBehaviorIcon }}
					data-at-link='ListItemPrimaryTextLink'>
					<ArrowRightIcon fontSize='smaller' />
				</Icon>
			</Box>
		);
	};

	return (
		<ListItemText
			primaryTypographyProps={primaryTypographyProps}
			secondaryTypographyProps={{
				'data-at': 'ListItemSecondaryText',
				variant: 'caption',
				color: 'textSecondary',
				...secondaryTypographyProps
			}}
			{...rest}
			primary={PrimaryTextComponent()}
			className={clsx({
				[classes.noBottomBorder]: hideBorder,
				[classes.noFlex]: disableFlex
			})}>
			{children}
		</ListItemText>
	);
};

export default ListItemTextBase;
