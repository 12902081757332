import React from 'react';
import BaseIcon from './BaseIcon';

export default function StreetIcon(props) {
	return (
		<BaseIcon {...props}>
			<path d='m 6.0039848,3.5126645 c -1.37052,0 -2.49236,1.11976 -2.49236,2.49029 V 17.994985 c 0,1.37052 1.12184,2.49235 2.49236,2.49235 H 17.996015 c 1.37052,0 2.49236,-1.12183 2.49236,-2.49235 V 6.0029545 c 0,-1.37053 -1.12184,-2.49029 -2.49236,-2.49029 z m 0,1.09967 H 17.996015 c 0.78015,0 1.39061,0.61047 1.39061,1.39062 V 17.994985 c 0,0.78015 -0.61046,1.39268 -1.39061,1.39268 h -5.44514 v -9.02942 h 3.5357 l -1.79059,1.79058 -0.38861,0.39068 0.77722,0.77721 0.3886,-0.38861 2.73627,-2.73626 0.38912,-0.3886105 -0.0016,-0.00207 0.0016,-0.00206 -0.38912,-0.38861 -2.73627,-2.73627 -0.3886,-0.3886 -0.77722,0.77721 0.38861,0.38861 1.80557,1.80557 h -4.10155 -0.55088 v 0.00982 0.55035 0.5410605 1.25005 H 7.9480548 l 1.79213,-1.7921405 0.3886102,-0.38861 -0.7772102,-0.77721 -0.38861,0.38861 -2.73627,2.7362605 -0.38912,0.38861 0.0021,0.0021 -0.0021,0.0021 0.38912,0.38861 2.73627,2.73627 0.38861,0.38861 0.7772102,-0.77722 -0.3886102,-0.39067 -1.80557,-1.80558 h 3.5145102 v 6.6797 H 6.0039848 c -0.78015,0 -1.39061,-0.61253 -1.39061,-1.39268 V 6.0029545 c 0,-0.78015 0.61046,-1.39062 1.39061,-1.39062 z' />
		</BaseIcon>
	);
}
