import useBuilingVisualization from './useBuilingVisualization';
import { VisualizationType } from '../../Enums/VisualizationType';
import BaseButton from '@sonar-web/common/src/components/Buttons/BaseButton';

const BuildingControls = () => {
	const { visualizationType, setVisualizationType } = useBuilingVisualization();
	const text = visualizationType === VisualizationType.Points.value ? 'BuildingDetailsView' : 'BuildingPointsView';

	const onClick = () =>
		setVisualizationType(
			visualizationType === VisualizationType.Points.value
				? VisualizationType.Details.value
				: VisualizationType.Points.value
		);

	return (
		<BaseButton name='buildingVisualizationViewChange' variant='outlined' onClick={onClick}>
			{text}
		</BaseButton>
	);
};

export default BuildingControls;
