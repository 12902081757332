export const moduleName = 'reports';

export const slices = {
	generatedReports: 'generatedReports',
	scheduledReports: 'scheduledReports',
	report: 'report',
	reportTypes: 'reportTypes',
	sharing: 'sharing'
};

export const sidebars = {
	reportTypes: 'reportTypes'
};
