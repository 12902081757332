export const moduleName = 'import';

const slices = {
	import: 'import',
	imports: 'imports',
	importsConfigurator: 'importsConfigurator'
};

const routes = {
	imports: () => '/imports',
	generatedImports: (props) => {
		return props ? `/imports/${props.type}/done` : `/imports/:type/done`;
	},
	newImport: (props) => {
		return props ? `/imports/${props.type}/new` : `/imports/:type/new`;
	}
};

const Module = {
	moduleName,
	slices,
	routes
};

export default Module;
