import { apiBase, pageDescriptor, REST_API_PATHS } from '@sonar-web/common';

const path = REST_API_PATHS.EXPORT;

/* templates */

//pobranie domyślnego szablonu
export async function fetchDefaultTemplate(type) {
	const { data } = await apiBase(`templates/default/${type}`, 'get', { path });

	return data;
}

//dodanie szablonu
export async function addTemplate(template) {
	const response = await apiBase('templates', 'post', {
		path,
		data: template
	});

	const locationHeader = response.headers.location;

	if (locationHeader)
		return {
			url: locationHeader,
			id: locationHeader.slice(locationHeader.lastIndexOf('/') + 1)
		};

	return response;
}

//edycja szablonu
export async function editTemplate(template) {
	const { data } = await apiBase(`templates/${template.id}`, 'put', {
		path,
		data: template
	});

	return data;
}

//usunięcie szablonu
export async function deleteTemplate(id) {
	const { data } = await apiBase(`templates/${id}`, 'delete', { path });

	return data;
}

//pobranie listy szablonów
export async function fetchTemplates(filters) {
	const pd = { ...pageDescriptor, FilterDescriptors: filters };

	const { data } = await apiBase('templates', 'get', {
		path,
		params: { pageDescriptor: pd }
	});

	return data;
}

/* scheduledexports */

//dodanie exportu
export async function addExport(exportData) {
	const response = await apiBase('scheduledexports', 'post', {
		path,
		data: exportData
	});

	const locationHeader = response.headers.location;

	if (locationHeader)
		return {
			url: locationHeader,
			id: locationHeader.slice(locationHeader.lastIndexOf('/') + 1)
		};

	return response;
}

//edycja szablonu
export async function editExport(exportData) {
	const { data } = await apiBase(`scheduledexports/${exportData.id}`, 'put', {
		path,
		data: exportData
	});

	return data;
}

//pobranie zaharmonogramowanego exportu po id
export async function fetchTemplate(id) {
	const { data } = await apiBase(`scheduledexports/${id}`, 'get', { path });

	return data;
}

//pobranie stref czasowych
export async function fetchTimezones() {
	const { data } = await apiBase(`timezones`, 'get', { path });

	return data;
}

//cancelExport
export async function cancelExport(id) {
	const { data } = await apiBase(`exports/${id}/cancel`, 'delete', { path });

	return data;
}

export async function runScheduledExport(id) {
	const { data } = await apiBase(`scheduledexports/generate`, 'post', {
		path,
		data: { scheduledExportId: id }
	});

	return data;
}
