import React from 'react';
import clsx from 'clsx';
import { Box } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = (props) => {
	return makeStyles((theme) => {
		return {
			root: {
				position: 'sticky',
				left: 0,
				top: 0,
				backgroundColor: 'white',
				marginBottom: '16px'
			},
			border: {
				width: '100%',
				height: '100%',
				minHeight: '100%',
				border: `1px solid ${theme.palette.grey[400]}`,
				borderBottom: 'none',
				borderLeft: props.leftSideOpen ? 'none' : undefined,
				borderRight: props.rightSideOpen ? 'none' : undefined,
				borderRadius: `${props.leftSideOpen ? '0px' : '8px'} ${props.rightSideOpen ? '0px' : '8px'} 0px 0px`,
				margin: '10px 0',
				position: 'relative',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center'
			},
			textRoot: {
				position: 'absolute',
				top: -11,
				background: theme.palette.common.white,
				padding: '0px 3px',
				margin: 0,
				maxWidth: 'calc(100% - 10px)',
				display: 'flex',
				flexDirection: 'row',
				flexWrap: 'wrap'
			}
		};
	});
};

const TimelineTopIndicator = ({ className, children, leftSideOpen = false, rightSideOpen = false, ...rest }) => {
	const classes = useStyles({
		leftSideOpen: leftSideOpen,
		rightSideOpen: rightSideOpen
	})();
	return (
		<Box className={clsx(classes.root, className)} display='flex' {...rest}>
			<Box display='flex' flexDirection='column' flex={1}>
				<Box className={classes.border}>
					<span className={classes.textRoot}>{children}</span>
				</Box>
			</Box>
		</Box>
	);
};

export default TimelineTopIndicator;
