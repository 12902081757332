import { createSelector, createSlice } from '@reduxjs/toolkit';
import Notification from '../../components/Notification/Notification';
import { ERROR_TYPES } from '../../constants';
import errorHandler from './errorHandler';

export const errorsSlice = createSlice({
	name: 'errors',
	initialState: {
		pending: false,
		last: null,
		app: null
	},
	reducers: {
		addError: (state, action) => {
			const { slice, parsed } = action.payload;

			console.log(parsed);

			state[slice] = parsed;
			state.pending = true;
			state.last = { ...parsed, slice };
		},
		closeError: (state) => {
			state.pending = false;
		}
	}
});

export const { addError, closeError } = errorsSlice.actions;

export const addErrorAsync =
	({ slice, error, skipNotificationMembers }) =>
	async (dispatch) => {
		const parsed = await errorHandler(error);
		if (parsed?.message === 'TokenRefreshFailed') return;

		console.log(parsed);
		handleNotification(parsed, skipNotificationMembers, dispatch);

		dispatch(addError({ slice, parsed }));
		return parsed;
	};

export const selectPendingError = createSelector(
	(state) => state.errors,
	(errors) => ({
		pending: errors.pending,
		error: errors.last
	})
);

export default errorsSlice.reducer;

function handleNotification(error, skipNotificationMembers, dispatch) {
	const isApiError = error.type === ERROR_TYPES.api;
	const hasMember = error.member != null;
	const skip =
		hasMember &&
		skipNotificationMembers?.length > 0 &&
		skipNotificationMembers.includes(error.member.uncapitalize());

	if (skip) return;

	if (isApiError) Notification.error({ message: error.message, onClose: () => dispatch(closeError()) });
}
