import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { Formik, ErrorMessage } from 'formik';
import {
	addReadAsync,
	setReadData,
	selectReadAddPending,
	selectRead,
	fetchReadAsync,
	editReadAsync,
	resetReadData
} from './readSlice';
import {
	FormToggleButton,
	TypographyEmphesizeWords,
	FormikDateTimePicker,
	ReadByInkasentIcon,
	ReadByTenantIcon,
	FormWrapper,
	guidEmpty
} from '@sonar-web/common';
import useLocale from '@sonar-web/common/src/hooks/useLocale';
import { useForm } from '@sonar-web/common/src/hooks';
import FormComponents from '@sonar-web/common/src/components/FormComponents/FormComponents';
import FormTextField from '@sonar-web/common/src/components/FormTextField/FormTextField';
import ReadSource from '@sonar-web/common/src/enums/ReadSource';
import validationSchema from './validationSchema';
import ReadSkeleton from './ReadSkeleton';

// eslint-disable-next-line react/display-name
const ReadForm = ({ id, heatallocatorId, startDate, onClose, onSuccess }) => {
	const dispatch = useDispatch();
	const { translate } = useLocale();
	const { handleApiErrors } = useForm();

	const addPending = useSelector(selectReadAddPending);
	const { dataRow, fetchSuccess } = useSelector(selectRead);

	const handleClose = () => onClose();

	const handleOnSubmit = async (values, actions) => {
		actions.setSubmitting(true);

		const submitFunction = id ? editReadAsync : addReadAsync;
		let requestData = { ...values };

		if (!id) requestData.heatAllocatorId = heatallocatorId;
		if (typeof requestData.date === 'object')
			requestData.date = requestData.date.set({ second: 0, milisecond: 0 }).toISOString();

		requestData.bearing =
			typeof requestData.bearing === 'string'
				? Number(requestData.bearing.replace(',', '.'))
				: requestData.bearing;

		dispatch(setReadData(requestData));
		dispatch(submitFunction()).then((response) => {
			if (response === true) {
				handleClose();
				onSuccess(requestData);
				return;
			}
			handleApiErrors(actions, response);
		});
	};

	useEffect(() => {
		if (id && id !== guidEmpty) dispatch(fetchReadAsync(id));

		return () => dispatch(resetReadData());
	}, []);

	return !id || (id && fetchSuccess) ? (
		<FormWrapper name='readForm'>
			<Formik
				initialValues={dataRow}
				enableReinitialize
				validationSchema={validationSchema}
				onSubmit={handleOnSubmit}>
				{(formik) => {
					const { values, errors, touched, handleSubmit, setFieldValue } = formik;

					return (
						<form onSubmit={handleSubmit}>
							<FormComponents.Title>{translate(id ? 'EditRead' : 'AddRead')}</FormComponents.Title>
							<FormTextField
								autoFocus
								name='bearing'
								label={translate('HeatAllocators_YearlyBearing')}
								variant='outlined'
								formik={formik}
							/>
							<FormikDateTimePicker
								name='date'
								disableFuture
								field={{ name: 'date', value: values.date }}
								form={{ errors, touched, setFieldValue }}
								label='HeatAllocators_MeasurementDateAndTime'
								FormHelperTextProps={{
									'data-at-error': errors.date
								}}
								textFieldProps={{
									margin: 'normal',
									variant: 'outlined',
									size: 'small'
								}}
								minDate={startDate}
							/>
							<Box display='flex' justifyContent='space-between' marginTop='2rem'>
								<FormToggleButton
									value={values.source}
									onValue={ReadSource.Inkasent.name}
									name={`readSource_${ReadSource.Inkasent.name}`}
									icon={<ReadByInkasentIcon fontSize='m' secondaryPathClass='primaryColor' />}
									text={
										<TypographyEmphesizeWords
											text='HeatAllocators_ReadBy'
											words={['HeatAllocators_ByInkasent']}
											breakRowOnWords
										/>
									}
									onClick={(val) => setFieldValue('source', val)}
								/>
								<FormToggleButton
									value={values.source}
									onValue={ReadSource.Tenant.name}
									name={`readSource_${ReadSource.Tenant.name}`}
									icon={<ReadByTenantIcon fontSize='m' secondaryPathClass='primaryColor' />}
									text={
										<TypographyEmphesizeWords
											text='HeatAllocators_ReadBy'
											words={['HeatAllocators_ByTenant']}
											breakRowOnWords
										/>
									}
									onClick={(val) => setFieldValue('source', val)}
								/>
							</Box>
							<ErrorMessage name='source'>
								{(msg) => (
									<Typography variant='caption' color='error' data-at-error={errors.source}>
										{translate(msg)}
									</Typography>
								)}
							</ErrorMessage>
							<FormComponents.Toolbar>
								<FormComponents.CancelButton pending={addPending} onClick={handleClose} />
								<FormComponents.SubmitButton pending={addPending} />
							</FormComponents.Toolbar>
						</form>
					);
				}}
			</Formik>
		</FormWrapper>
	) : (
		<Box flex='1' alignSelf='center' width={500}>
			<ReadSkeleton />
		</Box>
	);
};

export default ReadForm;
