import React, { useContext } from 'react';
import { getScheduleTypeDefinition } from './constants';
import ScheduleContext from './scheduleContext';

const ScheduleSettings = () => {
	const { selected, cronObjects } = useContext(ScheduleContext);

	if (!selected) return null;

	const type = getScheduleTypeDefinition(selected);

	const Control = type.control;
	return <Control cronObjects={cronObjects} type={selected} />;
};

export default ScheduleSettings;
