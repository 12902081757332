/* eslint-disable react/display-name */
import { forwardRef, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Virtuoso } from 'react-virtuoso';
import {
	filterOperators,
	ListNoRecords,
	BaseLinearProgress,
	BaseCircularProgress,
	PanelList,
	removeAllSliceFilters,
	listScrollToElement
} from '@sonar-web/common';
import {
	selectLocationNodes,
	fetchLocationNodesAsync,
	setOffset,
	selectSelectedBreadcrumb,
	resetLocationNodes
} from '../LocationNodes/slice';
import { useFilterDescriptor } from '@sonar-web/common/src/hooks';

const slice = 'locationNodes';

const LocationsSelectList = ({ listItem, selectedLocations, asList = false, listDefaultFilters }) => {
	const dispatch = useDispatch();
	const { createFilter } = useFilterDescriptor();
	const { dataRows, hasMore, pending, reset } = useSelector(selectLocationNodes);
	const [isReloading, setIsReloading] = useState(true);
	const currentBreadcrumb = useSelector(selectSelectedBreadcrumb);

	const defaultFilters = () => {
		let filtersData = asList
			? []
			: createFilter([
					{
						member: { name: 'parentLocationNodeId', type: 'guid' },
						value: currentBreadcrumb.id,
						operator: filterOperators.equal
					}
			  ]);

		if (selectedLocations.length > 0)
			filtersData = [...filtersData, ...createFilter(listDefaultFilters(selectedLocations))];

		return filtersData;
	};

	const fetchData = (append = false) => {
		if (append) {
			if (!hasMore) return;
		} else listScrollToElement(0, 'LocationNodesList');

		dispatch(setOffset(append));
		setIsReloading(!append);
		dispatch(fetchLocationNodesAsync(defaultFilters()));
	};

	useEffect(() => {
		if (asList && selectedLocations.length === 0) {
			dispatch(resetLocationNodes());
			return;
		}

		fetchData();
	}, [selectedLocations, reset, currentBreadcrumb.id]);

	useEffect(() => {
		return () => {
			dispatch(resetLocationNodes());
			dispatch(removeAllSliceFilters(slice));
		};
	}, []);

	if (!dataRows) return null;
	const elementsCount = dataRows?.length;
	const hasRecords = elementsCount !== 0;
	const hasNoSelected = asList && !listDefaultFilters;

	const Components = useMemo(() => {
		return {
			List: forwardRef(({ style, children, className }, listRef) => {
				return (
					<PanelList
						id='LocationNodesList'
						ref={listRef}
						style={style}
						disablePadding
						className={className}
						data-at-params={`hasLocations|${hasRecords}|hasMoreLocations|${hasMore}`}>
						{children}
					</PanelList>
				);
			}),
			Footer: () => <BaseCircularProgress pending={pending && !isReloading} paddingTop={3} />,
			EmptyPlaceholder: () => (
				<ListNoRecords
					show={hasNoSelected || (!pending && !hasRecords)}
					text={asList ? 'LocationNodes_SelectedNodesListIsEmpty' : 'LocationNodes_LocationNodeIsEmpty'}
				/>
			)
		};
	}, [elementsCount]);

	return (
		<>
			<BaseLinearProgress pending={pending && isReloading} />
			<Virtuoso
				style={{ minHeight: 225 }}
				data={dataRows}
				components={Components}
				itemContent={(index) => listItem(dataRows[index])}
				totalCount={dataRows.length}
				endReached={() => fetchData(true)}
			/>
		</>
	);
};

export default LocationsSelectList;
