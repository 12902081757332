import WatermeterTypePage from './WatermeterTypePage';
import WatermeterTypeForm from './WatermeterTypeForm';
import WatermeterTypeDelete from './WatermeterTypeDelete';

export const sidebarComponents = {
	Dashboard: {
		id: 1,
		name: 'Dashboard',
		component: WatermeterTypePage
	},
	DeviceTypeAdd: {
		id: 2,
		name: 'DeviceTypeAdd',
		component: WatermeterTypeForm
	},
	DeviceTypeEdit: {
		id: 3,
		name: 'DeviceTypeEdit',
		component: WatermeterTypeForm
	},
	DeviceTypeDelete: {
		id: 4,
		name: 'DeviceTypeDelete',
		component: WatermeterTypeDelete
	}
};

export const sidebarType = 'watermeterType';
